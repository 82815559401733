import { useRef } from "react";
import { TextHead3, moveTopBottom } from "../../styled/styled";
import styled from 'styled-components';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestion } from "@fortawesome/pro-solid-svg-icons";
import useDarkMode from "../../hooks/darkmode/DarkMode";



const ModalDiv = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0, 0.8);
  z-index: 1000;
  overflow-y: auto;
`;


const ContentDiv = styled.div<{ isDarkMode: boolean }>`
 position: absolute;
  float: left;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: ${props => (props?.isDarkMode ? '#2e2e2e' : '#fff')};
  padding: 20px;
  z-index: 1000;
  width: 350px;
  max-width: 90%;
  border-radius: .5em;
  text-align: center;
  animation: ${moveTopBottom} .4s ease-in-out;
`;


const QuestionModalIcon = styled.div`
    position: absolute;
    top: -50px;
    right: 130px;
    width: 70px;
    height: 70px;
    padding: 15px;
    z-index: 10;
    background: #22a45d;
    color: white;
    border-radius: 40px;
`;

const ButtonOne = styled.button`
background: #b3b3b3;
padding: 5px;
border-radius: 5px;
color: white;
font-weight: 600;
margin: 2px;
width: 100%;
`;

const ButtonTwo = styled.button`
background: #22A45D;
padding: 5px;
border-radius: 5px;
color: white;
font-weight: 600;
margin: 2px;
width: 100%;
`;

const TextMeal = styled.h3`
font-weight: 600;
 font-size: 25px;
 margin-bottom: 20px;
`;


const Flex = styled.div`
display: flex;
`;

export default function ModalAccessControl(props) {
    const isDarkMode = useDarkMode();
    const modalRef = useRef()

    const closeModal = (e: any) => {
        if (modalRef.current === e.target) {
            props?.setShowModal(false)
        }
    }


    return (
        <>
            {props?.showModal ? (
                <ModalDiv onClick={closeModal}>
                    <ContentDiv isDarkMode={isDarkMode}>
                        <QuestionModalIcon > <FontAwesomeIcon icon={faQuestion} style={{ fontSize: "40px" }} /></QuestionModalIcon>
                        <TextMeal >{props?.firstText}</TextMeal>
                        <TextHead3 style={{ fontSize: "20px" }}>{props?.secondText}</TextHead3>
                        <Flex>
                            <ButtonOne onClick={() => props?.setShowModal(false)}>{props?.btnOneText}</ButtonOne>
                            <ButtonTwo onClick={props?.handleBtn}>{props?.btnTwoText}</ButtonTwo>
                        </Flex>
                    </ContentDiv>
                </ModalDiv>
            ) : null
            }
        </>
    );
};


