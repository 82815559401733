import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TextHead4, SubText1, FlexBetween } from '../../styled/styled';



export default function CardRibbonImgText(props: any) {
  return (
    <>
      <FlexBetween onClick={props?.toLocation} style={{ alignItems: "center", margin: "10px 0px" }}>
        <img src={props?.img} alt="img" />
        <div className='mr-auto ml-2.5'>
          <TextHead4 >
            {props?.title}
            <SubText1 className='mt-1'>{props?.subText}</SubText1>
            <SubText1 className='mt-1'>{props?.subText2}</SubText1>
          </TextHead4>
        </div>
        <FontAwesomeIcon icon={props.rightIcon} style={props.rightIconStyle} />
      </FlexBetween>
    </>
  );
}

