import styled from 'styled-components';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


const FlexBetween = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px;
  align-items: center;
`

const Span = styled.span`
  font-size: 17px;
  cursor: pointer;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
`

const CheckBoxWrapper = styled.div`
  position: relative;
  margin-left: 5px;
  margin-right: 5px;
`;

const CheckBoxLabel = styled.label`
  position: absolute;
  top: 0;
  left: 0;
  width: 42px;
  height: 22px;
  border-radius: 15px;
  background: #bebebe;
  cursor: pointer;
  &::after {
    content: "";
    display: block;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    margin: 3px;
    background: #ffffff;
    box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.2);
    transition: 0.2s;
  }
`;

const CheckBox = styled.input`
  opacity: 0;
  z-index: 1;
  border-radius: 15px;
  width: 42px;
  height: 26px;
  cursor: pointer;
  &:checked + ${CheckBoxLabel} {
    background: #4fbe79;
    &::after {
      content: "";
      display: block;
      border-radius: 50%;
      width: 15px;
      height: 15px;
      margin-left: 23px;
      transition: 0.2s;
    }
  }
`;

const SubText = styled.h2`
  text-align: left;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #868686;
`;

const Block = styled.div`
display: block;
margin-left: 20px;
margin-right: auto;
`;

export default function ToggleWithSubText(props: any) {
  return (
    <FlexBetween>
      {props.iconLeft == null ? null : <FontAwesomeIcon icon={props.iconLeft} style={props.leftIconStyle} />}
      <Block style={props.style}>
        <Span>{props.text}</Span>
        <SubText>{props.subText}</SubText>
      </Block>
      <CheckBoxWrapper style={props.disabled == true ? { pointerEvents: "none" } : null}>
        <CheckBox disabled={props.disabled} id={"checkbox" + props?.name} type="checkbox" onChange={() => props?.handleOnChangeToggle()} checked={props?.switchState} />
        <CheckBoxLabel htmlFor={"checkbox" + props?.name} style={{ background: props.disabled ? "#b9e2c8" : "" }}
        // checked={props?.switchState}
        />
      <span className='text-xs'>{props?.labelForComingSoon}</span>

      </CheckBoxWrapper>
    </FlexBetween>
  );
};

