import Divider from "../../components/widgets/divider/Divider";
import TopHead from "../../components/widgets/head/TopHead";
import { faAngleLeft } from "@fortawesome/pro-solid-svg-icons";
import Section from "../../components/widgets/section/Section";
import {
  ButtonGreen,
  InputText,
  SubText1,
  TextHead1,
  TextHead3,
} from "../../components/styled/styled";
import { maskCurrencyInput, switchSentenceCase, isAuthenticated, trackPixelEvent } from "../../utils/utilities";
import { useHistory } from "react-router";
import TabsForTipPreference from "../../components/widgets/toggleable-tabs/TabsForTipPreference";
import TipPreferences from "../../components/assets/tip-preference.jpg";
import { useState, useEffect } from "react";
import { userProfileUpdate } from "../../services/user";
import ModalSuccess from "../../components/widgets/modals/ModalSuccess";
import { getUserData, setUserData } from "../../services/user";
import { useIonViewDidEnter } from "@ionic/react";

export default function TipPreference() {
  const history = useHistory();

  useIonViewDidEnter(() => {
    trackPixelEvent('Tip Preference');
  }, []);



  const spendingTypes = [
    {
      name: "None",
      value: "0",
    },

    {
      name: "$5",
      value: "5",
    },

    {
      name: "$10",
      value: "10",
    },

    {
      name: "$15",
      value: "15",
    },

    {
      name: "Other",
      value: "Other",
    },
  ];

  const [showModal, setShowModal] = useState(false);
  const [storeTipActive, setStoreTipActive] = useState("");
  const [driverTipActive, setDriverTipActive] = useState("");

  const [storeTipValue, setStoreTipValue] = useState("");
  const [driverTipValue, setDriverTipValue] = useState("");

  const [otherStoreTipValue, setOtherStoreTipValue] = useState("");
  const [otherStoreTipError, setOtherStoreTipError] = useState(false);

  const [otherDriverTipValue, setOtherDriverTipValue] = useState("");
  const [otherDriverTipError, setOtherDriverTipError] = useState(false);

  const handleOtherStoreTip = (val: any) => {
    let maskedValue = maskCurrencyInput(val);
    setOtherStoreTipError(false);
    setOtherStoreTipValue(maskedValue);
  };

  const handleOtherDriverTip = (val: any) => {
    let maskedValue = maskCurrencyInput(val);
    setOtherDriverTipError(false);
    setOtherDriverTipValue(maskedValue);
  };

  useEffect(() => {
    callData();
  }, []);

  const callData = async () => {
    if (isAuthenticated() === false) return false;
    const user = await getUserData();

    const filterStoreTip = spendingTypes.filter(
      (data) => data?.value === String(user?.user?.auto_tip_amt)
    );
    if (filterStoreTip?.length > 0) {
      setStoreTipValue(filterStoreTip[0]?.value);
      setStoreTipActive(filterStoreTip[0]?.value);
    } else if (user?.user?.auto_tip_amt === null) {
      setStoreTipValue(spendingTypes[0]?.value);
      setStoreTipActive(spendingTypes[0]?.value);
    } else {
      setStoreTipValue("Other");
      setStoreTipActive(spendingTypes[4]?.value);
      setOtherStoreTipValue("$" + user?.user?.auto_tip_amt);
    }

    const filterDriverTip = spendingTypes.filter(
      (data) => data?.value === String(user?.user?.driver_auto_tip)
    );

    if (filterDriverTip?.length > 0) {
      setDriverTipValue(filterDriverTip[0]?.value);
      setDriverTipActive(filterDriverTip[0]?.value);
    } else if (user?.user?.driver_auto_tip === null) {
      setDriverTipValue(spendingTypes[0]?.value);
      setDriverTipActive(spendingTypes[0]?.value);
    } else {
      setDriverTipValue("Other");
      setDriverTipActive(spendingTypes[4]?.value);
      setOtherDriverTipValue("$" + user?.user?.driver_auto_tip);
    }
  };

  const updateValue = async () => {
    if (storeTipValue === "Other" && otherStoreTipValue === "") {
      setOtherStoreTipError(true);
      return false;
    } else {
      setOtherStoreTipError(false);
    }

    if (driverTipValue === "Other" && otherDriverTipValue === "") {
      setOtherDriverTipError(true);
      return false;
    } else {
      setOtherDriverTipError(false);
    }

    const data = {
      auto_tip_amt:
        storeTipValue === "Other" ? Number((otherStoreTipValue)?.slice(1)) : Number(storeTipValue),
      driver_auto_tip:
        driverTipValue === "Other" ? Number((otherDriverTipValue)?.slice(1)) : Number(driverTipValue),
    };
    await userProfileUpdate(data)
      .then(async (res) => {
        if (res.status === 200) {
          trackPixelEvent('User profile update');
          const localData = await getUserData();
          const newUpdatedUserInfo = {
            ...localData,
            user: {
              ...localData.user,
              auto_tip_amt:
                storeTipValue === "Other"
                  ? Number(otherStoreTipValue?.slice(1))
                  : Number(storeTipValue),
              driver_auto_tip:
                driverTipValue === "Other"
                  ? Number(otherDriverTipValue?.slice(1))
                  : Number(driverTipValue),
            },
          };
          await setUserData(newUpdatedUserInfo);
          setShowModal(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="screen">
      <TopHead
        title="Tip Preferences"
        leftTextIcon={faAngleLeft}
        leftIconStyle={{ fontSize: "24px" }}
      />
      <Divider />
      <div className="grow overflow-auto">
        <Section>
          <TextHead1 className="text-center mt-7">Auto Tip Preferences</TextHead1>

          <SubText1 className="text-center mt-5">
            Tipping is one way to thank our restaurant and delivery partners for their hard work :)
            {/* Tipping is one way for the consumers to thank the delivery partners for their hard work :) */}
            {/* Every time you tip our delivery partners, they will instantly get a notification. */}
          </SubText1>
        </Section>

        <Section align="center">
          <img
            src={TipPreferences}
            alt=""
            style={{
              width: "200px",
              borderRadius: "40px",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          />
          <TextHead3 className="text-left mt-2">Store Tip</TextHead3>
          <TabsForTipPreference
            types={spendingTypes}
            setTipValue={setStoreTipValue}
            setActive={setStoreTipActive}
            selected={storeTipActive}
          />
          {storeTipValue === "Other" ? (
            <InputText
              type="text"
              inputMode="numeric"
              style={{
                border: otherStoreTipError ? "1px solid red" : "",
              }}
              min={0}
              value={otherStoreTipValue}
              placeholder="Enter Amount"
              onChange={(event: any) => handleOtherStoreTip(event.target.value)}
            />
          ) : null}

          <TextHead3 className="text-left mt-5">Driver's Tip</TextHead3>
          <TabsForTipPreference
            types={spendingTypes}
            setTipValue={setDriverTipValue}
            setActive={setDriverTipActive}
            selected={driverTipActive}
          />
          {driverTipValue === "Other" ? (
            <InputText
              type="text"
              inputMode="numeric"
              style={{
                border: otherDriverTipError ? "1px solid red" : "",
              }}
              min={0}
              value={otherDriverTipValue}
              placeholder="Enter Amount"
              onChange={(event: any) => handleOtherDriverTip(event.target.value)}
            />
          ) : null}
        </Section>

        <Section>
          <ButtonGreen onClick={updateValue}>
            {switchSentenceCase("primary button", "UPDATE")}
          </ButtonGreen>

          <ModalSuccess
            contentDivStyle={{ height: "auto" }}
            showModal={showModal}
            firstText={switchSentenceCase("primary button", "Tip Preference Updated Successfully")}
            btnOneGoto={() =>
              history.push({
                pathname: "/profile",
              })
            }
            btnOneText={switchSentenceCase("primary button", "OKAY")}
          />
        </Section>
      </div>
    </div>
  );
}
