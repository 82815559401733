import { SubText1 } from "../../styled/styled";
import { switchSentenceCase, wrapText } from "../../../utils/utilities";
import { Flex, Rate } from "antd";

export default function RestaurantCardDetails({ r }) {
  return (
    <div className="grow relative">
      <h3>{wrapText(switchSentenceCase("head text", r.name))}</h3>
      <div className="subtext wraptext">{r.address.street_addr}</div>
      <Flex className="items-baseline my-1">
        <Rate
          disabled
          allowHalf
          defaultValue={r.weighted_rating_value}
          style={{ color: "#c1a000", fontSize: "16px" }}
        />
        <SubText1 className="flex-fav text-sm text-gray-700">
          ({r?.aggregated_rating_count || 0})
        </SubText1>
      </Flex>
      <div className="flex absolute bottom-0 w-full items-end">
        <div className="grow" style={{ lineHeight: "1.1" }}>
          {r.cuisines?.join(", ")?.substring(0, 25)}
        </div>
        <div
          style={{ lineHeight: "1.1" }}
          className="grow text-right text-asprimary font-bold"
        >
          {r.miles?.toFixed(1)} mi
        </div>
      </div>
    </div>
  );
}