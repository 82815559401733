import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import {
  ButtonInitial,
  SubText1,
  TextHead5,
  Flex,
  TextHead4,
  InitialDiv,
  OrderPills,
  SubText2,
} from "../../components/styled/styled";
import Divider from "../../components/widgets/divider/Divider";
import TopHead from "../../components/widgets/head/TopHead";
import styled from "styled-components";
import Section from "../../components/widgets/section/Section";
import { useSelector, useDispatch } from "react-redux";
import { switchSentenceCase, slotDateTimeFormatter, isAuthenticated, trackPixelEvent, trackStandardPixelEvent, priceFormatterUsd } from "../../utils/utilities";
import { getUserInfo } from "../../services/user";
import {
  getUpcomingOrdersMealDetails,
  setUpcomingOrdersForMe,
  setUpcomingOrdersFromMe,
} from "./redux/upcomingOrderActionCreator";
import ModalRemoved from "../../components/widgets/modals/ModalRemoved";
import { cancelOrder, getUpcomingOrders } from "../../services/orderFood";
import Spinner from "../../components/widgets/spinner/Spinner";
import {
  DynamicToggleTabs,
  DynamicTab,
} from "../../components/widgets/toggleable-tabs/DynamicToggleTabs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHouse } from "@fortawesome/pro-regular-svg-icons";
import ModalReschedule from "../../components/widgets/modals/ModalReschedule";
import { faPotFood, faStore, faUser } from "@fortawesome/pro-solid-svg-icons";
import IonModalAlert from "../../components/widgets/alerts/IonModalAlert";
import ModalOrderDetails from "../../components/widgets/modals/ModalOrderDetails";
import { IonContent, IonRefresher, IonRefresherContent, RefresherEventDetail, useIonRouter, useIonViewDidEnter } from "@ionic/react";
// import ReactPullToRefresh from 'react-pull-to-refresh';

// **********************CSS In Styled For this Page Only****************************************
const WrapChoice = styled.div`
  padding: 5px;
`;

const Tab = styled.div`
  overflow: auto;
  border: none;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 10px;
  ::-webkit-scrollbar {
    display: none;
  }
`;

const TabLinks = styled.button<{ active?: boolean }>`
  background-color: #f1f1f1;
  float: left;
  border: 1px solid #dbdbdb;
  color: #949494;
  border-radius: 10px;
  margin: 5px;
  outline: none;
  padding: 8px 12px;
  transition: 0.3s;
  font-size: 12px;
  font-weight: 500;
  width: auto;
  white-space: nowrap;
  position: relative;
  z-index: 0;
  &:hover {
    background-color: #ddd;
  }

  ${({ active }) =>
    active &&
    ` background: #22A45D;
   color: white;
   border-radius: 10px;
   width: auto;
   &:hover {
    background-color: #22A45D;
  }
  `}
`;

// **********************Other Methods****************************************
export default function ManageOrders() {
  const history = useHistory();
  const dispatch = useDispatch();
  const user = getUserInfo();
  const router = useIonRouter();
  const pageRoute = router.routeInfo?.search;

  const filterOrdersTypes = [
    {
      name: "RECENT",
      value: "RECENT",
    },
    {
      name: "IN PROGRESS",
      value: "UPCOMING",
    },
    {
      name: "SCHEDULED",
      value: "SCHEDULED",
    },
    {
      name: "CANCELED",
      value: "CANCELED",
    },
    {
      name: "COMPLETED",
      value: "COMPLETED",
    },
  ];

  // **********************States Management****************************************
  const { upcomingOrdersForMe, upcomingOrdersFromMe } = useSelector((state: any) => state?.upcomingOrderState);

  // **********************Hooks****************************************
  const [selectedOrder, setSelectedOrder] = useState<any>({});
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [isRefreshed, setIsRefreshed] = useState(false);
  const limit = 10;
  const [skip, setSkip] = useState(0);
  const [showRescheduleModal, setShowRescheduleModal] = useState(false);
  const [showOrderDetailModal, setShowOrderDetailModal] = useState(false);
  const [cancelledID, setCancelledID] = useState<any>({});
  const [totalForMe, setTotalForMe] = useState(0);
  const [totalFromMe, setTotalFromMe] = useState(0);
  const [loadingOrders, setLoadingOrders] = useState(false);
  const [loadingForSeeMore, setLoadingForSeeMore] = useState(false);
  const [showIonAlertModal, setShowIonAlertModal] = useState(false);
  const [ionAlertHeader, setIonAlertHeader] = useState("");
  const [ionAlertMessage, setIonAlertMessage] = useState("");
  const [filterOrders, setFilterOrders] = useState("RECENT");
  const [orderType, setOrderType] = useState(pageRoute === "?for_other" ? "orders_for_someone_else" : "orders_for_me");
  const [updatePage, setUpdatePage] = useState(false);


  // ****************************First Time Rendering***********************************
  useEffect(() => {
    setLoadingOrders(true);
    if (orderType === "orders_for_me") {
      callDataForMe();
    } else if (orderType === "orders_for_someone_else") {
      callDataFromMe();
    }
  }, [filterOrders, orderType, isRefreshed, updatePage]);

  useIonViewDidEnter(() => {
    trackPixelEvent('Manage order');
  }, []);

  // *****************************Functions*******************************
  function handleRefresh(event: CustomEvent<RefresherEventDetail>) {
    setTimeout(() => {
      setIsRefreshed(prev => !prev);
      event.detail.complete();
    }, 2000);
  }

  const callDataForMe = async (skippingValue = 0) => {
    try {
      if (isAuthenticated() === false) return false;
      if (!user?.id) return false;
      const response = await getUpcomingOrders(
        user?.id,
        skippingValue,
        limit,
        "For_Me",
        filterOrders
      );
      setTotalForMe(response?.data?.meta?.pagination?.total);
      trackPixelEvent('Get upcoming orders');

      let tempData = response?.data?.data;

      if (skippingValue > 0) {
        setUpcomingOrdersForMe([...upcomingOrdersForMe, ...tempData]);
      } else {
        setUpcomingOrdersForMe(response?.data?.data);
      }
      setLoadingOrders(false);
      setLoadingForSeeMore(false);
    } catch (e) {
      console.log(e);
      setLoadingOrders(false);
      setLoadingForSeeMore(false);
    }
  };

  const callDataFromMe = async (skippingValue = 0) => {
    try {
      if (isAuthenticated() === false) return false;
      if (!user?.id) return false;
      const response = await getUpcomingOrders(
        user?.id,
        skippingValue,
        limit,
        "From_Me",
        filterOrders
      );
      setTotalFromMe(response?.data?.meta?.pagination?.total);
      trackPixelEvent('Get upcoming orders');

      let tempData = response?.data?.data;

      if (skippingValue > 0) {
        setUpcomingOrdersFromMe([...upcomingOrdersFromMe, ...tempData]);
      } else {
        setUpcomingOrdersFromMe(response?.data?.data);
      }
      setLoadingOrders(false);
      setLoadingForSeeMore(false);
    } catch (e) {
      console.log(e);
      setLoadingOrders(false);
      setLoadingForSeeMore(false);
    }
  };

  const showMore = () => {
    const skippingValue = skip + limit
    setSkip(skippingValue);
    setLoadingForSeeMore(true);
    if (orderType === "orders_for_me") {
      callDataForMe(skippingValue);
    }
    else {
      callDataFromMe(skippingValue);
    }
  };


  const openModalCancelOrder = (item: any) => {
    setSelectedOrder(item);
    setShowRemoveModal(true);
  };

  const deleteOrder = () => {
    const payloadData = {
      data: {
        // slotId: selectedOrder?.id,
        order_status: selectedOrder?.attributes?.schedule?.data !== null ? null : "CANCELED",
      },
    };

    let cancelPrice = '0.00'
    if (selectedOrder?.attributes?.mealMeOrderObject !== null) {
      cancelPrice = priceFormatterUsd(
        (selectedOrder?.attributes?.mealMeOrderObject?.final_quote?.quote?.subtotal || 0) +
        (selectedOrder?.attributes?.mealMeOrderObject?.final_quote?.quote?.sales_tax_cents || 0) +
        (selectedOrder?.attributes?.mealMeOrderObject?.final_quote?.quote?.service_fee_cents || 0) +
        (selectedOrder?.attributes?.mealMeOrderObject?.final_quote?.added_fees?.total_fee_cents || 0) +
        (selectedOrder?.attributes?.mealMeOrderObject?.final_quote?.quote?.delivery_fee_cents || 0) +
        (selectedOrder?.attributes?.mealMeOrderObject?.final_quote?.misc_fees[0]?.fee_amount || 0) +
        (selectedOrder?.attributes?.mealMeOrderObject?.final_quote?.tip || 0)
      ).slice(1);
    }
    else {
      cancelPrice = (Number(selectedOrder?.attributes?.favMeal?.data?.attributes?.total) + Number(selectedOrder?.attributes?.driver_tip?.toFixed(2)) + Number(1.50)).toFixed(2);
    }
    if (selectedOrder?.attributes?.schedule?.data !== null) {
      payloadData.data["favMeal"] = null;
      payloadData.data["sponsoredBy"] = null;
      payloadData.data["restaurant"] = null;
      payloadData.data["payment_card"] = "";
    }
    setSelectedOrder({});
    try {
      cancelOrder(payloadData, selectedOrder?.id).then((response) => {
        const data = response?.data?.data;
        trackStandardPixelEvent('Cancel order', {
          content_ids: null,
          content_name: null,
          content_type: null,
          contents: null,
          currency: 'USD',
          value: cancelPrice
        });
        setCancelledID(response?.data);
        callDataForMe();
        callDataFromMe();
      });
    } catch (e) {
      console.log(e);
    }
  };

  const openModalHandler = (faveId: any, upcomingOrderType: any, itemId: any, order_status: any) => {
    dispatch(getUpcomingOrdersMealDetails(faveId, upcomingOrderType, itemId) as any);
    setCancelledID([]);
    if (order_status === "SCHEDULED") {
      setShowRescheduleModal((prev) => !prev);
    }
    else {
      setShowOrderDetailModal((prev) => !prev);
    }
  };

  const handleFilterOrders = (value: any) => {
    setFilterOrders(value);
    if (orderType === "orders_for_me") {
      setSkip(0);
    } else if (orderType === "orders_for_someone_else") {
      setSkip(0);
    }
  };

  const orderNow = () => {
    // dispatch(scheduleDetailsClear() as any);
    history.push("/orders");
  };

  return (
    <div className="screen">
      <TopHead title="Manage Orders" />
      <Divider />
      <div className="grow overflow-auto">
        <IonContent >
          {/* <IonContent scrollY={showRescheduleModal || showOrderDetailModal || showRemoveModal ? false : true}> */}
          <IonRefresher slot="fixed" onIonRefresh={handleRefresh}>
            <IonRefresherContent></IonRefresherContent>
          </IonRefresher>

          {/* <ReactPullToRefresh
          onRefresh={handleRefresh}
          className="pull-refresh"
          style={{ textAlign: 'center' }}
          icon={<span className="genericon genericon-next"></span>}
        > */}
          <>
            <Section>
              <ButtonInitial
                style={{ background: "#d17700", color: "white", marginTop: "10px" }}
                onClick={() => orderNow()}
              >
                {switchSentenceCase("primary button", "Create A New Order")}
              </ButtonInitial>
            </Section>
            <Divider />
            <Section>
              {/* <DynamicTabs function1={callDataForMe} function2={callDataFromMe}> */}
              <DynamicToggleTabs>
                <DynamicTab
                  label={"orders_for_me"}
                  tabName={"Orders For Me"}
                  setOrderType={setOrderType}
                  selectedTab={orderType}
                >
                  <Tab>
                    {filterOrdersTypes?.map((type: any) => (
                      <TabLinks
                        key={type?.name}
                        active={type?.value === filterOrders}
                        onClick={() => handleFilterOrders(type?.value)}
                      >
                        {type?.name}
                      </TabLinks>
                    ))}
                  </Tab>
                  <Divider />
                  <div title="Orders For Me">
                    {loadingOrders && skip === 0 && (
                      <div className="flex items-center justify-center">
                        <Spinner />
                      </div>
                    )}
                    <WrapChoice>
                      {upcomingOrdersForMe?.length > 0 &&
                        !loadingOrders &&
                        upcomingOrdersForMe?.map((item: any, index: any) => {
                          return (
                            <React.Fragment key={index}>
                              <Flex style={{ alignItems: "center", marginBottom: "10px" }}>
                                <Flex
                                  className="items-center w-full cursor-pointer"
                                  onClick={() => openModalHandler(
                                    item?.attributes?.favMeal?.data?.id,
                                    "for_me",
                                    item?.id,
                                    "order_details"
                                  )
                                  }
                                >
                                  {/* <input type="checkbox" style={{ height: '14px', marginTop: "8px" }} className="w-8 accent-green-600 cursor-pointer" id={item?.id} checked={selectedSlotsId.includes(item?.id)} disabled={item?.attributes?.sponsoredBy?.data !== null} name={item?.id} onChange={(e) => handleCheckBox(item?.id)} /> */}
                                  <InitialDiv className="w-full">
                                    <TextHead4 className="flex !justify-between items-center">
                                      {slotDateTimeFormatter(
                                        item?.attributes?.date,
                                        item?.attributes?.time
                                      )}{" "}
                                      {""}
                                      {(cancelledID?.data === item?.id &&
                                        cancelledID?.status === true) ||
                                        item?.attributes?.order_status === "CANCELED" ? (
                                        <OrderPills
                                          style={{
                                            color: "#EF4406",
                                            backgroundColor: "#FBE0E0",
                                            borderColor: "#EF4406",
                                          }}
                                        >
                                          Canceled
                                        </OrderPills>
                                      ) : (
                                        ""
                                      )}
                                      {item?.attributes?.order_status === "ORDERED" ? (
                                        <OrderPills
                                          style={{
                                            color: "#22ae62",
                                            backgroundColor: "#dbf6e7",
                                            borderColor: "#22ae62",
                                          }}
                                        >
                                          Ordered
                                        </OrderPills>
                                      ) : (
                                        ""
                                      )}
                                      {item?.attributes?.order_status === "COMPLETED" ? (
                                        <OrderPills
                                          style={{
                                            color: "#22ae62",
                                            backgroundColor: "#dbf6e7",
                                            borderColor: "#22ae62",
                                          }}
                                        >
                                          Completed
                                        </OrderPills>
                                      ) : (
                                        ""
                                      )}
                                    </TextHead4>
                                    <span
                                      style={
                                        item?.attributes?.favMeal?.data !== null
                                          ? {
                                            fontWeight: "350",
                                            fontSize: "17px",
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "baseline",
                                          }
                                          : { fontWeight: "350", color: "#dc2626", fontSize: "17px" }
                                      }
                                    >
                                      {item?.attributes?.favMeal?.data !== null
                                        ? `(${switchSentenceCase(
                                          "head text",
                                          item?.attributes?.favMeal?.data?.attributes?.name
                                        )})`
                                        : `(${switchSentenceCase("head text", "Empty Slot")})`}
                                      {item?.attributes?.sponsoredBy?.data !== null &&
                                        item?.attributes?.sponsoredBy?.data?.id !== user?.id && (
                                          <p className="text-[15px] font-normal text-[#868686]">
                                            {switchSentenceCase(
                                              "head text",
                                              `Sponsored By ${item?.attributes?.sponsoredBy?.data?.attributes?.name !== null
                                                ? item?.attributes?.sponsoredBy?.data?.attributes?.name
                                                : item?.attributes?.sponsoredBy?.data?.attributes?.email !== null
                                                  ? item?.attributes?.sponsoredBy?.data?.attributes?.email
                                                  : item?.attributes?.sponsoredBy?.data?.attributes?.phone_no
                                              }`
                                            )}
                                          </p>
                                        )}
                                    </span>
                                    {/* {
                                  (item?.attributes?.sponsoredBy?.data !== null && item?.attributes?.sponsoredBy?.data?.id !== user?.id) &&
                                  <SubText1 className="mt-[2px]!font-light">
                                    {switchSentenceCase("head text", `Sponsored By ${(item?.attributes?.sponsoredBy?.data?.attributes?.name !== null) ? (item?.attributes?.sponsoredBy?.data?.attributes?.name) : (item?.attributes?.sponsoredBy?.data?.attributes?.email !== null) ? (item?.attributes?.sponsoredBy?.data?.attributes?.email) : (item?.attributes?.sponsoredBy?.data?.attributes?.phone_no)}`)}
                                  </SubText1>
                                } */}
                                    <div className="flex">
                                      {(item?.attributes?.order_type === "delivery") ?
                                        <FontAwesomeIcon
                                          icon={faHouse}
                                          className="mt-1 text-[#918a8a]"
                                        />
                                        :
                                        <FontAwesomeIcon
                                          icon={faStore}
                                          className="mt-1 text-[#918a8a]"
                                        />}
                                      <div className="ml-2 w-full mt-1">
                                        {(item?.attributes?.order_type === "pickup") &&
                                          <SubText2>
                                            Store Address -
                                            {(item?.attributes?.mealMeOrderObject !== null) ?
                                              item?.attributes?.mealMeOrderObject?.final_quote?.store_address :
                                              `${item?.attributes?.restaurant?.data?.attributes?.jsonData?.address?.street_addr || ''}, ${item?.attributes?.restaurant?.data?.attributes?.jsonData?.address?.city}, ${item?.attributes?.restaurant?.data?.attributes?.jsonData?.address?.state}, ${item?.attributes?.restaurant?.data?.attributes?.jsonData?.address?.zipcode}, ${item?.attributes?.restaurant?.data?.attributes?.jsonData?.address?.country}`
                                            }
                                          </SubText2>}
                                        {(item?.attributes?.order_type === "delivery" || item?.attributes?.order_type === null) &&
                                          <SubText2>
                                            {/* {(item?.attributes?.order_obj !== null) ?
                                              (item?.attributes?.order_obj?.user_street_num ===
                                                "N/A"
                                                ? ""
                                                : item?.attributes?.order_obj?.user_street_num) +
                                              " " +
                                              item?.attributes?.order_obj?.user_street_name +
                                              ", " +
                                              item?.attributes?.order_obj?.user_city +
                                              ", " +
                                              item?.attributes?.order_obj?.user_state +
                                              ", " +
                                              item?.attributes?.order_obj?.user_zipcode +
                                              ", " +
                                              item?.attributes?.order_obj?.user_country
                                              :  */}
                                            {
                                              item?.attributes?.delivery_location?.data?.attributes?.addressLine1 +
                                              ", " +
                                              item?.attributes?.delivery_location?.data?.attributes?.city +
                                              ", " +
                                              item?.attributes?.delivery_location?.data?.attributes?.state +
                                              ", " +
                                              item?.attributes?.delivery_location?.data?.attributes?.zip_code +
                                              ", " +
                                              item?.attributes?.delivery_location?.data?.attributes?.country
                                            }
                                          </SubText2>}
                                      </div>
                                    </div>
                                    {(item?.attributes?.order_type === "pickup") &&
                                      <div className="flex">
                                        <FontAwesomeIcon
                                          icon={faPotFood}
                                          className="mt-1 text-[#918a8a]"
                                        />
                                        <div className="ml-2 w-full mt-1">
                                          <TextHead4 className="!text-green-600 font-bold">(Pickup Order)</TextHead4>
                                        </div>
                                      </div>
                                    }
                                  </InitialDiv>
                                </Flex>
                              </Flex>
                              {(item?.attributes?.order_status === "ORDERED" ||
                                item?.attributes?.order_status === "COMPLETED") && (
                                  <Flex>
                                    <TextHead5
                                      onClick={() =>
                                        history.push({ pathname: "/orderTrack/" + item?.id })
                                      }
                                      className="!text-green-600 mb-2 !font-semibold cursor-pointer"
                                    >
                                      Track Order
                                    </TextHead5>
                                  </Flex>
                                )}
                              {item?.attributes?.order_status === "SCHEDULED" && (
                                <Flex>
                                  <TextHead5
                                    onClick={() =>
                                      openModalHandler(
                                        item?.attributes?.favMeal?.data?.id,
                                        "for_me",
                                        item?.id,
                                        item?.attributes?.order_status
                                      )
                                    }
                                    className="!text-green-600 mb-2 !font-semibold cursor-pointer !mr-10"
                                  >
                                    Reschedule
                                  </TextHead5>
                                  {(cancelledID?.data === item?.id && cancelledID?.status === true) ||
                                    item?.attributes?.order_status === "CANCELED" ? (
                                    ""
                                  ) : (
                                    <TextHead5
                                      className="text-red-500 underline cursor-pointer mb-2 !font-semibold cursor-pointer"
                                      onClick={() => openModalCancelOrder(item)}
                                    >
                                      Cancel Order
                                    </TextHead5>
                                  )}
                                </Flex>
                              )}
                              <Divider className="mb-2" />
                            </React.Fragment>
                          );
                        })}
                      {upcomingOrdersForMe?.length === 0 && !loadingOrders && (
                        <SubText1 className="text-center !text-gray-400 !text-base mt-20">
                          {filterOrders === "RECENT"
                            ? "No recent orders."
                            : filterOrders === "SCHEDULED"
                              ? "No scheduled orders."
                              : filterOrders === "UPCOMING"
                                ? "No orders in progress. Check “Scheduled” for upcoming orders or “Completed” for completed orders."
                                : filterOrders === "CANCELED"
                                  ? "No canceled orders."
                                  : "No completed orders."}
                        </SubText1>
                      )}
                    </WrapChoice>
                    {(totalForMe > limit && !loadingOrders) &&
                      (totalForMe !== upcomingOrdersForMe?.length ? (
                        <div
                          className="text-green-600 text-center cursor-pointer"
                          onClick={() => { showMore(); }}
                        >
                          {loadingForSeeMore ?
                            <div className="flex items-center justify-center">
                              <Spinner />
                            </div>
                            :
                            "See More"
                          }
                        </div>
                      ) : (
                        <div className="text-green-600 text-center">No More Orders</div>
                      ))}
                  </div>
                </DynamicTab>
                <DynamicTab
                  label={"orders_for_someone_else"}
                  tabName={"Orders For Someone Else"}
                  setOrderType={setOrderType}
                  selectedTab={orderType}
                >
                  <Tab>
                    {filterOrdersTypes?.map((type: any) => (
                      <TabLinks
                        key={type?.name}
                        active={type?.value === filterOrders}
                        onClick={() => handleFilterOrders(type?.value)}
                      >
                        {type?.name}
                      </TabLinks>
                    ))}
                  </Tab>
                  <div title="Orders For Someone Else">
                    {loadingOrders && skip === 0 && (
                      <div className="flex items-center justify-center">
                        <Spinner />
                      </div>
                    )}
                    <WrapChoice>
                      {upcomingOrdersFromMe?.length > 0 && !loadingOrders &&
                        upcomingOrdersFromMe?.map((item: any, index: any) => {
                          return (
                            <React.Fragment key={index}>
                              <Flex style={{ alignItems: "center", marginBottom: "10px" }}>
                                <Flex
                                  className="items-center w-full cursor-pointer"
                                  onClick={() => openModalHandler(
                                    item?.attributes?.favMeal?.data?.id,
                                    "from_me",
                                    item?.id,
                                    "order_details"
                                  )
                                  }
                                >
                                  <InitialDiv className="w-full">
                                    <TextHead4 className="flex !justify-between items-center">
                                      {slotDateTimeFormatter(
                                        item?.attributes?.date,
                                        item?.attributes?.time
                                      )}{" "}
                                      {""}
                                      {(cancelledID?.data === item?.id &&
                                        cancelledID?.status === true) ||
                                        item?.attributes?.order_status === "CANCELED" ? (
                                        <OrderPills
                                          style={{
                                            color: "#EF4406",
                                            backgroundColor: "#FBE0E0",
                                            borderColor: "#EF4406",
                                          }}
                                        >
                                          Canceled
                                        </OrderPills>
                                      ) : (
                                        // <TextHead5 className="text-red-500 cursor-pointer mb-2 !font-semibold cursor-pointer">Cancelled</TextHead5>
                                        ""
                                      )}
                                      {item?.attributes?.order_status === "ORDERED" ? (
                                        <OrderPills
                                          style={{
                                            color: "#22ae62",
                                            backgroundColor: "#dbf6e7",
                                            borderColor: "#22ae62",
                                          }}
                                        >
                                          Ordered
                                        </OrderPills>
                                      ) : (
                                        ""
                                      )}
                                      {item?.attributes?.order_status === "COMPLETED" ? (
                                        <OrderPills
                                          style={{
                                            color: "#22ae62",
                                            backgroundColor: "#dbf6e7",
                                            borderColor: "#22ae62",
                                          }}
                                        >
                                          Completed
                                        </OrderPills>
                                      ) : (
                                        ""
                                      )}
                                    </TextHead4>

                                    <span
                                      style={
                                        item?.attributes?.favMeal?.data !== null
                                          ? {
                                            fontWeight: "350",
                                            fontSize: "17px",
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "baseline",
                                          }
                                          : { fontWeight: "350", color: "#dc2626", fontSize: "17px" }
                                      }
                                    >
                                      {item?.attributes?.favMeal?.data !== null
                                        ? `(${switchSentenceCase(
                                          "head text",
                                          item?.attributes?.favMeal?.data?.attributes?.name
                                        )})`
                                        : `(${switchSentenceCase("head text", "Empty Slot")})`}

                                      {item?.attributes?.sponsoredBy?.data !== null &&
                                        item?.attributes?.sponsoredBy?.data?.id !== user?.id && (
                                          <p className="text-[15px] font-normal text-[#868686]">
                                            {switchSentenceCase(
                                              "head text",
                                              `Sponsored By ${item?.attributes?.sponsoredBy?.data?.attributes?.name !== null
                                                ? item?.attributes?.sponsoredBy?.data?.attributes?.name
                                                : item?.attributes?.sponsoredBy?.data?.attributes?.email !== null
                                                  ? item?.attributes?.sponsoredBy?.data?.attributes?.email
                                                  : item?.attributes?.sponsoredBy?.data?.attributes?.phone_no
                                              }`
                                            )}
                                          </p>
                                        )}
                                    </span>

                                    {/* {
                                  (item?.attributes?.sponsoredBy?.data !== null && item?.attributes?.sponsoredBy?.data?.id !== user?.id) &&
                                  <SubText1 className="mt-[2px] ml-[23px] !font-light">
                                    {switchSentenceCase("head text", `Sponsored By ${(item?.attributes?.sponsoredBy?.data?.attributes?.name !== null) ? (item?.attributes?.sponsoredBy?.data?.attributes?.name) : (item?.attributes?.sponsoredBy?.data?.attributes?.email !== null) ? (item?.attributes?.sponsoredBy?.data?.attributes?.email) : (item?.attributes?.sponsoredBy?.data?.attributes?.phone_no)}`)}
                                  </SubText1>
                                } */}
                                    {item?.attributes?.type === "for_other" || (item?.attributes?.sponsoredBy?.data?.id === user?.id && item?.attributes?.user?.data?.id !== user?.id) ? (
                                      <>
                                        <div className="flex">
                                          <FontAwesomeIcon
                                            icon={faUser}
                                            className="mt-1 ml-0.5 text-[#918a8a]"
                                          />
                                          <div className="ml-3 w-full mt-1">
                                            <SubText2>
                                              {" "}
                                              {item?.attributes?.user?.data?.attributes?.name
                                                ? item?.attributes?.user?.data?.attributes?.name + ","
                                                : ""}{" "}
                                              {item?.attributes?.user?.data?.attributes?.phone_no
                                                ? item?.attributes?.user?.data?.attributes?.phone_no
                                                : item?.attributes?.user?.data?.attributes?.email}{" "}
                                            </SubText2>
                                          </div>
                                        </div>
                                      </>
                                    ) : (
                                      ""
                                    )}

                                    <div className="flex">
                                      {(item?.attributes?.order_type === "delivery") ?
                                        <FontAwesomeIcon
                                          icon={faHouse}
                                          className="mt-1 text-[#918a8a]"
                                        />
                                        :
                                        <FontAwesomeIcon
                                          icon={faStore}
                                          className="mt-1 text-[#918a8a]"
                                        />}
                                      <div className="ml-2 w-full mt-1">
                                        {(item?.attributes?.order_type === "pickup") &&
                                          <SubText2>
                                            Store Address -
                                            {(item?.attributes?.mealMeOrderObject !== null) ?
                                              item?.attributes?.mealMeOrderObject?.final_quote?.store_address :
                                              `${item?.attributes?.restaurant?.data?.attributes?.jsonData?.address?.street_addr || ''}, ${item?.attributes?.restaurant?.data?.attributes?.jsonData?.address?.city}, ${item?.attributes?.restaurant?.data?.attributes?.jsonData?.address?.state}, ${item?.attributes?.restaurant?.data?.attributes?.jsonData?.address?.zipcode}, ${item?.attributes?.restaurant?.data?.attributes?.jsonData?.address?.country}`
                                            }
                                          </SubText2>}
                                        {(item?.attributes?.order_type === "delivery" || item?.attributes?.order_type === null) &&
                                          <SubText2>
                                            {(item?.attributes?.order_obj !== null) ?
                                              (item?.attributes?.order_obj?.user_street_num ===
                                                "N/A"
                                                ? ""
                                                : item?.attributes?.order_obj?.user_street_num) +
                                              " " +
                                              item?.attributes?.order_obj?.user_street_name +
                                              ", " +
                                              item?.attributes?.order_obj?.user_city +
                                              ", " +
                                              item?.attributes?.order_obj?.user_state +
                                              ", " +
                                              item?.attributes?.order_obj?.user_zipcode +
                                              ", " +
                                              item?.attributes?.order_obj?.user_country
                                              :
                                              item?.attributes?.delivery_location?.data?.attributes?.addressLine1 +
                                              ", " +
                                              item?.attributes?.delivery_location?.data?.attributes?.city +
                                              ", " +
                                              item?.attributes?.delivery_location?.data?.attributes?.state +
                                              ", " +
                                              item?.attributes?.delivery_location?.data?.attributes?.zip_code +
                                              ", " +
                                              item?.attributes?.delivery_location?.data?.attributes?.country
                                            }
                                          </SubText2>}
                                      </div>
                                    </div>
                                    {(item?.attributes?.order_type === "pickup") &&
                                      <div className="flex">
                                        <FontAwesomeIcon
                                          icon={faPotFood}
                                          className="mt-1 text-[#918a8a]"
                                        />
                                        <div className="ml-2 w-full mt-1">
                                          <TextHead4 className="!text-green-600 font-bold">(Pickup Order)</TextHead4>
                                        </div>
                                      </div>
                                    }
                                  </InitialDiv>
                                </Flex>
                              </Flex>

                              {(item?.attributes?.order_status === "ORDERED" ||
                                item?.attributes?.order_status === "COMPLETED") && (
                                  <Flex>
                                    <TextHead5
                                      onClick={() =>
                                        history.push({ pathname: "/orderTrack/" + item?.id })
                                      }
                                      className="!text-green-600 mb-2 !font-semibold cursor-pointer"
                                    >
                                      Track Order
                                    </TextHead5>
                                  </Flex>
                                )}

                              {item?.attributes?.order_status === "SCHEDULED" && (
                                <Flex>
                                  <TextHead5
                                    onClick={() =>
                                      openModalHandler(
                                        item?.attributes?.favMeal?.data?.id,
                                        "from_me",
                                        item?.id,
                                        item?.attributes?.order_status
                                      )
                                    }
                                    className="!text-green-600 mb-2 !font-semibold cursor-pointer !mr-10"
                                  >
                                    Reschedule
                                  </TextHead5>
                                  {(cancelledID?.data === item?.id && cancelledID?.status === true) ||
                                    item?.attributes?.order_status === "CANCELED" ? (
                                    <TextHead5 className="text-red-500 cursor-pointer mb-2 !font-semibold cursor-pointer">
                                      Canceled
                                    </TextHead5>
                                  ) : (
                                    <TextHead5
                                      className="text-red-500 underline cursor-pointer mb-2 !font-semibold cursor-pointer"
                                      onClick={() => openModalCancelOrder(item)}
                                    >
                                      Cancel Order
                                    </TextHead5>
                                  )}
                                </Flex>
                              )}
                              <Divider className="mb-2" />
                            </React.Fragment>
                          );
                        })}
                      {upcomingOrdersFromMe?.length === 0 && !loadingOrders && (
                        <SubText1 className="text-center !text-gray-400 !text-base mt-20">
                          {filterOrders === "RECENT"
                            ? "No recent orders."
                            : filterOrders === "SCHEDULED"
                              ? "No scheduled orders."
                              : filterOrders === "UPCOMING"
                                ? "No orders in progress. Check “Scheduled” for upcoming orders or “Completed” for completed orders."
                                : filterOrders === "CANCELED"
                                  ? "No canceled orders."
                                  : "No completed orders."}
                        </SubText1>
                      )}
                    </WrapChoice>
                    {(totalFromMe > limit && !loadingOrders) &&
                      (totalFromMe !== upcomingOrdersFromMe?.length ? (
                        <div
                          className="text-green-600 text-center cursor-pointer"
                          onClick={() => {
                            showMore();
                          }}
                        >
                          {loadingForSeeMore ? (
                            <div className="flex items-center justify-center">
                              <Spinner />
                            </div>
                          ) : (
                            "See More"
                          )}
                        </div>
                      ) : (
                        <div className="text-green-600 text-center">No More Orders</div>
                      ))}
                  </div>
                </DynamicTab>
              </DynamicToggleTabs>
              {/* </DynamicTabs> */}
            </Section>

            {/**************************************** Modal Reschedule ******************************************/}
            {showRescheduleModal && (
              <ModalReschedule
                showMealModal={showRescheduleModal}
                setShowMealModal={setShowRescheduleModal}
                updatePage={() => setUpdatePage(!updatePage)}
              />
            )}

            {/**************************************** Modal Order Details ******************************************/}
            {showOrderDetailModal && (
              <ModalOrderDetails
                showMealModal={showOrderDetailModal}
                setShowMealModal={setShowOrderDetailModal}
              />
            )}

            {/**************************************** Modal Confirm Delete Popup ******************************************/}
            <ModalRemoved
              firstText="Are you sure you want to"
              secondText="cancel this Order?"
              btnOneText="OOPS!  NEVER MIND..."
              btnTwoText="YES, CANCEL IT"
              showModal={showRemoveModal}
              setShowModal={setShowRemoveModal}
              deleteItem={deleteOrder}
              id={selectedOrder?.id}
            />

            {/* *********************Error Alert Modal****************************** */}
            <IonModalAlert
              isOpened={showIonAlertModal}
              setIsOpened={setShowIonAlertModal}
              header={ionAlertHeader}
              subHeader=""
              message={ionAlertMessage}
            />
          </>
          {/* </ReactPullToRefresh> */}
        </IonContent>
      </div>
    </div>
  );
}
