import { useHistory } from "react-router";
import MealCard from "../../components/widgets/food/MealCard";
import { useSelector } from "react-redux";


export default function FavoriteMealList(props: any) {
  const history = useHistory()
  const onMealClick = () => { history.push({ pathname: "productdetail" }) }

  return (
    <div>
      {/* {header ? <h2 className="mt-0">{header}</h2> : null} */}
      {props?.data?.map((p: any, index: number) => (
        <div className="lst-item" key={index}>
          <h1>{p?.attributes?.product?.data?.attributes.id}</h1>
          <MealCard
            meal={p}
            favMealId={p.id}
            onClick={onMealClick}
            type={props?.type}
          />
        </div>
      ))}
    </div>
  );
}


