import Divider from "../../components/widgets/divider/Divider";
import TopHead from "../../components/widgets/head/TopHead";
import { faAngleLeft } from "@fortawesome/pro-solid-svg-icons";
import Section from "../../components/widgets/section/Section";
import { getUserInfo } from "../../services/user";
import AddressesCart from "../../components/widgets/address/AdressesCart";
// import { isAuthenticated } from "../../utils/utilities"
import store from "../../redux/store";
import { trackPixelEvent } from "../../utils/utilities";
import { useIonViewDidEnter } from "@ionic/react";



export default function AddLocationList() {

    useIonViewDidEnter(() => {
        trackPixelEvent('Location lists');
    }, []);


    const user = getUserInfo();
    if (!user?.id) {
        store.dispatch({
            type: "SHOW_ALERT",
            payload: { show: true }
        })
    }
    const handleAddressId = () => {
    }

    return (
        <div className="screen">

            <TopHead
                title="Locations"
                leftTextIcon={faAngleLeft}
                leftIconStyle={{ fontSize: "24px" }}
            />
            <Divider />

            <div className="grow overflow-auto">
                <Section>
                    <AddressesCart
                        title=""
                        addressId={handleAddressId}
                        showDeleteBtn={true}
                        showList={true}
                        pageName=""
                        pageDataId=""
                        changeAddressTxtBtn=" "
                        userId={user?.id}
                        setDeliveryAddress={false}
                        changeAddress={true}
                        initialLength={1}
                    />
                </Section>
            </div>
        </div>
    );
}