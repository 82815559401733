import React, { useState, useRef, useEffect } from "react";
import { ButtonGreen, ButtonLimeGreen, Flex, FlexBetween, InitialDiv, InputText, SubText1, SubText2, TextArea1, TextHead1, TextHead3, TextHead4, TextHead5, moveTopBottom } from "../../styled/styled";
import styled from "styled-components";
import { isRestaurantOpen, switchSentenceCase, mergeSlotDateTime, fullDateToShortConvert, existingOrderItemsFormatter, sumOfMealInCart, slotDateTimeFormatter, restaurantOpenAndClosingTime, maxCharacterLimit, getTimeDifference, checkRestaurantAvailabilityForSelectedSlots, filterRestaurantSchedulesByDateTime, maskCurrencyInput, trackPixelEvent } from "../../../utils/utilities";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router";
import { IonDatetime, IonDatetimeButton, IonModal } from "@ionic/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarPen, faChevronLeft, faClockThree, faCircleInfo, faStore } from "@fortawesome/pro-solid-svg-icons";
import { faHeart } from "@fortawesome/pro-regular-svg-icons";
import { updateMultipleSlot } from "../../../services/schedule_slot";
import ModalSuccess from "./ModalSuccess";
import ModalAddOrderToFave from "./ModalAddOrderToFave";
import MealItemsList from "../food/MealItemsList";
import Accordion from "../accordian/AccordionStyled";
import AddressesCart from "../address/AdressesCart";
import { previewOrder } from "../../../services/orderFood";
import { getUserInfo } from "../../../services/user";
import PaymentCart from "../paymentscomponent/PaymentCart";
import Spinner from "../spinner/Spinner";
import TabsForTipPreference from "../toggleable-tabs/TabsForTipPreference";
import ModalFinalizeOrder from "./ModalFinalizeOrder";
import momentTimezone from "moment-timezone";
import IonModalAlert from "../alerts/IonModalAlert";
import ToolTip from "../alerts/tooltip";
import TimeWidget from "../time/TimeWidget";
import ModalSuccessForCards from "./ModalSuccessForCards";
import useDarkMode from "../../hooks/darkmode/DarkMode";
import { objectType } from "../../../type";
import { deleteSingleFavItem, updateMealCategoryFavItem } from "../../../services/favourite";
import { deleteSingleFavouriteData, updateFavMealItemData } from "../../../pages/faves/redux/favouriteActionCreator";
import ModalRemoved from "./ModalRemoved";

const ModalDiv = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1000;
  overflow-y: auto;
`;

const ContentDiv = styled.div<{ isDarkMode: boolean }>`
    background-color: ${props => (props?.isDarkMode ? '#2e2e2e' : '#fff')};
    margin-bottom: 85px;
    margin-top: 80px;
    padding: 10px;
    z-index: 1000;
    width: 600px;
    max-width: 90%;
    border-radius: 20px;
    text-align: center;
    height: fit-content;
    animation: ${moveTopBottom} .4s ease-in-out;

    /* ${({ active }: any) => active && `
    background: blue;
  `} */
`;

const HeartModalIcon = styled.div`
    padding: 10px;
    background: #f03f3f29;
    border-radius: 15px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: -65px;
    width: fit-content;
    position: relative;
    top: -60px;
    backdrop-filter: blur(7.5px);
`;

const BackModalBtn = styled.div`
  position: relative;
  right: 49%;
  font-size: 17px;
  cursor: pointer;
`;

const TextMealChildren1 = styled.h3`
  color: #868686;
  font-size: 18px;
  font-weight: 400;
  font-style: italic;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;


export default function ModalViewFavMealItems(props: any) {

  // ********************* Other methods *************************************
  const history = useHistory();
  const modalRef = useRef();
  const isDarkMode = useDarkMode();
  const user = getUserInfo();
  const currentDateTime = new Date();
  const dispatch = useDispatch();
  const { pageType } = useParams<any>();
  const { orderType } = useSelector((state: any) => state.getUserRestaurantsLists);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const type = searchParams.get('type') || orderType;

  // ****************************** State Management ********************************************
  const { selectedSlots, scheduleDetails } = useSelector((state: any) => state?.createScheduleForMe);
  const { userDeliveryAddress } = useSelector((state: any) => state.userAddresses);
  const { orderForSomeone } = useSelector((state: any) => state?.upcomingOrderState);
  const mealItemData = props?.mealProps;
  const mealModelData = props?.mealProps?.attributes?.favItems?.data;
  const minDate: any = momentTimezone().tz(userDeliveryAddress?.timezone?.timeZoneId).format();

  const Tips = [
    {
      name: "None",
      value: "0"
    },

    {
      name: "$5",
      value: "5"
    },

    {
      name: "$10",
      value: "10"
    },

    {
      name: "$15",
      value: "15"
    },

    {
      name: "Other",
      value: "Other"
    }
  ]

  const [storeTipActive, setStoreTipActive] = useState("");
  const [driverTipActive, setDriverTipActive] = useState("");

  const [storeTipValue, setStoreTipValue] = useState("");
  const [driverTipValue, setDriverTipValue] = useState("");

  const [otherStoreTipValue, setOtherStoreTipValue] = useState("");
  const [otherStoreTipError, setOtherStoreTipError] = useState(false);

  const [otherDriverTipValue, setOtherDriverTipValue] = useState("");
  const [otherDriverTipError, setOtherDriverTipError] = useState(false);

  const handleOtherStoreTip = (val: any) => {
    let maskedValue = maskCurrencyInput(val);
    setOtherStoreTipError(false);
    setOtherStoreTipValue(maskedValue);
  }

  const handleOtherDriverTip = (val: any) => {
    let maskedValue = maskCurrencyInput(val);
    setOtherDriverTipError(false);
    setOtherDriverTipValue(maskedValue);
  }

  const [showModal, setShowModal] = useState(false);
  const [showModalSuccessForAddMeal, setShowModalSuccessForAddMeal] = useState(false);
  const [hideBackModal, setHideBackModal] = useState(false);
  const [showAddToFaveModal, setShowAddToFaveModal] = useState(false);
  const [addressId, setAddressId] = useState("");
  const [addressIdError, setAddressIdError] = useState(false);
  const [paymentCardId, setPaymentCardId] = useState("");
  const [paymentCardError, setPaymentCardError] = useState(false);
  const [method, setMethod] = useState("");
  const [mealItemList, setMealItemList] = useState([{ id: null, name: null, price: null, customizations: null, quantity: null, instructions: null }]);
  const [orderProcess, setOrderProcess] = useState(false);
  const [addToScheduleProcess, setAddToScheduleProcess] = useState(false);
  const [responseError, setResponseError] = useState("");
  const [scheduleValues, setScheduleValues] = useState({
    time: "",
    date: fullDateToShortConvert(new Date())
  });

  const [restaurantSchedules, setRestaurantSchedules] = useState([]);
  const [restaurantQuote, setRestaurantQuote] = useState<objectType>({});
  const [scheduleTimeError, setScheduleTimeError] = useState(false);
  const [notes, setNotes] = useState(mealItemData?.attributes?.drop_off_notes !== null ? mealItemData?.attributes?.drop_off_notes : "");
  const [showFinalizeModal, setShowFinalizeModal] = useState(false);
  const [scheduleOrderedData, setScheduleOrderedData] = useState<any>([]);
  const [favMealObj, setFavMealObj] = useState({ name: "", id: "", restaurantName: "" })
  const [showIonAlertModal, setShowIonAlertModal] = useState(false);
  const [ionAlertHeader, setIonAlertHeader] = useState("");
  const [ionAlertMessage, setIonAlertMessage] = useState("");
  const [deliverableSlots, setDeliverableSlots] = useState([]);
  const [restaurantTime, setRestaurantTime] = useState(restaurantOpenAndClosingTime(type === "delivery" ? mealItemData?.attributes?.restaurant?.data?.attributes?.jsonData?.local_hours?.delivery : mealItemData?.attributes?.restaurant?.data?.attributes?.jsonData?.local_hours?.pickup, currentDateTime, type));
  const [showWarningModal, setShowWarningModal] = useState(false);

  useEffect(() => {
    const filterRestaurantAvailabilityForSlots = () => {
      let tempSlotList = [];
      selectedSlots?.forEach((obj: any) => {
        const data = checkRestaurantAvailabilityForSelectedSlots(
          (type === "delivery" ? mealItemData?.attributes?.restaurant?.data?.attributes?.jsonData?.local_hours?.delivery : mealItemData?.attributes?.restaurant?.data?.attributes?.jsonData?.local_hours?.pickup),
          (mergeSlotDateTime(obj?.attributes?.date, obj?.attributes?.time)),
          obj?.attributes?.delivery_location?.data?.attributes?.timezone);
        const newData = ({
          ...obj,
          attributes: ({
            ...obj.attributes,
            infoMessage: data?.infoMessage,
            restaurant: data?.restaurant
          })
        })
        tempSlotList.push(newData)
      })
      setDeliverableSlots(tempSlotList);
    };
    filterRestaurantAvailabilityForSlots();
    callData();
  }, []);

  useEffect(() => {

    if ((selectedSlots?.length === 0) && (restaurantQuote?.quote?.hasOwnProperty('error') || !restaurantQuote?.quote?.hasOwnProperty('error') && !restaurantQuote?.quote?.accepts_pickup_tip)) {
      setStoreTipValue(Tips[0]?.value);
      setStoreTipActive(Tips[0]?.value);
    }
    else if ((selectedSlots?.length > 0) && !mealItemData?.attributes?.restaurant?.data?.attributes?.scheduleData?.accepts_pickup_tip) {
      setStoreTipValue(Tips[0]?.value);
      setStoreTipActive(Tips[0]?.value);
    }
    else {
      const filterStoreTip = (Tips).filter((data) => data?.value === String(user?.auto_tip_amt));
      if (filterStoreTip?.length > 0) {
        setStoreTipValue(filterStoreTip[0]?.value);
        setStoreTipActive(filterStoreTip[0]?.value);
      }
      else if (user?.auto_tip_amt === null) {
        setStoreTipValue(Tips[0]?.value);
        setStoreTipActive(Tips[0]?.value);
      }
      else {
        setStoreTipValue("Other");
        setStoreTipActive(Tips[4]?.value);
        setOtherStoreTipValue("$" + user?.auto_tip_amt);
      }
    }

    if ((selectedSlots?.length === 0) && (restaurantQuote?.quote?.hasOwnProperty('error') || !restaurantQuote?.quote?.hasOwnProperty('error') && !restaurantQuote?.quote?.accepts_delivery_tip)) {
      setDriverTipValue(Tips[0]?.value);
      setDriverTipActive(Tips[0]?.value);
    }

    else if ((selectedSlots?.length > 0) && !mealItemData?.attributes?.restaurant?.data?.attributes?.scheduleData?.accepts_delivery_tip) {
      setDriverTipValue(Tips[0]?.value);
      setDriverTipActive(Tips[0]?.value);
    }
    else {
      const filterDriverTip = (Tips).filter((data) => data?.value === String(user?.driver_auto_tip));
      if (filterDriverTip?.length > 0) {
        setDriverTipValue(filterDriverTip[0]?.value);
        setDriverTipActive(filterDriverTip[0]?.value);
      }
      else if (user?.driver_auto_tip === null) {
        setDriverTipValue(Tips[0]?.value);
        setDriverTipActive(Tips[0]?.value);
      }
      else {
        setDriverTipValue("Other");
        setDriverTipActive(Tips[4]?.value);
        setOtherDriverTipValue("$" + user?.driver_auto_tip);
      }
    }
  }, [restaurantQuote]);

  const callData = () => {
    let tempMealItemList = [];

    setFavMealObj({ name: mealItemData?.attributes?.name, id: mealItemData?.id, restaurantName: mealItemData?.attributes?.restaurant?.data.attributes?.name })

    mealModelData?.forEach((currentObj: any) => {

      //********************** */ Calling the recursive function to retrieve selected data ************************************
      const data = {
        id: currentObj?.id,
        name: currentObj?.attributes?.product?.data?.attributes?.name,
        price: Number(currentObj?.attributes?.product?.data?.attributes?.price),
        customizations: currentObj?.attributes?.customizations,
        quantity: Number(currentObj?.attributes?.quantity),
        instructions: currentObj?.attributes?.instructions
      }
      if (mealItemData?.attributes?.status === "INACTIVE" && currentObj?.attributes?.product_error) {
        // Assign the error message to 'data' object
        data['product_error'] = currentObj?.attributes?.product_error;
      }
      tempMealItemList?.push(data);
    });
    setMealItemList(tempMealItemList);
  };

  let disable_buttons = false;
  if (mealItemData?.attributes?.status === "INACTIVE" && mealItemData?.attributes?.error !== null) {
    disable_buttons = mealItemData?.attributes?.error?.length === mealModelData?.length;
  }

  // const showNav = (router.routeInfo.pathname === '/scheduleslotfill' || pageType === 'for-schedule' || pageType === "for-schedule-other-fave" || pageType === "for-schedule-section") ? true : false;

  const closeModal = (e: any) => {
    if (modalRef.current === e.target) {
      props?.setShowMealModal(false);
    }
  };

  const addToSchedule = async () => {

    if (orderProcess || addToScheduleProcess || selectedSlots?.length === 0) { return false; }

    const filterSlots = deliverableSlots?.filter((el: any) => el?.attributes?.restaurant === "Open");
    if (filterSlots?.length === 0) {
      setIonAlertHeader("Sorry!");
      setIonAlertMessage("None of the slots are deliverable.");
      setShowIonAlertModal(true);
      return false;
    }
    let slotsArray = []

    filterSlots?.forEach((obj: any) => {
      slotsArray.push({ slotId: obj?.id, favMealId: props?.favMealId, date: obj?.attributes?.date, time: obj?.attributes?.time, deliveryAddress: obj?.attributes?.delivery_location?.data?.id });
    });

    const payloadData = {
      data: {
        slots: slotsArray,
        order_type: type
      }
    }
    setAddToScheduleProcess(true);
    try {
      await updateMultipleSlot(payloadData)
        .then(() => {
          trackPixelEvent('Update multiple slot');
          setHideBackModal(true);
          setMethod("addToSchedule");
          setAddToScheduleProcess(false);
          setShowModalSuccessForAddMeal(true);
        })
    }
    catch (e) {
      console.log(e);
      setAddToScheduleProcess(false);
    }
  }

  const addToFave = async () => {
    setHideBackModal(true);
    setShowAddToFaveModal(true);
  }

  const handleAddressId = (id: any) => {
    setAddressId(id);
    setAddressIdError(false);
  }

  const handlePaymentId = (id: any) => {
    setPaymentCardId(id);
    setPaymentCardError(false);
  }

  const handleOnDateChange = (e: any) => {
    const { name, value } = e.target;
    setResponseError("");
    setScheduleValues((prevValues) => {
      return {
        ...prevValues,
        [name]: value,
        time: ""
      };
    });
    setRestaurantTime(restaurantOpenAndClosingTime(type === "delivery" ? mealItemData?.attributes?.restaurant?.data?.attributes?.jsonData?.local_hours?.delivery : mealItemData?.attributes?.restaurant?.data?.attributes?.jsonData?.local_hours?.pickup, value, type));
  };

  const timeValuesHandler = (value: any) => {
    setScheduleValues((prevValues) => {
      return {
        ...prevValues,
        time: value,
      };
    });
  };

  const restaurantSchedulesHandler = (data: any) => {
    setRestaurantSchedules(data);
  };

  const orderNow = async () => {
    try {
      setResponseError("");

      if (orderProcess || addToScheduleProcess) { return false; }

      let scheduleTimeFirstIndexId = Object.values(restaurantSchedules);

      let selectedScheduleId = (scheduleValues?.time?.split("@")[1] !== undefined) ? scheduleValues?.time?.split("@")[1] : "";

      const timeDifferences = getTimeDifference(userDeliveryAddress?.timezone, scheduleValues?.time);
      let selectedDate = scheduleValues?.date?.split("T")[0];

      if (type === "delivery" && (addressId === "" || addressId === undefined)) {
        setAddressIdError(true);
        setIonAlertHeader("No Address Found");
        setIonAlertMessage("Please, Add Address.");
        setShowIonAlertModal(true);
        return false;
      }
      else { setAddressIdError(false) }

      if (paymentCardId === "" || paymentCardId === undefined) {
        setPaymentCardError(true);
        setIonAlertHeader("No Payment Method Found!");
        setIonAlertMessage("Please, Add Card.");
        setShowIonAlertModal(true);
        return false;
      }
      else { setPaymentCardError(false) }

      if (scheduleValues?.time?.split("@")[0] === "" || scheduleValues?.time?.split("@")[0] === undefined) { setScheduleTimeError(true); setIonAlertHeader("Please select time."); setShowIonAlertModal(true); }
      else { setScheduleTimeError(false); }

      if (type === "pickup" && storeTipValue === "Other" && otherStoreTipValue === "") {
        setOtherStoreTipError(true);
      } else {
        setOtherStoreTipError(false);
      }

      if (type === "delivery" && driverTipValue === "Other" && otherDriverTipValue === "") {
        setOtherDriverTipError(true);
      } else {
        setOtherDriverTipError(false);
      }

      if (
        (type === "delivery" && (addressId === "" || addressId === undefined || addressIdError)) ||
        (paymentCardId === "" || paymentCardId === undefined) ||
        (scheduleValues?.time?.split("@")[0] === "" || scheduleValues?.time?.split("@")[0] === undefined) ||
        (scheduleValues?.date === "" || scheduleValues?.date === undefined) ||
        (type === "pickup" && (storeTipValue === "Other" && otherStoreTipValue === "") || otherStoreTipError) ||
        (type === "delivery" && (driverTipValue === "Other" && otherDriverTipValue === "") || otherDriverTipError) ||
        paymentCardError || scheduleTimeError
      ) {
        return false;
      }
      setOrderProcess(true);
      const orderData = {
        mealId: mealItemData?.id,
        items: existingOrderItemsFormatter(mealModelData),
        delivery_location: (type === "pickup") ? userDeliveryAddress?.id : addressId,
        newMeal: false,
        mealType: mealItemData?.attributes?.type,
        userId: orderForSomeone?.recipientId ? orderForSomeone?.recipientId : user?.id,
        type: (orderForSomeone?.type !== undefined) ? orderForSomeone?.type : "for_me",
        from_userId: user?.id,
        date: selectedDate,
        time: scheduleValues?.time?.split("@")[0],
        order_status: (selectedDate === timeDifferences?.currentDate && timeDifferences?.differenceInMinutes < 15) ? "ORDERED" : "SCHEDULED",
        recipientName: orderForSomeone?.recipientName,
        recipientNotes: orderForSomeone?.shortNotes,
        sponsoredBy: user?.id,
        paymentCardId: paymentCardId,
        verifiedSlot: (scheduleValues?.time?.split("~")[1] === 'verified') ? true : false,
        orderType: type,
        accepts_pickup_tip: restaurantQuote?.quote?.accepts_pickup_tip || false,
        accepts_delivery_tip: restaurantQuote?.quote?.accepts_delivery_tip || false
      };
      if (type === "pickup") {
        orderData['storeTip'] = storeTipValue === "Other" ? Number(otherStoreTipValue?.slice(1)) : Number(storeTipValue)
      }
      if (type === "delivery") {
        orderData['driverTip'] = driverTipValue === "Other" ? Number(otherDriverTipValue?.slice(1)) : Number(driverTipValue)
        orderData['instructions'] = notes
      }
      const payloadData = {
        data: {
          items: existingOrderItemsFormatter(mealModelData),
          delivery_location: (type === "pickup") ? userDeliveryAddress?.id : addressId,
          paymentCardId: paymentCardId,
          userId: orderForSomeone?.recipientId ? orderForSomeone?.recipientId : user?.id,
          scheduled_id: (Object.keys(restaurantSchedules).length === 0 ? "" : (selectedDate === timeDifferences?.currentDate && timeDifferences?.differenceInMinutes < 15) ? "" : (selectedScheduleId !== "" ? selectedScheduleId?.split("~")[0] : scheduleTimeFirstIndexId[0][0]?.id)),
          store_id: mealItemData?.attributes?.restaurant?.data?.attributes?.mealme_id,
          recipientName: orderForSomeone?.recipientName,
          newMeal: false,
          date: selectedDate,
          time: scheduleValues?.time?.split("@")[0],
          orderType: type,
          accepts_pickup_tip: restaurantQuote?.quote?.accepts_pickup_tip || false,
          accepts_delivery_tip: restaurantQuote?.quote?.accepts_delivery_tip || false
        }
      };
      if (type === "pickup") {
        payloadData.data['storeTip'] = storeTipValue === "Other" ? Number(otherStoreTipValue?.slice(1)) : Number(storeTipValue)
      }
      if (type === "delivery") {
        payloadData.data['driverTip'] = driverTipValue === "Other" ? Number(otherDriverTipValue?.slice(1)) : Number(driverTipValue)
        payloadData.data['instructions'] = notes
      }
      await previewOrder(payloadData)
        .then((res) => {
          trackPixelEvent('Preview order');
          const scheduleResData = res?.data;
          const newData = ({
            ...scheduleResData,
            orderData: orderData
          });
          setScheduleOrderedData(newData);
          setShowFinalizeModal(prev => !prev);
          setShowWarningModal(false);
          setHideBackModal(true);
          setOrderProcess(false);
        })
    }
    catch (e) {
      console.log(e);
      setResponseError(e?.response?.data?.data?.error);
      setOrderProcess(false);
    }
  };

  const orderForSelectedSlots = async () => {
    try {
      setResponseError("");

      if (orderProcess || addToScheduleProcess) { return false; }

      let slotsArray = [];

      let scheduleTimeFirstIndexId = Object.values(restaurantSchedules);

      const filterSlots = deliverableSlots?.filter((el: any) => el?.attributes?.restaurant === "Open");

      filterSlots?.forEach((obj: any) => {
        slotsArray.push({ id: obj.id, date: obj.attributes.date, time: obj.attributes.time, deliveryAddress: obj.attributes?.delivery_location?.data?.id, user_id: obj.attributes?.user?.data?.id });
      });

      if (filterSlots?.length === 0) {
        setIonAlertHeader("Sorry!");
        setIonAlertMessage("None of the slots are deliverable.");
        setShowIonAlertModal(true);
        return false;
      }

      const providedDateTime = mergeSlotDateTime(filterSlots[0]?.attributes?.date, filterSlots[0]?.attributes?.time) // Replace with your provided date and time
      const filteredSchedule = filterRestaurantSchedulesByDateTime(restaurantSchedules, providedDateTime);

      if (type === "delivery" && (addressId === "" || addressId === undefined)) {
        setAddressIdError(true);
        setIonAlertHeader("No Address Found");
        setIonAlertMessage("Please, Add Address.");
        setShowIonAlertModal(true);
        return false;
      }
      else { setAddressIdError(false) }

      if (paymentCardId === "" || paymentCardId === undefined) {
        setPaymentCardError(true);
        setIonAlertHeader("No Payment Method Found!");
        setIonAlertMessage("Please, Add Card.");
        setShowIonAlertModal(true);
        return false;
      }
      else { setPaymentCardError(false) }

      if (type === "pickup" && storeTipValue === "Other" && otherStoreTipValue === "") {
        setOtherStoreTipError(true);
      } else {
        setOtherStoreTipError(false);
      }

      if (type === "delivery" && driverTipValue === "Other" && otherDriverTipValue === "") {
        setOtherDriverTipError(true);
      } else {
        setOtherDriverTipError(false);
      }

      if (
        (type === "delivery" && (addressId === "" || addressId === undefined || addressIdError)) ||
        (paymentCardId === "" || paymentCardId === undefined) ||
        (type === "pickup" && (storeTipValue === "Other" && otherStoreTipValue === "") || otherStoreTipError) ||
        (type === "delivery" && (driverTipValue === "Other" && otherDriverTipValue === "") || otherDriverTipError) ||
        paymentCardError
      ) {
        return false;
      }
      setOrderProcess(true);
      const payloadData = {
        data: {
          items: existingOrderItemsFormatter(mealModelData),
          delivery_location: (type === "pickup") ? userDeliveryAddress?.id : addressId,
          paymentCardId: paymentCardId,
          userId: filterSlots[0]?.attributes?.user?.data?.id,
          store_id: mealItemData?.attributes?.restaurant?.data?.attributes?.mealme_id,
          scheduled_id: (filteredSchedule !== undefined && filteredSchedule.length !== 0) ? filteredSchedule[0]?.id : Object.keys(restaurantSchedules).length > 0 ? scheduleTimeFirstIndexId[0][0]?.id : "",
          // scheduled_id: Object.keys(restaurantSchedules).length === 0 ? "" : scheduleTimeFirstIndexId[0][0]?.id,
          newMeal: false,
          date: filterSlots[0]?.attributes?.date,
          time: filterSlots[0]?.attributes?.time,
          orderType: type,
          accepts_pickup_tip: mealItemData?.attributes?.restaurant?.data?.attributes?.scheduleData?.accepts_pickup_tip || false,
          accepts_delivery_tip: mealItemData?.attributes?.restaurant?.data?.attributes?.scheduleData?.accepts_delivery_tip || false
        }
      };
      if (type === "pickup") {
        payloadData.data['storeTip'] = storeTipValue === "Other" ? Number(otherStoreTipValue?.slice(1)) : Number(storeTipValue)
      }
      if (type === "delivery") {
        payloadData.data['driverTip'] = driverTipValue === "Other" ? Number(otherDriverTipValue?.slice(1)) : Number(driverTipValue)
        payloadData.data['instructions'] = notes
      }
      const orderData = {
        faveMealId: mealItemData?.id,
        newMeal: false,
        user: filterSlots[0]?.attributes?.user?.data?.id,
        sponsoredBy: user?.id,
        slots: slotsArray,
        paymentCardId: paymentCardId,
        addressId: (type === "pickup") ? userDeliveryAddress?.id : addressId,
        orderType: type,
        accepts_pickup_tip: mealItemData?.attributes?.restaurant?.data?.attributes?.scheduleData?.accepts_pickup_tip || false,
        accepts_delivery_tip: mealItemData?.attributes?.restaurant?.data?.attributes?.scheduleData?.accepts_delivery_tip || false,
        calendar_name: filterSlots[0]?.attributes?.schedule?.data?.attributes?.name
      };
      if (type === "pickup") {
        orderData['storeTip'] = storeTipValue === "Other" ? Number(otherStoreTipValue?.slice(1)) : Number(storeTipValue)
      }
      if (type === "delivery") {
        orderData['driverTip'] = driverTipValue === "Other" ? Number(otherDriverTipValue?.slice(1)) : Number(driverTipValue)
        orderData['instructions'] = notes
      }

      await previewOrder(payloadData)
        .then((res) => {
          trackPixelEvent('Preview order');
          const scheduleResData = res?.data;
          const newData = ({
            ...scheduleResData,
            selectedSlots: slotsArray,
            orderData: orderData
          })
          setScheduleOrderedData(newData);
          setShowFinalizeModal(prev => !prev);
          setShowWarningModal(false);
          setHideBackModal(true);
          setOrderProcess(false);
        })
    }
    catch (e) {
      console.log(e);
      setResponseError(e?.response?.data?.data?.error);
      setOrderProcess(false);
      setShowWarningModal(false);
    }
  };

  const handleWarningModal = () => {
    const filterSlots = deliverableSlots?.filter(
      (el: any) => el?.attributes?.restaurant === "Open"
    );
    if (filterSlots?.length === 0) {
      setIonAlertHeader("Sorry!");
      setIonAlertMessage("None of the slots are deliverable.");
      setShowIonAlertModal(true);
      return false;
    } else {
      setShowWarningModal(true);
    }
  };

  const handleInactiveItem = async (id: number) => {
    if (mealModelData?.length < 2) {
      setShowModal(true);
    }
    else {
      handleRemoveInactiveItem(id);
    }
  }

  const handleRemoveInactiveItem = async (inactiveItemId: number) => {
    const type = (pageType === "for-schedule") ? "Fav" : (pageType === "for-schedule-other-fave") ? "Other" : "Order";
    if (mealModelData?.length < 2) {
      await deleteSingleFavItem(mealItemData?.id)
        .then((res) => {
          trackPixelEvent('Delete Fav Meal');
          setMethod("delete");
          dispatch(deleteSingleFavouriteData(mealItemData?.id, type) as any);
          setShowModalSuccessForAddMeal(true);
        })
        .catch((error) => {
          console.error('Error:', error);
        });
    }
    else {
      try {
        const remove_item = mealModelData?.filter((item: objectType) => item?.id === inactiveItemId);
        const new_price = mealItemData?.attributes?.total - Number(sumOfMealInCart(remove_item));
        const filteredArray = mealModelData.filter((item: objectType) => item.id !== inactiveItemId);
        const unmatchedIds = filteredArray.map((item: objectType) => item.id)
        const error = mealItemData?.attributes?.error?.filter((errorObj: objectType) => { return !Object.keys(errorObj).some(key => key.includes(String(inactiveItemId))); });
        const payloadData = {
          data: {
            favItems: unmatchedIds,
            total: Number(new_price).toFixed(2),
            error: error?.length > 0 ? error : null,
            status: error?.length > 0 ? "INACTIVE" : "ACTIVE"
          }
        }
        updateMealCategoryFavItem(payloadData, mealItemData?.id)
          .then((res) => {
            dispatch(updateFavMealItemData(mealItemData?.id, res?.data?.data, type) as any);
            trackPixelEvent('Remove inactive fav item');
            setHideBackModal(true);
            setMethod("remove_item");
            setShowModalSuccessForAddMeal(true);
          })
      }
      catch (e) {
        console.log(e);
      }
    }
  }

  // ************************* Checking Restaurant Open/Closed & Active/Inactive Condition ************************************
  const isClosedStrip = ((mealItemData?.attributes?.restaurant?.data !== null && userDeliveryAddress?.timezone !== null && userDeliveryAddress?.timezone !== undefined) && !isRestaurantOpen(type === "delivery" ? mealItemData?.attributes?.restaurant?.data?.attributes?.jsonData?.local_hours?.delivery : mealItemData?.attributes?.restaurant?.data?.attributes?.jsonData?.local_hours?.pickup, currentDateTime, userDeliveryAddress?.timezone, false)) ? true : false;
  const isInActive = (mealItemData?.attributes?.status === "INACTIVE") ? true : false;

  return (
    <>
      {(props?.showMealModal)
        &&
        (
          <ModalDiv onClick={closeModal}>
            <ContentDiv className={hideBackModal ? "invisible" : ""} isDarkMode={isDarkMode}>
              <BackModalBtn>
                <FontAwesomeIcon
                  icon={faChevronLeft}
                  onClick={() => props?.setShowMealModal((prev: any) => !prev)}
                />
              </BackModalBtn>
              <HeartModalIcon>
                <FontAwesomeIcon
                  icon={faHeart}
                  style={{ fontSize: "30px", color: "#ff000082" }}
                />
              </HeartModalIcon>
              <TextHead1 className="mx-5">{switchSentenceCase("head text", mealItemData?.attributes?.name)}</TextHead1>

              <SubText1 className="mt-1.5">
                {switchSentenceCase("head text", mealItemData?.attributes?.restaurant?.data?.attributes?.name)}
              </SubText1>
              {mealItemData?.attributes?.restaurant?.data !== null &&
                <SubText1 className="!text-red-600">
                  {isInActive ? "Warning: This meal is no longer valid.  Please replace or remove all inactive items." : isClosedStrip ? "(Closed)" : ""}
                </SubText1>
              }
              <TextMealChildren1>
                {switchSentenceCase("head text", mealItemData?.attributes?.restaurant?.data?.attributes?.description)}
              </TextMealChildren1>

              <InitialDiv className="mx-4">
                {/********************  MealItems Section ******************************/}
                <Accordion title="Meal Items" opened={true}>
                  <MealItemsList mealItems={mealItemList} favMealId={mealItemData?.id} restaurantId={mealItemData?.attributes?.restaurant?.data?.attributes?.mealme_id} selectedId={handleInactiveItem} faveMealUser={mealItemData?.attributes?.user?.data?.id} />
                </Accordion>

                <InitialDiv className="mt-4">
                  <Flex>
                    <FontAwesomeIcon icon={faStore} className="text-gray-600 text-s mt-1" />
                    <TextHead3 className="ml-2">{mealItemData?.attributes?.restaurant?.data?.attributes?.name}</TextHead3>
                  </Flex>

                  <InitialDiv className="flex mb-4">
                    <FontAwesomeIcon icon={faCircleInfo} className={(restaurantTime?.openingTime === "") ? "mt-[5px] mr-1 text-red-600" : "mt-[5px] mr-1 text-[#7a7a7aba]"} />
                    <TextHead5 style={{ fontWeight: "500", marginLeft: "4px" }} className={(restaurantTime?.openingTime === "") ? "text-red-600 text-left" : "text-[#7a7a7aba] text-left"}>{restaurantTime?.restaurantAvailability}</TextHead5>
                  </InitialDiv>
                </InitialDiv>

                {/********************  Schedule Section ******************************/}

                {(deliverableSlots?.length === 0) ?
                  <>
                    {/* <FlexBetween> */}
                    <Flex className="mt-4">
                      <TextHead3 className="mb-1">
                        {`${(type === "delivery") ? "Delivery" : "Pickup"}  Date/Time`}
                      </TextHead3>
                    </Flex>
                    <InitialDiv className="text-left text-[#7a7a7aba] flex mb-4">
                      <FontAwesomeIcon icon={faCircleInfo} className="mt-[7px] mr-1" />
                      <TextHead5 style={{ fontWeight: "500", marginLeft: "4px" }}>
                        {`${(type === "delivery") ? "Delivery" : "Pickup"} time is our best estimate. Green indicates confirmed appointments, while black will either be confirmed on the morning of ${(type === "delivery") ? "delivery" : "pickup"}, an hour before, or canceled. The time zone is based on the ${(type === "delivery") ? "delivery" : "pickup"} location.`}
                      </TextHead5>
                    </InitialDiv>
                    {/* </FlexBetween> */}
                    <InitialDiv className="">
                      <div className="flex">
                        <FontAwesomeIcon icon={faCalendarPen} className="mt-1" />
                        <div className="ml-3 mt-1 w-full">
                          <TextHead4 className="flex justify-between">Select Date</TextHead4>
                          <SubText2 className="mt-2">
                            <IonDatetimeButton className="my-2.5" datetime="date" style={{ border: "1px solid #e2e2e2", borderRadius: "8px" }}></IonDatetimeButton>
                            <IonModal keepContentsMounted={true}>
                              <IonDatetime className={isDarkMode ? "calendar-color" : ""} name="date" value={scheduleValues?.date} color={isDarkMode ? "dark" : "success"} onIonChange={(e: any) => handleOnDateChange(e)} min={minDate} showDefaultButtons={true} presentation="date" preferWheel={false} id="date" ></IonDatetime>
                            </IonModal>
                          </SubText2>
                        </div>
                      </div>
                      <div className="flex mt-2">
                        <FontAwesomeIcon icon={faClockThree} className="mt-1" />
                        {(selectedSlots?.length === 0) &&
                          <TimeWidget
                            storeId={mealItemData?.attributes?.restaurant?.data?.attributes?.mealme_id}
                            date={scheduleValues?.date}
                            timezone={userDeliveryAddress?.timezone}
                            openingTime={restaurantTime?.openingTime}
                            closingTime={restaurantTime?.closingTime}
                            timeValuesData={timeValuesHandler}
                            restaurantSchedulesData={restaurantSchedulesHandler}
                            orderType={type}
                            restaurantQuote={setRestaurantQuote}
                          />
                        }
                      </div>
                    </InitialDiv>
                  </>
                  :
                  <InitialDiv>
                    <Flex className="items-center mt-2">
                      <TextHead3 className="mb-1">{`Selected${(deliverableSlots?.length === 1) ? " Slot" : " Slots"}`}
                      </TextHead3>
                    </Flex>
                    {deliverableSlots?.map((item: any, index: any) =>
                      <React.Fragment key={index}>
                        <Flex className="text-left">
                          {/* <input type="checkbox" style={{ height: '14px', marginTop: "8px" }} className="w-8 accent-green-600 cursor-pointer" id={item?.id} checked={item?.attributes?.is_selected} disabled={item?.attributes?.sponsoredBy?.data !== null} name={item?.id} onChange={(e) => handleCheckBox((!(item?.attributes?.is_selected)), item?.id, index)} /> */}
                          <p style={{ fontSize: "17px", margin: "2px 0px" }}>{slotDateTimeFormatter(item?.attributes?.date, item?.attributes?.time)} {""}
                            <span className={(item?.attributes?.restaurant === 'Open') ? "!text-green-600" : "!text-red-600"}>{(item?.attributes?.restaurant === "Open") ? "[Deliverable]" : "[Undeliverable]"}</span>
                            {(item?.attributes?.restaurant === 'Close') &&
                              <ToolTip instructions={item?.attributes?.infoMessage} />
                            }
                          </p>
                        </Flex>
                      </React.Fragment>
                    )}
                  </InitialDiv>
                }
                {/********************  Store Tip Section ******************************/}
                {type === "pickup" &&
                  <InitialDiv className="my-4">
                    <TextHead3 className="text-left">Store Tip</TextHead3>
                    {(selectedSlots?.length === 0 ?
                      restaurantQuote?.quote && (!restaurantQuote?.quote?.hasOwnProperty('error') && !restaurantQuote?.quote?.accepts_pickup_tip) :
                      !mealItemData?.attributes?.restaurant?.data?.attributes?.scheduleData?.accepts_pickup_tip)
                      && (
                        <InitialDiv className="text-left">
                          <FontAwesomeIcon icon={faCircleInfo} className="text-red-500 mt-2 mr-1" />
                          <span
                            style={{
                              fontWeight: "350",
                              color: "#dc2626",
                              fontSize: "17px",
                            }}
                          >
                            Store tip is not available for this restaurant.
                          </span>
                        </InitialDiv>
                      )}
                    <TabsForTipPreference
                      types={Tips}
                      setTipValue={setStoreTipValue}
                      setActive={setStoreTipActive}
                      selected={storeTipActive}
                      disabled={(selectedSlots?.length > 0) ? !mealItemData?.attributes?.restaurant?.data?.attributes?.scheduleData?.accepts_pickup_tip : (restaurantQuote?.quote ? false : true) || (!restaurantQuote?.quote?.hasOwnProperty('error') && !restaurantQuote?.quote?.accepts_pickup_tip)}
                    />
                    {(storeTipValue === "Other")
                      ?
                      <InputText
                        type="text"
                        inputMode="numeric"
                        style={{
                          border: otherStoreTipError ? "1px solid red" : "",
                        }}
                        min={0}
                        value={otherStoreTipValue}
                        disabled={(selectedSlots?.length > 0) ? !mealItemData?.attributes?.restaurant?.data?.attributes?.scheduleData?.accepts_pickup_tip : (restaurantQuote?.quote ? false : true) || (!restaurantQuote?.quote?.hasOwnProperty('error') && !restaurantQuote?.quote?.accepts_pickup_tip)}
                        placeholder="Enter Amount in Cents"
                        onChange={(event: any) => handleOtherStoreTip(event.target.value)}
                      />
                      :
                      null
                    }
                  </InitialDiv>
                }

                {/********************  Driver's Tip Section ******************************/}
                {type === "delivery" &&
                  <InitialDiv className="my-4">
                    <TextHead3 className="text-left">Driver's Tip</TextHead3>
                    {(selectedSlots?.length === 0) ?
                      restaurantQuote?.quote && (!restaurantQuote?.quote?.hasOwnProperty('error') && !restaurantQuote?.quote?.accepts_delivery_tip) :
                      !mealItemData?.attributes?.restaurant?.data?.attributes?.scheduleData?.accepts_delivery_tip
                      && (
                        <InitialDiv className="text-left">
                          <FontAwesomeIcon icon={faCircleInfo} className="text-red-500 mt-2 mr-1" />
                          <span
                            style={{
                              fontWeight: "350",
                              color: "#dc2626",
                              fontSize: "17px",
                            }}
                          >
                            Driver tip is not available for this restaurant.
                          </span>
                        </InitialDiv>
                      )}
                    <TabsForTipPreference
                      types={Tips}
                      setTipValue={setDriverTipValue}
                      setActive={setDriverTipActive}
                      selected={driverTipActive}
                      disabled={(selectedSlots?.length > 0) ? !mealItemData?.attributes?.restaurant?.data?.attributes?.scheduleData?.accepts_delivery_tip : (restaurantQuote?.quote ? false : true) || (!restaurantQuote?.quote?.hasOwnProperty('error') && !restaurantQuote?.quote?.accepts_delivery_tip)}
                    />
                    {(driverTipValue === "Other")
                      ?
                      <InputText
                        type="text"
                        inputMode="numeric"
                        style={{
                          border: otherDriverTipError ? "1px solid red" : "",
                        }}
                        min={0}
                        value={otherDriverTipValue}
                        disabled={(selectedSlots?.length > 0) ? !mealItemData?.attributes?.restaurant?.data?.attributes?.scheduleData?.accepts_delivery_tip : (restaurantQuote?.quote ? false : true) || (!restaurantQuote?.quote?.hasOwnProperty('error') && !restaurantQuote?.quote?.accepts_delivery_tip)}
                        placeholder="Enter Amount"
                        onChange={(event: any) => handleOtherDriverTip(event.target.value)}
                      />
                      :
                      null
                    }
                  </InitialDiv>
                }

                {/********************  Address Section ******************************/}
                {type === "delivery" &&
                  <Accordion title="Delivery Address" opened={false}>
                    <AddressesCart title="" addressId={handleAddressId} showDeleteBtn={false} showList={false} pageName="newMealCheckout" pageDataId={orderForSomeone?.addressId ? orderForSomeone?.addressId : userDeliveryAddress?.id} changeAddressTxtBtn="CHANGE ADDRESS" userId={orderForSomeone?.recipientId ? orderForSomeone?.recipientId : user?.id} setDeliveryAddress={false} changeAddress={(selectedSlots?.length > 0) ? false : true} initialLength={1} />
                    {addressIdError && <TextHead5 style={{ color: "red" }}>
                      Address is required.
                    </TextHead5>}
                  </Accordion>
                }

                {/********************  Payment Section Section ******************************/}
                <Accordion title="Payment" opened={(paymentCardId === "" || paymentCardId === undefined) ? true : false}>
                  <PaymentCart paymentId={handlePaymentId} userId={user?.id} changePayment={true} selectedCard={null} />
                  {paymentCardError && <TextHead5 style={{ color: "red" }}>
                    Payment is required.
                  </TextHead5>}
                </Accordion>

                {/********************  Notes Section ******************************/}
                {type === "delivery" &&
                  <InitialDiv className="mt-8">
                    <TextHead3 className="text-left">Drop Off Notes</TextHead3>
                    <SubText1 className="text-right mt-1">{notes?.length}/{maxCharacterLimit}</SubText1>
                    <TextArea1
                      className="!mt-0"
                      placeholder=""
                      name="description"
                      value={notes}
                      onChange={(event: any) => setNotes(event.target.value)}
                      maxLength={maxCharacterLimit}
                    />
                  </InitialDiv>
                }

                <FlexBetween className="my-2">
                  <TextHead4 className="!font-medium whitespace-nowrap">Subtotal</TextHead4>
                  <TextHead4 className="!font-medium whitespace-nowrap">${sumOfMealInCart(mealModelData)}</TextHead4>
                </FlexBetween>
                {/* <Divider /> */}
              </InitialDiv>

              <InitialDiv className="mx-4">
                {/* {(showNav && (mealItemData?.attributes?.type !== "Order")) &&
                  <ButtonGreen
                    onClick={() => addToSchedule()}
                    style={{ margin: "5px 0px" }}
                  >
                    {switchSentenceCase("primary button", "ADD TO SCHEDULE")}
                  </ButtonGreen>
                } */}
                {/* {(mealItemData?.attributes?.type === "Order") &&
                  <> */}
                {orderProcess && !showWarningModal
                  ?
                  <div className="text-center">
                    <Spinner />
                  </div>
                  :
                  <>
                    {(deliverableSlots?.length === 0) ?
                      <ButtonLimeGreen
                        className={(orderProcess || addToScheduleProcess || disable_buttons) ? "!cursor-not-allowed opacity-30" : ""}
                        disabled={orderProcess || addToScheduleProcess || disable_buttons}
                        style={{ margin: "5px 0px" }}
                        onClick={() => orderNow()}
                      >
                        {switchSentenceCase("primary button", "CONTINUE TO PREVIEW ORDER")}
                      </ButtonLimeGreen>
                      :
                      <ButtonLimeGreen
                        className={(orderProcess || addToScheduleProcess || disable_buttons) ? "!cursor-not-allowed opacity-30" : ""}
                        disabled={orderProcess || addToScheduleProcess || disable_buttons}
                        style={{ margin: "5px 0px" }}
                        onClick={() => orderForSelectedSlots()}
                      >
                        {switchSentenceCase("primary button", "CONTINUE TO PREVIEW ORDER")}
                      </ButtonLimeGreen>
                    }
                  </>
                }
                {responseError && <p className="text-red-600 text-center">{(responseError === "Scheduled id is not valid or not available.") ? "Something went wrong. Please retry or 'Contact Us' in your profile." : responseError}</p>}
                {(scheduleDetails?.attributes) ?
                  (user?.id === scheduleDetails?.attributes?.user?.data?.id) &&
                  <>
                    <ButtonGreen
                      onClick={() => handleWarningModal()}
                      disabled={(orderProcess || addToScheduleProcess || disable_buttons)}
                      className={(orderProcess || addToScheduleProcess || disable_buttons) ? "!cursor-not-allowed my-2 opacity-30" : "my-2"}
                      title="Use this if you want to authorize payment later, or the order will cancel."
                    >
                      {switchSentenceCase("primary button", "Save and Come Back Later")}
                    </ButtonGreen>
                    <InitialDiv className="text-left text-[#7a7a7aba] flex items-baseline">
                      <FontAwesomeIcon icon={faCircleInfo} className="mr-2 text-[12px]" />
                      <TextHead5 className="font-normal">Save and Come Back Later:  Use this if you want to authorize payment later, or the order will cancel.</TextHead5>
                    </InitialDiv>
                  </>
                  :
                  <ButtonGreen
                    className={(orderProcess || disable_buttons) ? "!cursor-not-allowed my-2 opacity-30" : "my-2"}
                    disabled={(orderProcess || disable_buttons)}
                    onClick={() => addToFave()}
                  >
                    {switchSentenceCase("primary button", "ADD TO FAVE")}
                  </ButtonGreen>
                }
                {/* </>
                } */}
              </InitialDiv>
            </ContentDiv>
          </ModalDiv >
        )
      }
      {showAddToFaveModal &&
        <ModalAddOrderToFave
          showAddToFaveModal={showAddToFaveModal}
          setShowAddToFaveModal={setShowAddToFaveModal}
          mealItemData={favMealObj}
          setHideModal={setHideBackModal}
          firstModal={props?.setShowMealModal}
          destinationRoute={"/faves"}
        />
      }

      {/* *********************Modal Finalize Order****************************** */}
      {showFinalizeModal &&
        < ModalFinalizeOrder
          showFinalizeModal={showFinalizeModal}
          setShowFinalizeModal={setShowFinalizeModal}
          finalizeData={scheduleOrderedData}
          showBackModal={props?.setShowMealModal}
          setHideBackModal={setHideBackModal}
        />}

      {/* ********************* Modal Confirmation ****************************** */}

      {showWarningModal && (
        <ModalSuccessForCards
          firstText={
            "To schedule the delivery, you or a sponsor will need to finalize the order at least 1 hour prior to the scheduled delivery time."
          }
          secondText=""
          btnOneText="I understand"
          btnTwoText="Let’s finalize the order now"
          btnOneStyle={{ marginTop: "15px" }}
          btnTwoStyle={{ backgroundColor: "#b3b3b3", marginTop: "15px", color: "#ffffff" }}
          btnOneGoto={() => {
            addToSchedule();
          }}
          btnTwoGoto={() => {
            orderForSelectedSlots();
          }}
          showModal={showWarningModal}
          setShowModal={setShowWarningModal}
          buttonOneLoader={addToScheduleProcess}
          buttonTwoLoader={orderProcess}
          buttonOneDisabled={orderProcess || addToScheduleProcess}
          buttonTwoDisabled={orderProcess || addToScheduleProcess}
        />
      )}

      {/* ---------------------------------Modal Success for Add Meal to schedule------------------- */}
      <ModalSuccess
        contentDivStyle={{ height: "auto" }}
        firstText={(method === "addToSchedule") ? "Meal Added to Schedule Successfully." : (method === "delete") ? "Fav Meal is removed Successfully." : (method === "remove_item") ? "Item  Removed Successfully" : "You Place the Order Successfully"}
        btnOneText="OKAY"
        btnOneGoto={() => {
          setShowModalSuccessForAddMeal((prev: any) => !prev);
          props?.setShowMealModal((prev: any) => !prev);
          (method === "addToSchedule") && history.push(`/calendar-slots/${scheduleDetails?.id}`)
        }}
        showModal={showModalSuccessForAddMeal}
        setShowModal={setShowModalSuccessForAddMeal}
      />

      {/* ********************* Alert Modal for validations ****************************** */}
      <IonModalAlert
        isOpened={showIonAlertModal}
        setIsOpened={setShowIonAlertModal}
        header={ionAlertHeader}
        subHeader=""
        message={ionAlertMessage}
      />

      <ModalRemoved
        firstText="This will remove your fave meal and any associated orders will be canceled."
        secondText="Do you want to delete this fave meal and any associated orders?"
        btnOneText="NO"
        btnTwoText="YES"
        showModal={showModal}
        setShowModal={setShowModal}
        id={mealItemData?.id}
        deleteItem={handleRemoveInactiveItem}
      />
    </>
  );
}



