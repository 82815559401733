import { useState, useEffect } from "react";
import Divider from "../../components/widgets/divider/Divider";
import Section from "../../components/widgets/section/Section";
import PreferredMealList from "../orderFood/PreferredMealList";
import PreferredMealGrid from "../orderFood/PreferredMealGrid";
import styled from 'styled-components';
import BothSideTextToggle from "../../components/widgets/buttons/BothSideTextToggle";
import AccordianCartegoryChild from "../../components/widgets/accordian/AccordianCartegoryChild";
import Accordion, { SubAccordion } from "../../components/widgets/accordian/AccordionStyled";
import TopHead from "../../components/widgets/head/TopHead";
import { faAngleLeft } from "@fortawesome/pro-solid-svg-icons";
import { useSelector, useDispatch } from "react-redux";
import { switchSentenceCase, getFoodDateTimeFormatter, mergeSlotDateTime, isAuthenticated, trackPixelEvent, trackStandardPixelEvent } from "../../utils/utilities";
import { getUserFavoriteMeal } from "../../services/favourite";
import { getUserInfo } from "../../services/user";
import Spinner from "../../components/widgets/spinner/Spinner";
import AddressList from "../../components/widgets/address/AddressList";
import { SubText1, TextHead4 } from "../../components/styled/styled";
import RestaurantCardGrid from "../../components/widgets/cards/RestaurantCardGrid";
import { restaurantsNearPlace } from "../../services/restaurant";
import { clearRestaurantList, setNearByScheduleRestaurants, setRestaurantsListLoading } from "../search/redux/searchActionCreator";
import RestaurantCardList from "../../components/widgets/cards/RestaurantCardList";
import { setOtherFavMeals, setRecentOrderList, setUserFaveMealList } from "../faves/redux/favouriteActionCreator";
import { useHistory, useLocation } from "react-router";
import SearchRestaurantsByKeywords from "../../components/widgets/searchinput/SearchRestaurantsByKeywords";
import { useIonViewDidEnter } from "@ionic/react";
import { objectType } from "../../type";

const H1 = styled.h1`
  font-size: 16px;
    font-weight: 500;
    position: relative;
    top: -48px;
    /* right: 25px; */
    text-align: right;
    @media (max-width: 1024px) {
    top: -15px;
    margin: 10px 30px;
    text-align: center;
  }
`;

export default function ScheduleSlotFill(
) {

  const dispatch = useDispatch();
  const currentDateTime = new Date();
  const userData = getUserInfo();
  const history = useHistory();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const type = searchParams.get('type');

  const [switchState, setSwitchState] = useState(true);
  const { userFaveMealsList, otherFavMealList, recentOrderList, mealCategory } = useSelector((state: any) => state.getAllFavData);
  const { nearByScheduleRestaurants } = useSelector((state: any) => state.getUserRestaurantsLists)
  const restaurantData = nearByScheduleRestaurants?.slice(0, 10)
  const { selectedSlots, scheduleDetails } = useSelector((state: any) => state?.createScheduleForMe);
  const [nearbyRestaurantLoading, setNearbyRestaurantLoading] = useState(true);
  const [preferredMealLoading, setPreferredMealLoading] = useState(true);
  const [otherMealLoading, setOtherMealLoading] = useState(true);
  const [recentOrderLoading, setRecentOrderLoading] = useState(true);

  const address = scheduleDetails?.attributes?.delivery_location?.data?.attributes;
  const formattedAddress = (address?.city) ? ((address?.addressLine2 ? address?.addressLine2 + ", " : "") + address?.addressLine1 + ", " + address?.city + ", " + address?.state + " - " + address?.zip_code) : "";

  useEffect(() => {
    callData();
    searchFunction();
  }, [address]);

  useIonViewDidEnter(() => {
    trackPixelEvent('Schedule slot fill');
  }, []);


  const callData = async () => {
    if (isAuthenticated() === false) return false;
    if (address?.city === undefined || address?.city === "") {
      setPreferredMealLoading(false);
      setOtherMealLoading(false);
      setRecentOrderLoading(false);
      return false;
    }
    let mealCategoriesId = [];
    scheduleDetails?.attributes?.meal_categories?.data?.forEach((currentObj: objectType) => {
      mealCategoriesId.push(currentObj?.id);
    })
    const filterMealCategory = mealCategory.filter((obj: objectType) => !mealCategoriesId.includes(obj?.id)).map((obj: objectType) => obj.id);

    let queryForPreferredMeal = {
      id: scheduleDetails?.attributes?.user?.data?.id,
      type: "Fav",
      public_meal: (scheduleDetails?.attributes?.user?.data?.id !== userData?.id) ? true : "All",
      suggested: (scheduleDetails?.attributes?.user?.data?.id !== userData?.id) ? true : "All",
      skip: 0,
      limit: 10,
      category: mealCategoriesId,
      lat: address?.latitude,
      lang: address?.longitude,
      order_type: type
    }
    try {
      const _preferredList = await getUserFavoriteMeal(queryForPreferredMeal);
      trackPixelEvent('Get user fave meal');
      setUserFaveMealList(_preferredList?.data);
      setPreferredMealLoading(false);

      const queryForOtherMeal = {
        id: scheduleDetails?.attributes?.user?.data?.id,
        type: "Fav",
        public_meal: (scheduleDetails?.attributes?.user?.data?.id !== userData?.id) ? true : "All",
        suggested: (scheduleDetails?.attributes?.user?.data?.id !== userData?.id) ? true : "All",
        skip: 0,
        limit: 10,
        category: filterMealCategory,
        lat: address?.latitude,
        lang: address?.longitude,
        order_type: type
      }
      const _otherList = await getUserFavoriteMeal(queryForOtherMeal);
      trackPixelEvent('Get user fave meal');
      setOtherFavMeals(_otherList?.data);
      setOtherMealLoading(false);

      const _orderList = await getUserFavoriteMeal({
        id: userData?.id,
        type: "Order",
        skip: 0,
        limit: 10,
        lat: address?.latitude,
        lang: address?.longitude,
        order_type: type
      });
      trackPixelEvent('Get user fave meal');
      setRecentOrderList(_orderList?.data);
      setRecentOrderLoading(false);
    }
    catch (e) {
      console.log(e)
    }
  };

  function getSelectedSlotDateTime() {
    const filteredSlots = selectedSlots.filter((items: any) => new Date(mergeSlotDateTime(items?.attributes?.date, items?.attributes?.time)) > currentDateTime).sort(compareDateTime)
    if (filteredSlots?.length > 1) {
      const value = getFoodDateTimeFormatter(filteredSlots[0], filteredSlots[filteredSlots.length - 1]);
      return `${value}`
    }
    else {
      const value = getFoodDateTimeFormatter(filteredSlots[0], filteredSlots[0])
      return `${value}`;
    }
  };

  const compareDateTime = (a: any, b: any) => {
    var keyA = new Date(mergeSlotDateTime(a?.attributes?.date, a?.attributes?.time)),
      keyB = new Date(mergeSlotDateTime(b?.attributes?.date, b?.attributes?.time));
    // Compare the 2 dates
    if (keyA < keyB) return -1;
    if (keyA > keyB) return 1;
    return 0;
  };

  function handleOnChangeToggle() {
    setSwitchState(switchState === false);
  };

  const searchFunction = async () => {
    setNearbyRestaurantLoading(true);
    if (address?.city === "" || address?.city === undefined) {
      setNearbyRestaurantLoading(false);
      return false
    }
    try {
      const placeObject = {
        lat: address.latitude,
        lang: address?.longitude,
        streetName: address?.addressLine1,
        streetNum: address?.addressLine2,
        streetAddress: address?.street_address,
        city: address?.city,
        state: address?.state,
        country: address?.country,
        zip: address?.zip_code,
        limit: scheduleDetails?.attributes?.limit_per_meal,
        orderType: type
      };

      const response = await restaurantsNearPlace(placeObject);
      if (response) {
        trackStandardPixelEvent('Search');
        const restaurantData = response?.data?.data?.stores;

        // -----------Filtering for Duplicate Data---------------
        // const filteredData = Array?.from(
        //   new Set(restaurantData?.map((a: any) => a?.name))
        // ).map((name: any) => {
        //   return restaurantData?.find((a: any) =>
        //     String(name)?.toLowerCase()?.includes(String(a?.name)?.toLowerCase())
        //   );
        // });

        dispatch(setRestaurantsListLoading(false) as any);
        setNearByScheduleRestaurants(restaurantData);
        setNearbyRestaurantLoading(false);
      }
    }
    catch (error) {
      dispatch(setRestaurantsListLoading(false) as any);
      setNearbyRestaurantLoading(false);
      console.log(error);
    }
  };

  return (
    <div className="screen">
      <div className="d-flex">
        <TopHead
          title={switchSentenceCase("head text", scheduleDetails?.attributes?.name)}
          leftTextIcon={faAngleLeft}
          leftIconStyle={{ fontSize: "24px" }}
          rightText={true}
        />
        <H1>
          {getSelectedSlotDateTime()}
        </H1>
      </div>
      <Divider />
      <div className="screen overflow-auto">
        {/* <Divider /> */}
        <Section>
          <AddressList userAddress={formattedAddress} isAddressAvailable={true} selectedId={scheduleDetails?.attributes?.delivery_location?.data?.id} userId={scheduleDetails?.attributes?.user?.data?.id} showList={false} type={type} />

          <Accordion title="Search">
            <SearchRestaurantsByKeywords pageRoute={`?for=get-food-now&type=${type}`} goToSearch={true} type={type} />
          </Accordion>

          <BothSideTextToggle leftText="List" rightText="Grid" switchState={switchState} handleOnChangeToggle={handleOnChangeToggle} />
          {
            switchState === false
              ? <>
                <Accordion title={`${switchSentenceCase("head text", scheduleDetails?.attributes?.name)} Preferred Meals`}>
                  {preferredMealLoading && (
                    <div className="flex items-center justify-center">
                      <Spinner />
                    </div>
                  )}
                  {!preferredMealLoading && (
                    <>
                      {(userFaveMealsList?.length > 0) ?
                        <>
                          <PreferredMealList
                            // header={null} 
                            data={userFaveMealsList}
                            type={type}
                          />
                          <TextHead4 className="flex justify-center text-center text-green-600 mt-2"
                            onClick={() => history.push(`/ordersallitems/for-schedule?type=${type}`)}
                          >
                            <div className="cursor-pointer w-[80px]">See all</div>
                          </TextHead4>
                        </>
                        :
                        <SubText1 className="text-center !text-gray-400 !text-base mb-10">
                          There are no preferred meals for this meal schedule.
                        </SubText1>
                      }
                    </>
                  )}
                </Accordion>
                <>
                  <Accordion title="Nearby Restaurants">
                    {nearbyRestaurantLoading && (
                      <div className="flex items-center justify-center">
                        <Spinner />
                      </div>
                    )}

                    {!nearbyRestaurantLoading && (
                      <>
                        {(nearByScheduleRestaurants?.length > 0) ?
                          <>
                            <RestaurantCardList
                              data={restaurantData}
                              page="for-order"
                              type={type}
                            />
                            <TextHead4 className="flex justify-center text-center text-green-600 mt-2"
                              onClick={() => {
                                history.push(`/restaurantallitems/for-schedule?type=${type}`);
                                clearRestaurantList();
                              }}>
                              <div className="cursor-pointer w-[80px]">See all</div></TextHead4>
                          </>
                          :
                          <SubText1 className="text-center !text-gray-400 !text-base mb-10">
                            No restaurants are within the distance and search criteria.
                          </SubText1>
                        }
                      </>
                    )}

                  </Accordion>
                </>
                <Accordion title="Other Fave Meals">
                  {otherMealLoading && (
                    <div className="flex items-center justify-center">
                      <Spinner />
                    </div>
                  )}
                  {!otherMealLoading && (
                    <>
                      {(otherFavMealList?.length > 0) ?
                        <>
                          <PreferredMealList
                            // header={null} 
                            data={otherFavMealList}
                            type={type}
                          />
                          <TextHead4 className="flex justify-center text-center text-green-600 mt-2"
                            onClick={() => history.push(`/ordersallitems/for-schedule-other-fave?type=${type}`)}
                          >
                            <div className="cursor-pointer w-[80px]">See all</div>
                          </TextHead4>
                        </>
                        :
                        <SubText1 className="text-center !text-gray-400 !text-base mb-10">
                          {`There are no meal faves for this ${(type === "delivery") ? "delivery" : ""} address.`}
                        </SubText1>
                      }
                    </>
                  )}
                </Accordion>
              </>
              :
              <>
                <AccordianCartegoryChild text={{ categoryName: `${switchSentenceCase("head text", scheduleDetails?.attributes?.name)} Preferred Meals`, seeMore: (!preferredMealLoading && userFaveMealsList?.length > 0) ? 'See all' : "" }}
                  handleOnClick={() => history.push(`/ordersallitems/for-schedule?type=${type}`)}
                />

                {preferredMealLoading && (
                  <div className="flex items-center justify-center">
                    <Spinner />
                  </div>
                )}
                {!preferredMealLoading && (
                  <>
                    {(userFaveMealsList?.length > 0) ?
                      <PreferredMealGrid
                        // header={null} 
                        data={userFaveMealsList}
                        type={type}
                      />
                      :
                      <SubText1 className="text-center !text-gray-400 !text-base mb-10">
                        There are no preferred meals for this meal schedule.
                      </SubText1>
                    }
                  </>
                )}
                <>
                  <AccordianCartegoryChild text={{ categoryName: 'Nearby Restaurants', seeMore: (!nearbyRestaurantLoading && nearByScheduleRestaurants?.length > 0) ? 'See all' : '', }}
                    handleOnClick={() => {
                      history.push(`/restaurantallitems/for-schedule?type=${type}`);
                      clearRestaurantList();
                    }} />

                  {nearbyRestaurantLoading && (
                    <div className="flex items-center justify-center">
                      <Spinner />
                    </div>
                  )}

                  {!nearbyRestaurantLoading && (
                    <>
                      {(nearByScheduleRestaurants?.length > 0) ?
                        <RestaurantCardGrid
                          data={restaurantData}
                          page="for-order"
                          type={type}
                        />
                        :
                        <SubText1 className="text-center !text-gray-400 !text-base mb-10">
                          No restaurants are within the distance and search criteria.
                        </SubText1>
                      }
                    </>
                  )}
                </>

                <AccordianCartegoryChild text={{ categoryName: "Other Fave Meals", seeMore: (!otherMealLoading && otherFavMealList?.length > 0) ? 'See all' : '' }}
                  handleOnClick={() => history.push(`/ordersallitems/for-schedule-other-fave?type=${type}`)} />
                {otherMealLoading && (
                  <div className="flex items-center justify-center">
                    <Spinner />
                  </div>
                )}
                {!otherMealLoading && (
                  <>
                    {(otherFavMealList?.length > 0) ?
                      <PreferredMealGrid
                        // header={null} 
                        data={otherFavMealList}
                        type={type}
                      />
                      :
                      <SubText1 className="text-center !text-gray-400 !text-base mb-10">
                        {`There are no meal faves for this ${(type === "delivery") ? "delivery" : ""} address.`}
                      </SubText1>
                    }
                  </>
                )}
              </>
          }
          <Accordion title="Suggested Fave Meals">
            <Divider />
            {
              switchState === false
                ?
                <>
                  <SubAccordion title="Recent Orders to Fave">
                    {recentOrderLoading && (
                      <div className="flex items-center justify-center">
                        <Spinner />
                      </div>
                    )}
                    {!recentOrderLoading && (
                      <>
                        {(recentOrderList?.length > 0) ?
                          <>
                            < PreferredMealList
                              // header={null}
                              data={recentOrderList}
                              type={type}
                            />
                            <TextHead4 className="flex justify-center text-center text-green-600 mt-2"
                              onClick={() => history.push(`/ordersallitems/for-schedule-section?type=${type}`)}
                            >
                              <div className="cursor-pointer w-[80px]">See all</div>
                            </TextHead4>
                          </>
                          :
                          <SubText1 className="text-center !text-gray-400 !text-base mb-10">
                            {`There are no recent orders for this ${(type === "delivery") ? "delivery" : ""} address.`}
                          </SubText1>
                        }
                      </>
                    )}
                  </SubAccordion>
                </>
                :
                <>
                  <AccordianCartegoryChild text={{ categoryName: 'Recent Orders To Fave', seeMore: (!recentOrderLoading && recentOrderList?.length > 0) ? 'See all' : '' }}
                    handleOnClick={() => history.push(`/ordersallitems/for-schedule-section?type=${type}`)} />
                  {recentOrderLoading && (
                    <div className="flex items-center justify-center">
                      <Spinner />
                    </div>
                  )}
                  {!recentOrderLoading && (
                    <>
                      {(recentOrderList?.length > 0) ?
                        <PreferredMealGrid
                          //  header={null} 
                          data={recentOrderList}
                          type={type}
                        />
                        :
                        <SubText1 className="text-center !text-gray-400 !text-base mb-10">
                          {`There are no recent orders for this ${(type === "delivery") ? "delivery" : ""} address.`}
                        </SubText1>
                      }
                    </>
                  )}
                </>
            }
          </Accordion>
        </Section>
      </div>
    </div >
  );
}

