const initialValue = {
    scheduleSlug: "",
    invitationToken: "",
    userSlug: "",
};

const scheduleInvitationState = (state = initialValue, action: any) => {

    switch (action?.type) {

        case "INVITED_MEMBER_LOGIN":
            return { ...state, scheduleSlug: action.payload.schedule, invitationToken: action.payload.invite };


        case "SET_LOGIN_STATE":
            return { ...state, scheduleSlug: action.payload.schedule, userSlug: action.payload.user };

        case "CLEAR_INVITATION_STATE":
            return {
                scheduleSlug: "", invitationToken: "", userSlug: ""
            };
        default:
            return state;
    }
};

export default scheduleInvitationState;