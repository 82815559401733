import { useParams } from 'react-router';
import styled from 'styled-components';
import { faAngleLeft, faAngleRight, faHeart } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useIonAlert, useIonRouter } from '@ionic/react';
import { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { A11y, FreeMode, Navigation, Pagination, Scrollbar } from 'swiper';
import 'swiper/css';
import { Swiper, SwiperSlide } from 'swiper/react';
import sampleMealImage from "../../components/assets/restaurent-Meal-3.jpg";
import { ClosedStripDiv, SubText1 } from '../../components/styled/styled';
import ModalMealClicked from '../../components/widgets/modals/ModalMealClicked';
import ModalRemoved from '../../components/widgets/modals/ModalRemoved';
import { preventAndStop } from '../../components/widgets/preventandstop/preventAndStop';
import { countryCurrency, isRestaurantOpen, properNameFormate, sumOfMealInCart, switchSentenceCase } from '../../utils/utilities';
import { deleteSingleFavouriteData, setFaveModalStatus } from '../faves/redux/favouriteActionCreator';
import ModalSuccess from '../../components/widgets/modals/ModalSuccess';
import { updateMealCategoryFavItem } from "../../services/favourite";
import ModalChooseOption from '../../components/widgets/modals/ModalChooseOption';
import { objectType } from '../../type';

export const PageWrapper = styled.div`
  width: 100%;
  min-width: 343px;
`;

export const Container = styled.div`
  width: 100%;
  border-radius: 0.75rem;
  background: ${({ theme }) => theme.background};
  padding: 4px 20px 28px;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    max-width: 480px;
  `};
  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 0 16px 24px;
  `};
`
export const BodyWrapper = styled.div`
display:flex;
overflow-x: scroll;
::-webkit-scrollbar {
  display: none;
}
`;


export const ButtonRemove = styled.button`
width:140px;
color:#979797;
border: 1px solid #9797976b;
background: #9797971f;
border-radius: 25px;
font-size: 14px;
font-weight: 500;
padding: 2px;
padding-bottom: 5px;
`;


export const ButtonAdd = styled.button`
width:140px;
color: white;
border: 1px solid #9797976b;
background: #22A45D;
border-radius: 25px;
font-size: 14px;
font-weight: 500;
padding: 2px;
padding-bottom: 5px;
`;


export const Block = styled.div`
height: 245px;
display: flex;
flex-direction: column;
justify-content: space-around;
margin: 0px 7px;
`;

const HeartModalIcons = styled.div`
    width:27px;
    height:27px;
    background: #f03f3f29;
    border-radius: 50%;
    text-align: center;
    font-size: 14px ;
    color: #ff000082;
    display:flex;
    justify-content:center;
    align-items: center;
    margin-bottom: "5px";
`;


const MealName = styled.h3`
overflow: hidden;
text-overflow: ellipsis;
display: -webkit-box;
-webkit-line-clamp: 1;
-webkit-box-orient: vertical;
`;

const ButtonArrowLeft = styled.button`
border-radius: 38px;
    font-size: 22px;
    position: relative;
    bottom: 40px;
    z-index: 10;
    color: #22ae62;
`;

const ButtonArrowRight = styled.button`
    border-radius: 38px;
    font-size: 22px;
    margin-left: 5px;
    position: relative;
    bottom: 48px;
    z-index: 10;
    color: #22ae62;
`;

export default function FavoriteMealGrid(props: any) {

  // ******************************* Other Methods *******************************************************
  const router = useIonRouter();
  const dispatch = useDispatch();
  const currentDateTime = new Date();
  const { userId, uid } = useParams<any>();
  const [presentAlert] = useIonAlert();
  const showAlert = (header: string, message: string, btnMessage: string) => {
    presentAlert({
      header: header,
      message: message,
      buttons: [btnMessage],
    });
  };
  const showNav = (router.routeInfo.pathname === '/scheduleslotfill' || router.routeInfo.pathname === '/getfoodnow' || router.routeInfo.pathname === `/friends-profile/${userId}/${uid}`) ? true : false;


  // ******************************* States Management *******************************************************
  const { userFaveMealsList } = useSelector((state: any) => state.getAllFavData);
  const { userDeliveryAddress } = useSelector((state: any) => state.userAddresses);


  // ******************************* Hooks *******************************************************
  const [swipe, setSwipe] = useState<any>();
  const [showMealModal, setShowMealModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [mealItems, setMealItems] = useState<any>({});
  const [showModalSuccess, setShowModalSuccess] = useState(false);
  const [showOptionModal, setShowOptionModal] = useState(false);


  // ******************************* Functions *******************************************************
  const openMealModal = (mealItems: objectType) => {
    setShowMealModal(prev => !prev);
    setMealItems(mealItems);
    setFaveModalStatus(true);
  };

  const openModal = (mealItems: objectType) => {
    setShowModal(prev => !prev)
    setMealItems(mealItems);
  };

  const unfavoriteItem = async () => {
    if (userFaveMealsList?.user == null || mealItems?.id == null) {
      return;
    }
  }

  const deleteMeal = (ids: any) => {
    setShowMealModal(false)
    setShowModal(false);
    setShowModalSuccess(true);
    setFaveModalStatus(false);
  }

  const onClickOrder = (meal: objectType) => {
    if (router.routeInfo.pathname === `/friends-profile/${userId}/${uid}`) {
      setShowOptionModal(true);
      setMealItems(meal);
    }
    else {
      openMealModal(meal)
    }
  }

  const handleSubmit = async (value: string) => {
    if (value === "") {
      return showAlert('', 'Please select any one option.', 'OK');
    }
    else {
      setShowOptionModal(false);
      openMealModal(mealItems)
    }
  }

  const dispatchEvent = (id: Number) => {
    setShowModalSuccess(false);
    const updatedData = {
      data: {
        type: null,
      }
    }
    updateMealCategoryFavItem(updatedData, id)
      .then(async (res) => {
        dispatch(deleteSingleFavouriteData(id, "Fav") as any)
      })
  }

  return (
    <PageWrapper>
      <BodyWrapper className='faves-swiper-cards'>
        <ButtonArrowLeft onClick={() => swipe?.slidePrev()}><FontAwesomeIcon icon={faAngleLeft} /></ButtonArrowLeft>
        <Swiper
          onBeforeInit={(swipper) => setSwipe(swipper)}
          modules={[FreeMode, A11y, Navigation, Pagination, Scrollbar]}
          slidesPerView={"auto"}
          centeredSlides={false}
          freeMode={true}
          grabCursor={true}
        // navigation={true}
        >
          {props?.data?.map((meal: any, index: any) => {
            let productImage: any;
            if (meal?.attributes?.favItems?.data.length) {
              if (meal?.attributes?.favItems?.data[0].attributes?.product?.data?.attributes?.thumbnail_image) {
                productImage = meal?.attributes?.favItems?.data[0].attributes?.product?.data?.attributes?.thumbnail_image;
              } else if (meal?.attributes?.favItems?.data[0].attributes?.product?.data?.attributes?.image) {
                productImage = meal?.attributes?.favItems?.data[0].attributes?.product?.data?.attributes?.image;
              } else {
                productImage = meal?.attributes?.favItems?.data[0].attributes?.product?.data?.attributes?.restaurant?.data?.attributes?.logo_photos;
              }
            }
            // ************************* Checking Restaurant Open/Closed & Active/Inactive Condition ************************************
            const isClosedStrip = ((meal?.attributes?.restaurant?.data !== null && userDeliveryAddress?.timezone !== null && userDeliveryAddress?.timezone !== undefined) && !isRestaurantOpen(props?.type === "delivery" ? meal?.attributes?.restaurant?.data?.attributes?.jsonData?.local_hours?.delivery : meal?.attributes?.restaurant?.data?.attributes?.jsonData?.local_hours?.pickup, currentDateTime, userDeliveryAddress?.timezone, false)) ? true : false;
            const isInActive = (meal?.attributes?.status === "INACTIVE") ? true : false;

            // ************************* Getting Cuisiens ************************************
            const mealProp = meal?.attributes?.product?.data?.attributes;
            return (
              <SwiperSlide key={index}>
                <Block className="w-max">

                  <img src={productImage ? productImage : sampleMealImage} alt="" className={isInActive ? "opacity-30 mr-6 rounded-lg h-36 w-36 cursor-pointer object-cover" : "mr-6 rounded-lg h-36 w-36 cursor-pointer object-cover"} onClick={() => onClickOrder(meal)} />

                  <div className="flex-content" style={{ width: "148px", marginTop: ((isClosedStrip && showNav) || isInActive) ? "-20px" : "10px" }}>
                    {((showNav && (isClosedStrip || isInActive)) || isInActive)
                      &&
                      <ClosedStripDiv>{isInActive ? "Inactive" : "Closed"}</ClosedStripDiv>
                    }
                    <MealName >{meal?.attributes?.name ? switchSentenceCase("head text", meal?.attributes?.name) : switchSentenceCase("head text", properNameFormate(meal?.attributes?.name))}</MealName>
                    <div >{mealProp?.cuisine}</div>
                    <SubText1 className="flex-fav text-sm text-gray-700 my-1.5">
                      {countryCurrency("dollor")}{sumOfMealInCart(meal.attributes?.favItems?.data)}

                      <div>{mealProp?.category?.data?.attributes?.name}</div>
                      <HeartModalIcons >
                        <FontAwesomeIcon icon={faHeart} />
                      </HeartModalIcons>
                    </SubText1>
                  </div>

                  {showNav
                    ?
                    <ButtonAdd
                      // className={isClosedStrip ? "!cursor-not-allowed !border !border-solid !border-[#5cb383] !bg-[#5cb383]" : ""}
                      onClick={() => onClickOrder(meal)}
                    // disabled={isClosedStrip ? true : false}
                    >
                      {switchSentenceCase("primary button", "Order")}
                    </ButtonAdd>
                    :
                    <ButtonRemove onClick={async (e: any) => {
                      preventAndStop(e);
                      openModal(meal);
                    }}
                    >
                      {switchSentenceCase("primary button", "REMOVE FAVE")}
                    </ButtonRemove>
                  }
                </Block >
              </SwiperSlide>
            )
          })
          }
        </Swiper>

        {showModal
          &&
          <ModalRemoved
            firstText="Are you sure you want to"
            secondText="remove from faves?"
            btnOneText="OOPS! NEVER MIND..."
            btnTwoText="YES, REMOVE IT"
            showModal={showModal}
            setShowModal={setShowModal}
            id={mealItems?.id}
            deleteItem={deleteMeal}
            unfavoriteItem={unfavoriteItem}
          />
        }
        {
          showMealModal
          &&
          <ModalMealClicked
            showMealModal={showMealModal}
            setShowMealModal={setShowMealModal}
            mealProps={mealItems}
            deleteMeal={deleteMeal}
            favMealId={mealItems?.id}
          />
        }
        <ModalSuccess
          contentDivStyle={{ height: "auto" }}
          firstText="Successfully Deleted"
          secondText="Fave meal is deleted"
          btnOneText="OK"
          btnOneGoto={() => {
            dispatchEvent(mealItems?.id || 0);
          }}
          showModal={showModalSuccess}
          setShowModal={setShowModalSuccess}
        />
        {setShowOptionModal &&
          <ModalChooseOption
            showModal={showOptionModal}
            setShowModal={setShowOptionModal}
            selectedValue={handleSubmit}
          />
        }
        <ButtonArrowRight onClick={() => swipe?.slideNext()}><FontAwesomeIcon icon={faAngleRight} /></ButtonArrowRight>
      </BodyWrapper>
    </PageWrapper>
  );
}

