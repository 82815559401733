import store from "../../../redux/store";

export const setSearchedRestaurantData = (restaurantsData: any) => (dispatch: any) => {
    try {
        dispatch(
            {
                type: "SET_RESTAURANT_DATA",
                payload: restaurantsData
            })
    } catch (error) {
        console.log(error);
    }
};

export const getRestaurantMenusData = (restaurantsMenuList: any) => (dispatch: any) => {
    try {
        dispatch(
            {
                type: "GET_RESTAURANT_MENU_DATA",
                payload: restaurantsMenuList
            })
    } catch (error) {
        console.log(error);
    }
};

export const setRestaurantMenusData = (menuData: any) => (dispatch: any) => {
    try {
        dispatch(
            {
                type: "SET_RESTAURANT_MENU_DATA",
                payload: menuData
            })
    } catch (error) {
        console.log(error);

    }
};

export const addMealId = (mealId: any, itemIndex: number) => (dispatch: any) => {
    try {
        dispatch(
            {
                type: "ADD_MEAL_ID",
                payload: { mealId, itemIndex }
            })
    } catch (error) {
        console.log(error);
    }
};

export const addOrderMealItem = (addMealItem: any, type: String, itemIndex: number) => (dispatch: any) => {
    try {
        dispatch(
            {
                type: "ADD_MEAL_ITEM",
                payload: { addMealItem, type, itemIndex }
            })
    } catch (error) {
        console.log(error);
    }
};

export const changeQuantity = (quoteId: any, product_id: any, quantity: any) => (dispatch: any) => {
    try {
        dispatch(
            {
                type: "CHANGE_QUANTITY",
                payload: { product_id, quantity, quoteId }
            })
    } catch (error) {
        console.log(error);
    }
};

export const changeQuantityOfAddOrderMealItem = (product_id: any, quantity: any, itemIndex: number) => (dispatch: any) => {
    try {
        dispatch(
            {
                type: "CHANGE_QUANTITY_OF_ORDER_MEAL_ITEM",
                payload: { product_id, quantity, itemIndex }
            })
    } catch (error) {
        console.log(error);

    }
};

export const removeSingleCartItem = (product_id: any, itemIndex: number) => (dispatch: any) => {
    try {
        dispatch(
            {
                type: "REMOVE_SINGLE_CART_ITEM",
                payload: { product_id, itemIndex }
            })

    } catch (error) {
        console.log(error);
    }
};

export const removeOrderMealItem = (quoteId: string) => (dispatch: any) => {
    try {
        dispatch(
            {
                type: "REMOVE_MEAL_ITEMS",
                payload: { quoteId }
            })

        dispatch(
            {
                type: "REMOVE_MENU_ITEM_QUANTITY",
                payload: { quoteId }
            })
    } catch (error) {
        console.log(error);
    }
};

export const removeRestaurantMenuData = (value: string) => {
    store.dispatch({
        type: "REMOVE_RESTAURANT_MENU_DATA",
        payload: value
    })
}
