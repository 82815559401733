import store from "../../../redux/store";
import { objectType } from "../../../type";

export const getRestaurantsMenuList = (restaurantsMenuList: any) => (dispatch: any) => {
    try {
        dispatch(
            {
                type: "GET_RESTAURANTS_MENU_LIST",
                payload: restaurantsMenuList
            })
    } catch (error) {
        console.log(error);
    }
};

export const setMenuDataLists = (menuData: any) => (dispatch: any) => {
    try {
        dispatch(
            {
                type: "SET_MENU_DATA_LIST",
                payload: menuData
            })
    } catch (error) {
        console.log(error);
    }
};

export const addMealId = (mealId: any, itemIndex: number) => (dispatch: any) => {
    try {
        dispatch(
            {
                type: "ADD_MEAL_ID",
                payload: { mealId, itemIndex }
            })
    } catch (error) {
        console.log(error);
    }
};

export const addFavsMealItem = (addFavMealItem: any, type: String, itemIndex: number) => (dispatch: any) => {
    try {
        dispatch(
            {
                type: "ADD_FAV_MEAL_ITEMS",
                payload: { addFavMealItem, type, itemIndex }
            })
    } catch (error) {
        console.log(error);
    }
};

export const changeQuantity = (quoteId: any, product_id: any, quantity: any) => (dispatch: any) => {
    try {
        dispatch(
            {
                type: "CHANGE_QUANTITY",
                payload: { product_id, quantity, quoteId }
            })
    } catch (error) {
        console.log(error);
    }
};

export const changeQuantityOfAddFavMealItem = (product_id: any, quantity: any, itemIndex: number) => (dispatch: any) => {
    try {
        dispatch(
            {
                type: "CHANGE_QUANTITY_OF_ADD_MEAL_ITEM",
                payload: { product_id, quantity, itemIndex }
            })
    } catch (error) {
        console.log(error);
    }
};

export const removeSingleCartItem = (product_id: any, itemIndex: number) => (dispatch: any) => {
    try {
        dispatch(
            {
                type: "REMOVE_SINGLE_FAV_CART_ITEM",
                payload: { product_id, itemIndex }
            })

    } catch (error) {
        console.log(error);
    }
};

export const removeFavsMealItem = (quoteId: any) => (dispatch: any) => {
    try {
        dispatch(
            {
                type: "REMOVE_FAV_MEAL_ITEMS",
                payload: { quoteId }
            })

        dispatch(
            {
                type: "REMOVE_MENU_ITEM_QUANTITY",
                payload: { quoteId }
            })
    } catch (error) {
        console.log(error);
    }
};

export const getSingleMeal = (id: any) => (dispatch: any) => {
    try {
        dispatch(
            {
                type: "GET_SINGLE_MEAL",
                payload: id
            })
    } catch (error) {
        console.log(error);
    }
};

export const setReplaceItemData = (value: objectType) => {
    store.dispatch({
        type: "SET_REPLACE_FAV_ITEM_DATA",
        payload: value
    })
}

export const removeMenuDataList = (value: string) => {
    store.dispatch({
        type: "REMOVE_MENU_DATA_LIST",
        payload: value
    })
}