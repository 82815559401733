import Divider from "../../components/widgets/divider/Divider";
import Section from "../../components/widgets/section/Section";
import FavoriteMealGrid from "../profile/FavoriteMealGrid";
import AccordianCartegoryChild from "../../components/widgets/accordian/AccordianCartegoryChild";
import TopHead from "../../components/widgets/head/TopHead";
import imgSample1 from '../../components/assets/restaurent-Meal-2.jpg';
import imgSample2 from '../../components/assets/restaurent-Meal-3.jpg';
import imgSample3 from '../../components/assets/restaurent-Meal-4.jpg';
import imgSample4 from '../../components/assets/restaurent-Meal-5.jpg';
import RestaurantCrousel from "../../components/widgets/carousel/RestaurantCrousel";
import RestaurantCardGrid from "../../components/widgets/cards/RestaurantCardGrid";
import RestaurantCardList from "../../components/widgets/cards/RestaurantCardList2";
import { useHistory } from "react-router";


export default function HomeV1() {
  const history = useHistory();

  const favRestaurantData = [
    {
      img: imgSample1,
      name: "McDonald’s",
      address: "Colarodo, San Francisco",
      rating: "4.5",
      deliveryTime: "25min",
      deliveryType: "Free delivery"
    },
    {
      img: imgSample2,
      name: "Cafe Brichor’s",
      address: "Colarodo, San Francisco",
      rating: "4.3",
      deliveryTime: "25min",
      deliveryType: "Free delivery"
    },
    {
      img: imgSample3,
      name: "The Halal Guys",
      address: "Colarodo, San Francisco",
      rating: "4.4",
      deliveryTime: "25min",
      deliveryType: "Free delivery"
    },
    {
      img: imgSample4,
      name: "Wynk's",
      address: "Colarodo, San Francisco",
      rating: "4.5",
      deliveryTime: "25min",
      deliveryType: "Free delivery"
    }
  ]


  return (
    <div className="screen">
      <TopHead
        title="[Recipient] in San Francisco"
      // rightTextIcon={faAngleDown}
      // rightIconStyle={{ marginLeft: "5px" }}
      // rightText="Filter"
      />
      <Divider />
      <div className="grow overflow-auto">
        <Section>
          <RestaurantCrousel />
          <AccordianCartegoryChild text={{ categoryName: '[Recipient] Favorite Meals', seeMore: 'See all', }} />
          <FavoriteMealGrid

          />
          <AccordianCartegoryChild text={{ categoryName: 'Recommended Restaurants', seeMore: 'See all', }} handleOnClick={() => history.push({ pathname: "/searchfood" })} />
          <RestaurantCardGrid data={favRestaurantData} />

          <AccordianCartegoryChild text={{ categoryName: 'All Restaurants', seeMore: 'See all', }} handleOnClick={() => history.push({ pathname: "/searchresults/v1" })} />
          <RestaurantCardList restarantName="McDonald's" />
          <RestaurantCardList restarantName="Cafe Brichor’s" />
          <RestaurantCardList restarantName="The Halal Guys" />
        </Section>
        <Divider />
      </div>
    </div >
  );
}



