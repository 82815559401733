import Divider from "../../components/widgets/divider/Divider";
import TopHead from "../../components/widgets/head/TopHead";
import { faAngleLeft } from "@fortawesome/pro-solid-svg-icons";
import Section from "../../components/widgets/section/Section";
import { SubText1, TextHead1 } from "../../components/styled/styled";
import { useState, useEffect } from "react";
import {
    IonAccordion,
    IonAccordionGroup,
    IonItem,
    IonLabel,
    useIonViewDidEnter
} from '@ionic/react';
import { chevronDown } from 'ionicons/icons';
import { getFaqs } from "../../services/faq";
import IonModalAlert from "../../components/widgets/alerts/IonModalAlert";
import { trackPixelEvent } from "../../utils/utilities";

export default function Faqs() {

    const [faqList, setFaqList] = useState([]);
    const [showIonAlertModal, setShowIonAlertModal] = useState(false);
    const [ionAlertHeader, setIonAlertHeader] = useState("");
    const [ionAlertMessage, setIonAlertMessage] = useState("");


    useEffect(() => {
        callData()
    }, []);

    const callData = async () => {
        try {
            const response = await getFaqs();
            setFaqList(response?.data?.data);
                trackPixelEvent('Faq');
        }
        catch (e) {
            setShowIonAlertModal(true)
            setIonAlertHeader(e?.response?.status);
            setIonAlertMessage(e?.response?.statusText);
            console.log(e)
        }
    }

    return (
        <div className="screen">
            <TopHead
                title="FAQs"
                leftTextIcon={faAngleLeft}
                leftIconStyle={{ fontSize: "24px" }}
            />
            <Divider />
            <div className="grow overflow-auto">
                <Section>
                    <TextHead1 className="text-center mt-7">
                        Frequently Asked Questions
                    </TextHead1>

                    <SubText1 className="text-center mt-3">
                        Browse through these FAQs to find answers to commonly raised questions.
                    </SubText1>
                </Section>

                <IonAccordionGroup expand="inset">
                    {faqList?.map((item, index) =>
                        <IonAccordion value={`${index}`} toggleIcon={chevronDown} key={index}>
                            <IonItem slot="header" color="rose">
                                <IonLabel>{index < 9 ? `0${(index + 1)}. ` : `${(index + 1)}. `}{item?.attributes?.question}</IonLabel>
                            </IonItem>
                            <div className="ion-padding" slot="content">
                                <strong>Answer: </strong>
                                {item?.attributes?.answer}
                            </div>
                        </IonAccordion>
                    )}
                </IonAccordionGroup>
            </div>

            {/* *********************Error Alert Modal****************************** */}
            <IonModalAlert
                isOpened={showIonAlertModal}
                setIsOpened={setShowIonAlertModal}
                header={ionAlertHeader}
                subHeader=""
                message={ionAlertMessage}
            />
        </div >
    );
}