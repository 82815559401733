import store from "../../../redux/store";
import { getUserFavoriteMeal } from "../../../services/favourite";
import { getUserAddress } from "../../../services/location";
import { clearAllNotifications, getNotifications, updateNotification } from "../../../services/notification";
import { declineSchedule, deleteInvitation, deleteSchedule, deleteSlots, getInvitation, getScheduleSlots, getSchedules, removeSlot, updateSchedule, updateSlot } from "../../../services/schedule";
import { updateMultipleSlot } from "../../../services/schedule_slot";
import { objectType } from "../../../type";
import { trackPixelEvent } from "../../../utils/utilities";

export const createScheduleForMeStep1 = (type: any) => (dispatch: any) => {
    try {
        dispatch(
            {
                type: "STEP_1",
                payload: { type }
            })
    } catch (error) {
        console.log(error);
    }
};

export const calendarRecipientData = (data: objectType) => {
    store.dispatch({
        type: "SET_RECIPIENT_DATA",
        payload: data
    })
}

export const createScheduleForMeStep2 = (data: any) => (dispatch: any) => {
    try {
        dispatch(
            {
                type: "STEP_2",
                payload: data
            })
    } catch (error) {
        console.log(error);
    }
};

export const setScheduleDetailsRedux = (value: any) => {
    store.dispatch({
        type: "SET_SCHEDULE_DETAILS",
        payload: value
    })
}

export const setTotalSlotsRedux = (value: any) => {
    store.dispatch({
        type: "SET_TOTAL_SLOTS",
        payload: value
    })
}

export const createScheduleForMeClear = () => (dispatch: any) => {
    dispatch(
        {
            type: "CLEAR",
        })
};

export const scheduleDetailsClear = () => (dispatch: any) => {
    dispatch(
        {
            type: "CLEAR_SCHEDULES_DETAILS",
        })
};

export const notificationDetailsClear = () => (dispatch: any) => {
    dispatch(
        {
            type: "CLEAR_INVITATION_DETAILS",
        })
};

export const getSchedulesForMe = (id: any, skip: any, limit: any, type: any) => async (dispatch: any) => {
    try {
        const response = await getSchedules(id, skip, limit, type)
        if (response) {
            trackPixelEvent('Get schedules');
            dispatch(
                {
                    type: "GET_SCHEDULES_FOR_ME",
                    payload: { action: response?.data, skip: skip }
                })
        }
    } catch (error) {
        console.log(error);
    }
}

export const getScheduleForOther = (id: any, skip: any, limit: any, type: any) => async (dispatch: any) => {
    try {
        const response = await getSchedules(id, skip, limit, type)
        if (response) {
            trackPixelEvent('Get schedules');
            dispatch(
                {
                    type: "GET_SCHEDULE_FOR_OTHER",
                    payload: { action: response?.data, skip: skip }
                })
        }
    } catch (error) {
        console.log(error);
    }
}

export const getScheduleDetails = (id: any, type: any) => async (dispatch: any) => {
    try {
        dispatch(
            {
                type: "GET_SCHEDULE_DETAILS",
                payload: { id, type }
            })

    } catch (error) {
        console.log(error);
    }
};

export const addInvitations = (data: any) => async (dispatch: any) => {
    let updatedData = [];
    for (const obj of data) {
        updatedData.push({ id: obj?.id, attributes: obj });
    }
    dispatch(
        {
            type: "ADD_NEW_INVITATION",
            payload: updatedData
        })
}

export const removeInvitation = (id: any) => async (dispatch: any) => {
    try {
        const response = await deleteInvitation(id);
        if (response) {
            trackPixelEvent('Delete schedule invitation');
            dispatch(
                {
                    type: "REMOVE_INVITATION",
                    payload: response?.data?.data?.id
                })
        }
    } catch (error) {
        console.log(error);
    }
}

export const updateSchedules = (id: any, updatedData: any) => async (dispatch: any) => {
    try {
        const res = await updateSchedule(id, updatedData);
        if (res) {
            trackPixelEvent('Update Schedule');
            dispatch({
                type: "UPDATE_SCHEDULES_DATA",
                payload: res?.data?.data
            })
        }
    }
    catch (error) {
        console.log(error);
    }
}

export const getUserAddresses = (id: any) => async (dispatch: any) => {
    try {
        const response = await getUserAddress("", id)
        if (response) {
            trackPixelEvent('Get user address');
            dispatch(
                {
                    type: "GET_USER_ADDRESS",
                    payload: response?.data?.data
                })
        }
    } catch (error) {
        console.log(error);
    }
}


export const setScheduleSlotsRedux = (value: any) => {
    store.dispatch({
        type: "SET_SCHEDULE_SLOTS",
        payload: value
    })
}

export const setScheduleSlotsLoaderRedux = (value: any) => {
    store.dispatch({
        type: "SET_SCHEDULE_SLOTS_LOADER",
        payload: value
    })
}

export const getAllScheduleSlots = (id: any, skip: any, limit: any, showEmpty: any) => async (dispatch: any) => {
    try {
        const response = await getScheduleSlots(id, skip, limit, showEmpty)
        if (response) {
            trackPixelEvent('Get schedule slots');
            dispatch(
                {
                    type: "GET_ALL_SCHEDULE_SLOTS",
                    payload: { action: response?.data, skip: skip, setAwaiting: false }
                })
        }
    } catch (error) {
        console.log(error);
    }
}

export const getSlotDetails = (id: any) => async (dispatch: any) => {
    try {
        dispatch(
            {
                type: "GET_SLOT_DETAILS",
                payload: id
            })

    } catch (error) {
        console.log(error);
    }
};


export const updateSlotData = (id: any, updatedData: any) => async (dispatch: any) => {
    try {
        const res = await updateSlot(id, updatedData)
        if (res) {
            trackPixelEvent('Update slot');
            dispatch(
                {
                    type: "UPDATE_SLOT_DATA",
                    payload: { data: res?.data?.data, id: id }
                })
        }
    }
    catch (error) {
        console.log(error);
    }
}

export const removeScheduleSlots = (data: any) => async (dispatch: any) => {
    try {
        const response = await deleteSlots(data)
        if (response) {
            trackPixelEvent('Delete slots');
            dispatch(
                {
                    type: "REMOVE_MULTIPLE_SLOTS",
                    payload: response?.data?.data
                })
        }
    } catch (error) {
        console.log(error);
    }

}

export const deleteSingleSlot = (id: any) => async (dispatch: any) => {
    try {
        const response = await removeSlot(id)
        if (response) {
            trackPixelEvent('Remove slot');
            dispatch(
                {
                    type: "REMOVE_SINGLE_SLOT",
                    payload: response?.data?.data?.id
                })
        }
    } catch (error) {
        console.log(error);
    }

}

export const setUserFavMeals = (value: any) => {
    store.dispatch({
        type: "SET_USER_FAV_MEAL",
        payload: value
    })
}

export const getUserFavMeals = (query: any) => async (dispatch: any) => {
    try {
        const response = await getUserFavoriteMeal(query)
        if (response) {
            trackPixelEvent('Get user fave meal');
            dispatch(
                {
                    type: "GET_FAV_MEAL",
                    payload: response?.data
                })
        }
    } catch (error) {
        console.log(error);
    }
};

export const getSelectedSlots = (ids: any) => async (dispatch: any) => {
    dispatch(
        {
            type: "SELECT_EMPTY_SLOTS",
            payload: ids
        })
};

export const assignMealToSlots = (data: any) => async (dispatch: any) => {
    let tempArray = []
    data?.slotArray?.forEach((obj: any) => {
        if (obj?.attributes?.favMeal?.data !== null) {
            tempArray.push({ slotId: obj?.id, favMealId: obj?.attributes?.favMeal?.data?.id, date: obj?.attributes?.date, time: obj?.attributes?.time, deliveryAddress: obj?.attributes?.delivery_location?.data?.id });
        }
    });
    const payloadData = {
        data: {
            slots: tempArray,
            price_notify: data?.price_notify,
            order_type: data?.order_type
        }
    }
    try {
        const res = await updateMultipleSlot(payloadData)
        if (res) {
            trackPixelEvent('Update multiple slot');
            dispatch(
                {
                    type: "ASSIGN_MEAL_TO_SLOT",
                    payload: data
                })
        }
    }
    catch (error) {
        console.log(error);
    }
}

export const MealSponsored = (payloadData: any, updateData: any) => async (dispatch: any) => {
    try {
        const res = await updateMultipleSlot(payloadData)
        if (res) {
            trackPixelEvent('Update multiple slot');
            dispatch(
                {
                    type: "MEAL_SPONSORED",
                    payload: updateData
                })
        }
    }
    catch (error) {
        console.log(error);
    }
}

export const orderFoodForSlot = (data: any) => async (dispatch: any) => {
    try {
        dispatch(
            {
                type: "ORDER_FOOD_FOR_SLOT",
                payload: data
            })
    }
    catch (error) {
        console.log(error);
    }
}

export const selfCookedSlots = (payloadData: any) => async (dispatch: any) => {
    try {
        const data = payloadData.data
        const res = await updateMultipleSlot(payloadData)
        if (res) {
            trackPixelEvent('Update multiple slot');
            dispatch(
                {
                    type: "SELF_COOKED_SLOTS",
                    payload: data
                })
        }
    }
    catch (error) {
        console.log(error);
    }
}

export const invitationScheduleDetails = (data: any) => async (dispatch: any) => {
    dispatch(
        {
            type: "INVITATION_SCHEDULE_DETAILS",
            payload: data
        })
};

export const getUserNotifications = (skip: any, limit: any) => async (dispatch: any) => {
    try {
        const response = await getNotifications(skip, limit)
        if (response) {
            trackPixelEvent('Get notification');
            dispatch(
                {
                    type: "GET_USER_NOTIFICATIONS",
                    payload: { action: response?.data, skip: skip }
                })
        }
    } catch (error) {
        console.log(error);
    }
}


export const readNotification = (id: any, updatedData: any) => async (dispatch: any) => {
    try {
        const res = await updateNotification(id, updatedData)
        if (res) {
            trackPixelEvent('Update notification');
            dispatch(
                {
                    type: "READ_NOTIFICATION",
                    payload: res?.data?.data
                })
        }
    }
    catch (error) {
        console.log(error);
    }
}

export const clearNotification = (ids: any) => async (dispatch: any) => {
    const clearData = {
        data: {
            items: ids
        }
    }
    try {
        const res = await clearAllNotifications(clearData)
        if (res) {
            trackPixelEvent('Clear all notifications');
            dispatch(
                {
                    type: "CLEAR_NOTIFICATION",
                    payload: ids
                })
        }
    }
    catch (error) {
        console.log(error);
    }
}


export const getSingleFavItemsDetails = (id: any, date: any, time: any) => async (dispatch: any) => {
    try {
        dispatch(
            {
                type: "GET_ INDIVIDUAL_FAV_ITEMS_DETAILS",
                payload: { id: id, date: date, time: time }
            })

    } catch (error) {
        console.log(error);
    }
};

export const getSponsorFavItemsDetails = (faveId: any) => async (dispatch: any) => {
    dispatch(
        {
            type: "GET_SPONSOR_FAVE_ITEM_DETAILS",
            payload: { faveId: faveId }
        }
    )
}

export const getInvitationScheduleData = (scheduleData: any, skip: any, limit: any, showEmpty: any) => async (dispatch: any) => {
    try {

        if (scheduleData?.data?.data?.length > 0) {
            dispatch(
                {
                    type: "GET_INVITATION_SCHEDULE_DETAILS",
                    payload: scheduleData?.data?.data
                })
            getScheduleSlots(scheduleData?.data?.data[0]?.id, skip, limit, showEmpty)
                .then((response) => {
                    trackPixelEvent('Get schedule slots');
                    dispatch(
                        {
                            type: "GET_ALL_SCHEDULE_SLOTS",
                            payload: { action: response?.data, skip: skip, setAwaiting: false }
                        })
                })
            // {
            //     (user?.jwt)
            //         &&
            //         getUserAddress("", scheduleData?.data?.data[0]?.attributes?.user?.data?.id)
            //             .then((response) => {
            //                 trackPixelEvent('Get user address');
            //                 dispatch(
            //                     {
            //                         type: "GET_USER_ADDRESS",
            //                         payload: response?.data?.data
            //                     })
            //             })
            // }
        }
    } catch (error) {
        console.log(error);
    }
};

export const getInvitationData = (token: any) => async (dispatch: any) => {
    try {
        const response = await getInvitation(token)
        if (response) {
            trackPixelEvent('Get schedule invitation');
            dispatch(
                {
                    type: "GET_INVITATION_DATA",
                    payload: response?.data?.data
                })
        }
    } catch (error) {
        console.log(error);
    }
}

export const declineScheduleInvitation = (payloadData: any) => async (dispatch: any) => {
    try {
        const response = await declineSchedule(payloadData)
        if (response) {
            trackPixelEvent('Decline schedule');
            dispatch(
                {
                    type: "DECLINE_SCHEDULE_INVITATION",
                })
        }
    } catch (error) {
        console.log(error);
    }
}

export const removeSchedule = (id: any, type: any) => async (dispatch: any) => {
    try {
        if (type === "for_me") {
            const res = await deleteSchedule(id)
            if (res) {
                trackPixelEvent('Delete schedule');
                dispatch(
                    {
                        type: "REMOVE_SCHEDULE",
                        payload: { id: res?.data?.data?.id, type }
                    })
            }
        }
        else {
            const payloadData = {
                data: {
                    from_user: null
                }
            }
            const res = await updateSchedule(id, payloadData);
            if (res) {
                trackPixelEvent('Update Schedule');
                dispatch(
                    {
                        type: "REMOVE_SCHEDULE",
                        payload: { id, type }
                    })
            }
        }
    } catch (error) {
        console.log(error);
    }
}

export const updateSlotRestaurantScheduleData = (id: any, updatedData: any) => async (dispatch: any) => {
    try {
        dispatch(
            {
                type: "UPDATE_SLOT_RESTAURANT_SCHEDULE_DATA",
                payload: { data: updatedData, id: id }
            })
    }
    catch (error) {
        console.log(error);
    }
}

export const setOrderRedirectUrl = (value: string) => {
    store.dispatch({
        type: "ORDER_REDIRECT_URL",
        payload: value
    })
}