import { useState, useEffect } from "react";
import { Device, DeviceInfo } from "@capacitor/device";

const useDeviceInfo = () => {
    const [deviceInfo, setDeviceInfo] = useState<DeviceInfo | null>(null);

    useEffect(() => {
        const logDeviceInfo = async () => {
            const info = await Device.getInfo();
            setDeviceInfo(info);
        };

        logDeviceInfo();
    }, []);

    return deviceInfo;
};

export default useDeviceInfo;
