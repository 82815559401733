import styled from 'styled-components';
import useDarkMode from '../../hooks/darkmode/DarkMode';

const Tab = styled.div<{ isDarkMode: boolean }>`
  overflow: hidden;
  border: 1px solid white;
  border-radius: 30px;
  background-color: ${props => (props?.isDarkMode ? '#2e2e2e' : '#f1f1f1')};
  display: flex;
  justify-content: space-between;
  margin: 20px 0px;
`;

const Tablinks = styled.button<{ active?: boolean; isDarkMode: boolean; disabled?: boolean }>`
  background-color: inherit;
  float: left;
  border: none;
  outline: none;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  padding: 10px 0px;
  transition: 0.3s;
  font-size: 15px;
  color: ${props => (props?.isDarkMode ? 'white' : 'black')};
  width: 100px;

  &:not(:disabled):hover {
    background-color: #ddd;
  }

  ${({ active, disabled }) =>
    active && !disabled &&
    `
      background: #22A45D;
      color: white;
      padding: 10px 0px;
      border-radius: 30px;
      width: 140px;

      &:not(:disabled):hover {
        background-color: #22A45D;
      }
    `}
`;

export default function TabsForTipPreference(props: any) {
  const isDarkMode = useDarkMode();

  const changeTipValue = (type: any) => {
    if (!props.disabled) { // Check if the tab is disabled before changing value
      props?.setActive(type);
      props?.setTipValue(type);
    }
  }

  return (
    <>
      <Tab isDarkMode={isDarkMode}>
        {props?.types.map((type: any) => (
          <Tablinks
            isDarkMode={isDarkMode}
            key={type?.value}
            active={props?.selected === type?.value}
            disabled={props.disabled} // Pass disabled prop to Tablinks
            onClick={() => changeTipValue(type?.value)}
          >
            {type?.name}
          </Tablinks>
        ))}
      </Tab>
      <p />
      {/* <p> Your payment selection: {active} </p> */}
    </>
  );
};


