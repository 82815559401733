const initialValue = {
    searchedRestaurants: {},
    nextPage: null,
    searchFilter: {},
    restaurantsList: [],
    isResultLoading: false,
    nearByRestaurants: [],
    nearByScheduleRestaurants: [],
    orderType: ""
}


const getUserRestaurantsLists = (state = initialValue, action: any) => {
    switch (action.type) {
        case "GET_RESTAURANTS_DATA":
            return { ...state, searchedRestaurants: action.payload }

        case "SET_ORDER_TYPE":
            return { ...state, orderType: action.payload }

        case "GET_RESTAURANTS_LIST":
            if (state.nextPage !== null) {
                const oldData = state.restaurantsList
                const newData = [...oldData, ...action.payload.restaurantsList];
                return { ...state, restaurantsList: newData, nextPage: action.payload.nextPage };
            }
            else {
                return { ...state, restaurantsList: action.payload.restaurantsList, nextPage: action.payload.nextPage }
            }

        case "SET_SEARCH_FILTER":
            return { ...state, searchFilter: action.payload }

        case "SET_RESULT_LOADING":
            return { ...state, isResultLoading: action.payload }

        case "SET_NEAR_BY_RESTAURANTS":
            return { ...state, nearByRestaurants: action.payload }

        case "SET_NEAR_BY_SCHEDULE_RESTAURANTS":
            return { ...state, nearByScheduleRestaurants: action.payload }

        case "CLEAR_RESTAURANT_LIST":
            return { ...state, restaurantsList: [], nextPage: null, searchFilter: {} };

        default:
            return state;
    }
}

export default getUserRestaurantsLists;