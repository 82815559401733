import { IonContent, IonRefresher, IonRefresherContent, RefresherEventDetail, useIonAlert, useIonViewDidEnter } from "@ionic/react";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import useDeviceInfo from "../../components/hooks/seDeviceInfo";
import { Flex, InitialDiv, TextHead1, TextHead3, TextHead5 } from "../../components/styled/styled";
import Divider from "../../components/widgets/divider/Divider";
import HomeTab from "../../components/widgets/toggleable-tabs/HomeTab";
import { checkAppVersion } from "../../services/apiCheckUpdate";
import { getFavesCount } from "../../services/favourite";
import { getMyExistedFriend } from "../../services/friends";
import { getUpcomingOrdersCount } from "../../services/orderFood";
import { getUserInfo } from "../../services/user";
import { displayFullName, trackPixelEvent } from "../../utils/utilities";
import { setRecentOrderCount, setTotalFaveMealsCount } from "../faves/redux/favouriteActionCreator";
import GetFoodNowButton from "../gfn/GetFoodNowButton";
import { setTotalUpcomingOrdersCount } from "../orders/redux/upcomingOrderActionCreator";
import { setExistedFriendsCount } from "../profile/redux/locationActionCreator";
import { getSchedulesForMe } from "../schedules/redux/createScheduleReduxActions";
import MyFavoritesButton from "./MyFavoritesButton";
import MySchedulesButton from "./MySchedulesButton";
import MyUpcomingOrdersButton from "./MyUpcomingOrdersButton";
import MyGiftsButton from "./MyGiftsButton";
import MyPlansButton from "./MyPlansButton";



export default function Home2() {
  const [presentAlert] = useIonAlert();
  const dispatch = useDispatch();
  const deviceInfo = useDeviceInfo();
  const user = getUserInfo();
  const [changeStyle, setChangeStyle] = useState(false);
  const [isRefreshed, setIsRefreshed] = useState(false);
  const androidCurentVersion = process.env.REACT_APP_ANDROID_VERSION_PRODUCTION;
  const iosCurentVersion = process.env.REACT_APP_IOS_VERSION_PRODUCTION;


  //***************** GET CARD COUNTS API  *****************//
  const getCardCounts = async () => {
    try {
      const fav_response = await getFavesCount({
        id: user?.id,
        type: "Fav",
        skip: 0,
        limit: 100
      });
      trackPixelEvent('Get count');
      setTotalFaveMealsCount(fav_response?.data?.meta?.pagination?.total);

      dispatch(getSchedulesForMe(user?.id, "", "", "For_Me") as any);

      const order_response = await getFavesCount({
        id: user?.id,
        type: "Order",
        skip: 0,
        limit: 100
      });
      setRecentOrderCount(order_response?.data?.meta?.pagination?.total)
      trackPixelEvent('Get count');
      const upcoming_order = await getUpcomingOrdersCount(user?.id);
      setTotalUpcomingOrdersCount(upcoming_order?.data?.meta?.pagination?.total);
      if (upcoming_order) {
        trackPixelEvent('Get upcoming orders count');
      };

      const existedFriendResponse = await getMyExistedFriend(0, 100);
      setExistedFriendsCount(existedFriendResponse?.data?.meta?.pagination?.total);
      // console.log("existedFriendResponse", existedFriendResponse?.data?.meta?.pagination?.total);
    }
    catch (e) {
      console.log(e);
    }
  };


  const checkForUpdate = async () => {
    try {
      if (deviceInfo?.platform === "android" || deviceInfo?.platform === "ios") {
        const response = await checkAppVersion();
        if (deviceInfo?.platform === "android" && ((response?.data?.data?.[0]?.attributes?.android) > (androidCurentVersion))) {
          presentAlert({
            header: 'Update Required !',
            subHeader: `Mealfave is out of date. Please visit the Play Store to upgrade to the latest version.`,
            buttons: [{
              text: 'Update Now',
              role: 'confirm',
              handler: () => {
                window.open('https://play.google.com/store/apps/details?id=com.mealfave.app', '_blank');
              }
            }],
          })
        } else if (deviceInfo?.platform === "ios" && ((response?.data?.data?.[0]?.attributes?.ios) > (iosCurentVersion))) {
          presentAlert({
            header: 'Update Required !',
            subHeader: `Mealfave is out of date. Please visit the App Store to upgrade to the latest version.`,
            buttons: [{
              text: 'Update Now',
              role: 'confirm',
              handler: () => {
                window.open('https://apps.apple.com/us/app/mealfave-food-delivery-plan/id6469111188', '_blank');
              }
            }],
          })
        }
      }
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    checkForUpdate();
    // eslint-disable-next-line
  }, [isRefreshed, deviceInfo]);

  useEffect(() => {
    checkForUpdate();
    getCardCounts();
    setInterval(() => {
      setChangeStyle(true)
    }, 2000);

    // eslint-disable-next-line
  }, [isRefreshed]);



  function handleRefresh(event: CustomEvent<RefresherEventDetail>) {
    setTimeout(() => {
      setIsRefreshed(prev => !prev);
      event.detail.complete();
    }, 2000);
  }


  useIonViewDidEnter(() => {
    trackPixelEvent('Home');
  }, []);


  //***************** RETURN START  *****************//
  return (
    <div className="screen">
      {/* <div className="grow overflow-auto"> */}
      <div className="text-center mb-3 ion-safe-top text-[#22a45d]">
        <TextHead1 className={changeStyle ? `!hidden !text-[28px]` : "drop-in !text-[28px]"} id="text1">Hi, {displayFullName() ? displayFullName() : "Mealfave User"}</TextHead1>
        <TextHead1 className={changeStyle ? `!block drop-in !text-[28px]` : "!text-[28px]"} id="text2">Home</TextHead1>
      </div>
      <Divider className="border-[#22a45d]" />
      <IonContent>
        <IonRefresher slot="fixed" onIonRefresh={handleRefresh}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>

        {/* <HomeTab /> */}

        <div className="home_box">
          <InitialDiv className="m-4">
            <div className="cursor-pointer ">
              <InitialDiv className="lg:w-[50%] lg:ml-auto lg:mr-auto">
                <TextHead5 className="!text-base !font-bold">Get Started</TextHead5>
                <Flex className="max-md:justify-between gap-5 justify-center">
                  <MyGiftsButton />
                  <MyPlansButton />
                </Flex>
              </InitialDiv>
              <InitialDiv className="my-5 lg:w-[50%] lg:ml-auto lg:mr-auto">
                <TextHead5 className="!text-base !font-bold">Quick Access</TextHead5>
                <Flex className="max-md:justify-between gap-5 justify-center">
                  <MyFavoritesButton />
                  <GetFoodNowButton />
                </Flex>
                <Flex className="max-md:justify-between gap-5 justify-center">
                  <MySchedulesButton />
                  <MyUpcomingOrdersButton />
                </Flex>
              </InitialDiv>

            </div>
          </InitialDiv>
        </div>

      </IonContent>
      {/* </div> */}
    </div>
  );
}
