import { FormData } from "formdata-node";
import axios from "axios";
import { getUserData } from "../services/user";


export const imageUploader = async (file: any) => {

  try {
    const user = await getUserData();
    const formData = new FormData();
    formData.append('files', file);
    const { data } = await axios.post(
      `${process.env.REACT_APP_API_URL}/v1/upload`,
      formData,
      {
        headers: {
          'Authorization': 'Bearer ' + user?.jwt,
        }
      }
    );
    return (data)
  }

  catch (e) {
    console.log("Error in image uploading ->", e)
    throw e
  }
};
