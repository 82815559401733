import React, { useEffect, useState } from "react";
import Section from "../../components/widgets/section/Section";
import FeatureImage from "../../components/widgets/FeatureImage/FeatureImage";
import RatingSummary from "../../components/widgets/ratingsummary/RatingSummary";
import MenuCategories from "./MenuCategories";
import MenuItemList from "./MenuItemList";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";
import DotSeprator from "../../components/widgets/dotseprator/DotSeprator";
import { getSelectedRestaurantData, getRestaurantById, restaurantsMenus } from "../../services/restaurant";
import { FlexBetween, InitialDiv, InputWithIcon, SubText1, TextHead4, TextHead5 } from "../../components/styled/styled";
import Divider from "../../components/widgets/divider/Divider";
import { useDispatch, useSelector } from "react-redux";
import { setMenuDataLists, setReplaceItemData } from "./redux/menuItemActionCreatior";
import ModalSpinner from "../../components/widgets/modalspinner/ModalSpinner";
import AddToFaveFloating from "./AddToFaveFloatin";
import { goBackPage, priceFormatterUsd, switchSentenceCase, textFormatter, trackPixelEvent } from "../../utils/utilities";
import defaultRestaurant from "../../components/assets/restaurent-Meal-3.jpg";
import { faPlateUtensils } from "@fortawesome/pro-solid-svg-icons";
import useDarkMode from "../../components/hooks/darkmode/DarkMode";
import { useLocation, useParams } from "react-router";
import { RouteParams } from "../../type";
import { useIonViewDidEnter } from "@ionic/react";
import { getDeliveryAddress } from "../../services/location";
import { getSearchedRestaurantsData } from "../search/redux/searchActionCreator";
import { setUserDeliveryAddress } from "../profile/redux/locationActionCreator";

const ContentSticky = styled.div<{ isDarkMode: boolean }>`
  padding-left: 1rem;
  padding-right: 1rem;
  text-align: left;
  position: sticky;
  top: 0;
  background-color: ${props => (props?.isDarkMode ? 'black' : 'white')};
  z-index: 10;
  color: ${props => (props?.isDarkMode ? 'white' : '')};
`;

const RestaurantHead = styled.h1`
  padding: 10px 0px;
  margin: 0px -2px;
  font-family: system-ui;
`;


export default function RestaurantHome(data: any) {
  const dispatch = useDispatch();
  const isDarkMode = useDarkMode();
  const { rid } = useParams<RouteParams>();
  const addressData = getDeliveryAddress();
  const location = useLocation<any>();
  const searchParams = new URLSearchParams(location.search);
  const type = searchParams.get('type');
  // *************************** Initial hooks *************************************
  const [category, setCategory] = useState<string | null>(null);
  const [awaitingResults, setAwaitingResults] = useState(false);
  const [quoteData, setQuoteData] = useState<any>([]);

  // *************************** State Managements *************************************
  const { searchedRestaurants } = useSelector((state: any) => state.getUserSearchedRestaurants);
  const { menuDataList, addfavMealItem } = useSelector((state: any) => state.getRestaurantsMenuLists);
  const storeId = location?.state?.restaurantId ? location?.state?.restaurantId || rid : searchedRestaurants?.id || rid;
  const _menuDataList = menuDataList?.filter((currentObj: any) => currentObj?.quoteIds === storeId)[0] || [];
  const { userSearchLocation } = useSelector((state: any) => state.getUserSearchLocation);
  const { userDeliveryAddress } = useSelector((state: any) => state.userAddresses);
  const [filterTerm, setFilterTerm] = useState('');
  const [showWarningMsg, setShowWarningMsg] = useState(true);


  // *********************** First time redering ********************************
  useEffect(() => {
    getRestaurantMenu();
    setInterval(() => {
      setShowWarningMsg(false)
    }, 1000);
  }, [searchedRestaurants]);

  useEffect(() => {
    if (!searchedRestaurants?.id || location?.state?.restaurantId) {
      callData();
    }
  }, []);


  useIonViewDidEnter(() => {
    trackPixelEvent('Restaurants for Fave');
  }, []);

  window.onload = async () => {
    const data = await getSelectedRestaurantData();
    dispatch(getSearchedRestaurantsData(data) as any);
    setUserDeliveryAddress(addressData);
  };
  // *************************** Functions *******************************************

  // ----This if for getting cuisiens which is below to Product Name----
  const cuisines = (Object.keys(_menuDataList?.data || {}));

  // ----This if for getting Meal Dishes Length which is below to Product Names----
  const MealDataArray = Object.values(_menuDataList?.data || {});
  const MealDishesLength = [].concat.apply([], MealDataArray);

  // ----Getting Min and Max Price-----
  let tempPriceArray = [];
  MealDataArray?.forEach((currentData: any) => {
    const mealDataFor = currentData;

    mealDataFor?.forEach((dataPrice: any) => {
      tempPriceArray.push(dataPrice?.price);
    })
  });

  const minMealPrice = (Math.min(...tempPriceArray));
  const maxMealPrice = (Math.max(...tempPriceArray));

  const callData = async () => {
    if (!storeId) return;
    const data = await getRestaurantById(storeId);
    const store_data = data?.data?.data[0]
    const restaurantSendData = {
      id: store_data?.attributes?.mealme_id,
      name: store_data?.attributes?.name,
      logoPhoto: store_data?.attributes?.logo_photos,
      description: store_data?.attributes?.description,
      rating: store_data?.attributes?.jsonData?.weighted_rating_value,
      ratingCount: store_data?.attributes?.jsonData?.aggregated_rating_count,
      quoteIds: store_data?.attributes?.jsonData?.quote_ids,
      is_open: store_data?.attributes?.jsonData?.is_open,
      dollar_signs: store_data?.attributes?.jsonData?.dollar_signs,
      local_hours: store_data?.attributes?.jsonData?.local_hours,
    };
    dispatch(getSearchedRestaurantsData(restaurantSendData) as any);
    setUserDeliveryAddress(addressData);
    if (location?.state?.restaurantId) {
      setReplaceItemData(location?.state);
    }
  };

  const getRestaurantMenu = async () => {
    try {
      if (!storeId) return;

      if (!Object.keys(_menuDataList?.data || {})?.length || storeId !== _menuDataList?.quoteIds || location?.state?.restaurantId || addfavMealItem?.length === 0) {
        setAwaitingResults(true);
        // let data = Object.keys(searchedRestaurants);
        // if (data.length) {
        const locationData = {
          store_id: storeId,
          user_latitude: userSearchLocation?.lat || userDeliveryAddress?.lat || addressData?.lat,
          user_longitude: userSearchLocation?.lang || userDeliveryAddress?.lang || addressData?.lang,
          user_street_num: userSearchLocation?.streetNum,
          user_street_name: userSearchLocation?.streetName,
          user_city: userSearchLocation?.city,
          user_state: userSearchLocation?.state,
          user_zipcode: userSearchLocation?.zip,
          user_country: userSearchLocation?.country,
          orderType: type
        }

        await restaurantsMenus(locationData).then(async (response) => {
          let restaurantDatas = response;
          if (restaurantDatas?.data?.loading) { } else {
            setQuoteData(restaurantDatas?.data?.quote);
            trackPixelEvent('Restaurant Menu');
            dispatch(setMenuDataLists({ "quoteIds": storeId, data: restaurantDatas?.data?.data }) as any);
            setAwaitingResults(false);
          }
        });
      }
      // }
    } catch (error) {
      console.log("Restaurant Menus Fetching Error ", error);
      setAwaitingResults(false);
    }
  };

  function dollarSign(count: any) {
    let sign = "";
    for (let i = 0; i < count; i++) {
      sign += `$`
    }
    return sign;
  };

  const handleFilterChange = (event: any) => {
    const filterTextTerm = event.target.value;
    setCategory(searchByName(filterTextTerm)?.[0]);
    setFilterTerm(filterTextTerm);
  };

  function searchByName(searchTerm: string) {
    let results = [];
    for (const category in _menuDataList?.data) {
      const categoryData = _menuDataList?.data[category];
      if (categoryData) {
        const result = categoryData.find(item => item.name.toLowerCase().includes(searchTerm.toLowerCase()));
        if (result) {
          results.push(category);
        }
      }
    }
    return (results?.length > 0 ? results : null);
  };

  const handleChangeCuisine = (category: string) => {
    setCategory(category);
    document.getElementById("restaurant_meal_category").scrollIntoView({ behavior: "smooth" })
  }

  return (
    <div className="screen !h-full">
      {awaitingResults === true ? (
        <div className="text-center">
          <ModalSpinner loadingText={"Loading Menu..."} />
        </div>
      ) : (
        <div className="relative screen">
          <div className="overflow-y-auto">
            <FontAwesomeIcon
              icon={faChevronLeft}
              className="back-chevron text-white z-10 p-1.5 w-5"
              onClick={() => goBackPage()}
            // onClick={() => history.push("/search?for=faves")}
            />

            <FeatureImage img={searchedRestaurants.logoPhoto ? searchedRestaurants.logoPhoto : defaultRestaurant} />
            <ContentSticky isDarkMode={isDarkMode}>
              <FlexBetween className="items-center">
                <RestaurantHead>{switchSentenceCase("head text", searchedRestaurants.name)}</RestaurantHead>
                <SubText1 >[{searchedRestaurants.is_open ? <span className="text-green-600">Open</span> : <span className="text-red-600">Closed</span>}]</SubText1>
              </FlexBetween>

              {/\bwrong\b/.test(quoteData?.error)
                ?
                ""
                :
                (quoteData?.error !== "") &&
                <InitialDiv className="flex">
                  <TextHead5 className="text-red-600">{quoteData?.error}</TextHead5>
                </InitialDiv>
              }
              {/*----------------- cuisines -----------------------*/}
              <div className="flex text-gray-400 items-center overflow-hidden whitespace-nowrap">
                <h6>{dollarSign(searchedRestaurants?.dollar_signs)}</h6>
                {cuisines?.slice(0, 3)?.map((cuisinesItem: any, index: any) => {
                  return (
                    <React.Fragment key={index}>
                      {`\u00A0`}
                      <DotSeprator />{`\u00A0`}{switchSentenceCase("head text", (textFormatter(cuisinesItem)))}
                    </React.Fragment>
                  );
                })}
              </div>

              <SubText1 className="my-1 truncate">{searchedRestaurants.description}</SubText1>
              <div className="flex text-gray-600 font-medium max-w-md text-sm">
                <RatingSummary
                  stars={searchedRestaurants.rating || 0}
                  count={searchedRestaurants.ratingCount || 0}
                />
                <DotSeprator />
                <span className="flex">
                  <svg
                    width="15"
                    height="20"
                    viewBox="0 0 11 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.9099 1.66497C10.7449 1.26497 10.3549 0.979971 9.87488 0.979971H8.83488L8.55488 2.27497C8.51488 2.44997 8.35988 2.56997 8.18988 2.56997C8.16488 2.56997 8.13488 2.56997 8.10988 2.55997C7.90988 2.51497 7.77988 2.31497 7.81988 2.11497L8.06488 0.974971H5.51488L5.19988 2.27497C5.15988 2.44497 5.00488 2.55997 4.83488 2.55997C4.80488 2.55997 4.77488 2.55497 4.74488 2.54997C4.54488 2.49997 4.41988 2.29997 4.46988 2.09497L4.73988 0.969971H3.72488C3.23488 0.969971 2.79988 1.28997 2.65488 1.75997L0.549877 8.53497C0.329877 9.25997 0.864877 9.99997 1.61988 9.99997H8.18988C8.70988 9.99997 9.15988 9.64997 9.27988 9.14497L10.9649 2.37997C10.9949 2.25497 11.0049 2.12997 10.9949 2.00997C10.9849 1.88997 10.9599 1.76997 10.9099 1.66497ZM7.34988 7.37497H3.34988C3.14488 7.37497 2.97488 7.20497 2.97488 6.99997C2.97488 6.79497 3.14488 6.62497 3.34988 6.62497H7.34988C7.55488 6.62497 7.72488 6.79497 7.72488 6.99997C7.72488 7.20497 7.55488 7.37497 7.34988 7.37497ZM7.84988 5.37497H3.84988C3.64488 5.37497 3.47488 5.20497 3.47488 4.99997C3.47488 4.79497 3.64488 4.62497 3.84988 4.62497H7.84988C8.05488 4.62497 8.22488 4.79497 8.22488 4.99997C8.22488 5.20497 8.05488 5.37497 7.84988 5.37497Z"
                      fill="#ABABAB"
                    />
                  </svg>
                  {`\u00A0 ${MealDishesLength.length} dishes`}
                </span>
                <DotSeprator />
                <span className="flex">
                  <svg
                    width="15"
                    height="20"
                    viewBox="0 0 10 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.485 7.04C9.365 8.375 8.4 9.25 7 9.25H2.5C1.12 9.25 0 8.13 0 6.75V3.25C0 1.89 0.82 0.94 2.095 0.78C2.225 0.76 2.36 0.75 2.5 0.75H7C7.13 0.75 7.255 0.755 7.375 0.775C8.57 0.915 9.38 1.75 9.485 2.96C9.5 3.105 9.38 3.225 9.235 3.225H8.46C7.98 3.225 7.535 3.41 7.215 3.74C6.835 4.11 6.645 4.63 6.69 5.15C6.77 6.06 7.57 6.775 8.52 6.775H9.235C9.38 6.775 9.5 6.895 9.485 7.04Z"
                      fill="#ABABAB"
                    />
                  </svg>
                  {`\u00A0` + ((minMealPrice === Infinity) ? "$0.00" : (priceFormatterUsd(minMealPrice)) + ` - ` + priceFormatterUsd(maxMealPrice))}
                </span>
              </div>

              {/* <p className="text-green-600 my-4">[Recipient’s] Preferences</p>
              <PreferencesPanel /> */}

              {
                Object.keys(_menuDataList?.data || {})?.length > 0
                  ?
                  <>
                    <InitialDiv className="!mt-[-10px]">
                      <FontAwesomeIcon icon={faPlateUtensils} style={{ color: "#aeaeae", position: "relative", top: "35px", left: "10px" }} />
                      <InputWithIcon
                        type="text"
                        style={{ padding: "6px 10px 10px 40px" }}
                        //pl-[40px] 
                        className="rounded-[50px]"
                        value={filterTerm}
                        onChange={handleFilterChange}
                        placeholder="Search menu items..."
                      />
                    </InitialDiv>

                    <MenuCategories
                      categories={(filterTerm !== "" ? searchByName(filterTerm) : Object.keys(_menuDataList?.data || {}))}
                      onChange={(category) => handleChangeCuisine(category)}
                      selected={category}
                    />
                  </>
                  :
                  ""
              }
            </ContentSticky>

            <Section>
              {/* <UserDietaryRestrictionsPreview restrictions={user.diet.restrictions} /> */}
              {/* <FeaturedFood foods={[]} /> */}
              <Divider />

              {Object.keys(_menuDataList?.data || {})?.length > 0
                ?
                <MenuItemList
                  restaurant={searchedRestaurants}
                  filterTerm={filterTerm}
                  title={category || Object.keys(_menuDataList?.data || {})?.[0]}
                  items={
                    _menuDataList?.data[category || Object.keys(_menuDataList?.data || {})?.[0]] || []
                  }
                />
                :
                (
                  showWarningMsg
                    ?
                    null
                    :
                    <InitialDiv>
                      <div className="w-full text-center flex flex-row justify-center h-full mt-[50px]">
                        <InitialDiv >
                          <TextHead4 className="!text-[#a30000]">Unfortunately, this restaurant’s menu is not populated at this time.  We’re disappointed too!</TextHead4>
                          <TextHead4 className="!text-[#a30000] mt-2">In your profile, please send us feedback so we can petition for their menu</TextHead4>
                        </InitialDiv>
                      </div>
                    </InitialDiv>
                )
              }
              <AddToFaveFloating menuDataList={_menuDataList} restaurant={searchedRestaurants} />
            </Section>
          </div>
        </div>
      )}
    </div>
  );
}
