import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/pro-solid-svg-icons";
import {
  faBuilding,
  faHome,
  faMapLocationDot,
} from "@fortawesome/pro-solid-svg-icons";
import {
  Astk,
  ButtonGreen,
  Flex,
  FlexBetween,
  InitialDiv,
  InputLabel,
  InputText,
  SubText1,
  TextHead3,
  moveTopBottom,
} from "../../styled/styled";
import AddressAutocomplete2 from "../address/AddressAutocomplete2";
import TabsForAddress from "../toggleable-tabs/TabsForAddress";
import OneSideTextToggle from "../buttons/OneSideTextToggle";
import {
  countryCodeFilter,
  onKeyDownNonDecimalFilter,
  removeMask,
  sleep,
  switchSentenceCase,
  validateEmail,
  validatePhone,
  maxAddressCharLimit,
  maxStateCharLimit,
  maxCityCharLimit,
  maxCountryCharLimit,
  maxZipCodeCharLimit,
  trackPixelEvent,
  maxUserCharLimit,
} from "../../../utils/utilities";
import { useSelector } from "react-redux";
import {
  getUserInfo,
  getUserData,
  setUserData,
  changeEmailPhone,
  verifyEmailPhone,
  userProfileUpdate,
} from "../../../services/user";
import { addUserLocation, getLatLong } from "../../../services/location";
import { setUserDeliveryAddress } from "../../../pages/profile/redux/locationActionCreator";
import Spinner from "../spinner/Spinner";
import Divider from "../divider/Divider";
import InitialModalCenter from "./InitialModalCenter";
import { useIonAlert, useIonViewDidEnter } from "@ionic/react";
import { setUserSearchLocation } from "../address/redux/addressActionCreator";
import { setNearByRestaurants } from "../../../pages/search/redux/searchActionCreator";
import useDarkMode from "../../hooks/darkmode/DarkMode";
import OtpInput from "react18-input-otp";
import PhoneInput from "react-phone-number-input";
import { stateOptions, countryOptions } from "../../../common/constant";
import { objectType } from "../../../type";
import { Tooltip } from "antd";

const ModalDiv = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1000;
  overflow-y: auto;
`;

const ContentDiv = styled.div<{ isDarkMode: boolean }>`
  background-color: ${(props) => (props?.isDarkMode ? "#2e2e2e" : "#fff")};
  margin-bottom: 85px;
  margin-top: 80px;
  padding: 10px;
  z-index: 1000;
  width: 600px;
  max-width: 90%;
  border-radius: 20px;
  text-align: center;
  height: fit-content;
  animation: ${moveTopBottom} 0.4s ease-in-out;
`;

const BackModalBtn = styled.div`
  position: relative;
  right: 49%;
  font-size: 17px;
  cursor: pointer;
`;

export default function ModalAddAddressCart(props: any) {
  //******************* Fetching States *************************
  const modalRef = useRef();
  const userData = getUserInfo();
  const [presentAlert] = useIonAlert();
  const isDarkMode = useDarkMode();

  const { userSearchLocation } = useSelector(
    (state: any) => state.getUserSearchLocation
  );
  useIonViewDidEnter(() => {
    trackPixelEvent("Add location");
  }, []);

  //*******************  Others Methods *************************
  const addressTypes = [
    {
      icon: faHome,
      name: "Home",
    },
    {
      icon: faBuilding,
      name: "Work",
    },
    {
      icon: faMapLocationDot,
      name: "Other",
    },
  ];

  //******************* Hooks *************************
  // Error Handler
  const [addressLine1Error, setAddressLine1Error] = useState(false);
  const [cityError, setCityError] = useState(false);
  const [stateError, setStateError] = useState(false);
  const [countryError, setCountryError] = useState(false);
  const [zipError, setZipError] = useState(false);
  const [addressState, setAddressState] = useState(
    props?.addressList?.length === 0 && userData?.id === props?.userId
      ? true
      : false
  );
  const [active, setActive] = useState(addressTypes[0]?.name);
  const [loading, setLoading] = useState(false);
  const [showChangeModal, setShowChangeModal] = useState(false);
  const [userPhoneNo, setUserPhoneNo] = useState("");
  const [apiResponse, setApiResponse] = useState("");
  const [showVerify, setShowVerify] = useState(
    userData?.email !== null && userData?.phone_no !== null ? true : false
  );
  const [readOnlyEmailData, setReadOnlyEmailData] = useState(false);
  const [readOnlyPhoneData, setReadOnlyPhoneData] = useState(false);
  const [responseData, setResponseData] = useState<any>({});
  const [awaiting, setAwaiting] = useState(false);
  const [resendWait, setResendWait] = useState(false);
  const [userEmail, setUserEmail] = useState("");
  const [userName, setUserName] = useState(
    userData?.name !== null ? userData?.name : ""
  );
  const [userEmailError, setUserEmailError] = useState(false);
  const [userNameError, setUserNameError] = useState(false);
  const time = 30;
  const [addressType, setAddressType] = useState(addressTypes[0]?.name);
  const [otherValue, setOtherValue] = useState("");
  const [otherError, setOtherError] = useState(false);
  const [otp, setOtp] = useState(process.env.REACT_APP_OTP_BYPASS || "");
  const [otpError, setOtpError] = useState("");
  const [userPhoneNoError, setUserPhoneNoError] = useState(false);
  const [seconds, setSeconds] = useState(0);

  const [selectedLocation, setSelectedLocation] = useState({
    streetNum: "",
    addressLine1: "",
    addressLine2: "",
    streetAddress: "",
    streetName: "",
    city: "",
    state: "",
    country: "",
    zip: "",
    lat: "",
    lang: "",
  });

  //******************* first time page rendering *************************
  useEffect(() => {
    setSelectedLocation(userSearchLocation);

    if (userSearchLocation?.city && userSearchLocation?.country) {
      if (userSearchLocation.addressLine1 === "") {
        setAddressLine1Error(true);
      } else {
        setAddressLine1Error(false);
      }

      if (userSearchLocation.city === "") {
        setCityError(true);
      } else {
        setCityError(false);
      }

      if (userSearchLocation.state === "") {
        setStateError(true);
      } else {
        setStateError(false);
      }

      if (userSearchLocation.country === "") {
        setCountryError(true);
      } else {
        setCountryError(false);
      }

      if (userSearchLocation.zip === "") {
        setZipError(true);
      } else {
        setZipError(false);
      }
    }
    if (
      !userSearchLocation.addressLine1 &&
      !userSearchLocation.addressLine2 &&
      userSearchLocation?.city &&
      userSearchLocation?.country
    ) {
      let addressLine1 = `${
        userSearchLocation?.streetNum !== "" &&
        userSearchLocation?.streetNum !== undefined
          ? userSearchLocation?.streetNum
          : ""
      }${
        userSearchLocation?.streetNum !== "" &&
        userSearchLocation?.streetNum !== undefined &&
        userSearchLocation?.streetName !== "" &&
        userSearchLocation?.streetName !== undefined
          ? " "
          : ""
      }${
        userSearchLocation?.streetName !== "" &&
        userSearchLocation?.streetName !== undefined
          ? userSearchLocation?.streetName
          : ""
      }`;

      if (addressLine1 === "" || addressLine1 === undefined) {
        // setLocateMeError(true);
      } else {
        // setLocateMeError(false);
      }
      setSelectedLocation((prevValues) => {
        return {
          ...prevValues,
          addressLine1: addressLine1,
        };
      });
    }
  }, [userSearchLocation]);

  //*******************  Functions *************************
  const closeModal = (e: any) => {
    if (modalRef.current === e.target) {
      props?.setShowNewAddressModal(false);
    }
  };

  const onChooseAddress = async (val: any) => {
    setSelectedLocation(val);
  };

  const handleChange = (e: any) => {
    e.preventDefault();
    const { name, value } = e.target;

    if (name === "addressLine1") {
      if (value?.length > e.target.maxLength) {
        return false;
      } else {
        // setLocateMeError(false);
        if (String(value).trim()?.length === 0 || value === undefined) {
          setAddressLine1Error(true);
        } else {
          setAddressLine1Error(false);
        }
      }
    }

    if (name === "city") {
      if (value?.length > e.target.maxLength) {
        return false;
      } else {
        if (String(value).trim()?.length === 0 || value === undefined) {
          setCityError(true);
        } else {
          setCityError(false);
        }
      }
    }

    if (name === "state") {
      if (value?.length > e.target.maxLength) {
        return false;
      } else {
        if (String(value).trim()?.length === 0 || value === undefined) {
          setStateError(true);
        } else {
          setStateError(false);
        }
      }
    }

    if (name === "country") {
      if (value?.length > e.target.maxLength) {
        return false;
      } else {
        if (String(value).trim()?.length === 0 || value === undefined) {
          setCountryError(true);
        } else {
          setCountryError(false);
        }
      }
    }
    if (name === "zip") {
      if (value?.length > e.target.maxLength) {
        return false;
      } else {
        if (String(value).trim()?.length === 0 || value === undefined) {
          setZipError(true);
        } else {
          setZipError(false);
        }
      }
    }
    setSelectedLocation({
      ...selectedLocation,
      [name]: value,
    });
  };

  const handleKeyDown = (e: any) => {
    if (e.key === "Enter" && showChangeModal) {
      verify(e);
    }
  };

  const onHandleSubmit = async (e) => {
    // -------------------For Verify Phone or email if not available-----------------------------------
    let infoType = userData?.email === null ? "email" : "phone";
    try {
      const { addressLine1, city, state, country, zip } = selectedLocation;
      const validEmail = validateEmail(userEmail);
      const validPhoneNo = validatePhone(removeMask(userPhoneNo));

      if (addressType === "Other" && otherValue === "") {
        setOtherError(true);
      } else {
        setOtherError(false);
      }

      if (
        String(addressLine1).trim()?.length === 0 ||
        addressLine1 === undefined
      ) {
        setAddressLine1Error(true);
      } else {
        setAddressLine1Error(false);
      }

      if (String(city).trim()?.length === 0 || city === undefined) {
        setCityError(true);
      } else {
        setCityError(false);
      }

      if (String(state).trim()?.length === 0 || state === undefined) {
        setStateError(true);
      } else {
        setStateError(false);
      }

      if (String(country).trim()?.length === 0 || country === undefined) {
        setCountryError(true);
      } else {
        setCountryError(false);
      }

      if (String(zip).trim()?.length === 0 || zip === undefined) {
        setZipError(true);
      } else {
        setZipError(false);
      }

      if (props?.showUserNotFilledDataInput === true) {
      } else {
        if (userData?.name === null) {
          if (userName.trim() === "" || userName?.length < 3) {
            setUserNameError(true);
          } else {
            setUserNameError(false);
          }
        }

        if (userData?.email === null) {
          if (!userEmail || !validEmail) {
            setUserEmailError(true);
          } else {
            setUserEmailError(false);
          }
        }

        if (userData?.phone_no === null) {
          if (!userPhoneNo || !validPhoneNo) {
            setUserPhoneNoError(true);
          } else {
            setUserPhoneNoError(false);
          }
        }
      }
      if (
        String(addressLine1).trim() === "" ||
        addressLine1 === undefined ||
        String(city).trim() === "" ||
        city === undefined ||
        String(state).trim() === "" ||
        state === undefined ||
        String(country).trim() === "" ||
        country === undefined ||
        String(zip).trim() === "" ||
        zip === undefined ||
        // showVerify === false ||
        otherError ||
        (!props?.showUserNotFilledDataInput && !userName) ||
        userNameError ||
        userEmailError ||
        userPhoneNoError
      ) {
        setLoading(false);
        return false;
      }

      setLoading(true);

      // ___________________ Adding User Email and phone, if not available _________________________________
      if (props?.showUserNotFilledDataInput === true) {
        handleAddAddress();
      } else {
        if (
          userData?.email === null ||
          userData?.phone_no === null ||
          userData?.email === "" ||
          userData?.phone_no === ""
        ) {
          updateEmailPhone(infoType);
        } else {
          handleAddAddress();
        }
      }
      // ___________________ End _________________________________
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  const handleAddAddress = async () => {
    try {
      let {
        streetNum,
        streetAddress,
        addressLine1,
        addressLine2,
        streetName,
        city,
        state,
        country,
        zip,
        lat,
        lang,
      } = selectedLocation;

      if (!lat || !lang) {
        let address = `${
          addressLine1 + ", " + city + " " + state + ", " + country + ", " + zip
        }`;
        const placeObject = await getLatLong(address);

        if (placeObject) {
          lat = placeObject?.lat;
          lang = placeObject?.lng;
          streetNum = placeObject?.streetNum;
          streetName = placeObject?.streetName;
          streetAddress = placeObject?.streetAddress;
        }
      }

      // ___________________ Adding User name if not available _________________________________
      if (
        !props?.showUserNotFilledDataInput &&
        (userData?.name === null || userData?.name === "")
      ) {
        const data = {
          name: userName,
        };
        await userProfileUpdate(data);
      }
      // ___________________ End _________________________________

      const payloadObject = {
        data: {
          house_no: streetNum,
          street_address: streetAddress,
          addressLine1: addressLine1,
          addressLine2: addressLine2,
          area: streetName,
          city: city,
          state: state,
          country: country,
          zip_code: zip,
          type: addressType === "Other" ? otherValue : addressType,
          default_address: addressState,
          latitude: lat,
          longitude: lang,
          user: props?.userId,
        },
      };
      const localData = await getUserData();

      const response = await addUserLocation(payloadObject);
      if (response?.status === 200) {
        trackPixelEvent("Add user location");
        setLoading(false);
        props?.setShowAddressModal((prev) => !prev);
        if (userData?.id === props?.userId) {
          if (addressState === true) {
            const newUpdatedUserInfo = {
              ...localData,
              user: {
                ...localData.user,
                city: city,
                name: userName ? userName : userData?.name,
              },
            };
            await setUserData(newUpdatedUserInfo);
          }
          const data = response?.data?.data;
          const getFormatAddress = {
            lat: data?.latitude,
            lang: data?.longitude,
            streetName: data?.addressLine1,
            streetNum: data?.addressLine2,
            streetAddress: data?.street_address,
            city: data?.city,
            state: data?.state,
            country: data?.country,
            zip: data?.zip_code,
            defaultAddressType: data?.default_address,
            timezone: data?.timezone,
            id: data?.id,
          };
          setUserDeliveryAddress(getFormatAddress);
          props?.setOpenAccordion && props?.setOpenAccordion(false);
          setUserSearchLocation({});
          setNearByRestaurants([]);
        }
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleChangeOther = (val: any) => {
    setOtherError(false);
    setOtherValue(val);
  };

  function handleChangeState() {
    if (props?.addressList?.length === 0 && userData?.id === props?.userId) {
      setAddressState(true);
    } else {
      setAddressState(!addressState);
    }
  }

  // user Phone Number Validation
  const _setUserPhoneNo = (value: string) => {
    const validPhoneNo = validatePhone(removeMask(value));
    setUserPhoneNo(value);
    if (!validPhoneNo) {
      setUserPhoneNoError(true);
    } else {
      setUserPhoneNoError(false);
    }
  };

  // user name Validation
  const _setUserName = (e: any) => {
    let value = e.target.value;
    if (value?.length > e.target.maxLength) {
      return false;
    }
    if (value.trim() === "" || value?.length < 3) {
      setUserNameError(true);
    } else {
      setUserNameError(false);
    }
    setUserName(value);
  };

  // Email Validation
  const _setUserEmail = (value: any) => {
    setUserEmail(value);
    const validEmail = validateEmail(value);
    if (String(value).trim()?.length === 0 || !validEmail) {
      setUserEmailError(true);
    } else {
      setUserEmailError(false);
    }
  };

  const updateEmailPhone = async (type: any) => {
    try {
      setApiResponse("");
      // Validation-----------------------

      if (type === "email") {
        const validEmail = validateEmail(userEmail);
        if (userEmail.trim()?.length === 0 || !validEmail) {
          setUserEmailError(true);
          setLoading(false);
          return false;
        } else {
          setUserEmailError(false);
        }
      } else if (type === "phone") {
        const validPhoneNo = validatePhone(removeMask(userPhoneNo));
        if (userPhoneNo.trim()?.length === 0 || !validPhoneNo) {
          setUserPhoneNoError(true);
          setLoading(false);
          return;
        } else {
          setUserPhoneNoError(false);
        }
      }

      if (userEmailError || userPhoneNoError) {
        return false;
      }

      const data = {
        contact:
          type === "email"
            ? userEmail.toLowerCase()
            : removeMask(countryCodeFilter(userPhoneNo)),
        provider: type === "email" ? "email" : "phone",
      };

      console.log("data", data);

      // Api Call--------------------------

      // setAwaiting(true);
      await changeEmailPhone(data).then(async (response: any) => {
        trackPixelEvent("Chnage email phone");
        let data = response?.data;
        console.log("data response==>", data);
        if (data.status === "pending") {
          if (type === "email") {
            setReadOnlyEmailData(true);
          } else {
            setReadOnlyPhoneData(true);
          }
          // setAwaiting(false);
          setLoading(false);
          setResponseData(data);

          // ***************** For now according to client(mealfave-client) don't required otp verification so i'm commented otp verify modal(setShowChangeModal), so that it can't show modal, and directly verifying using verify function and direct bypass method, so that it looks like no otp required for verification  *********************************
          // setShowChangeModal(true);
          const contactInfo = {
            contact: data?.contact,
            provider: data?.provider,
            status: data?.status,
          };
          verify(contactInfo);
        }
      });
    } catch (error) {
      // setAwaiting(false);
      setLoading(false);
      if (error?.response?.data?.error?.status === 400) {
        presentAlert({
          header: "",
          message: `${
            error?.response?.data?.error?.message ===
            "Phone Number already taken"
              ? "This phone number is associated with another account."
              : error?.response?.data?.error?.message === "Email already taken"
              ? "This email is associated with another account."
              : error?.response?.data?.error?.message
          }`,
          buttons: ["OK"],
        });
      } else {
        presentAlert({
          header: "",
          message: `${error?.response?.data?.error?.message}`,
          buttons: ["OK"],
        });
      }
    }
  };

  // Timer for user to wait between resending otp.
  const startCountdown = async () => {
    setResendWait(true);
    for (let i = time; i >= 0; i--) {
      await sleep(1000);
      setSeconds(i);
      if (i === 0) {
        setResendWait(false);
        break;
      }
    }
  };

  const resendOtp = async (e: any) => {
    try {
      if (awaiting || resendWait) {
        return false;
      }
      startCountdown();
      setOtp("");
      setOtpError("");

      const data = {
        contact:
          userData?.email === null
            ? userEmail.toLowerCase()
            : removeMask(countryCodeFilter(userPhoneNo)),
        provider: userData?.email === null ? "email" : "phone",
      };

      // Api Call--------------------------
      setAwaiting(true);
      await changeEmailPhone(data).then(async (response: any) => {
        trackPixelEvent("Chnage email phone");
        let data = response?.data;
        if (data.status === "pending") {
          setAwaiting(false);
          setResponseData(data);
        }
      });
    } catch (error) {
      setAwaiting(false);
      const errMsg = error?.response?.data?.error?.message || "";
      setApiResponse(errMsg);
    }
  };

  const verify = async (e: any) => {
    console.log("e", e);

    // Validation-----------------------
    if (otp === "") {
      setOtpError("Please Enter OTP First !");
    } else if (otp?.length < 6 || otp?.length > 6) {
      setOtpError("OTP Should Contain 6 Digits !");
    } else if (otp?.length === 6) {
      setOtpError("");

      // Api Call--------------------------
      try {
        setAwaiting(true);
        const data = {
          contact: responseData?.contact || e.contact,
          otp: otp,
          provider: responseData?.provider || e.provider,
        };

        const localData = await getUserData();
        await verifyEmailPhone(data).then(async (response: any) => {
          trackPixelEvent("Verify email phone");
          let data = response?.data;
          if (response.status === 200) {
            const newUpdatedUserInfo = {
              ...localData,
              user: {
                ...localData.user,
                email: data?.email,
                phone_no: data?.phone_no,
              },
            };
            await setUserData(newUpdatedUserInfo);
            setResponseData({});
            setShowVerify(true);
            handleAddAddress();
            // setShowChangeModal(false);
            setAwaiting(false);
            setOtp("");
          }
        });
      } catch (error) {
        setAwaiting(false);
        const err = error?.response?.data?.error?.message;
        if (String(err).toLowerCase().includes("invalid")) {
          setOtpError("Code is invalid. Try again or →");
        }
      }
    }
  };

  return (
    <>
      {props?.showAddressModal && (
        <ModalDiv onClick={closeModal}>
          <ContentDiv isDarkMode={isDarkMode}>
            <div className="grow overflow-auto">
              <InitialDiv className="mx-2">
                <BackModalBtn>
                  <FontAwesomeIcon
                    icon={faChevronLeft}
                    onClick={() =>
                      props?.setShowNewAddressModal((prev: any) => !prev)
                    }
                  />
                </BackModalBtn>
                <TextHead3
                  style={{
                    marginBottom: "15px",
                    marginTop: "-25px",
                    textAlign: "center",
                  }}
                >
                  ADD ADDRESS DETAILS
                </TextHead3>

                <Tooltip title="Address and contact info are needed to search for nearby restaurants and for delivery and pickup order processing.">
                  <span
                    className="text-green-600 cursor-pointer underline text-sm"
                    title="Detailed info here!"
                  >
                    Why ?
                  </span>
                </Tooltip>

                <InitialDiv className="my-6">
                  <AddressAutocomplete2
                    onAddressChosen={(val: any) => {
                      onChooseAddress(val);
                    }}
                    place={null}
                  />
                </InitialDiv>
                {/* {(process.env.REACT_APP_HIDE_LOCATE_ME !== "true") &&
                    <ButtonInitial
                      className="mt-2 h-10 !w-full"
                      style={{ border: "1px solid green" }}
                      onClick={fetchCurrentLocation}
                    // onClick={() => { onUseCurrentLocation(); setSelectedLocation(currentPlace) }}
                    >
                      <span className="text-green-600">
                        <FontAwesomeIcon icon={faLocationCrosshairs} style={{ color: "#22A45D", marginRight: "10px" }} />
                        {switchSentenceCase("primary button", "Locate Me")}
                      </span>
                    </ButtonInitial>
                  }
                  {(locateMeError) ?
                    <SubText1 className="!text-red-600 mt-2">Unable to locate “Street Address.” Please enter below.</SubText1>
                    :
                    ""
                  } */}
                <InputLabel className="mt-2">
                  Address Line1<Astk> *</Astk>
                </InputLabel>
                <InputText
                  style={{
                    marginBottom: "20px",
                    border: addressLine1Error ? "1px solid red" : "",
                  }}
                  placeholder="Street Address, P.O. box, company name, c/o - addressLine1"
                  name="addressLine1"
                  value={selectedLocation?.addressLine1 || ""}
                  maxLength={maxAddressCharLimit}
                  onChange={(e) => handleChange(e)}
                ></InputText>

                <InputLabel>Address Line2</InputLabel>
                <InputText
                  style={{
                    marginBottom: "20px",
                  }}
                  placeholder="Apartment, suite, unit, building, floor, etc (optional). - addressLine2"
                  name="addressLine2"
                  value={selectedLocation?.addressLine2 || ""}
                  maxLength={maxAddressCharLimit}
                  onChange={(e) => handleChange(e)}
                ></InputText>

                {/* <InputLabel>Area, Colony <Astk> *</Astk></InputLabel>
                  <InputText
                    style={{
                      marginBottom: "20px",
                      border: streetNameError ? "1px solid red" : "",
                    }}
                    placeholder="e.g. Fusce Rd."
                    name="streetName"
                    value={selectedLocation?.streetName || ""}
                    onChange={(e) => handleChange(e)}
                  >
                  </InputText> */}

                <FlexBetween>
                  <InitialDiv className="mx-1 w-6/12">
                    <InputLabel>
                      City <Astk> *</Astk>
                    </InputLabel>
                    <InputText
                      style={{
                        marginBottom: "20px",
                        border: cityError ? "1px solid red" : "",
                      }}
                      placeholder="e.g. Frederick"
                      name="city"
                      value={selectedLocation?.city || ""}
                      maxLength={maxCityCharLimit}
                      onChange={(e) => handleChange(e)}
                    ></InputText>
                  </InitialDiv>
                  <InitialDiv className="mx-1 w-6/12">
                    <InputLabel>
                      State <Astk> *</Astk>
                    </InputLabel>
                    <select
                      style={{
                        marginBottom: "20px",
                        border: countryError
                          ? "1px solid red"
                          : "1px solid #e2e2e2",
                        width: "100%",
                        height: "40px",
                        background: "none",
                        color:
                          selectedLocation?.state !== "" &&
                          selectedLocation?.state !== undefined
                            ? isDarkMode
                              ? "#ffffff"
                              : "#000"
                            : "#9ca3af",
                        outline: "none",
                      }}
                      name="state"
                      value={selectedLocation?.state || ""}
                      onChange={(e) => handleChange(e)}
                    >
                      <option value="" disabled>
                        Select State
                      </option>
                      {stateOptions?.map((state: objectType) => (
                        <option
                          key={state.value}
                          value={state?.value}
                          style={{ color: "#9ca3af" }}
                        >
                          {state?.value}
                        </option>
                      ))}
                    </select>
                    {/* <InputText
                        style={{
                          marginBottom: "20px",
                          border: stateError ? "1px solid red" : "",
                        }}
                        placeholder="e.g. NY"
                        name="state"
                        value={selectedLocation?.state || ""}
                        maxLength={maxStateCharLimit}
                        onChange={(e) => handleChange(e)}
                      >
                      </InputText> */}
                  </InitialDiv>
                </FlexBetween>

                <FlexBetween>
                  <InitialDiv className="mx-1 w-6/12">
                    <InputLabel>
                      Country <Astk> *</Astk>
                    </InputLabel>
                    <select
                      style={{
                        marginBottom: "20px",
                        border: countryError
                          ? "1px solid red"
                          : "1px solid #e2e2e2",
                        width: "100%",
                        height: "40px",
                        background: "none",
                        color:
                          selectedLocation?.country !== "" &&
                          selectedLocation?.country !== undefined
                            ? isDarkMode
                              ? "#ffffff"
                              : "#000"
                            : "#9ca3af",
                        outline: "none",
                      }}
                      name="country"
                      value={selectedLocation?.country || ""}
                      onChange={(e) => handleChange(e)}
                    >
                      <option value="" disabled>
                        Select Country
                      </option>
                      {countryOptions.map((country: string) => (
                        <option
                          key={country}
                          value={country}
                          className="custom-option"
                        >
                          {country}
                        </option>
                      ))}
                    </select>
                    {/* <InputText
                        style={{
                          marginBottom: "20px",
                          border: countryError ? "1px solid red" : "",
                        }}
                        placeholder="e.g. US"
                        name="country"
                        value={selectedLocation?.country || ""}
                        maxLength={maxCountryCharLimit}
                        onChange={(e) => handleChange(e)}
                      >
                      </InputText> */}
                  </InitialDiv>
                  <InitialDiv className="mx-1 w-6/12">
                    <InputLabel>
                      Zip Code <Astk> *</Astk>
                    </InputLabel>
                    <InputText
                      type="number"
                      inputMode="numeric"
                      style={{
                        marginBottom: "20px",
                        border: zipError ? "1px solid red" : "",
                      }}
                      placeholder="e.g. 20620"
                      name="zip"
                      value={selectedLocation?.zip || ""}
                      onChange={(e) => handleChange(e)}
                      maxLength={maxZipCodeCharLimit}
                      onKeyDown={onKeyDownNonDecimalFilter}
                    ></InputText>
                  </InitialDiv>
                </FlexBetween>
                {props?.showUserNotFilledDataInput === true ? (
                  ""
                ) : userData?.name === null ? (
                  <InitialDiv className="w-full">
                    <InputLabel>
                      Your Name<Astk> *</Astk>
                    </InputLabel>
                    <InputText
                      type="email"
                      placeholder="Enter Your Name"
                      name="username"
                      style={{
                        marginTop: "10px",
                        border: userNameError ? "1px solid red" : "",
                      }}
                      value={userName}
                      onChange={(event) => _setUserName(event)}
                      maxLength={maxUserCharLimit}
                    ></InputText>
                  </InitialDiv>
                ) : null}
                <Flex className="items-center">
                  {props?.showUserNotFilledDataInput === true
                    ? ""
                    : (readOnlyEmailData || userData?.email === null) && (
                        <>
                          <InitialDiv className="mt-2 w-full">
                            <InputLabel>
                              Your Email<Astk> *</Astk>
                            </InputLabel>
                            <InputText
                              type="email"
                              placeholder="Enter Your Email"
                              name="useremail"
                              style={{
                                marginTop: "10px",
                                border: userEmailError ? "1px solid red" : "",
                              }}
                              value={userEmail}
                              onChange={(event) =>
                                _setUserEmail(event.target.value)
                              }
                              readOnly={showVerify}
                            ></InputText>
                          </InitialDiv>
                          {/* <InitialDiv className="mt-7">
                          {awaiting ? <Spinner /> : (showVerify ? <SubText1 className="!text-green-600">Verified</SubText1> : <ButtonGreen className="!w-[85px] h-[35px]" onClick={() => updateEmailPhone("email")}>Send OTP</ButtonGreen>)}
                        </InitialDiv> */}
                        </>
                      )}
                  {props?.showUserNotFilledDataInput === true
                    ? ""
                    : (readOnlyPhoneData || userData?.phone_no === null) && (
                        <>
                          <InitialDiv className="mt-2 w-full">
                            <InputLabel>
                              Mobile Number<Astk> *</Astk>
                            </InputLabel>
                            <PhoneInput
                              className={isDarkMode ? "country-selector" : ""}
                              international
                              countryCallingCodeEditable={false}
                              defaultCountry="US"
                              name="phoneno"
                              value={userPhoneNo}
                              placeholder="e.g.+1 501 333 9827"
                              limitMaxLength={true}
                              onChange={(event) => _setUserPhoneNo(event)}
                              style={{
                                marginTop: "10px",
                                border: userPhoneNoError ? "1px solid red" : "",
                              }}
                              readOnly={showVerify}
                            />
                          </InitialDiv>
                          {/* <InitialDiv className="mt-7">
                          {awaiting ? <Spinner /> : (showVerify ? <SubText1 className="!text-green-600">Verified</SubText1> : <ButtonGreen className="!w-[85px] h-[35px]" onClick={() => updateEmailPhone("phone")}>Send OTP</ButtonGreen>)}
                        </InitialDiv> */}
                        </>
                      )}
                </Flex>
                <TabsForAddress
                  types={addressTypes}
                  setAddressType={setAddressType}
                  setActive={setActive}
                  selected={active}
                />
                {addressType === "Other" ? (
                  <InputText
                    type="text"
                    style={{
                      border: otherError ? "1px solid red" : "",
                    }}
                    min={0}
                    value={otherValue}
                    placeholder="Other"
                    maxLength={15}
                    onChange={(event: any) =>
                      handleChangeOther(event.target.value)
                    }
                  />
                ) : null}
                <OneSideTextToggle
                  leftText="Save as Default Address?"
                  style={{ fontWeight: "500" }}
                  switchState={addressState}
                  handleOnChangeToggle={() => handleChangeState()}
                />
              </InitialDiv>

              <InitialDiv className="mx-2">
                {loading ? (
                  <div className="text-center">
                    <Spinner />
                  </div>
                ) : (
                  <ButtonGreen onClick={onHandleSubmit}>
                    {switchSentenceCase("primary button", "SAVE ADDRESS")}
                  </ButtonGreen>
                )}
              </InitialDiv>
            </div>
          </ContentDiv>
        </ModalDiv>
      )}

      {/* ********************************** Change Email and verify Modal********************************************** */}
      {showChangeModal && (
        <InitialModalCenter
          showModal={showChangeModal}
          setShowModal={setShowChangeModal}
        >
          <InitialDiv className="m-4">
            <Flex className="items-center">
              <FontAwesomeIcon
                className="cursor-pointer"
                icon={faChevronLeft}
                onClick={() => setShowChangeModal((prev: any) => !prev)}
              />
              <InitialDiv className="w-full text-center">
                <TextHead3 className="">
                  Verify{userData?.phone_no === null ? " Number" : " Email"}
                </TextHead3>
              </InitialDiv>
            </Flex>
          </InitialDiv>
          <Divider />
          <InitialDiv className="m-4 text-left">
            <InitialDiv>
              <FlexBetween>
                <InputLabel className="mb-[10px]">
                  {`Enter code sent to ${responseData?.contact}`}
                </InputLabel>
              </FlexBetween>
              <div>
                <form onKeyDown={handleKeyDown}>
                  <OtpInput
                    value={otp}
                    shouldAutoFocus={true}
                    onChange={setOtp}
                    numInputs={6}
                    isInputNum={true}
                    separator={<span>-</span>}
                    containerStyle="justify-center"
                    inputStyle="bg-transparent !w-10 h-10 border m-[0.2rem]"
                  />
                </form>
              </div>
              <FlexBetween>
                {otpError ? (
                  <p className="mt-2 text-rose-800 whitespace-nowrap">
                    {otpError}
                  </p>
                ) : (
                  <p></p>
                )}
                <p className="mt-2 text-red-600 text-center">
                  <a
                    onClick={resendOtp}
                    style={{ color: seconds > 0 ? "grey" : "" }}
                  >
                    Resend Code {seconds > 0 ? `(in ${seconds}s)` : ""}
                  </a>
                </p>
              </FlexBetween>
            </InitialDiv>

            <InitialDiv>
              {awaiting === false ? (
                <ButtonGreen className="mt-[15px]" onClick={verify}>
                  {switchSentenceCase("primary button", "CONTINUE")}
                </ButtonGreen>
              ) : (
                <div className="text-center">
                  <Spinner />
                </div>
              )}
              {apiResponse.length > 0 && (
                <SubText1 className="text-center mt-5">
                  <label className="text-red-600 mt-5">{apiResponse}</label>
                </SubText1>
              )}
            </InitialDiv>
          </InitialDiv>
        </InitialModalCenter>
      )}
    </>
  );
}
