import { faMinus, faPlus } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useIonAlert } from "@ionic/react";
import styled from "styled-components";

const LabelText = styled.div`
   font-size: 17px;
   margin-left: 5px;
   width: -webkit-fill-available;
`;

const Item = styled.div`
  display: flex;
  align-items: center;
  height: 48px;
  position: relative;
  margin-bottom: 10px;
  margin-top: 10px;
`;

const styleDiv = {
  display: "flex",
  alignItems: "center",
  justifyContent: "start",
  width: "-webkit-fill-available",
}

const buttonStyle = {
  padding: "8px",
  background: "#22A45D",
  cursor: "pointer",
  color: "white",
}

const countNumberStyle = {
  padding: "2px 10px",
  borderTop: "2px solid #22A45D",
  borderBottom: "2px solid #22A45D",
  cursor: "pointer",
}

export default function QuantitySelector(props: any) {
  const [presentAlert] = useIonAlert();

  const increaseQuantity = (quantity: any) => {
    if (props?.selected_quantity < props?.max_quantity && props?.max_choice_options > props?.selected_total_quantity) {
      props?.changeQuantity(quantity)
    }

    // Max Quantity Validation
    if (props?.max_choice_options <= props?.selected_total_quantity) {
      presentAlert({
        header: 'Max quantity selected',
        buttons: ['OK'],
      })
    }
  }

  const decreaseQuantity = (quantity: any) => {
    if (props?.selected_quantity >= 1) {
      if (props?.min_quantity < props?.selected_quantity) {
        props?.changeQuantity(quantity)
      }
    }
  }


  return (
    <>
      <Item style={props?.border}>
        <div style={styleDiv}>

          {(props?.selected_quantity === 0)
            &&
            <div className="flex items-stretch justify-between mr-[8px] ml-[8px]">
              <FontAwesomeIcon
                icon={faPlus}
                style={{ ...buttonStyle, borderRadius: "6px", }}
                onClick={() => increaseQuantity(props?.selected_quantity + 1)}
              />
            </div>
          }

          {(props?.selected_quantity > 0)
            &&
            <div className="flex items-stretch justify-between mr-[8px] ml-[8px]">
              <FontAwesomeIcon
                icon={faMinus}
                style={{ ...buttonStyle, borderTopLeftRadius: "6px", borderBottomLeftRadius: "6px" }}
                onClick={() => decreaseQuantity(props?.selected_quantity - 1)}
              />

              <span style={countNumberStyle}>
                {props?.selected_quantity}
              </span>

              <FontAwesomeIcon
                icon={faPlus}
                style={{ ...buttonStyle, borderTopRightRadius: "6px", borderBottomRightRadius: "6px" }}
                onClick={() => increaseQuantity(props?.selected_quantity + 1)}
              />
            </div>
          }

          <label htmlFor={props?.customization_id + props?.index}>
            <LabelText style={props.labelStyle}>{props?.label}</LabelText>
          </label>
        </div>
      </Item>
    </>
  );
}
