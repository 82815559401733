const initialValue = {
    searchedRestaurantData: {},
    restaurantsMenuList: [],
    menusDataList: [],
    addMealItem: [],
    mealId: null,
    mealIndex: 0,
}


const getRestaurantData = (state = initialValue, action: any) => {
    switch (action.type) {

        case "SET_RESTAURANT_DATA":
            return { ...state, searchedRestaurantData: action.payload }

        case "GET_RESTAURANT_MENU_DATA":
            return { ...state, restaurantsMenuList: action.payload };


        case "SET_RESTAURANT_MENU_DATA":
            let finalData = {};
            let quoteIds = action.payload?.quoteIds;
            const data = action.payload?.data;

            for (const [key, menu] of Object.entries<any>(data)) {
                for (let j = 0; j < menu.length; j++) {
                    if (!menu[j].quantity) {
                        menu[j].quantity = 0
                    }
                }
                finalData[key] = menu;
            };
            state.menusDataList.push({ quoteIds: quoteIds, data: finalData, schedules: action.payload?.schedules, quote: action.payload?.quote });

            // Remove Duplicates
            const _quoteIds = state.menusDataList.map((o: any) => o?.quoteIds)
            state.menusDataList = state.menusDataList.filter(({ quoteIds }, index) => !_quoteIds.includes(quoteIds, index + 1))
            return { ...state, menusDataList: state.menusDataList, addMealItem: [] };

        case "REMOVE_RESTAURANT_MENU_DATA":
            state.menusDataList = state.menusDataList.filter(({ quoteIds }) => quoteIds !== action.payload)
            return { ...state, menusDataList: state.menusDataList };

        case "ADD_MEAL_ITEM":
            const newList: any[] = action.payload.addMealItem;
            if (newList.length > 0) {
                if (action.payload.type === "update") {
                    const index = state.addMealItem.findIndex((list: any, index: number) => list?.product_id === action.payload.addMealItem[0].product_id && index === action.payload.itemIndex);
                    state.addMealItem[index] = action.payload.addMealItem[0];
                    // Remove Duplicates
                    // const _product_ids = state.addMealItem.map((o: any) => o?.product_id)
                    // state.addMealItem = state.addMealItem.filter(({ product_id }, index) => !_product_ids.includes(product_id, index + 1))
                    return { ...state, addMealItem: state.addMealItem };
                }
                else {
                    newList?.forEach((element: any) => {
                        state.addMealItem.push(element)
                    });
                    return { ...state, addMealItem: state.addMealItem };
                }
            }
            else {
                return { ...state, addMealItem: state.addMealItem }
            }

        case "ADD_MEAL_ID":
            return { ...state, mealId: action.payload.mealId, mealIndex: action.payload.itemIndex };

        case "CHANGE_QUANTITY":

            let menuItems = state.menusDataList?.filter((currentObj: any) => currentObj?.quoteIds == action.payload.quoteId)[0]?.data;

            for (const [key, item] of Object.entries<any>(menuItems || {})) {
                let isFindIndex = false;

                for (let j = 0; j < item.length; j++) {
                    if (item[j].product_id === action.payload.product_id) {
                        menuItems[key][j].quantity = action.payload.quantity;
                        isFindIndex = true
                        break;
                    }
                }
                if (isFindIndex) {
                    break;
                }
            }
            return {
                ...state,
                menusDataList: state.menusDataList,
            };

        case "REMOVE_MEAL_ITEMS":
            return { ...state, addMealItem: [] };

        case "REMOVE_MENU_ITEM_QUANTITY":
            const menuItemsList = state.menusDataList?.filter((currentObj: any) => currentObj?.quoteIds != action.payload.quoteId);
            return { ...state, menusDataList: menuItemsList };


        case "CHANGE_QUANTITY_OF_ORDER_MEAL_ITEM":
            // for (let index = 0; index < state.addMealItem.length; index++) {
            //     if (state.addMealItem[index].product_id === action.payload.product_id) {
            //         state.addMealItem[index].quantity = action.payload.quantity
            //         break;
            //     }
            // }
            const index = state.addMealItem.findIndex((list: any, index: number) => list?.product_id === action.payload.product_id && index === action.payload.itemIndex);
            state.addMealItem[index].quantity = action.payload.quantity;
            return { ...state, addMealItem: state.addMealItem };

        case "REMOVE_SINGLE_CART_ITEM":
            //data.product_id !== action.payload.product_id && 
            const filteredItem = state.addMealItem.filter((data, index) => index !== action.payload.itemIndex);
            return { ...state, addMealItem: filteredItem };

        default:
            return state;
    }
}

export default getRestaurantData;