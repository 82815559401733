import { faAngleLeft } from "@fortawesome/pro-solid-svg-icons";
import { useHistory } from "react-router";
import send_gift_bro from "../../components/assets/dashboard/Send_gift_bro.svg";
import { ButtonGreen, InitialDiv, TextHead1 } from "../../components/styled/styled";
import Divider from "../../components/widgets/divider/Divider";
import TopHead from "../../components/widgets/head/TopHead";
import Section from "../../components/widgets/section/Section";
import { switchSentenceCase } from "../../utils/utilities";
import { setCalendarData } from "../../services/schedule";
import { createScheduleForMeClear, createScheduleForMeStep1 } from "../schedules/redux/createScheduleReduxActions";
import { useDispatch } from "react-redux";



export default function GiftHelps() {
  const history = useHistory();
  const dispatch = useDispatch();

  const GotoHandle = () => {
    history.push({
      pathname: '/home',
    })
  };


  const handleOnClickForGiftOther = async () => {
    // dispatch(createScheduleForMeClear() as any);
    dispatch(createScheduleForMeStep1("for_other") as any);
    let data = {
      step1: "for_other"
    }
    await setCalendarData(data);
  }

  return (
    <>
      <div className="screen">
        <TopHead
          title="Gifts"
          titleStyle={{ color: "#22A45D", fontWeight: "600" }}
          leftTextIcon={faAngleLeft}
          leftIconStyle={{ fontSize: "26px", color: "#22A45D" }}
          goToHandle={GotoHandle}
        />
        <Divider />

        <div className="grow overflow-auto">
          <Section>
            <TextHead1 className="!text-[18px] !font-bold text-center text-[#D97706]">How MealFave Helps You Gift Meals</TextHead1>
          </Section>
          <Section>
            <ol type="1" className="font-medium">
              <li>1. Gift their faves without guessing or cooking</li>
              <li>2. Both you and the recipient can track the order</li>
              <li>3. The recipient can reschedule if plans change up to the day before</li>
              <li>4. You can set up recurring gifts</li>
              <li>5. You can invite friends to gift a meal</li>
              <li>6. It’s way more meaningful than a gift card</li>
            </ol>
          </Section>
          <Section>
            <ButtonGreen
              onClick={() => {
                handleOnClickForGiftOther();
                history.push({
                  pathname: '/gift-form',
                })
              }}
            >
              {switchSentenceCase("primary button", "Continue Gifting")}</ButtonGreen>
          </Section>
          <InitialDiv>
            <img src={send_gift_bro} className="ml-auto mr-auto" alt=" send-gift" />
          </InitialDiv>
        </div>
      </div >
    </>
  );
}


