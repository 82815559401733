import { faLocationDot } from "@fortawesome/pro-solid-svg-icons";
import React, { useState, useEffect } from "react";
import { ButtonInitial, Flex, FlexBetween, InitialDiv, Pills, SubText1, TextHead4 } from "../../styled/styled";
import { setSelectedLocation, setUserDeliveryAddress } from "../../../pages/profile/redux/locationActionCreator";
import { getUserAddress, setLocalDeliveryAddress } from "../../../services/location";
import { clearRestaurantList, setNearByRestaurants, setNearByScheduleRestaurants } from "../../../pages/search/redux/searchActionCreator";
import { useSelector } from "react-redux";
import Spinner from "../spinner/Spinner";
import { switchSentenceCase, trackPixelEvent } from "../../../utils/utilities";
import ModalAddAddressCart from "../modals/ModalAddAddressCart";
import AccordionNew from "../accordian/AccordionNew";
import IonModalAlert from "../alerts/IonModalAlert";
import { setOrderForSomeone } from "../../../pages/orders/redux/upcomingOrderActionCreator";
import { setRecentOrderList, setUserFaveMealList } from "../../../pages/faves/redux/favouriteActionCreator";
import useDarkMode from "../../hooks/darkmode/DarkMode";

export default function AddressList(props: any) {
  const isDarkMode = useDarkMode();
  const { userDeliveryAddress } = useSelector((state: any) => state.userAddresses);
  const { orderForSomeone } = useSelector((state: any) => state?.upcomingOrderState);
  const [openAccordion, setOpenAccordion] = useState(false);
  const [userFetchedAddress, setUserFetchedAddress] = useState<any>([]);
  const [showNewAddressModal, setShowNewAddressModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [selectedAddress, setSelectedAddress] = useState("");
  const [selectedAddressId, setSelectedAddressId] = useState("");
  const [length, setLength] = useState(0);

  const [showIonAlertModal, setShowIonAlertModal] = useState(false);
  const [ionAlertHeader, setIonAlertHeader] = useState("");
  const [ionAlertMessage, setIonAlertMessage] = useState("");
  


  useEffect(() => {
    callAddressData();
  }, []);


  //*******************  Functions *************************
  const callAddressData = async () => {
    if (!props?.userId) {
      return false;
    }
    try {
      setLoading(true);
      const response = await getUserAddress("", props?.userId);
      if (response) {
        trackPixelEvent('Get user address');
        const data = response?.data?.data || [];
        setLength(data?.length);
        let newLength = data?.length;
        setUserFetchedAddress(data);
        const defaultAddress = (newLength > length && length === 0) ? ((props?.selectedId) ? (data?.filter((item: any) => item?.id === props?.selectedId)) : ((data?.filter((item: any) => item?.attributes?.default_address === true)?.length > 0)) ? (data?.filter((item: any) => item?.attributes?.default_address === true)) : (data.slice(-1))) : (data.slice(-1));
        const formattedAddress = (defaultAddress[0]?.attributes?.city) ? (defaultAddress[0]?.attributes?.addressLine1 + (defaultAddress[0]?.attributes?.addressLine2 ? " " + switchSentenceCase("head text", defaultAddress[0]?.attributes?.addressLine2) : "") + ", " + defaultAddress[0]?.attributes?.city + ", " + defaultAddress[0]?.attributes?.state + ", " + defaultAddress[0]?.attributes?.country + " - " + defaultAddress[0]?.attributes?.zip_code) : "";
        const getFormatAddress = {
          lat: defaultAddress[0]?.attributes?.latitude,
          lang: defaultAddress[0]?.attributes?.longitude,
          streetName: defaultAddress[0]?.attributes?.addressLine1,
          streetNum: defaultAddress[0]?.attributes?.addressLine2,
          streetAddress: defaultAddress[0]?.attributes?.street_address,
          city: defaultAddress[0]?.attributes?.city,
          state: defaultAddress[0]?.attributes?.state,
          country: defaultAddress[0]?.attributes?.country,
          zip: defaultAddress[0]?.attributes?.zip_code,
          defaultAddressType: defaultAddress[0]?.attributes?.default_address,
          timezone: defaultAddress[0]?.attributes?.timezone,
          id: defaultAddress[0]?.id
        };

        setSelectedAddress(formattedAddress);
        setSelectedAddressId(defaultAddress[0]?.id);
        // setNearByScheduleRestaurants([]);        
        if (data?.length > 0 && userDeliveryAddress?.id !== defaultAddress[0]?.id) {
          setNearByRestaurants([]);
          setUserFaveMealList([]);
          setRecentOrderList([]);
          setNearByScheduleRestaurants([]);
          setUserDeliveryAddress(getFormatAddress);
          await setLocalDeliveryAddress(getFormatAddress);
        }
        setLoading(false);
        // *************** Checking Condition Opening Add New Address Accordion *********************************
        if (data?.length === 0 && props?.isAddressAvailable === false) {
          setShowNewAddressModal(true);
          setOpenAccordion(true);
        };
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    };
  };

  const handleCloseModal = (value: any) => {
    setShowNewAddressModal(value);
    clearRestaurantList();
    callAddressData();
  };

  const handleChangeAddress = async (id: any) => {
    try {
      if ((userFetchedAddress?.length === 1) || (selectedAddressId === id)) {
        setOpenAccordion(prev => !prev);
        return false;
      }
      else {
        const data = userFetchedAddress?.filter((item: any) => item?.id === id)?.[0];
        const getFormatAddress = {
          lat: data?.attributes?.latitude,
          lang: data?.attributes?.longitude,
          streetName: data?.attributes?.addressLine1,
          streetNum: data?.attributes?.addressLine2,
          streetAddress: data?.attributes?.street_address,
          city: data?.attributes?.city,
          state: data?.attributes?.state,
          country: data?.attributes?.country,
          zip: data?.attributes?.zip_code,
          defaultAddressType: data?.attributes?.default_address,
          timezone: data?.attributes?.timezone,
          id: data?.id
        }

        if (orderForSomeone?.addressId) {
          const existingData = {
            ...orderForSomeone,
            "addressId": data?.id
          }
          setOrderForSomeone(existingData);
        }
        clearRestaurantList();
        setNearByRestaurants([]);
        setUserFaveMealList([]);
        setRecentOrderList([]);
        setUserDeliveryAddress(getFormatAddress);
        await setLocalDeliveryAddress(getFormatAddress);
        setSelectedLocation(data?.id);
        const formattedAddress = (data?.attributes?.city) ? (data?.attributes?.addressLine1 + (data?.attributes?.addressLine2 ? " " + switchSentenceCase("head text", data?.attributes?.addressLine2) : "") + ", " + data?.attributes?.city + ", " + data?.attributes?.state + ", " + data?.attributes?.country + " - " + data?.attributes?.zip_code) : "";
        setSelectedAddress(formattedAddress);
        setSelectedAddressId(data?.id);
        setOpenAccordion(prev => !prev);
      };
    }
    catch (e) {
      setShowIonAlertModal(true)
      setIonAlertHeader(e?.response?.status);
      setIonAlertMessage(e?.response?.statusText);
      console.log(e)
    }
  };

  return (
    <>
      <InitialDiv className={isDarkMode ? "rounded-lg bg-[#3b3b3bb3]" : "rounded-lg border-gray-100 border bg-[#fbfbfb]"} >
        <AccordionNew
          title={props?.type === "delivery" ? "Delivery Address" : "Select Address For Restaurant"}
          titleClassName="text-sm"
          isOpen={openAccordion}
          setIsOpen={setOpenAccordion}
          leftTextIconClassName="mr-2 text-sm"
          leftTextIcon={faLocationDot}
          leftSubTitle={selectedAddress}
          rightIcon={props?.showList}
        >

          {/* *********************** Bottom Section for Existing Address and for Adding New Address ****************************** */}
          {props?.showList &&
            <>
              {
                loading && (
                  <Flex className="items-center justify-center">
                    <Spinner />
                  </Flex>
                )
              }
              {userFetchedAddress?.length > 0 && !loading && (
                <>
                  <FlexBetween>
                    <TextHead4></TextHead4>
                    <SubText1
                      className="!text-green-600 underline !font-semibold text-right cursor-pointer"
                      onClick={() => setShowNewAddressModal((prev) => !prev)}
                    >
                      ADD NEW ADDRESS
                    </SubText1>
                  </FlexBetween>

                  <InitialDiv className="text-left">

                    {/* *********************** Bottom Section for Existing Address and for Adding New Address ****************************** */}
                    {userFetchedAddress?.map((item: any, index: any) => (
                      <InitialDiv className="cursor-pointer" onClick={() => handleChangeAddress(item?.id)} key={index}>

                        <React.Fragment >
                          <FlexBetween
                            style={{
                              boxShadow: "1px 3px 8px -1px #dcf0dccf",
                              padding: "15px",
                              borderRadius: "7px",
                              margin: "15px 0px",
                            }}>

                            <InitialDiv>
                              <Flex>
                                <TextHead4>{item?.attributes?.city} </TextHead4>
                                {item?.attributes?.default_address ? (
                                  <i className="text-gray-500 !ml-2">
                                    <SubText1>(Default)</SubText1>
                                  </i>
                                ) : (
                                  ""
                                )}
                              </Flex>
                              <SubText1 className="mt-2">{switchSentenceCase("head text", item?.attributes?.addressLine1) + (item?.attributes?.addressLine2 ? " " + switchSentenceCase("head text", item?.attributes?.addressLine2) : "") + ", " + (item?.attributes?.city)}{" "}</SubText1>
                              <SubText1>
                                {item?.attributes?.state}, {item?.attributes?.country} -{" "}
                                {item?.attributes?.zip_code}
                              </SubText1>
                            </InitialDiv>

                            <InitialDiv
                              style={{
                                display: "flex",
                                flexFlow: "column",
                                justifyContent: "space-between",
                              }}
                            >
                              <Pills
                                style={{
                                  background: "#21734633",
                                  color: "#22A45D",
                                  fontSize: "10px",
                                }}
                              >
                                {item?.attributes?.type}
                              </Pills>
                            </InitialDiv>
                          </FlexBetween>
                        </React.Fragment>
                      </InitialDiv>
                    ))}
                  </InitialDiv>
                </>
              )}

              {userFetchedAddress?.length === 0 && !loading && (
                <>
                  <SubText1 className="mb-2 text-center">
                    No Address Found! Please Add One.
                  </SubText1>
                  <ButtonInitial
                    onClick={() => setShowNewAddressModal((prev) => !prev)}
                    style={{ color: "#22A45D", border: "1px solid #22A45D" }}
                  >
                    {switchSentenceCase("primary button", "ADD NEW ADDRESS")}
                  </ButtonInitial>
                </>
              )}

            </>
          }
        </AccordionNew>
        {/* *********************Modal Add New Address****************************** */}
        {showNewAddressModal &&
          <ModalAddAddressCart
            showAddressModal={showNewAddressModal}
            setShowAddressModal={handleCloseModal}
            addressList={userFetchedAddress}
            setShowNewAddressModal={setShowNewAddressModal}
            userId={props?.userId}
            setOpenAccordion={setOpenAccordion}
          />
        }


        {/* *********************Error Alert Modal****************************** */}
        <IonModalAlert
          isOpened={showIonAlertModal}
          setIsOpened={setShowIonAlertModal}
          header={ionAlertHeader}
          subHeader=""
          message={ionAlertMessage}
        />
      </InitialDiv>
    </>
  );
}
