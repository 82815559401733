import { faCartCirclePlus } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import styled from "styled-components";
import { priceFormatterUsd, sumOfMealItemInCart, trackStandardPixelEvent } from "../../utils/utilities";

const ButtonOne = styled.button`
background: #22a45d;
color: white;
border-radius: 10px;
font-weight: 600;
padding: 15px;
position: fixed;
bottom: 85px;
box-shadow: 11px 11px 30px #0e4225, 11px 11px 30px #36ff95;
`;


export default function AddToFaveFloating(props: any) {
    // const dispatch = useDispatch();
    const history = useHistory();
    const { addfavMealItem } = useSelector((state: any) => state.getRestaurantsMenuLists);

    // const menuItems = props?.menuDataList?.data;
    // let tempList = [];
    // Object.values(menuItems || {}).forEach((val: any) => {
    //     if (val?.length > 0) {
    //         val?.forEach((element: any) => {
    //             if (element?.quantity > 0) {
    //                 tempList?.push(element)
    //             }
    //         });
    //     }
    // });

    const onAddItemToFavMeal = () => {
        trackStandardPixelEvent('AddToWishlist', {
            content_name: null,
            content_category: null,
            content_ids: null,
            contents: null,
            currency: null,
            value: priceFormatterUsd(sumOfMealItemInCart(addfavMealItem)).slice(1)
        });
        history.push({
            pathname: "/newmealfave",
        });
        //     }
    };

    return (
        <>
            {
                (addfavMealItem?.length > 0)
                &&
                <ButtonOne
                    onClick={onAddItemToFavMeal}
                >
                    <FontAwesomeIcon icon={faCartCirclePlus} className="mr-3" />
                    Add to Fave Meal ({priceFormatterUsd(sumOfMealItemInCart(addfavMealItem))})
                </ButtonOne>
            }
        </>
    )
}