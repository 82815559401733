import Section from "../../components/widgets/section/Section";
import { ButtonGreen, FlexBetween, InitialDiv, InputLabel, TextHead3 } from "../../components/styled/styled";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useState } from "react";
import { getUserData, setUserData, } from "../../services/user";
import Spinner from "../../components/widgets/spinner/Spinner";
import { sleep, switchSentenceCase, trackPixelEvent } from "../../utils/utilities";
import { invitationStateClear } from "../scheduleInvitations/redux/invitationActionCreator";
import { loginRegisterUser, verifyUser } from "../../services/user";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenLine } from "@fortawesome/pro-regular-svg-icons";
import OtpInput from 'react18-input-otp';
import unlockPassword from "../../components/assets/onboarding/unlock-password.svg";
import { useIonViewDidEnter } from "@ionic/react";


export default function VerifyOtp(inputValue) {
  const history = useHistory();
  const dispatch = useDispatch();

  const { pageRoute } = useSelector((state: any) => state.sessionAlertState)

  const [otp, setOtp] = useState("");
  const [otpError, setOtpError] = useState("");
  const [awaiting, setAwaiting] = useState(false);
  const [resendWait, setResendWait] = useState(false);
  const [localData, setLocalData] = useState<any>({});
  const [seconds, setSeconds] = useState(0);
  const time = 30;

  const { scheduleSlug, invitationToken } = useSelector((state: any) => state?.scheduleInvitationState);

  //***************** CHECK USER SESSION  *****************//
  const checkUserLoggedIn = useCallback(async () => {
    const userData = await getUserData();
    setLocalData(userData);
    startCountdown();
  }, []);

  useEffect(() => {
    checkUserLoggedIn();
  }, [checkUserLoggedIn]);


  useIonViewDidEnter(() => {
    trackPixelEvent('Verify Otp');
  }, []);

  //***************** HANDLE SUBMIT  *****************//
  const handleSubmit = async (e: any) => {

    //* --------------------------Validation-----------------------
    if (otp === "") {
      setOtpError("Please Enter OTP First !");
    } else if (otp?.length < 6 || otp?.length > 6) {
      setOtpError("OTP Should Contain 6 Digits !");
    } else if (otp?.length === 6) {
      setOtpError("")

      //* --------------------------Api Call--------------------------
      try {
        setAwaiting(true);
        await verifyUser(otp, localData?.provider)
          .then(async (response: any) => {
            trackPixelEvent('Login register user');
            let data = response?.data;


            // Google Analytics event snippet
            if (process.env.REACT_APP_GTAG_ENABLED_FOR_PRODUCTION === "true") {
              if (window.gtag && data?.user?.new_user === true) {
                window.gtag('event', 'conversion', { 'send_to': 'AW-16498935235/D9w9CMi297cZEMOLp7s9' });
              } else if (window.gtag && data?.user?.new_user === false) {
                window.gtag('event', 'conversion', { 'send_to': 'AW-16498935235/tNh_CPmurrkZEMOLp7s9', 'value': 1.0, 'currency': 'USD' });
              }
            }
            // Google Analytics event snippet end

            if (data.user.confirmed === true) {
              setAwaiting(false);
              await setUserData(data);
              if (scheduleSlug !== "" && invitationToken !== "") {
                history.push({
                  pathname: `/schedule/${encodeURIComponent(scheduleSlug)}/${encodeURIComponent(invitationToken)}`
                })
                dispatch(invitationStateClear() as any);
              }
              else if (pageRoute === "?for=manage-order") {
                history.push("/manage-orders?for_me")
              }
              else {
                history.push('/onboarding');
              }
            }
          })
      } catch (error) {
        console.log(error);
        setAwaiting(false);

        const err = error?.response?.data?.error?.message;

        if (String(err).toLowerCase().includes("invalid")) {
          setOtpError("Code is invalid. Try again or →");
        }
      }
    }
  }


  //* Timer for user to wait between resending otp.
  const startCountdown = async () => {
    setResendWait(true);
    for (let i = time; i >= 0; i--) {
      await sleep(1000)
      setSeconds(i);

      if (i === 0) {
        setResendWait(false);
        break
      }
    }
  }

  //***************** HANDLE RESEND OTP  *****************//
  const resendOtp = async (e: any) => {
    try {
      if (awaiting || resendWait) { return false; }
      startCountdown();
      setOtp("");
      setOtpError("");

      //* Api Call--------------------------
      setAwaiting(true);
      await loginRegisterUser(localData?.contact, localData?.provider)
        .then(async (response: any) => {
          trackPixelEvent('Login register user');
          let data = response?.data;
          if (data.status === "pending") {
            setAwaiting(false);
          }
        })

    } catch (error) {
      console.log(error);
      setAwaiting(false);
    }
  }

  //***************** HANDLE KEY DOWN FUNCTION *****************//
  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      handleSubmit(e);
    }
  };


  //***************** RETURN START  *****************//
  return (
    <>
      <Section>
        <Section>
          <TextHead3 style={{ fontWeight: "600" }}>Verification</TextHead3>
        </Section>
        <InitialDiv className="w-full flex justify-center">
          <img src={unlockPassword} alt="img" width={250} />
        </InitialDiv>

        <Section>
          <FlexBetween><InputLabel className="mb-[10px]">{`Enter code sent to ${localData?.contact ? localData?.contact + "." : "[email or phone number]"}`}</InputLabel>{(seconds > 0) ? "" : <FontAwesomeIcon icon={faPenLine} className="text-green-600 text-sm"
            onClick={async () => {
              const isEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(localData?.contact);
              history.push({
                pathname: "/login",
                state: { loginType: isEmail, inputValue: localData?.contact },
              });

            }} />}</FlexBetween>

          <div>
            <form onKeyDown={handleKeyDown}>
              <OtpInput
                value={otp}
                shouldAutoFocus={true}
                onChange={setOtp}
                numInputs={6}
                isInputNum={true}
                separator={<span>-</span>}
                containerStyle="justify-center"
                inputStyle="bg-transparent !w-11 h-10 border m-[0.2rem]"

              />
            </form>
          </div>

          <FlexBetween>
            {otpError ? <p className="mt-2 text-rose-800">{otpError}</p> : <p></p>}
            <p className="mt-2 text-red-600 cursor-pointer">
              <span onClick={resendOtp} style={{ color: ((seconds > 0) ? "grey" : "") }}>Resend Code {(seconds > 0) ? `(in ${seconds}s)` : ""}</span>
            </p>
          </FlexBetween>


          {(awaiting === false)
            ?
            <ButtonGreen
              className="mt-[15px]"
              onClick={handleSubmit}
            >
              {switchSentenceCase("primary button", "CONTINUE")}
            </ButtonGreen>
            :
            <div className="text-center">
              <Spinner />
            </div>
          }
        </Section>
      </Section>
    </>
  );
}

