import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import connect_with_friends from "../../components/assets/dashboard/connect-with-friends-icon.svg";
import useDarkMode from "../../components/hooks/darkmode/DarkMode";
import { scheduleDetailsClear } from "../schedules/redux/createScheduleReduxActions";
import { useState } from "react";

export default function GetFoodNowButton() {
  // const isDarkMode = useDarkMode();
  const history = useHistory();
  const dispatch = useDispatch();

  // const { totalFavMeal } = useSelector((state: any) => state.getAllFavData);
  const { totalExistedFriends } = useSelector((state: any) => state?.userAddresses);
  // const { totalForMe } = useSelector((state: any) => state.createScheduleForMe);

  const [getTotalExistedFriendsClass, setGetTotalExistedFriendsClass] = useState("");


  // useEffect(() => {
  //   getConditionsBasedClasses();

  //   // eslint-disable-next-line
  // }, [totalFavMeal, totalExistedFriends, totalForMe]);


  // const getConditionsBasedClasses = () => {
  //   if (totalFavMeal >= 1 && totalExistedFriends >= 1 && totalForMe >= 1) {
  //     setGetTotalExistedFriendsClass("!opacity-100");
  //   } else if (totalFavMeal >= 1 && totalExistedFriends === 0) {
  //     setGetTotalExistedFriendsClass("!opacity-100");
  //   } else if (totalFavMeal > 1 && totalExistedFriends === 1 && totalForMe === 0) {
  //     setGetTotalExistedFriendsClass("");
  //   } else {
  //     setGetTotalExistedFriendsClass("");
  //   }
  // }


  return (
    <>
      <div className={`getFood_box getfoodNow max-md:w-[50%] max-lg:w-[100%] lg:w-[50%] lg:min-h-32 ${getTotalExistedFriendsClass}`}
        onClick={(e: any) => {
          e.preventDefault(); dispatch(scheduleDetailsClear() as any)
          history.push('/friends');
        }}>
        <div className="w-full">

          <div className="Food_box_icon">
            <img src={connect_with_friends} alt="order-img" className="w-[35px]" />
            <span className="Food_order-orange">{totalExistedFriends}  {(totalExistedFriends > 1 || totalExistedFriends === 0) ? "" : ""}</span>
          </div>

          <div className="Food_box_text text-center max-md:text-left my-3">
            <span className="text">Connect With Friends </span>
            <p>Add friends, see their faves, & set up orders or meal calendars for them.</p>
          </div>
        </div>
      </div>
    </>
  );
}
