import { faCircle1 } from "@fortawesome/pro-regular-svg-icons";
import { faAngleLeft } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Flex } from "antd";
import { useHistory } from "react-router";
import event_repeat from "../../components/assets/dashboard/event_repeat.svg";
import { ButtonGreen, TextHead1 } from "../../components/styled/styled";
import Divider from "../../components/widgets/divider/Divider";
import TopHead from "../../components/widgets/head/TopHead";
import Section from "../../components/widgets/section/Section";
import { switchSentenceCase } from "../../utils/utilities";
import { useRef, useState } from "react";
import ModalChooseOption from "../../components/widgets/modals/ModalChooseOption";
import { useIonAlert } from "@ionic/react";
import { setUserDeliveryAddress } from "../profile/redux/locationActionCreator";
import { clearRestaurantList, setNearByRestaurants } from "../search/redux/searchActionCreator";



export default function GiftSelect() {
  const history = useHistory();
  const btn2 = useRef<HTMLButtonElement>(null);
  const [showOptionModal, setShowOptionModal] = useState(false);
  const [presentAlert] = useIonAlert();
  const showAlert = (header: string, message: string, btnMessage: string) => {
    presentAlert({
      header: header,
      message: message,
      buttons: [btnMessage],
    });
  };

  const gotoHandle = () => {
    history.push({
      pathname: '/home',
    })
  };


  const handleSubmit = async (orderType: string) => {
    if (orderType === "") {
      return showAlert('', 'Please select any one option.', 'OK');
    } else {
      clearRestaurantList();
      setNearByRestaurants([]);
      setUserDeliveryAddress({});
      setShowOptionModal(false);
      history.push(`/getfoodnow?type=${orderType}`);
    }
  }

  return (
    <>
      <div className="screen">
        <TopHead
          title="Gifts"
          titleStyle={{ color: "#22A45D", fontWeight: "600" }}
          leftTextIcon={faAngleLeft}
          leftIconStyle={{ fontSize: "26px", color: "#22A45D" }}
          goToHandle={gotoHandle}
        />
        <Divider />

        <div className="grow overflow-auto">
          <Section>
            <TextHead1 className="!text-[18px] !font-bold text-center text-[#D97706]">One-Time Gift or Setup Meal Calendar</TextHead1>
          </Section>

          <Section>
            <ButtonGreen
              onClick={() => setShowOptionModal(true)}
            >
              <FontAwesomeIcon icon={faCircle1} className="mr-2" />{switchSentenceCase("primary button", "One - time Gift")}</ButtonGreen>
          </Section>

          <Section>
            <ButtonGreen
              ref={btn2}
              onClick={() => {
                history.push({ pathname: '/create-calendar' })
              }}
            >
              <Flex className="justify-center items-center gap-2 p-[2px]"><img src={event_repeat} className="w-5" alt="event-repeat" />
                <TextHead1 className="!text-[15px] !mt-0">{switchSentenceCase("primary button", "Set up Meal Calendar")}</TextHead1>
              </Flex>
            </ButtonGreen>
          </Section>
        </div>
      </div >
      {
        setShowOptionModal &&
        <ModalChooseOption
          showModal={showOptionModal}
          setShowModal={setShowOptionModal}
          selectedValue={handleSubmit}
        />
      }
    </>
  );
}


