import Divider from "../../components/widgets/divider/Divider";
import Section from "../../components/widgets/section/Section";
import TopHead from "../../components/widgets/head/TopHead";
import { useHistory } from "react-router";
import { faAngleLeft } from "@fortawesome/pro-solid-svg-icons";
import { useState, useEffect } from "react";
import { ButtonGreen, InputText } from "../../components/styled/styled";
import styled from "styled-components";
import { switchSentenceCase, trackPixelEvent } from "../../utils/utilities";
// import ModalSuccessUpdate from "../../components/widgets/modals/ModalSuccessUpdate";
import ModalSuccess from "../../components/widgets/modals/ModalSuccess";
import { validateFacebook, validateTwitter, validateLinkedIn } from "../../utils/utilities";
import { userMe, userProfileUpdate, getUserData, setUserData } from "../../services/user";
import { IonContent, IonRefresher, IonRefresherContent, RefresherEventDetail, useIonViewDidEnter } from "@ionic/react";

const HeadText = styled.h5`
  text-align: left;
  color: #868686;
`;


export default function SocialAccounts() {
  const history = useHistory();
  const [showModal, setShowModal] = useState(false);
  const [isRefreshed, setIsRefreshed] = useState(false);

  const GotoHandle = () => {
    history.push({
      pathname: '/newmealaddparticipants',
    })
  }

  useIonViewDidEnter(() => {
    trackPixelEvent('Add Social Accounts');
  }, []);

  const [values, setValues] = useState({
    facebook: "",
    twitter: "",
    linkedIn: "",
  })

  // Error Handler
  const [facebookError, setFaceBookError] = useState(false);
  const [linkedInError, setLinkedInError] = useState(false);
  const [twitterError, setTwitterError] = useState(false);
  const [disable, setDisable] = useState(false);

  useEffect(() => {
    callUserData();
  }, [isRefreshed]);


  function handleRefresh(event: CustomEvent<RefresherEventDetail>) {
    setTimeout(() => {
      setIsRefreshed(prev => !prev);
      event.detail.complete();
    }, 2000);
  };


  // Get User Info
  const callUserData = async () => {
    try {
      const { data } = await userMe();
      // setUserId(data?.id)
      trackPixelEvent('User me');

      let linkedIn: any
      let facebook: any
      let twitter: any

      if (data?.linkedIn == null) {
        linkedIn = ""
      } else {
        linkedIn = data?.linkedIn
      }

      if (data?.facebook == null) {
        facebook = ""
      } else {
        facebook = data?.facebook
      }

      if (data?.twitter == null) {
        twitter = ""
      } else {
        twitter = data?.twitter
      }

      setValues({
        ...values,
        linkedIn: String(linkedIn).trim(),
        facebook: String(facebook).trim(),
        twitter: String(twitter).trim(),
      });
    }
    catch (e) {
      console.log(e)
    }
  };

  const handleChange = async (e: any) => {
    setFaceBookError(false);
    setTwitterError(false);
    setLinkedInError(false);

    setDisable(false);
    const { name, value } = e.target;

    setValues((prevValues) => {
      return {
        ...prevValues,
        [e.target.name]: e.target.value,
      };
    });

    if (name === "facebook") {

      const validFacebook = validateFacebook(value);
      if (!validFacebook) {
        setFaceBookError(true);
      } else {
        setFaceBookError(false);
      }
    }

    if (name === "twitter") {

      const validTwitter = validateTwitter(value);
      if (!validTwitter) {
        setTwitterError(true);
      } else {
        setTwitterError(false);
      }
    }

    if (name === "linkedIn") {

      const validLinkedIn = validateLinkedIn(value);
      if (!validLinkedIn) {
        setLinkedInError(true);
      } else {
        setLinkedInError(false);
      }
    }
  };

  const onHandleSubmit = async () => {
    try {
      let validFacebookErr = false;
      let validTwitterErr = false;
      let validLinkedInErr = false;

      const { facebook, twitter, linkedIn } = values;

      if ((String(facebook).length > 0) && (!validateFacebook(facebook))) { setFaceBookError(true); validFacebookErr = true; }
      else { setFaceBookError(false); validFacebookErr = false; }

      if ((String(twitter).length > 0) && (!validateTwitter(twitter))) { setTwitterError(true); validTwitterErr = true; }
      else { setTwitterError(false); validTwitterErr = false; }

      if ((String(linkedIn).length > 0) && (!validateLinkedIn(linkedIn))) { setLinkedInError(true); validLinkedInErr = true; }
      else { setLinkedInError(false); validLinkedInErr = false; }

      if (String(facebook).trim() === "" && String(twitter).trim() === "" && String(linkedIn).trim() === "") {
        setFaceBookError(true);
        setTwitterError(true);
        setLinkedInError(true);
        setDisable(true);
        return false;
      }

      if (
        validFacebookErr || validTwitterErr || validLinkedInErr || facebookError || twitterError || linkedInError
      ) {
        return false;
      }

      const data = {
        "facebook": facebook,
        "twitter": twitter,
        "linkedIn": linkedIn
      }
      const localData = await getUserData();
      await userProfileUpdate(data)
        .then(async (res) => {
          if (res.status === 200) {
            trackPixelEvent('User profile update');
            const newUpdatedUserInfo = {
              ...localData,
              user: ({
                ...localData.user,
                "facebook": facebook,
                "twitter": twitter,
                "linkedIn": linkedIn,
              })
            };
            await setUserData(newUpdatedUserInfo);
            setShowModal(true);
          }
        })
    }
    catch (e) {
      console.log(e)
    }
  }

  return (
    <div className="screen">
      <TopHead
        title="Add Social Accounts"
        leftTextIcon={faAngleLeft}
        leftIconStyle={{ fontSize: "24px" }}
        goToHandle={GotoHandle}
      />
      <Divider />

      {/* <div className="grow overflow-auto"> */}
      <IonContent>
        <IonRefresher slot="fixed" onIonRefresh={handleRefresh}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>
        <Section align="center">
          <HeadText className="my-3">Facebook</HeadText>
          <InputText
            type="text"
            style={{
              border: facebookError ? "1px solid red" : "",
            }}
            placeholder="e.g. http://facebook.com/abc"
            name="facebook"
            value={values?.facebook}
            onChange={handleChange}
          />

          {/* <HeadText>Instagram</HeadText>
          <InputText
            type="text"
            placeholder="e.g. instagram.com/username"
            name=""
          /> */}

          <HeadText className="my-3">Twitter</HeadText>
          <InputText
            type="text"
            style={{
              border: twitterError ? "1px solid red" : "",
            }}
            placeholder="e.g. http://twitter.com/abc"
            name="twitter"
            value={values?.twitter}
            onChange={handleChange}
          />


          <HeadText className="my-3">LinkedIn</HeadText>
          <InputText
            type="text"
            style={{
              border: linkedInError ? "1px solid red" : "",
            }}
            placeholder="e.g. http://linkedin.com/in/abc"
            name="linkedIn"
            value={values?.linkedIn}
            onChange={handleChange}
          />

          <ButtonGreen style={{ marginTop: "20px" }} onClick={onHandleSubmit} disabled={disable}>
            {switchSentenceCase("primary button", "SAVE & BACK")}
          </ButtonGreen>
          <ModalSuccess
            contentDivStyle={{ height: "auto" }}
            showModal={showModal}
            firstText={switchSentenceCase(
              "primary button",
              "Social Account Updated Successfully"
            )}
            btnOneGoto={() =>
              history.push({
                pathname: "/profile",
              })
            }
            btnOneText={switchSentenceCase("primary button", "OKAY")}
          />
        </Section>
        <Divider />
      </IonContent>
      {/* </div> */}
    </div >

  );
}