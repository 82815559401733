import Section from "../../components/widgets/section/Section";
import TopHead from "../../components/widgets/head/TopHead";
import Divider from "../../components/widgets/divider/Divider";
import { Flex, FlexBetween, InitialDiv, SubText1, TextHead1, TextHead4 } from "../../components/styled/styled";
import { faAngleLeft, faCalendarPlus, faCartPlus, faCircleDollar, faPotFood, faStore, faTree } from "@fortawesome/pro-solid-svg-icons";
import DotSeprator from "../../components/widgets/dotseprator/DotSeprator";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import sampleMealImage from "../../components/assets/restaurent-Meal-3.jpg";
import { useSelector } from "react-redux";
import { isRestaurantOpen, orderFilterByHighestPriceAndSum, properNameFormate, switchSentenceCase, textFormatter, isAuthenticated, trackPixelEvent } from "../../utils/utilities";
import React, { useState, useEffect, useRef } from "react";
import ModalSpinner from "../../components/widgets/modalspinner/ModalSpinner";
import ModalViewFavMealItems from "../../components/widgets/modals/ModalViewFavMealItems";
import { useLocation, useParams } from "react-router";
import { getUserFavoriteMeal } from "../../services/favourite";
import { getUserInfo } from "../../services/user";
import Spinner from "../../components/widgets/spinner/Spinner";
import { useIonViewDidEnter } from "@ionic/react";
import { setOtherFavMeals, setRecentOrderList, setUserFaveMealList } from "../faves/redux/favouriteActionCreator";
import { objectType } from "../../type";

const CartIcon = styled.div`
    padding: 3px;
    background: #22a45d36;
    border-radius: 15px;
    position: relative;
    top: 50px;
    left: 20px;
    backdrop-filter: blur(6.5px);
    color: #22a45d;
    width: 30px;
    height: 30px;
    text-align: center;
    /* @media (max-width: 768px) {
      right: 140px;
    } */
`;

const GridSection = styled.div`
  display: grid;
  grid-template-columns: auto auto auto auto;

  @media (max-width: 1100px) {
    grid-template-columns: auto auto auto;
    justify-content: center;
  }

  @media (max-width: 900px) {
    grid-template-columns: auto auto;
    justify-content: center;
  }
  @media (max-width: 600px) {
    grid-template-columns: auto;
    justify-content: center;
  }
`;

export const ButtonRemove = styled.button`
    width: 50px;
    height: 22px;
    color: #979797;
    border: 1px solid #9797976b;
    background: #9797971f;
    border-radius: 25px;
    font-size: 14px;
    font-weight: 500;
    padding-bottom: 5px;
    align-self: center;
    margin: 0px 10px;
`;

export const ButtonAdd = styled.button`
    color: white;
    background: #22A45D;
    width: 50px;
    height: 22px;
    border: 1px solid #9797976b;
    border-radius: 25px;
    font-size: 14px;
    font-weight: 500;
    padding-bottom: 5px;
    align-self: center;
    margin: 0px 10px;
`;

export default function OrdersAllItems() {
  // **************** Other Methods ***********************************
  const currentDateTime = new Date();
  const { pageType } = useParams<any>();
  const userData = getUserInfo();
  const containerRef = useRef(null);
  const limit = 10;
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const type = searchParams.get('type');

  const showNav = (pageType === "for-schedule" || pageType === "for-schedule-section" || pageType === "for-schedule-other-fave") ? true : false;
  const { scheduleDetails } = useSelector((state: any) => state?.createScheduleForMe);
  const { userDeliveryAddress } = useSelector((state: any) => state.userAddresses);
  const { userFaveMealsList, otherFavMealList, recentOrderList, mealCategory } = useSelector((state: any) => state.getAllFavData);
  const mealData = (pageType === "for-schedule") ? userFaveMealsList : (pageType === "for-schedule-other-fave") ? otherFavMealList : recentOrderList;


  // *************************** Hooks ***************************************
  const [isLoading, setIsLoading] = useState(false);
  const [showMealModal, setShowMealModal] = useState(false);
  const [mealItems, setMealItems] = useState<any>({});
  const [totalItems, setTotalItems] = useState(0);
  // const [mealData, setMealData] = useState([]);
  let [skip, setSkip] = useState(0);

  // *************************** First Time Redering **********************************
  useEffect(() => {
    setIsLoading(true);
    if (totalItems === 0) {
      callData();
    }
  }, []);

  useIonViewDidEnter(() => {
    trackPixelEvent('Orders all lists');
  }, []);

  const callData = async (skippingValue = 0) => {
    if (isAuthenticated() === false) return false;

    if (userDeliveryAddress?.city === undefined || userDeliveryAddress?.city === "") {
      setIsLoading(false);
      return false;
    }

    let mealCategoriesId = []
    if (scheduleDetails?.attributes?.meal_categories?.data !== null) {
      scheduleDetails?.attributes?.meal_categories?.data?.forEach((currentObj: any) => {
        mealCategoriesId.push(currentObj?.id);
      })
    }
    const filterMealCategory = mealCategory.filter((obj: objectType) => !mealCategoriesId.includes(obj?.id)).map((obj: objectType) => obj.id);
    try {
      let query: any;
      if (pageType === "for-schedule") {
        query = {
          id: scheduleDetails?.attributes?.user?.data?.id,
          type: "Fav",
          public_meal: (scheduleDetails?.attributes?.user?.data?.id !== userData?.id) ? true : "All",
          suggested: (scheduleDetails?.attributes?.user?.data?.id !== userData?.id) ? true : "All",
          skip: skippingValue,
          limit: limit,
          category: mealCategoriesId,
          lat: userDeliveryAddress?.lat,
          lang: userDeliveryAddress?.lang,
          order_type: type
        }
      }
      else if (pageType === "for-schedule-other-fave") {
        query = {
          id: scheduleDetails?.attributes?.user?.data?.id,
          type: "Fav",
          public_meal: (scheduleDetails?.attributes?.user?.data?.id !== userData?.id) ? true : "All",
          suggested: (scheduleDetails?.attributes?.user?.data?.id !== userData?.id) ? true : "All",
          skip: skippingValue,
          limit: limit,
          category: filterMealCategory,
          lat: userDeliveryAddress?.lat,
          lang: userDeliveryAddress?.lang,
          order_type: type
        }
      }
      else {
        query = {
          id: userData?.id,
          type: "Order",
          skip: skippingValue,
          limit: limit,
          lat: userDeliveryAddress?.lat,
          lang: userDeliveryAddress?.lang,
          order_type: type
        }
      }

      const response = await getUserFavoriteMeal(query);
      trackPixelEvent('Get user fave meal');
      setTotalItems(response?.total);
      let tempData = response?.data;

      if (skippingValue > 0) {
        if (pageType === "for-schedule") {
          setUserFaveMealList([...userFaveMealsList, ...tempData]);
        }
        else if (pageType === "for-schedule-other-fave") {
          setOtherFavMeals([...otherFavMealList, ...tempData])
        }
        else {
          setRecentOrderList([...recentOrderList, ...tempData]);
        }
        setIsLoading(false);
      }
      else {
        if (pageType === "for-schedule") {
          setUserFaveMealList(tempData);
        }
        else if (pageType === "for-schedule-other-fave") {
          setOtherFavMeals(tempData)
        }
        else {
          setRecentOrderList(tempData);
        }
        setIsLoading(false);
      }
    }
    catch (e) {
      console.log(e)
      setIsLoading(false);
    }
  };

  const openMealModal = (meallist: any) => {
    setShowMealModal(prev => !prev)
    setMealItems(meallist)
  };

  const showMore = () => {
    setIsLoading(true);
    const skippingValue = skip + limit
    setSkip(skippingValue)
    callData(skippingValue);
  };

  return (
    <div className="screen">
      <TopHead
        title={(pageType === "for-schedule") ? `${switchSentenceCase("head text", scheduleDetails?.attributes?.name)} Preferred Meals` : (pageType === "for-schedule-other-fave") ? "Other Fave Meals" : "Orders"}
        leftTextIcon={faAngleLeft}
        leftIconStyle={{ fontSize: "24px" }}
      // rightText="Filter"
      />
      <Divider />

      <div className="overflow-auto" ref={containerRef} style={{ overflowY: 'scroll', maxHeight: 'fit-content' }}>
        {(isLoading && skip === 0) ?
          <ModalSpinner />
          :
          <Section>

            <TextHead1 style={{ marginBottom: "8px" }}>
              {totalItems} {totalItems > 0 ? "Results found" : "Result found"}
            </TextHead1>
            <GridSection>
              {mealData?.map((ordersList: any, index: any) => {
                let productImage: any;

                if (ordersList?.attributes?.favItems?.data.length) {
                  if (ordersList?.attributes?.favItems?.data[0].attributes?.product?.data?.attributes?.thumbnail_image) {
                    productImage = ordersList?.attributes?.favItems?.data[0].attributes?.product?.data?.attributes?.thumbnail_image;
                  } else if (ordersList?.attributes?.favItems?.data[0].attributes?.product?.data?.attributes?.image) {
                    productImage = ordersList?.attributes?.favItems?.data[0].attributes?.product?.data?.attributes?.image;
                  } else {
                    productImage = ordersList?.attributes?.favItems?.data[0].attributes?.product?.data?.attributes?.restaurant?.data?.attributes?.logo_photos;
                  }
                }

                // ************************* Checking Restaurant Open/Closed & Active/Inactive Condition ************************************
                const isClosedStrip = ((ordersList?.attributes?.restaurant?.data !== null && userDeliveryAddress?.timezone !== null && userDeliveryAddress?.timezone !== undefined) && !isRestaurantOpen(type === "delivery" ? ordersList?.attributes?.restaurant?.data?.attributes?.jsonData?.local_hours?.delivery : ordersList?.attributes?.restaurant?.data?.attributes?.jsonData?.local_hours?.pickup, currentDateTime, userDeliveryAddress?.timezone, false)) ? true : false;
                const isInActive = (ordersList?.attributes?.status === "INACTIVE") ? true : false;

                if (ordersList?.favItems?.length > 1) {
                  const products = ordersList?.favItems;
                  orderFilterByHighestPriceAndSum(products)
                  ordersList.favItems = products;
                }
                return (
                  <div className="slideshow" key={index}>
                    <div className="slideshowSlider">
                      <div className="slide">
                        <CartIcon>
                          <FontAwesomeIcon
                            icon={faPotFood}
                          />
                        </CartIcon>
                        <img
                          className={isInActive ? "opacity-30 h-[215px] object-cover cursor-pointer" : "h-[215px] object-cover cursor-pointer"}
                          src={productImage ? productImage : sampleMealImage}
                          alt='fave-meals-logo'
                          height={215}
                          onClick={() => openMealModal(ordersList)} />
                      </div>
                    </div>
                    <div className="px-[10px]">
                      <FlexBetween>
                        <TextHead4>
                          {ordersList?.attributes?.name ? switchSentenceCase("head text", ordersList?.attributes?.name) : switchSentenceCase("head text", properNameFormate(ordersList?.attributes?.name))}
                        </TextHead4>

                        {showNav
                          ?
                          // <ButtonRemove onClick={async (e: any) => {
                          //   preventAndStop(e);
                          //   openModal(ordersList);
                          // }}
                          // >
                          //   <FontAwesomeIcon icon={faTrash} />
                          // </ButtonRemove>
                          <ButtonAdd onClick={() => openMealModal(ordersList)}>
                            <FontAwesomeIcon icon={faCalendarPlus} />
                          </ButtonAdd>
                          :
                          <ButtonAdd
                            className={isClosedStrip ? "!cursor-not-allowed !border !border-solid !border-[#5cb383] !bg-[#5cb383]" : ""}
                            onClick={() => openMealModal(ordersList)}
                            disabled={isClosedStrip ? true : false}
                          >
                            <FontAwesomeIcon icon={faCartPlus} />
                          </ButtonAdd>
                        }
                      </FlexBetween>

                      <InitialDiv className="my-2">
                        <SubText1 className="truncate">
                          {ordersList?.attributes?.restaurant?.data?.attributes?.description}
                        </SubText1>
                      </InitialDiv>
                      <Flex className='items-center max-w-xs parent-elipse'>
                        <SubText1>
                          <FontAwesomeIcon icon={faPotFood} className="text-gray-500 text-xs" />
                        </SubText1>
                        {ordersList?.attributes?.favItems?.data?.slice(0, 3)?.map((orderMealItem: any, index: any) => {
                          return (<React.Fragment key={index}><DotSeprator />
                            <SubText1>{switchSentenceCase("head text", (textFormatter(orderMealItem?.attributes?.product?.data?.attributes?.name)))}</SubText1>
                          </React.Fragment>)
                        })}
                      </Flex>
                      <FlexBetween className='items-center max-w-xs'>
                        <FontAwesomeIcon icon={faCircleDollar} className="text-gray-400 text-sm mr-1" />
                        <SubText1 className="mx-1">${ordersList?.attributes?.total}</SubText1>
                        <DotSeprator />
                        <FontAwesomeIcon icon={faTree} className="text-gray-500 text-xs mx-1" />
                        <SubText1>{ordersList?.attributes?.restaurant?.data?.attributes?.jsonData?.address?.city}</SubText1><DotSeprator />
                        <FontAwesomeIcon icon={faStore} className="text-gray-500 text-xs mx-1" />
                        <SubText1 className={(isClosedStrip || isInActive) ? "!text-red-600" : "!text-green-600"}>
                          {isInActive ? "[Inactive]" : isClosedStrip ? "[Closed]" : "[Open]"}
                        </SubText1>
                      </FlexBetween>
                    </div >
                  </div>
                )
              })
              }
            </GridSection>
            {(totalItems > limit) &&
              ((totalItems != mealData?.length) ?
                <div
                  className="text-green-600 text-center cursor-pointer mt-4 font-semibold"
                  onClick={() => { showMore() }}
                >
                  {(!isLoading) ? <span>See More</span> : <span><Spinner /></span>}
                </div>
                :
                <div
                  className="text-green-600 text-center mt-4 font-semibold"
                >
                  No More Orders
                </div>
              )
            }

            {/* ************************  Modal to View Order Details******************************** */}
            {
              showMealModal
              &&
              <ModalViewFavMealItems
                showMealModal={showMealModal}
                setShowMealModal={setShowMealModal}
                mealProps={mealItems}
                favMealId={mealItems?.id}
              />
            }
          </Section>
        }
      </div>
    </div>
  );
}


