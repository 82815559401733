import { apiRequestService } from './apiRequestService';
import { getUserData } from './user';

const API_URL = process.env.REACT_APP_API_URL

export const finalizeOrderForSlots = async (payloadData: any) => {
    try {
        const user = await getUserData()

        let config = {
            method: 'post',
            url: API_URL + `/v1/add/meal-in-slots`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + user?.jwt
            },
            data: payloadData
        };
        const request = apiRequestService(config);
        return request;
    } catch (error) {
        console.log(error);
    }
};

// Update Multiple Slot Data
export const updateMultipleSlot = async (requestData: any) => {
    try {
        const user = await getUserData()

        let config = {
            method: 'put',
            url: API_URL + `/v1/fill/empty-slots`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + user?.jwt
            },
            data: requestData
        };
        const request = apiRequestService(config);
        return request;
    } catch (error) {
        console.log(error);
    }
}


export const getSlotById = async (id: any) => {
    try {
        const user = await getUserData()

        let config = {
            method: 'GET',
            url: API_URL + `/v1/schedule-slots/${id}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + user?.jwt
            },
        };

        const request = apiRequestService(config);
        return request;

    } catch (error) {
        console.log(error);
    }
};

export const authorizePaymentForCalendar = async (requestData: any) => {
    try {
        const user = await getUserData()

        let config = {
            method: 'POST',
            url: API_URL + `/v1/authorize-payment/calendar`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + user?.jwt
            },
            data: requestData
        };
        const request = apiRequestService(config);
        return request;
    } catch (error) {
        console.log(error);
    }
}
