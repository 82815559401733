import clsx from "clsx";
import { useState } from "react";
import { switchSentenceCase } from "../../utils/utilities";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, A11y, Scrollbar, Pagination, Navigation } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight } from "@fortawesome/pro-solid-svg-icons";
import styled from "styled-components";
import { TextHead4 } from "../../components/styled/styled";

const ButtonArrowLeft = styled.button`
  filter: drop-shadow(0px 4px 10px black);
  border-radius: 38px;
  font-size: 20px;
  padding: 1px 7px;
  position: relative;
  bottom: 37px;
  left: -46%;
  z-index: 10;
  color: #22ae62;
`;

const ButtonArrowRight = styled.button`
  filter: drop-shadow(0px 4px 10px black);
  border-radius: 38px;
  font-size: 20px;
  padding: 1px 7px;
  position: relative;
  bottom: 37px;
  right: -46%;
  z-index: 10;
  color: #22ae62;
`;

export default function RestaurantMenuCategories({
  categories,
  selected,
  onChange,
}: {
  categories: string[];
  selected?: string | null;
  onChange: (c: string) => void;
}) {
  selected = selected || categories?.[0];

  const [swipe, setSwipe] = useState<any>();

  return (
    <div className="cuisiens-parent text-center mb-[-15px] pt-[10px]">
      <Swiper
        onBeforeInit={(swipper) => setSwipe(swipper)}
        modules={[FreeMode, A11y, Navigation, Pagination, Scrollbar]}
        spaceBetween={30}
        slidesPerView={"auto"}
        centeredSlides={false}
        freeMode={true}
        grabCursor={false}
        className="w-[92%]"
        // navigation={true}
      >
        {categories?.length > 0 ? (
          categories?.map((category, key) => (
            <SwiperSlide key={key}>
              <h2
                onClick={() => onChange(category)}
                className={clsx(
                  "inline-block pr-3.5 my-2 font-medium font-sans text-2xl cursor-pointer",
                  category !== selected && "subtext"
                )}
              >
                {switchSentenceCase("head text", category)}
              </h2>
            </SwiperSlide>
          ))
        ) : (
          <TextHead4 className="text-center my-4">
            No results found for your search !
          </TextHead4>
        )}
      </Swiper>
          <ButtonArrowLeft onClick={() => swipe?.slidePrev()}>
            <FontAwesomeIcon icon={faAngleLeft} />
          </ButtonArrowLeft>
          <ButtonArrowRight onClick={() => swipe?.slideNext()}>
            <FontAwesomeIcon icon={faAngleRight} />
          </ButtonArrowRight>
    </div>
  );
}
