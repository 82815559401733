import React, { ReactNode } from "react";
import styled from 'styled-components';


const Seprator = styled.span`
  color: #ABABAB;
  margin: 0px 2px;
  `;

export default function DotSeprator({
    children,
    className,
}: {
    children?: ReactNode;
    className?: string;
}) {
    return (
        <>
            <Seprator>•</Seprator>
        </>
    );
}
