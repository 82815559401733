import { useState } from "react";
import Section from "../../components/widgets/section/Section";
import TopHead from "../../components/widgets/head/TopHead";
import Divider from "../../components/widgets/divider/Divider";
import { ButtonGreen, FlexBetween, InitialDiv, InputLabel, ItemCount, SubText1, TextHead1, TextHead4 } from "../../components/styled/styled";
import styled from "styled-components";
import RadioButtonText from "../../components/widgets/buttons/RadioButtonText";
import { useHistory } from "react-router";
import { switchSentenceCase } from "../../utils/utilities";
import {
  faAngleLeft
} from "@fortawesome/pro-solid-svg-icons";
import { IonDatetime } from "@ionic/react";
import ModalBottomToTop from "../../components/widgets/modals/ModalBottomToTop";
import ModalDeliveryCheckout from "../../components/widgets/modals/ModalDeliveryCheckout";


const BlockContainer = styled.div`
    display: block;
    width: -webkit-fill-available;
    text-align: left;
`;

const WrapChoice = styled.div`
  padding: 5px;
`;

const ChoiceHead = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Trans = styled.div`
  font-size: 18px;
  font-weight: 500;
  margin-top: 10px;
`;

const TabText = styled.button`
  padding: 5px;
  color: #217346;
  background: #21734633;
  border-radius: 5px;
  float: right;
  font-size: 15px;
`;



export default function TimeSlot(data: any) {
  const history = useHistory();
  const [chocolateTopCookie, setChocolateTopCookie] = useState();
  const [showSelectContactModal, setShowSelectContactModal] = useState(false);
  const [showDeliveryModal, setShowDeliveryModal] = useState(false);

  const GotoHandle = () => {
    history.push({
      pathname: "/scheduledetailsrecipientsnew",
    });
  };

  const openModal = () => {
    setShowSelectContactModal(true);
  }


  const openModalCheckout = () => {
    setShowDeliveryModal(true);
  }


  return (
    <div className="screen">
      <TopHead
        title="Schedule Delivery"
        leftTextIcon={faAngleLeft}
        leftIconStyle={{ fontSize: "24px" }}
        goToHandle={GotoHandle}
      />
      <Divider />

      <div className="grow overflow-auto">
        <Section>
          <TextHead1 style={{ fontWeight: "700", marginBottom: "8px" }}>
            Schedule
          </TextHead1>
          <SubText1>Choose an available timeslot for [Reciepient’s] meal schedule.</SubText1>
        </Section>

        <Section>
          <IonDatetime preferWheel={true} showDefaultButtons={true}></IonDatetime>
          <ButtonGreen
            style={{ margin: "15px 0px" }}
          >
            {switchSentenceCase("primary button", "CONFIRM TIME")}
          </ButtonGreen>
        </Section>

        <Section>
          <ChoiceHead>
            <Trans>Open Time Slots</Trans>
          </ChoiceHead>
          <WrapChoice>
            <RadioButtonText
              label="Mon 07 Mar 09:00A"
              name="chocolateChip"
              value="chocolateChip"
              checked={chocolateTopCookie}
              setter={setChocolateTopCookie}
            />
            <TabText>WAILA S.</TabText>
            <RadioButtonText
              label="Mon 07 Mar 12:00P"
              name="cookiesAndCream"
              value="cookiesAndCream"
              checked={chocolateTopCookie}
              setter={setChocolateTopCookie}
            />
            <RadioButtonText
              label="Mon 07 Mar 06:00P"
              name="funfetti"
              value="funfetti"
              checked={chocolateTopCookie}
              setter={setChocolateTopCookie}
            />
            <RadioButtonText
              label="Tue 08 Mar 09:00A"
              name="mAndm"
              value="mAndm"
              checked={chocolateTopCookie}
              setter={setChocolateTopCookie}
            />
            <RadioButtonText
              label="Tue 08 Mar 07:00P"
              name="redVelvet"
              value="redVelvet"
              checked={chocolateTopCookie}
              setter={setChocolateTopCookie}
            />
            <RadioButtonText
              label="Wed 09 Mar 11:00A"
              name="peanutButter"
              value="peanutButter"
              checked={chocolateTopCookie}
              setter={setChocolateTopCookie}
            />
            <TabText>CLAIMED</TabText>
            <RadioButtonText
              label="Wed 09 Mar 07:00P"
              name="snickerdoodle"
              value="snickerdoodle"
              checked={chocolateTopCookie}
              setter={setChocolateTopCookie}
            />
            <TabText>SOLD OUT</TabText>
            <RadioButtonText
              label="Thu 10 Mar 05:00P"
              name="whiteChocolateMacadamia"
              value="whiteChocolateMacadamia"
              checked={chocolateTopCookie}
              setter={setChocolateTopCookie}
            />
          </WrapChoice>
        </Section>

        <Section>
          <ButtonGreen
            onClick={openModal}
            style={{ margin: "15px 0px" }}
          >
            {switchSentenceCase("primary button", "CONFIRM TIME")}
          </ButtonGreen>
        </Section>
      </div>

      {/*---------- Modal Bottom to top - Select Contact Lists----------- */}
      <ModalBottomToTop containerStyle={{ height: "auto" }} showModal={showSelectContactModal} setShowModal={setShowSelectContactModal} >
        <TextHead1 style={{ textAlign: "left" }}>Mayfield Bakery & Cafe</TextHead1>
        <InputLabel style={{ color: "black", textAlign: "left", marginBottom: "10px" }}>Resturant Address</InputLabel>
        <Section>
          <FlexBetween className="my-2.5">
            <ItemCount>1</ItemCount>
            <BlockContainer>
              <FlexBetween >
                <TextHead4>Super Duper Icecream Sandwhich</TextHead4>
              </FlexBetween >
              <InitialDiv >
                <SubText1 className="mt-2">Choc Mint, Sugar Cookie</SubText1>
              </InitialDiv>
            </BlockContainer>
          </FlexBetween>
          <Divider />
          <FlexBetween className="my-2.5">
            <ItemCount>2</ItemCount>
            <BlockContainer>
              <FlexBetween >
                <TextHead4>Time</TextHead4>
              </FlexBetween >
              <InitialDiv>
                <SubText1 className="mt-2">Arrives at 7:00PM</SubText1>
                <SubText1 className="mt-2">Dates: Mar 07 2022</SubText1>
                <SubText1 className="mt-2">Items: 2</SubText1>
              </InitialDiv>
            </BlockContainer>
          </FlexBetween>
        </Section>
        <ButtonGreen
          onClick={openModalCheckout}
        >{switchSentenceCase("primary button", "GO TO CHECKOUT")}</ButtonGreen>
      </ModalBottomToTop>

      {/*---------- Modal Delivery Details Checkout----------- */}
      <ModalDeliveryCheckout showModal={showDeliveryModal} setShowModal={setShowDeliveryModal} />
    </div>
  );
}
