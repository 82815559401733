import Section from "../../components/widgets/section/Section";
import TopHead from "../../components/widgets/head/TopHead";
import Divider from "../../components/widgets/divider/Divider";
import { TextHead1 } from "../../components/styled/styled";
import { useHistory } from "react-router";
import {
  faAngleLeft
} from "@fortawesome/pro-solid-svg-icons";
import RestaurantCardList from "../../components/widgets/cards/RestaurantCardList2";



export default function SearchResultsV1(data: any) {
  const history = useHistory();

  const GotoHandle = () => {
    history.push({
      pathname: "/",
    });
  };


  return (
    <div className="screen">
      <TopHead
        title="Burgers"
        leftTextIcon={faAngleLeft}
        leftIconStyle={{ fontSize: "24px" }}
        rightText="Filter"
        goToHandle={GotoHandle}
      />
      <Divider />

      <div className="grow overflow-auto">
        <Section>
          <TextHead1 style={{ marginBottom: "8px" }}>
            80 Results found
          </TextHead1>
          <RestaurantCardList restarantName="McDonald's" />
          <RestaurantCardList restarantName="Cafe Brichor’s" />
          <RestaurantCardList restarantName="The Halal Guys" />
        </Section>
      </div>
    </div>
  );
}
