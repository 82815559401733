import store from "../../../redux/store";

export const setSearchFilterRedux = (value: any) => {
    store.dispatch({
        type: "SET_SEARCH_FILTER",
        payload: value
    })
};

export const setOrderType = (value: string) => {
    store.dispatch({
        type: "SET_ORDER_TYPE",
        payload: value
    })
};

export const getSearchedRestaurantsData = (restaurantsData: any) => (dispatch: any) => {
    try {
        dispatch(
            {
                type: "GET_RESTAURANTS_DATA",
                payload: restaurantsData
            })
    } catch (error) {
        console.log(error);
    }
};

export const getRestaurantsList = (restaurantsList: any, nextPage: any) => (dispatch: any) => {
    try {
        dispatch(
            {
                type: "GET_RESTAURANTS_LIST",
                payload: { restaurantsList, nextPage }
            })
    } catch (error) {
        console.log(error);
    }
};

export const setNearByRestaurants = (value: any) => {
    store.dispatch({
        type: "SET_NEAR_BY_RESTAURANTS",
        payload: value
    })
}

export const setNearByScheduleRestaurants = (value: any) => {
    store.dispatch({
        type: "SET_NEAR_BY_SCHEDULE_RESTAURANTS",
        payload: value
    })
}

export const setRestaurantsListLoading = (value: any) => (dispatch: any) => {
    try {
        dispatch(
            {
                type: "SET_RESULT_LOADING",
                payload: value
            })
    } catch (error) {
        console.log(error);
    }
};

export const clearRestaurantList = () => {
    store.dispatch({
        type: "CLEAR_RESTAURANT_LIST",
    })
};

