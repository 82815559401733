import {
    useState,
    useCallback,
    useRef,
    ReactNode
} from "react";
import styled from 'styled-components';
import useDarkMode from "../../hooks/darkmode/DarkMode";

interface AccordionProps {
    title?: string;
    titleSize?: string;
    children: ReactNode;
    opened?: boolean;
    showTitle?: boolean;
}

type DirectionType = "top" | "right" | "bottom" | "left";

interface ChevronProps {
    direction: DirectionType;
    isDarkMode: boolean;
}

const Chevron = styled.div<ChevronProps>`
  border-style: solid;
  border-width: 0.125rem 0.125rem 0 0;
  border-color: ${(props) => (props?.isDarkMode ? "white" : "black")};
  height: 0.40rem;
  width: 0.40rem;
  transition: all 0.25s ease-in-out;
  margin-left: 6px;
  transform: ${(p) => p.direction === "top" && "rotate(-45deg)"};
  transform: ${(p) => p.direction === "right" && "rotate(45deg)"};
  transform: ${(p) => p.direction === "bottom" && "rotate(135deg)"};
  transform: ${(p) => p.direction === "left" && "rotate(-135deg)"};
`;


const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  /* margin-top: 0.4rem; */
  color: #22A45D;
  cursor: pointer;
`;


const ContentWrapper = styled.div<{ maxHeight: any, isDarkMode: boolean }>`
  max-height: ${(p) => `${p.maxHeight}`};
  /* max-height: ${(p) => `${p.maxHeight}px`}; */
  transition: max-height 0.25s ease-in-out;
  overflow: hidden;
  color: ${(props) => (props?.isDarkMode ? "white" : "black")};
  `;

const Content = styled.div<{ isDarkMode: boolean }>`
  /* padding: 0 0 1rem; */
  color: ${(props) => (props?.isDarkMode ? "white" : "rgba(0, 0, 0, 0.75)")};
  line-height: 1.5;
  height: auto;
  `;


export default function AccordionCheckoutCustomization({ title, children, titleSize = "", opened = false, showTitle = true }: AccordionProps): JSX.Element {
    const [isExpanded, setExpand] = useState<boolean>(opened);

    const contentRef = useRef<HTMLDivElement>();
    const contentHeight = isExpanded ? "100%" : 0;
    const isDarkMode = useDarkMode();

    const handleExpandToggle = useCallback(() => {
        setExpand(!isExpanded);
    }, [isExpanded]);

    return (
        <>
            {showTitle
                &&
                <Title onClick={handleExpandToggle} style={{ fontSize: titleSize }}>
                    {title}
                    <Chevron direction={isExpanded ? "bottom" : "right"} isDarkMode={isDarkMode} />
                </Title>
            }
            <ContentWrapper
                maxHeight={contentHeight}
                isDarkMode={isDarkMode}
            >
                <Content
                    ref={contentRef}
                    isDarkMode={isDarkMode}
                >{children}
                </Content>
            </ContentWrapper>
        </>
    );
};
