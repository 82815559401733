import Divider from "../../components/widgets/divider/Divider";
import Section from "../../components/widgets/section/Section";
import TopHead from "../../components/widgets/head/TopHead";
import { useHistory } from "react-router";
import { faAngleRight } from "@fortawesome/pro-regular-svg-icons";
import { faAngleLeft } from "@fortawesome/pro-solid-svg-icons";
import CardRibbonImgText from "../../components/widgets/cards/CardRibbonImgText";
import cardIconImg from "../../components/assets/RectangleCardIcon.png"
import paypalImg from "../../components/assets/PaypalRectangle.png"


export default function CardList() {
  const history = useHistory();

  const GotoHandle = () => {
    history.push({
      pathname: '/newmealaddparticipants',
    })
  }

  return (
    <div className="screen">
      <TopHead
        title="Payment Methods"
        leftTextIcon={faAngleLeft}
        leftIconStyle={{ fontSize: "24px" }}
        goToHandle={GotoHandle}
      />
      <Divider />

      <div className="grow overflow-auto">
        <Section>
          <CardRibbonImgText
            img={paypalImg}
            title="PayPal"
            subText="Default Payment"
            rightIcon={faAngleRight}
          />
          <Divider />
          <CardRibbonImgText
            img={cardIconImg}
            title="Credit Card"
            subText="Not Default"
            rightIcon={faAngleRight}
            toLocation={() => history.push("/paymentmethod")}
          />
          <Divider />
        </Section>
      </div>
    </div >
  );
}