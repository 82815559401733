import Section from "../../components/widgets/section/Section";
import { ButtonGreen, InputLabel, InputText2 } from "../../components/styled/styled";
import { useHistory } from "react-router";
import { useState } from "react";
import { setUserData } from "../../services/user";
import Spinner from "../../components/widgets/spinner/Spinner";
import { handleKeyDownNumber, switchSentenceCase, textToNumberFilter, trackPixelEvent, validateEmail } from "../../utils/utilities";
import { loginRegisterUser } from "../../services/user";
import { useIonRouter } from "@ionic/react";
import useDarkMode from "../../components/hooks/darkmode/DarkMode";
import { objectType } from "../../type";
import store from "../../redux/store";

export default function SignInWithEmail(props: objectType) {
  const history = useHistory();
  const router = useIonRouter();
  const isDarkMode = useDarkMode();

  let checkInputTypeIsPhone = /^\+?\d+$/.test(props?.inputTypeValue?.inputValue);

  const [inputValue, setInputValue] = useState((checkInputTypeIsPhone ? "" : props?.inputTypeValue?.inputValue) || "");
  const [inputError, setInputError] = useState(false);
  const [awaiting, setAwaiting] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  //***************** HANDLE CHANGE FUNCTION *****************//
  const handleChange = async (e: any) => {
    const { value } = e.target;
    setErrorMessage("");
    const validEmail = validateEmail(value);

    if (!validEmail) {
      setInputError(true);
    } else {
      setInputError(false);
    }
    setInputValue(textToNumberFilter(value));
  };


  //***************** HANDLE KEY DOWN FUNCTION *****************//
  const handleKeyDown = (e: any) => {
    const number = handleKeyDownNumber(e, inputValue);
    setInputValue(number)
    if (e.key === 'Enter') {
      handleSubmit();
    }
  };

  //***************** HANDLE SUBMIT FUNCTION *****************//
  const handleSubmit = async () => {
    try {
      // Validation-----------------------
      if (inputValue === "") {
        setErrorMessage("Email address is required.");
        return false;
      }

      const validEmail = validateEmail(inputValue);

      if (!validEmail) {
        setErrorMessage("Please enter a valid email address.");
        return false;
      }
      else if (validEmail) {
        setErrorMessage("");
      }
      store.dispatch({
        type: "SET_LOGIN_STATE",
        payload: { loginState: true }
      })
      // Api Call--------------------------
      setAwaiting(true);
      await loginRegisterUser(inputValue.toLowerCase(), "email")
        .then(async (response: any) => {
          trackPixelEvent('Login register user');
          let data = response?.data;
          if (data.status === "pending") {
            store.dispatch({
              type: "SET_LOGIN_STATE",
              payload: { loginState: false }
            })
            await setUserData(data);
            if (router.routeInfo.pathname === '/login') {
              history.push('/verify-otp');
            }
            else {
              props?.setShowVerifyModal(true);
            }
          }
        })
    } catch (error) {
      // console.log(error);
      setAwaiting(false);
      store.dispatch({
        type: "SET_LOGIN_STATE",
        payload: { loginState: false }
      })
    }
  }

  return (
    <>
      <Section>
        <InputLabel className="mb-[10px] mt-4">Please enter your email address.</InputLabel>
        <InputText2
          className={isDarkMode ? "!bg-transparent" : ""}
          placeholder="Enter Your Email Address"
          name="inputValue"
          value={inputValue}
          onChange={handleChange}
          maxLength={50}
          onKeyDown={handleKeyDown}
          disabled={props?.loginState}
          type="text"
          style={{
            border: inputError ? "1px solid red" : ""
          }}
        />
        {errorMessage ? <p className="mt-2 text-red-600">{errorMessage}</p> : ""}
        {(awaiting === false)
          ?
          <ButtonGreen
            className="mt-[15px]"
            onClick={handleSubmit}
            disabled={props?.loginState}
          >
            {switchSentenceCase("primary button", "CONTINUE")}
          </ButtonGreen>
          :
          <div className="text-center">
            <Spinner />
          </div>
        }
      </Section>
    </>
  );
}

