import { ReactNode, useRef } from "react";
import styled from 'styled-components';
import { isDarkMode, moveTopBottom } from "../../styled/styled";
import useDarkMode from "../../hooks/darkmode/DarkMode";


interface ModalBottomToTopProps {
    title: string;
    subTitle: string;
    children: ReactNode;
}

const ModalDiv = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0, 0.8);
  z-index: 1000;
  overflow-y: auto;
`;


const ContentDiv = styled.div<{ isDarkMode: boolean }>`
    background-color:${props => (props?.isDarkMode ? '#2e2e2e' : '#fff')};
    margin-top: 80px;
    padding: 20px;
    z-index: 1000;
    width: 720px;
    max-width: 100%;
    border-radius: 0.5em;
    text-align: center;
    height: inherit;
    animation: ${moveTopBottom} .4s ease-in-out;
`;


const DashModalIcon = styled.div`
  margin-top: -25px;
`;


const TextMeal = styled.h3`
font-weight: 600;
 font-size: 21px;
`;


const IntialDiv = styled.div`

`;


const ModalBottomToTop = (props,) => {

    const isDarkMode = useDarkMode();
    const contentRef = useRef<HTMLDivElement>();
    const modalRef = useRef()

    const closeModal = e => {
        if (modalRef.current === e.target) {
            props?.setShowModal(false)
        }
    }


    return (
        <>
            {props?.showModal ? (
                <ModalDiv ref={modalRef} onClick={closeModal}>
                    <ContentDiv isDarkMode={isDarkMode}>
                        <DashModalIcon ><i className="fa-solid fa-dash" style={{ fontSize: "35px", color: "#E7E7E7", }}></i></DashModalIcon>
                        <TextMeal >{props?.title}</TextMeal>
                        <TextMeal >{props?.subtitle}</TextMeal>
                        <IntialDiv ref={contentRef}>{props?.children}</IntialDiv>
                    </ContentDiv>
                </ModalDiv >
            ) : null
            }
        </>
    );
};


export default ModalBottomToTop;
