import { combineReducers } from "redux";
import getAllFavData from "../pages/faves/redux/favouriteReducer"
import getUserSearchLocation from "../components/widgets/address/redux/addressReducer";
import getUserSearchedRestaurants from "../pages/search/redux/searchReducer";
import getUserRestaurantsLists from "../pages/search/redux/searchReducer";
import getRestaurantsMenuLists from "../pages/restaurant/redux/menuItemReducer";
import createScheduleForMe from "../pages/schedules/redux/createScheduleForMe";
import sessionAlertState from "../services/redux/sessionAlertState";
import userAddresses from "../pages/profile/redux/locationReducer";
import autocompleteSearch from "../pages/scheduleotherpages/redux/scheduleReducer";
import getRestaurantData from "../pages/orderFood/redux/orderFoodReducer";
import upcomingOrderState from "../pages/orders/redux/upcomingOrderReducer";
import scheduleInvitationState from "../pages/scheduleInvitations/redux/invitationReducer";

const rootReducer = combineReducers({
    getAllFavData,
    getUserSearchLocation,
    getUserSearchedRestaurants,
    getUserRestaurantsLists,
    getRestaurantsMenuLists,
    createScheduleForMe,
    sessionAlertState,
    userAddresses,
    autocompleteSearch,
    getRestaurantData,
    upcomingOrderState,
    scheduleInvitationState
})

export default rootReducer;