import { apiRequestService } from './apiRequestService';
import { getUserData } from './user';

const API_URL = process.env.REACT_APP_API_URL

export async function addAccount(data: any) {
    try {
        const user = await getUserData();

        let config = {
            method: 'POST',
            url: API_URL + `/v1/user-accounts/create`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + user?.jwt
            },
            data: data
        };
        const request = apiRequestService(config);
        return request;
    }
    catch (error) {
        console.log(error);

    }
}

export async function verifyAccount(data: any) {
    try {

        const user = await getUserData();

        let config = {
            method: 'POST',
            url: API_URL + `/v1/user-accounts/verify`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + user?.jwt
            },
            data: data
        };
        const request = apiRequestService(config);
        return request;
    }
    catch (error) {
        console.log(error);
    }
}


export const getUserAllAccounts = async () => {
    const user = await getUserData();
    try {
        let config = {
            method: 'get',
            url: API_URL + `/v1/user-accounts?filters[user_id][id][$eq]=${user?.user?.id}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + user?.jwt
            },
        };
        const request = apiRequestService(config);
        return request;
    } catch (error) {
        console.log(error);
    }
}



//Delete Account
export async function deleteUserAccount(id: any) {
    try {
        const user = await getUserData();
        let config = {
            method: 'delete',
            url: API_URL + `/v1/user-accounts/${id}`,
            headers: {
                'Authorization': 'Bearer ' + user?.jwt
            },
        };
        const request = apiRequestService(config);
        return request;
    }
    catch (error) {
        console.log(error);
    }
}
