import store from "../../../redux/store";

export const setUpcomingOrdersForMe = (value: any) => {
    store.dispatch({
        type: "SET_UPCOMING_ORDERS_FOR_ME",
        payload: value
    })
}

export const setUpcomingOrdersFromMe = (value: any) => {
    store.dispatch({
        type: "SET_UPCOMING_ORDERS_FROM_ME",
        payload: value
    })
}

export const setTotalUpcomingOrdersCount = (value: any) => {
    store.dispatch({
        type: "SET_TOTAL_ORDERS_COUNT",
        payload: value
    })
}

export const setOrderForSomeone = (value: any) => {
    store.dispatch({
        type: "SET_ORDER_FOR_SOMEONE",
        payload: value
    })
}

export const getUpcomingOrdersMealDetails = (faveId: any, upcomingOrderType: any, itemId: any) => async (dispatch: any) => {
    dispatch(
        {
            type: "GET_UPCOMING_ORDERS_MEAL_DETAILS",
            payload: { faveId: faveId, upcomingOrderType: upcomingOrderType, id: itemId }
        }
    )
}

export const rescheduleSlot = (id: any, updatedData: any, orderType: any) => async (dispatch: any) => {
    dispatch(
        {
            type: "RESCHEDULE_SLOT",
            payload: { updatedData, id, orderType }
        })
}

export const updateScheduleData = (id: any, updatedData: any) => async (dispatch: any) => {
    try {
        dispatch(
            {
                type: "UPDATE_SCHEDULE_DATA",
                payload: { data: updatedData, id: id }
            })
    }
    catch (error) {
        console.log(error);
    }
}
