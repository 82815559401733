import { useState } from "react";
import Divider from "../../components/widgets/divider/Divider";
import Section from "../../components/widgets/section/Section";
import styled from 'styled-components';
import TopHead from "../../components/widgets/head/TopHead";
import { ButtonGreen, Flex, FlexBetween, InitialDiv, InputText, ItemCount, SubText1, TextHead4, TextHead5 } from "../../components/styled/styled";
import { switchSentenceCase } from "../../utils/utilities";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleRight } from "@fortawesome/pro-regular-svg-icons";
import { faAngleLeft } from "@fortawesome/pro-solid-svg-icons";
import cardIconImg from "../../components/assets/RectangleCardIcon.png"
import ModalSuccess from "../../components/widgets/modals/ModalSuccess";
import { useHistory } from "react-router";



const BlockContainer = styled.div`
    display: block;
    width: -webkit-fill-available;
    text-align: left;
`;


const Container = styled.div`
   margin: 13px 5px;
`;



export default function OrderDetails(data: any) {
  const history = useHistory();
  const [showPromoInput, setShowPromoInput] = useState(true);
  const [showModalSuccess, setShowModalSuccess] = useState(false);


  return (
    <div className="screen">

      <TopHead
        title="All Aboard"
        leftTextIcon={faAngleLeft}
        leftIconStyle={{ fontSize: "24px" }}
      />
      <Divider />

      <div className="grow overflow-auto">
        <Section>
          <Container >
            <FlexBetween className="my-2.5">
              <ItemCount>1</ItemCount>
              <BlockContainer>
                <FlexBetween >
                  <TextHead4>Recipient</TextHead4>
                  <TextHead5 className="text-green-600 underline">EDIT</TextHead5>
                </FlexBetween >
                <InitialDiv >
                  <SubText1 className="mt-2">Andrew Scholar</SubText1>
                </InitialDiv>
              </BlockContainer>
            </FlexBetween>
            <Divider />
            <Flex className="my-2.5">
              <ItemCount>2</ItemCount>
              <BlockContainer>
                <FlexBetween>
                  <TextHead4>Duration</TextHead4>
                  <TextHead5 className="text-green-600 underline">EDIT</TextHead5>
                </FlexBetween >
                <InitialDiv >
                  <SubText1 className="mt-2">Dates: Mar 14 - Mar 30, 2022</SubText1>
                  <SubText1 className="mt-1">Meals/Day: 2</SubText1>
                  <SubText1 className="mt-1">Overflow: Yes</SubText1>
                </InitialDiv>
              </BlockContainer>
            </Flex>
            <Divider />
            <Flex className="my-2.5">
              <ItemCount>3</ItemCount>
              <BlockContainer>
                <FlexBetween>
                  <TextHead4>Participants</TextHead4>
                  <TextHead5 className="text-green-600 underline">EDIT</TextHead5>
                </FlexBetween >
                <InitialDiv >
                  <SubText1 className="mt-2">Hashim Velez</SubText1>
                  <SubText1 className="mt-1">Soraya Taylor</SubText1>
                  <SubText1 className="mt-1">Yanis Patton</SubText1>
                </InitialDiv>
              </BlockContainer>
            </Flex>

            <Divider />

            <FlexBetween className="mt-2.5">
              <TextHead5>Subtotal</TextHead5>
              <TextHead5>$20.05</TextHead5>
            </FlexBetween>
            <FlexBetween>
              <TextHead5>Taxes</TextHead5>
              <TextHead5>$4.00</TextHead5>
            </FlexBetween>
            <FlexBetween >
              <TextHead5 style={{ fontWeight: "500" }}>Total</TextHead5>
              <TextHead5 style={{ fontWeight: "500" }}>$31.01</TextHead5>
            </FlexBetween>

            <TextHead5 onClick={() => setShowPromoInput(prev => !prev)} style={{ marginBottom: "10px", fontWeight: "500", marginTop: "15px" }}>Promo code<FontAwesomeIcon icon={showPromoInput ? faAngleRight : faAngleDown} style={{ float: "right" }} /></TextHead5>
            {showPromoInput === false
              ?
              <InputText style={{ marginBottom: "20px" }} placeholder="Enter Promo Code"></InputText>
              :
              null
            }
            <Divider />
            <FlexBetween className="items-center">
              <div className="flex mt-5"><img alt="" src={cardIconImg} className="mr-2" /><TextHead5>•••• 1234</TextHead5></div><FontAwesomeIcon icon={faAngleRight} />
            </FlexBetween>
          </Container>
          <Divider />
          <ButtonGreen onClick={() => setShowModalSuccess(prev => !prev)}>{switchSentenceCase("primary button", "PLACE ORDER")}</ButtonGreen>
        </Section>

        <ModalSuccess
          firstText="You Place the Order Successfully"
          secondText="You placed the order successfully."
          btnOneText="SHARE"
          btnTwoText="HOME"
          btnTwoStyle={{ border: "1px solid #e1e1e1", marginTop: "15px" }}
          btnOneGoto={() => history.push({ pathname: "/refertofriends" })}
          btnTwoGoto={() => history.push({ pathname: "/home" })}
          showModal={showModalSuccess}
          setShowModal={setShowModalSuccess} />
      </div>
    </div >
  );
}