import { useContext, useEffect, useRef } from "react";
import loadGoogle from "../../../services/google";
import { nullthrows } from "@strut/utils";
import styled from 'styled-components';
import { UserContext } from "../../../App";
import { useDispatch } from "react-redux";
import { setUserSearchLocation, getLocationName } from "./redux/addressActionCreator";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot } from "@fortawesome/pro-solid-svg-icons";
import useDarkMode from "../../hooks/darkmode/DarkMode";
import { trackPixelEvent } from "../../../utils/utilities";


/*props => (props?.isDarkMode ? 'none' : '#FBFBFB')*/
const InputAddress = styled.input<{ isDarkMode: boolean }>`
    background: none;
    border: 1px solid #e1e1e1;
    border-radius: 8px;
    // color: ${props => (props?.isDarkMode ? '#c5c5c5' : '#726d6d')};
    outline-color: rgb(134 134 134 / 14%);
    padding-left: 2rem;
    width: 100%;
    height:40px;
    //   ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    //     color: ${props => (props?.isDarkMode ? '#9898983b' : '#c3c3c3')};
    //     opacity: 1;
    // }
`;

export default function AddressAutocomplete2({
    onAddressChosen,
    place,
}: {
    onAddressChosen: (place: google.maps.places.PlaceResult) => void;
    place,
}) {

    // ******************** hooks **************************
    const dispatch = useDispatch();
    const isDarkMode = useDarkMode();
    const addrAutcomplete = useRef<HTMLInputElement | null>();
    const { userValue, setUserValue } = useContext(UserContext);


    useEffect(() => {
        // set up places API
        let listener: google.maps.MapsEventListener | null = null;
        let unmounted = false;
        loadGoogle().then((google) => {
            trackPixelEvent('Load google location');
            if (unmounted) {
                return;
            }

            const autocompleteInput = nullthrows(addrAutcomplete.current);

            if (place) {
                autocompleteInput.value =
                    String(
                        (place.streetNum === undefined) ? "" : place.streetNum +
                            " " +
                            place.streetName +
                            ", " +
                            place.city +
                            ", " +
                            place.state
                    ).trim()
            } else {
                autocompleteInput.value = "";
            }
            const autocomplete = new google.maps.places.Autocomplete(
                autocompleteInput,
                {
                    fields: ["address_components", "geometry", "name"],
                    types: ["address"],
                    componentRestrictions: {
                        country: "us"
                    }
                }
            ) as google.maps.places.Autocomplete;

            listener = autocomplete.addListener("place_changed", () => {
                const place = autocomplete.getPlace();
                fillInAddress(place);
            });
        });

        return () => {
            if (listener) {
                listener.remove();
            }
            unmounted = true;
        };
    }, [place]);


    const fillInAddress = (place: google.maps.places.PlaceResult) => {

        const addressNameFormat = {
            street_number: "short_name",
            sublocality_level_1: "long_name",
            route: "long_name",
            locality: "long_name",
            administrative_area_level_1: "short_name",
            country: "short_name",
            postal_code: "short_name",
        };


        const getAddressComp = function (type: any) {
            for (const component of place.address_components || []) {
                if (component.types[0] === type) {
                    return component[addressNameFormat[type]];
                }
            }
            return "";
        };

        const autocompleteInput = nullthrows(addrAutcomplete.current);
        // autocompleteInput.value =
        //   getAddressComp("street_number") + " " + getAddressComp("route");
        onAddressChosen(place);

        let premise = "";
        let streetNum = "";
        let streetName = "";
        let streetAddress = "";
        let city = "";
        let state = "";
        let country = "";
        let zip = "";


        for (let i = 0; i < place.address_components.length; i++) {
            for (let j = 0; j < place.address_components[i].types.length; j++) {
                switch (place.address_components[i].types[j]) {
                    case "premise":
                        premise = place?.address_components[i]?.long_name;
                        break;
                    case "street_number":
                        streetNum = place?.address_components[i]?.long_name;
                        break;
                    case "route":
                        streetName = place?.address_components[i]?.long_name;
                        break;
                    case "street_address":
                        streetAddress = place?.address_components[i]?.long_name;
                        break;
                    case "locality":
                        city = place?.address_components[i]?.long_name;
                        break;
                    case "administrative_area_level_1":
                        state = place?.address_components[i]?.short_name;
                        break;
                    case "country":
                        country = place?.address_components[i]?.short_name;
                        break;
                    case "postal_code":
                        zip = place?.address_components[i]?.long_name;
                        break;
                }
            }
        }
        let lat = place?.geometry?.location.lat()
        let lang = place?.geometry?.location.lng()

        const placeObject = { addressLine1: (streetNum + " " + streetName), addressLine2: premise, streetAddress, streetNum, streetName, city, state, country, zip, lat, lang }
        setUserSearchLocation(placeObject);
        setUserValue(placeObject);
    };


    return (
        <div className="relative w-full">
            <FontAwesomeIcon icon={faLocationDot} style={{ color: "#868686", position: "absolute", top: 12, left: 12 }} />
            <InputAddress
                type="text"
                ref={(ref) => (addrAutcomplete.current = ref)}
                placeholder="Enter the delivery location"
                onChange={(event) => dispatch(getLocationName(event.target.value) as any)}
                isDarkMode={isDarkMode}
                autoComplete="off"
                autoCapitalize="none"
                spellCheck="false"

            />
        </div>

    );
}
