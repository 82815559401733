import styled from 'styled-components';

const FlexBetween = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px;
`;

const Span = styled.span`
`;

export const CheckBoxWrapper = styled.div`
  position: relative;
  margin-left: 5px;
  margin-right: 5px;
  cursor: pointer;
`;


export const CheckBoxLabel = styled.label<{ checked?: boolean; }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 42px;
  height: 22px;
  border-radius: 15px;
  background: #bebebe;
  cursor: pointer;
  &::after {
    content: "";
    display: block;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    margin: 3px;
    background: #ffffff;
    box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.2);
    transition: 0.2s;
  }
`;


export const CheckBox = styled.input`
  opacity: 0;
  z-index: 1;
  border-radius: 15px;
  width: 42px;
  height: 26px;
  cursor: pointer;
  &:checked + ${CheckBoxLabel} {
    background: #4fbe79;
    &::after {
      content: "";
      display: block;
      border-radius: 50%;
      width: 15px;
      height: 15px;
      margin-left: 23px;
      transition: 0.2s;
    }
  }
`;



export default function OneSideTextToggle(props: any) {

  return (
    <FlexBetween>
      <Span style={props.style}>{props.leftText}</Span>
      <Span style={{ marginLeft: "auto", color: "rgb(34 164 93)" }}>{props.beforeBtnText}</Span>
      <CheckBoxWrapper style={props.disabled == true ? { pointerEvents: "none" } : null}>
        <CheckBox disabled={props.disabled} id={props?.id} name={props?.name} type="checkbox" onChange={() => props?.handleOnChangeToggle()} checked={props?.switchState} style={{ position: "relative" }} />
        <CheckBoxLabel htmlFor={props?.id} checked={props?.switchState} style={{ background: props.disabled ? "#b9e2c8" : "" }} />
      </CheckBoxWrapper>
    </FlexBetween>
  );
};



