import { GoogleAuth } from "@codetrix-studio/capacitor-google-auth";
import { faFacebook } from "@fortawesome/free-brands-svg-icons";
import { faAngleRight } from "@fortawesome/pro-regular-svg-icons";
import { faArrowRightFromBracket, faBell, faBookBlank, faCircleDollar, faCreditCard, faHeadset, faLocationDot, faStar, faTrash, faUser, faUserGroup } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IonContent, IonRefresher, IonRefresherContent, RefresherEventDetail, useIonViewDidEnter } from "@ionic/react";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import styled from "styled-components";
import loader from "../../components/assets/loading.svg";
import exProfileImg from "../../components/assets/profile/no-profile-photo.png";
import useDarkMode from "../../components/hooks/darkmode/DarkMode";
import { InitialDiv, SubText1 } from "../../components/styled/styled";
import { setUserSearchLocation } from "../../components/widgets/address/redux/addressActionCreator";
import ToggleWithSubText from "../../components/widgets/buttons/ToggleWithSubText";
import CardRibbon from "../../components/widgets/cards/CardRibbon";
import Divider from "../../components/widgets/divider/Divider";
import ModalDeleteAccount from "../../components/widgets/modals/ModalDeleteAccount";
import Section from "../../components/widgets/section/Section";
import { getUserData, setUserData, userProfileUpdate } from "../../services/user";
import { isAuthenticated, switchSentenceCase, trackPixelEvent } from "../../utils/utilities";
import { setRecentOrderList, setUserFaveMealList } from "../faves/redux/favouriteActionCreator";
import { clearRestaurantList, setNearByRestaurants, setNearByScheduleRestaurants } from "../search/redux/searchActionCreator";
import { setUserDeliveryAddress } from "./redux/locationActionCreator";



const HeadText = styled.h1`
  text-align: left;
  font-size: 24px;
  line-height: 36px;
  margin-top: 15px;
  font-weight: 700;
`;


const SubText = styled.h1`
  text-align: left;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #868686;
  margin-bottom: 15px;
`;


const Head2Text = styled.h1<{ isDarkMode: boolean }>`
  font-size: 19px;
  line-height: 36px;
  color: ${props => (props?.isDarkMode ? '#e1e1e1a3' : '#010f07a3')};
`;

export default function UserProfile() {
  const isDarkMode = useDarkMode();
  const history = useHistory();
  const [userName, setUserName] = useState("");
  const [userImage, setUserImage] = useState("");
  const [userCity, setUserCity] = useState("");
  const [userImageLoading, setUserImageLoading] = useState(true);
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [messageInvite, setMessageInvite] = useState(false);
  const [smsNotification, setSmsNotification] = useState(false);
  const [emailNotification, setEmailNotification] = useState(false);
  const [promotionNotification, setPromotionNotification] = useState(false);
  const [isRefreshed, setIsRefreshed] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);


  useEffect(() => {
    fetchingUserData();
  }, [isRefreshed]);

  useIonViewDidEnter(() => {
    trackPixelEvent('User Profile');
  }, []);


  function handleRefresh(event: CustomEvent<RefresherEventDetail>) {
    setTimeout(() => {
      setIsRefreshed(prev => !prev);
      event.detail.complete();
    }, 2000);
  }

  const fetchingUserData = async () => {
    if (isAuthenticated() === false) return false;
    const user = await getUserData();
    setUserImage(user?.user?.profile_pic?.url);
    setUserName(user?.user?.name);
    setUserCity(user?.user?.city);
    setSmsNotification(user?.user?.sms_notifications);
    setMessageInvite(user?.user?.invite_notifications);
    setEmailNotification(user?.user?.email_notifications);
    setPromotionNotification(user?.user?.promotional_notifications);
    setIsDataLoading(false)
  };

  const onChangingUserData = (data: any) => {
    userProfileUpdate(data)
      .then((res) => {
        trackPixelEvent('User profile update');
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onMessageInvite = async () => {
    setMessageInvite(!messageInvite);
    const data = {
      invite_notifications: !messageInvite,
    }
    const localData = await getUserData();
    const newUpdatedUserInfo = {
      ...localData,
      user: ({
        ...localData.user,
        "invite_notifications": !messageInvite,
      })
    };
    await setUserData(newUpdatedUserInfo);
    onChangingUserData(data)
  }

  const onSmsNotification = async () => {
    setSmsNotification(!smsNotification);
    const data = {
      sms_notifications: !smsNotification,
    }
    const localData = await getUserData();
    const newUpdatedUserInfo = {
      ...localData,
      user: ({
        ...localData.user,
        "sms_notifications": !smsNotification,
      })
    };
    await setUserData(newUpdatedUserInfo);
    onChangingUserData(data)
  }

  const onEmailNotification = async () => {
    setEmailNotification(!emailNotification);
    const data = {
      email_notifications: !emailNotification
    }
    const localData = await getUserData();
    const newUpdatedUserInfo = {
      ...localData,
      user: ({
        ...localData.user,
        "email_notifications": !emailNotification,
      })
    };
    await setUserData(newUpdatedUserInfo);
    onChangingUserData(data)
  }

  const onPromotionNotification = async () => {
    setPromotionNotification(!promotionNotification);
    const data = {
      promotional_notifications: !promotionNotification
    }
    const localData = await getUserData();
    const newUpdatedUserInfo = {
      ...localData,
      user: ({
        ...localData.user,
        "promotional_notifications": !promotionNotification,
      })
    };
    await setUserData(newUpdatedUserInfo);
    onChangingUserData(data)
  }

  const handleDelete = async () => {
    setShowDeleteModal(true);
  };


  const handleLogOut = async () => {
    try {
      // Log out from Google
      try {
        await GoogleAuth.signOut();
      } catch (googleLogoutError) {
        console.error('Error signing out from Google:', googleLogoutError);
      }
      // Log out from Facebook (if user is logged in)
      // try {
      //   const signOutFacebook = await FacebookLogin.logout();
      //   console.log('Sign out from Facebook successful:', signOutFacebook);
      // } catch (facebookLogoutError) {
      //   console.error('Error signing out from Facebook:', facebookLogoutError);
      //   // Handle Facebook logout error gracefully
      // }

      // Perform any additional cleanup actions
      localStorage.removeItem("MealTrendUser");
      localStorage.removeItem("MealFaveUserDeliveryAddress");
      localStorage.removeItem("CalendarData");
      localStorage.removeItem("RestaurantData");
      localStorage.removeItem("SomeoneElseOrderData");
      setUserSearchLocation({});
      clearRestaurantList();
      setNearByRestaurants([]);
      setUserDeliveryAddress({});
      setUserFaveMealList([]);
      setRecentOrderList([]);
      setNearByScheduleRestaurants([]);

      // Redirect the user to the home page
      history.push("/");
    } catch (error) {
      console.error('Error signing out:', error);
      // Handle sign-out error gracefully
    }
  };

  return (
    <div className="screen">
      {/* <div className="grow overflow-auto"> */}
      <IonContent>
        <IonRefresher slot="fixed" onIonRefresh={handleRefresh}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>
        <Section align="center">
          <HeadText>Account Settings</HeadText>
          <SubText>
            Update your settings like notifications, payments, profile edit etc.
          </SubText>
          <InitialDiv>

            {userImage
              ?
              <img alt="User Profile" style={{ objectFit: "cover" }} className="inline w-36 h-36 rounded-full" onLoad={() => setUserImageLoading(false)} src={(userImageLoading) ? loader : `${process.env.REACT_APP_API_URL}${userImage}`} />
              :
              <img alt="User Profile" style={{ objectFit: "cover" }} className="inline w-36 h-36 rounded-full" src={(isDataLoading) ? loader : exProfileImg} />
            }

          </InitialDiv>
          <HeadText style={{ textAlign: "center" }}>
            {switchSentenceCase("head text", userName)}
          </HeadText>
          {(userCity) &&
            <div className="flex justify-center text-center" style={{ textAlign: "center" }}>
              <FontAwesomeIcon icon={faLocationDot} className="text-[#cccaca]" />
              <SubText1 className="ml-1">
                {switchSentenceCase("head text", userCity)}
              </SubText1>
            </div>
          }
          {/* <div className="subtext">{switchSentenceCase("head text", userCity)}</div> */}
        </Section>

        <Section>
          <CardRibbon
            styleDiv={{ marginTop: "0px" }}
            title="Profile Information"
            subText="Change your phone and email information"
            leftIcon={faUser}
            leftIconStyle={{ color: "#6b7280", fontSize: "1.25rem" }}
            rightIcon={faAngleRight}
            rightIconStyle={{ fontSize: "1.25rem" }}
            handleManage={() => history.push("/userprofiledetails")}
          />

          <Divider />
          <CardRibbon
            styleDiv={{ marginTop: "10px" }}
            title="Payment Methods"
            subText="Add your credit & debit cards"
            leftIcon={faCreditCard}
            leftIconStyle={{ color: "#6b7280", fontSize: "1rem" }}
            rightIcon={faAngleRight}
            rightIconStyle={{ fontSize: "1.25rem" }}
            handleManage={() => history.push("/paymentmethod")}
          />

          <Divider />
          <CardRibbon
            styleDiv={{ marginTop: "10px" }}
            title="Auto Tip Preferences"
            subText="Decide if and how much to tip by default"
            leftIcon={faCircleDollar}
            leftIconStyle={{ color: "#6b7280", fontSize: "1.2rem" }}
            rightIcon={faAngleRight}
            rightIconStyle={{ fontSize: "1.25rem" }}
            handleManage={() => history.push("/tippreference")}
          />

          <Divider />
          <CardRibbon
            styleDiv={{ marginTop: "10px" }}
            title="Locations"
            subText="Add or remove your delivery locations"
            leftIcon={faLocationDot}
            leftIconStyle={{ color: "#6b7280", fontSize: "1.25rem", marginRight: "5px" }}
            rightIcon={faAngleRight}
            rightIconStyle={{ fontSize: "1.25rem" }}
            handleManage={() => history.push("/addlocationlist")}
          />

          <Divider />
          <CardRibbon
            styleDiv={{ marginTop: "10px" }}
            title="Add Social Account"
            subText="Add Facebook, Twitter etc"
            leftIcon={faFacebook}
            leftIconStyle={{ color: "#6b7280", fontSize: "1.25rem" }}
            rightIcon={faAngleRight}
            rightIconStyle={{ fontSize: "1.25rem" }}
            handleManage={() => history.push("/socialaccounts")}
          />
          <Divider />

          <CardRibbon
            styleDiv={{ marginTop: "10px" }}
            title="Friends"
            subText="Add Friends, Find Friends etc"
            leftIcon={faUserGroup}
            leftIconStyle={{ color: "#6b7280", fontSize: "1.25rem" }}
            rightIcon={faAngleRight}
            rightIconStyle={{ fontSize: "1.25rem" }}
            handleManage={() => history.push("/friends")}
          />
          <Divider />

          {/* <CardRibbon
            styleDiv={{ marginTop: "10px" }}
            title="Manage Account"
            subText="Add Account, Remove Account etc"
            leftIcon={faPeopleRoof}
            leftIconStyle={{ color: "#6b7280", fontSize: "1.25rem" }}
            rightIcon={faAngleRight}
            rightIconStyle={{ fontSize: "1.25rem" }}
            handleManage={() => history.push("/manage-accounts")}
          /> */}
          <Divider />
          {/* <button
            style={{ backgroundColor: "red" }}
            onClick={onChangingUserData}
          >
            Change DAta
          </button> */}

          <Head2Text style={{ marginTop: "40px" }} isDarkMode={isDarkMode}>NOTIFICATIONS</Head2Text>

          <ToggleWithSubText
            iconLeft={faBell}
            leftIconStyle={{ color: "#6b7280", fontSize: "1rem" }}
            text="Message Me for New Invites as Sponsor"
            subText="Text when invited to a new calendar (Recipients are always notified)"
            switchState={messageInvite}
            handleOnChangeToggle={onMessageInvite}
            name="toggleBtn1"
          />
          <Divider />
          <ToggleWithSubText
            iconLeft={faBell}
            leftIconStyle={{ color: "#6b7280", fontSize: "1rem" }}
            text="SMS Notifications"
            subText="To text or not to text"
            switchState={smsNotification}
            handleOnChangeToggle={onSmsNotification}
            name="toggleBtn2"
          />
          <Divider />
          <ToggleWithSubText
            iconLeft={faBell}
            leftIconStyle={{ color: "#6b7280", fontSize: "1rem" }}
            text="Email Notifications"
            subText="Email me notifications"
            switchState={emailNotification}
            handleOnChangeToggle={onEmailNotification}
            name="toggleBtn3"
          />
          <Divider />
          <ToggleWithSubText
            iconLeft={faBell}
            leftIconStyle={{ color: "#6b7280", fontSize: "1rem" }}
            text="Promotional Notifications"
            subText="Tell me what’s going on"
            switchState={promotionNotification}
            handleOnChangeToggle={onPromotionNotification}
            name="toggleBtn4"
          />
          <Divider />

          <Head2Text style={{ marginTop: "30px", marginBottom: "10px" }} isDarkMode={isDarkMode}>
            MORE
          </Head2Text>
          <CardRibbon
            title="Privacy"
            subText="Privacy Settings"
            leftIcon={faStar}
            leftIconStyle={{ color: "#6b7280", fontSize: "1.25rem" }}
            rightIcon={faAngleRight}
            rightIconStyle={{ fontSize: "1.25rem" }}
            handleManage={() => history.push("/privacypolicy")}
          />
          <Divider />
          <CardRibbon
            styleDiv={{ marginTop: "10px" }}
            title="Contact Us"
            subText="Questions?  Need Help?  Have a suggestion?"
            leftIcon={faHeadset}
            leftIconStyle={{ color: "#6b7280", fontSize: "1.25rem", marginRight: "5px" }}
            rightIcon={faAngleRight}
            rightIconStyle={{ fontSize: "1.25rem" }}
            handleManage={() => history.push("/contactus")}
          />
          <Divider />
          <CardRibbon
            styleDiv={{ marginTop: "10px" }}
            title="Delete Account"
            subText="Request to Delete Account"
            leftIcon={faTrash}
            leftIconStyle={{ color: "#6b7280", fontSize: "1.25rem", marginRight: "5px" }}
            rightIcon={faAngleRight}
            rightIconStyle={{ fontSize: "1.25rem" }}
            handleManage={() => handleDelete()}
          />
          <Divider />
          <CardRibbon
            styleDiv={{ marginTop: "10px" }}
            title="FAQs"
            subText="Frequently asked questions"
            leftIcon={faBookBlank}
            leftIconStyle={{ color: "#6b7280", fontSize: "1.25rem", marginRight: "5px" }}
            rightIcon={faAngleRight}
            rightIconStyle={{ fontSize: "1.25rem" }}
            handleManage={() => history.push("/faqs")}
          />
          <Divider />
          <CardRibbon
            styleDiv={{ margin: "10px 0px" }}
            title="Logout"
            subText=""
            leftIcon={faArrowRightFromBracket}
            leftIconStyle={{ color: "#6b7280", fontSize: "1.25rem", marginRight: "3px" }}
            rightIcon={faAngleRight}
            rightIconStyle={{ fontSize: "1.25rem" }}
            handleManage={handleLogOut}
          />
        </Section>
      </IonContent>
      {/* </div> */}
      {showDeleteModal &&
        <ModalDeleteAccount
          showModal={showDeleteModal}
          setShowModal={setShowDeleteModal}
        />
      }
    </div>
  );
}
