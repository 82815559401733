import { faHeart } from "@fortawesome/pro-regular-svg-icons";
import { faCircleInfo, faXmark } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useIonRouter } from "@ionic/react";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import styled from "styled-components";
import { removeOrderMealItem, removeRestaurantMenuData } from "../../../pages/orderFood/redux/orderFoodActionCreator";
import { orderFoodForSlot, setOrderRedirectUrl } from "../../../pages/schedules/redux/createScheduleReduxActions";
import { finalizeOrder } from "../../../services/orderFood";
import { removeSlot } from "../../../services/schedule";
import { finalizeOrderForSlots } from "../../../services/schedule_slot";
import { getUserInfo } from "../../../services/user";
import {
  finalizeItemPriceCalculate,
  priceFormater,
  priceFormatterUsd,
  properNameFormate,
  slotDateTimeFormatter,
  switchSentenceCase,
  trackPixelEvent,
  trackStandardPixelEvent,
} from "../../../utils/utilities";
import useDarkMode from "../../hooks/darkmode/DarkMode";
import {
  ButtonGreen,
  Flex,
  FlexBetween,
  InitialDiv,
  TextHead1,
  TextHead3,
  TextHead4,
  TextHead5,
  moveTopBottom
} from "../../styled/styled";
import AccordionCheckoutCustomization from "../accordian/AccordionCheckoutCustomization";
import Section from "../section/Section";
import Spinner from "../spinner/Spinner";
import ModalAddOrderToFave from "./ModalAddOrderToFave";
import ModalConfirmationForCards from "./ModalConfirmationForCards";
import ModalSuccessForCards from "./ModalSuccessForCards";
import ShowProductCustomizations from "./ShowProductCustomizations";

const ModalDiv = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1000;
  overflow-y: auto;
`;

const ContentDiv = styled.div<{ isDarkMode: boolean }>`
  background-color: ${props => (props?.isDarkMode ? "#2e2e2e" : "#fff")};
  margin-bottom: 85px;
  margin-top: 80px;
  padding: 10px;
  z-index: 1000;
  width: 600px;
  max-width: 90%;
  border-radius: 20px;
  text-align: center;
  height: fit-content;
  animation: ${moveTopBottom} 0.4s ease-in-out;
`;

const HeartModalIcon = styled.div`
  padding: 3px;
  background: #f03f3f29;
  border-radius: 40px;
  text-align: center;
  height: -webkit-fit-content;
  font-size: 14px;
  color: #ff000082;
  width: 28px;
  margin-top: 5px;
  margin-left: 10px;
`;

const BackModalBtn = styled.div`
  position: relative;
  right: 50%;
  font-size: 17px;
  cursor: pointer;
  margin-top: 2px;
`;

export default function ModalFinalizeOrder(props: any) {
  const history = useHistory();
  const dispatch = useDispatch();
  const router = useIonRouter();
  const user = getUserInfo();
  const isDarkMode = useDarkMode();

  const { scheduleDetails, orderRedirectUrl } = useSelector((state: any) => state.createScheduleForMe);
  const { orderForSomeone } = useSelector((state: any) => state?.upcomingOrderState);

  const [awaiting, setAwaiting] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showAddToFaveModal, setShowAddToFaveModal] = useState(false);
  const [favMealData, setFavMealData] = useState({});
  const [showWarningModal, setShowWarningModal] = useState(false);
  const [differencePrice, setDifferencePrice] = useState(0);
  const [loggedUserData, setLoggedUserData] = useState({ attributes: null, id: null });
  const [responseError, setResponseError] = useState("");
  const totalPrice = priceFormatterUsd(
    (props?.finalizeData?.data?.mealMeOrderObject?.final_quote?.quote?.subtotal || 0) +
    (props?.finalizeData?.data?.mealMeOrderObject?.final_quote?.quote?.sales_tax_cents || 0) +
    (props?.finalizeData?.data?.mealMeOrderObject?.final_quote?.quote?.service_fee_cents || 0) +
    (props?.finalizeData?.data?.mealMeOrderObject?.final_quote?.added_fees?.total_fee_cents || 0) +
    (props?.finalizeData?.data?.mealMeOrderObject?.final_quote?.quote?.delivery_fee_cents || 0) +
    (props?.finalizeData?.data?.mealMeOrderObject?.final_quote?.misc_fees[0]?.fee_amount || 0) +
    (props?.finalizeData?.data?.mealMeOrderObject?.final_quote?.tip || 0) -
    (props?.finalizeData?.data?.mealMeOrderObject?.final_quote?.promotion?.discount_total || 0)
  );

  const modalRef = useRef();

  useEffect(() => {
    setLoggedUserData({ attributes: user, id: user?.id });
  }, []);

  const todayDate = new Date().toJSON().slice(0, 10);

  const closeModal = (e: any) => {
    if (modalRef.current === e.target) {
      props?.setShowFinalizeModal(false);
      props?.setHideBackModal(false);
    }
  };

  const handleFinalizeOrder = async () => {
    try {
      setAwaiting(true);
      setResponseError("");
      const payloadData = props?.finalizeData?.orderData;
      payloadData["orderObject"] = props?.finalizeData?.data?.orderObject;
      payloadData["mealMeOrderId"] = props?.finalizeData?.data?.mealMeOrderId;
      payloadData["mealMeOrderObject"] = props?.finalizeData?.data?.mealMeOrderObject;

      const response = await finalizeOrder(payloadData);
      if (response?.status === 200) {
        // props?.setShowFinalizeModal(false);
        // clearRestaurantList();
        if (response?.data?.data?.slot?.order_status === "ORDERED") {

          // Google Analytics event snippet
          if (process.env.REACT_APP_GTAG_ENABLED_FOR_PRODUCTION === "true") {
            window.gtag('event', 'conversion', { 'send_to': 'AW-16498935235/yzfeCJ3Zi7kZEMOLp7s9', 'value': totalPrice.slice(1), 'currency': 'USD', 'transaction_id': '' });
          }

          trackStandardPixelEvent('Purchase', {
            content_ids: null,
            content_name: null,
            content_type: null,
            contents: null,
            currency: 'USD',
            num_items: null,
            value: totalPrice.slice(1),
          });
        }
        else if (response?.data?.data?.slot?.order_status === "SCHEDULED" && payloadData?.slotId !== undefined) {
          trackPixelEvent('Reschedule', {
            content_ids: null,
            content_name: null,
            content_type: null,
            contents: null,
            currency: 'USD',
            num_items: null,
            value: totalPrice.slice(1),
          });
        }
        else {
          trackStandardPixelEvent('Schedule', {
            content_ids: null,
            content_name: null,
            content_type: null,
            contents: null,
            currency: 'USD',
            num_items: null,
            value: totalPrice.slice(1),
          });
        }
        dispatch(removeOrderMealItem(props?.storeId) as any);
        removeRestaurantMenuData(props?.storeId);
        localStorage.removeItem("RestaurantData");
        localStorage.removeItem("SomeoneElseOrderData");
        setAwaiting(false);
        // setUserDeliveryAddress({});
        // setOrderForSomeone({});
        if (payloadData?.slotId !== undefined) {
          try {
            const response = await removeSlot(payloadData?.slotId);
            if (response) {
              trackPixelEvent('Remove slot');
              props?.setHideBackModal && props.setHideBackModal((prev: boolean) => !prev);
              props.setShowFinalizeModal((prev: boolean) => !prev);
              props?.closeBackModal && props?.closeBackModal((prev: boolean) => !prev);
              props?.updatePage && props?.updatePage()
              if (orderForSomeone?.type === "for_other") {
                history.push("/manage-orders?for_other");
              }
              else {
                history.push("/manage-orders?for_me");
              }
              return;
            }
          } catch (e) {
            console.log(e);
          }
        }

        if (payloadData?.mealType === "Fav") {
          if (orderForSomeone?.type === "for_other") {
            history.push("/manage-orders?for_other");
          }
          else {
            history.push("/manage-orders?for_me");
          }
          return;
        }
        setFavMealData(response?.data?.data?.favMeal);
        setShowConfirmationModal(true);
        // props?.showBackModal(false);
      } else {
        console.log("Something went wrong");
      }
    } catch (e) {
      setResponseError(e?.response?.data?.data?.error);
      setAwaiting(false);
    }
  };

  const assignOrderToSlots = async () => {
    try {
      setAwaiting(true);
      const payloadData = props?.finalizeData?.orderData;
      payloadData["orderObject"] = props?.finalizeData?.data?.orderObject;
      payloadData["mealMeOrderObject"] = props?.finalizeData?.data?.mealMeOrderObject;

      await finalizeOrderForSlots(payloadData).then((res) => {
        // clearRestaurantList();
        trackPixelEvent('Finalize orders for slot');
        dispatch(removeOrderMealItem(props?.storeId) as any);
        removeRestaurantMenuData(props?.storeId);
        localStorage.removeItem("RestaurantData");
        props?.setHideBackModal && props.setHideBackModal((prev: boolean) => !prev);
        props.setShowFinalizeModal((prev: boolean) => !prev);
        if (router.routeInfo.pathname.includes("/calendar-slots")) {
          const data = {
            slotId: payloadData.slots[0].id,
            sponsoredBy: loggedUserData,
            paymentCardId: payloadData.paymentCardId,
            order_type: payloadData.orderType,
            mealMeOrderObject: props?.finalizeData?.data?.mealMeOrderObject
          };
          dispatch(orderFoodForSlot(data) as any);
          props?.closeBackModal((prev: boolean) => !prev);
        }
        if (orderRedirectUrl) {
          history.push(orderRedirectUrl);
          setOrderRedirectUrl("");
        }
        else {
          history.push(`/calendar-slots/${scheduleDetails?.id}`);
        }
        setAwaiting(false);
      });
    } catch (e) {
      console.log(e);
      setAwaiting(false);
    }
  };

  const handleCloseFinalizePopup = () => {
    props?.setShowFinalizeModal(false);
    props.setHideBackModal && props?.setHideBackModal(false);
  };

  const handleFinalizeModal = () => {
    setShowAddToFaveModal(true);
  };

  const handleRejectModal = () => {
    setShowConfirmationModal(false);
    props?.setShowFinalizeModal(false);
    if (orderForSomeone?.type === "for_other") {
      history.push("/manage-orders?for_other");
    }
    else {
      history.push("/manage-orders?for_me");
    }
  };

  const handleWarningModal = () => {
    const subTotal_Price = Number(
      priceFormater(
        props?.finalizeData?.data?.mealMeOrderObject?.final_quote?.quote?.subtotal
      ).slice(1)
    );
    const total_Price = Number(
      priceFormater(
        props?.finalizeData?.data?.mealMeOrderObject?.final_quote?.total_with_tip
      ).slice(1)
    );
    if (
      scheduleDetails?.attributes?.limit_per_meal > 0 &&
      subTotal_Price > scheduleDetails?.attributes?.limit_per_meal
    ) {
      const differencePrice = subTotal_Price - scheduleDetails?.attributes?.limit_per_meal;
      setDifferencePrice(differencePrice);
      setShowWarningModal(true);
    } else if (
      scheduleDetails?.attributes?.limit_per_meal > 0 &&
      scheduleDetails?.attributes?.includes_fees_n_tips &&
      total_Price > scheduleDetails?.attributes?.limit_per_meal
    ) {
      const differencePrice = total_Price - scheduleDetails?.attributes?.limit_per_meal;
      setDifferencePrice(differencePrice);
      setShowWarningModal(true);
    } else {
      assignOrderToSlots();
    }
  };

  return (
    <>
      {props?.showFinalizeModal ? (
        <ModalDiv onClick={closeModal}>
          <ContentDiv isDarkMode={isDarkMode}>
            <div className="grow overflow-auto">
              {/* <InitialDiv className="mx-2">
                <BackModalBtn>
                  <FontAwesomeIcon
                    icon={faXmark}
                    onClick={handleCloseFinalizePopup}
                  />
                </BackModalBtn>
                <TextHead1 className="text-green-600 text-center">Finalize Order</TextHead1> */}

              <InitialDiv className="mx-2">
                <BackModalBtn>
                  <FontAwesomeIcon icon={faXmark} onClick={handleCloseFinalizePopup} />
                </BackModalBtn>
                {/* <BackModalBtn>
                  <FontAwesomeIcon
                    icon={faChevronLeft}
                    onClick={() => props?.setShowNewAddressModal((prev: any) => !prev)}
                  />
                </BackModalBtn> */}
                <TextHead1
                  style={{ marginBottom: "15px", marginTop: "-25px", textAlign: "center" }}
                  className="text-green-600 text-center"
                >
                  Finalize Order
                </TextHead1>
              </InitialDiv>
              {((props?.finalizeData?.selectedSlots !== undefined &&
                props?.finalizeData?.selectedSlots?.length > 0) ||
                (props?.finalizeData?.orderData?.date &&
                  props?.finalizeData?.orderData?.date > todayDate)) && (
                  <InitialDiv className="text-left mt-2 text-[#7a7a7aba] flex">
                    <FontAwesomeIcon icon={faCircleInfo} className="mt-[7px] mr-1" />
                    <TextHead5 style={{ fontWeight: "500" }}>
                      {`Final costs may be different than estimated due to changes in menu${(props?.finalizeData?.orderData?.orderType === "delivery") ? " or delivery fees." : "."}`}
                    </TextHead5>
                  </InitialDiv>
                )}
              <InitialDiv className="mx-4 text-left">
                <TextHead1 style={{ textAlign: "left", lineHeight: "10px" }}>
                  Order Details
                </TextHead1>
                {props?.finalizeData?.selectedSlots !== undefined &&
                  props?.finalizeData?.selectedSlots?.length > 0 ? (
                  <TextHead4 style={{ textAlign: "left" }} className="mt-2">
                    {" "}
                    Selected slot(s) will be filled with the chosen food items.
                  </TextHead4>
                ) : (
                  <TextHead4 style={{ textAlign: "left" }} className="mt-2">
                    {slotDateTimeFormatter(
                      props?.finalizeData?.orderData?.date,
                      props?.finalizeData?.orderData?.time
                    )}
                  </TextHead4>
                )}
              </InitialDiv>

              {/* <Section>
                <TextHead5 style={{ fontWeight: "500" }}>Here are your order details including taxes, fees and tips.  Please read carefully before placing your order below.</TextHead5>
                <TextHead5 style={{ margin: "10px 0px", fontWeight: "500" }}>Please read details carefully to finalize your order or the order will be cancelled.</TextHead5>
              </Section> */}

              {props?.finalizeData?.data?.customCreatedQuote ? (
                <InitialDiv className="text-left my-4">
                  <Flex>
                    <FontAwesomeIcon icon={faCircleInfo} className="text-red-500 mt-2 mr-1" />{" "}
                    <TextHead5 style={{ fontWeight: "500" }}>
                      {" "}
                      {`Here are your order details, with estimates for taxes, fees, and tips. Actual order costs may vary based on updated ${(props?.finalizeData?.orderData?.orderType === "delivery") ? "delivery and" : ""}  food costs on the day of ${(props?.finalizeData?.orderData?.orderType === "delivery") ? "delivery" : "pickup"}. Please read carefully before placing your order below.`}
                    </TextHead5>
                  </Flex>
                  {/* <TextHead5 style={{ margin: "10px 0px", fontWeight: "500" }}>Please read details carefully to finalize your order or the order will be cancelled.</TextHead5> */}
                </InitialDiv>
              ) : (
                <InitialDiv className="mx-4 text-left my-4">
                  <TextHead5 style={{ fontWeight: "500" }}>
                    Here are your order details including taxes, fees and tips. Please read
                    carefully before placing your order below.
                  </TextHead5>
                  {/* <TextHead5 style={{ margin: "10px 0px", fontWeight: "500" }}>Please read details carefully to finalize your order or the order will be cancelled.</TextHead5> */}
                </InitialDiv>
              )}
              <TextHead3 className="text-left mx-4 underline">Items</TextHead3>
              {props?.finalizeData?.data?.mealMeOrderObject?.final_quote?.items?.map(
                (item: any, index: any) => {
                  return (
                    <InitialDiv className="mx-4" key={index}>
                      <FlexBetween className="!items-center mb-[-8px]">
                        <TextHead4 style={{ textAlign: "left", marginLeft: "1px" }}>
                          {switchSentenceCase("head text", properNameFormate(item?.name))}
                        </TextHead4>
                        <div className="flex items-baseline">
                          <HeartModalIcon>
                            <FontAwesomeIcon icon={faHeart} />
                          </HeartModalIcon>
                          <TextHead5 style={{ marginLeft: "10px", display: "block" }} className="!mt-2">
                            <div>
                              {priceFormatterUsd(
                                finalizeItemPriceCalculate(item?.customizations) + item?.base_price
                              )}
                            </div>{" "}
                            <div className="text-end">[x {item?.quantity}]</div>
                          </TextHead5>
                        </div>
                      </FlexBetween>
                      {
                        (item?.customizations?.length > 0)
                        &&
                        <>
                          <AccordionCheckoutCustomization
                            title={"Selected Customizations"}
                            titleSize={"16px"}
                          >
                            <div className="block">
                              <ShowProductCustomizations customizations={item?.customizations} />
                            </div>
                          </AccordionCheckoutCustomization>
                        </>
                      }
                    </InitialDiv>
                  );
                }
              )}

              <Section>
                <FlexBetween>
                  <TextHead5>Subtotal</TextHead5>
                  <TextHead5>
                    {priceFormatterUsd(
                      props?.finalizeData?.data?.mealMeOrderObject?.final_quote?.quote?.subtotal || 0
                    )}
                  </TextHead5>
                </FlexBetween>
                <FlexBetween>
                  <TextHead5>Taxes</TextHead5>
                  <TextHead5>
                    {priceFormatterUsd(
                      props?.finalizeData?.data?.mealMeOrderObject?.final_quote?.quote?.sales_tax_cents || 0
                    )}
                  </TextHead5>
                </FlexBetween>
                <FlexBetween>
                  <TextHead5>Service fee</TextHead5>
                  <TextHead5>
                    {priceFormatterUsd(
                      props?.finalizeData?.data?.mealMeOrderObject?.final_quote?.quote?.service_fee_cents || 0
                    )}
                  </TextHead5>
                </FlexBetween>
                <FlexBetween>
                  <TextHead5>Fees</TextHead5>
                  <TextHead5>
                    {priceFormatterUsd(
                      props?.finalizeData?.data?.mealMeOrderObject?.final_quote?.added_fees?.total_fee_cents || 0
                    )}
                  </TextHead5>
                </FlexBetween>
                <FlexBetween>
                  <TextHead5>Delivery fee</TextHead5>
                  <TextHead5>
                    {priceFormatterUsd(
                      props?.finalizeData?.data?.mealMeOrderObject?.final_quote?.quote?.delivery_fee_cents || 0
                    )}
                  </TextHead5>
                </FlexBetween>
                <FlexBetween>
                  <TextHead5>MealFave fee</TextHead5>
                  <TextHead5>
                    {priceFormatterUsd(
                      props?.finalizeData?.data?.mealMeOrderObject?.final_quote?.misc_fees[0]?.fee_amount || 0
                    )}
                  </TextHead5>
                </FlexBetween>
                <FlexBetween>
                  <TextHead5>Tip</TextHead5>
                  <TextHead5>
                    {priceFormatterUsd(
                      props?.finalizeData?.data?.mealMeOrderObject?.final_quote?.tip || 0
                    )}
                  </TextHead5>
                </FlexBetween>
                {props?.finalizeData?.data?.mealMeOrderObject?.final_quote?.promotion?.discount_total
                  && <FlexBetween>
                    <TextHead5>Discount</TextHead5>
                    <TextHead5>
                      - {priceFormatterUsd(
                        props?.finalizeData?.data?.mealMeOrderObject?.final_quote?.promotion?.discount_total || 0
                      )}
                    </TextHead5>
                  </FlexBetween>
                }
                <FlexBetween>
                  <TextHead5 className="!font-medium">Total</TextHead5>
                  <TextHead5 className="!font-medium">
                    {priceFormatterUsd(
                      (props?.finalizeData?.data?.mealMeOrderObject?.final_quote?.quote?.subtotal || 0) +
                      (props?.finalizeData?.data?.mealMeOrderObject?.final_quote?.quote?.sales_tax_cents || 0) +
                      (props?.finalizeData?.data?.mealMeOrderObject?.final_quote?.quote?.service_fee_cents || 0) +
                      (props?.finalizeData?.data?.mealMeOrderObject?.final_quote?.added_fees?.total_fee_cents || 0) +
                      (props?.finalizeData?.data?.mealMeOrderObject?.final_quote?.quote?.delivery_fee_cents || 0) +
                      (props?.finalizeData?.data?.mealMeOrderObject?.final_quote?.misc_fees[0]?.fee_amount || 0) +
                      (props?.finalizeData?.data?.mealMeOrderObject?.final_quote?.tip || 0) -
                      (props?.finalizeData?.data?.mealMeOrderObject?.final_quote?.promotion?.discount_total || 0)
                    )}
                  </TextHead5>
                </FlexBetween>
                {props?.finalizeData?.selectedSlots?.length > 0 && (
                  <FlexBetween>
                    <TextHead5 className="!font-medium">Total for Selected Slots</TextHead5>
                    <TextHead5 className="!font-medium">
                      <div>
                        {priceFormatterUsd(
                          (props?.finalizeData?.data?.mealMeOrderObject?.final_quote?.quote?.subtotal || 0) +
                          (props?.finalizeData?.data?.mealMeOrderObject?.final_quote?.quote?.sales_tax_cents || 0) +
                          (props?.finalizeData?.data?.mealMeOrderObject?.final_quote?.quote?.service_fee_cents || 0) +
                          (props?.finalizeData?.data?.mealMeOrderObject?.final_quote?.added_fees?.total_fee_cents || 0) +
                          (props?.finalizeData?.data?.mealMeOrderObject?.final_quote?.quote?.delivery_fee_cents || 0) +
                          (props?.finalizeData?.data?.mealMeOrderObject?.final_quote?.misc_fees[0]?.fee_amount || 0) +
                          (props?.finalizeData?.data?.mealMeOrderObject?.final_quote?.tip || 0)
                        )}{" "}
                        [x {props?.finalizeData?.selectedSlots?.length}]
                      </div>
                      <div style={{ marginLeft: "35px" }}>
                        {priceFormatterUsd(
                          (props?.finalizeData?.data?.mealMeOrderObject?.final_quote?.quote?.subtotal || 0) +
                          (props?.finalizeData?.data?.mealMeOrderObject?.final_quote?.quote?.sales_tax_cents || 0) +
                          (props?.finalizeData?.data?.mealMeOrderObject?.final_quote?.quote?.service_fee_cents || 0) +
                          (props?.finalizeData?.data?.mealMeOrderObject?.final_quote?.added_fees?.total_fee_cents || 0) +
                          (props?.finalizeData?.data?.mealMeOrderObject?.final_quote?.quote?.delivery_fee_cents || 0) +
                          (props?.finalizeData?.data?.mealMeOrderObject?.final_quote?.misc_fees[0]?.fee_amount || 0) +
                          (props?.finalizeData?.data?.mealMeOrderObject?.final_quote?.tip || 0) *
                          (props?.finalizeData?.selectedSlots?.length)
                        )}
                      </div>
                    </TextHead5>
                  </FlexBetween>
                )}
              </Section>
              <InitialDiv className="mx-4">
                {awaiting ? (
                  <Spinner />
                ) : (
                  <ButtonGreen
                    className="my-2"
                    onClick={() =>
                      props?.finalizeData?.selectedSlots !== undefined &&
                        props?.finalizeData?.selectedSlots?.length > 0
                        ? handleWarningModal()
                        : handleFinalizeOrder()
                    }>
                    {switchSentenceCase("primary button", "PLACE ORDER")}
                  </ButtonGreen>
                )}
                {responseError && (
                  <p className="text-red-600 text-center">
                    {responseError === "Order ID not found"
                      ? "Delivery is currently unavailable for this store."
                      : responseError}
                  </p>
                )}
              </InitialDiv>

              {/* <ButtonGray onClick={handleCloseFinalizePopup}>{switchSentenceCase("primary button", "CANCEL ORDER")}</ButtonGray> */}
            </div>
          </ContentDiv>

          {/* ********************* Modal Finalize Order ****************************** */}
          {showConfirmationModal && (
            <ModalConfirmationForCards
              firstText={"Do you want to make this order a Meal Fave?"}
              secondText=""
              btnOneText="NO"
              btnTwoText="YES"
              showModal={showConfirmationModal}
              setShowModal={setShowConfirmationModal}
              rejectAction={handleRejectModal}
              confirmAction={handleFinalizeModal}
              styleBtnSecondText={{ backgroundColor: "#22A45D" }}
              iconBackgroundColor={""}
            />
          )}

          {/* ********************* Modal Add to Faves -- Add Name and etc ****************************** */}
          {showAddToFaveModal && (
            <ModalAddOrderToFave
              showAddToFaveModal={showAddToFaveModal}
              setShowAddToFaveModal={setShowAddToFaveModal}
              mealItemData={favMealData}
              setHideModal={setShowConfirmationModal}
              firstModal={props?.setShowFinalizeModal}
              destinationRoute={"/manage-orders"}
            />
          )}
          {showWarningModal && (
            <ModalSuccessForCards
              firstText={`Your total price exceeds your spending limit for per meal by $${differencePrice.toFixed(2)}`}
              secondText=""
              btnOneText="Proceeed"
              btnTwoText="Cancel"
              btnOneStyle={{ marginTop: "15px" }}
              btnTwoStyle={{ backgroundColor: "#b3b3b3", marginTop: "15px", color: "#ffffff" }}
              btnOneGoto={() => assignOrderToSlots()}
              btnTwoGoto={() => setShowWarningModal((prev) => !prev)}
              showModal={showWarningModal}
              setShowModal={setShowWarningModal}
              buttonOneLoader={awaiting}
              buttonTwoDisabled={awaiting}
            />
          )}
        </ModalDiv>
      ) : null}
    </>
  );
}
