import defaultRestaurant from "../../assets/restaurent-Meal-3.jpg"

export default function RestaurantCardImage({ photo, isClosed }) {
  return (
    <img
      alt="Restaurant Logo"
      src={photo?.length ? photo[0] : defaultRestaurant}
      className="rounded-lg w-32 h-32 object-cover"
      style={{
        marginBottom: isClosed ? "" : "-30px",
        maxWidth: isClosed ? "15rem" : "w-32",
      }}
    />
  );
}