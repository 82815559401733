// darkMode.js

import { useState, useEffect } from 'react';

const useDarkMode = () => {
    const [isDarkMode, setIsDarkMode] = useState(window.matchMedia("(prefers-color-scheme: dark)").matches);

    useEffect(() => {
        const darkModeQuery = window.matchMedia("(prefers-color-scheme: dark)");

        const updateDarkMode = () => {
            setIsDarkMode(darkModeQuery.matches);
        };

        // Use addEventListener instead of addListener
        darkModeQuery.addEventListener('change', updateDarkMode);

        return () => {
            // Use removeEventListener instead of removeListener
            darkModeQuery.removeEventListener('change', updateDarkMode);
        };
    }, []); // Empty dependency array to run the effect only once

    return isDarkMode;
};

export default useDarkMode;
