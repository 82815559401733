import { useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import {
    ButtonGreen,
    TextHead1,
    InputText,
    InputLabel,
    Astk,
    moveTopBottom,
} from "../../styled/styled";
import styled from "styled-components";
import { switchSentenceCase, displayName, trackPixelEvent } from "../../../utils/utilities";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/pro-solid-svg-icons";
import Section from "../section/Section";
import { updateMealCategoryFavItem } from "../../../services/favourite";
import { useIonRouter } from "@ionic/react";
import { getUserInfo } from "../../../services/user";
import { makeOrderToFav } from "../../../pages/faves/redux/favouriteActionCreator"
import useDarkMode from "../../hooks/darkmode/DarkMode";
// import { makeOrderToFav } from "../../../pages/faves/redux/favouriteActionCreator";

const ModalDiv = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1000;
  overflow-y: auto;
`;

const ContentDiv = styled.div<{ isDarkMode: boolean }>`
  position: absolute;
  background-color:${props => (props?.isDarkMode ? '#2e2e2e' : '#fff')};
  margin-top: 80px;
  margin-bottom: 50px;
  padding: 10px;
  z-index: 1000;
  width: 600px;
  max-width: 90%;
  border-radius: 20px;
  text-align: center;
  animation: ${moveTopBottom} .4s ease-in-out;
`;


const BackModalBtn = styled.div`
  position: absolute;
  top: 12px;
  left: 12px;
  font-size: 17px;
`;


export default function ModalAddOrderToFave(props: any) {

    const mealItemData = props?.mealItemData;
    const history = useHistory();
    const router = useIonRouter();
    const modalRef = useRef();
    const dispatch = useDispatch();
    const user = getUserInfo();
    const isDarkMode = useDarkMode();

    const { mealCategory } = useSelector((state: any) => state.getAllFavData);
    const { userDeliveryAddress } = useSelector((state: any) => state.userAddresses);

    const defaultCategory = mealCategory?.length > 0 ? (mealCategory?.filter((item: any) => item?.attributes?.defaultMeal === true)[0]?.id) : ""

    const [nameError, setNameError] = useState(false);
    const [values, setValues] = useState({
        name: mealItemData?.name,
    })

    const showNav = (router.routeInfo.pathname === '/faves') ? true : false;

    const handleChange = (e: any) => {
        setNameError(false)
        const { name, value } = e.target;
        setValues((prevValues) => {
            return {
                ...prevValues,
                [name]: value,
            };
        });
    }

    // {
    //     showNav ?
    //         dispatch(makeOrderToFav(updatedData, mealItemData?.id) as any)
    //             .then((res) => {
    //                 props?.setShowAddToFaveModal((prev: any) => !prev);
    //                 props?.firstModal((prev: any) => !prev);
    //             })
    //         :
    //         updateMealCategoryFavItem(updatedData, mealItemData?.id)
    //             .then((res) => {
    //                 props?.setShowAddToFaveModal((prev: any) => !prev);
    //                 props?.firstModal((prev: any) => !prev);
    //                 history.push({
    //                     pathname: '/faves',
    //                 })
    //             })
    // }

    const handleUpdate = async () => {
        try {

            const { name } = values;
            if (name.trim() === "") {
                setNameError(true);
                return false;
            }
            else {
                setNameError(false);
            }

            if (name.trim() === "" || nameError || !mealItemData?.id) {
                return false;
            }
            const updatedData = {
                data: {
                    name: name,
                    type: "Fav",
                    meal_category: defaultCategory,
                    user: user?.id
                }
            }
            updateMealCategoryFavItem(updatedData, mealItemData?.id)
                .then((res) => {
                    if (props?.destinationRoute === "/faves") {
                        dispatch(makeOrderToFav(mealItemData?.id, userDeliveryAddress?.lat, userDeliveryAddress?.lang) as any)
                    }
                    trackPixelEvent('Update meal category fave item');
                    history.push(props?.destinationRoute)
                    props?.setShowAddToFaveModal((prev: any) => !prev);
                    (props?.firstModal) && props?.firstModal((prev: any) => !prev);
                })
        }
        catch (e) {
            console.log(e)
        }
    }


    const closeModal = (e: any) => {
        if (modalRef.current === e.target) {
            props?.setShowAddToFaveModal(false);
            props?.setHideModal(false);
        }
    };

    return (
        <>
            {props?.showAddToFaveModal ? (
                <ModalDiv onClick={closeModal}>
                    <ContentDiv isDarkMode={isDarkMode}>
                        <BackModalBtn>
                            <FontAwesomeIcon
                                icon={faChevronLeft}
                                className="cursor-pointer"
                                onClick={() => {
                                    props?.setShowAddToFaveModal((prev: any) => !prev);
                                    props?.setHideModal(false);
                                }}
                            />

                        </BackModalBtn>

                        <TextHead1>Add To Fave</TextHead1>
                        {/* <SubText1 className="mt-1.5">
                            Meal Edit
                        </SubText1> */}

                        <Section>
                            <InputLabel className="mt-5">Fave Name <Astk> *</Astk></InputLabel>
                            <InputText
                                style={{
                                    marginBottom: "20px",
                                    border: nameError ? "1px solid red" : "",
                                }}
                                placeholder={`${displayName()} Fave @ ${(mealItemData?.name)?.split('-')[0]}`}
                                name="name"
                                value={values?.name}
                                onChange={(e) => handleChange(e)}
                            >
                            </InputText>

                        </Section>

                        <Section>
                            <ButtonGreen
                                style={{ margin: "5px 0px" }}
                                onClick={() => handleUpdate()}
                            >
                                {switchSentenceCase("primary button", "SAVE")}
                            </ButtonGreen>

                        </Section>

                    </ContentDiv>
                </ModalDiv>
            )
                :
                null}
        </>
    );
}
