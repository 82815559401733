import styled from "styled-components";

const LabelText = styled.div`
  font-size: 17px;
  margin-left: 5px;
  width: -webkit-fill-available;
`;

const Item = styled.div`
  display: flex;
  align-items: center;
  height: 48px;
  position: relative;
  border-bottom: 1px solid #8080801f;
  margin-bottom: 10px;
  margin-top: 10px;
`;

const RadioButtonLabel = styled.label`
  position: absolute;
  top: 25%;
  left: 4px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: white;
  border: 1px solid #bebebe;
`;

const RadioButton = styled.input`
  opacity: 0;
  z-index: 1;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin-right: 10px;
  &:hover ~ ${RadioButtonLabel} {
    background: #bebebe;
    &::after {
      content: "";
      display: block;
      border-radius: 50%;
      width: 12px;
      height: 12px;
      margin: 6px;
      background: #eeeeee;
    }
  }
  ${(props) =>
    props.checked &&
    ` 
        &:checked + ${RadioButtonLabel} {
          background: #fff;
          border: 1px solid #16a34a;
          &::after {
            content: "";
            display: block;
            border-radius: 50%;
            width: 19px;
            height: 18px;
            margin: 2px;
            box-shadow: 1px 3px 3px 1px rgb(0 0 0 / 10%);
            background: #16a34a;
          }
        }
      `}
`;

const inputStyle = {
  width: "20px",
  accentColor: "#16a34a",
  transform: "scale(1.8)",
  margin: "0px 10px",
};

const styleDiv = {
  display: "flex",
  alignItems: "center",
  justifyContent: "start",
  width: "-webkit-fill-available",
};

export default function RadioButtonText(props: any) {
  return (
    <>
      <Item style={props?.border}>
        {/* <RadioButton
          type="radio"
          name={props?.name}
          checked={props?.checked === props?.value}
          onChange={() => {props?.setter(props?.value)
          props.handleChange &&  props.handleChange({custmName:props.coustomises.name,custm_id:props.coustomises.customization_id,option_name:props.option.name,option_id:props.option.option_id,option_price:props.option.price})
          }}
        />
        <RadioButtonLabel />
        <LabelText style={props.labelStyle}>{props?.label}</LabelText> */}

        <div style={styleDiv}>
          <input
            style={inputStyle}
            type="radio"
            id={props?.name + props?.index}
            name={props?.name}
            value={props?.value}
            checked={props?.checked == props?.value}
            onChange={props?.onSelect}
          />
          <label htmlFor={props?.name + props?.index}>
            <LabelText style={props.labelStyle}>{props?.label}</LabelText>
          </label>
        </div>
      </Item>
    </>
  );
}
