import axios from 'axios';
import store from '../redux/store';
import { setUserSearchLocation } from '../components/widgets/address/redux/addressActionCreator';
import { clearRestaurantList, setNearByRestaurants, setNearByScheduleRestaurants } from '../pages/search/redux/searchActionCreator';
import { setUserDeliveryAddress } from '../pages/profile/redux/locationActionCreator';
import { setRecentOrderList, setUserFaveMealList } from '../pages/faves/redux/favouriteActionCreator';

export const apiRequestService = async (config: any) => { //change the name like -> apiRequestService
    try {
        return await axios(config)
    }
    catch (e) {
        console.log("API CALLER ERROR -> ", e)

        if (e?.response?.status === 401) {
            store.dispatch({
                type: "SHOW_ALERT",
                payload: { show: true }
            })
            localStorage.removeItem("MealTrendUser");
            localStorage.removeItem("MealFaveUserDeliveryAddress");
            localStorage.removeItem("CalendarData");
            localStorage.removeItem("RestaurantData");
            localStorage.removeItem("SomeoneElseOrderData");
            setUserSearchLocation({});
            clearRestaurantList();
            setNearByRestaurants([]);
            setUserDeliveryAddress({});
            setUserFaveMealList([]);
            setRecentOrderList([]);
            setNearByScheduleRestaurants([]);
        }
        throw e;
    }
}