import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { faAngleLeft } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useIonAlert } from "@ionic/react";
import { Select } from "antd";
import React, { useEffect, useRef, useState } from "react";
import Joyride, { CallBackProps } from 'react-joyride';
import PhoneInput from "react-phone-number-input";
import { useHistory, useLocation } from "react-router";
import styled from 'styled-components';
import no_user_pic from "../../components/assets/profile/no-profile-photo.png";
import useDarkMode from "../../components/hooks/darkmode/DarkMode";
import { Astk, ButtonGreen, Flex, InitialDiv, InputLabel, SubText1, TextBox1, TextHead1, TextHead3, TextHead4, TextHead5 } from "../../components/styled/styled";
import AddressesCart from "../../components/widgets/address/AdressesCart";
import Divider from "../../components/widgets/divider/Divider";
import TopHead from "../../components/widgets/head/TopHead";
import Section from "../../components/widgets/section/Section";
import TabsForRecipientDetails from "../../components/widgets/toggleable-tabs/TabsForRecipientDetails";
import { findFriends, inviteFriend } from "../../services/friends";
import { addNewRecipient, getCalendarData, setCalendarData } from "../../services/schedule";
import { getUserData, getUserInfo, setUserData, updateUser } from "../../services/user";
import { objectType } from "../../type";
import { countryCodeFilter, handleNetworkError, handleRequestError, handleResponse, removeMask, switchSentenceCase, trackPixelEvent, validateEmail, validatePhone } from "../../utils/utilities";
import { calendarRecipientData } from "../schedules/redux/createScheduleReduxActions";
import InitialModalCenter from "../../components/widgets/modals/InitialModalCenter";
import { setOrderForSomeone } from "../orders/redux/upcomingOrderActionCreator";
import { setSomeOneElseOrderData } from "../../services/orderFood";
import { giftNotifyForCreateFave } from "../../services/notification";
import { getFavesCount } from "../../services/favourite";



export const MaterialTextfield = styled.div`
  position: relative;
`;

export const Label = styled.label`
  position: absolute;
  font-size: 1rem;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  background-color: white;
  color: gray;
  padding: 0 0.3rem;
  margin: 0 0.5rem;
  transition: 0.1s ease-out;
  transform-origin: left top;
  pointer-events: none;
`;



export const Input = styled(TextBox1)`
  /* font-size: 1rem;
  outline: none;
  border-radius: 5px;
  padding: 1rem 0.7rem;
  color: gray;
  transition: 0.1s ease-out; */
  border: 1px solid #d3d1d1;
  padding: 10px 10px 10px 10px;
  &:focus {
    border-color: #22A45D;
  }

  &:focus + ${Label} {
    color: #22A45D;
    top: 0;
    font-size: 14px;
    transform: translateY(-50%) scale(0.9) !important;
  }

  &:not(:placeholder-shown) + ${Label} {
    top: 0;
    transform: translateY(-50%) scale(0.9) !important;
  }

  &::placeholder {
    opacity: 0;
  }
`;



export const PhoneInputWrapper = styled.div<{ value: string }>`
  position: relative;
  border: 1px solid transparent; 
  border-radius: 8px;

  &:focus-within {
    border: 1px solid #19c366 !important;
  }

  .PhoneInputInput {
    padding: 10px 10px 10px 10px;
    font-size: 1rem;
    outline: none;
    border-radius: 8px;
    color: gray;
    transition: 0.1s ease-out;
    border: none; /* Remove inner input border */
  }

  &:focus-within label {
    color: #22A45D;
    top: 0;
    left: 0 !important;
    font-size: 14px;
    transform: translateY(-50%) scale(0.9) !important;
    display: block;
  }

  ${({ value }) => value && `
    label {
      top: 0;
      left: 0 !important;
      font-size: 14px;
      transform: translateY(-50%) scale(0.9) !important;
      display: block;
    }
  `}
`;

export default function GiftForm() {
  const history = useHistory();
  const location = useLocation<any>();
  const isDarkMode = useDarkMode();
  const user = getUserInfo();
  const API_URL = process.env.REACT_APP_API_URL;
  const [presentAlert] = useIonAlert();
  const showAlert = (header: string, message: string, btnMessage: string) => {
    presentAlert({
      header: header,
      message: message,
      buttons: [btnMessage],
    });
  };



  const [respondedData, setRespondedData] = useState<any>({});
  const [recipientEmail, setRecipientEmail] = useState("");
  const [disabledEmail, setDisabledEmail] = useState(false);
  const [friendsData, setFriendsData] = useState([]);
  const [searchedFriendText, setSearchedFriendText] = useState("");
  const [awaiting, setAwaiting] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [userDatavalues, setUserDatavalues] = useState({
    username: "",
    useremail: "",
    userphonenumber: "",
  });


  const [values, setValues] = useState({
    recipientName: (location?.state?.recipientName !== undefined ? location?.state?.recipientName : ""),
    recipientContact: (location?.state?.recipientContact !== undefined ? location?.state?.recipientContact : ""),
    recipientId: (location?.state?.recipientId !== undefined ? location?.state?.recipientId : ""),
    addressId: (location?.state?.addressId !== undefined ? location?.state?.addressId : ""),
  });


  // ********************Error Handler****************************
  const [recipientNameError, setRecipientNameError] = useState(false);
  const [addressIdError, setAddressIdError] = useState(false);
  const [recipientContactError, setRecipientContactError] = useState(false);
  const [userNameError, setUserNameError] = useState(false);
  const [userEmailError, setUserEmailError] = useState(false);
  const [userPhoneError, setUserPhoneError] = useState(false);
  const [recipientEmailError, setRecipientEmailError] = useState(false);
  const [recipientEmailErrorMsg, setRecipientEmailErrorMsg] = useState("");
  const [userResponseError, setUserResponseError] = useState(false);


  const userDetails = [
    {
      name: "Details",
      value: "details",
      disabled: false,
      active: respondedData?.id ? true : false
    },
    {
      name: "Address",
      value: "address",
      disabled: respondedData?.id ? false : true,
      active: values?.addressId ? true : false
    }
  ];


  let options = [];
  options = friendsData?.map((item: any, index: any) => (
    {
      id: item?.id,
      name: item?.name,
      phone_no: item?.phone_no,
      email: item?.email,
      value: (item?.name !== null ? item?.name + index : (item?.email !== null ? item?.email : item?.phone_no)),
      label: (
        <React.Fragment key={index}>
          <div className="flex">
            <img
              alt={item?.name + index}
              src={item?.profile_pic !== null ? API_URL + item?.profile_pic?.url : no_user_pic}
              className="mr-6 mt-1 rounded-[20px] border border-solid border-gray-500 w-8 h-8" />
            <div>
              <h3>{item?.name !== null ? item?.name : "-----"}</h3>
              <SubText1>{item?.city !== null ? item?.city : "-----"}</SubText1>
            </div>
          </div>
        </React.Fragment>
      ),
    }
  ))

  const [activeBar, setActiveBar] = useState(userDetails[0].value);

  useEffect(() => {
    if (location?.state?.recipientEmail) {
      setRecipientEmail(location.state.recipientEmail);
      setDisabledEmail(true);
    }
  }, [location]);



  // ***************************** Functions **************************************************
  const GotoHandle = () => {
    history.push({
      pathname: '/home',
    })
  };


  const handleAddressId = (id: any) => {
    setAddressIdError(false);
    setValues((prevValues) => {
      return {
        ...prevValues,
        addressId: id,
      };
    });
  };


  const handleGetUserFriends = async (e: string) => { // Finding friend lists from api On search.... || calling api when length > 3     
    setSearchedFriendText(e);
    setValues((prevValues) => {
      return {
        ...prevValues,
        recipientName: "",
        recipientContact: "",
        recipientId: ""
      };
    });
    if (e?.length >= 3) {
      setAwaiting(true);
      setFriendsData([]);
      try {
        const { data } = await findFriends(e);
        trackPixelEvent('User find');
        setFriendsData(data?.data);
        setAwaiting(false);
      } catch (error) {
        setAwaiting(false);
        if (error.response) {
          handleResponse(error.response); // Server responded with a non-2xx status code
        } else if (error.request) {
          handleRequestError(error.request);  // The request was made but no response was received
        } else {
          handleNetworkError(error); // Network error or something else happened
        }
      }
    } else {
      setFriendsData([]);
    }
  };


  const handleUserPhoneChange = (value: string) => {
    setUserResponseError(false);
    if (value === "" || value === undefined) {
      setUserPhoneError(true);
    } else {
      setUserPhoneError(false);
    }
    setUserDatavalues((prevValues) => {
      return {
        ...prevValues,
        userphonenumber: value,
      };
    });
  };

  const handleUserChange = (e: any) => {
    const { name, value } = e.target;
    setUserDatavalues((prevValues) => {
      return {
        ...prevValues,
        [name]: value,
      };
    });
    setUserResponseError(false);
    if (name === "username") {
      if (value.trim() === "" || value.length < 3) {
        setUserNameError(true);
      } else {
        setUserNameError(false);
      }
    }

    if (name === "useremail") {
      const validEmail = validateEmail(value);
      if (!validEmail) {
        setUserEmailError(true);
      } else {
        setUserEmailError(false);
      }
    }
  };

  const handleEmailChange = (e: any) => {
    setRecipientEmailErrorMsg("");
    setRecipientEmailError(false);
    setRecipientEmail(e?.target?.value);

    const validEmail = validateEmail(e?.target?.value);
    if (String(e?.target?.value).trim()?.length === 0 || !validEmail) {
      setRecipientEmailError(true);
    } else {
      setRecipientEmailError(false);
    }
  };


  const handleChangePhoneNumber = (value: string) => {
    if (value === "" || value === undefined) {
      setRecipientContactError(true);
    } else {
      setRecipientContactError(false);
    }
    setValues((prevValues) => {
      return {
        ...prevValues,
        recipientContact: value,
      };
    });
  };


  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setValues((prevValues) => {
      return {
        ...prevValues,
        [name]: value,
      };
    });

    if (name === "recipientName") {
      if (value.trim() === "" || value.length < 3) {
        setRecipientNameError(true);
      } else {
        setRecipientNameError(false);
      }
    }
  };


  const handleUpdateData = async () => {
    setAwaiting(true);
    setRecipientEmailErrorMsg("");
    if (recipientEmail !== "" && !recipientEmailError) {
      const validEmail = validateEmail(recipientEmail);
      if (!validEmail || recipientEmailError) {
        return false;
      }
      try {
        const data = {
          email: recipientEmail.toLowerCase(),
        };
        const res = await updateUser(data, respondedData?.id);
        if (res?.status === 200) {
          trackPixelEvent("Update user");
          setDisabledEmail(true);
        }
      } catch (error) {
        setRecipientEmailErrorMsg(error?.response?.data?.error?.message);
        setRecipientEmailError(true);
        setAwaiting(false);
      }
    }
  };

  const onSelect = (val: any, option: objectType) => {
    setRespondedData({});
    setRecipientContactError(false);
    setRecipientNameError(false);
    setValues((prevValues) => {
      return {
        ...prevValues,
        recipientName: option?.name,
        recipientContact: option?.phone_no,
        recipientId: option?.id
      };
    });
    if (option?.email) {
      setRecipientEmail(option?.email);
    }
    setFriendsData([]);
  };


  const handleGetData = async () => {
    const { recipientName, recipientContact } = values;
    if (
      removeMask(recipientContact) !== "" &&
      !recipientContactError &&
      recipientName !== "" &&
      !recipientNameError
    ) {
      setRecipientEmailError(false);
      const validPhoneNo = validatePhone(removeMask(recipientContact));
      if (!validPhoneNo || recipientContactError || recipientNameError) {
        return false;
      }
      setAwaiting(true);
      try {
        const payloadData = {
          recipientName: recipientName,
          recipient: removeMask(countryCodeFilter(recipientContact)),
          recipientEmail: recipientEmail
        };

        const response = await addNewRecipient(payloadData);
        if (response) {
          trackPixelEvent("Add new recipient");
          setRespondedData(response?.data?.data);
          if (response?.data?.data?.email !== null && response?.data?.data?.email !== "") {
            setRecipientEmail(response?.data?.data?.email);
          }
          setValues((prevValues) => {
            return {
              ...prevValues,
              recipientId: response?.data?.data?.id,
            };
          });
          setAddressIdError(false);
          setAwaiting(false);
          return true;
        } else {
          setAddressIdError(true);
        }
      } catch (e) {
        setAwaiting(false);
        setRespondedData({});
        setRecipientEmail("")
        setValues((prevValues) => {
          return {
            ...prevValues,
            recipientId: "",
            addressId: ""
          };
        });
        return showAlert(e?.response?.data?.error?.name ? e?.response?.data?.error?.name : e?.response?.status, e?.response?.data?.error?.message ? e?.response?.data?.error?.message : e?.response?.statusText, "OK");
      }
    }
  };


  const handleSubmit = async () => {
    const {
      recipientName,
      recipientContact,
      addressId,
      recipientId,
    } = values;
    const { username, useremail, userphonenumber } = userDatavalues;


    // *********************** validation Email and phone *************************************
    const validEmail = validateEmail(recipientEmail);
    const validPhoneNo = validatePhone(removeMask(recipientContact));
    const userValidEmail = validateEmail(useremail);
    const userValidPhoneNo = validatePhone(removeMask(userphonenumber));
    let recipientContactErr = false;


    if (activeBar === "details") {
      if (!validPhoneNo) {
        setRecipientContactError(true);
        recipientContactErr = true;
      }
      else {
        setRecipientContactError(false);
        recipientContactErr = false;
      }

      // *********************** validation recipient name*************************************
      if (recipientName === "") {
        setRecipientNameError(true);
        return false;
      } else {
        setRecipientNameError(false);
      }

      // *********************** validation user name, email, phone*************************************
      if (user?.name === null && (username === "" || username?.length < 3)) {
        setUserNameError(true);
        return false;
      } else {
        setUserNameError(false);
      }

      if (user?.email === null && (!userValidEmail || userResponseError)) {
        setUserEmailError(true);
        return false;
      } else {
        setUserEmailError(false);
      }

      if (user?.phone_no === null && (!userValidPhoneNo || userResponseError)) {
        setUserPhoneError(true);
        return false;
      } else {
        setUserPhoneError(false);
      }

      if (recipientContactErr || recipientNameError || recipientContactError || userNameError || userEmailError || userPhoneError) return false;

      if ((user?.email === null || user?.name === null || user?.phone_no === null)) {
        // *********************** Calling API to update user name, email, phone, if not ************************************
        try {
          const data = {
            email: user?.email === null ? useremail.toLowerCase() : user?.email,
            name: user?.name === null ? username : user?.name,
            phone_no: user?.phone_no === null ? removeMask(countryCodeFilter(userphonenumber)) : user?.phone_no,
          };
          const localData = await getUserData();
          await updateUser(data, user?.id).then(async (response: any) => {
            let data = response?.data;
            if (response.status === 200) {
              trackPixelEvent("Update user");
              const newUpdatedUserInfo = {
                ...localData,
                user: {
                  ...localData.user,
                  name: data?.name,
                  email: data?.email,
                  phone_no: data?.phone_no,
                },
              };
              await setUserData(newUpdatedUserInfo);
            }
          });
        } catch (error) {
          setUserResponseError(true);
          setUserPhoneError(true);
          setUserEmailError(true);
          return showAlert("Error", error?.response?.data?.error?.message, "OK");
        }
      }
      let response = await handleGetData();
      if (response === true) {
        setActiveBar("address");
      }
    }
    else {
      if (respondedData?.email === null) {
        if (!validEmail || recipientEmailError) {
          setRecipientEmailError(true);
          return false;
        } else if (validEmail) {
          setRecipientEmailError(false);
        }
      }
      if (recipientEmailErrorMsg || recipientEmailError || addressIdError) return false;
      if (values?.recipientId) {
        const payloadData = {
          data: {
            "from_id": user?.id,
            "to_id": recipientId,
            "status": "PENDING",
            "message": 'Hey! Add me as a friend so I can see your faves and buy you food on special occasions!'
          }
        }
        await inviteFriend(payloadData).then((response: any) => {
          if (response?.statusText === "OK" || response?.status === 200) {
            trackPixelEvent('Invite Friend');
          }
        });
      }
      // ************************validation Address ID**************************************
      if (addressId === "" || addressId === undefined) {
        setAddressIdError(true);
        return false;
      } else {
        setAddressIdError(false);
      }

      const favs_count = await getFavesCount({
        id: recipientId,
        type: "Fav",
        skip: 0,
        limit: 100
      });


      // **************Data Object for state********************
      let data = {
        recipientName: recipientName,
        recipientContact: removeMask(countryCodeFilter(recipientContact)),
        recipientEmail: recipientEmail,
        recipientId: recipientId,
        addressId: addressId,
        type: "for_other",
      };

      // ********* Added for if gift going to calendar for other section *******************
      calendarRecipientData(data);

      // *********** Added for if gift going to one time order section *******************
      setOrderForSomeone(data);
      await setSomeOneElseOrderData(data);
      // ****************************************

      let calendarData = await getCalendarData();
      const newUpdatedCalendarData = {
        ...calendarData,
        recipientData: data
      };
      await setCalendarData(newUpdatedCalendarData)
      if (favs_count?.data?.meta?.pagination?.total > 0) {
        history.push({ pathname: '/gift-select' })
      } else {
        setShowModal((prev) => !prev);
      }
      // return history.push({ pathname: '/gift-select' })
    }
  };

  const [{ run, steps, stepIndex }, setState] = useState<any>({
    run: false,
    steps: [],
  });

  const btn = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    setState({
      run: true,
      steps: [
        {
          content: (
            <div>
              <TextHead3 className="text-left text-[#D97706] font-semibold">Step 1:</TextHead3>
              <TextHead5 className="text-left">Enter Recipient's Details</TextHead5>
            </div>
          ),
          disableBeacon: true,
          disableOverlayClose: true,
          hideCloseButton: true,
          hideFooter: false,
          placement: 'bottom',
          spotlightClicks: true,
          target: btn.current!,
          data: {
            next: '/gift-select',
          },
        },

        {
          target: '#routeA',
          content: (
            <div>
              <TextHead3 className="text-left text-[#D97706] font-semibold">Step 2:</TextHead3>
              <TextHead5 className="text-left">Enter Recipient's Details</TextHead5>
            </div>
          ),
          data: {
            previous: '/multi-route',
            next: '/multi-route/b',
          },
        },
      ],
    });
  }, []);


  const handleInvite = async () => {
    const payloadData = {
      data: {
        from_id: user?.id,
        to_id: values?.recipientId,
        type: "invite",
      }
    };
    try {
      const response = await giftNotifyForCreateFave(payloadData);
      if (response?.statusText === "OK" || response?.status === 200) {
        presentAlert({
          header: 'Recipient Invited !',
          subHeader: `Fave meal creation Invitation Sent to recipient.`,
          buttons: ['OK'],
        })
        window.history.back();
      }
    } catch (error) {
      console.log(error);
    }
  }


  // *************************** Joyride app tour handleCallback commented for now as no required !! **********************************

  // const handleCallback = (data: CallBackProps) => {
  //   const {
  //     action,
  //     index,
  //     step: {
  //       data: { next, previous },
  //     },
  //     type,
  //   } = data;
  //   const isPreviousAction = action === 'prev';

  //   if (type === 'step:after') {
  //     if (index < 2) {
  //       setState({ run: false });
  //       history.push(isPreviousAction && previous ? previous : next)
  //     }

  //     if (index === 2) {
  //       if (isPreviousAction && previous) {
  //         setState({ run: false });
  //         history.push(previous)
  //       } else {
  //         setState({ run: false, stepIndex: 0, tourActive: false });
  //       }
  //     }
  //   }
  // };



  return (
    <>
      <div className="screen">
        <TopHead
          title="Gifts"
          titleStyle={{ color: "#22A45D", fontWeight: "600" }}
          leftTextIcon={faAngleLeft}
          leftIconStyle={{ fontSize: "26px", color: "#22A45D" }}
          goToHandle={GotoHandle}
        />
        <Divider />


        {/* ************** Commented app tour as no required !! *********************** */}

        {/* <Joyride
          run={run}
          steps={steps}
          continuous
          stepIndex={stepIndex}
          callback={handleCallback}
          styles={{
            tooltipContainer: {
              borderRadius: '120px',
            },
            options: {
              zIndex: 10000,
              primaryColor: "#22A45D",
            },
          }}
        /> */}


        <div className="grow overflow-auto">
          {/* ********************************** Show Recipient's Modal********************************************** */}

          <>
            <TextHead1 className="!text-[18px] !font-bold text-center text-[#D97706]">Enter Recipient's Details</TextHead1>
            <Section>
              <InitialDiv className="m-4">
                <TabsForRecipientDetails
                  types={userDetails}
                  setActive={setActiveBar}
                  selected={activeBar}
                />

                {(activeBar === "details") &&
                  <>
                    {/* ********************* Search Friends Section ******************************* */}
                    <section ref={btn}>
                      <InitialDiv>
                        <TextHead3>
                          Search for Friend
                        </TextHead3>
                        <FontAwesomeIcon icon={faSearch} className="relative top-[32px] left-[10px] z-10" />
                        <Select
                          allowClear
                          showSearch
                          size="large"
                          bordered={false}
                          filterOption={false}
                          options={options}
                          loading={awaiting}
                          placeholder={"Search by name, phone, or email"}
                          onSearch={(e) => handleGetUserFriends(e)}
                          onSelect={(val, option) => onSelect(val, option)}
                          style={{ width: "100%", paddingLeft: "22px", border: "1px solid #7c7c7c69", borderRadius: "10px", marginBottom: "20px", background: `${isDarkMode ? "#3b3b3bb3" : "#FBFBFB"}` }}
                          notFoundContent={
                            !awaiting && searchedFriendText?.length > 2
                            &&
                            <SubText1 className="m-2 !mb-[2px]">No Result Found</SubText1>
                          }
                        />
                      </InitialDiv>
                    </section>

                    <SubText1 className="!font-bold text-center !text-green-600">OR</SubText1>

                    <TextHead3>
                      Add a Friend
                    </TextHead3>
                    <InitialDiv className="text-left">
                      <TextHead4 id="recipientname" className="mb-[-15px] mt-4">
                        Recipient Name<Astk> *</Astk>
                      </TextHead4>
                      <TextBox1
                        type="text"
                        placeholder="Enter Recipient Name"
                        name="recipientName"
                        style={{ border: recipientNameError ? "1px solid red" : "" }}
                        value={values?.recipientName}
                        onChange={handleChange}
                      />
                    </InitialDiv>

                    <InitialDiv className="text-left">
                      <TextHead4 id="recipientcontact" className="mb-[-15px] mt-4">
                        Recipient Phone Number<Astk> *</Astk>
                      </TextHead4>
                      <div className="relative mb-4 flex mt-6">
                        <PhoneInput
                          className={isDarkMode ? "country-selector" : ""}
                          international
                          countryCallingCodeEditable={false}
                          defaultCountry="US"
                          limitMaxLength={true}
                          placeholder="Enter Recipient Phone Number"
                          name="recipientContact"
                          value={values?.recipientContact}
                          onChange={handleChangePhoneNumber}
                          style={{
                            borderRadius: "8px",
                            width: "100%",
                            border: recipientContactError ? "1px solid red" : "",
                          }}
                        />

                      </div>
                    </InitialDiv>

                    {user?.name === null && (
                      <InitialDiv className="text-left">
                        <TextHead4 id="username" className="mb-[-15px] mt-4">
                          Your Name<Astk> *</Astk>
                        </TextHead4>
                        <TextBox1
                          type="text"
                          placeholder="Enter Your Name"
                          name="username"
                          style={{
                            border: userNameError ? "1px solid red" : "",
                          }}
                          value={userDatavalues?.username}
                          onChange={handleUserChange}
                        />
                      </InitialDiv>
                    )}

                    {user?.email === null && (
                      <InitialDiv className="text-left">
                        <TextHead4 id="useremail" className="mb-[-15px] mt-4">
                          Your Email<Astk> *</Astk>
                        </TextHead4>
                        <TextBox1
                          type="text"
                          placeholder="Enter Your Email Address"
                          name="useremail"
                          style={{
                            border: userEmailError ? "1px solid red" : "",
                          }}
                          value={userDatavalues?.useremail}
                          onChange={handleUserChange}
                        />
                      </InitialDiv>
                    )}

                    {user?.phone_no === null && (
                      <InitialDiv className="text-left">
                        <TextHead4 id="userphonenumber" className="mb-12 mt-4">
                          Your Phone Number<Astk> *</Astk>
                        </TextHead4>
                        <PhoneInput
                          className={isDarkMode ? "country-selector" : ""}
                          international
                          countryCallingCodeEditable={false}
                          defaultCountry="US"
                          limitMaxLength={true}
                          placeholder="Enter Your Phone Number"
                          name="userphonenumber"
                          value={userDatavalues?.userphonenumber}
                          onChange={handleUserPhoneChange}
                          style={{
                            border: userPhoneError ? "1px solid red" : "",
                          }}
                        />
                      </InitialDiv>
                    )}
                  </>}
              </InitialDiv>

              {(activeBar === "address") &&
                <InitialDiv className="text-left m-4">
                  {(respondedData?.email === null || respondedData?.email === "") && (
                    <>
                      <TextHead4
                        id="recipientcontact"
                        className="mb-[-15px] mt-4"
                      >
                        Recipient Email<Astk> *</Astk>
                      </TextHead4>
                      <TextBox1
                        placeholder="Enter Recipient Email"
                        name="recipientContact"
                        onBlur={handleUpdateData}
                        onChange={handleEmailChange}
                        style={{ border: recipientEmailError ? "1px solid red" : "" }}
                        value={recipientEmail}
                        readOnly={disabledEmail ? true : false}
                      />
                      <SubText1 className="!text-red-600 mt-[-10px] mb-[10px]">
                        {recipientEmailErrorMsg}
                      </SubText1>
                    </>)}


                  {/*----------------------------------- Address Component Section  ------------------------*/}
                  <InitialDiv className="text-left mb-8">
                    <TextHead4>
                      Delivery Address<Astk> *</Astk>
                    </TextHead4>
                    <AddressesCart
                      title=""
                      addressId={handleAddressId}
                      showDeleteBtn={false}
                      showList={false}
                      pageName="odersHome"
                      pageDataId=""
                      changeAddressTxtBtn="CHANGE ADDRESS"
                      userId={values?.recipientId}
                      setDeliveryAddress={true}
                      changeAddress={true}
                      initialLength={1}
                    />
                    {addressIdError && (
                      <InputLabel className="!text-red-600 !text-center mt-2">
                        Address is required.
                      </InputLabel>
                    )}
                  </InitialDiv>
                </InitialDiv>
              }

              <InitialDiv className="m-4">
                <ButtonGreen onClick={handleSubmit} >
                  {switchSentenceCase("head text", "NEXT")}
                </ButtonGreen>
              </InitialDiv>
            </Section>
          </>
          {showModal && (
            <InitialModalCenter
              showModal={showModal}
              setShowModal={setShowModal}
            >
              <InitialDiv className="m-4">
                <Flex className="items-center">
                  <InitialDiv className="w-full text-center">
                    <TextHead1 className="!text-[18px] !font-bold text-center text-[#D97706]">{values?.recipientName} hasn’t added any fave meals yet.  Do you want to ask them to add their fave meals first?</TextHead1>
                  </InitialDiv>
                </Flex>
                <InitialDiv className="mt-3">
                  <Section>
                    <ButtonGreen
                      className="mb-2"
                      onClick={() => handleInvite()}
                    >
                      {switchSentenceCase("primary button", "Yes, ask them and notify me when they add faves.")}
                    </ButtonGreen>
                    <ButtonGreen
                      className="mt-2"
                      onClick={() =>
                        history.push({
                          pathname: '/gift-select',
                        })
                      }
                    >
                      {switchSentenceCase("primary button", "No, continue without knowing their faves.")}
                    </ButtonGreen>
                  </Section>
                </InitialDiv>
              </InitialDiv>
            </InitialModalCenter>
          )}
        </div>
      </div >
    </>
  );
}


