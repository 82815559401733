import imgFb from '../../assets/restaurent-Meal-4.jpg';
import imginsta from '../../assets/restaurent-Meal-3.jpg';
import imgTwitter from '../../assets/restaurent-Meal-2.jpg';
import imgLinkedin from '../../assets/restaurent-Meal-5.jpg';
import { useEffect, useRef, useState } from 'react';
import { FlexBetween, SubText1, TextHead4 } from '../../styled/styled';
import DotSeprator from '../dotseprator/DotSeprator';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleDollar, faClock, faStar } from '@fortawesome/pro-solid-svg-icons';
import styled from 'styled-components';
import { faHeart } from '@fortawesome/pro-regular-svg-icons';



const HeartModalIcon = styled.div`
    padding: 10px;
    background: #9b9b9b29;
    border-radius: 15px;
    margin-left: auto;
    margin-right: auto;
    /* margin-bottom: -40px; */
    width: fit-content;
    position: relative;
    bottom: 270px;
    left: 130px;
    backdrop-filter: blur(6.5px);
    color: white;
`;

export default function RestaurantCardList2(props: any) {

    const images = [imgFb, imginsta, imgTwitter, imgLinkedin,];
    const delay = 2500;

    const [index, setIndex] = useState(0);
    const timeoutRef = useRef(null);

    useEffect(() => {
        resetTimeout();
        timeoutRef.current = setTimeout(
            () =>
                setIndex((prevIndex) =>
                    prevIndex === images.length - 1 ? 0 : prevIndex + 1
                ),
            delay
        );
        return () => {
            resetTimeout();
        };
    }, [index]);



    function resetTimeout() {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
    }


    return (
        <div className="slideshow">

            <div
                className="slideshowSlider"
                style={{ transform: `translate3d(${-index * 100}%, 0, 0)` }}
            >

                {
                    images?.map((imgs, index) => (
                        <div className="slide" key={index}>
                            <img src={imgs} alt='' />
                        </div>
                    ))
                }
            </div>

            <div className="slideshowDots" style={{
                marginBottom: "-20px"
            }}>
                {images?.map((_, idx) => (
                    <div
                        key={idx}
                        className={`slideshowDot${index === idx ? " active" : ""}`}
                        onClick={() => {
                            setIndex(idx);
                        }}
                    ></div>
                ))}
            </div>
            <TextHead4 className='!mt-2'>
                {props?.restarantName}
            </TextHead4>
            <FlexBetween className='items-center max-w-xs'>
                <SubText1>$$</SubText1><DotSeprator /><SubText1>Chenese</SubText1><DotSeprator /><SubText1>American</SubText1><DotSeprator /><SubText1>Desi Food</SubText1>
            </FlexBetween>
            <FlexBetween className='items-center max-w-xs'>
                <SubText1>4.3</SubText1><FontAwesomeIcon icon={faStar} className="text-green-600 text-xs" /><SubText1>200+ Ratings</SubText1><FontAwesomeIcon icon={faClock} className="text-gray-500 text-xs" /><SubText1>25 min</SubText1><DotSeprator /><FontAwesomeIcon icon={faCircleDollar} className="text-gray-400 text-sm" /><SubText1>Free</SubText1>
            </FlexBetween>
            <HeartModalIcon>
                <FontAwesomeIcon
                    icon={faHeart}
                />
            </HeartModalIcon>
        </div >
    );
}