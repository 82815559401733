import styled from "styled-components";

const LabelText = styled.div`
   font-size: 17px;
   margin-left: 5px;
   width: -webkit-fill-available;
  `;

const Item = styled.div`
  display: flex;
  align-items: center;
  height: 48px;
  position: relative;
  border-bottom: 1px solid #8080801f;
  margin-bottom: 10px;
  margin-top: 10px;
`;

const inputStyle = {
  width: "20px",
  accentColor: "#16a34a",
  transform: "scale(1.8)",
  margin: "0px 10px",
};

const styleDiv = {
  display: "flex",
  alignItems: "center",
  justifyContent: "start",
  width: "-webkit-fill-available",
}

export default function CheckBoxButtonText(props: any) {


  return (
    <>
      <Item style={props?.border}>
        <div style={styleDiv}>
          <input
            style={inputStyle}
            type="checkbox"
            id={props?.name + props?.index}
            name={props?.name}
            value={props?.value}
            checked={props?.checked == props?.value}
            onChange={props?.onSelect}
          />
          <label htmlFor={props?.name + props?.index}>
            <LabelText style={props.labelStyle}>{props?.label}</LabelText>
          </label>
        </div>
      </Item>
    </>
  );
}
