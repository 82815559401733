import { faAngleLeft } from "@fortawesome/pro-solid-svg-icons";
import { useIonViewDidEnter } from "@ionic/react";
import { useState } from "react";
import { useHistory } from "react-router";
import { ButtonGray, ButtonGreen, TextBox2 } from "../../components/styled/styled";
import OneSideTextToggle from "../../components/widgets/buttons/OneSideTextToggle";
import Divider from "../../components/widgets/divider/Divider";
import TopHead from "../../components/widgets/head/TopHead";
import Section from "../../components/widgets/section/Section";
import { displayName, switchSentenceCase, trackPixelEvent } from "../../utils/utilities";



export default function AssignMealCategories(data: any) {
  const history = useHistory();

  const [faveMealsState, setFaveMealsState] = useState(true);
  const [faveMealsState1, setFaveMealsState1] = useState(false);
  const [faveMealsState2, setFaveMealsState2] = useState(true);
  const [faveMealsState3, setFaveMealsState3] = useState(false);
  const [faveMealsState4, setFaveMealsState4] = useState(false);
  const [faveMealsState5, setFaveMealsState5] = useState(false);
  const [faveMealsState6, setFaveMealsState6] = useState(true);
  const [faveMealsState7, setFaveMealsState7] = useState(true);

  const GotoHandle = () => {
    history.push({
      pathname: '/scheduledetailsrecipientsnew',
    })
  }

  useIonViewDidEnter(() => {
    trackPixelEvent('Assign meal categories');
  }, []);


  function FaveMealsState() {
    setFaveMealsState(faveMealsState === false);
  }


  return (
    <div className="screen">

      <TopHead
        title="Assign Fave Meal Categories"
        leftTextIcon={faAngleLeft}
        leftIconStyle={{ fontSize: "24px" }}
        goToHandle={GotoHandle}
      />
      <Divider />

      <div className="grow overflow-auto">
        <Section>
          <TextBox2
            name="wrok-fuel"
            placeholder="Work Fuel Schedule"
          />
          <OneSideTextToggle leftText="Fave Meal Categories" style={{ fontWeight: "500" }} beforeBtnText="Select All" switchState={faveMealsState} handleOnChangeToggle={FaveMealsState} />
          <Divider />

          <OneSideTextToggle leftText={`${displayName()} Anytime`} id="1" style={{ paddingLeft: "15px" }} beforeBtnText="" switchState={faveMealsState1} handleOnChangeToggle={() => setFaveMealsState1(prev => !prev)} />

          <OneSideTextToggle leftText="Snack" id="2" style={{ paddingLeft: "15px" }} beforeBtnText="" switchState={faveMealsState2} handleOnChangeToggle={() => setFaveMealsState2(prev => !prev)} />

          <OneSideTextToggle leftText="Evening Dinner" id="3" style={{ paddingLeft: "15px" }} beforeBtnText="" switchState={faveMealsState3} handleOnChangeToggle={() => setFaveMealsState3(prev => !prev)} />

          <OneSideTextToggle leftText="My Fave Meals" id="4" style={{ fontWeight: "500" }} beforeBtnText="Select All" switchState={faveMealsState4} handleOnChangeToggle={() => setFaveMealsState4(prev => !prev)} />
          <Divider />

          <OneSideTextToggle leftText="Cookie Sandwich Meal" id="5" style={{ paddingLeft: "15px" }} beforeBtnText="" switchState={faveMealsState5} handleOnChangeToggle={() => setFaveMealsState5(prev => !prev)} />

          <OneSideTextToggle leftText="Chow Fun Meal" id="6" style={{ paddingLeft: "15px" }} beforeBtnText="" switchState={faveMealsState6} handleOnChangeToggle={() => setFaveMealsState6(prev => !prev)} />

          <OneSideTextToggle leftText="Dim Sum Meal" id="7" style={{ paddingLeft: "15px" }} beforeBtnText="" switchState={faveMealsState7} handleOnChangeToggle={() => setFaveMealsState7(prev => !prev)} />

          <Section>
            <ButtonGreen onClick={() => history.push({ pathname: "/scheduledetailsrecipientsnew" })}>{switchSentenceCase("primary button", "SAVE AND CONTINUE")}</ButtonGreen>
            <ButtonGray style={{ margin: "15px 0px" }} >{switchSentenceCase("primary button", "OOPS! NEVER MIND")}</ButtonGray>
          </Section>

        </Section>
        <Divider />
      </div>
    </div >
  );
}
