import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { ClosedStripDiv3 } from "../../components/styled/styled";
import RestaurantCardDetails from "../../components/widgets/cards/RestaurantCardDetails";
import RestaurantCardImage from "../../components/widgets/cards/RestaurantCardImage";
import ModalSpinner from "../../components/widgets/modalspinner/ModalSpinner";
import NoDataFound from "../../components/widgets/nodatafound/NoDataFound";
import Spinner from "../../components/widgets/spinner/Spinner";
import { restaurantsNearPlace, setSelectedRestaurantData } from "../../services/restaurant";
import { trackStandardPixelEvent } from "../../utils/utilities";
import { removeOrderMealItem, setSearchedRestaurantData } from "../orderFood/redux/orderFoodActionCreator";
import { removeFavsMealItem } from "../restaurant/redux/menuItemActionCreatior";
import { getRestaurantsList, getSearchedRestaurantsData, setRestaurantsListLoading } from "./redux/searchActionCreator";

export default function SearchResults() {

  const dispatch = useDispatch()
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const type = searchParams.get('type');
  const history = useHistory();
  const { userSearchLocation } = useSelector((state: any) => state.getUserSearchLocation);
  const { restaurantsList, isResultLoading, nextPage } = useSelector((state: any) => state.getUserRestaurantsLists);
  const { userDeliveryAddress } = useSelector((state: any) => state.userAddresses);

  const onRestaurantClick = async (r: any) => {
    const restaurantSendData = {
      id: r._id,
      name: r.name,
      logoPhoto: r.logo_photos[0],
      description: r.description,
      rating: r.weighted_rating_value,
      ratingCount: r.aggregated_rating_count,
      quoteIds: r.quote_ids,
      is_open: r.is_open,
      dollar_signs: r.dollar_signs,
      local_hours: r.local_hours
    }

    // Access the query values
    const forPage = searchParams.get('for');
    if (forPage === "get-food-now") {
      dispatch(setSearchedRestaurantData(restaurantSendData) as any);
      await setSelectedRestaurantData(restaurantSendData);
      dispatch(removeOrderMealItem(restaurantSendData?.id) as any);
      history.push(`/restaurantDetail/${restaurantSendData?.id}?type=${type}`);
    }
    else if (forPage === "faves") {
      dispatch(getSearchedRestaurantsData(restaurantSendData) as any);
      await setSelectedRestaurantData(restaurantSendData);
      dispatch(removeFavsMealItem(restaurantSendData?.id) as any);
      history.push(`/restaurant/${restaurantSendData?.id}?type=${type}`);
    }
  };


  // -----------Filtering for Duplicate Data---------------
  const filteredData = Array.from(new Set(restaurantsList.map((a: any) => a.name)))
    .map((name: any) => {
      return restaurantsList.find((a: any) => String(name).toLowerCase().includes(String(a.name).toLowerCase()))
    });

  const showMore = async () => {
    try {
      const placeObject = {
        lat: userDeliveryAddress?.lat ? userDeliveryAddress?.lat : userSearchLocation?.lat,
        lang: userDeliveryAddress?.lang ? userDeliveryAddress?.lang : userSearchLocation?.lang,
        nextPage: nextPage,
        orderType: type
      };

      dispatch(setRestaurantsListLoading(true) as any);

      const response = await restaurantsNearPlace(placeObject);
      if (response) {
        trackStandardPixelEvent('Search');
        const restaurantData = response?.data?.data?.stores;
        const nextPage = response?.data?.data?.next_page;

        // -----------Filtering for Duplicate Data---------------
        const filteredData = Array?.from(new Set(restaurantData?.map((a: any) => a?.name))).map((name: any) => {
          return restaurantData?.find((a: any) =>
            String(name)?.toLowerCase()?.includes(String(a?.name)?.toLowerCase())
          );
        }
        );
        dispatch(setRestaurantsListLoading(false) as any);
        dispatch(getRestaurantsList(filteredData, nextPage) as any);
      }
    }
    catch (error) {
      dispatch(setRestaurantsListLoading(false) as any);
      console.log(error);
    }
  }

  return (
    <div className="search-results">
      {
        (isResultLoading && (nextPage === null))
          ?
          <div className="text-center">
            <ModalSpinner style={{ height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }} loadingText={"Loading Nearby Restaurants..."} />
          </div>
          :
          ((filteredData.length === 0)
            ?
            <NoDataFound
              message="Your search may not be within the max delivery distance, filter selections, or available for delivery."
              btnStyle={{ display: "none" }}
            />
            :
            <>
              {filteredData?.map((r: any, index: any) => (
                <div
                  className="search-result"
                  key={index}
                  onClick={() => onRestaurantClick(r)}
                >
                  {/* TODO placeholder img if needed */}
                  <div className="mr-4">
                    <RestaurantCardImage photo={r?.logo_photos} isClosed={r?.is_open} />
                    {/* <img
                      alt="Restaurant Logo"
                      src={r?.logo_photos?.length ? r.logo_photos[0] : defaultRestaurant}
                      className="mr-6 rounded-lg w-32 h-32" style={{ marginBottom: r?.is_open ? "" : "-30px" }} /> */}
                    {
                      r?.is_open
                        ?
                        ""
                        :
                        <ClosedStripDiv3 className="!text-red-600">Closed</ClosedStripDiv3>
                    }
                  </div>
                  <RestaurantCardDetails r={r} />
                </div>
              ))}
              {
                (nextPage !== null) &&
                <div
                  className="text-green-600 text-center cursor-pointer mt-5"
                  onClick={showMore}
                >
                  {(isResultLoading && (nextPage)) ? <span className="text-center"><Spinner /></span> : <span className="mt-2 font-semibold">See More</span>}
                </div>
              }
            </>
          )
      }
    </div >
  );
}
