import { useRef, useState } from "react";
import styled from 'styled-components';
import { switchSentenceCase } from "../../../utils/utilities";
import { ButtonGreen, FlexBetween, TextHead5, TextHead4, TextHead3, SubText2, InputText, moveTopBottom } from "../../styled/styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/pro-regular-svg-icons";

import Section from "../section/Section";
import cardIconImg from "../../assets/RectangleCardIcon.png"
import { useHistory } from "react-router";
import Divider from "../divider/Divider";
import { faAngleDown, faClockThree, faHouseWindow, faUser, faUtensils, faXmark } from "@fortawesome/pro-solid-svg-icons";
import TabsForSpendingLimit from "../toggleable-tabs/TabsForSpendingLimit";
import useDarkMode from "../../hooks/darkmode/DarkMode";

const ModalDiv = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0, 0.8);
  z-index: 1000;
  overflow-y: auto;
`;


const ContentDiv = styled.div<{ isDarkMode: boolean }>`
 position: absolute;
  background-color: ${props => (props?.isDarkMode ? '#2e2e2e' : '#fff')};
  margin-top: 80px;
  padding: 10px;
  z-index: 1000;
  width: 600px;
  max-width: 100%;
  border-radius: .5em;
  text-align: center;
  animation: ${moveTopBottom} .4s ease-in-out;
`;

const BackModalBtn = styled.div`
    position: relative;
    left: -15px;
    font-size: 17px;
    top: -25px;
    margin-bottom: -15px;
`;


export default function ModalDeliveryCheckout(props: any) {
    const history = useHistory();
    const isDarkMode = useDarkMode();
    const [showPromoInput, setShowPromoInput] = useState(true);
    const [perMealValue, setPerMealValue] = useState("");
    const modalRef = useRef()

    const spendingTypes = [

        {
            name: "$2",
            value: "2"
        },

        {
            name: "$3",
            value: "3"
        },

        {
            name: "$4",
            value: "4"
        },
        {
            name: "$5",
            value: "5"
        },
        {
            name: "Other",
            value: "Other"
        }
    ]



    const closeModal = (e: any) => {
        if (modalRef.current === e.target) {
            props?.setShowModal(false)
        }
    }

    return (
        <>
            {props?.showModal ? (
                <ModalDiv ref={modalRef} onClick={closeModal}>

                    <ContentDiv isDarkMode={isDarkMode}>
                        <Section>
                            <BackModalBtn>
                                <FontAwesomeIcon
                                    onClick={() => props?.setShowModal(false)}
                                    icon={faXmark}
                                />
                            </BackModalBtn>
                            <div className="text-left">
                                <TextHead3>Delivery Details</TextHead3>

                                <div className="flex items-end my-7">
                                    <FontAwesomeIcon icon={faUser} className="mt-1" />
                                    <div className="ml-5">
                                        <SubText2 className="!text-green-600">Recipient’s Name</SubText2>
                                    </div>
                                </div>

                                <div className="flex my-4">
                                    <FontAwesomeIcon icon={faUtensils} className="mt-1" />
                                    <div className="ml-5">
                                        <TextHead4>Super Duper Icecream Sandwhich</TextHead4>
                                        <SubText2 className="mt-2">Choc Mint, Sugar Cookie</SubText2>
                                    </div>
                                </div>

                                <Divider />

                                <div className="flex my-4">
                                    <FontAwesomeIcon icon={faClockThree} className="mt-1" />
                                    <div className="ml-5">
                                        <TextHead4>Time</TextHead4>
                                        <SubText2 className="mt-2">Arrives at 7:00PM</SubText2>
                                        <SubText2>Dates: Mar 07 2022</SubText2>
                                        <SubText2>Items: 2</SubText2>
                                    </div>
                                </div>

                                <Divider />

                                <div className="flex my-4">
                                    <FontAwesomeIcon icon={faHouseWindow} className="mt-1" />
                                    <div className="ml-5">
                                        <TextHead4>Leave at Door</TextHead4>
                                        <SubText2 className="mt-2">Leave on the table</SubText2>
                                    </div>
                                </div>

                                <Divider />
                                <FlexBetween>
                                    <TextHead5>Subtotal</TextHead5>
                                    <TextHead5>$10</TextHead5>
                                </FlexBetween>
                                <FlexBetween>
                                    <TextHead5>Taxes</TextHead5>
                                    <TextHead5>$1.11</TextHead5>
                                </FlexBetween>
                                <FlexBetween>
                                    <TextHead5 style={{ fontWeight: "500" }}>Total</TextHead5>
                                    <TextHead5 style={{ fontWeight: "500" }}>$11.11</TextHead5>
                                </FlexBetween>
                                <TextHead5
                                    onClick={() => setShowPromoInput((prev) => !prev)}
                                    style={{
                                        marginBottom: "10px",
                                        fontWeight: "500",
                                        marginTop: "15px",
                                    }}
                                >
                                    Promo code
                                    <FontAwesomeIcon
                                        icon={showPromoInput ? faAngleRight : faAngleDown}
                                        style={{ float: "right" }}
                                    />
                                </TextHead5>
                                {showPromoInput === false ? (
                                    <InputText
                                        style={{ marginBottom: "20px" }}
                                        placeholder="Enter Promo Code"
                                    ></InputText>
                                ) : null}
                                <Divider />

                                <TextHead5 style={{ fontWeight: "700", margin: "10px 0px" }}>
                                    Payment
                                </TextHead5>

                                <FlexBetween>
                                    <TextHead5>Delivery Tip</TextHead5>
                                    <TextHead5>$2</TextHead5>
                                </FlexBetween>
                                <TabsForSpendingLimit types={spendingTypes} setPerMealValue={setPerMealValue} />
                                <FlexBetween className="items-center mb-3">
                                    <div className="flex"><img alt="" src={cardIconImg} className="mr-2" /><TextHead5>•••• 1234</TextHead5></div><FontAwesomeIcon icon={faAngleRight} />
                                </FlexBetween>
                                <Divider />
                            </div>

                            <Section>
                                <SubText2 className="text-center">
                                    Legal jargon about contacting someon via email and you agree
                                </SubText2>
                            </Section>

                            <ButtonGreen
                                style={{ margin: "15px 0px" }}
                                onClick={() => history.push({ pathname: "/othertip" })}
                            >
                                {switchSentenceCase("primary button", "PLACE ORDER")}
                            </ButtonGreen>
                        </Section>
                    </ContentDiv>
                </ModalDiv >
            ) : null
            }
        </>
    );
};


