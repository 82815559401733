import store from "../../../redux/store";
import { getUserAddress, deleteUserAddress } from "../../../services/location";
import { trackPixelEvent } from "../../../utils/utilities";

export const setUserDeliveryAddress = (value: any) => {

    try {
        store.dispatch({
            type: "SET_USER_DELIVERY_ADDRESS",
            payload: value
        })

    } catch (error) {
        console.log(error);
    }
};

export const setSelectedLocation = (value: any) => {

    try {
        store.dispatch({
            type: "SET_SELECTED_LOCATION_ID",
            payload: value
        })

    } catch (error) {
        console.log(error);
    }
};

export const getUserAddresses = (id: any) => (dispatch: any) => {
    try {
        getUserAddress("", id).then((response) => {
            trackPixelEvent('Get user address');
            dispatch(
                {
                    type: "GET_USER_ADDRESS",
                    payload: response?.data?.data
                })
        })
    } catch (error) {
        console.log(error);
    }
};

export const removeAddress = (id: any) => async (dispatch: any) => {
    try {
        deleteUserAddress(id).then((response) => {
            trackPixelEvent('Delete user address');
            dispatch(
                {
                    type: "DELETE_ADDRESS",
                    payload: response?.data?.data?.id
                })
        })
    } catch (error) {
        console.log(error);
    }
};


export const setUserFriendProfileData = (uid, data) => {

    try {
        store.dispatch({
            type: "SET_USER_FRIEND_PROFILE_DATA",
            payload: { uid, data }
        })

    } catch (error) {
        console.log(error);
    }
};

export const setExistedFriendsCount = (value: any) => {
    store.dispatch({
        type: "EXISTED_FRIENDS_COUNT",
        payload: value
    })
}


