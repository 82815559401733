import { apiRequestService } from './apiRequestService';
import { getUserData } from './user';


const API_URL = process.env.REACT_APP_API_URL

export const getCoupon = async (user_id: any, cart_value: any, user_type: any) => {
    try {
        const user = await getUserData()

        let config = {
            method: 'GET',
            url: API_URL + `/v1/get-coupon/${user_id}/${cart_value}/${user_type}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + user?.jwt
            },
        };

        const request = apiRequestService(config);
        return request;

    } catch (error) {
        console.log(error);
    }
};