import { faAngleLeft } from "@fortawesome/pro-solid-svg-icons";
import { useEffect, useRef, useState } from "react";
import Joyride from 'react-joyride';
import { useHistory } from "react-router";
import { ButtonGreen, InitialDiv, TextHead1, TextHead3, TextHead5 } from "../../components/styled/styled";
import Divider from "../../components/widgets/divider/Divider";
import TopHead from "../../components/widgets/head/TopHead";
import Section from "../../components/widgets/section/Section";
import { switchSentenceCase, trackPixelEvent } from "../../utils/utilities";
import { useDispatch } from "react-redux";
import { setCalendarData } from "../../services/schedule";
import { createScheduleForMeClear, createScheduleForMeStep1 } from "../schedules/redux/createScheduleReduxActions";


export default function PlansSelect() {
  const history = useHistory();
  const dispatch = useDispatch();
  let data: object;

  const GotoHandle = () => {
    history.push({
      pathname: '/home',
    })
  };

  const [{ run, steps }, setState] = useState<any>({
    run: false,
    steps: [],
  });

  const btn = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    setState({
      run: true,
      steps: [
        {
          content: (
            <div>
              <TextHead3 className="text-left text-[#D97706] font-semibold">Step 1:</TextHead3>
              <TextHead5 className="text-left">Choose if you want to save your faves first now or later.</TextHead5>
            </div>
          ),
          disableBeacon: true,
          disableOverlayClose: false,
          hideCloseButton: true,
          hideFooter: false,
          placement: 'bottom',
          spotlightClicks: true,
          styles: {
            tooltipContainer: {
              borderRadius: '120px',
            },
            options: {
              zIndex: 10000,
              primaryColor: "#22A45D",
            },
          },
          target: btn.current!,
        },
      ],
    });
  }, []);


  const handleOnClickForMe = async () => {
    dispatch(createScheduleForMeClear() as any);
    dispatch(createScheduleForMeStep1("for_me") as any);
    trackPixelEvent('Calendar for me');
    data = {
      step1: "for_me"
    }
    await setCalendarData(data);
    return history.push({
      pathname: '/create-calendar',
    })
  };


  return (
    <>
      <div className="screen">
        <TopHead
          title="Plans"
          titleStyle={{ color: "#22A45D", fontWeight: "600" }}
          leftTextIcon={faAngleLeft}
          leftIconStyle={{ fontSize: "26px", color: "#22A45D" }}
          goToHandle={GotoHandle}
        />
        <Divider />

        {/* <Joyride
          steps={steps}
          // callback={handleCallback}
          run={run}   // Automatically proceeds to the next step
        /> */}

        <div className="grow overflow-auto">

          <Section>
            <TextHead1 className="!text-[18px] !font-bold text-center text-[#D97706]">Before You Start Planning</TextHead1>
          </Section>

          <InitialDiv className="mx-4 my-2">
            <TextHead1 className="!text-[18px] !font-bold text-center text-[#D97706]">Saving your fave meals can make the planning easier. Do you want to save your faves first?</TextHead1>
          </InitialDiv>

          <section ref={btn}>
            <Section>
              <ButtonGreen
                onClick={() => {
                  history.push({
                    pathname: '/faves',
                  })
                }}
              >
                {switchSentenceCase("primary button", "Yes, let’s do that now")}</ButtonGreen>
            </Section>
            <Section>
              <ButtonGreen
                onClick={() => handleOnClickForMe()}
                className="tour-target-1"
              >
                {switchSentenceCase("primary button", "No, maybe later")}</ButtonGreen>
            </Section>
          </section>
          {/* <button onClick={handleClickStart}>click</button> */}
        </div>
      </div >
    </>
  );
}

