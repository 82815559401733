const initialValue = {
  favDataList: [],
  userFaveMealsList: [],
  otherFavMealList: [],
  totalFavMeal: 0,
  mealCategory: [],
  recentOrderList: [],
  faveMealModalStatus: false,
  totalRecentOrders: 0,
};

const getAllFavData = (state = initialValue, action: any) => {
  switch (action.type) {

    case "SET_USER_FAVE_MEAL_LIST":
      return { ...state, userFaveMealsList: action?.payload };

    case "SET_FAVE_MEAL_MODAL_STATUS":
      return { ...state, faveMealModalStatus: action?.payload };

    case "SET_TOTAL_FAV_MEAL_COUNT":
      return { ...state, totalFavMeal: action?.payload };

    case "SET_OTHER_FAV_MEALS":
      return { ...state, otherFavMealList: action.payload };

    case "SET_USER_MEAL_CATEGORY":
      return { ...state, mealCategory: action.payload };

    case "SET_ORDER_LIST_DATA":
      return { ...state, recentOrderList: action.payload };

    case "RECENT_ORDER_COUNT":
      return { ...state, totalRecentOrders: action?.payload };


    case "UPDATE_FAV_MEAL_DATA":
      const index = (state.userFaveMealsList).findIndex((obj) => obj.id === action.payload.id)
      state.userFaveMealsList[index].attributes.drop_off_notes = action.payload.data.drop_off_notes;
      state.userFaveMealsList[index].attributes.lat = action.payload.data.latitude;
      state.userFaveMealsList[index].attributes.lng = action.payload.data.longitude;
      state.userFaveMealsList[index].attributes.address.data.id = action.payload.data.address.id;
      state.userFaveMealsList[index].attributes.address.data.attributes = action.payload.data.address;
      return { ...state, userFaveMealsList: state.userFaveMealsList };

    case "UPDATE_FAV_MEAL_ITEM_DATA":
      if (action.payload.type === "Fav") {
        const index = (state.userFaveMealsList).findIndex((obj) => obj.id === action.payload.id)
        state.userFaveMealsList[index] = action.payload.data;
        return { ...state, userFaveMealsList: state.userFaveMealsList };
      }
      else if (action.payload.type === "Order") {
        const index = state.recentOrderList.findIndex((obj) => obj.id === action.payload.id)
        state.recentOrderList[index] = action.payload.data;
        return { ...state, recentOrderList: state.recentOrderList };
      }
      else {
        const index = state.otherFavMealList.findIndex((obj) => obj.id === action.payload.id)
        state.otherFavMealList[index] = action.payload.data;
        return { ...state, otherFavMealList: state.otherFavMealList };
      }


    case "INCREASE_QUANTITY":

      const indx1 = state.favDataList.findIndex((list: any) => list.id === action.payload.id1);

      if (indx1 >= 0) {
        const indx2 = state.favDataList[indx1].attributes.favItems.data.findIndex((data: any) => data.id === action.payload.id2);

        if (indx2 >= 0) {
          state.favDataList[indx1].attributes.favItems.data[indx2].attributes.quantity = action.payload.quantity;
        }
      }
      return { ...state, favDataList: state.favDataList };


    case "DELETE_SINGLE_FAV_ITEM":
      if (action.payload.type === "Fav") {
        const filterdData = state.userFaveMealsList.filter((element) => element.id !== action.payload.id);
        return { ...state, userFaveMealsList: filterdData };
      }
      else if (action.payload.type === "Order") {
        const filterdData = state.recentOrderList.filter((element) => element.id !== action.payload.id);
        return { ...state, recentOrderList: filterdData };
      }
      else {
        const filterdData = state.otherFavMealList.filter((element) => element.id !== action.payload.id);
        return { ...state, otherFavMealList: filterdData };
      }

    case "DELETE_SINGLE_CATEGORY_ITEM":
      const filteredCategory = state.mealCategory.filter(
        (data) => data.id !== action.payload
      );

      return { ...state, mealCategory: filteredCategory };



    case "UPDATE_CATEGORY":
      const updatedIndex = state.mealCategory.findIndex((item) => item.id === action.payload.id)

      if (updatedIndex >= 0) {
        state.mealCategory[updatedIndex] = action.payload.updatedData
      }
      return { ...state, mealCategory: state.mealCategory };



    case "DELETE_SINGLE_FAV_MEAL_ITEM":

      const mealId = action?.payload?.meal_id
      const itemId = action?.payload?.item_id

      const mealList = state?.favDataList;
      const mealData = mealList?.filter((list: any) => list?.id === mealId)[0];
      const faveItemList = mealData?.attributes?.favItems?.data;
      const filteredFaveItemList = faveItemList?.filter((list: any) => list?.id !== itemId)

      const mealIndex = mealList.findIndex((list: any) => list.id === mealId);

      if (mealIndex >= 0) {
        const itemIndex = mealList[mealIndex]?.attributes?.favItems?.data?.findIndex((data: any) => data?.id === itemId);

        if (itemIndex >= 0) {
          state.favDataList[mealIndex].attributes.favItems.data = filteredFaveItemList;
        }
      }

      return { ...state, favDataList: state.favDataList };


    // case "GET_ORDER_LIST_DATA":
    //   return { ...state, orderList: action.payload, totalRecentOrders: action.payload?.length };

    case "MAKE_ORDER_TO_FAV":
      const filteredOrder = state.recentOrderList.filter(
        (element) => element.id !== action.payload?.mealId
      );
      return { ...state, recentOrderList: filteredOrder, userFaveMealsList: action.payload?.data };

    default:
      return state;
  }
};

export default getAllFavData;
