import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useRef } from "react";
import styled from 'styled-components';
import { InitialDiv, moveTopBottom } from "../../styled/styled";
import useDarkMode from "../../hooks/darkmode/DarkMode";



const ModalDiv = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0, 0.8);
  z-index: 1000;
  overflow-y: auto;
`;


const ContentDiv = styled.div<{ isDarkMode: boolean }>`
    background-color: ${props => (props?.isDarkMode ? '#2e2e2e' : '#fff')};
    margin: 100px 0px;
    z-index: 1000;
    width: 720px;
    height: fit-content;
    max-width: 90%;
    border-radius: 20px;
    text-align: center;
    animation: ${moveTopBottom} .4s ease-in-out;
`;


const DashModalIcon = styled.div`
  margin-top: -25px;
  text-align: left;
`;


const TextMeal = styled.h3`
font-weight: 600;
 font-size: 21px;
`;



const InitialModalCenter = (props: any) => {

    const contentRef = useRef<HTMLDivElement>();
    const modalRef = useRef();
    const isDarkMode = useDarkMode();

    const closeModal = (e: any) => {
        if (modalRef.current === e.target) {
            props?.setShowModal(false)
        }
    }


    return (
        <>
            {
                props?.showModal ?
                    (
                        <ModalDiv onClick={closeModal}>
                            <ContentDiv style={props?.parentContainerStyle} isDarkMode={isDarkMode}>
                                {props.leftTextIcon == null ? null : <DashModalIcon ><FontAwesomeIcon icon={props?.leftIcon} style={props.leftIconStyle} /></DashModalIcon>}
                                <TextMeal >{props?.title}</TextMeal>
                                <TextMeal >{props?.subtitle}</TextMeal>
                                <InitialDiv ref={contentRef}>{props?.children}</InitialDiv>
                            </ContentDiv>
                        </ModalDiv >
                    )
                    :
                    null
            }
        </>
    );
};


export default InitialModalCenter;






