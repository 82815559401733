import { useRef, useState } from "react";
import styled from "styled-components";
import cardPng from '../../assets/card.png';
import Section from "../section/Section";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/pro-solid-svg-icons";
import { ButtonGreen, InputWithIcon, SubText1, TextHead1, moveTopBottom } from "../../styled/styled";
import { switchSentenceCase, trackStandardPixelEvent, validateEmail } from "../../../utils/utilities";
import { addCardApi } from "../../../services/paymentCards";
import { getUserData, setUserData } from "../../../services/user";
import useDarkMode from "../../hooks/darkmode/DarkMode";

const ModalDiv = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1000;
  overflow-y: auto;
`;

const ContentDiv = styled.div<{ isDarkMode: boolean }>`
  position: absolute;
  background-color: ${props => (props?.isDarkMode ? '#2e2e2e' : '#fff')};
  margin-top: 80px;
  margin-bottom: 80px;
  padding: 10px;
  z-index: 1000;
  width: 600px;
  max-width: 90%;
  border-radius: 20px;
  text-align: center;
  max-height: 90vh;
  overflow: scroll;
  ::-webkit-scrollbar {
    display: none;
}
animation: ${moveTopBottom} .4s ease-in-out;
`;

const BackModalBtn = styled.div`
  position: absolute;
  top: 12px;
  left: 12px;
  font-size: 17px;
  cursor: pointer;
`;

export default function ModalAddPaymentCart(props: any) {

  const isDarkMode = useDarkMode();
  const modalRef = useRef();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [apiResponse, setApiResponse] = useState("");
  const [cardData, setCardData] = useState({ number: "", exp_date: "", cvc: "", })  
  const [cardDataError, setCardDataError] = useState({ number: "", exp_date: "", cvc: "", })

  const handleInput = (e: any) => {
    const name = e.target.name;
    const value = e.target.value;

    if (name === "number") {      
      setCardData((prevValue) => ({ ...prevValue, number: formatCreditCardNumber(value) }))

      if (String(value).trim().length > 0) { setCardDataError((prevValue) => ({ ...prevValue, number: "" })) }
      else { setCardDataError((prevValue) => ({ ...prevValue, number: "required" })) }

    }
    else if (name === "exp_date") {
      setCardData((prevValue) => ({ ...prevValue, exp_date: formatDate(value) }))

      if (String(value).trim().length > 0) { setCardDataError((prevValue) => ({ ...prevValue, exp_date: "" })) }
      else { setCardDataError((prevValue) => ({ ...prevValue, exp_date: "required" })) }

    }
    else if (name === "email") {
      setEmail(value)

      if (String(value).trim().length > 0) { setEmailError("") }
      else { setEmailError("required") }

    }
    else {
      setCardData((prevValue) => ({ ...prevValue, [name]: (value).replace(/\D/g, '') }))

      if (String(value).trim().length > 0) { setCardDataError((prevValue) => ({ ...prevValue, cvc: "" })) }
      else { setCardDataError((prevValue) => ({ ...prevValue, cvc: "required" })) }
    }
  }

  function formatCreditCardNumber(input: string): string {
    // Remove any non-numeric characters
    const numericInput = input.replace(/\D/g, '');

    // Split the numeric input into groups of 4 digits each
    const groups = numericInput.match(/.{1,4}/g);

    // Join the groups with a space separator and return the formatted string
    if (groups) {
      const formattedInput = groups.join(' ');
      return formattedInput;
    } else {
      return numericInput;
    }
  }

  function formatDate(input: any) {
    // Remove any non-numeric characters
    const numericInput = input.replace(/\D/g, '');

    // Add the forward slash if the input value is 2 characters long
    if (numericInput.length > 2) {
      const _month = (Number(numericInput.slice(0, 2)) <= 12) ? numericInput.slice(0, 2) : 12;
      const formattedInput = _month + '/' + numericInput.slice(2, 4);
      return formattedInput;
    } else {
      return numericInput;
    }
  }

  const handleSubmit = async () => {
    try {
      setApiResponse("")

      const { number, exp_date, cvc } = cardData;

      if (number?.length === 0) { setCardDataError((prevValue) => ({ ...prevValue, number: "required" })) }
      else {
        setCardDataError((prevValue) => ({ ...prevValue, number: "" }))
      }

      if (exp_date?.length === 0) { setCardDataError((prevValue) => ({ ...prevValue, exp_date: "required" })) }
      else if (exp_date?.length < 5) { setCardDataError((prevValue) => ({ ...prevValue, exp_date: "Invalid date." })) }
      else { setCardDataError((prevValue) => ({ ...prevValue, exp_date: "" })) }

      if (cvc?.length === 0) { setCardDataError((prevValue) => ({ ...prevValue, cvc: "required" })) }
      else if (cvc?.length < 3) { setCardDataError((prevValue) => ({ ...prevValue, cvc: "Invalid CVC." })) }
      else { setCardDataError((prevValue) => ({ ...prevValue, cvc: "" })) }

      if (props?.emailRequired) {
        if (email?.length === 0) { setEmailError("required") }
        else if (!validateEmail(email)) { setEmailError("Invalid email.") }
        else { setEmailError("") }
      }

      const err = validateDate(cardData?.exp_date)

      if (number?.length < 8 || exp_date?.length === 0 || exp_date?.length < 5 || cvc?.length === 0 || cvc?.length < 3 || (props?.emailRequired && email?.length === 0) || (props?.emailRequired && !validateEmail(email)) || err) { return false; }

      const requestData = {
        email: email.toLowerCase(),
        number: (cardData?.number).replace(/\s/g, ""),
        exp_month: cardData?.exp_date?.split("/")[0],
        exp_year: cardData?.exp_date?.split("/")[1],
        cvc: cardData?.cvc,
      };

      setLoading(true);

      await addCardApi(requestData)
        .then(async (res) => {
          trackStandardPixelEvent('AddPaymentInfo', {
            content_category: null,
            content_ids: null,
            contents: null,
            currency: null,
            value: null
          })
          setLoading(false);
          props?.setShowPaymentModal(false)
          props?.updateData()

          if (props?.emailRequired) {
            const localData = await getUserData();
            const newUpdatedUserInfo = {
              ...localData,
              user: ({
                ...localData.user,
                "email": email,
              })
            };
            await setUserData(newUpdatedUserInfo);
          }
        })

    }
    catch (e) {
      setLoading(false);
      // console.log("Error->",e);

      if (e?.response?.data?.data?.error?.name === "NotFoundError") {
        setCardDataError((prevValue) => ({
          ...prevValue, number: "Invalid card number."
        }))
      }
      let errMsg = "";
      if (e?.response?.data?.data?.raw?.message === "Production API key was used, but a test credit card was provided." || e?.response?.data?.data?.raw?.message === "Missing/Invalid required input parameter: card_number") {
        errMsg = "Incorrect card details provided."
      } 
      else {
      errMsg = e?.response?.data?.data?.raw?.message || "";
      }
      // setCardDataError((prevValue) => ({ ...prevValue, number: errMsg }))
      setApiResponse(errMsg)
    }
  }

  const validateDate = (input: string) => {
    const month = input?.split("/")[0];
    const year = '20' + input?.split("/")[1];
    const currentDateTime = new Date();
    const currentYear = currentDateTime.getFullYear();
    const currentMonth = currentDateTime.getMonth() + 1;

    // Check if the year is in the past
    if (Number(year) < currentYear) {
      setCardDataError((prevValue) => ({ ...prevValue, exp_date: "Invalid year." }))
      return true;
    }

    // Check if the year is the current year and the month is in the past
    if (Number(year) === currentYear && Number(month) < currentMonth) {
      setCardDataError((prevValue) => ({ ...prevValue, exp_date: "Invalid month." }))
      return true;
    }
  }


  const closeModal = (e: any) => {
    if (modalRef.current === e.target) {
      props?.setShowPaymentModal(false);
    }
  };


  return (
    <>
      {(props?.showPaymentModal)
        &&
        (
          <ModalDiv onClick={closeModal}>
            <ContentDiv isDarkMode={isDarkMode}>
              <BackModalBtn>
                <FontAwesomeIcon
                  icon={faChevronLeft}
                  onClick={() => { !loading && props?.setShowPaymentModal((prev: any) => !prev) }}
                />
              </BackModalBtn>

              <div className="grow overflow-auto">
                <Section>
                  <TextHead1 className="text-center mt-7">
                    Add Payment Method
                  </TextHead1>

                  <SubText1 className="text-center mt-5">
                    Add your card information.
                    <br />
                    This card will only be charged when you place an order.
                  </SubText1>
                </Section>
                <Section>
                  {props?.emailRequired &&
                    <>
                      <SubText1>
                        <label className="text-red-900">
                          {/* <FontAwesomeIcon icon={faInfoCircle} /> &nbsp; */}
                          (This email will be tied to your account)
                        </label>
                      </SubText1>
                      <InputWithIcon disabled={loading} style={{ borderColor: (emailError === "required" ? "red" : "") }} onChange={handleInput} value={email} maxLength={100} type="text" name="email" placeholder="example@domain.com" />
                      {((emailError)?.length > 0 && emailError !== "required") && <label className="text-red-600">{emailError}</label>}
                    </>
                  }

                  <img src={cardPng} alt="credit-card" className="for-icon" />
                  <InputWithIcon disabled={loading} style={{ borderColor: (cardDataError?.number === "required" ? "red" : ""), padding: "8px 10px 8px 50px" }} onChange={handleInput} value={String(cardData?.number)} maxLength={19} type="text" inputMode="numeric" name="number" placeholder="XXXX XXXX XXXX XXXX" />
                  {((cardDataError?.number)?.length > 0 && cardDataError?.number !== "required") && <label className="text-red-600">{cardDataError?.number}</label>}
                  <div className="flex">
                    <InputWithIcon disabled={loading} style={{ borderColor: (cardDataError?.exp_date === "required" ? "red" : "") }} onChange={handleInput} value={cardData?.exp_date} className="mr-1" maxLength={5} type="tel" inputMode="numeric" name="exp_date" placeholder="MM/YY" />
                    <InputWithIcon disabled={loading} style={{ borderColor: (cardDataError?.cvc === "required" ? "red" : "") }} onChange={handleInput} value={cardData?.cvc} className="ml-1" maxLength={3} type="text" inputMode="numeric" name="cvc" placeholder="CVC" />
                  </div>
                  <div className="flex justify-between">
                    {((cardDataError?.exp_date)?.length > 0 && cardDataError?.exp_date !== "required") ? <label className="text-red-600">{cardDataError?.exp_date}</label> : <label></label>}
                    {((cardDataError?.cvc)?.length > 0 && cardDataError?.cvc !== "required") && <label className="text-red-600">{cardDataError?.cvc}</label>}
                  </div>
                </Section>
                <Section>
                  <ButtonGreen onClick={handleSubmit} disabled={loading}>
                    {switchSentenceCase("primary button", loading ? "Processing..." : "ADD CARD")}
                  </ButtonGreen>

                  {/* <ButtonInitial
                    onClick={() => history.push({ pathname: "/scancard" })}
                    style={{ border: "1px solid black", marginTop: "15px" }}
                    disabled={loading}
                  >
                    <FontAwesomeIcon icon={faCameraViewfinder} className="mr-2" />
                    {switchSentenceCase("primary button", "SCAN CARD")}
                  </ButtonInitial> */}

                  {(apiResponse.length > 0)
                    &&
                    <SubText1 className="text-center mt-5">
                      <label className="text-red-600 mt-5">
                        {apiResponse}
                      </label>
                    </SubText1>
                  }
                </Section>
              </div>



            </ContentDiv>
          </ModalDiv >
        )
      }
    </>
  );
}
