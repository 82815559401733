import Divider from "../../components/widgets/divider/Divider";
import TopHead from "../../components/widgets/head/TopHead";
import { faAngleLeft, faBadgeCheck, faChevronLeft, faCircleEnvelope, faCirclePhone, faTrashCan } from "@fortawesome/pro-solid-svg-icons";
import { useEffect, useState } from "react";
import { Astk, ButtonGreen, ButtonInitial, Flex, FlexBetween, InitialDiv, InputLabel, InputText2, SubText1, TextBox1, TextHead3, TextHead5 } from "../../components/styled/styled";
import { countryCodeFilter, onKeyDownNonDecimalFilter, sleep, switchSentenceCase, textToNumberFilter, trackPixelEvent, validateEmail, validatePhone } from "../../utils/utilities";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import InitialModalCenter from "../../components/widgets/modals/InitialModalCenter";
import { addAccount, deleteUserAccount, getUserAllAccounts, verifyAccount } from "../../services/manageAccount";
import Spinner from "../../components/widgets/spinner/Spinner";
import ModalSpinner from "../../components/widgets/modalspinner/ModalSpinner";
import NoDataFound from "../../components/widgets/nodatafound/NoDataFound";
import Section from "../../components/widgets/section/Section";
import ModalRemoved from "../../components/widgets/modals/ModalRemoved";
import useDarkMode from "../../components/hooks/darkmode/DarkMode";


export default function ManageAccounts() {

    // *********************** Other Methods ******************************
    const time = 30;
    const isDarkMode = useDarkMode();



    // *********************** Hooks ******************************
    const [showModal, setShowModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showVerify, setShowVerify] = useState(false);
    const [awaiting, setAwaiting] = useState(false);
    const [loading, setLoading] = useState(false);
    const [otp, setOtp] = useState("");
    const [userContact, setUserContact] = useState("");
    const [responseData, setResponseData] = useState<any>({});
    const [userAccountData, setUserAccountData] = useState<any>({});
    const [seconds, setSeconds] = useState(0);
    const [apiResponse, setApiResponse] = useState("");
    const [resendWait, setResendWait] = useState(false);
    const [deleteAccountId, setDeleteAccountId] = useState();



    // ********************Error Handler****************************
    const [inputError, setInputError] = useState("");
    const [otpError, setOtpError] = useState("");


    // ********************** First time Redering *******************************
    useEffect(() => {
        callData();
    }, []);




    // ***************************** Functions ****************************
    const callData = async () => {
        setLoading(true);
        try {
            const response = await getUserAllAccounts();
            setUserAccountData(response?.data?.data);
            trackPixelEvent('Get user all account');
            setLoading(false);
        } catch (error) {
            console.log("error", error)
        }
    };


    const openModal = (id: any) => {
        setDeleteAccountId(id);
        setShowDeleteModal((prev) => !prev);
    };



    const handleDeleteAccount = (id: any) => {
        try {
            deleteUserAccount(id).then((response: any) => {
                let filteredData = userAccountData?.filter((elements: any) => elements?.id !== response?.data?.data?.id);
                setUserAccountData(filteredData);
                trackPixelEvent('Delete user account');
            });
        }
        catch (e) {
            console.log(e);
        }
    };


    const handleChange = async (e: any) => {
        const { value } = e.target;
        setUserContact(textToNumberFilter(value));
        if (userContact?.length > 2) {
            setInputError("");
        }
    };


    const handleSubmit = async (e: any) => {
        try {
            setApiResponse("")
            // Validation-----------------------
            if (userContact === "") {
                setInputError("Phone Number or Email is Required !");
                return false;
            };

            const validPhoneNo = validatePhone(userContact);
            const validEmail = validateEmail(userContact);

            if (!validEmail && !validPhoneNo) {
                setInputError("Please Enter Valid Email or Phone Number!");
                return false;
            }
            else if (validEmail || validPhoneNo) {
                setInputError("");
            };

            const data = {
                contact: validPhoneNo ? countryCodeFilter(userContact) : userContact,
                provider: validPhoneNo ? "phone" : "email"
            }

            // Api Call--------------------------
            setAwaiting(true);
            await addAccount(data)
                .then(async (response: any) => {
                    trackPixelEvent('Add account');
                    let data = response?.data;
                    if (data.status === "pending") {
                        setAwaiting(false);
                        setResponseData(data);
                        setShowVerify(true);
                    }
                })
        } catch (error) {
            setAwaiting(false);
            const errMsg = error?.response?.data?.error?.message || "";
            setApiResponse(errMsg);
        }
    };




    const handleOtpChange = async (e: any) => {
        const { value } = e.target;
        setOtp(value);
    };




    // Timer for user to wait between resending otp.
    const startCountdown = async () => {
        setResendWait(true);
        for (let i = time; i >= 0; i--) {
            await sleep(1000)
            setSeconds(i);

            if (i === 0) {
                setResendWait(false);
                break
            }
        }
    };




    const handleVerify = async () => {

        // Validation-----------------------
        if (otp === "") {
            setOtpError("Please Enter OTP First !");
        } else if (otp?.length < 6 || otp?.length > 6) {
            setOtpError("OTP Should Contain 6 Digits !");
        } else if (otp?.length === 6) {
            setOtpError("");


            // Api Call--------------------------x  
            try {
                setAwaiting(true);
                const data = {
                    "contact": responseData?.contact,
                    "otp": otp,
                    "provider": responseData?.provider
                };

                await verifyAccount(data)
                    .then(async (response: any) => {
                        if (response.status === 200) {
                            trackPixelEvent('Verify account');
                            setAwaiting(false);
                            setResponseData({});
                            setShowVerify(false);
                            setShowModal(false);
                            setAwaiting(false);
                            setUserContact("");
                            setOtp("");
                            callData();
                        }
                    });

            } catch (error) {
                setAwaiting(false);
                const err = error?.response?.data?.error?.message;
                if (String(err).toLowerCase().includes("invalid")) {
                    setOtpError(err);
                    // setApiResponse("Code is invalid. Try again or →");
                }
            }
        }
    };



    const resendOtp = async (e: any) => {
        try {
            if (awaiting || resendWait) { return false; }
            startCountdown();
            setOtp("");
            setOtpError("");

            const data = {
                "contact": responseData?.contact,
                "provider": responseData?.provider
            }

            // Api Call--------------------------
            setAwaiting(true);
            await addAccount(data)
                .then(async (response: any) => {
                    let data = response?.data;
                    trackPixelEvent('Add account');
                    if (data.status === "pending") {
                        setAwaiting(false);
                        setResponseData(data);
                        setShowVerify(true);
                    }
                });

        } catch (error) {
            setAwaiting(false);
            const errMsg = error?.response?.data?.error?.message || "";
            setApiResponse(errMsg)
        }
    };



    return (
        <div className="screen">
            <TopHead
                title="Add Accounts"
                leftTextIcon={faAngleLeft}
                leftIconStyle={{ fontSize: "24px" }}
            />
            <Divider />

            <div className="grow overflow-auto">
                <Section>
                    <InitialDiv className="m-6">
                        <ButtonInitial onClick={() => setShowModal(prev => !prev)} style={{ color: "#22A45D", border: "1px solid #22A45D" }}>
                            {switchSentenceCase("primary button", "ADD NEW ACCOUNT")}
                        </ButtonInitial>
                    </InitialDiv>

                    {loading
                        ?
                        <ModalSpinner />
                        :
                        userAccountData?.length > 0
                            ?
                            userAccountData?.map((accountItems: any, index: any) => {
                                return (
                                    <InitialDiv
                                        key={index}
                                        style={{
                                            boxShadow: "1px 3px 8px -1px #dcf0dccf",
                                            padding: "15px",
                                            borderRadius: "7px",
                                            margin: "15px 20px"
                                        }}
                                    >
                                        <InitialDiv >
                                            <Flex className="items-center">
                                                <FontAwesomeIcon icon={(accountItems?.attributes?.contact).includes("@") ? faCircleEnvelope : faCirclePhone} className="text-[30px] mr-[10px] text-[#cecece]" />
                                                <InitialDiv className="w-full">
                                                    <Flex className="items-baseline"><SubText1 className="mt-2 mr-2">{(accountItems?.attributes?.contact).includes("@") ? "Email" : "Phone"}</SubText1><FontAwesomeIcon icon={faBadgeCheck} className="text-green-600" /></Flex>
                                                    <FlexBetween className="items-center"><TextHead5>{accountItems?.attributes?.contact}</TextHead5><FontAwesomeIcon icon={faTrashCan} className="text-red-600 text-xs cursor-pointer p-[5px] bg-[#ff000012] rounded-[5px]" onClick={() => { openModal(accountItems?.id) }} /></FlexBetween>
                                                    {/* <SubText1 className="text-right !text-green-600 mt-1">2023-06-30T09:18</SubText1> */}
                                                </InitialDiv>
                                            </Flex>
                                        </InitialDiv>
                                    </InitialDiv>
                                )
                            })
                            :
                            <NoDataFound
                                btnStyle={{
                                    display: "none",
                                }}
                                message="No Account Found!"
                            />
                    }

                    {
                        showModal
                        &&
                        <InitialModalCenter
                            showModal={showModal}
                            setShowModal={setShowModal}
                        >
                            <InitialDiv className="m-4">
                                <Flex className="items-center">
                                    <FontAwesomeIcon
                                        className="cursor-pointer"
                                        icon={faChevronLeft}
                                        onClick={() => setShowModal((prev: any) => !prev)}
                                    />
                                    <InitialDiv className="w-full text-center">
                                        <TextHead3>Account</TextHead3>
                                    </InitialDiv>
                                </Flex>
                            </InitialDiv>
                            <Divider />

                            <Divider />
                            {
                                (!showVerify) ?
                                    <InitialDiv className="m-4">
                                        <InitialDiv className="text-left">
                                            <TextHead3 id="recipientcontact" className="mt-4">Email or Phone<Astk> *</Astk></TextHead3>
                                            <TextBox1
                                                placeholder="Enter Email/Phone Number"
                                                name="recipientContact"
                                                style={
                                                    {
                                                        marginTop: "10px",
                                                        marginBottom: "15px",
                                                        border: inputError !== "" ? "1px solid red" : "",
                                                    }
                                                }
                                                onChange={handleChange}
                                                value={userContact} />
                                            {inputError ? <p className="mt-2 text-red-600">{inputError}</p> : ""}
                                            {
                                                (apiResponse?.length > 0)
                                                &&
                                                <SubText1 className="text-left">
                                                    <label className="text-red-600 mt-5">
                                                        {apiResponse}
                                                    </label>
                                                </SubText1>
                                            }
                                        </InitialDiv>
                                    </InitialDiv>
                                    :
                                    <InitialDiv className="m-4">
                                        <FlexBetween><InputLabel className="mb-[10px]">{`Enter code sent to ${responseData?.contact}`}</InputLabel></FlexBetween>
                                        <InputText2 className={isDarkMode ? "!bg-transparent" : ""} placeholder="Enter six digit code" name="otp" value={otp} onChange={handleOtpChange} onKeyDown={onKeyDownNonDecimalFilter} type="number" />

                                        <FlexBetween>
                                            {otpError ? <p className="mt-2 text-rose-800">{otpError}</p> : <p></p>}
                                            <p className="mt-2 text-red-600">
                                                <a onClick={resendOtp} style={{ color: ((seconds > 0) ? "grey" : "") }}>Resend Code {(seconds > 0) ? `(in ${seconds}s)` : ""}</a>
                                            </p>
                                        </FlexBetween>
                                    </InitialDiv>
                            }

                            <InitialDiv className="m-4">
                                {(awaiting === false)
                                    ?
                                    <ButtonGreen
                                        className="mt-[10px]"
                                        onClick={(showVerify) ? handleVerify : handleSubmit}
                                    >
                                        {switchSentenceCase("primary button", "CONTINUE")}
                                    </ButtonGreen>
                                    :
                                    <div className="text-center">
                                        <Spinner />
                                    </div>
                                }
                            </InitialDiv>

                        </InitialModalCenter>
                    }

                    {/* *********************Modal Delete Address****************************** */}
                    <ModalRemoved
                        firstText="Are you sure you want to"
                        secondText="remove this Account?"
                        btnOneText="OOPS!  NEVER MIND..."
                        btnTwoText="YES, REMOVE IT"
                        showModal={showDeleteModal}
                        setShowModal={setShowDeleteModal}
                        deleteItem={handleDeleteAccount}
                        id={deleteAccountId}
                    />
                </Section>
            </div>
        </div>
    );
}