import React from "react";
// import { CheckBoxWrapper, CheckBoxLabel, CheckBox } from "../../components/styled/styled";
import styled from 'styled-components';

const FlexBetween = styled.div`
  display: flex;
  justify-content: center;
  padding: 10px;
`;

const Span = styled.span`

`;

export const CheckBoxWrapper = styled.div`
  position: relative;
  margin-left: 5px;
  margin-right: 5px;
`;


export const CheckBoxLabel = styled.label`
  position: absolute;
  top: 0;
  left: 0;
  width: 42px;
  height: 22px;
  border-radius: 15px;
  background: #bebebe;
  cursor: pointer;
  &::after {
    content: "";
    display: block;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    margin: 3px;
    background: #ffffff;
    box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.2);
    transition: 0.2s;
  }
`;


export const CheckBox = styled.input`
  opacity: 0;
  z-index: 1;
  border-radius: 15px;
  width: 42px;
  height: 26px;
  &:checked + ${CheckBoxLabel} {
    background: #4fbe79;
    &::after {
      content: "";
      display: block;
      border-radius: 50%;
      width: 15px;
      height: 15px;
      margin-left: 23px;
      transition: 0.2s;
    }
  }
`;


export default function BothSideTextToggle(props: any,) {

  return (
    <FlexBetween>
      <Span>{props?.leftText}</Span>
      <CheckBoxWrapper>
        <CheckBox id={props?.id ? props?.id : "checkbox" + props?.name} type="checkbox" onChange={() => props?.handleOnChangeToggle()} checked={props?.switchState} />
        <CheckBoxLabel htmlFor={props?.id ? props?.id : "checkbox" + props?.name} />
      </CheckBoxWrapper>
      <Span>{props?.rightText}</Span>
    </FlexBetween>
  );
}