import React from "react";
import styled from "styled-components";
import { SubText1 } from "../../../styled/styled";
import { priceFormatterUsd, } from "../../../../utils/utilities";
import RadioButtonText from "../../buttons/RadioButtonText";
import Accordion from "../../accordian/AccordionStyled";
import CheckBoxButtonText from "../../buttons/CheckBoxButtonText";
import QuantitySelector from "../../buttons/QuantitySelector";
import { useIonAlert } from "@ionic/react";


const WrapChoice = styled.div`
  padding: 5px;
`;


const ProductCustomization = ({ customizations, /*inputList*/ handleOptionChange, errorList = [] }) => {
    const [presentAlert] = useIonAlert();

    const accordionOpen = (list: any) => {
        let optionCount = 0;
        list?.options?.forEach((element: any) => {
            if (element?.is_selected) {
                optionCount += 1;
            }
        });

        if (optionCount > 0) { return true; }
        else { return false; }
    }

    const sumSelectedOptionQuantity = (options: any) => {
        let sum = 0;
        options?.forEach((optObj: any) => sum += optObj?.selected_quantity);
        return sum
    }

    return (
        <>
            {
                customizations?.map((customizes: any, mainIndex: any) => {

                    return (
                        <div key={mainIndex}>
                            <Accordion
                                opened={accordionOpen(customizes)}
                                title={customizes?.name}
                                error={errorList?.find((e: any) => e?.name === customizes?.name)}
                                requireText={customizes?.min_choice_options > 0 && (customizes?.min_choice_options > 1 ? ("Min Req. " + customizes?.min_choice_options) : "Required")}
                            >
                                <p className="text-slate-400">[Max choice {customizes?.max_choice_options}]</p>
                                <WrapChoice>

                                    {customizes?.options?.map((optionObj: any, index: any) => {

                                        return (
                                            <React.Fragment key={index}>
                                                <div className="flex items-center justify-between !border-b !border-solid !border-gray-100">

                                                    {(customizes?.max_choice_options > 1 && optionObj?.max_qty > 1)
                                                        &&
                                                        <QuantitySelector
                                                            index={index}
                                                            label={optionObj?.name}
                                                            customization_id={customizes?.customization_id}
                                                            max_choice_options={customizes?.max_choice_options}
                                                            selected_quantity={optionObj?.selected_quantity}
                                                            selected_total_quantity={sumSelectedOptionQuantity(customizes?.options)}
                                                            min_quantity={optionObj?.min_qty}
                                                            max_quantity={optionObj?.max_qty}
                                                            default_quantity={optionObj?.default_qty}

                                                            changeQuantity={(quantity: any) => {
                                                                handleOptionChange(
                                                                    {
                                                                        customization_id: customizes?.customization_id,
                                                                        option_id: optionObj?.option_id,
                                                                        quantity: quantity,
                                                                    },
                                                                )
                                                            }}
                                                        />
                                                    }

                                                    {(customizes?.max_choice_options === 1 && optionObj?.max_qty === 1)
                                                        &&
                                                        <RadioButtonText
                                                            index={index}
                                                            label={optionObj?.name}
                                                            checked={optionObj?.name}
                                                            value={(optionObj?.is_selected) && optionObj?.name}
                                                            name={customizes?.customization_id}
                                                            option={optionObj}
                                                            border={{ borderBottom: "none" }}
                                                            onSelect={() => {
                                                                handleOptionChange(
                                                                    {
                                                                        customization_id: customizes?.customization_id,
                                                                        option_id: optionObj?.option_id,
                                                                        quantity: null,
                                                                    },
                                                                )
                                                            }}
                                                        />
                                                    }

                                                    {(customizes?.max_choice_options === 1 && customizes?.min_choice_options === 1 && optionObj?.max_qty > 1)
                                                        &&
                                                        <RadioButtonText
                                                            index={index}
                                                            label={optionObj?.name}
                                                            checked={optionObj?.name}
                                                            value={(optionObj?.is_selected) && optionObj?.name}
                                                            name={customizes?.customization_id}
                                                            option={optionObj}
                                                            border={{ borderBottom: "none" }}
                                                            onSelect={() => {
                                                                handleOptionChange(
                                                                    {
                                                                        customization_id: customizes?.customization_id,
                                                                        option_id: optionObj?.option_id,
                                                                        quantity: null,
                                                                    },
                                                                )
                                                            }}
                                                        />
                                                    }


                                                    {(customizes?.max_choice_options === -1 && optionObj?.max_qty === 1)
                                                        &&
                                                        <RadioButtonText
                                                            index={index}
                                                            label={optionObj?.name}
                                                            checked={optionObj?.name}
                                                            value={(optionObj?.is_selected) && optionObj?.name}
                                                            name={customizes?.customization_id}
                                                            option={optionObj}
                                                            border={{ borderBottom: "none" }}
                                                            onSelect={() => {
                                                                handleOptionChange(
                                                                    {
                                                                        customization_id: customizes?.customization_id,
                                                                        option_id: optionObj?.option_id,
                                                                        quantity: null,
                                                                    },
                                                                )
                                                            }}
                                                        />
                                                    }


                                                    {(customizes?.max_choice_options > 1 && optionObj?.max_qty === 1)
                                                        &&
                                                        <CheckBoxButtonText
                                                            index={index}
                                                            label={optionObj?.name}
                                                            checked={optionObj?.name}
                                                            value={(optionObj?.is_selected) && optionObj?.name}
                                                            name={customizes?.customization_id}
                                                            option={optionObj}
                                                            border={{ borderBottom: "none" }}
                                                            onSelect={() => {

                                                                // Max Quantity Validation
                                                                if (!optionObj?.is_selected && customizes?.max_choice_options <= sumSelectedOptionQuantity(customizes?.options)) {
                                                                    presentAlert({
                                                                        header: 'Max quantity selected',
                                                                        buttons: ['OK'],
                                                                    })
                                                                    return
                                                                }

                                                                handleOptionChange(
                                                                    {
                                                                        customization_id: customizes?.customization_id,
                                                                        option_id: optionObj?.option_id,
                                                                        quantity: null,
                                                                    },
                                                                )
                                                            }}
                                                        />
                                                    }

                                                    <SubText1 className="!text-green-600">+{optionObj?.formatted_price}</SubText1>
                                                </div>

                                                {
                                                    ((optionObj?.customizations?.length > 0) && (customizes?.options?.find((obj: any) => obj?.is_selected))?.option_id === optionObj?.option_id)
                                                    &&
                                                    <div style={{ marginLeft: '10px' }}>
                                                        <ProductCustomization
                                                            errorList={errorList}
                                                            customizations={optionObj?.customizations}
                                                            // inputList={inputList[mainIndex]?.optionObj?.customizations}
                                                            handleOptionChange={handleOptionChange}
                                                        />
                                                    </div>
                                                }
                                            </React.Fragment>
                                        );
                                    })}
                                </WrapChoice>
                            </Accordion>
                        </div>
                    );
                })
            }
        </>
    )
}

export default ProductCustomization;