const initialValue = {
    userSearchLocation: {},
    locationName: ""
}


const getUserSearchLocation = (state = initialValue, action: any) => {
    switch (action.type) {
        case "SET_USER_SEARCH_LOCATION":
            return { ...state, userSearchLocation: action.payload }
        case "LOCATION_NAME":
            return { ...state, locationName: action.payload }
        case "CLEAR":
            return initialValue;
        default:
            return state;
    }
}

export default getUserSearchLocation;