import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faMinus, faPlus } from "@fortawesome/pro-solid-svg-icons";
import { ButtonGreen, FlexBetween, SubText1, SubText2, TextArea1, TextHead1, TextHead5, moveTopBottom } from "../../styled/styled";
import { customizationValidator, findAndModifySelectedOption, initiateCustomization, maxCharacterLimit, priceCalculator, priceFormatterUsd, properNameFormate, switchSentenceCase, trackPixelEvent } from "../../../utils/utilities";
import FeatureImage from "../FeatureImage/FeatureImage";
import sampleMealImage from "../../assets/restaurent-Meal-3.jpg";
import { useDispatch, useSelector } from "react-redux";
import { addFavsMealItem, changeQuantity } from "../../../pages/restaurant/redux/menuItemActionCreatior";
import Accordion from "../accordian/AccordionStyled";
import Spinner from "../spinner/Spinner";
import { getProductCustomizations } from "../../../services/restaurant";
import { addOrderMealItem } from "../../../pages/orderFood/redux/orderFoodActionCreator";
import ProductCustomization from "./sub-components/ProductCustomization";
import { useIonAlert } from "@ionic/react";
import IonModalAlert from "../alerts/IonModalAlert";
import useDarkMode from "../../hooks/darkmode/DarkMode";
import { useParams } from "react-router";
import { RouteParams } from "../../../type";
import { getDeliveryAddress } from "../../../services/location";


// ************************** Styled Components & CSS  *********************************
const ModalDiv = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1000;
  overflow-y: auto;
`;

const ContentDiv = styled.div`
   /* background-color: #fff; */
    margin-bottom: 85px;
    margin-top: 80px;
    /* padding: 10px; */
    z-index: 1000;
    width: 600px;
    max-width: 90%;
    border-radius: 20px;
    text-align: center;
    height: fit-content;
    animation: ${moveTopBottom} .4s ease-in-out;
`;

const Container = styled.div<{ isDarkMode: boolean }>`
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 0.7rem;
  text-align: left;
  background-color: ${props => (props?.isDarkMode ? '#2e2e2e' : 'white')};
`;


const QuantityDiv = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  margin: 30px;
  font-weight: 600;
  font-size: 20px;
`;


export default function ModalProductDetails(props: any) {

  //******************* Others Methods *************************
  const modalRef = useRef();
  const dispatch = useDispatch();
  const [presentAlert] = useIonAlert();
  const isDarkMode = useDarkMode();
  const { rid } = useParams<RouteParams>();
  const addressData = getDeliveryAddress();


  //******************* Fetching States *************************
  const { menuDataList, addfavMealItem, mealId: favePageMealId, mealIndex: favePageMealIndex } = useSelector((state: any) => state.getRestaurantsMenuLists);

  const { searchedRestaurants } = useSelector((state: any) => state.getUserSearchedRestaurants);

  const { searchedRestaurantData, menusDataList, addMealItem, mealId: orderPageMealId, mealIndex: orderPageMealIndex } = useSelector((state: any) => state.getRestaurantData);

  const storeId = (props?.pageName == "FromOrderPage" ? searchedRestaurantData?.id || rid : searchedRestaurants?.id || rid);
  const _menuDataList = (props?.pageName == "FromOrderPage" ? menusDataList?.filter((currentObj: any) => currentObj?.quoteIds == storeId)[0] || [] : menuDataList?.filter((currentObj: any) => currentObj?.quoteIds == storeId)[0] || []);

  const productId = (props?.pageName == "FromOrderPage" ? orderPageMealId : favePageMealId);
  const productIndex = (props?.pageName == "FromOrderPage" ? orderPageMealIndex : favePageMealIndex);
  const mealItem = (props?.pageName === "FromOrderPage" ? addMealItem : addfavMealItem);
  const restaurantName = (props?.pageName == "FromOrderPage" ? searchedRestaurantData?.name : searchedRestaurants?.name);


  //******************* Hooks *************************
  const [singleProduct, setSingleProduct] = useState<any>({});
  const [inputList, setInputList] = useState([]);
  const [errorList, setErrorList] = useState([]);
  const [instructions, setInstructions] = useState("");
  const [errorMsg, setErrorMsg] = useState(false);
  const [awaitingResults, setAwaitingResults] = useState(true);
  const [productInfo, setProductInfo] = useState<any>({});
  const [quantityCount, setQuantityCount] = useState(1);
  const [showIonAlertModal, setShowIonAlertModal] = useState(false);
  const [ionAlertHeader, setIonAlertHeader] = useState("");
  const [ionAlertMessage, setIonAlertMessage] = useState("");


  //******************* first time page rendering *************************
  useEffect(() => {
    matchedData();
  }, []);

  //*******************  Functions *************************
  const closeModal = (e: any) => {
    if (modalRef.current === e.target) {
      props?.setShowProductDetailsModal(false);
    }
  };

  const showAlert = (header: string, message: string, btnMessage: string) => {
    presentAlert({
      header: header,
      message: message,
      buttons: [btnMessage],
    });
  };

  const inputInstructions = (val: string) => {
    setInstructions(val.substring(0, 300));
    if (String(val).trim().length >= 300) {
      return showAlert("", "You exceed the maximum limit.", "OK");
    }
  };

  const handleKeyDown = (e: any) => {
    if (instructions.length >= 300) {
      if (e.key === " " || e.keyCode === 32) {
        return showAlert("", "You exceed the maximum limit.", "OK");
      }
    }
  };


  const matchedData = async () => {
    try {
      setProductInfo({});
      setInputList([]);

      // **************** Filtering Data For Mapped Restaurant Data (Restaurant Name, Restaurant Description, Restaurant Price etc.) ***********************
      let dataList: any;
      for (const item in _menuDataList?.data || {}) {
        dataList = _menuDataList?.data[item].findIndex((list: any) => list?.product_id === productId);

        if (dataList >= 0) {
          const mainData = _menuDataList?.data[item][dataList];
          setSingleProduct(mainData);
          setQuantityCount(mainData?.quantity > 0 ? mainData?.quantity : 1);
          break;
        }
      };

      if (props?.btnText === "Update Changes") {
        // ************** Getting Instructions using Redux state ***********************
        const product = (mealItem?.length > 0) ? mealItem?.find((obj: any, index: number) => obj?.product_id === productId && index === productIndex) : mealItem?.find((obj: any) => obj?.product_id === productId);
        setQuantityCount(product?.quantity);
        if (product?.instructions !== undefined) {
          setInstructions(product?.instructions);
        }


        // ************** Getting Customization using Redux state ***********************
        const selectedOptionFromState = (mealItem?.length > 0) ? mealItem?.find((obj: any, index: number) => obj?.product_id === productId && index === productIndex)?.customizationList || [] : mealItem?.find((obj: any) => obj?.product_id === productId)?.customizationList || [];

        if (selectedOptionFromState?.length > 0) {
          setInputList(selectedOptionFromState)
          setProductInfo({ customizations: selectedOptionFromState })
          setAwaitingResults(false);
          return
        }
      }
      const query = {
        user_latitude: addressData?.lat,
        user_longitude: addressData?.lang,
      }
      // ************** Getting Customization using meal-me API ***********************
      const response = await getProductCustomizations(productId, query)
      if (response) {
        trackPixelEvent('Get product customizations');
        const customizeData = response?.data?.data;
        setProductInfo(customizeData);
        setInputList(initiateCustomization(customizeData?.customizations))
        setAwaitingResults(false);
      }
    }
    catch (e) {
      console.log(e)
    }
  }

  const onAddItemToFavMeal = () => {
    // Required Customization Validation
    const validationList = customizationValidator(inputList);
    setErrorList(validationList);

    if (validationList?.length > 0) {
      return showAlert("Customization Error", "Please select required customization!", "OK");
    }
    if (props?.btnText === "Update Changes") {
      dispatch(
        changeQuantity(
          storeId,
          singleProduct?.product_id,
          quantityCount
        ) as any
      )
    }
    const favItemMeal = [{
      name: singleProduct?.name,
      product_id: singleProduct?.product_id,
      product_image: singleProduct?.image,
      product_price: singleProduct?.price,
      quantity: quantityCount, //singleProduct?.quantity,
      price: priceCalculator(singleProduct?.price, inputList),
      customizationList: inputList,
      instructions: instructions,
      restaurantName: restaurantName
    }];
    if (quantityCount == 0) {
      setErrorMsg(true);
      setShowIonAlertModal(true);
      setIonAlertHeader("No Quantity Found");
      setIonAlertMessage("Please Add at least 1 Quantity of Item !")
    } else {
      dispatch(addFavsMealItem(favItemMeal, (props?.btnText === "Update Changes" ? "update" : "add"), productIndex) as any);
      props?.setShowProductDetailsModal((prev: any) => !prev)
    }
  };



  const onAddItemToOrderMeal = () => {
    // Required Customization Validation
    const validationList = customizationValidator(inputList);
    setErrorList(validationList);

    if (validationList?.length > 0) {
      return showAlert("Customization Error", "Please select required customization!", "OK");
    }
    if (props?.btnText === "Update Changes") {
      dispatch(
        changeQuantity(
          storeId,
          singleProduct?.product_id,
          quantityCount
        ) as any
      )
    }
    const mealItem = [{
      name: singleProduct?.name,
      product_id: singleProduct?.product_id,
      product_image: singleProduct?.image,
      product_price: singleProduct?.price,
      quantity: quantityCount, //singleProduct?.quantity,
      price: priceCalculator(singleProduct?.price, inputList),
      customizationList: inputList,
      instructions: instructions,
      restaurantName: restaurantName
    }];
    if (quantityCount == 0) {
      setErrorMsg(true);
      setShowIonAlertModal(true);
      setIonAlertHeader("No Quantity Found");
      setIonAlertMessage("Please Add at least 1 Quantity of Item !")
    } else {
      dispatch(addOrderMealItem(mealItem, (props?.btnText === "Update Changes" ? "update" : "add"), productIndex) as any);
      props?.setShowProductDetailsModal((prev: any) => !prev)
    }
  };


  const handleOptionChange = (value: any) => {
    const list = findAndModifySelectedOption(
      [...inputList],
      value?.customization_id,
      value?.option_id,
      value?.quantity,
    )
    setInputList(list);
  };


  return (
    <>
      {(props?.showProductDetailsModal)
        &&
        (
          <ModalDiv onClick={closeModal}>
            <ContentDiv>
              <div className="relative screen">
                <div className="overflow-y-auto rounded-[20px]">
                  <FontAwesomeIcon
                    icon={faAngleLeft}
                    className="back-chevron !text-[25px] !px-[8px] !py-[6px] text-white z-10 w-5"
                    onClick={() => props?.setShowProductDetailsModal((prev: any) => !prev)}
                  />
                  <FeatureImage img={singleProduct?.image || sampleMealImage} />
                  <div className={isDarkMode === true ? 'bg-[#2e2e2e]' : 'bg-white'}>
                    <Container isDarkMode={isDarkMode}>
                      <FlexBetween className="items-center">
                        <TextHead1>{properNameFormate(singleProduct?.name)}</TextHead1>
                        <SubText2 className="!text-green-600 mt-2">
                          [{priceFormatterUsd(singleProduct?.price)}]
                        </SubText2>
                      </FlexBetween>
                      <h6 className="text-gray-500 max-w-xs underline">
                        {restaurantName}
                      </h6>
                      <h6 className="text-gray-500 mt-2">
                        {singleProduct?.description}
                      </h6>
                    </Container>
                    <Container isDarkMode={isDarkMode}>
                      {errorMsg && <TextHead5 className="text-red-600 text-center">Please Add at least 1 Quantity !</TextHead5>}
                      <QuantityDiv className={awaitingResults === true ? "pointer-events-none" : ""}>
                        <FontAwesomeIcon
                          icon={faMinus}
                          style={{
                            padding: " 20px",
                            background: "#9797971B",
                            borderRadius: "30px",
                            cursor: "pointer",
                            marginRight: "8px",
                          }}
                          onClick={() => quantityCount >= 1 && setQuantityCount(quantityCount - 1)}
                        />
                        <span
                          style={{
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "row",
                          }}
                        >
                          Quantity {quantityCount}
                        </span>

                        <FontAwesomeIcon
                          icon={faPlus}
                          style={{
                            padding: " 20px",
                            background: "#9797971B",
                            borderRadius: "30px",
                            cursor: "pointer",
                            marginLeft: "8px",
                          }}
                          onClick={() => setQuantityCount(quantityCount + 1)}
                        />
                      </QuantityDiv>

                      {
                        awaitingResults
                        &&
                        <div className="text-center"><Spinner /></div>
                      }

                      {
                        (productInfo?.customizations?.length == 0 && !awaitingResults)
                        &&
                        <div className="text-center" style={{ color: "rgb(169 170 172)" }}>No customization required.</div>
                      }

                      {(productInfo?.customizations?.length > 0 && !awaitingResults)
                        &&
                        <>
                          <ProductCustomization
                            customizations={inputList} //{productInfo?.customizations}
                            // inputList={inputList}
                            errorList={errorList}
                            handleOptionChange={handleOptionChange}
                          />
                        </>}

                      <Accordion title="Add Special Instructions" opened={false}>
                        <SubText1 className="text-right">
                          {instructions?.length >= 300 ? 300 : instructions.length}/{maxCharacterLimit}
                        </SubText1>
                        <TextArea1
                          value={instructions}
                          className="!mt-[0px]"
                          placeholder="Enter Special Instructions"
                          onChange={(e: any) => inputInstructions(e?.target?.value)}
                          onKeyDown={(e) => handleKeyDown(e)}
                        // maxLength={maxCharacterLimit}
                        ></TextArea1>
                      </Accordion>

                      {
                        (props?.pageName == "FromOrderPage")
                          ?
                          <ButtonGreen
                            className={awaitingResults === true ? "my-2 pointer-events-none !bg-[#5cb383] " : `my-2`}
                            onClick={() => onAddItemToOrderMeal()}
                          >
                            {switchSentenceCase("primary button", props?.btnText)}
                          </ButtonGreen>
                          :
                          <ButtonGreen
                            className={awaitingResults === true ? "my-2 pointer-events-none !bg-[#5cb383] " : `my-2`}
                            onClick={() => onAddItemToFavMeal()}
                          >
                            {switchSentenceCase("primary button", props?.btnText)}
                          </ButtonGreen>
                      }
                    </Container>
                  </div>
                </div>
              </div>
            </ContentDiv >
          </ModalDiv >
        )
      }


      {/* *********************Error Alert Modal****************************** */}
      <IonModalAlert
        isOpened={showIonAlertModal}
        setIsOpened={setShowIonAlertModal}
        header={ionAlertHeader}
        subHeader=""
        message={ionAlertMessage}
      />
    </>
  );
}
