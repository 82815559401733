const initialValue = {
    upcomingOrdersForMe: [],
    upcomingOrdersFromMe: [],
    totalUpcomingOrders: 0,
    upcomingOrdersMealDetails: [],
    orderType: "",
    orderForSomeone: {}
};



const upcomingOrderState = (state = initialValue, action: any) => {

    switch (action?.type) {

        case "SET_UPCOMING_ORDERS_FOR_ME":
            return { ...state, upcomingOrdersForMe: action?.payload };

        case "SET_UPCOMING_ORDERS_FROM_ME":
            return { ...state, upcomingOrdersFromMe: action?.payload };


        case "SET_TOTAL_ORDERS_COUNT":
            return { ...state, totalUpcomingOrders: action?.payload };


        case "SET_ORDER_FOR_SOMEONE":
            return { ...state, orderForSomeone: action?.payload };


        case "GET_UPCOMING_ORDERS_MEAL_DETAILS":
            if (action.payload.upcomingOrderType === "for_me") {
                const filteredFaveData = state.upcomingOrdersForMe.filter((data) => (data?.attributes?.favMeal?.data?.id === action.payload.faveId && data?.id === action.payload?.id));
                return { ...state, upcomingOrdersMealDetails: filteredFaveData, orderType: action.payload.upcomingOrderType };
            } else {
                const filteredFaveData = state.upcomingOrdersFromMe.filter((data) => data?.attributes?.favMeal?.data?.id === action.payload.faveId && data?.id === action.payload?.id);
                return { ...state, upcomingOrdersMealDetails: filteredFaveData, orderType: action.payload.upcomingOrderType };
            };


        case "RESCHEDULE_SLOT":

            if (action.payload.orderType === "for_me") {
                const index = state.upcomingOrdersForMe.findIndex((obj) => obj.id === action.payload.id)
                state.upcomingOrdersForMe[index] = action.payload.updatedData;

                return { ...state, upcomingOrdersForMe: state.upcomingOrdersForMe };
            }

            else if (action.payload.orderType === "from_me") {
                const index = state.upcomingOrdersFromMe.findIndex((obj) => obj.id === action.payload.id)
                state.upcomingOrdersFromMe[index] = action.payload.updatedData;

                return { ...state, upcomingOrdersFromMe: state.upcomingOrdersFromMe };
            }

            else {
                return { ...state, upcomingOrdersForMe: state.upcomingOrdersForMe, upcomingOrdersFromMe: state.upcomingOrdersFromMe, };
            }

        case "UPDATE_SCHEDULE_DATA":

            const index = state.upcomingOrdersMealDetails.findIndex((obj) => obj.id === action.payload.id)
            state.upcomingOrdersMealDetails[index].attributes.favMeal.data.attributes.restaurant.data.attributes.scheduleData = action.payload.data;

            return { ...state, upcomingOrdersMealDetails: state.upcomingOrdersMealDetails };


        default:
            return state;
    }
};

export default upcomingOrderState;