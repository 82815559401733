import Divider from "../../components/widgets/divider/Divider";
import Section from "../../components/widgets/section/Section";
import TopHead from "../../components/widgets/head/TopHead";
import {
  Block2,
  ButtonGreen,
  Flex,
  FlexBetween,
  InitialDiv,
  OrderPills,
  SubText1,
  TextHead1,
  TextHead4,
} from "../../components/styled/styled";
import { switchSentenceCase, trackPixelEvent } from "../../utils/utilities";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleLeft,
  faLocationDot,
  faPotFood,
} from "@fortawesome/pro-solid-svg-icons";
import sampleMealImg from "../../components/assets/chow-fun.png";
import { useParams } from "react-router";
import { useEffect, useState } from "react";
import { trackOrder } from "../../services/orderFood";
import Spinner from "../../components/widgets/spinner/Spinner";
import { getSlotById } from "../../services/schedule_slot";
import { IonContent, IonRefresher, IonRefresherContent, RefresherEventDetail, useIonViewDidEnter } from "@ionic/react";



export default function OrderTracking() {
  // **************************** Hooks ***************************************
  const { slotId } = useParams<any>();
  const [awaiting, setAwaiting] = useState(false);
  const [trackingData, setTrackingData] = useState<any>({});
  const [slotData, setSlotData] = useState<any>({});
  const [isRefreshed, setIsRefreshed] = useState(false);

  // const trackingData = {
  //   status: ""
  // }


  // **************************** First time Rendering ***************************************
  useEffect(() => {
    getOrdersTrackingDetails();

    const intervalId = setInterval(() => {
      getOrdersTrackingDetails();
    }, 3 * 60 * 1000);

    // Clean up the interval when the component is unmounted or when the dependencies change
    return () => {
      clearInterval(intervalId);
    };
  }, [isRefreshed]);


  useIonViewDidEnter(() => {
    trackPixelEvent('Order track');
  }, []);


  // **************************** Functions ***************************************
  function handleRefresh(event: CustomEvent<RefresherEventDetail>) {
    setTimeout(() => {
      setIsRefreshed(prev => !prev);
      event.detail.complete();
    }, 2000);
  }

  const getOrdersTrackingDetails = async () => {
    setAwaiting(true);
    try {
      if (!slotId)
        return false;
      const response = await trackOrder(slotId);
      setTrackingData(response?.data?.data);
      trackPixelEvent('Track order');
      const slot_res = await getSlotById(slotId);
      setSlotData(slot_res?.data?.data);
      if (slot_res) {
        trackPixelEvent('Get slot by id');
      }
      setAwaiting(false);
    } catch (error) {
      console.log(error);
      setAwaiting(false);
    }
  };


  return (
    <div className="screen">
      <TopHead
        title="Track Order"
        leftTextIcon={faAngleLeft}
        leftIconStyle={{ fontSize: "24px" }}
      />
      <Divider />
      {/* <div className="grow overflow-auto"> */}
      <IonContent>
        <IonRefresher slot="fixed" onIonRefresh={handleRefresh}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>
        {awaiting ? (
          <div className="h-full flex flex-col items-center justify-center">
            <Spinner />
          </div>
        ) : (
          <>
            <Section>
              <FlexBetween className="items-center">
                <TextHead1 className="text-left">{trackingData?.final_quote?.store}</TextHead1>
                {trackingData?.status === "canceled" ?
                  <OrderPills style={{ color: "#EF4406", backgroundColor: "#FBE0E0", borderColor: "#EF4406", }}>
                    Canceled</OrderPills>
                  : trackingData?.status === "completed" || slotData?.attributes?.order_status === "COMPLETED" ? (
                    <OrderPills className="mt-2">Completed</OrderPills>
                  ) : (
                    <OrderPills className="mt-2 text-center">
                      {`${trackingData?.pickup ? "Ready" : "Arriving"} at ${trackingData?.final_quote?.quote?.expected_time_of_arrival}`}
                    </OrderPills>
                  )}
              </FlexBetween>
              <Flex className="mt-2 items-center">
                <FontAwesomeIcon icon={faLocationDot} className="text-[#cccaca] ml-1" />
                <SubText1 className="text-left ml-2">
                  {trackingData?.final_quote?.store_address}
                </SubText1>
              </Flex>
              <Flex className="items-center">
                <FontAwesomeIcon icon={faPotFood} className="text-[#cccaca]" />
                <SubText1 className="mt-1 ml-1">Order ID ({trackingData?.order_id})</SubText1>
              </Flex>
            </Section>
            {trackingData?.items?.map((item: any, index: any) => {
              return (
                <Section key={index}>
                  <Flex>
                    <img
                      src={item?.image ? item?.image : sampleMealImg}
                      alt="sample-food"
                      className="shadow-[9px_9px_9px_-12px_rgba(0,0,0,10.3)] !rounded-[10px]"
                      width={120}
                      height={120}
                    />
                    <InitialDiv className="flex flex-col justify-evenly">
                      <InitialDiv className="ml-6">
                        <TextHead4>{item?.name}</TextHead4>
                        <SubText1 className="mt-1">Product</SubText1>
                      </InitialDiv>
                      <InitialDiv className="ml-6">
                        <TextHead4>x {item?.quantity}</TextHead4>
                        <SubText1 className="mt-1">Item Quantity</SubText1>
                      </InitialDiv>
                    </InitialDiv>
                  </Flex>
                </Section>
              );
            })}
            <Section className="stepper">
              <ul className="">
                <li>
                  <div className={trackingData?.status === "awaiting_confirmation" ? "active progress one" : "active progress one"}>
                    {/* <p>1</p> */}
                  </div>
                  <Block2>
                    <TextHead4>{trackingData?.final_quote?.store}</TextHead4>
                    <SubText1 className="mt-1 max-w-[18rem]">
                      Order Placed
                    </SubText1>
                  </Block2>
                </li>

                <li>
                  <div className={trackingData?.status === "in_progress" || trackingData?.status === "completed" || slotData?.attributes?.order_status === "COMPLETED" ? "active progress two" : trackingData?.status === "canceled" ? "de-active progress two" : "progress two"}>
                  </div>
                  <Block2>
                    <TextHead4>
                      {trackingData?.status === "in_progress" ? "In Progress" : trackingData?.status === "canceled" ? "Canceled" : "In Progress"}
                    </TextHead4>
                    <SubText1 className="mt-1 items-center">
                      {trackingData?.status === "in_progress" ? "Order preparing" : trackingData?.status === "canceled" ? "Order not completed" : "Order preparing"}
                    </SubText1>
                  </Block2>
                </li>

                <li className="mr-4">
                  <div
                    className={
                      trackingData?.status === "completed" ||
                        slotData?.attributes?.order_status === "COMPLETED"
                        ? "active progress three"
                        : "progress three"
                    }
                  >
                  </div>
                  <Block2 className="last-stepper">
                    {/* <Block2 className="max-[500px]:mt-0 max-[980px]:mt-5"> */}
                    <TextHead4 className="">
                      {(trackingData?.user_street_num !== "N/A"
                        ? trackingData?.user_street_num + " "
                        : "") +
                        trackingData?.user_street_name +
                        ", " +
                        trackingData?.user_city +
                        ", " +
                        trackingData?.user_state +
                        " - " +
                        trackingData?.user_zipcode +
                        ", " +
                        trackingData?.user_country
                      }
                    </TextHead4>
                    <SubText1 className="mt-1">{trackingData?.pickup ? "Completed" : "Delivered"}</SubText1>
                  </Block2>
                </li>
              </ul>
            </Section>
            <Section>
              <ButtonGreen onClick={() => window.open(trackingData?.tracking_link, "_blank")}>
                {switchSentenceCase("primary button", "See More Tracking Details")}
              </ButtonGreen>
            </Section>
          </>
        )}
      </IonContent>
      {/* </div> */}
    </div>
  );
}
