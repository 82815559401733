export const invitedMemberLogin = (schedule: any, invite: any) => async (dispatch: any) => {
    try {
        dispatch(
            {
                type: "INVITED_MEMBER_LOGIN",
                payload: { schedule, invite }
            })
    } catch (error) {
        console.log(error);
    }
}

export const invitationStateClear = () => (dispatch: any) => {
    try {
        dispatch(
            {
                type: "CLEAR_INVITATION_STATE",
            })
    } catch (error) {
        console.log(error);
    }
};


