import { faAngleLeft } from "@fortawesome/pro-solid-svg-icons";
import { useHistory } from "react-router";
import send_plan_sis from "../../components/assets/dashboard/Send_plan_sis.svg";
import { ButtonGreen, InitialDiv, TextHead1 } from "../../components/styled/styled";
import Divider from "../../components/widgets/divider/Divider";
import TopHead from "../../components/widgets/head/TopHead";
import Section from "../../components/widgets/section/Section";
import { switchSentenceCase, trackPixelEvent } from "../../utils/utilities";
import { useDispatch, useSelector } from "react-redux";
import { createScheduleForMeClear, createScheduleForMeStep1 } from "../schedules/redux/createScheduleReduxActions";
import { setCalendarData } from "../../services/schedule";



export default function PlansHelps() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { totalFavMeal } = useSelector((state: any) => state.getAllFavData);
  let data: object;

  const GotoHandle = () => {
    history.push({
      pathname: '/home',
    })
  };


  const handleRoute = () => {
    if (totalFavMeal > 0) {
      handleOnClickForMe();
    } else {
      history.push({
        pathname: '/plans-select',
      })
    }
  };

  const handleOnClickForMe = async () => {
    dispatch(createScheduleForMeClear() as any);
    dispatch(createScheduleForMeStep1("for_me") as any);
    trackPixelEvent('Calendar for me');
    data = {
      step1: "for_me"
    }
    await setCalendarData(data);
    return history.push({
      pathname: '/create-calendar',
    })
  };


  return (
    <>
      <div className="screen">
        <TopHead
          title="Plans"
          titleStyle={{ color: "#22A45D", fontWeight: "600" }}
          leftTextIcon={faAngleLeft}
          leftIconStyle={{ fontSize: "26px", color: "#22A45D" }}
          goToHandle={GotoHandle}
        />
        <Divider />

        <div className="grow overflow-auto">
          <Section>
            <TextHead1 className="!text-[18px] !font-bold text-center text-[#D97706]">How MealFave helps you plan your meals</TextHead1>
          </Section>
          <Section>
            <ol type="1" className="font-medium">
              <li>1. Set up recurring orders</li>
              <li>2. Autofill scheduled orders with faves</li>
              <li>3. Invite friends to gift a meal</li>
            </ol>
          </Section>
          <Section>
            <ButtonGreen
              onClick={() => handleRoute()}
            >
              {switchSentenceCase("primary button", "Continue Planning")}</ButtonGreen>
          </Section>
          <InitialDiv>
            <img src={send_plan_sis} className="ml-auto mr-auto" alt="send-palns" />
          </InitialDiv>
        </div>
      </div >
    </>
  );
}


