const initialValue = {
    locationList: [],
    userDeliveryAddress: {},
    userFriendDataList: [],
    selectedLocationId: "",
    totalExistedFriends: "",
};

const userAddresses = (state = initialValue, action: any) => {

    switch (action?.type) {

        case "GET_USER_ADDRESS":
            return { ...state, locationList: action.payload };

        case "DELETE_ADDRESS":
            const filteredData = state.locationList.filter(
                (element) => element.id !== action.payload
            );
            return { ...state, locationList: filteredData };

        case "SET_USER_DELIVERY_ADDRESS":
            return { ...state, userDeliveryAddress: action.payload, selectedLocationId: action.payload.id };

        case "SET_SELECTED_LOCATION_ID":
            return { ...state, selectedLocationId: action.payload };

        case "EXISTED_FRIENDS_COUNT":
            return { ...state, totalExistedFriends: action?.payload };


        case "SET_USER_FRIEND_PROFILE_DATA":
            const { uid, data } = action.payload;
            return {
                ...state,
                userFriendDataList: [
                    ...state.userFriendDataList.filter(item => item.uid !== uid), // Remove existing data with the same uid
                    { uid, data } // Add new data to the array
                ],
            };

        default:
            return state;
    }
};

export default userAddresses;