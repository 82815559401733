import { useDispatch } from "react-redux";
import sampleMealImage from "../../components/assets/restaurent-Meal-3.jpg";
import { addMealId } from "./redux/orderFoodActionCreator";
import styled from "styled-components";
import { priceFormatterUsd, switchSentenceCase, textFormatter } from "../../utils/utilities";
import Divider from "../../components/widgets/divider/Divider";
import React, { useState, useRef, useEffect } from "react";
import { AddPlusItemBtn, TextHead4 } from "../../components/styled/styled";
import ModalProductDetails from "../../components/widgets/modals/ModalProductDetails";

const FlexBetween = styled.div`
  font-weight: 600;
  display: flex;
  justify-content: space-between;
`;

const ItemNameText = styled.h4`
  max-width: 65%;
  font-family: system-ui;
  font-size: 18px;
  margin-bottom: 5px;
`;

const SubTextMeal = styled.h3`
    color: #868686;
    font-size: 15px;
    font-weight: 400;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
`;

const MealPrice = styled.div`
color: #22a45d;
`;

export default function RestaurantMenuItemList({
  title,
  filterTerm,
  items,
  restaurant,
  restaurantQuote
}) {

  const dispatch = useDispatch();

  //******************* Hooks *************************
  const [showProductDetailsModal, setShowProductDetailsModal] = useState(false);

  const onPushLocation = (id: any, index: number) => {
    dispatch(addMealId(id, index) as any);
    setShowProductDetailsModal(true);
  };

  const handleCloseModal = (value: any) => {
    setShowProductDetailsModal(value);
  };

  // ---------------------> Filtering according to search data
  const filteredMenuItems = filterTerm ? items.filter((item: any) => item.name.toLowerCase().includes(filterTerm.toLowerCase())) : items;


  return (
    <>
      <div>
        {filteredMenuItems?.length > 0 && <h2 className="mt-2 mb-3 font-normal text-2xl" >{title}</h2>}
        <div>
          {items.length > 0 ?
            filteredMenuItems?.map((i: any, j: number) => {
              return (
                <React.Fragment key={j}>
                  <div
                    className={(process.env.REACT_APP_RESTAURANT_DISABLE === "true" && restaurantQuote !== "") ? "flex meal-card cursor-pointer my-2 !pointer-events-none" : "flex meal-card cursor-pointer my-2"}>
                    <img
                      alt=""
                      src={i.image ? i.image : (restaurant?.logoPhoto ? restaurant?.logoPhoto : sampleMealImage)}
                      className={(process.env.REACT_APP_RESTAURANT_DISABLE === "true" && restaurantQuote !== "") ? "mr-6 rounded-lg w-32 h-32 aspect-square grayscale" : "mr-6 rounded-lg w-32 h-32 aspect-square"}
                      onClick={() => onPushLocation(i?.product_id, 0)}
                    />
                    <div className="flex flex-col justify-around w-full">
                      <FlexBetween>
                        <ItemNameText onClick={() => onPushLocation(i?.product_id, 0)}>{switchSentenceCase("head text", (textFormatter(i.name)))}</ItemNameText>

                        <div className={(process.env.REACT_APP_RESTAURANT_DISABLE === "true" && restaurantQuote !== "") ? `pointer-events-none flex` : "flex"} onClick={() => onPushLocation(i?.product_id, 0)}>
                          <AddPlusItemBtn className={(process.env.REACT_APP_RESTAURANT_DISABLE === "true" && restaurantQuote !== "") ? "!bg-[#5cb383]" : ""}>+ Add</AddPlusItemBtn>
                        </div>
                      </FlexBetween>

                      <FlexBetween>
                        <SubTextMeal>{i.description}</SubTextMeal>
                        <div className="flex absolute bottom-0 w-full">
                          <div className="grow">{i.cuisine}</div>
                        </div>
                        <MealPrice className="grow text-right text-asprimary font-medium">
                          {priceFormatterUsd(i?.price)}
                        </MealPrice>
                      </FlexBetween>
                    </div>
                  </div>
                  <Divider />
                </React.Fragment>
              )
            })
            :
            <>
              {(filterTerm === "") &&
                <TextHead4 className="text-center mt-10">No menu item for the selected category !</TextHead4>
              }
            </>
          }
        </div >
        {/* *********************Modal Product Details****************************** */}
        {showProductDetailsModal && < ModalProductDetails showProductDetailsModal={showProductDetailsModal} setShowProductDetailsModal={handleCloseModal} pageName="FromOrderPage" btnText="Add Item to Order" />}

      </div >
    </>
  );
}
