import { faAngleLeft, faCircleF, faCircleM, faCircleS, faCircleT, faCircleW, faGift, faMinus, faPlus, faSearch } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IonDatetime, IonDatetimeButton, IonModal, IonSelect, IonSelectOption, useIonAlert, useIonViewDidEnter } from "@ionic/react";
import { Select, TimePicker } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Calendar } from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import TimePickers from "react-multi-date-picker/plugins/time_picker";
import "react-multi-date-picker/styles/colors/green.css";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import no_user_pic from "../../components/assets/profile/no-profile-photo.png";
import useDarkMode from "../../components/hooks/darkmode/DarkMode";
import DebouncedSelect from "../../components/styled/DebouncedSelect";
import { Astk, ButtonGreen, Flex, FlexBetween, InitialDiv, InputLabel, InputText, SubText1, TextArea1, TextBox1, TextHead1, TextHead3, TextHead5 } from "../../components/styled/styled";
import AddressesCart from "../../components/widgets/address/AdressesCart";
import RadioButtonText from "../../components/widgets/buttons/RadioButtonText";
import ToggleWithSubText from "../../components/widgets/buttons/ToggleWithSubText";
import Divider from "../../components/widgets/divider/Divider";
import TopHead from "../../components/widgets/head/TopHead";
import Section from "../../components/widgets/section/Section";
import { findFriends } from "../../services/friends";
import { getCalendarData, setCalendarData } from "../../services/schedule";
import { getUserInfo } from "../../services/user";
import { objectType } from "../../type";
import { addDaystoGetDate, countryCodeFilter, displayEmail, displayFullName, displayName, displayNumber, fullDateToShortConvert, generateTimeOptions, handleKeyDownNumber, isAuthenticated, maxCharacterLimit, removeMask, switchSentenceCase, textToNumberFilter, trackStandardPixelEvent, validateEmail, validatePhone } from "../../utils/utilities";
import { calendarRecipientData, createScheduleForMeStep1, createScheduleForMeStep2 } from "./redux/createScheduleReduxActions";

export default function MealForMe() {
  const dispatch = useDispatch();
  const history = useHistory();
  const isDarkMode = useDarkMode();
  const scheduleValues = useSelector((state: any) => state.createScheduleForMe);

  const minDate: any = new Date().toISOString();
  const [presentAlert] = useIonAlert();
  const user = getUserInfo();
  const userDisplayName = displayName();
  const [inputLength, setInputLength] = useState(false);

  const showAlert = (header: string, message: string, btnMessage: string) => {
    presentAlert({
      header: header,
      message: message,
      buttons: [btnMessage],
    });
  };

  useEffect(() => {
    document.getElementById("senderName").scrollIntoView();
    window.scrollTo(0, 0); // Scrolls to the top when the component mounts
  }, []);

  // Getting Sender userName Or Email Or Number
  let userDetails: any;

  if (displayFullName() !== "") {
    userDetails = displayFullName();
  } else if (displayEmail() !== "") {
    userDetails = displayEmail();
  } else {
    userDetails = displayNumber();
  };

  window.onload = async () => {
    const data = await getCalendarData();
    dispatch(
      createScheduleForMeStep1(data?.step1) as any
    );
    if (data?.recipientData !== undefined) {
      calendarRecipientData(data?.recipientData);
    }
    if (data?.step2 !== undefined) {
      dispatch(createScheduleForMeStep2(data?.step2) as any)
    }
  };

  useEffect(() => {
    callFunction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scheduleValues, dispatch]);


  const [switchState, setSwitchState] = useState(false);
  const [sponsorContact, setSponsorContact] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endByDate, setEndByDate] = useState("");
  const [repeatDate, setRepeatDate] = useState<string>("");
  const [repeatDays, setRepeatDays] = useState((scheduleValues?.slots?.type !== undefined) ? scheduleValues?.slots?.type : "Weeks");
  const [endAfterDays, setEndAfterDays] = useState(scheduleValues?.endAfterDays ? scheduleValues?.endAfterDays : "");
  const [endAfterDaysFormatted, setEndAfterDaysFormatted] = useState(scheduleValues?.endAfterDaysFormatted ? scheduleValues?.endAfterDaysFormatted : fullDateToShortConvert(new Date()));
  const [selectedEndBy, setSelectedEndBy] = useState(scheduleValues?.selectedEndBy ? scheduleValues?.selectedEndBy : "endByDate");
  const [timeValue, setTimeValue] = useState(scheduleValues?.slots?.time === undefined ? [{ id: 1, startTime: "" }] : scheduleValues?.slots?.time);
  const [repeatOn, setRepeatOn] = useState((scheduleValues?.slots?.repeatTime === undefined) ? { SUN: [], MON: [], TUE: [], WED: [], THU: [], FRI: [], SAT: [] } : scheduleValues?.slots?.repeatTime);
  const [repeatOnChecked, setRepeatOnChecked] = useState((scheduleValues?.slots?.repeatTime !== undefined) ? { SUN: ((scheduleValues?.slots?.repeatTime?.SUN?.length > 0) ? true : false), MON: ((scheduleValues?.slots?.repeatTime?.MON?.length > 0) ? true : false), TUE: ((scheduleValues?.slots?.repeatTime?.TUE?.length > 0) ? true : false), WED: ((scheduleValues?.slots?.repeatTime?.WED?.length > 0) ? true : false), THU: ((scheduleValues?.slots?.repeatTime?.THU?.length > 0) ? true : false), FRI: ((scheduleValues?.slots?.repeatTime?.FRI?.length > 0) ? true : false), SAT: ((scheduleValues?.slots?.repeatTime?.SAT?.length > 0) ? true : false) } : { SUN: false, MON: false, TUE: false, WED: false, THU: false, FRI: false, SAT: false });
  const [inviteEmails, setInviteEmails] = useState(scheduleValues?.invitations?.length > 0 ? scheduleValues?.invitations : []);
  const [addressId, setAddressId] = useState(scheduleValues?.addressId ? scheduleValues?.addressId : "");
  const [customDateTimeValues, setCustomDateTimeValues] = useState<any>();
  const [values, setValues] = useState({
    senderName: (scheduleValues?.senderName) ? scheduleValues?.senderName : (userDetails !== "") ? userDetails : "",
    name: (scheduleValues?.name !== "") ? scheduleValues?.name : "",
    description: (scheduleValues?.description !== "") ? scheduleValues?.description : "",
  });
  const [friendValue, setFriendValue] = useState([]);

  // Error Handler
  const [sponsorContactError, setSponsorContactError] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [startDateError, setStartDateError] = useState(false);
  const [repeatDayError, setRepeatDayError] = useState(false);
  const [repeatTypeError, setRepeatTypeError] = useState(false);
  const [timeError, setTimeError] = useState(false);
  const [repeatOnTimeError, setRepeatOnTimeError] = useState(false);
  const [customDateTimeError, setCustomDateTimeError] = useState(false);
  const [endByError, setEndByError] = useState(false);
  const [addressIdError, setAddressIdError] = useState(false);
  const [senderNameError, setSenderNameError] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [awaiting, setAwaiting] = useState(false);
  let minEndByDate = startDate === "" ? new Date().toISOString() : moment(startDate).format("YYYY-MM-DD");

  useIonViewDidEnter(() => {
    trackStandardPixelEvent('Schedule');
  }, []);

  const callFunction = () => {
    try {
      if (isAuthenticated() === false) return false;
      setStartDate(scheduleValues?.slots?.startDate ? scheduleValues?.slots?.startDate : fullDateToShortConvert(new Date()));
      setEndByDate(scheduleValues?.endByDate ? scheduleValues?.endByDate : fullDateToShortConvert(new Date()));
      setRepeatDate((scheduleValues?.slots?.repeat !== undefined) ? String(scheduleValues?.slots?.repeat) : "1");
    }
    catch (e) {
      console.log(e)
    }
  }

  const GotoHandle = () => {
    history.push({
      pathname: '/newmealpayments',
    })
  };

  function handleOnChangeToggle() {
    setSwitchState(switchState === false);
  };

  const handleEmailChange = (e: any) => {
    const value = e.target.value
    const validEmail = validateEmail(value);
    const validPhoneNo = validatePhone(removeMask(value));
    const isNumeric = /^[0-9-]+$/.test(value);

    if (validPhoneNo && isNumeric) {
      setInputLength(true);
    }
    else {
      setInputLength(false);
    }
    if (!validEmail && !validPhoneNo) {
      setSponsorContactError(true);
    } else if (validEmail || validPhoneNo) {
      setSponsorContactError(false);
    } else {
      setSponsorContactError(false);
    }
    setSponsorContact(textToNumberFilter(value));
  };

  const handleKeyDown = (e: any, name: any) => {
    const number = handleKeyDownNumber(e, sponsorContact);
    setSponsorContact(number);
  };

  const inputInstructions = (val: string) => {
    setValues((prevValues) => {
      return {
        ...prevValues,
        description: (val.substring(0, 300)),
      };
    });
    if (String(val).trim().length >= 300) {
      return showAlert("", "You exceed the maximum limit.", "OK");
    }
  };

  const handleKeyDownInstruction = (e: any) => {
    if (values?.description?.length >= 300) {
      if (e.key === " " || e.keyCode === 32) {
        return showAlert("", "You exceed the maximum limit.", "OK");
      }
    }
  };

  function sendInvite() {
    if (sponsorContact !== "" && !sponsorContactError) {
      const validPhoneNo = validatePhone(removeMask(sponsorContact));

      if (validPhoneNo && user?.phone_no === removeMask(countryCodeFilter(sponsorContact))) {
        setSponsorContact("");
        return showAlert("", "You cannot send invitation to yourself.", "OK");
      }
      else if (user?.email === sponsorContact.toLowerCase()) {
        setSponsorContact("");
        return showAlert("", "You cannot send invitation to yourself.", "OK");
      }
      else if (inviteEmails.includes((validPhoneNo) ? removeMask(countryCodeFilter(sponsorContact)) : sponsorContact.toLowerCase())) {
        setSponsorContact("");
        return showAlert("", "This member is already added.", "OK");
      }
      else {
        inviteEmails.push((validPhoneNo) ? removeMask(countryCodeFilter(sponsorContact)) : sponsorContact.toLowerCase());
        setSponsorContactError(false);
        setSponsorContact("");
      }
    }
    else {
      setSponsorContactError(true);
    }
  };

  function removeEmail(index: number, item: string) {
    const list = [...inviteEmails];
    const friendList = [...friendValue]

    list.splice(index, 1);
    setInviteEmails(list);
    const itemIndex = friendList.findIndex((val) => val === item);

    if (itemIndex !== -1) {
      friendList.splice(itemIndex, 1);
      // Update the state with the modified arrays
      setFriendValue(friendList);
    }
  };

  const handleChange = (e: any) => {
    // setNameError(false);
    const { name, value } = e.target;
    setValues((prevValues) => {
      return {
        ...prevValues,
        [name]: value,
      };
    });

    if (name === "name") {
      if (value.trim() === "") {
        setNameError(true);
      } else {
        setNameError(false)
      }
    };
  };

  const onHandleSubmit = async () => {
    const { senderName, name, description } = values;
    try {
      if (awaiting) return false;
      let recipientErr = false;
      let timeErr = false;
      let endDateErr = false;

      // for checking senderName Error----
      if (senderName === "") {
        setSenderNameError(true);
        document.getElementById("senderName").scrollIntoView();
      } else {
        setSenderNameError(false);
      };

      // for checking Name Error----
      if (name.trim() === "") {
        setNameError(true);
        document.getElementById("mealSchedulename").scrollIntoView();
      }
      else {
        setNameError(false);
      };

      // for checking Start Date Error----
      if (startDate === "") {
        setStartDateError(true);
      } else {
        setStartDateError(false);
      };

      // for checking Repeat Every Numbers Error----
      if (repeatDate === "") {
        setRepeatDayError(true)
      } else {
        setRepeatDayError(false)
      };

      // for checking Repeat Every Days Error----
      if (repeatDays === "") {
        setRepeatTypeError(true)
      } else {
        setRepeatTypeError(false)
      };

      // for checking Time Error----
      if (repeatDays === "Weeks" || repeatDays === "Custom") {
        setTimeError(false);
      } else {
        const now = moment();
        const today = moment().startOf('day');

        for (let i = 0; i < timeValue.length; i++) {
          const value = timeValue[i].startTime;
          if (value === undefined || value === null || value === "") {
            setTimeError(true);
            timeErr = true;
            document.getElementById("time").scrollIntoView();
          } else {
            const startTime = moment(value, 'HH:mm:ss');
            const startDateMoment = moment(startDate);

            if (startDateMoment.isSame(today, 'day') && startTime.isBefore(now, 'minute')) {
              setTimeError(true);
              document.getElementById('time').scrollIntoView();
              showAlert("", "Selected time should be greater than current time.", "OK");
              return true;
            }
          }
        }
      }

      // for checking RepeatOn Multiple Time(Weeks) Error----
      if (repeatDays === "Weeks") {
        if (repeatOn.SUN.length > 0 || repeatOn.MON.length > 0 || repeatOn.TUE.length > 0 || repeatOn.WED.length > 0 || repeatOn.THU.length > 0 || repeatOn.FRI.length > 0 || repeatOn.SAT.length > 0) {
          setRepeatOnTimeError(false);
        } else {
          setRepeatOnTimeError(true);
          document.getElementById("repeatOn").scrollIntoView();
          return false;
        }
      };

      // for checking endByDate Error----
      if (selectedEndBy === "endByDate" && (endByDate === "" || endByDate < startDate)) {
        setEndByError(true);
        endDateErr = true;
        return showAlert("", "End date should be greater than start date.", "OK");
      } else if (selectedEndBy === "endByDays" && endAfterDays === "") {
        setEndByError(true);
        endDateErr = true;
      }
      else {
        endDateErr = false;
        setEndByError(false)
      }

      if (addressId === "" || addressId === undefined) {
        setAddressIdError(true);
      } else {
        setAddressIdError(false);
      };

      const data = {
        senderName: senderName,
        name: name,
        description: description,
        invitations: inviteEmails,
        addressId: addressId,
        selectedEndBy: selectedEndBy,
        endAfterDays: endAfterDays,
        endByDate: endByDate,
        endAfterDaysFormatted: endAfterDaysFormatted,
        slots: {
          startDate: startDate,
          repeat: parseInt(repeatDate),
          type: repeatDays,
          time: timeValue,
          repeatTime: repeatOn,
          endDate: (selectedEndBy === "endByDays") ? endAfterDaysFormatted : endByDate
        }
      };

      if (repeatDays === "Custom") {
        if (customDateTimeValues === undefined || customDateTimeValues?.length === 0) {
          setCustomDateTimeError(true);
          // document.getElementById("custonCalendar").scrollIntoView();
          return false;
        } else {
          setCustomDateTimeError(false);
          let CustomScheduleTime = [];
          let largestDateTime = new Date(customDateTimeValues[0]); // Initialize with the first element

          customDateTimeValues?.forEach((arrayObj: any) => {
            let customTimeDate = new Date(arrayObj);
            CustomScheduleTime.push({ date: moment(customTimeDate).format('YYYY-MM-DD'), time: moment(customTimeDate).format('HH:mm:ss') });

            if (customTimeDate > largestDateTime) {
              largestDateTime = customTimeDate;
            }
          });
          data.slots["customDateTime"] = CustomScheduleTime;
          data.slots.endDate = moment(largestDateTime).format('YYYY-MM-DD')
        }
      }
      //((scheduleValues?.type === "for_other") && (recipientNameError || scheduleValues?.recipientId === "" || recipientError))
      if ((senderName === "") || (name === '') || (startDate === '') || (repeatDate === "") || (repeatDays === "") || timeErr || endDateErr || (repeatDays === "Days" ? timeValue[0]?.startTime === '' : null) || (data.slots.endDate === '') || (addressId === "") || (addressId === undefined) || recipientErr || (repeatOnTimeError === true) || customDateTimeError) {
        return showAlert("", "Please fill all required fields.", "OK");
      };

      dispatch(createScheduleForMeStep2(data) as any);
      let calendarData = await getCalendarData();
      const newUpdatedCalendarData = {
        ...calendarData,
        step2: data
      };
      await setCalendarData(newUpdatedCalendarData)
      setInviteEmails([]);
      history.push({
        pathname: '/newmealpayments',
      })
    }
    catch (e) {
      console.log(e)
    }
  };

  const handleMultiTimeChange = (value: any, index: any) => {
    let timeFormattedValue = moment(value?.$d).format('HH:mm');
    const newTimes = [...timeValue];
    newTimes[index].startTime = timeFormattedValue;
    setTimeValue(newTimes);
  };

  const addRow = () => {
    const newId = timeValue.length + 1;
    setTimeValue([...timeValue, { id: newId, startTime: "" }]);
  };

  const removeRow = (index: any) => {
    const newTimes = [...timeValue];
    newTimes.splice(index, 1);
    setTimeValue(newTimes);
  };

  const pushLog = (e: any) => {
    if (e.target.name === "repeatDate") {
      setRepeatDate(e.target.value);
    } else {
      setRepeatDays(e.target.value);
      setEndAfterDaysFormatted("");
      setEndAfterDays("");
      // this is for resetting index and number of input fields of time section---
      if (e.target.value !== "Days") {
        setTimeValue([{ id: 1, startTime: "" }]);
      }
      setTimeError(false);
      setRepeatOnTimeError(false);
    }
  };

  const handleOptionChange = (value: any) => {
    setSelectedEndBy(value);
  };

  const handleEndByData = (e: any) => {
    setEndByError(false);
    if (e.target.name === "endByDate") {
      setEndByDate(e.target.value);
    } else {
      setEndAfterDays(e.target.value);
      const addNumber = (repeatDays === "Weeks") ? e.target.value * 7 : e.target.value
      const yourDate = new Date(startDate);
      const formattedDate = fullDateToShortConvert(addDaystoGetDate(yourDate, Number(addNumber)))
      setEndAfterDaysFormatted(formattedDate)
    }
  };

  const handleRepeatOn = (value: any, day: any) => {
    setRepeatOnTimeError(false);
    setRepeatOn((prevValues: any) => {
      return { ...prevValues, [day]: value, };
    });
  };

  const handleAddressId = (id: any) => {
    setAddressIdError(false)
    setAddressId(id);
  };

  const handleCheckboxRepeatOn = (e: any, day: any) => {
    const dayChecked = e;
    setRepeatOnTimeError(false);
    setRepeatOnChecked((prevValues) => {
      return { ...prevValues, [day]: dayChecked, };
    });

    setRepeatOn((prevValues: any) => {
      return { ...prevValues, [day]: dayChecked ? [timeValue[0].startTime] : [] };
    });
  };

  const handleChangeCustom = (e: any) => {
    setCustomDateTimeError(false);
    setCustomDateTimeValues(e);
  }

  const fetchUserList = async (username: string) => {
    if (username.length < 3) {
      // Return an empty array if username length is less than 3
      return [];
    }
    try {
      // Call userFind with the username
      const { data } = await findFriends(username);

      // Map the results to OptionType with custom JSX label
      const options = data?.data.map((user: { name: string; city: string; profile_pic: objectType; email: string; phone_no: string }, index: number) => {
        let labelJSX = (
          <React.Fragment key={index}>
            <div className="flex">
              <img
                alt={user.name + index}
                src={user.profile_pic !== null ? process.env.REACT_APP_API_URL + user.profile_pic?.url : no_user_pic}
                className="mr-6 mt-1 rounded-[20px] border border-solid border-gray-500 w-8 h-8"
              />
              <div>
                <h3>{user.name !== null ? user.name : "-----"}</h3>
                <SubText1>{user.city !== null ? user.city : "-----"}</SubText1>
              </div>
            </div>
          </React.Fragment>
        );
        return {
          title: user.name,
          label: labelJSX,
          value: user.email !== null && user.email !== "" ? user.email : user.phone_no ? user.phone_no : "",
        };
      });
      return options;
    } catch (error) {
      // console.error('Error fetching users:', error);
      // Return an empty array in case of an error
      return [];
    }
  }

  const onChange = (newValue: any[]) => {

    // Set the friend value directly
    setFriendValue(newValue);

    // Identify values to be added to inviteEmails
    const valuesToAdd = newValue.filter(item => !inviteEmails.includes(item));

    // Identify values to be removed from newValue that are already present in inviteEmails
    const valueToRemove = newValue.filter(item => inviteEmails.includes(item));

    // Update inviteEmails by concatenating valuesToAdd with inviteEmails and removing valuesToRemoveFromNewValue
    setInviteEmails([...inviteEmails, ...valuesToAdd.filter(item => !valueToRemove.includes(item))]);

    if (friendValue.length > newValue.length) {
      // Identify values lastly removed from newValue
      const lastRemovedValues = friendValue.filter(item => !newValue.includes(item));
      if (lastRemovedValues.length > 0) {
        const inviteList = [...inviteEmails];
        const itemIndex = inviteList.findIndex((val) => val === lastRemovedValues[0]);
        if (itemIndex !== -1) {
          inviteList.splice(itemIndex, 1);
          // Update the state with the modified arrays
          setInviteEmails(inviteList);
        }
      }
    }
  };

  return (
    <div className="screen" id="top">
      <TopHead
        title={(scheduleValues?.type === "for_me") ? "New Meal Calendar For Me" : `New Meal Calendar For ${scheduleValues?.recipientName}`}
        leftTextIcon={faAngleLeft}
        leftIconStyle={{ fontSize: "24px" }}
        goToHandle={GotoHandle}
        rightText={true}
      />

      <Divider />
      <div className="grow overflow-auto">
        <Section>
          <TextHead1 id="senderName">Your Name<Astk> *</Astk></TextHead1>
          <TextBox1
            placeholder="Meal Calendar Creator Name"
            name="senderName"
            style={{
              border: senderNameError ? "1px solid red" : "",
            }}
            value={values?.senderName}
            onChange={handleChange}
          />

          <TextHead1 id="mealSchedulename">Meal Calendar Name<Astk> *</Astk></TextHead1>
          <TextBox1
            placeholder={`${scheduleValues?.type === "for_me" ? (userDisplayName !== "null's" ? userDisplayName + " Meal Calendar 1" : "User's Meal Calendar 1") : scheduleValues?.recipientName + "'s Meal Calendar 1"}`}
            style={{ border: nameError ? "1px solid red" : "" }}
            name="name"
            value={values?.name}
            onChange={handleChange}
          />

          <TextHead1 className="mt-4">Meal Calendar Description</TextHead1>
          <SubText1 className="text-right mt-2">{values?.description?.length}/{maxCharacterLimit}</SubText1>
          <TextArea1
            className="!-mt-0"
            placeholder="Describe the purpose of the schedule for you and any sponsors (optional)."
            name="description"
            value={values?.description}
            onChange={(e: any) => inputInstructions(e?.target?.value)}
            onKeyDown={(e) => handleKeyDownInstruction(e)}
          />

          {/*----------------------------------- Address Component Section  ------------------------*/}
          {(scheduleValues?.type === "for_me") &&
            <>
              <TextHead1>Delivery Address<Astk> *</Astk></TextHead1>
              {addressIdError && <InputLabel style={{ color: "red" }}>Address is required.</InputLabel>}
              <AddressesCart
                title=""
                addressId={handleAddressId}
                showDeleteBtn={false}
                showList={false}
                pageName={scheduleValues?.addressId ? "scheduleSlotsEdit" : ""}
                pageDataId={scheduleValues?.addressId ? scheduleValues?.addressId : ""}
                changeAddressTxtBtn="CHANGE ADDRESS"
                userId={(scheduleValues?.type === "for_other") ? scheduleValues?.recipientId : user?.id}
                setDeliveryAddress={true}
                changeAddress={true}
                initialLength={(scheduleValues?.type === "for_other") ? (scheduleValues?.addressId) ? 1 : 0 : 1}
              />
            </>
          }

          {/* <TextHead3 className="text-center" onClick={() => history.push({ pathname: "/schedulingwidget" })}>{`${displayName()} Scheduling Widget Here`} </TextHead3>
          {/*----------------------------------- SchedulingWidget  ------------------------*/}
          {/* <div className="grow overflow-auto"> */}
          <FlexBetween className="items-center">
            <TextHead1 className={(scheduleValues?.type === "for_other") ? "-mt-2" : "mt-6"}>Schedule<Astk> *</Astk></TextHead1>
          </FlexBetween>
          <InitialDiv className="my-4">
            {repeatDays !== "Custom" && (
              <InitialDiv className="my-2">
                <SubText1>Start Date</SubText1>
                <IonDatetimeButton className="my-2.5" datetime="date" style={{ border: startDateError ? "1px solid red" : "1px solid #e2e2e2", borderRadius: startDateError ? "8px" : "8px" }}></IonDatetimeButton>
                <IonModal keepContentsMounted={true}>
                  <IonDatetime className={isDarkMode ? "calendar-color" : ""} name="startDate" onIonChange={(e: any) => { setStartDate(e.target.value); }} color={isDarkMode ? "dark" : "success"} value={startDate} min={minDate} showDefaultButtons={true} presentation="date" preferWheel={false} id="date" ></IonDatetime>
                </IonModal>
              </InitialDiv>
            )}

            <InitialDiv className="my-2 container-main">
              <Flex className="flex-container-main">
                <InitialDiv className={`${repeatDays !== "Custom" ? "w-6/12" : "w-full"} mr-1`}>
                  <SubText1 className="text-main">Select Type</SubText1>
                  <IonSelect onIonChange={(e: any) => pushLog(e)} name="repeatType" value={repeatDays} placeholder="Select Type" className={`ion-select-for-schedule !mr-1 ${repeatTypeError ? `!border-red-600` : ""}`} interface="popover">
                    <IonSelectOption value="Days">Repeat Daily</IonSelectOption>
                    <IonSelectOption value="Weeks">Repeat Weekly</IonSelectOption>
                    <IonSelectOption value="Custom">Custom Calendar</IonSelectOption>
                  </IonSelect>
                </InitialDiv>
                {repeatDays !== "Custom" && (
                  <InitialDiv className="w-6/12 ml-1">
                    <SubText1 className="text-main">Repeat&nbsp;Every&nbsp;{repeatDate} {(repeatDays === "Weeks") ? "Week(s)" : "Day(s)"}</SubText1>
                    <IonSelect selected-text={repeatDate} onIonChange={(e: any) => pushLog(e)} name="repeatDate" value={repeatDate} placeholder="Select Days" className={`ion-select-for-schedule !mr-1 ${repeatDayError ? `!border-red-600` : ""}`} interface="popover">
                      <IonSelectOption value="1">1</IonSelectOption>
                      <IonSelectOption value="2">2</IonSelectOption>
                      <IonSelectOption value="3">3</IonSelectOption>
                      <IonSelectOption value="4">4</IonSelectOption>
                      <IonSelectOption value="5">5</IonSelectOption>
                    </IonSelect>
                  </InitialDiv>
                )}
              </Flex>
            </InitialDiv>

            {repeatDays !== "Custom" && (
              <InitialDiv className="my-2" >
                <SubText1 id="time">Time</SubText1>
                {
                  timeValue.map((item: any, index: any) => {
                    return (
                      <FlexBetween key={item.id} className="items-center">
                        <TimePicker
                          style={{ width: "100%", margin: "10px 0px", border: (timeError && (item.startTime === "" || item.startTime === undefined)) ? "1px solid red" : "1px solid #e2e2e2", borderRadius: (timeError && (item.startTime === "" || item.startTime === undefined)) ? "8px" : "8px" }}
                          popupClassName="popup-time-panel"
                          autoFocus={true}
                          use12Hours
                          minuteStep={15}
                          format="h:mm a"
                          onChange={(e: any) => handleMultiTimeChange(e, index)}
                          inputReadOnly={true}
                          className={isDarkMode ? "time-picker-color" : ""}
                        />
                        {
                          repeatDays === "Days" && (
                            <FontAwesomeIcon
                              icon={index === 0 ? faPlus : faMinus}
                              onClick={index === 0 ? addRow : () => removeRow(index)}
                              className="p-[9px] ml-[5px] text-white bg-[#22A45D] rounded-[5px] cursor-pointer"
                            />
                          )}
                      </FlexBetween>
                    )
                  })
                }
              </InitialDiv>
            )}
            {repeatDays === "Weeks"
              ?
              <InitialDiv className="my-2">
                <SubText1 id="repeatOn">Repeat on day(s) of the week :</SubText1>
                {
                  repeatOnTimeError
                    ?
                    <SubText1 className="!text-red-600">Please select at least one day and time.</SubText1>
                    :
                    ""
                }

                <Flex className="items-center ">
                  <input type="checkbox" className="w-7 accent-green-600 cursor-pointer" id="forSunday" checked={repeatOnChecked.SUN} name="SUN" onChange={(e) => handleCheckboxRepeatOn(!repeatOnChecked.SUN, 'SUN')} disabled={!timeValue[0]?.startTime} />
                  <FontAwesomeIcon icon={faCircleS} className="text-[#c3c3c3] mr-2 text-xl" />
                  <Select
                    id="SUN"
                    value={repeatOn?.SUN ? repeatOn?.SUN : ""}
                    size="large"
                    bordered={false}
                    className={isDarkMode ? "time-selector multiple-time-selector" : ""}
                    style={{ width: "100%", textAlign: "left", margin: "10px 0px", border: "1px solid #e2e2e2", borderRadius: "8px" }}
                    allowClear
                    optionFilterProp="label"
                    onChange={(value: any) => handleRepeatOn(value, 'SUN')}
                    options={generateTimeOptions()}
                    mode="multiple"
                    placeholder="Please select time"
                  />
                </Flex>

                <Flex className="items-center">
                  <input type="checkbox" className="w-7 accent-green-600 cursor-pointer" id="forMonday" checked={repeatOnChecked?.MON} name="MON" onChange={(e) => handleCheckboxRepeatOn(!repeatOnChecked.MON, 'MON')} disabled={!timeValue[0]?.startTime} />
                  <FontAwesomeIcon icon={faCircleM} className="text-[#c3c3c3] mr-2 text-xl" />
                  <Select
                    id="MON"
                    value={repeatOn?.MON ? repeatOn?.MON : ""}
                    size="large"
                    bordered={false}
                    style={{ width: "100%", textAlign: "left", margin: "10px 0px", border: "1px solid #e2e2e2", borderRadius: "8px" }}
                    className={isDarkMode ? "time-selector multiple-time-selector" : ""}
                    allowClear
                    optionFilterProp="label"
                    onChange={(value: any) => handleRepeatOn(value, 'MON')}
                    options={generateTimeOptions()}
                    mode="multiple"
                    placeholder="Please select time"
                  />
                </Flex>

                <Flex className="items-center ">
                  <input type="checkbox" className="w-7 accent-green-600 cursor-pointer" id="forTuesday" checked={repeatOnChecked.TUE} name="TUE" onChange={(e) => handleCheckboxRepeatOn(!repeatOnChecked.TUE, 'TUE')} disabled={!timeValue[0]?.startTime} />
                  <FontAwesomeIcon icon={faCircleT} className="text-[#c3c3c3] mr-2 text-xl" />
                  <Select
                    id="TUE"
                    value={repeatOn?.TUE ? repeatOn?.TUE : ""}
                    size="large"
                    bordered={false}
                    style={{ width: "100%", textAlign: "left", margin: "10px 0px", border: "1px solid #e2e2e2", borderRadius: "8px" }}
                    className={isDarkMode ? "time-selector multiple-time-selector" : ""}
                    allowClear
                    optionFilterProp="label"
                    onChange={(value: any) => handleRepeatOn(value, 'TUE')}
                    options={generateTimeOptions()}
                    mode="multiple"
                    placeholder="Please select time"
                  />
                </Flex>

                <Flex className="items-center ">
                  <input type="checkbox" className="w-7 accent-green-600 cursor-pointer" id="forWednesday" checked={repeatOnChecked.WED} name="WED" onChange={(e) => handleCheckboxRepeatOn(!repeatOnChecked.WED, 'WED')} disabled={!timeValue[0]?.startTime} />
                  <FontAwesomeIcon icon={faCircleW} className="text-[#c3c3c3] mr-2 text-xl" />
                  <Select
                    id="WED"
                    value={repeatOn?.WED ? repeatOn?.WED : ""}
                    size="large"
                    bordered={false}
                    style={{ width: "100%", textAlign: "left", margin: "10px 0px", border: "1px solid #e2e2e2", borderRadius: "8px" }}
                    className={isDarkMode ? "time-selector multiple-time-selector" : ""}
                    allowClear
                    optionFilterProp="label"
                    onChange={(value: any) => handleRepeatOn(value, 'WED')}
                    options={generateTimeOptions()}
                    mode="multiple"
                    placeholder="Please select time"
                  />
                </Flex>

                <Flex className="items-center ">
                  <input type="checkbox" className="w-7 accent-green-600 cursor-pointer" id="forThursday" checked={repeatOnChecked.THU} name="THU" onChange={(e) => handleCheckboxRepeatOn(!repeatOnChecked.THU, 'THU')} disabled={!timeValue[0]?.startTime} />
                  <FontAwesomeIcon icon={faCircleT} className="text-[#c3c3c3] mr-2 text-xl" />
                  <Select
                    id="THU"
                    value={repeatOn?.THU ? repeatOn?.THU : ""}
                    size="large"
                    bordered={false}
                    style={{ width: "100%", textAlign: "left", margin: "10px 0px", border: "1px solid #e2e2e2", borderRadius: "8px" }}
                    className={isDarkMode ? "time-selector multiple-time-selector" : ""}
                    allowClear
                    optionFilterProp="label"
                    onChange={(value: any) => handleRepeatOn(value, 'THU')}
                    options={generateTimeOptions()}
                    mode="multiple"
                    placeholder="Please select time"
                  />
                </Flex>

                <Flex className="items-center ">
                  <input type="checkbox" className="w-7 accent-green-600 cursor-pointer" id="forFriday" checked={repeatOnChecked.FRI} name="FRI" onChange={(e) => handleCheckboxRepeatOn(!repeatOnChecked.FRI, 'FRI')} disabled={!timeValue[0]?.startTime} />
                  <FontAwesomeIcon icon={faCircleF} className="text-[#c3c3c3] mr-2 text-xl" />
                  <Select
                    id="FRI"
                    value={repeatOn?.FRI ? repeatOn?.FRI : ""}
                    size="large"
                    bordered={false}
                    style={{ width: "100%", textAlign: "left", margin: "10px 0px", border: "1px solid #e2e2e2", borderRadius: "8px" }}
                    className={isDarkMode ? "time-selector multiple-time-selector" : ""}
                    allowClear
                    optionFilterProp="label"
                    onChange={(value: any) => handleRepeatOn(value, 'FRI')}
                    options={generateTimeOptions()}
                    mode="multiple"
                    placeholder="Please select time"
                  />
                </Flex>

                <Flex className="items-center ">
                  <input type="checkbox" className="w-7 accent-green-600 cursor-pointer" id="forSaturday" checked={repeatOnChecked.SAT} name="SAT" onChange={(e) => handleCheckboxRepeatOn(!repeatOnChecked.SAT, 'SAT')} disabled={!timeValue[0]?.startTime} />
                  <FontAwesomeIcon icon={faCircleS} className="text-[#c3c3c3] mr-2 text-xl" />
                  <Select
                    id="SAT"
                    value={repeatOn?.SAT ? repeatOn?.SAT : ""}
                    size="large"
                    bordered={false}
                    style={{ width: "100%", textAlign: "left", margin: "10px 0px", border: "1px solid #e2e2e2", borderRadius: "8px" }}
                    className={isDarkMode ? "time-selector multiple-time-selector" : ""}
                    allowClear
                    optionFilterProp="label"
                    onChange={(value: any) => handleRepeatOn(value, 'SAT')}
                    options={generateTimeOptions()}
                    mode="multiple"
                    placeholder="Please select time"
                  />
                </Flex>
              </InitialDiv>
              :
              null
            }

            {/************  Custom Calendar *****************/}
            {repeatDays === "Custom" && (
              <>
                {customDateTimeError ? <SubText1 className="!text-red-600 my-3">Please select at least one day and time.</SubText1> : ""}
                <div className="flex justify-center" id="custonCalendar">
                  <Calendar
                    className="green"
                    value={customDateTimeValues}
                    onChange={handleChangeCustom}
                    format="MM/DD/YYYY hh:mm A"
                    minDate={new Date()}
                    multiple
                    plugins={[
                      <TimePickers position="bottom" hideSeconds />,
                      <DatePanel markFocused />
                    ]}
                  />
                </div>
              </>
            )}

            {repeatDays !== "Custom" && (
              <InitialDiv className="my-2">
                <FlexBetween>
                  <RadioButtonText
                    border={{ borderBottom: "none", width: "50%" }}
                    index="endByDate"
                    label="End by Date"
                    checked={selectedEndBy}
                    name="End by Date"
                    value="endByDate"
                    labelStyle={{
                      color: "#868686",
                      fontSize: "15px",
                      fontWeight: "400"
                    }}
                    onSelect={(e: any) => { handleOptionChange(e.target.value) }}
                  />
                  <RadioButtonText
                    border={{ borderBottom: "none", width: "50%" }}
                    index="endByDays"
                    label={`End After Number of ${(repeatDays === "Weeks") ? "Weeks" : "Days"}`}
                    checked={selectedEndBy}
                    name="End by Days"
                    value="endByDays"
                    labelStyle={{
                      color: "#868686",
                      fontSize: "15px",
                      fontWeight: "400"
                    }}
                    onSelect={(e: any) => { handleOptionChange(e.target.value) }}
                  />
                </FlexBetween>
                {
                  selectedEndBy === "endByDate"
                    ?
                    <InitialDiv className="my-2">
                      <SubText1>Choose End Date</SubText1>
                      {/* style={{ border: endByError ? "1px solid red" : "", borderRadius: endByError ? "10px" : "" }} */}
                      <IonDatetimeButton className="my-2.5" datetime="endbydate" style={{ border: endByError ? "1px solid red" : "1px solid #e2e2e2", borderRadius: endByError ? "8px" : "8px" }}></IonDatetimeButton>
                      <IonModal keepContentsMounted={true}>
                        <IonDatetime className={isDarkMode ? "calendar-color" : ""} onIonChange={(e: any) => handleEndByData(e)} name="endByDate" color={isDarkMode ? "dark" : "success"} value={endByDate} min={minEndByDate} showDefaultButtons={true} presentation="date" preferWheel={false} id="endbydate" ></IonDatetime>
                      </IonModal>
                    </InitialDiv>
                    :
                    <InitialDiv className="my-2">
                      <InputText
                        type="number"
                        inputMode="numeric"
                        onChange={(e: any) => handleEndByData(e)}
                        name="endafterDays" value={endAfterDays}
                        placeholder="Enter in Numbers"
                        className={`${endByError ? "!border-red-600" : ""}`} />
                    </InitialDiv>
                }
              </InitialDiv>
            )}
          </InitialDiv>
          {/* </div> */}

          <ToggleWithSubText
            iconLeft={faGift}
            leftIconStyle={{ marginBottom: "auto", marginTop: "9px", marginRight: "9px", fontSize: "20px" }}
            style={{ marginLeft: "0px" }}
            text="Send invitations to friends to sponsor a meal"
            subText="This will share the calendar so your friends can gift meals without guessing what, when, and where to order delivery."
            switchState={switchState}
            handleOnChangeToggle={handleOnChangeToggle}
          />
        </Section>
        {
          (switchState === true || inviteEmails?.length > 0)
            ?
            <>
              <Section>
                {/* <TextHead3 style={{ marginBottom: "10px" }}>Invite From Contacts</TextHead3>
                <ButtonGreen>{switchSentenceCase("primary button", "ADD FROM CONTACTS")}</ButtonGreen>
                <Divider /> */}

                <TextHead3>
                  Invite Friends
                </TextHead3>
                <FontAwesomeIcon icon={faSearch} className="relative top-[32px] left-[10px] z-10" />
                <DebouncedSelect
                  mode="multiple"
                  value={friendValue}
                  placeholder={"Name, phone, or email"}
                  fetchOptions={fetchUserList}
                  onChange={(newValue) => onChange(newValue)}
                  style={{ width: "100%", paddingLeft: "22px", border: "1px solid #7c7c7c69", borderRadius: "10px", marginBottom: "20px", background: `${isDarkMode ? "black" : "#FBFBFB"}` }}
                  allowClear
                  showSearch
                  size="large"
                  bordered={false}
                  className={isDarkMode ? "time-selector multiple-time-selector" : ""}
                  optionLabelProp="title"
                />
                <SubText1 className="!font-bold text-center !text-green-600">OR</SubText1>
                <TextHead3 style={{ margin: "15px 0px" }}>Invite By Email/Phone </TextHead3>
                <InputLabel>Email/Phone</InputLabel>
                <FlexBetween className="items-center">
                  <InputText
                    type="text/number"
                    placeholder="Enter Email or Phone"
                    style={{
                      border: sponsorContactError ? "1px solid red" : "",
                    }}
                    name="email"
                    maxLength={(inputLength) ? 10 : 50}
                    value={sponsorContact}
                    onChange={handleEmailChange}
                    onKeyDown={(e) => handleKeyDown(e, "")}
                  >
                  </InputText>
                  <ButtonGreen className="ml-2 h-10 !w-2/5" onClick={sendInvite}>Add</ButtonGreen>
                </FlexBetween>
              </Section>
              <Section>
                {(inviteEmails?.length > 0) && <InputLabel>Invited Members</InputLabel>}
                {(inviteEmails?.length > 0) ?
                  inviteEmails?.map((item, index: number) =>
                    <FlexBetween className="items-center my-3" key={index}>
                      <TextHead5>{item}</TextHead5>
                      <TextHead5 className="text-red-500 underline cursor-pointer" onClick={() => removeEmail(index, item)}>Remove</TextHead5>
                    </FlexBetween>
                  )
                  :
                  ""
                }
              </Section>
            </>
            :
            null
        }
        <Section>
          <ButtonGreen onClick={onHandleSubmit}>{switchSentenceCase("primary button", "SAVE & CONTINUE")}</ButtonGreen>
        </Section>
      </div >
    </div >
  );
}
