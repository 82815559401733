import { Loader } from "@googlemaps/js-api-loader";

const loader = new Loader({
  apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
  version: "weekly",
  libraries: ["places"],
});

let promise: Promise<any> | null = null;
export default function loadGoogle(): Promise<any> {
  if (promise != null) {
    return promise;
  }

  promise = loader.load();

  return promise;
}

