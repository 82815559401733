import React, { useEffect, useState } from "react";
import Divider from "../../components/widgets/divider/Divider";
import Section from "../../components/widgets/section/Section";
import OneSideTextToggle from "../../components/widgets/buttons/OneSideTextToggle";
import styled from 'styled-components';
import TopHead from "../../components/widgets/head/TopHead";
import { useHistory, useParams } from "react-router";
import { switchSentenceCase, trackPixelEvent } from "../../utils/utilities";
import { faAngleLeft } from "@fortawesome/pro-solid-svg-icons";
import { ButtonGreen } from "../../components/styled/styled";
import { useSelector } from "react-redux";
import { updateMealCategoryFavItem } from "../../services/favourite";
import { useIonViewDidEnter } from "@ionic/react";

const HeadText = styled.div`
font-size: 23px;
font-weight: 500;
text-align: center;
margin-bottom: 30px;
`;

export default function Faves() {
  const history = useHistory();
  const { id } = useParams<any>();
  const { mealCategory, userFaveMealsList } = useSelector((state: any) => state?.getAllFavData);
  const [inputList, setInputList] = useState([]);
  const [selectAllState, setSelectAllState] = useState(false);
  const [mealData, setMealData] = useState({ mealName: "", restaurantName: "" });

  useEffect(() => {
    manageDefaultState();
    extractMealDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useIonViewDidEnter(() => {
    trackPixelEvent('Add fave meal category');
  }, []);

  // Extract Selected Meal Details
  const extractMealDetails = () => {
    try {
      const mealListRedux: [] = userFaveMealsList;
      const mealDetail: any = mealListRedux?.filter((el: any) => el?.id === id)[0];

      const highestPriceProduct = (mealDetail?.attributes?.favItems?.data).reduce(function (prev: any, current: any) {
        return (prev?.attributes?.product?.data?.attributes?.price > current?.attributes?.product?.data?.attributes?.price) ? prev : current
      })
      setMealData({
        mealName: mealDetail?.attributes?.name,
        restaurantName: highestPriceProduct?.attributes?.product?.data?.attributes?.restaurant?.data?.attributes?.name,
      })
    }
    catch (e) {
      console.log(e);
    }
  }

  const manageDefaultState = () => {
    let tempArrayList = []

    mealCategory?.forEach((currentObj: any) => {
      const favMeals = currentObj?.attributes?.favMeals?.data;
      tempArrayList?.push({
        id: currentObj?.id,
        name: currentObj?.attributes?.name,
        selected: (favMeals?.some((e: any) => e?.id === id)) ? true : false,
      })
    })

    setInputList(tempArrayList);
    // Select all button automation
    const filteredTempArrayLength: any[] = tempArrayList?.filter((el: any) => el?.selected === true)
    setSelectAllState((filteredTempArrayLength?.length === tempArrayList?.length))
  }

  function selectAllButton() {
    setSelectAllState(!selectAllState);
    let tempArray = [];
    inputList?.forEach((currentObj: any) => {
      tempArray.push({
        id: currentObj?.id,
        name: currentObj?.name,
        selected: (!selectAllState),
      });
    });

    setInputList(tempArray);
  }

  const manageInputSwitch = (value: boolean, index: number) => {
    const list: any = [...inputList];
    list[index]["selected"] = value;
    setInputList(list);

    // Select all button automation
    const filteredTempArrayLength: [] = list?.filter((el: any) => el?.selected === true)
    setSelectAllState((filteredTempArrayLength?.length === list?.length))
  };

  const GotoHandle = () => {
    history.push('/newmealfave');
  }

  const saveChanges = async () => {
    try {

      // Filter Out All Ids From Object Array
      let tempDataArray = [];
      inputList?.forEach((currentData: any) => {
        if (currentData?.selected === true) {
          tempDataArray.push(currentData?.id);
        }
      });

      const payloadData = {
        data: {
          meal_category: tempDataArray,
        }
      }

      updateMealCategoryFavItem(payloadData, id)
        .then((res) => {
          trackPixelEvent('Update meal category fav item');
          history.push({
            pathname: '/faves',
          })
        })
    }
    catch (e) {
      console.log(e);
    }
  }

  return (
    <div className="screen">

      <TopHead
        title="Add to Fave Meal Category"
        leftTextIcon={faAngleLeft}
        leftIconStyle={{ fontSize: "24px" }}
        goToHandle={GotoHandle}
      />
      <Divider />
      <div className="grow overflow-auto">
        <Section>
          {/* @ {mealData?.restaurantName} */}
          <HeadText >{mealData?.mealName}</HeadText>
          <OneSideTextToggle leftText={"My Category"} style={{ fontSize: "18", fontWeight: "500" }} beforeBtnText="Select All" id="faveMealsState" name="faveMealsState" switchState={selectAllState} handleOnChangeToggle={selectAllButton} />
          <Divider />

          {
            mealCategory.map((favMeal: any, index: any) => {
              return (
                <React.Fragment key={index}>
                  <OneSideTextToggle
                    leftText={favMeal?.attributes?.defaultMeal === true ? favMeal?.attributes?.name + ` (Default)` : favMeal?.attributes?.name}
                    style={{ paddingLeft: "15px" }}
                    name={favMeal?.attributes?.name}
                    id={favMeal?.id}
                    switchState={inputList[index]?.selected}
                    handleOnChangeToggle={() => {
                      manageInputSwitch(!inputList[index]?.selected, index);
                    }}
                  />
                </React.Fragment>
              )
            }
            )
          }
          <ButtonGreen onClick={saveChanges}>{switchSentenceCase("primary button", "SAVE & CONTINUE")}</ButtonGreen>
        </Section>
        <Divider />
      </div>
    </div >
  );
}

