import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import styled from 'styled-components';
import useDarkMode from "../../hooks/darkmode/DarkMode";

const Tab = styled.div<{ isDarkMode: boolean }>`
 overflow: hidden;
  border: none;
  border-radius: 30px;
  background-color:${props => (props?.isDarkMode ? '#2e2e2e' : '#f1f1f1')};
  border: 1px solid white;
  display: flex;
  justify-content: space-around;
  margin: 20px 0px;
`;

const Tablinks = styled.button<{ active?: boolean; isDarkMode: boolean; }>`
  background-color: inherit;
  float: left;
  border: 1px solid #dbdbdb;
  color:${props => (props?.isDarkMode ? 'white' : '#949494')};
  border-radius: 999px;
  outline: none;
  width: 60px;
  height: 60px;
  cursor: pointer;
  transition: 0.3s;
  font-size: 15px;
  font-weight: 500px;
  &:hover {
    background-color: #ddd;
  }

  ${({ active }) =>
    active &&
    ` background: #22A45D;
   color: white;
   border-radius:999px;
   width: 60px;
   height: 60px;
   &:hover {
    background-color: #22A45D;
  }
  `}
`;




export default function TabsForFilterPrice(props: any) {
  const isDarkMode = useDarkMode();
  const [active, setActive] = useState(props?.types[0]?.value);

  const changeValue = (type: any) => {
    setActive(type);
    props?.setFilterType(type);
  }

  return (
    <>
      <Tab isDarkMode={isDarkMode}>
        {props?.types.map((type: any) => (
          <Tablinks
            key={type?.name}
            active={active === type?.value}
            onClick={() => changeValue(type?.value)}
            isDarkMode={isDarkMode}
          >
            <FontAwesomeIcon icon={type?.icon} /> {type?.name}
          </Tablinks>
        ))}
      </Tab>
      <p />
      {/* <p> Your payment selection: {active} </p> */}
    </>
  );
};


