import styled from 'styled-components';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


const Trans = styled.div`
    font-size: 17px;
    cursor: pointer;
    font-weight: 500;
    display: flex;
    justify-content: space-between;
`;

const SubText = styled.h2`
  text-align: left;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #868686;
  margin-bottom: 10px;
`;

const Flex = styled.div`
display: flex;
align-items: center;
justify-content: space-between;
`;

const Block = styled.div`
    margin-left: 20px;
    margin-right: auto;
`;

export default function CardRibbon(props: any) {

  return (
    <Flex style={props?.styleDiv} onClick={props?.handleManage}>
      <FontAwesomeIcon icon={props?.leftIcon} style={props.leftIconStyle} />
      <Block>
        <Trans>{props?.title}</Trans>
        <SubText>{props?.subText}</SubText>
      </Block>
      <FontAwesomeIcon icon={props?.rightIcon} style={props.rightIconStyle} />
    </Flex>
  );

}
