import React from "react";
import Spinner from "../spinner/Spinner";

export default function ModalSpinner(props) {
  return (
    <div className="fullscreen faded-glass">
      <div style={props?.style ? props?.style : { textAlign: "center", marginTop: 120 }}>
        <div>
          <Spinner />
          <p>{props?.loadingText ? props?.loadingText : "Loading..."}</p>
        </div>
      </div>
    </div>
  );
}

// herkos: wL5mGiZTlqq91LJnajV0i
// parklawn: 4SqgaXqIFi_Fz83gVLBhy
