import React, { useEffect, useState } from "react";
import Divider from "../../components/widgets/divider/Divider";
import Section from "../../components/widgets/section/Section";
import OneSideTextToggle from "../../components/widgets/buttons/OneSideTextToggle";
import styled from "styled-components";
import BothSideTextToggle from "../../components/widgets/buttons/BothSideTextToggle";
import TopHead from "../../components/widgets/head/TopHead";
import { useHistory } from "react-router";
import { Astk, ButtonGreen, Flex, FlexBetween, InitialDiv, ItemCount, SubText1, TextArea1, TextBox2, TextHead1, TextHead3, TextHead4, TextHead5 } from "../../components/styled/styled";
import { faveMealFormatter, increasePriceByQuantity, priceFormater, properNameFormate, sumOfMealItemInCart, switchSentenceCase, displayName, priceFormatterUsd, expensiveItem, textFormatter, trackPixelEvent, maxCharacterLimit } from "../../utils/utilities";
import { faAngleRight, faAngleDown } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/pro-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { changeQuantity, changeQuantityOfAddFavMealItem, removeFavsMealItem, removeSingleCartItem, addMealId, setReplaceItemData, removeMenuDataList } from "../restaurant/redux/menuItemActionCreatior";
import { createNewFavItem, getFavesCount, getMealCategory, updateExistingFavItem } from "../../services/favourite";
import { getUserInfo } from "../../services/user";
import { setUserFaveMealList, setUserMealCategory } from "./redux/favouriteActionCreator";
import ModalSpinner from "../../components/widgets/modalspinner/ModalSpinner";
import ModalProductDetails from "../../components/widgets/modals/ModalProductDetails";
import ToolTip from "../../components/widgets/alerts/tooltip";
import AccordionCheckoutCustomization from "../../components/widgets/accordian/AccordionCheckoutCustomization";
import CheckoutProductCustomization from "../orderFood/CheckoutProductCustomization";
import QuantitySelectorGlobal from "../../components/widgets/buttons/QuantitySelectorGlobal";
import { useIonAlert, useIonViewDidEnter } from "@ionic/react";
import Spinner from "../../components/widgets/spinner/Spinner";

const BlockContainer = styled.div`
  display: block;
  width: 100%;
`;

const Container = styled.div`
  margin: 35px 0px 35px 5px;
`;

const ToggleSubText = styled.h2`
  text-align: left;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #868686;
  margin-top: -15px;
  margin-left: 10px;
`;

const TransDiv = styled.div`
  padding: 10px 0px 20px 8px;
  font-size: 17px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
`;

export default function Faves() {

  const history = useHistory();
  const userData = getUserInfo();
  const [presentAlert] = useIonAlert();
  const showAlert = (header: string, message: string, btnMessage: string) => {
    presentAlert({
      header: header,
      message: message,
      buttons: [btnMessage],
    });
  };
  const limit = 10;
  const { addfavMealItem, replaceFavItemData } = useSelector((state: any) => state.getRestaurantsMenuLists);
  const [switchState, setSwitchState] = useState(replaceFavItemData?.restaurantId ? false : true);
  const [faveMealsState, setFaveMealsState] = useState(true);
  const [faveMealNameError, setFaveMealNameError] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [faveMealNullItemError, setFaveMealNullItemError] = useState(false);
  const [existingMealCategoryError, setExistingMealCategoryError] = useState(false);
  const [newMealCategoryError, setNewMealCategoryError] = useState(false);
  const [inProcess, setInProcess] = useState(false);
  const [showPromoInput, setShowPromoInput] = useState(true);
  const [inputList, setInputList] = useState([{ id: "", name: "", selected: false }]);
  const [inputExistList, setInputExistList] = useState([{ id: "", name: "", selected: false, notes: "" }]);
  const [showProductDetailsModal, setShowProductDetailsModal] = useState(false);
  const { userFaveMealsList, mealCategory } = useSelector((state: any) => state?.getAllFavData);
  const { userDeliveryAddress } = useSelector((state: any) => state.userAddresses);
  const [isLoading, setIsLoading] = useState(false);
  const [skip, setSkip] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [notes, setNotes] = useState("");
  const item = expensiveItem(addfavMealItem);

  const { searchedRestaurants } = useSelector((state: any) => state.getUserRestaurantsLists);
  const [faveMealName, setFaveMealName] = useState(`${textFormatter(item?.name)} Fave @ ${addfavMealItem[0]?.restaurantName ? addfavMealItem[0]?.restaurantName : "Restaurant"}`);
  const storeId = searchedRestaurants?.id || "";

  useEffect(() => {
    CategoryListData();
    // MealListData();
    setIsLoading(true);
    callData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useIonViewDidEnter(() => {
    trackPixelEvent('Fave checkout');
  }, []);

  const callData = async (skippingValue = 0) => {
    if (userDeliveryAddress?.city === undefined || userDeliveryAddress?.city === "") {
      setIsLoading(false);
      return false;
    }
    try {
      const response = await getFavesCount({
        id: userData?.id,
        type: replaceFavItemData?.fav_meal_id ? "Both" : "Fav",
        skip: skippingValue,
        limit: limit,
        storeId: replaceFavItemData?.restaurantId ? replaceFavItemData?.restaurantId : storeId
      });
      trackPixelEvent('Get user fave meal');
      setTotalItems(response?.data?.data);
      let tempData = response?.data?.data;
      let tempArray = [];

      if (skippingValue > 0) {
        setUserFaveMealList([...userFaveMealsList, ...tempData]);
        setIsLoading(false);

        tempData?.forEach((currentObj: any) => {
          tempArray.push({
            id: currentObj?.id,
            name: currentObj?.attributes?.name,
            selected: replaceFavItemData?.fav_meal_id === currentObj?.id,
            notes: currentObj?.attributes?.drop_off_notes
          });
        });
        setInputExistList([...inputExistList, ...tempArray]);
      } else {
        setUserFaveMealList(tempData);
        setIsLoading(false);
        tempData?.forEach((currentObj: any) => {
          tempArray.push({
            id: currentObj?.id,
            name: currentObj?.attributes?.name,
            selected: replaceFavItemData?.fav_meal_id === currentObj?.id,
            notes: currentObj?.attributes?.drop_off_notes
          });
        });

        setInputExistList(tempArray);
      }
      let query = {
        suggested: "All"
      }
      const cat_response = await getMealCategory(userData?.id, query);
      setUserMealCategory(cat_response?.data?.data);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  };

  const handleKeyDown = (e: any) => {
    if (notes.length >= 300) {
      if (e.key === " " || e.keyCode === 32) {
        return showAlert("", "You exceed the maximum limit.", "OK");
      }
    }
  };

  const handleChangeNotes = (val: string) => {
    setNotes(val.substring(0, 300));
    if (String(val).trim().length >= 300) {
      return showAlert("", "You exceed the maximum limit.", "OK");
    }
  }

  const showMore = () => {
    setIsLoading(true);
    const skippingValue = skip + limit;
    setSkip(skippingValue);
    callData(skippingValue);
  };

  const CategoryListData = () => {
    let tempArray = [];
    const category: [] = mealCategory;
    category?.forEach((currentObj: any) => {
      if (currentObj?.attributes?.defaultMeal)
        tempArray.push({ id: currentObj?.id, name: currentObj?.attributes?.name, selected: true })
      else
        tempArray.push({ id: currentObj?.id, name: currentObj?.attributes?.name, selected: false })
    })
    setInputList(tempArray)
  }

  // const MealListData = () => {
  //   let mealTtempArray = [];
  //   const mealCategory: [] = userFaveMealsList;
  //   mealCategory?.forEach((currentExistObj: any) => {
  //     mealTtempArray.push({ id: currentExistObj?.id, name: currentExistObj?.attributes?.name, selected: false })
  //   })
  //   setInputExistList(mealTtempArray)
  // }
  function handleOnChangeToggle() { setSwitchState(switchState === false); }
  const GotoHandle = () => { history.push("/restaurant"); };
  const dispatch = useDispatch();


  const handleChangeFaveName = (value: any) => {
    setFaveMealName(String(value));
    if (String(value).trim()?.length === 0) { setFaveMealNameError(true); }
    else { setFaveMealNameError(false); }
  }

  const onAddNewMealName = async () => {
    try {
      if (inProcess) { return false; }
      // ------For Getting Each True Checked Data
      let tempCategArrayData = [];
      inputList?.forEach((categoryEachData: any) => {
        if (categoryEachData?.selected === true) {
          tempCategArrayData.push(categoryEachData?.id)
        }
      });

      if (tempCategArrayData?.length === 0) {
        // setNewMealCategoryError(true);
        return showAlert("", "Please select Fave Meal category.", "OK");
      }
      else { setNewMealCategoryError(false); }

      if (faveMealName.trim()?.length === 0) {
        document.getElementById("mealName").scrollIntoView();
        setFaveMealNameError(true);
        return false;
      }
      else { setFaveMealNameError(false); }

      // Calculating Total Price
      const total_Price = (Number(priceFormater(sumOfMealItemInCart(addfavMealItem)).slice(1)));
      setInProcess(true);
      const mealData = {
        name: faveMealName,
        favItems: faveMealFormatter(addfavMealItem),
        meal_category: tempCategArrayData,
        public_meal: faveMealsState,
        total_price: total_Price,
        notes: notes,
        address: userDeliveryAddress?.id
      };

      // ------For Checking Null Data Validation
      if (mealData?.favItems?.length === 0) {
        // setFaveMealNullItemError(true);
        setInProcess(false);
        return showAlert("", "Please add atleast one item.", "OK");
      } else {
        await createNewFavItem(mealData)
          .then((res) => {
            dispatch(removeFavsMealItem(storeId) as any);
            removeMenuDataList(storeId);
            localStorage.removeItem("RestaurantData");
            // clearRestaurantList();
            trackPixelEvent('Fave saved');
            history.push("/faves")
            setInProcess(false);
          })
      }
    }
    catch (e) {
      console.log(e);
      setInProcess(false);
    }
  };

  const handleSwitch = (value: boolean, index: number) => {
    const list: any = [...inputList];
    list[index]["selected"] = value;
    setInputList(list);
    if (list.filter(function (el: any) { return el?.selected === true })) {
      setNewMealCategoryError(false);
    }
  }

  const handleSwitchExisting = (value: boolean, index: number) => {
    const list: any = [...inputExistList];
    list[index]['selected'] = value;
    setInputExistList(list);
    if (list.filter(function (el: any) { return el?.selected === true })) {
      setExistingMealCategoryError(false);
    }
  }

  // -------------For Update Existing Section -- OnToggle--------------------------
  const onUpdateExistingMealName = async () => {
    try {
      const total_Price = (Number(priceFormater(sumOfMealItemInCart(addfavMealItem)).slice(1)));
      if (inProcess) { return false; }
      let tempExistArrayData = [];
      inputExistList?.forEach((mealEachData: any) => {
        if (mealEachData?.selected === true) {
          tempExistArrayData.push(mealEachData?.id)
        }
      });
      if (tempExistArrayData?.length === 0) {
        // setExistingMealCategoryError(true);
        return showAlert("", "Please select Fave Meal.", "OK");
      }
      else { setExistingMealCategoryError(false); }
      setInProcess(true);
      const mealData = {
        favItems: faveMealFormatter(addfavMealItem),
        favMeals: tempExistArrayData,
        total_price: total_Price,
        replaceItemId: replaceFavItemData?.favItem_id ? replaceFavItemData?.favItem_id : ""
      };

      if (mealData?.favItems?.length === 0) {
        // setFaveMealNullItemError(true);
        setInProcess(false);
        return showAlert("", "Please add atleast one item.", "OK");
      } else {
        await updateExistingFavItem(mealData)
          .then((res) => {
            dispatch(removeFavsMealItem(storeId) as any);
            localStorage.removeItem("RestaurantData");
            trackPixelEvent('Existing Fave updated');
            if (replaceFavItemData?.redirect_link) {
              history.push(replaceFavItemData?.redirect_link);
              setReplaceItemData({});
            }
            else {
              history.push("/faves")
            }
            setInProcess(false);
          })
      }
    }
    catch (e) {
      console.log(e);
      setInProcess(false);
    }
  }

  const onPushLocation = (id: any, index: number) => {
    dispatch(addMealId(id, index) as any);
    setShowProductDetailsModal(true);
  };

  const handleCloseModal = (value: any) => {
    setShowProductDetailsModal(value);
  };

  function isAnyItemSelected(array: any) {
    // Iterate through the array
    for (const obj of array) {
      // Check if the 'is_selected' key is true
      if (obj.hasOwnProperty('is_selected') && obj.is_selected === true) {
        // If true, return true
        return true;
      }
      // If the object has an 'options' array, recursively check it
      if (obj.hasOwnProperty('options') && Array.isArray(obj.options)) {
        if (isAnyItemSelected(obj.options)) {
          return true;
        }
      }
    }
    // If no 'is_selected' is true, return false
    return false;
  }

  return (
    <div className="screen">
      <TopHead
        title="New Meal Fave"
        leftTextIcon={faAngleLeft}
        leftIconStyle={{ fontSize: "24px" }}
        goto={GotoHandle}
      />
      <Divider />
      <div className="grow overflow-auto">
        <Section>
          <BothSideTextToggle
            leftText="Add to Existing Fave"
            rightText="Add to New Fave"
            switchState={switchState}
            handleOnChangeToggle={handleOnChangeToggle}
          />
          {
            (switchState === true)
              ?
              (
                <>
                  <TextHead1 id="mealName">New Meal Name <Astk>*</Astk></TextHead1>
                  <TextBox2
                    style={{ marginTop: "10px", border: (faveMealNameError ? "1px solid red" : "") }}
                    placeholder={`${displayName()} Fave @ ${addfavMealItem[0]?.restaurantName ? addfavMealItem[0]?.restaurantName : "Restaurant"}`}
                    value={faveMealName}
                    onChange={(event) => handleChangeFaveName(event.target.value)}
                  />

                  <TextHead1 className="mt-4">Fave Items in Meal</TextHead1>
                  <Container>
                    {(addfavMealItem?.length > 0)
                      &&
                      addfavMealItem?.map((list: any, index: any) => {
                        return (
                          <React.Fragment key={index}>
                            <Flex>
                              <ItemCount>{index + 1}</ItemCount>
                              <BlockContainer>
                                <FlexBetween>
                                  <Flex>
                                    <TextHead4
                                      onClick={() => onPushLocation(list?.product_id, index)}
                                      className="underline cursor-pointer"
                                    >{switchSentenceCase("head text", properNameFormate(list?.name))}
                                    </TextHead4>
                                    {(list?.instructions !== "") &&
                                      <ToolTip instructions={list?.instructions} />
                                    }
                                  </Flex>
                                  <TextHead5
                                    className="text-green-600 underline !font-medium cursor-pointer"
                                    onClick={() => onPushLocation(list?.product_id, index)}
                                  >
                                    EDIT
                                  </TextHead5>
                                </FlexBetween>
                                <InitialDiv>
                                  {
                                    (list?.customizationList.length > 0)
                                    // && list?.customizationList?.[0]?.options?.[0]?.is_selected === true
                                    && (isAnyItemSelected(list?.customizationList))
                                    &&
                                    <>
                                      <AccordionCheckoutCustomization
                                        title={"Selected Customizations"}
                                        titleSize={"16px"}
                                      >
                                        <div className="block">
                                          <CheckoutProductCustomization customizations={list?.customizationList} />
                                        </div>
                                      </AccordionCheckoutCustomization>
                                    </>
                                  }
                                </InitialDiv>

                                <div className="flex justify-between items-center">
                                  <QuantitySelectorGlobal
                                    selected_quantity={list?.quantity}
                                    increaseQuantity={() => {
                                      dispatch(
                                        changeQuantity(
                                          storeId,
                                          list?.product_id,
                                          Number(list?.quantity) + 1
                                        ) as any
                                      );

                                      dispatch(
                                        changeQuantityOfAddFavMealItem(
                                          list?.product_id,
                                          Number(list?.quantity) + 1,
                                          index
                                        ) as any
                                      );
                                    }}
                                    decreaseQuantity={() => {
                                      if (Number(list?.quantity) > 1) {
                                        dispatch(
                                          changeQuantity(
                                            storeId,
                                            list?.product_id,
                                            Number(list?.quantity) - 1
                                          ) as any
                                        );
                                        dispatch(
                                          changeQuantityOfAddFavMealItem(
                                            list?.product_id,
                                            Number(list?.quantity) - 1,
                                            index
                                          ) as any
                                        );
                                      }
                                      else {
                                        dispatch(
                                          changeQuantity(
                                            storeId,
                                            list?.product_id,
                                            Number(0)
                                          ) as any
                                        );
                                        dispatch(
                                          removeSingleCartItem(
                                            list?.product_id,
                                            index
                                          ) as any
                                        );
                                      }
                                    }}
                                  />
                                  <h4 className="font-semibold">
                                    {priceFormater(increasePriceByQuantity(list?.price, list?.quantity))}
                                    {/*itemPlusOptionPrice(list?.price, list?.inputList), list?.quantity) */}
                                  </h4>
                                </div>
                              </BlockContainer>
                            </Flex>
                            <hr className="mt-2 mb-2" />
                          </React.Fragment >
                        );
                      }
                      )
                    }
                    {faveMealNullItemError
                      ?
                      <TextHead5 style={{ color: "red" }}>
                        Please, Add atleast one item !
                      </TextHead5>
                      :
                      null
                    }
                    <TextHead5
                      className="text-green-600 underline italic cursor-pointer"
                      onClick={() => history.push(`/restaurant/${storeId}`)}
                    >
                      Add Another Item...
                    </TextHead5>
                    <FlexBetween>
                      {/* className="mt-6 !mx-[5px] !px-[5px]" */}
                      <TextHead5 className="!font-medium">Subtotal</TextHead5>
                      <TextHead5 className="!font-medium !whitespace-nowrap">
                        {(priceFormatterUsd(sumOfMealItemInCart(addfavMealItem)))}
                      </TextHead5>
                    </FlexBetween>
                  </Container>
                  <Divider />

                  {(mealCategory?.length > 0)
                    &&
                    <>
                      <TransDiv onClick={() => setShowPromoInput((prev) => !prev)}>
                        Add to Fave Meal Category
                        <FontAwesomeIcon
                          icon={showPromoInput ? faAngleRight : faAngleDown}
                          style={{ float: "right" }}
                        />
                      </TransDiv>
                      {
                        showPromoInput === false
                          ?
                          (
                            mealCategory?.map((favMeal: any, index: any) => {
                              return (
                                <React.Fragment key={index}>
                                  <OneSideTextToggle
                                    leftText={favMeal?.attributes?.defaultMeal === true ? favMeal?.attributes?.name + ` (Default)` : favMeal?.attributes?.name}
                                    style={{ paddingLeft: "15px" }}
                                    name={favMeal?.attributes?.name}
                                    id={favMeal?.id}
                                    switchState={inputList[index]?.selected}
                                    handleOnChangeToggle={() => { handleSwitch((!(inputList[index]?.selected)), index) }}
                                  />
                                </React.Fragment>
                              )
                            },
                            )
                          )
                          :
                          null
                      }
                      {newMealCategoryError &&
                        <>
                          <TextHead5 className="!font-medium text-center" style={{ color: "red" }}>Please Select Category !</TextHead5>
                          <Divider />
                        </>
                      }
                    </>
                  }
                  <InitialDiv className="mb-2">

                    <OneSideTextToggle
                      leftText="Visible to Others"
                      style={{ fontWeight: "500" }}
                      switchState={faveMealsState}
                      handleOnChangeToggle={() => setFaveMealsState(prev => !prev)}
                    />
                    <ToggleSubText>
                      Other people can see this meal to add to meal schedules for you.
                      If this isn’t selected, no one else will know this meal is a
                      fave.
                    </ToggleSubText>
                  </InitialDiv>
                  <Divider />

                  {/********************  Notes Section ******************************/}
                  <InitialDiv className="my-4 mx-2">
                    <TextHead3 className="">Drop Off Notes</TextHead3>
                    <SubText1 className="text-right mt-1">
                      {notes?.length}/{maxCharacterLimit}
                    </SubText1>
                    <TextArea1
                      className="!mt-0"
                      placeholder=""
                      name="description"
                      value={notes}
                      onChange={(event: any) => handleChangeNotes(event.target.value)}
                      onKeyDown={(e) => handleKeyDown(e)}
                    // maxLength={maxCharacterLimit}
                    />
                  </InitialDiv>

                  <ButtonGreen id="saveNewMeal" onClick={onAddNewMealName} style={{ margin: "10px 0px" }}>
                    {switchSentenceCase("primary button", "SAVE & RETURN TO FAVES")}
                  </ButtonGreen>
                  {/* <ButtonLimeGreen
                    style={{ margin: "10px 0px" }}
                  // onClick={onAddNewMealName}
                  >
                    {switchSentenceCase("primary button", "SAVE & ORDER NOW")}
                  </ButtonLimeGreen>
                  <ButtonGreen style={{ margin: "10px 0px" }}>
                    {switchSentenceCase("primary button", "SAVE & ADD TO SCHEDULE")}
                  </ButtonGreen> */}
                </>
              )
              :
              (
                <>
                  <TextHead1>Fave Items to Add</TextHead1>
                  <Container>
                    {(addfavMealItem?.length > 0)
                      &&
                      addfavMealItem?.map((list: any, index: any) => {
                        return (
                          <Flex key={index} className="mb-2">
                            <ItemCount>{index + 1}</ItemCount>
                            <BlockContainer>
                              <FlexBetween>
                                <Flex>
                                  <TextHead4
                                    onClick={() => onPushLocation(list?.product_id, index)}
                                    className="underline">{switchSentenceCase("head text", properNameFormate(list?.name))}</TextHead4>
                                  {(list?.instructions !== "") &&
                                    <ToolTip instructions={list?.instructions} />
                                  }
                                </Flex>
                                <TextHead5
                                  className="text-green-600 underline !font-medium cursor-pointer"
                                  onClick={() => onPushLocation(list?.product_id, index)}
                                >
                                  EDIT
                                </TextHead5>
                              </FlexBetween>
                              <InitialDiv>
                                {
                                  list?.customizationList.length > 0
                                  && (isAnyItemSelected(list?.customizationList))
                                  // list?.customizationList?.[0]?.options?.[0]?.is_selected === true
                                  &&
                                  // <TextHead5
                                  //   onClick={() => onPushLocation(list?.product_id)}
                                  //   style={{ color: "#49c077", fontWeight: "500" }}>
                                  //   Selected Customizations
                                  // </TextHead5>
                                  // :
                                  // ""
                                  <AccordionCheckoutCustomization
                                    title={"Selected Customizations"}
                                    titleSize={"16px"}
                                  >
                                    <div className="block">
                                      <CheckoutProductCustomization customizations={list?.customizationList} />
                                    </div>
                                  </AccordionCheckoutCustomization>
                                }

                                {/* <div className="block">
                                  {selectedCustomisedData?.map((optionObj: any, optionIndex: any) => (
                                    <React.Fragment key={optionIndex}>
                                      <CustomizationTag>{(optionIndex + 1)}. {optionObj?.objData?.name}</CustomizationTag >
                                    </React.Fragment>
                                  )
                                  )}
                                </div> */}
                              </InitialDiv>
                              <div className="flex justify-between items-center">
                                <QuantitySelectorGlobal
                                  selected_quantity={list?.quantity}
                                  increaseQuantity={() => {

                                    dispatch(
                                      changeQuantity(
                                        storeId,
                                        list?.product_id,
                                        Number(list?.quantity) + 1
                                      ) as any
                                    );

                                    dispatch(
                                      changeQuantityOfAddFavMealItem(
                                        list?.product_id,
                                        Number(list?.quantity) + 1,
                                        index
                                      ) as any
                                    );

                                  }}
                                  decreaseQuantity={() => {
                                    if (Number(list?.quantity) > 1) {

                                      dispatch(
                                        changeQuantity(
                                          storeId,
                                          list?.product_id,
                                          Number(list?.quantity) - 1
                                        ) as any
                                      );

                                      dispatch(
                                        changeQuantityOfAddFavMealItem(
                                          list?.product_id,
                                          Number(list?.quantity) - 1,
                                          index
                                        ) as any
                                      );

                                    } else {

                                      dispatch(
                                        changeQuantity(
                                          storeId,
                                          list?.product_id,
                                          Number(0)
                                        ) as any
                                      );

                                      dispatch(
                                        removeSingleCartItem(
                                          list?.product_id,
                                          index
                                        ) as any
                                      );
                                    }
                                  }}
                                />
                              </div>
                            </BlockContainer>
                          </Flex>
                        );
                      })}
                    {
                      faveMealNullItemError
                        ?
                        <TextHead5 style={{ color: "red" }}>
                          Please, Add atleast one item !
                        </TextHead5>
                        :
                        null
                    }
                    <TextHead5
                      className="text-green-600 underline italic cursor-pointer"
                      onClick={() => history.push(`/restaurant/${storeId}`)}
                    >
                      Add Another Item...
                    </TextHead5>

                    <FlexBetween className="mt-6 !mx-[5px] !px-[5px]">
                      <TextHead5 className="!font-medium">Subtotal</TextHead5>
                      <TextHead5 className="!font-medium !whitespace-nowrap">
                        {priceFormatterUsd(sumOfMealItemInCart(addfavMealItem))}
                      </TextHead5>
                    </FlexBetween>
                  </Container>

                  <Divider />

                  {userFaveMealsList.length === 0
                    ?
                    <TextHead5 className="!font-medium text-center" >No Existing Fave Meal Found, Please Create One First !</TextHead5>
                    :
                    <>
                      <FlexBetween className="my-2">
                        <TextHead4>
                          Add to Fave Meals
                        </TextHead4>
                        <TextHead4 className="float-right">
                          <FontAwesomeIcon
                            icon={faAngleDown}
                            style={{ marginLeft: "4px" }}
                          />
                        </TextHead4>
                      </FlexBetween>

                      {inputExistList.map((favMealData: any, index: any) => {
                        return (
                          <React.Fragment key={index}>
                            <OneSideTextToggle
                              leftText={favMealData?.name}
                              style={{ paddingLeft: "15px" }}
                              name={favMealData?.name}
                              id={favMealData?.id}
                              switchState={favMealData?.selected}
                              disabled={favMealData?.id === replaceFavItemData?.fav_meal_id}
                              handleOnChangeToggle={() => handleSwitchExisting((!(inputExistList[index]?.selected)), index)}
                            />
                          </React.Fragment>
                        )
                      })
                      }

                      {totalItems > limit &&
                        (totalItems !== userFaveMealsList?.length ? (
                          <div
                            className="text-green-600 text-center cursor-pointer mt-4 font-semibold"
                            onClick={() => {
                              showMore();
                            }}
                          >
                            {!isLoading ? (
                              <span>See More</span>
                            ) : (
                              <span>
                                <Spinner />
                              </span>
                            )}
                          </div>
                        ) : (
                          <div className="text-green-600 text-center mt-4 font-semibold">
                            No More Fave Meals
                          </div>
                        ))}
                      {existingMealCategoryError
                        &&
                        <>
                          <TextHead5 className="!font-medium text-center" style={{ color: "red" }}>Please Select Fave Meal !</TextHead5>
                          <Divider />
                        </>
                      }
                    </>
                  }
                  <Divider />
                  <ButtonGreen onClick={onUpdateExistingMealName} className={userFaveMealsList.length === 0 ? "pointer-event-none !border !border-solid !border-[#5cb383] !bg-[#5cb383]" : "my-[10px]"}>
                    {switchSentenceCase("primary button", "SAVE & RETURN TO FAVES")}
                  </ButtonGreen>
                </>
              )
          }
        </Section>
        {
          inProcess === true
            ?
            <ModalSpinner />
            :
            null
        }
        <Divider />
      </div>

      {/* *********************Modal Add New Address****************************** */}
      {showProductDetailsModal && < ModalProductDetails showProductDetailsModal={showProductDetailsModal} setShowProductDetailsModal={handleCloseModal} pageName="FromFavesPage" btnText="Update Changes" />}
    </div >
  );
}
