import { faAngleLeft, faCameraViewfinder } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useIonViewDidEnter } from "@ionic/react";
import { useState } from "react";
import { useHistory } from "react-router";
import cardPng from '../../components/assets/card.png';
import { ButtonGreen, ButtonInitial, InputWithIcon, SubText1, TextHead1 } from "../../components/styled/styled";
import Divider from "../../components/widgets/divider/Divider";
import TopHead from "../../components/widgets/head/TopHead";
import Section from "../../components/widgets/section/Section";
import { addCardApi } from "../../services/paymentCards";
import { switchSentenceCase, trackStandardPixelEvent } from "../../utils/utilities";



export default function AddPaymentCard() {
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState("");
    const [cardData, setCardData] = useState({ number: "", exp_date: "", cvc: "", })
    const [cardDataError, setCardDataError] = useState({ number: "", exp_date: "", cvc: "", })


    useIonViewDidEnter(() => {
        trackStandardPixelEvent('AddPaymentInfo', {
            content_category: null,
            content_ids: null,
            contents: null,
            currency: null,
            value: null
        })
    }, []);


    const handleInput = (e: any) => {
        const name = e.target.name;
        const value = e.target.value;

        if (name === "number") {
            setCardData((prevValue) => ({ ...prevValue, number: formatCreditCardNumber(value) }))

            if (String(value).trim().length > 0) { setCardDataError((prevValue) => ({ ...prevValue, number: "" })) }
            else { setCardDataError((prevValue) => ({ ...prevValue, number: "required" })) }

        }
        else if (name === "exp_date") {
            setCardData((prevValue) => ({ ...prevValue, exp_date: formatDate(value) }))

            if (String(value).trim().length > 0) { setCardDataError((prevValue) => ({ ...prevValue, exp_date: "" })) }
            else { setCardDataError((prevValue) => ({ ...prevValue, exp_date: "required" })) }

        }
        else if (name === "email") {
            setEmail(value)

            if (String(value).trim().length > 0) { setEmailError("") }
            else { setEmailError("required") }

        }
        else {
            setCardData((prevValue) => ({ ...prevValue, [name]: (value).replace(/\D/g, '') }))

            if (String(value).trim().length > 0) { setCardDataError((prevValue) => ({ ...prevValue, cvc: "" })) }
            else { setCardDataError((prevValue) => ({ ...prevValue, cvc: "required" })) }
        }
    }

    function formatCreditCardNumber(input: string): string {
        // Remove any non-numeric characters
        const numericInput = input.replace(/\D/g, '');

        // Split the numeric input into groups of 4 digits each
        const groups = numericInput.match(/.{1,4}/g);

        // Join the groups with a space separator and return the formatted string
        if (groups) {
            const formattedInput = groups.join(' ');
            return formattedInput;
        } else {
            return numericInput;
        }
    }

    function formatDate(input: any) {
        // Remove any non-numeric characters
        const numericInput = input.replace(/\D/g, '');

        // Add the forward slash if the input value is 2 characters long
        if (numericInput.length > 2) {
            const _month = (Number(numericInput.slice(0, 2)) <= 12) ? numericInput.slice(0, 2) : 12;
            const formattedInput = _month + '/' + numericInput.slice(2, 4);
            return formattedInput;
        } else {
            return numericInput;
        }
    }

    const handleSubmit = async () => {
        return;
        try {

            const { number, exp_date, cvc } = cardData;

            if (number?.length === 0) { setCardDataError((prevValue) => ({ ...prevValue, number: "required" })) }
            else { setCardDataError((prevValue) => ({ ...prevValue, number: "" })) }

            if (exp_date?.length === 0) { setCardDataError((prevValue) => ({ ...prevValue, exp_date: "required" })) }
            else if (exp_date?.length < 5) { setCardDataError((prevValue) => ({ ...prevValue, exp_date: "Invalid date." })) }
            else { setCardDataError((prevValue) => ({ ...prevValue, exp_date: "" })) }

            if (cvc?.length === 0) { setCardDataError((prevValue) => ({ ...prevValue, cvc: "required" })) }
            else if (cvc?.length < 3) { setCardDataError((prevValue) => ({ ...prevValue, cvc: "Invalid CVC." })) }
            else { setCardDataError((prevValue) => ({ ...prevValue, cvc: "" })) }

            const err = validateDate(cardData?.exp_date)

            if (number?.length < 8 || exp_date?.length === 0 || exp_date?.length < 5 || cvc?.length === 0 || cvc?.length < 3 || err) { return false; }

            const requestData = {
                number: (cardData?.number).replace(/\s/g, ""),
                exp_month: cardData?.exp_date?.split("/")[0],
                exp_year: cardData?.exp_date?.split("/")[1],
                cvc: cardData?.cvc,
            };

            setLoading(true);
            await addCardApi(requestData)
                .then((res) => {
                    trackStandardPixelEvent('AddPaymentInfo', {
                        content_category: null,
                        content_ids: null,
                        contents: null,
                        currency: null,
                        value: null
                    })
                    setLoading(false);
                    history.push({ pathname: "/paymentmethod" })
                })

        }
        catch (e) {
            console.log(e);
            setLoading(false);

            const errMsg = e?.response?.data?.data?.raw?.message || "";
            setCardDataError((prevValue) => ({ ...prevValue, number: errMsg }))
        }
    }

    const validateDate = (input: string) => {
        const month = input?.split("/")[0];
        const year = '20' + input?.split("/")[1];
        const currentDateTime = new Date();
        const currentYear = currentDateTime.getFullYear();
        const currentMonth = currentDateTime.getMonth() + 1;

        // Check if the year is in the past
        if (Number(year) < currentYear) {
            setCardDataError((prevValue) => ({ ...prevValue, exp_date: "Invalid year." }))
            return true;
        }

        // Check if the year is the current year and the month is in the past
        if (Number(year) === currentYear && Number(month) < currentMonth) {
            setCardDataError((prevValue) => ({ ...prevValue, exp_date: "Invalid month." }))
            return true;
        }
    }

    return (
        <div className="screen">

            <TopHead
                title="Payment Methods"
                leftTextIcon={faAngleLeft}
                leftIconStyle={{ fontSize: "24px" }}
            />
            <Divider />
            <div className="grow overflow-auto">
                <Section>
                    <TextHead1 className="text-center mt-7">
                        Add your payment methods
                    </TextHead1>

                    <SubText1 className="text-center mt-5">
                        This card will only be charged when you place an order.
                    </SubText1>
                </Section>
                <Section>
                    <InputWithIcon disabled={loading} style={{ borderColor: (emailError === "required" ? "red" : "") }} onChange={handleInput} value={email} maxLength={19} type="text" name="email" placeholder="example@domain.com" />


                    <img src={cardPng} alt="credit-card" className="for-icon" />
                    <InputWithIcon disabled={loading} style={{ borderColor: (cardDataError?.number === "required" ? "red" : "") }} onChange={handleInput} value={cardData?.number} maxLength={19} type="text" name="number" placeholder="XXXX XXXX XXXX XXXX" />
                    {((cardDataError?.number)?.length > 0 && cardDataError?.number !== "required") && <label className="text-red-600">{cardDataError?.number}</label>}
                    <div className="flex">
                        <InputWithIcon disabled={loading} style={{ borderColor: (cardDataError?.exp_date === "required" ? "red" : "") }} onChange={handleInput} value={cardData?.exp_date} className="mr-1" maxLength={5} type="text" name="exp_date" placeholder="MM/YY" />
                        <InputWithIcon disabled={loading} style={{ borderColor: (cardDataError?.cvc === "required" ? "red" : "") }} onChange={handleInput} value={cardData?.cvc} className="ml-1" maxLength={3} type="text" name="cvc" placeholder="CVC" />
                    </div>
                    <div className="flex justify-between">
                        {((cardDataError?.exp_date)?.length > 0 && cardDataError?.exp_date !== "required") ? <label className="text-red-600">{cardDataError?.exp_date}</label> : <label></label>}
                        {((cardDataError?.cvc)?.length > 0 && cardDataError?.cvc !== "required") && <label className="text-red-600">{cardDataError?.cvc}</label>}
                    </div>
                </Section>
                <Section>
                    <ButtonGreen onClick={handleSubmit} disabled={loading}>
                        {switchSentenceCase("primary button", loading ? "Processing..." : "ADD CARD")}
                    </ButtonGreen>
                    <ButtonInitial
                        onClick={() => history.push({ pathname: "/scancard" })}
                        style={{ border: "1px solid black", marginTop: "15px" }}
                        disabled={loading}
                    >
                        <FontAwesomeIcon icon={faCameraViewfinder} className="mr-2" />
                        {switchSentenceCase("primary button", "SCAN CARD")}
                    </ButtonInitial>

                    {/* <SubText1 className="text-center mt-5">
                        <label className="text-red-600 mt-5">
                            <FontAwesomeIcon icon={faInfoCircle} />
                            &nbsp;
                            A refundable charge of $0.50 will be applied for verification purposes.
                        </label>
                    </SubText1> */}
                </Section>
            </div>
        </div >
    );
}