import { useHistory } from "react-router";
import PreferredMealCard from "../../components/widgets/food/PreferredMealCard";


export default function PreferredMealList(props: any) {

  const history = useHistory()
  const onMealClick = () => { history.push({ pathname: "productdetail" }) }

  return (
    <div>
      {/* {header ? <h2 className="mt-0">{header}</h2> : null} */}
      {props?.data.map((item: any, index: number) => (
        <div className="lst-item" key={index}>
          <h1>{item?.attributes?.product?.data?.attributes.id}</h1>
          <PreferredMealCard
            meal={item}
            fav_meal_id={item.id}
            onClick={onMealClick}
            type={props?.type}
          />
        </div>
      ))}
    </div>
  );
}


