import { apiRequestService } from './apiRequestService';
import { getUserData } from './user';

const API_URL = process.env.REACT_APP_API_URL

// Create New Fav Items
export const addCardApi = async (requestData: any) => {
    try {
        const user = await getUserData()
        const user_id = user?.user?.id

        const payloadData = {
            data: {
                user: user_id,
                email: requestData?.email,
                card: {
                    number: requestData?.number,
                    exp_month: requestData?.exp_month,
                    exp_year: requestData?.exp_year,
                    cvc: requestData?.cvc,
                }
            }
        }

        let config = {
            method: 'post',
            url: API_URL + `/v1/card/add`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + user?.jwt
            },
            data: payloadData
        };
        const request = apiRequestService(config);
        return request;
    } catch (error) {
        throw (error);
    }
}

//Get User's Payment Cards
export const userPaymentCardListApi = async (id: any) => {
    try {
        const user = await getUserData()

        let config = {
            method: 'get',
            url: API_URL + `/v1/card/list/${id}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + user?.jwt
            },
        };
        const request = apiRequestService(config);
        return request;
    } catch (error) {
        throw error;
    }
}

//Set Default User's Payment Card
export const setDefaultCardApi = async (id: any) => {
    try {
        const user = await getUserData()

        let config = {
            method: 'put',
            url: API_URL + `/v1/card/set-default/${id}/${user?.user?.id}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + user?.jwt
            },
        };
        const request = apiRequestService(config);
        return request;
    } catch (error) {
        throw error;
    }
}

//Remove User's Payment Card
export const removeCardApi = async (id: any) => {
    try {
        const user = await getUserData()

        let config = {
            method: 'delete',
            url: API_URL + `/v1/card/remove/${id}/${user?.user?.id}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + user?.jwt
            },
        };
        const request = apiRequestService(config);
        return request;
    } catch (error) {
        throw error;
    }
}