import Section from "../../components/widgets/section/Section";
import { useHistory } from 'react-router-dom';
import { ButtonGreen, InitialDiv, TextHead4 } from "../../components/styled/styled";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import { useEffect, useState } from "react";
import { getUserData, setUserData, userProfileUpdate } from "../../services/user";
import { getContent } from "../../services/landingPages";
import { useIonViewDidEnter } from "@ionic/react";
import { trackPixelEvent } from "../../utils/utilities";


export default function OnboardingNew() {
  const history = useHistory();

  const [data, setData] = useState([]);

  // **************** First time Redering ***********************
  useEffect(() => {
    checkUserLoggedIn();
    callData();
  }, []);

  useIonViewDidEnter(() => {
    trackPixelEvent('On Boarding');
  }, []);


  // **************** Hooks ***********************
  const [swipe, setSwipe] = useState<any>();
  let [count, setCount] = useState(0);



  // ************* Functions ******************
  async function checkUserLoggedIn() {
    const userData = await getUserData();
    if (userData?.user?.new_user === false) {
      history.push("/home")
    }
  }

  const callData = async () => {
    try {
      const response = await getContent();
      if (response) {
        trackPixelEvent('Get Content');
        const data = response?.data?.data;

        //? Map through the array and modify each "attributes.heading" field
        const modifiedData = data.map((item: any) => {
          if (item.attributes && item.attributes.heading) {
            item.attributes.heading = item.attributes.heading.replace(/<h4/g, '<h1/');
            item.attributes.content = item.attributes.content.replace(/<p/g, '<p/');
          }
          return item;
        });

        setData(modifiedData)
      }
    }
    catch (error) {
      console.log(error);
    };
  };


  const getActiveIndex = (e: any) => {
    setCount(e?.activeIndex);
  }


  //***************** HANDLE UPDATE NEW USER TO NOT SHOW AGAIN ONBOARDING ROUTE/PAGE FUNCTION *****************//
  const handleStarted = async () => {
    const localData = await getUserData();
    const data = {
      "new_user": false,
    };
    await userProfileUpdate(data).then((res) => {
      if (res.status === 200) {
        trackPixelEvent('User profile update');
        const newUpdatedUserInfo = {
          ...localData,
          user: ({
            ...localData.user,
            "new_user": false,
          })
        };
        setUserData(newUpdatedUserInfo);
      }
    })
    history.push("/home");
  }

  return (
    <>
      <InitialDiv className="h-full onboarding-parent p-[25px] overflow-scroll hidden-scroll">
        <>
          {count === 2
            ?
            ""
            :
            <TextHead4 className="float-right absolute right-5 text-gray-600 z-20 cursor-pointer" onClick={() => handleStarted()}>skip</TextHead4>
          }
          <Swiper
            onBeforeInit={(swipper) => setSwipe(swipper)}
            spaceBetween={30}
            grabCursor={true}
            pagination={{
              clickable: true,
            }}
            modules={[Pagination]}
            className="mySwiper h-[94%] overflow-y-scroll hidden-scroll"
            onActiveIndexChange={(e: any) => getActiveIndex(e)}
          >
            {/* <SwiperSlide>{Logo(data[0])}</SwiperSlide> */}
            <SwiperSlide>{SaveFavorite(data[1])}</SwiperSlide>
            <SwiperSlide>{Schedule(data[2])}</SwiperSlide>
            <SwiperSlide>{OrderFood(data[3])}</SwiperSlide>
          </Swiper>
        </>
        {count === 2 ?
          <ButtonGreen className="onboarding-button" onClick={() => handleStarted()}>Get Started</ButtonGreen>
          :
          <ButtonGreen className="onboarding-button" onClick={() => swipe?.slideNext()}>Next</ButtonGreen>}
      </InitialDiv >
    </>
  );
}


//***************** FIRST SLIDE *****************//
// TODO: space out in vertical space
export function Logo(data: any) {
  return (
    <InitialDiv className="mx-4 text-center">
      <div className="bg-[#22a45d] w-[180px] ml-auto mr-auto rounded-[110px] p-[28px]">
        <img src={`${process.env.REACT_APP_API_URL}${data?.attributes?.icon?.data?.attributes?.url}`} alt="Send Meal" className="mx-auto logo-animate rounded-[22px]" width={120} height={120} />
      </div>
      <div className="text-below-image">
        <div className="!text-[18px] onboarding" dangerouslySetInnerHTML={{ __html: data?.attributes?.heading }}></div>
        <div className="subtext text-center text-gray-400 mt-2 text-[15px] onboarding" dangerouslySetInnerHTML={{ __html: data?.attributes?.content }}></div>
      </div>
    </InitialDiv >
  );
}

//***************** SECOND SLIDE *****************//
export function SaveFavorite(data: any) {
  return (
    <Section align="center">
      <img src={`${process.env.REACT_APP_API_URL}${data?.attributes?.icon?.data?.attributes?.url}`} alt="Hot and Fresh Meal" className="mx-auto" width={180} height={180} />
      <div className="text-below-image">
        <div className="onboarding !text-[18px] mt-7" dangerouslySetInnerHTML={{ __html: data?.attributes?.heading }}></div>
        <div className="subtext text-center text-gray-400 my-4 text-[15px] onboarding" dangerouslySetInnerHTML={{ __html: data?.attributes?.content }}></div>
      </div>
    </Section>
  );
}

//***************** THIRD SLIDE *****************//
export function Schedule(data: any) {
  return (
    <Section align="center">
      <img src={`${process.env.REACT_APP_API_URL}${data?.attributes?.icon?.data?.attributes?.url}`} alt="Preferences" className="mx-auto" width={180} height={180} />
      <div className="text-below-image">
        <div className="!text-[18px] mt-7 onboarding" dangerouslySetInnerHTML={{ __html: data?.attributes?.heading }}></div>
        <div className="subtext text-center text-gray-400 my-4 text-[15px] onboarding" dangerouslySetInnerHTML={{ __html: data?.attributes?.content }}></div>
      </div>
    </Section>
  );
}

//***************** FOURTH SLIDE *****************//
export function OrderFood(data: any) {
  return (
    <Section align="center">
      <img src={`${process.env.REACT_APP_API_URL}${data?.attributes?.icon?.data?.attributes?.url}`} alt="Preferences" className="mx-auto mt-6" width={180} height={180} />
      <div className="text-below-image">
        <div className="!text-[18px] mt-7 onboarding" dangerouslySetInnerHTML={{ __html: data?.attributes?.heading }}></div>
        <div className="subtext text-center text-gray-400 mt-4 text-[15px] onboarding" dangerouslySetInnerHTML={{ __html: data?.attributes?.content }}></div>
      </div>
    </Section>
  );
}