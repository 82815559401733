import { FacebookLogin } from '@capacitor-community/facebook-login';
import { IonPage, IonRouterOutlet, setupIonicReact } from "@ionic/react";
import { IonReactHashRouter } from "@ionic/react-router";
import { createContext, useEffect, useState } from "react";
import { Redirect, Route } from "react-router-dom";
import { Plugins } from "@capacitor/core";
import BottomNav from "./components/widgets/bottomnav/BottomNav";
import RestaurantAllList from "./components/widgets/cards/RestaurantAllList";
import ModalSignUp from "./components/widgets/modals/ModalSignUp";
import ModalVerifyOtp from "./components/widgets/modals/ModalVerifyOTP";
import AddPaymentCard from "./pages/checkout/AddPaymentCard";
import CardList from "./pages/checkout/CardList";
import OrderDetails from "./pages/checkout/OrderDetails";
import PaymentMethod from "./pages/checkout/PaymentMethod";
import ReferToFriends from "./pages/checkout/ReferToFriends";
import ScanCard from "./pages/checkout/ScanCard";
import AddFaveMeal from "./pages/faves/AddToFaveMeal";
import AddToFaveMealCategory from "./pages/faves/AddToFaveMealCategory";
import FavesAddNewCategory from "./pages/faves/FaveAddNewCategory";
import FavesCategoryRemoved from "./pages/faves/FaveCategoryRemoved";
import Fave from "./pages/faves/Faves";
import FavesAllItems from "./pages/faves/FavesAllItems";
import NewMealFave from "./pages/faves/NewMealFave";
import RemoveFaveMeal from "./pages/faves/RemoveFromFaveMeal";
import FiltersV2 from "./pages/foodsearch/FiltersV2";
import HomeV1 from "./pages/foodsearch/HomeV1";
import Notifications from "./pages/foodsearch/Notifications";
import OtherTip from "./pages/foodsearch/OtherTip";
import RecipetVeto from "./pages/foodsearch/RecipetVeto";
import SearchFood from "./pages/foodsearch/SearchFood";
import SearchResultsV1 from "./pages/foodsearch/SearchResultsV1";
import TimeSlot from "./pages/foodsearch/TimeSlot";
import YourOrders from "./pages/foodsearch/YourOrders";
import GetFoodNow from "./pages/gfn/GetFoodNow";
import OrdersHome from "./pages/gfn/OrdersHome";
import Home from "./pages/home/Home2";
import OnboardingNew from "./pages/onboarding/OnboardingNew";
import NewMealCheckout from "./pages/orderFood/NewMealCheckout";
import OrdersAllItems from "./pages/orderFood/OrdersAllItems";
import RestaurantDetail from "./pages/orderFood/RestaurantDetail";
import ManageOrders from "./pages/orders/ManageOrders";
import OrderTracking from "./pages/orders/OrderTracking";
import AddLocation from "./pages/profile/AddLocation";
import AddLocationList from "./pages/profile/AddLocationList";
import ContactUs from "./pages/profile/ContactUs";
import Faqs from "./pages/profile/Faqs";
import Friends from "./pages/profile/Friends";
import FriendsInvite from "./pages/profile/FriendsInvite";
import FriendsRequest from "./pages/profile/FriendsRequest";
import ManageAccounts from "./pages/profile/ManageAccounts";
import PrivacyPolicy from "./pages/profile/PrivacyPolicy";
import SocialAccounts from "./pages/profile/SocialAccounts";
import TermsAndConditions from "./pages/profile/TermsAndConditions";
import TipPreference from "./pages/profile/TipPreference";
import Profile from "./pages/profile/UserProfile";
import UserProfileDetails from "./pages/profile/UserProfileDetails";
import ProductDetail from "./pages/restaurant/ProductDetail";
import Restaurant from "./pages/restaurant/RestaurantHome";
import AssignMealCategories from "./pages/scheduleotherpages/AssignMealCategories";
import CalendarInvitation from "./pages/scheduleotherpages/CalendarInvitation";
import InviteFoodie from "./pages/scheduleotherpages/InviteFoodie";
import NewMealPayments from "./pages/scheduleotherpages/NewMealPayments";
import ScheduleDetailsRecipientsCreated from "./pages/scheduleotherpages/ScheduleDetailsRecipientsCreated";
import ScheduleDetailsRecipientsNew from "./pages/scheduleotherpages/ScheduleDetailsRecipientsNew";
import ScheduleSlotFill from "./pages/scheduleotherpages/ScheduleSlotFill";
import ScheduleWSlots from "./pages/scheduleotherpages/ScheduleWSlots";
import SchedulingWidget from "./pages/scheduleotherpages/SchedulingWidget";
import CreateMealSchedule from "./pages/schedules/CreateMealSchedule";
import MealForMultiPeople from "./pages/schedules/MealForMultiPeople";
import MealForSomeElse from "./pages/schedules/MealForSomeElse";
import Schedules from "./pages/schedules/Schedules";
import SchedulesHome from "./pages/schedules/SchedulesHome";
import Search from "./pages/search/Search";
import SignUpIn from "./pages/signupin/SignUpIn";
import AllAboard from "./pages/sponsors/AllAboard";
import NewMealAddParticipants from "./pages/sponsors/NewMealAddParticipants";
import NewMealAddRecipients from "./pages/sponsors/NewMealAddRecipients";
import VerifyOtp from "./pages/veryfyphone/VerifyOtp";


/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/display.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/padding.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";


/* Theme variables */
import "./theme/variables.css";
import useDeviceInfo from './components/hooks/seDeviceInfo';
import GiftSelect from './pages/gift/GiftSelect';
import GiftHelps from './pages/gift/GiftHelps';
import GiftForm from './pages/gift/GiftForm';
import PlansSelect from './pages/plans/PlansSelect';
import PlansHelps from './pages/plans/PlansHelps';

setupIonicReact();
export const UserContext = createContext(null);

const App = () => {
  const deviceInfo = useDeviceInfo();
  const [userValue, setUserValue] = useState(null);
  const { AppTrackingTransparency } = Plugins;

  useEffect(() => {
    // const initializeGoogle = async () => {
    //   GoogleAuth.initialize({ grantOfflineAccess: true });
    // };
    const initializeFacebook = async () => {
      await FacebookLogin.initialize({
        appId: process.env.REACT_APP_FACEBOOK_APP_ID
      });
    };
    initializeFacebook();
    // initializeGoogle();
  }, []);


  useEffect(() => {
    const addScript = (sendToId: string) => {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.text = `gtag('event', 'conversion', {'send_to': '${sendToId}'});`;
      document.body.appendChild(script);
    };

    if (deviceInfo?.platform === "android") {
      addScript(`AW-${process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID}/X7TLCMWprrMZEMOLp7s9`);
    } else if (deviceInfo?.platform === "ios") {
      addScript(`AW-${process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID}/b4hrCMKprrMZEMOLp7s9`);
    } else {
      addScript(`AW-${process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID}/3zd1COadrrMZEMOLp7s9`);
    }
  }, []);

  useEffect(() => {
    const requestTrackingPermission = async () => {
      try {
        const status = await AppTrackingTransparency.requestPermission();
        if (status === 'authorized' || status === 'unrestricted') {
          console.log('Tracking permission granted');
        } else {
          console.log('Tracking permission denied');
        }
      } catch (error) {
        console.error('Error requesting tracking permission', error);
      }
    };

    if (deviceInfo?.platform === "ios") {
      requestTrackingPermission();
    }
  }, []);


  return (
    // <IonApp>
    <IonReactHashRouter>
      <UserContext.Provider value={{ userValue: userValue, setUserValue: setUserValue }}>
        <IonRouterOutlet>
          <IonPage>
            <Route exact path="/">
              <Redirect to="/login" />
            </Route>
            <Route exact path="/login">
              <SignUpIn />
            </Route>
            <Route exact path="/onboarding">
              <OnboardingNew />
            </Route>
            <Route exact path="/verify-otp">
              <VerifyOtp />
            </Route>
            <Route exact path="/home">
              <Home />
            </Route>
            <Route exact path="/faves">
              <Fave />
            </Route>
            <Route exact path="/search">
              <Search />
            </Route>
            <Route exact path="/profile">
              <Profile />
            </Route>
            <Route exact path="/contactus">
              <ContactUs />
            </Route>
            <Route exact path="/userprofiledetails">
              <UserProfileDetails />
            </Route>
            <Route exact path="/restaurant/:rid">
              <Restaurant />
            </Route>
            <Route exact path="/restaurantDetail/:rid">
              <RestaurantDetail />
            </Route>
            <Route exact path="/newMealCheckout">
              <NewMealCheckout />
            </Route>
            <Route exact path="/newmealfave">
              <NewMealFave />
            </Route>
            <Route exact path="/addtofavemealcategory/:id">
              <AddToFaveMealCategory />
            </Route>
            <Route exact path="/productdetail">
              <ProductDetail />
            </Route>
            <Route exact path="/manage-orders">
              <ManageOrders />
            </Route>
            <Route exact path="/getfoodnow">
              <GetFoodNow />
            </Route>
            <Route exact path="/schedules">
              <Schedules />
            </Route>
            <Route exact path="/create-calendar">
              <CreateMealSchedule />
            </Route>
            <Route exact path="/mealforsomeoneelse">
              <MealForSomeElse />
            </Route>
            <Route exact path="/mealformultipeople">
              <MealForMultiPeople />
            </Route>
            <Route exact path="/newmealpayments">
              <NewMealPayments />
            </Route>
            <Route exact path="/newmealaddrecipients">
              <NewMealAddRecipients />
            </Route>
            <Route exact path="/newmealaddparticipants">
              <NewMealAddParticipants />
            </Route>
            <Route exact path="/allaboard">
              <AllAboard />
            </Route>
            <Route exact path="/calendar-home">
              <SchedulesHome />
            </Route>
            <Route exact path="/view-calendar/:schedule/:user">
              <ScheduleDetailsRecipientsCreated />
            </Route>
            <Route exact path="/scheduledetailsrecipientsnew">
              <ScheduleDetailsRecipientsNew />
            </Route>
            <Route exact path="/assignmealcategories">
              <AssignMealCategories />
            </Route>
            <Route exact path="/faveaddnewcategory">
              <FavesAddNewCategory />
            </Route>
            <Route exact path="/editmealcategory/:id">
              <FavesCategoryRemoved />
            </Route>
            <Route exact path="/addfavemeal">
              <AddFaveMeal />
            </Route>
            <Route exact path="/removefavemeal">
              <RemoveFaveMeal />
            </Route>
            <Route exact path="/calendar-slots/:scheduleId">
              <ScheduleWSlots />
            </Route>
            <Route exact path="/scheduleslotfill">
              <ScheduleSlotFill />
            </Route>
            <Route exact path="/cardlist">
              <CardList />
            </Route>
            <Route exact path="/paymentmethod">
              <PaymentMethod />
            </Route>
            <Route exact path="/addpaymentcard">
              <AddPaymentCard />
            </Route>
            <Route exact path="/orderdetails">
              <OrderDetails />
            </Route>
            <Route exact path="/scancard">
              <ScanCard />
            </Route>
            <Route exact path="/refertofriends">
              <ReferToFriends />
            </Route>
            <Route exact path="/tippreference">
              <TipPreference />
            </Route>
            <Route exact path="/addlocationlist">
              <AddLocationList />
            </Route>
            <Route exact path="/addlocation">
              <AddLocation />
            </Route>
            <Route exact path="/socialaccounts">
              <SocialAccounts />
            </Route>
            <Route exact path="/manage-accounts">
              <ManageAccounts />
            </Route>
            <Route exact path="/invitefoodie">
              <InviteFoodie />
            </Route>
            <Route exact path="/faqs">
              <Faqs />
            </Route>
            <Route exact path="/homepage/v1">
              <HomeV1 />
            </Route>
            <Route exact path="/filters/v2">
              <FiltersV2 />
            </Route>
            <Route exact path="/timeslot">
              <TimeSlot />
            </Route>
            <Route exact path="/othertip">
              <OtherTip />
            </Route>
            <Route exact path="/recipetveto">
              <RecipetVeto />
            </Route>
            <Route exact path="/searchresults/v1">
              <SearchResultsV1 />
            </Route>
            <Route exact path="/searchfood">
              <SearchFood />
            </Route>
            <Route exact path="/yourorders">
              <YourOrders />
            </Route>
            <Route exact path="/notifications">
              <Notifications />
            </Route>
            <Route exact path="/schedulingwidget">
              <SchedulingWidget />
            </Route>
            <Route exact path="/calendar-invitation/:schedule/:invite/:user">
              <CalendarInvitation />
            </Route>
            <Route exact path="/orderTrack/:slotId">
              <OrderTracking />
            </Route>
            <Route exact path="/termsandconditions">
              <TermsAndConditions />
            </Route>
            <Route exact path="/privacypolicy">
              <PrivacyPolicy />
            </Route>
            <Route exact path="/favesallitems/:pageType">
              <FavesAllItems />
            </Route>
            <Route exact path="/restaurantallitems/:pageType">
              <RestaurantAllList />
            </Route>
            <Route exact path="/ordersallitems/:pageType">
              <OrdersAllItems />
            </Route>
            <Route exact path="/orders">
              <OrdersHome />
            </Route>
            <Route exact path="/friends">
              <Friends />
            </Route>
            <Route exact path="/friends-profile/:userId/:uid">
              <FriendsRequest />
            </Route>
            <Route exact path="/friendsinvite/:userinfo">
              <FriendsInvite />
            </Route>
            <Route exact path="/gift-helps">
              <GiftHelps />
            </Route>
            <Route exact path="/gift-select">
              <GiftSelect />
            </Route>
            <Route exact path="/gift-form">
              <GiftForm />
            </Route>
            <Route exact path="/plans-helps">
              <PlansHelps />
            </Route>
            <Route exact path="/plans-select">
              <PlansSelect />
            </Route>
          </IonPage>
        </IonRouterOutlet>
        <BottomNav />
        {/* <SessionOutAlert /> */}
        <ModalSignUp />
        <ModalVerifyOtp />
      </UserContext.Provider>
    </IonReactHashRouter >
    // </IonApp>
  );
};

export default App;
