import TopHead from "../../components/widgets/head/TopHead";
import { faAngleLeft } from "@fortawesome/pro-solid-svg-icons";
import Divider from "../../components/widgets/divider/Divider";
import { Astk, ButtonGreen, SubText1, SubText2, TextArea1, TextBox1, TextHead1 } from "../../components/styled/styled";
import Section from "../../components/widgets/section/Section";
import { contactUsService } from "../../services/user";
import { useState } from "react";
import { maxCharacterLimit, validateEmail, maxEmailCharLimit, maxUserNameCharLimit, trackPixelEvent, trackStandardPixelEvent, switchSentenceCase } from "../../utils/utilities";
import { imageUploader } from "../../utils/imageUploader";
import { getUserInfo } from "../../services/user";
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import store from "../../redux/store";
import { useIonAlert, useIonViewDidEnter } from "@ionic/react";
import ModalSuccess from "../../components/widgets/modals/ModalSuccess";
import { useHistory } from "react-router";
import useDarkMode from "../../components/hooks/darkmode/DarkMode";
import { objectType } from "../../type";

export default function ContactUs() {
  const user = getUserInfo();
  const history = useHistory();
  const isDarkMode = useDarkMode();
  if (!user?.id) {
    store.dispatch({
      type: "SHOW_ALERT",
      payload: { show: true }
    })
  }

  const [presentAlert] = useIonAlert();
  const showAlert = (header: string, message: string, btnMessage: string) => {
    presentAlert({
      header: header,
      message: message,
      buttons: [btnMessage],
    });
  };

  // *********************** Hooks ********************************

  const [values, setValues] = useState({
    userName: user?.name ? user?.name : "",
    userEmail: user?.email ? user?.email : "",
    userPhoneNo: "",
    userReason: "",
    userReasonDetails: "",
  });

  const [image, setImage] = useState([]);

  // *********************** First time redering ********************************
  useIonViewDidEnter(() => {
    trackPixelEvent('Contact');
  }, []);

  // *********************** Errors Hook *************************************
  const [userNameError, setUserNameError] = useState(false);
  const [userEmailError, setUserEmailError] = useState(false);
  const [userPhoneNoError, setUserPhoneNoError] = useState(false);
  const [userReasonError, setUserReasonError] = useState(false);
  const [userReasonDetailsError, setUserReasonDetailsError] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  // ***********************Functions********************************

  const onChangeProfilePic = async (value: any) => {
    const file = value[0];
    if (file?.type === "image/jpeg" || file?.type === "image/png") {
      if (file) {
        const image = await imageUploader(file);
        setImage(image);
      }
    } else {
      return false;
    }
  };

  const handleOnChangePhoneNumber = (value: string) => {
    setValues((prevValues) => {
      return {
        ...prevValues,
        userPhoneNo: value,
      };
    });

    if (value == "" || value == undefined) {
      setUserPhoneNoError(true);
    } else {
      setUserPhoneNoError(false);
    }
  }

  const handleDetails = (e: objectType) => {
    let value = e.target.value;
    if (e.target.name === "userReason") {
      setValues((prevValues) => {
        return {
          ...prevValues,
          userReason: (value.substring(0, 100)),
        };
      });
      if (String(value).trim().length >= 100) {
        return showAlert("", "You exceed the maximum limit.", "OK");
      }
    }
    else {
      setValues((prevValues) => {
        return {
          ...prevValues,
          userReasonDetails: (value.substring(0, 300)),
        };
      });
      if (String(value).trim().length >= 300) {
        return showAlert("", "You exceed the maximum limit.", "OK");
      }
    }
  };

  const handleKeyDownDetails = (e: objectType) => {
    if (e.target.name === "userReason") {
      if (values?.userReason?.length >= 100) {
        if (e.key === " " || e.keyCode === 32) {
          return showAlert("", "You exceed the maximum limit.", "OK");
        }
      }
    }
    else {
      if (values?.userReasonDetails?.length >= 300) {
        if (e.key === " " || e.keyCode === 32) {
          return showAlert("", "You exceed the maximum limit.", "OK");
        }
      }
    }
  };

  const handleOnChange = (e: any) => {
    const { name, value } = e.target;
    setValues((prevValues) => {
      return {
        ...prevValues,
        [name]: value,
      };
    });

    if (name === "userName") {
      setUserNameError(false);
      if (value.trim() === "") {
        setUserNameError(true);
      } else {
        setUserNameError(false);
      }
    };

    if (name === "userEmail") {
      setUserEmailError(false);
      // const validEmail = validateEmail(value);
      // if (String(value).trim()?.length === 0 || !validEmail) {
      //   setUserEmailError(true);
      // }
      // else {
      //   setUserEmailError(false);
      // }
    }
  };

  const handleSubmit = async () => {

    const { userName, userEmail, userPhoneNo, userReason, userReasonDetails } = values;

    // ______________________ Checking Validations _____________________________
    const validEmail = validateEmail(userEmail);

    if (userName.trim() === "") {
      setUserNameError(true);
      document.getElementById("username").scrollIntoView({ behavior: "smooth" })
    } else {
      setUserNameError(false);
    }

    if (userEmail.trim()?.length === 0 || !validEmail) {
      setUserEmailError(true);
      document.getElementById("userEmail").scrollIntoView({ behavior: "smooth" })
      return
    } else {
      setUserEmailError(false);
    }

    if (userReason.trim() === "") {
      setUserReasonError(true);
      document.getElementById("reason").scrollIntoView({ behavior: "smooth" })
    } else {
      setUserReasonError(false);
    }

    if (userReasonDetails.trim() === "") {
      setUserReasonDetailsError(true);
      document.getElementById("details").scrollIntoView({ behavior: "smooth" })
    } else {
      setUserReasonDetailsError(false);
    }

    if (
      userName.trim() === "" ||
      userEmail.trim() === "" ||
      userEmailError ||
      userReason.trim() === "" ||
      userReasonDetails.trim() === ""
    ) {
      return false;
    }

    // ----------Sending Data to Post API Function----------
    const payloadData = {
      data: {
        "name": userName,
        "email": userEmail,
        "mobile_no": userPhoneNo,
        "reason": userReason,
        "details": userReasonDetails,
        "image": image[0]?.id,
        "status": "OPENED",
        "user": user?.id
      }
    };

    try {
      await contactUsService(payloadData).then((response: any) => {
        trackStandardPixelEvent('Contact');
        let data = response;
        if (data?.status === 200) {
          setShowSuccessModal(true);
        }
      });
    } catch (error) {
      // console.log(error);
    }
  }


  return (

    <div className="screen">
      <TopHead
        title={"Contact Us"}
        leftTextIcon={faAngleLeft}
        leftIconStyle={{ fontSize: "24px" }}
      />

      <Divider />
      <div className="grow overflow-auto">
        <Section>
          <TextHead1 id="username">Your Name<Astk> *</Astk></TextHead1>
          <TextBox1
            placeholder="Enter your Name"
            name="userName"
            value={values?.userName}
            maxLength={maxUserNameCharLimit}
            onChange={handleOnChange}
            style={{
              border: userNameError ? "1px solid red" : "",
            }}
          />
          {userNameError ? <SubText1 className="!text-red-600 mt-[-10px]"> Please Add Name.</SubText1> : ""}

          <TextHead1 id="userEmail">Your Email<Astk> *</Astk></TextHead1>
          <TextBox1
            placeholder="Enter your Email"
            name="userEmail"
            value={values?.userEmail}
            maxLength={maxEmailCharLimit}
            onChange={handleOnChange}
            style={{
              border: userEmailError ? "1px solid red" : "",
            }}
          />
          {userEmailError ? <SubText1 className="!text-red-600 mt-[-10px]">{(values?.userEmail.trim().length === 0) ? "Please Add Email." : "Please Enter Valid Email."}</SubText1> : ""}

          <TextHead1 id="userPhoneNo">Mobile Number<span className="text-base text-[#b5bbc9]">{" "}(Optional)</span></TextHead1>
          <PhoneInput
            className={isDarkMode ? "country-selector" : ""}
            international
            defaultCountry="US"
            countryCallingCodeEditable={false}
            limitMaxLength={true}
            placeholder="Enter your Mobile Number (optional)"
            name="userPhoneNo"
            value={values?.userPhoneNo}
            onChange={handleOnChangePhoneNumber}
            style={{
              margin: "25px 0px",
              border: userPhoneNoError ? "1px solid red" : "",
            }} />

          <TextHead1 id="reason">Reason for Contacting<Astk> *</Astk></TextHead1>
          <SubText1 className="text-right mt-2">{values?.userReason?.length}/100</SubText1>
          <TextBox1
            className="!mt-[0px]"
            placeholder="Enter your reason for Contacting Us"
            name="userReason"
            value={values?.userReason}
            onChange={(e: any) => handleDetails(e)}
            onKeyDown={(e) => handleKeyDownDetails(e)}
            style={{
              border: userReasonError ? "1px solid red" : "",
            }}
          />
          {userReasonError ? <SubText1 className="!text-red-600 mt-[-10px]"> Please Add Reason.</SubText1> : ""}

          <TextHead1 id="details">Details <Astk> *</Astk></TextHead1>
          <SubText1 className="text-right mt-2">{values?.userReasonDetails?.length}/{maxCharacterLimit}</SubText1>
          <TextArea1
            className="!mt-[0px]"
            placeholder="Describe the purpose"
            name="userReasonDetails"
            value={values?.userReasonDetails}
            onChange={(e: any) => handleDetails(e)}
            onKeyDown={(e) => handleKeyDownDetails(e)}
            style={{ border: userReasonDetailsError ? "1px solid red" : "" }}
          />
          {userReasonDetailsError ? <SubText1 className="!text-red-600 mt-[-10px]"> Please Describe Reason in Detail.</SubText1> : ""}

          <TextHead1>Add Screen Shots<span className="text-base text-[#b5bbc9]">{" "}(Optional)</span></TextHead1>
          <input type="file" id="img" name="img" accept="image/*"
            className="bg-transparent border border-solid border-[#e1e1e1]"
            onChange={(e) => onChangeProfilePic(e.target.files)}
          >
          </input>
        </Section>
        <Section>

          <ButtonGreen onClick={handleSubmit}>Submit</ButtonGreen>
        </Section>

        <ModalSuccess
          contentDivStyle={{ height: "auto" }}
          firstText={switchSentenceCase(
            "primary button",
            "Form Submitted Successfully"
          )}
          btnOneText={switchSentenceCase("primary button", "OKAY")}
          btnOneGoto={() =>
            history.push({
              pathname: "/profile",
            })
          }
          showModal={showSuccessModal}
        />

      </div >
    </div >
  );
}
