import {
  faAngleLeft,
  faCalendarPen,
  faCircleInfo,
  faClockThree,
  faStore,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  IonDatetime,
  IonDatetimeButton,
  IonModal,
  useIonAlert,
  useIonViewDidEnter,
} from "@ionic/react";
import { Select } from "antd";
import moment from "moment";
import momentTimezone from "moment-timezone";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import styled from "styled-components";
import useDarkMode from "../../components/hooks/darkmode/DarkMode";
import {
  ButtonGreen,
  Flex,
  FlexBetween,
  InitialDiv,
  InputText,
  ItemCount,
  SubText1,
  SubText2,
  TextArea1,
  TextHead3,
  TextHead4,
  TextHead5,
} from "../../components/styled/styled";
import AccordionCheckoutCustomization from "../../components/widgets/accordian/AccordionCheckoutCustomization";
import Accordion from "../../components/widgets/accordian/AccordionStyled";
import AddressesCart from "../../components/widgets/address/AdressesCart";
import IonModalAlert from "../../components/widgets/alerts/IonModalAlert";
import ToolTip from "../../components/widgets/alerts/tooltip";
import QuantitySelectorGlobal from "../../components/widgets/buttons/QuantitySelectorGlobal";
import CardApplyCoupon from "../../components/widgets/cards/CardApplyCoupon";
import Divider from "../../components/widgets/divider/Divider";
import TopHead from "../../components/widgets/head/TopHead";
import ModalFinalizeOrder from "../../components/widgets/modals/ModalFinalizeOrder";
import ModalProductDetails from "../../components/widgets/modals/ModalProductDetails";
import ModalSuccess from "../../components/widgets/modals/ModalSuccess";
import ModalSuccessForCards from "../../components/widgets/modals/ModalSuccessForCards";
import PaymentCart from "../../components/widgets/paymentscomponent/PaymentCart";
import Section from "../../components/widgets/section/Section";
import Spinner from "../../components/widgets/spinner/Spinner";
import TabsForTipPreference from "../../components/widgets/toggleable-tabs/TabsForTipPreference";
import { previewOrder } from "../../services/orderFood";
import { finalizeOrderForSlots } from "../../services/schedule_slot";
import { getUserInfo } from "../../services/user";
import {
  calculateTimeDifference,
  checkRestaurantAvailabilityForSelectedSlots,
  expensiveItem,
  faveMealFormatter,
  filterRestaurantSchedulesByDateTime,
  fullDateToShortConvert,
  genTimeOptFromScheduleTime,
  getTimeDifference,
  increasePriceByQuantity,
  maskCurrencyInput,
  maxCharacterLimit,
  mergeSlotDateTime,
  priceFormater,
  priceFormatterUsd,
  properNameFormate,
  restaurantOpenAndClosingTime,
  slotDateTimeFormatter,
  sumOfMealItemInCart,
  switchSentenceCase,
  textFormatter,
  trackPixelEvent,
  trackStandardPixelEvent,
} from "../../utils/utilities";
import CheckoutProductCustomization from "./CheckoutProductCustomization";
import {
  addMealId,
  changeQuantity,
  changeQuantityOfAddOrderMealItem,
  removeOrderMealItem,
  removeRestaurantMenuData,
  removeSingleCartItem,
} from "./redux/orderFoodActionCreator";
import { getCoupon } from "../../services/discounts";

const BlockContainer = styled.div`
  display: block;
  width: 100%;
`;

const Container = styled.div`
  margin: 0px 0px 35px 0px;
`;

export default function NewMealCheckout() {
  const { scheduleDetails, selectedSlots } = useSelector((state: any) => state?.createScheduleForMe);


  const { addMealItem, searchedRestaurantData, menusDataList } = useSelector((state: any) => state?.getRestaurantData);
  const { userDeliveryAddress } = useSelector((state: any) => state?.userAddresses);
  const { orderForSomeone } = useSelector((state: any) => state?.upcomingOrderState);
  const storeId = searchedRestaurantData?.id || "";
  const _menusDataList = menusDataList?.filter((currentObj: any) => currentObj?.quoteIds === storeId)[0] || [];
  const restaurantSchedules = _menusDataList?.schedules;
  const isDarkMode = useDarkMode();
  const currentDateTime = moment();
  const history = useHistory();
  const dispatch = useDispatch();
  const user = getUserInfo();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const orderType = searchParams.get('type');
  const minDate: any = momentTimezone().tz(userDeliveryAddress?.timezone?.timeZoneId)?.format();
  const [presentAlert] = useIonAlert();
  const showAlert = (header: string, message: string, btnMessage: string) => {
    presentAlert({
      header: header,
      message: message,
      buttons: [btnMessage],
    });
  };

  useIonViewDidEnter(() => {
    trackStandardPixelEvent("InitiateCheckout", {
      content_category: null,
      content_ids: null,
      contents: null,
      currency: null,
      num_items: null,
      value: null,
    });
  }, []);

  const Tips = [
    {
      name: "None",
      value: "0",
    },
    {
      name: "$5",
      value: "5",
    },
    {
      name: "$10",
      value: "10",
    },
    {
      name: "$15",
      value: "15",
    },
    {
      name: "Other",
      value: "Other",
    },
  ];

  const [couponDetails, setCouponDetails] = useState<any>();
  const [appliedCoupon, setAppliedCoupon] = useState(null);
  const [discountPrice, setDiscountPrice] = useState<number>(0);
  const [discountCouponObj, setDiscountCouponObj] = useState<any>([]);

  const [storeTipActive, setStoreTipActive] = useState("");
  const [driverTipActive, setDriverTipActive] = useState("");

  const [storeTipValue, setStoreTipValue] = useState("");
  const [driverTipValue, setDriverTipValue] = useState("");

  const [otherStoreTipValue, setOtherStoreTipValue] = useState("");
  const [otherStoreTipError, setOtherStoreTipError] = useState(false);

  const [otherDriverTipValue, setOtherDriverTipValue] = useState("");
  const [otherDriverTipError, setOtherDriverTipError] = useState(false);

  const [deliverableSlots, setDeliverableSlots] = useState([]);
  const [generatedScheduleTimeOptions, setGeneratedScheduleTimeOptions] = useState([]);

  const [showWarningModal, setShowWarningModal] = useState(false);
  const [scheduleValues, setScheduleValues] = useState({
    time: "",
    date: fullDateToShortConvert(new Date()),
  });

  const handleOtherStoreTip = (val: any) => {
    let maskedValue = maskCurrencyInput(val);
    setOtherStoreTipError(false);
    setOtherStoreTipValue(maskedValue);
  };

  const handleOtherDriverTip = (val: any) => {
    let maskedValue = maskCurrencyInput(val);
    setOtherDriverTipError(false);
    setOtherDriverTipValue(maskedValue);
  };

  useEffect(() => {
    const filterRestaurantAvailabilityForSlots = () => {
      let tempSlotList = [];
      selectedSlots?.forEach((obj: any) => {
        const data = checkRestaurantAvailabilityForSelectedSlots(
          (orderType === "delivery" ? searchedRestaurantData?.local_hours?.delivery : searchedRestaurantData?.local_hours?.pickup),
          mergeSlotDateTime(obj?.attributes?.date, obj?.attributes?.time),
          obj?.attributes?.delivery_location?.data?.attributes?.timezone
        );
        const newData = {
          ...obj,
          attributes: {
            ...obj.attributes,
            infoMessage: data?.infoMessage,
            restaurant: data?.restaurant,
          },
        };
        tempSlotList.push(newData);
      });
      setDeliverableSlots(tempSlotList);
    };

    filterRestaurantAvailabilityForSlots();
    callData();
    filterScheduleDates();
  }, [scheduleValues?.date]);

  useEffect(() => {
    callGetCoupon();
  }, [sumOfMealItemInCart(addMealItem)])


  const callGetCoupon = async () => {
    try {
      const response = await getCoupon(scheduleDetails?.attributes !== undefined ? scheduleDetails?.attributes?.user?.data?.id : user?.id, priceFormater(sumOfMealItemInCart(addMealItem)).slice(1), orderForSomeone?.type === undefined ? scheduleDetails?.attributes?.type : orderForSomeone?.type);
      // const response = await getCoupon("15", "10", orderForSomeone?.type);
      let couponData = response?.data;
      if (couponData) {
        setCouponDetails(couponData);
      }
    } catch (e) {
      console.log("error==>", e);
    }
  };


  const handleApplyCoupon = (couponCode: string) => {
    setAppliedCoupon(couponCode);
    const coupon = couponDetails?.data?.find((coupon: any) => coupon?.coupon_code === couponCode);
    setDiscountCouponObj(coupon);
    if (coupon?.discount_type === "flat") {
      setDiscountPrice(parseFloat(coupon?.discount_amt));
    } else {
      setDiscountPrice(parseFloat(coupon?.discount_amt) / 100 * Number(priceFormater(sumOfMealItemInCart(addMealItem)).slice(1)));
    }
  };


  const handleRemoveCoupon = () => {
    setAppliedCoupon(null);
    setDiscountCouponObj([]);
    setDiscountPrice(0);
  };


  // Calculate and format discounted price
  const cartValueString = priceFormater(sumOfMealItemInCart(addMealItem)).slice(1);
  const cartValue = parseFloat(cartValueString);
  const discountedPrice = (cartValue - discountPrice).toFixed(2);


  const filterScheduleDates = () => {
    setScheduleValues((prevValues) => {
      return {
        ...prevValues,
        time: "",
      };
    });
    // Group schedules by date and format timings
    const groupedSchedules = [];
    const timeDifference = [];

    // Filter and push matching dates to the array
    for (var scheduleArray in restaurantSchedules) {
      if (restaurantSchedules.hasOwnProperty(scheduleArray)) {
        // Apply filtering condition to the date (for example, filtering out dates after a certain date)
        if (
          moment(scheduleArray).format("YYYY-MM-DD") ===
          moment(scheduleValues?.date).format("YYYY-MM-DD")
        ) {
          groupedSchedules[scheduleArray] = restaurantSchedules[scheduleArray];
        }
      }
    }

    // Calculating time difference-------
    Object.values(groupedSchedules)[0]?.forEach((timeObj: any) => {
      timeDifference.push(timeObj?.time);
    });

    const generatedScheduleTimes = genTimeOptFromScheduleTime(
      calculateTimeDifference(timeDifference),
      groupedSchedules,
      scheduleValues?.date,
      restaurantTime?.openingTime,
      restaurantTime?.closingTime,
      userDeliveryAddress?.timezone
    );
    if (moment(scheduleValues?.date).format("YYYY-MM-DD") === moment(new Date()).format("YYYY-MM-DD")) {
      var newObject = {
        value: `${generatedScheduleTimes[0]?.value}@`,
        label: "ASAP",
        className: "",
      };
      generatedScheduleTimes.splice(0, 0, newObject);
      if (generatedScheduleTimes[0]?.value !== "undefined@") {
        setScheduleValues((prevValues) => {
          return {
            ...prevValues,
            time: generatedScheduleTimes[0]?.value,
          };
        });
        setGeneratedScheduleTimeOptions(generatedScheduleTimes);
      }
    }
    setGeneratedScheduleTimeOptions(generatedScheduleTimes);
  };

  const callData = async () => {
    if (_menusDataList?.quote?.hasOwnProperty('error') || (!_menusDataList?.quote?.hasOwnProperty('error') && !_menusDataList?.quote?.accepts_pickup_tip)) {
      setStoreTipValue(Tips[0]?.value);
      setStoreTipActive(Tips[0]?.value);
    }
    else {
      const filterStoreTip = Tips.filter(
        (data) => data?.value === String(user?.auto_tip_amt)
      );

      if (filterStoreTip?.length > 0) {
        setStoreTipValue(filterStoreTip[0]?.value);
        setStoreTipActive(filterStoreTip[0]?.value);
      } else if (user?.auto_tip_amt === null) {
        setStoreTipValue(Tips[0]?.value);
        setStoreTipActive(Tips[0]?.value);
      } else {
        setStoreTipValue("Other");
        setStoreTipActive(Tips[4]?.value);
        setOtherStoreTipValue("$" + user?.auto_tip_amt);
      }
    }

    if (_menusDataList?.quote?.hasOwnProperty('error') || (!_menusDataList?.quote?.hasOwnProperty('error') && !_menusDataList?.quote?.accepts_delivery_tip)) {
      setDriverTipValue(Tips[0]?.value);
      setDriverTipActive(Tips[0]?.value);
    }
    else {
      const filterDriverTip = Tips.filter(
        (data) => data?.value === String(user?.driver_auto_tip)
      );

      if (filterDriverTip?.length > 0) {
        setDriverTipValue(filterDriverTip[0]?.value);
        setDriverTipActive(filterDriverTip[0]?.value);
      } else if (user?.driver_auto_tip === null) {
        setDriverTipValue(Tips[0]?.value);
        setDriverTipActive(Tips[0]?.value);
      } else {
        setDriverTipValue("Other");
        setDriverTipActive(Tips[4]?.value);
        setOtherDriverTipValue("$" + user?.driver_auto_tip);
      }
    }
  };

  const [showProductDetailsModal, setShowProductDetailsModal] = useState(false);
  const [showFinalizeModal, setShowFinalizeModal] = useState(false);
  const [orderProcess, setOrderProcess] = useState(false);
  const [addToScheduleProcess, setAddToScheduleProcess] = useState(false);
  const [orderMealItemNullError, setOrderMealItemNullError] = useState(false);
  const [addressId, setAddressId] = useState("");
  const [addressIdError, setAddressIdError] = useState(false);
  const [paymentCardId, setPaymentCardId] = useState("");
  const [paymentCardError, setPaymentCardError] = useState(false);
  const [responseError, setResponseError] = useState("");
  const [scheduleOrderedData, setScheduleOrderedData] = useState<any>([]);
  const [showModalSuccessForAddMeal, setShowModalSuccessForAddMeal] = useState(false);
  const [scheduleTimeError, setScheduleTimeError] = useState(false);
  const [notes, setNotes] = useState("");

  const [showIonAlertModal, setShowIonAlertModal] = useState(false);
  const [ionAlertHeader, setIonAlertHeader] = useState("");
  const [ionAlertMessage, setIonAlertMessage] = useState("");
  const [restaurantTime, setRestaurantTime] = useState(
    restaurantOpenAndClosingTime(
      orderType === "delivery" ? searchedRestaurantData?.local_hours?.delivery : searchedRestaurantData?.local_hours?.pickup,
      currentDateTime,
      orderType
    )
  );

  const GotoHandle = () => {
    history.push("/restaurantDetail");
  };

  const onSaveAndAddToSchedule = async () => {
    try {
      setResponseError("");
      if (orderProcess || addToScheduleProcess || selectedSlots?.length === 0) {
        return false;
      }
      const filterSlots = deliverableSlots?.filter((el: any) => el?.attributes?.restaurant === "Open");

      if (filterSlots?.length === 0) {
        setIonAlertHeader("Sorry!");
        setIonAlertMessage("None of the slots are deliverable.");
        setShowIonAlertModal(true);
        return false;
      }

      let slotsArray = [];
      filterSlots?.forEach((obj: any) => {
        slotsArray.push({
          id: obj.id,
          date: obj.attributes.date,
          time: obj.attributes.time,
          deliveryAddress: obj.attributes?.delivery_location?.data?.id,
          user_id: obj.attributes?.user?.data?.id
        });
      });

      if (orderType === "pickup" && storeTipValue === "Other" && otherStoreTipValue === "") {
        setOtherStoreTipError(true);
      } else {
        setOtherStoreTipError(false);
      }

      if (orderType === "delivery" && driverTipValue === "Other" && otherDriverTipValue === "") {
        setOtherDriverTipError(true);
      } else {
        setOtherDriverTipError(false);
      }

      if (
        addMealItem?.length === 0 ||
        (orderType === "pickup" && (storeTipValue === "Other" && otherStoreTipValue === "") || otherStoreTipError) ||
        (orderType === "delivery" && (driverTipValue === "Other" && otherDriverTipValue === "") || otherDriverTipError)
      ) {
        return;
      }

      const item = expensiveItem(addMealItem);

      // Calculating Total Price
      const total_Price = Number(
        priceFormater(sumOfMealItemInCart(addMealItem)).slice(1)
      );
      setAddToScheduleProcess(true);

      const payloadData = {
        name: `${searchedRestaurantData?.name
          ? switchSentenceCase(
            "head text",
            textFormatter(searchedRestaurantData?.name)
          ) + "-"
          : ""
          }${switchSentenceCase("head text", textFormatter(item?.name))}`,
        items: faveMealFormatter(addMealItem),
        total: total_Price,
        slots: slotsArray,
        newMeal: true,
        user: user?.id,
        addressId: orderType === "pickup" ? userDeliveryAddress?.id : addressId,
        orderType: orderType,
        accepts_pickup_tip: _menusDataList?.quote?.accepts_pickup_tip || false,
        accepts_delivery_tip: _menusDataList?.quote?.accepts_delivery_tip || false,
        calendar_name: filterSlots[0]?.attributes?.schedule?.data?.attributes?.name
      };
      if (orderType === "pickup") {
        payloadData['storeTip'] = storeTipValue === "Other" ? Number(otherStoreTipValue?.slice(1)) : Number(storeTipValue)
      }
      if (orderType === "delivery") {
        payloadData['driverTip'] = driverTipValue === "Other" ? Number(otherDriverTipValue?.slice(1)) : Number(driverTipValue)
      }

      // ------For Checking Null Data Validation
      if (payloadData?.items?.length === 0) {
        setOrderMealItemNullError(true);
        setAddToScheduleProcess(false);
        return false;
      } else {
        await finalizeOrderForSlots(payloadData).then((res) => {
          trackPixelEvent("Finalize orders for slot");
          dispatch(removeOrderMealItem(storeId) as any);
          removeRestaurantMenuData(storeId);
          setAddToScheduleProcess(false);
          setShowModalSuccessForAddMeal(true);
        });
      }
    } catch (e) {
      // console.log(e);
      setAddToScheduleProcess(false);
    }
  };

  const orderNow = async () => {
    try {
      setResponseError("");

      if (orderProcess || addToScheduleProcess) {
        return false;
      }

      let scheduleTimeFirstIndexId = Object.values(restaurantSchedules);
      let selectedScheduleId = scheduleValues?.time?.split("@")[1] !== undefined ? scheduleValues?.time?.split("@")[1] : "";
      const timeDifferences = getTimeDifference(userDeliveryAddress?.timezone, scheduleValues?.time);
      let selectedDate = scheduleValues?.date?.split("T")[0];

      if (orderType === "delivery" && (addressId === "" || addressId === undefined)) {
        setAddressIdError(true);
        setIonAlertHeader("No Address Found");
        setIonAlertMessage("Please, Add Address.");
        setShowIonAlertModal(true);
        return false;
      } else {
        setAddressIdError(false);
      }

      if (addMealItem?.length === 0) {
        setOrderMealItemNullError(true);
      } else {
        setOrderMealItemNullError(false);
      }

      if (paymentCardId === "" || paymentCardId === undefined) {
        setPaymentCardError(true);
        setIonAlertHeader("No Payment Method Found!");
        setIonAlertMessage("Please, Add Card.");
        setShowIonAlertModal(true);
        return false;
      } else {
        setPaymentCardError(false);
      }

      if (scheduleValues?.time?.split("@")[0] === "" || scheduleValues?.time?.split("@")[0] === undefined) {
        setScheduleTimeError(true);
        setIonAlertHeader("Please select time.");
        setIonAlertMessage("");
        setShowIonAlertModal(true);
      } else {
        setScheduleTimeError(false);
      }

      if (orderType === "pickup" && storeTipValue === "Other" && otherStoreTipValue === "") {
        setOtherStoreTipError(true);
      } else {
        setOtherStoreTipError(false);
      }

      if (orderType === "delivery" && driverTipValue === "Other" && otherDriverTipValue === "") {
        setOtherDriverTipError(true);
      } else {
        setOtherDriverTipError(false);
      }

      if (
        (orderType === "delivery" && (addressId === "" || addressId === undefined || addressIdError)) ||
        addMealItem?.length === 0 ||
        paymentCardId === "" ||
        paymentCardId === undefined ||
        scheduleValues?.time?.split("@")[0] === "" ||
        scheduleValues?.time?.split("@")[0] === undefined ||
        scheduleValues?.date === "" ||
        scheduleValues?.date === undefined ||
        (orderType === "pickup" && (storeTipValue === "Other" && otherStoreTipValue === "") || otherStoreTipError) ||
        (orderType === "delivery" && (driverTipValue === "Other" && otherDriverTipValue === "") || otherDriverTipError) ||
        orderMealItemNullError ||
        paymentCardError ||
        scheduleTimeError
      ) {
        return false;
      }

      const item = expensiveItem(addMealItem);
      // Calculating Total Price
      const total_Price = Number(
        priceFormater(sumOfMealItemInCart(addMealItem)).slice(1)
      );

      setOrderProcess(true);
      const payloadData = {
        data: {
          items: faveMealFormatter(addMealItem),
          paymentCardId: paymentCardId,
          delivery_location: (orderType === "pickup") ? userDeliveryAddress?.id : addressId,
          userId: orderForSomeone?.recipientId ? orderForSomeone?.recipientId : user?.id,
          scheduled_id: Object.keys(restaurantSchedules).length === 0 ? "" : selectedDate === timeDifferences?.currentDate && timeDifferences?.differenceInMinutes < 15 ? "" : selectedScheduleId !== "" ? selectedScheduleId.split("~")[0] : scheduleTimeFirstIndexId[0][0]?.id,
          store_id: storeId,
          recipientName: orderForSomeone?.recipientName ? orderForSomeone?.recipientName : "",
          newMeal: true,
          date: selectedDate,
          time: scheduleValues?.time?.split("@")[0],
          orderType: orderType,
          discount_json: discountCouponObj,
          discount_type: discountCouponObj?.discount_type || "flat",
          discount_name: discountCouponObj?.coupon_code || "",
          discount_amt: discountCouponObj?.discount_amt || "0",
          discounted_amt: appliedCoupon !== null ? discountedPrice : "0",
          accepts_pickup_tip: _menusDataList?.quote?.accepts_pickup_tip || false,
          accepts_delivery_tip: _menusDataList?.quote?.accepts_delivery_tip || false
        },
      };

      if (orderType === "pickup") {
        payloadData.data['storeTip'] = storeTipValue === "Other" ? Number(otherStoreTipValue?.slice(1)) : Number(storeTipValue)
      }
      if (orderType === "delivery") {
        payloadData.data['driverTip'] = driverTipValue === "Other" ? Number(otherDriverTipValue?.slice(1)) : Number(driverTipValue)
        payloadData.data['instructions'] = notes
      }

      const orderData = {
        name: `${item?.restaurantName ? switchSentenceCase("head text", textFormatter(item?.restaurantName)) + "-" : ""}${switchSentenceCase("head text", textFormatter(item.name))}`,
        items: faveMealFormatter(addMealItem),
        delivery_location: (orderType === "pickup") ? userDeliveryAddress?.id : addressId,
        total: total_Price,
        newMeal: true,
        userId: orderForSomeone?.recipientId ? orderForSomeone?.recipientId : user?.id,
        type: orderForSomeone?.type ? orderForSomeone?.type : "for_me",
        from_userId: user?.id,
        date: selectedDate,
        time: scheduleValues?.time.split("@")[0],
        order_status: selectedDate === timeDifferences?.currentDate && timeDifferences?.differenceInMinutes < 15 ? "ORDERED" : "SCHEDULED",
        recipientName: orderForSomeone?.recipientName ? orderForSomeone?.recipientName : "",
        recipientNotes: orderForSomeone?.shortNotes ? orderForSomeone?.shortNotes : "",
        sponsoredBy: user?.id,
        paymentCardId: paymentCardId,
        verifiedSlot: scheduleValues?.time?.split("~")[1] === "verified" ? true : false,
        orderType: orderType,
        discount_json: discountCouponObj,
        discount_type: discountCouponObj?.discount_type || "flat",
        discount_name: discountCouponObj?.coupon_code || "",
        discount_amt: discountCouponObj?.discount_amt || "0",
        discounted_amt: appliedCoupon !== null ? discountedPrice : "0",
        accepts_pickup_tip: _menusDataList?.quote?.accepts_pickup_tip || false,
        accepts_delivery_tip: _menusDataList?.quote?.accepts_delivery_tip || false
      };

      if (orderType === "pickup") {
        orderData['storeTip'] = storeTipValue === "Other" ? Number(otherStoreTipValue?.slice(1)) : Number(storeTipValue)
      }
      if (orderType === "delivery") {
        orderData['driverTip'] = driverTipValue === "Other" ? Number(otherDriverTipValue?.slice(1)) : Number(driverTipValue)
        orderData['instructions'] = notes
      }

      await previewOrder(payloadData).then((res) => {
        trackPixelEvent("Preview order");
        const scheduleResData = res?.data;
        const newData = {
          ...scheduleResData,
          orderData: orderData,
        };
        setScheduleOrderedData(newData);
        setShowFinalizeModal((prev) => !prev);
        setShowWarningModal(false);
        setOrderProcess(false);
        trackPixelEvent("Preview Order");
      });
    } catch (e) {
      // console.log(e);
      setResponseError(e?.response?.data?.data?.error);
      setOrderProcess(false);
    }
  };

  const orderForSelectedSlots = async () => {
    try {
      setResponseError("");

      let slotsArray = [];

      if (orderProcess || addToScheduleProcess) {
        return false;
      }

      const scheduleTimeFirstIndexId = Object.values(restaurantSchedules);
      const filterSlots = deliverableSlots?.filter((el: any) => el?.attributes?.restaurant === "Open");

      filterSlots?.forEach((obj: any) => {
        slotsArray.push({
          id: obj.id,
          date: obj.attributes.date,
          time: obj.attributes.time,
          deliveryAddress: obj.attributes?.delivery_location?.data?.id,
          user_id: obj.attributes?.user?.data?.id
        });
      });

      if (filterSlots?.length === 0) {
        setIonAlertHeader("Sorry!");
        setIonAlertMessage("None of the selected slots are deliverable.");
        setShowIonAlertModal(true);
        return false;
      }

      const providedDateTime = mergeSlotDateTime(filterSlots[0]?.attributes?.date, filterSlots[0]?.attributes?.time); // Replace with your provided date and time
      const filteredSchedule = filterRestaurantSchedulesByDateTime(restaurantSchedules, providedDateTime);

      if (orderType === "delivery" && (addressId === "" || addressId === undefined)) {
        setAddressIdError(true);
        setIonAlertHeader("No Address Found");
        setIonAlertMessage("Please, Add Address.");
        setShowIonAlertModal(true);
        return false;
      } else {
        setAddressIdError(false);
      }

      if (addMealItem?.length === 0) {
        setOrderMealItemNullError(true);
      } else {
        setOrderMealItemNullError(false);
      }

      if (paymentCardId === "" || paymentCardId === undefined) {
        setPaymentCardError(true);
        setIonAlertHeader("No Payment Method Found!");
        setIonAlertMessage("Please, Add Card.");
        setShowIonAlertModal(true);
        return false;
      } else {
        setPaymentCardError(false);
      }

      if (orderType === "pickup" && storeTipValue === "Other" && otherStoreTipValue === "") {
        setOtherStoreTipError(true);
      } else {
        setOtherStoreTipError(false);
      }

      if (orderType === "delivery" && driverTipValue === "Other" && otherDriverTipValue === "") {
        setOtherDriverTipError(true);
      } else {
        setOtherDriverTipError(false);
      }

      if (
        (orderType === "delivery" && (addressId === "" || addressId === undefined || addressIdError)) ||
        addMealItem?.length === 0 ||
        paymentCardId === "" ||
        paymentCardId === undefined ||
        (orderType === "pickup" && (storeTipValue === "Other" && otherStoreTipValue === "") || otherStoreTipError) ||
        (orderType === "delivery" && (driverTipValue === "Other" && otherDriverTipValue === "") || otherDriverTipError) ||
        orderMealItemNullError ||
        paymentCardError
      ) {
        return false;
      }
      const item = expensiveItem(addMealItem);

      // Calculating Total Price
      const total_Price = Number(
        priceFormater(sumOfMealItemInCart(addMealItem)).slice(1)
      );

      setOrderProcess(true);
      const payloadData = {
        data: {
          items: faveMealFormatter(addMealItem),
          paymentCardId: paymentCardId,
          delivery_location: (orderType === "pickup") ? userDeliveryAddress?.id : addressId,
          userId: filterSlots[0]?.attributes?.user?.data?.id,
          // scheduled_id: Object.keys(restaurantSchedules).length === 0 ? "" : scheduleTimeFirstIndexId[0][0]?.id,
          scheduled_id: filteredSchedule !== undefined && filteredSchedule.length !== 0 ? filteredSchedule[0]?.id : Object.keys(restaurantSchedules).length > 0 ? scheduleTimeFirstIndexId[0][0]?.id : "",
          store_id: storeId,
          newMeal: true,
          date: filterSlots[0]?.attributes?.date,
          time: filterSlots[0]?.attributes?.time,
          orderType: orderType,
          discount_json: discountCouponObj,
          discount_type: discountCouponObj?.discount_type || "flat",
          discount_name: discountCouponObj?.coupon_code || "",
          discount_amt: discountCouponObj?.discount_amt || "0",
          discounted_amt: appliedCoupon !== null ? discountedPrice : "0",
          accepts_pickup_tip: _menusDataList?.quote?.accepts_pickup_tip || false,
          accepts_delivery_tip: _menusDataList?.quote?.accepts_delivery_tip || false
        },
      };
      if (orderType === "pickup") {
        payloadData.data['storeTip'] = storeTipValue === "Other" ? Number(otherStoreTipValue?.slice(1)) : Number(storeTipValue)
      }
      if (orderType === "delivery") {
        payloadData.data['driverTip'] = driverTipValue === "Other" ? Number(otherDriverTipValue?.slice(1)) : Number(driverTipValue)
        payloadData.data['instructions'] = notes
      }
      const orderData = {
        name: `${item?.restaurantName ? switchSentenceCase("head text", textFormatter(item?.restaurantName)) + "-" : ""}${switchSentenceCase("head text", textFormatter(item.name))}`,
        items: faveMealFormatter(addMealItem),
        total: total_Price,
        newMeal: true,
        user: filterSlots[0]?.attributes?.user?.data?.id,
        sponsoredBy: user?.id,
        slots: slotsArray,
        paymentCardId: paymentCardId,
        addressId: (orderType === "pickup") ? userDeliveryAddress?.id : addressId,
        orderType: orderType,
        discount_json: discountCouponObj,
        discount_type: discountCouponObj?.discount_type || "flat",
        discount_name: discountCouponObj?.coupon_code || "",
        discount_amt: discountCouponObj?.discount_amt || "0",
        discounted_amt: appliedCoupon !== null ? discountedPrice : "0",
        accepts_pickup_tip: _menusDataList?.quote?.accepts_pickup_tip || false,
        accepts_delivery_tip: _menusDataList?.quote?.accepts_delivery_tip || false,
        calendar_name: filterSlots[0]?.attributes?.schedule?.data?.attributes?.name
      };
      if (orderType === "pickup") {
        orderData['storeTip'] = storeTipValue === "Other" ? Number(otherStoreTipValue?.slice(1)) : Number(storeTipValue)
      }
      if (orderType === "delivery") {
        orderData['driverTip'] = driverTipValue === "Other" ? Number(otherDriverTipValue?.slice(1)) : Number(driverTipValue)
        orderData['instructions'] = notes
      }
      await previewOrder(payloadData).then((res) => {
        trackPixelEvent("Preview order");
        const scheduleResData = res?.data;
        const newData = {
          ...scheduleResData,
          selectedSlots: slotsArray,
          orderData: orderData,
        };
        setScheduleOrderedData(newData);
        setShowWarningModal(false);
        setShowFinalizeModal((prev) => !prev);
        setOrderProcess(false);
      });
    } catch (e) {
      // console.log(e);
      setShowWarningModal(false);
      setResponseError(e?.response?.data?.data?.error);
      setOrderProcess(false);
    }
  };

  const onPushLocation = (id: any, index: any) => {
    dispatch(addMealId(id, index) as any);
    setShowProductDetailsModal(true);
  };

  const handleCloseModal = (value: any) => {
    setShowProductDetailsModal(value);
  };

  const handleAddressId = (id: any) => {
    setAddressId(id);
    setAddressIdError(false);
  };

  const handlePaymentId = (paymentCardId: any) => {
    setPaymentCardId(paymentCardId);
    setPaymentCardError(false);
  };

  const handleOnDateChange = (e: any) => {
    const { name, value } = e.target;
    setResponseError("");
    if (value === "") {
    } else {
      setScheduleValues((prevValues) => {
        return {
          ...prevValues,
          [name]: value,
          time: "",
        };
      });
      setRestaurantTime(
        restaurantOpenAndClosingTime(
          orderType === "delivery" ? searchedRestaurantData?.local_hours?.delivery : searchedRestaurantData?.local_hours?.pickup,
          value,
          orderType
        )
      );
      filterScheduleDates();
    }
  };

  const handleOnTimeChange = (value: any) => {
    setResponseError("");
    setScheduleTimeError(false);
    if (value === "") {
      setScheduleTimeError(true);
    } else {
      setScheduleTimeError(false);
      setScheduleValues((prevValues) => {
        return {
          ...prevValues,
          time: value,
        };
      });
    }
  };

  const handleWarningModal = () => {
    const filterSlots = deliverableSlots?.filter((el: any) => el?.attributes?.restaurant === "Open");
    if (filterSlots?.length === 0) {
      setIonAlertHeader("Sorry!");
      setIonAlertMessage("None of the slots are deliverable.");
      setShowIonAlertModal(true);
      return false;
    } else {
      setShowWarningModal(true);
    }
  };

  const handleChangeNotes = (val: string) => {
    setNotes(val.substring(0, 300));
    if (String(val).trim().length >= 300) {
      return showAlert("", "You exceed the maximum limit.", "OK");
    }
  };

  const handleKeyDown = (e: any) => {
    if (notes.length >= 300) {
      if (e.key === " " || e.keyCode === 32) {
        return showAlert("", "You exceed the maximum limit.", "OK");
      }
    }
  };

  function isAnyItemSelected(array: any) {
    // Iterate through the array
    for (const obj of array) {
      // Check if the 'is_selected' key is true
      if (obj.hasOwnProperty("is_selected") && obj.is_selected === true) {
        // If true, return true
        return true;
      }

      // If the object has an 'options' array, recursively check it
      if (obj.hasOwnProperty("options") && Array.isArray(obj.options)) {
        if (isAnyItemSelected(obj.options)) {
          return true;
        }
      }
    }

    // If no 'is_selected' is true, return false
    return false;
  }


  return (
    <div className="screen">
      <TopHead
        title={switchSentenceCase(
          "head text",
          `${scheduleDetails?.attributes?.name
            ? scheduleDetails?.attributes?.name
            : searchedRestaurantData?.name
          }`
        )}
        leftTextIcon={faAngleLeft}
        leftIconStyle={{ fontSize: "24px" }}
        goToHandle={GotoHandle}
      />

      <Divider />

      <div className="grow overflow-auto">
        <Section>
          <Container>
            {/********************  Meal Cart Section ******************************/}

            <Accordion title="Meal Items" opened={true}>
              {addMealItem?.length > 0 &&
                addMealItem?.map((list: any, index: any) => {
                  return (
                    <React.Fragment key={index}>
                      <Flex>
                        <ItemCount>{index + 1}</ItemCount>
                        <BlockContainer>
                          <FlexBetween>
                            <Flex>
                              <TextHead4
                                onClick={() => onPushLocation(list?.product_id, index)}
                                className="underline cursor-pointer"
                              >
                                {switchSentenceCase(
                                  "head text",
                                  properNameFormate(list?.name)
                                )}
                              </TextHead4>
                              {list?.instructions !== "" && (
                                <ToolTip instructions={list?.instructions} />
                              )}
                            </Flex>
                            <TextHead5
                              className="text-green-600 underline !font-medium cursor-pointer !m-2"
                              onClick={() => onPushLocation(list?.product_id, index)}
                            >
                              EDIT
                            </TextHead5>
                          </FlexBetween>
                          <InitialDiv>
                            {list?.customizationList.length > 0 &&
                              isAnyItemSelected(list?.customizationList) && (
                                <>
                                  <AccordionCheckoutCustomization
                                    title={"Selected Customizations"}
                                    titleSize={"16px"}
                                  >
                                    <div className="block">
                                      <CheckoutProductCustomization
                                        customizations={list?.customizationList}
                                      />
                                    </div>
                                  </AccordionCheckoutCustomization>
                                </>
                              )}
                          </InitialDiv>

                          <div className="flex justify-between items-center">
                            <QuantitySelectorGlobal
                              selected_quantity={list?.quantity}
                              increaseQuantity={() => {
                                dispatch(
                                  changeQuantity(
                                    storeId,
                                    list?.product_id,
                                    Number(list?.quantity) + 1
                                  ) as any
                                );

                                dispatch(
                                  changeQuantityOfAddOrderMealItem(
                                    list?.product_id,
                                    Number(list?.quantity) + 1,
                                    index
                                  ) as any
                                );
                              }}
                              decreaseQuantity={() => {
                                if (Number(list?.quantity) > 1) {
                                  dispatch(
                                    changeQuantity(
                                      storeId,
                                      list?.product_id,
                                      Number(list?.quantity) - 1
                                    ) as any
                                  );

                                  dispatch(
                                    changeQuantityOfAddOrderMealItem(
                                      list?.product_id,
                                      Number(list?.quantity) - 1,
                                      index
                                    ) as any
                                  );
                                } else {
                                  dispatch(
                                    changeQuantity(
                                      storeId,
                                      list?.product_id,
                                      Number(0)
                                    ) as any
                                  );
                                  dispatch(removeSingleCartItem(list?.product_id, index) as any);
                                }
                              }}
                            />
                            <h4 className="font-semibold">
                              {priceFormater(
                                increasePriceByQuantity(list?.price, list?.quantity)
                              )}
                              {/* itemPlusOptionPrice(list?.price, list?.customizationList) */}
                            </h4>
                          </div>
                        </BlockContainer>
                      </Flex>
                      <hr className="mt-2 mb-2" />
                    </React.Fragment>
                  );
                })}

              {orderMealItemNullError ? (
                <TextHead5 style={{ color: "red" }}>
                  Please, Add atleast one item !
                </TextHead5>
              ) : null}
            </Accordion>
            <TextHead5
              className="text-green-600 underline italic cursor-pointer"
              onClick={() => history.push(`/restaurantDetail/${storeId}?type=${orderType}`)}
            >
              Add Another Item...
            </TextHead5>
            <InitialDiv>
              <Flex>
                <FontAwesomeIcon
                  icon={faStore}
                  className="text-gray-600 text-s mt-1"
                />
                <TextHead3 className="ml-2">
                  {searchedRestaurantData?.name}
                </TextHead3>
              </Flex>
              <InitialDiv className="flex mb-4">
                <FontAwesomeIcon
                  icon={faCircleInfo}
                  className={
                    restaurantTime?.openingTime === ""
                      ? "mt-[5px] mr-1 text-red-600"
                      : "mt-[5px] mr-1 text-[#7a7a7aba]"
                  }
                />
                <TextHead5
                  style={{ fontWeight: "500", marginLeft: "4px" }}
                  className={
                    restaurantTime?.openingTime === ""
                      ? "text-red-600"
                      : "text-[#7a7a7aba]"
                  }
                >
                  {restaurantTime?.restaurantAvailability}
                </TextHead5>
              </InitialDiv>
            </InitialDiv>

            {/********************  Schedule Section ******************************/}

            {deliverableSlots?.length === 0 ? (
              <>
                {/* <FlexBetween> */}
                <Flex className="mt-4">
                  <TextHead3 className="mb-1">
                    {`${(orderType === "delivery") ? "Delivery" : "Pickup"}  Date/Time`}
                  </TextHead3>
                </Flex>
                <InitialDiv className="text-left text-[#7a7a7aba] flex mb-4">
                  <FontAwesomeIcon
                    icon={faCircleInfo}
                    className="mt-[7px] mr-1"
                  />
                  <TextHead5 style={{ fontWeight: "500", marginLeft: "4px" }}>
                    {`${(orderType === "delivery") ? "Delivery" : "Pickup"} time is our best estimate. Green indicates confirmed appointments, while black will either be confirmed on the morning of ${(orderType === "delivery") ? "delivery" : "pickup"}, an hour before, or canceled. The time zone is based on the ${(orderType === "delivery") ? "delivery" : "pickup"} location.`}
                  </TextHead5>
                </InitialDiv>
                {/* </FlexBetween> */}
                <InitialDiv className="">
                  <div className="flex">
                    <FontAwesomeIcon icon={faCalendarPen} className="mt-1" />
                    <div className="ml-3 w-full">
                      <TextHead4 className="flex justify-between">
                        Select Date
                      </TextHead4>
                      <SubText2 className="mt-2">
                        <IonDatetimeButton
                          className="my-2.5"
                          datetime="date"
                          style={{ border: "1px solid #e2e2e2", borderRadius: "8px" }}
                        ></IonDatetimeButton>
                        <IonModal keepContentsMounted={true}>
                          <IonDatetime
                            className={isDarkMode ? "calendar-color" : ""}
                            name="date"
                            value={scheduleValues?.date}
                            color={isDarkMode ? "dark" : "success"}
                            onIonChange={(e: any) => handleOnDateChange(e)}
                            min={minDate}
                            showDefaultButtons={true}
                            presentation="date"
                            preferWheel={false}
                            id="date"
                          ></IonDatetime>
                        </IonModal>
                      </SubText2>
                    </div>
                  </div>
                  <div className="flex mt-2">
                    <FontAwesomeIcon icon={faClockThree} className="mt-1" />
                    <div className="ml-3 w-full">
                      <TextHead4 className="flex justify-between">
                        Select Time
                      </TextHead4>
                      <SubText2 className="mt-2">
                        <Select
                          allowClear
                          showSearch
                          size="large"
                          value={
                            scheduleValues?.time
                              ? scheduleValues?.time
                              : moment(scheduleValues?.date).format(
                                "YYYY-MM-DD"
                              ) === moment(new Date()).format("YYYY-MM-DD")
                                ? generatedScheduleTimeOptions[0]
                                : ""
                          }
                          optionFilterProp="label"
                          notFoundContent={
                            <SubText1 className="m-2">
                              No Schedule Found !
                            </SubText1>
                          }
                          bordered={false}
                          onChange={(e: any) => handleOnTimeChange(e)}
                          options={generatedScheduleTimeOptions}
                          className={isDarkMode ? "time-selector" : ""}
                          style={{
                            width: "100%",
                            textAlign: "left",
                            margin: "10px 0px",
                            border: scheduleTimeError
                              ? "1px solid red"
                              : "1px solid #e2e2e2",
                            borderRadius: scheduleTimeError ? "10px" : "8px",
                          }}
                        />
                      </SubText2>
                    </div>
                  </div>
                </InitialDiv>
              </>
            ) : (
              <>
                {/* <TextHead3 className="mb-1">Selected Slot
                </TextHead3> */}

                <FlexBetween>
                  <Flex
                    className="items-center mt-4"
                    style={{ alignContent: "center" }}
                  >
                    <TextHead3 className="mb-1">
                      {`Selected${deliverableSlots?.length === 1 ? " Slot" : " Slots"
                        }`}
                    </TextHead3>
                  </Flex>
                </FlexBetween>
                {deliverableSlots?.map((item: any, index: any) => (
                  <React.Fragment key={index}>
                    <FlexBetween
                      style={{ alignItems: "center", marginBottom: "2px" }}
                    >
                      <Flex className="items-center">
                        {/* <input type="checkbox" style={{ height: '14px', marginTop: "8px" }} className="w-8 accent-green-600 cursor-pointer" id={item?.id} checked={item?.attributes?.is_selected} disabled={item?.attributes?.sponsoredBy?.data !== null} name={item?.id} onChange={(e) => handleCheckBox((!(item?.attributes?.is_selected)), item?.id, index)} /> */}

                        <p style={{ fontSize: "17px", margin: "2px 0px" }}>
                          {slotDateTimeFormatter(
                            item?.attributes?.date,
                            item?.attributes?.time
                          )}{" "}
                          {""}
                          <span
                            style={{
                              fontWeight: "350",
                              color: "#dc2626",
                              fontSize: "17px",
                            }}
                            className={
                              item?.attributes?.restaurant === "Open"
                                ? "!text-green-600"
                                : "!text-red-600"
                            }
                          >
                            {item?.attributes?.restaurant === "Open"
                              ? "[Deliverable]"
                              : "[Undeliverable]"}
                          </span>
                          {item?.attributes?.restaurant === "Close" && (
                            <ToolTip
                              instructions={item?.attributes?.infoMessage}
                            />
                          )}
                          {/* <span style={(item?.attributes?.favMeal?.data !== null) ? { fontWeight: "350", fontSize: "17px" } : { fontWeight: "350", color: "#dc2626", fontSize: "17px" }}>Empty Slot</span> */}
                          {/* {(item?.attributes?.sponsoredBy?.data !== null) &&
                          <SubText1>
                            {switchSentenceCase("head text", `Sponsored By ${item?.attributes?.sponsoredBy?.data?.attributes?.name}`)}
                          </SubText1>
                        } */}
                        </p>
                      </Flex>
                    </FlexBetween>
                  </React.Fragment>
                ))}
              </>
            )}

            {/********************  Store Tip Section ******************************/}
            {orderType === "pickup" && (
              <InitialDiv className="my-4">
                <TextHead3 className="">Store Tip</TextHead3>
                {!_menusDataList?.quote?.hasOwnProperty('error') && !_menusDataList?.quote?.accepts_pickup_tip && (
                  <>
                    <FontAwesomeIcon icon={faCircleInfo} className="text-red-500 mt-2 mr-1" />
                    <span
                      style={{
                        fontWeight: "350",
                        color: "#dc2626",
                        fontSize: "17px",
                      }}
                    >
                      Store tip is not available for this restaurant.
                    </span>
                  </>
                )}
                <TabsForTipPreference
                  types={Tips}
                  setTipValue={setStoreTipValue}
                  setActive={setStoreTipActive}
                  disabled={!_menusDataList?.quote?.hasOwnProperty('error') && !_menusDataList?.quote?.accepts_pickup_tip}
                  selected={storeTipActive}
                />
                {storeTipValue === "Other" ? (
                  <InputText
                    type="text"
                    inputMode="numeric"
                    style={{
                      border: otherStoreTipError ? "1px solid red" : "",
                    }}
                    min={0}
                    value={otherStoreTipValue}
                    disabled={!_menusDataList?.quote?.hasOwnProperty('error') && !_menusDataList?.quote?.accepts_pickup_tip}
                    placeholder="Enter Amount"
                    onChange={(event: any) =>
                      handleOtherStoreTip(event.target.value)
                    }
                  />
                ) : null}
              </InitialDiv>
            )}

            {/********************  Delivery Pickup ******************************/}
            {/* <OneSideTextToggle
              leftText={"Delivery Pickup"}
              style={{ marginLeft: "-10px", fontSize: "20px", fontWeight: "500" }}
              switchState={deliveryPickup}
              handleOnChangeToggle={() => setDeliveryPickup(prev => !prev)}
            /> */}

            {/********************  Driver's Tip Section ******************************/}
            {orderType === "delivery" && (
              <InitialDiv className="my-4">
                <TextHead3 className="">Driver's Tip</TextHead3>
                {!_menusDataList?.quote?.hasOwnProperty('error') && !_menusDataList?.quote?.accepts_delivery_tip && (
                  <>
                    <FontAwesomeIcon icon={faCircleInfo} className="text-red-500 mt-2 mr-1" />
                    <span
                      style={{
                        fontWeight: "350",
                        color: "#dc2626",
                        fontSize: "17px",
                      }}
                    >
                      Driver tip is not available for this restaurant.
                    </span>
                  </>
                )}
                <TabsForTipPreference
                  types={Tips}
                  setTipValue={setDriverTipValue}
                  setActive={setDriverTipActive}
                  disabled={!_menusDataList?.quote?.hasOwnProperty('error') && !_menusDataList?.quote?.accepts_delivery_tip}
                  selected={driverTipActive}
                />
                {driverTipValue === "Other" ? (
                  <InputText
                    type="text"
                    inputMode="numeric"
                    style={{
                      border: otherDriverTipError ? "1px solid red" : "",
                    }}
                    min={0}
                    value={otherDriverTipValue}
                    disabled={!_menusDataList?.quote?.hasOwnProperty('error') && !_menusDataList?.quote?.accepts_delivery_tip}
                    placeholder="Enter Amount"
                    onChange={(event: any) => handleOtherDriverTip(event.target.value)}
                  />
                ) : null}
              </InitialDiv>
            )
            }

            {/********************  Address Section ******************************/}
            {orderType === "delivery" &&
              <InitialDiv>
                <Accordion title="Delivery Address" opened={true}>
                  <AddressesCart
                    title=""
                    addressId={handleAddressId}
                    showDeleteBtn={false}
                    showList={false}
                    pageName="newMealCheckout"
                    pageDataId={orderForSomeone?.addressId ? orderForSomeone?.addressId : scheduleDetails?.attributes?.delivery_location?.data ? scheduleDetails?.attributes?.delivery_location?.data?.id : userDeliveryAddress?.id}
                    changeAddressTxtBtn="CHANGE ADDRESS"
                    userId={orderForSomeone?.recipientId ? orderForSomeone?.recipientId : scheduleDetails?.attributes?.user?.data ? scheduleDetails?.attributes?.user?.data?.id : user?.id}
                    setDeliveryAddress={false}
                    changeAddress={selectedSlots?.length > 0 ? false : true}
                    initialLength={1}
                  />
                  {addressIdError && (
                    <TextHead5 style={{ color: "red" }}>
                      Address is required.
                    </TextHead5>
                  )}
                </Accordion>
              </InitialDiv>
            }

            {/********************  Payment Section ******************************/}
            <InitialDiv className="my-4">
              <Accordion
                title="Payment"
                opened={
                  paymentCardId === "" || paymentCardId === undefined
                    ? true
                    : false
                }
              >
                <PaymentCart
                  paymentId={handlePaymentId}
                  userId={user?.id}
                  changePayment={true}
                  selectedCard={null}
                />
                {paymentCardError && (
                  <TextHead5 style={{ color: "red" }}>
                    Payment is required.
                  </TextHead5>
                )}
              </Accordion>
            </InitialDiv>

            {/********************  Notes Section ******************************/}
            {orderType === "delivery" &&
              <InitialDiv>
                <TextHead3 className="">Drop Off Notes</TextHead3>
                <SubText1 className="text-right mt-1">
                  {notes?.length}/{maxCharacterLimit}
                </SubText1>
                <TextArea1
                  className="!mt-0"
                  placeholder=""
                  name="description"
                  value={notes}
                  onChange={(event: any) => handleChangeNotes(event.target.value)}
                  onKeyDown={(e) => handleKeyDown(e)}
                // maxLength={maxCharacterLimit}
                />
              </InitialDiv>
            }

            {/********************  Discount Section ******************************/}
            {process.env.REACT_APP_ENABLED_DISCOUNT_COUPON === "true" &&
              <InitialDiv className="my-4">
                <Accordion title="Apply Coupon" opened={true}>
                  {
                    couponDetails?.status
                      ?
                      couponDetails?.data?.map((list: any, index: any) => {
                        return (
                          <React.Fragment key={index}>
                            <CardApplyCoupon
                              couponCode={list?.coupon_code}
                              description={list?.description}
                              discountType={list?.discount_type}
                              discountAmt={list?.discount_amt}
                              cartValue={priceFormater(sumOfMealItemInCart(addMealItem)).slice(1)}
                              handleApply={handleApplyCoupon}
                              handleRemove={handleRemoveCoupon}
                              isApplied={appliedCoupon === list?.coupon_code}
                            />
                          </React.Fragment>
                        )
                      })
                      :
                      <SubText2 className="text-center">{couponDetails?.data?.message}</SubText2>
                  }

                </Accordion>
              </InitialDiv>
            }

            <FlexBetween>
              <TextHead4 className="!font-medium whitespace-nowrap">
                Subtotal
              </TextHead4>
              <TextHead4 className="!font-medium whitespace-nowrap">
                {priceFormatterUsd(sumOfMealItemInCart(addMealItem))}
              </TextHead4>
            </FlexBetween>
            {appliedCoupon !== null &&
              <>
                <FlexBetween className="mt-2">
                  <SubText1 className="!font-medium whitespace-nowrap">
                    Discount
                  </SubText1>
                  <SubText1 className="!font-medium whitespace-nowrap">
                    ${(discountPrice).toFixed(2)}
                  </SubText1>
                </FlexBetween>
                <FlexBetween className="mt-2">
                  <SubText1 className="!font-medium whitespace-nowrap">
                    Discounted Price
                  </SubText1>
                  <SubText1 className="!font-medium whitespace-nowrap">
                    ${discountedPrice}
                  </SubText1>
                </FlexBetween>
              </>
            }
          </Container>

          {/* <Divider /> */}

          {orderProcess && !showWarningModal ? (
            <div className="text-center">
              <Spinner />
            </div>
          ) : (
            <ButtonGreen
              onClick={() =>
                deliverableSlots?.length === 0
                  ? orderNow()
                  : orderForSelectedSlots()
              }
              disabled={addToScheduleProcess}
              className={
                (addToScheduleProcess) && "!cursor-not-allowed opacity-30"
              }
            >
              {switchSentenceCase(
                "primary button",
                "CONTINUE TO PREVIEW ORDER"
              )}
            </ButtonGreen>
          )}

          {scheduleDetails?.attributes?.user?.data?.id === user?.id && (
            <>
              {addToScheduleProcess && !showWarningModal ? (
                <div className="text-center">
                  <Spinner />
                </div>
              ) : (
                <ButtonGreen
                  style={{ margin: "10px 0px" }}
                  onClick={() => handleWarningModal()}
                  disabled={orderProcess || addToScheduleProcess}
                  className={
                    (orderProcess || addToScheduleProcess) &&
                    "!cursor-not-allowed opacity-30"
                  }
                >
                  {switchSentenceCase(
                    "primary button",
                    "Save and Come Back Later"
                  )}
                </ButtonGreen>
              )}
            </>
          )}
          {responseError && (
            <p className="text-red-600 text-center">
              {responseError === "Scheduled id is not valid or not available."
                ? "Something went wrong. Please retry or 'Contact Us' in your profile."
                : responseError}
            </p>
          )}
        </Section>
      </div>

      {/* ********************* Modal Product Details ****************************** */}
      {showProductDetailsModal && (
        <ModalProductDetails
          showProductDetailsModal={showProductDetailsModal}
          setShowProductDetailsModal={handleCloseModal}
          pageName="FromOrderPage"
          btnText="Update Changes"
        />
      )}

      {/* ********************* Modal Finalize Order ****************************** */}
      {showFinalizeModal && (
        <ModalFinalizeOrder
          showFinalizeModal={showFinalizeModal}
          setShowFinalizeModal={setShowFinalizeModal}
          finalizeData={scheduleOrderedData}
          storeId={storeId}
        />
      )}

      {/* ********************* Modal Confirmation ****************************** */}

      {showWarningModal && (
        <ModalSuccessForCards
          firstText={
            "To schedule the delivery, you or a sponsor will need to finalize the order at least 1 hour prior to the scheduled delivery time."
          }
          secondText=""
          btnOneText="I understand"
          btnTwoText="Let’s finalize the order now"
          btnOneStyle={{ marginTop: "15px" }}
          btnTwoStyle={{
            backgroundColor: "#b3b3b3",
            marginTop: "15px",
            color: "#ffffff",
          }}
          btnOneGoto={() => {
            onSaveAndAddToSchedule();
          }}
          btnTwoGoto={() => {
            orderForSelectedSlots();
          }}
          showModal={showWarningModal}
          setShowModal={setShowWarningModal}
          buttonOneLoader={addToScheduleProcess}
          buttonTwoLoader={orderProcess}
          buttonOneDisabled={orderProcess || addToScheduleProcess}
          buttonTwoDisabled={orderProcess || addToScheduleProcess}
        />
      )}

      {/* ---------------------------------Modal Success for Add Meal to schedule------------------- */}
      <ModalSuccess
        contentDivStyle={{ height: "auto" }}
        firstText={"Meal Added to Schedule Successfully!"}
        btnOneText="OKAY"
        btnOneGoto={() => {
          setShowModalSuccessForAddMeal((prev: any) => !prev);
          history.push(`/calendar-slots/${scheduleDetails?.id}`);
        }}
        showModal={showModalSuccessForAddMeal}
        setShowModal={setShowModalSuccessForAddMeal}
      />

      {/* ********************* Alert Modal for validations ****************************** */}

      <IonModalAlert
        isOpened={showIonAlertModal}
        setIsOpened={setShowIonAlertModal}
        header={ionAlertHeader}
        subHeader=""
        message={ionAlertMessage}
      />
    </div>
  );
}
