import { faAngleRight } from "@fortawesome/pro-regular-svg-icons";
import { faAngleLeft, faShareAll } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IonAlert, useIonRouter, useIonViewDidEnter } from "@ionic/react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { RWebShare } from "react-web-share";
import styled from 'styled-components';
import { AccordDiv, ButtonGray, ButtonGreen, Flex, FlexBetween, ItemCount, SubText1, TextHead4, TextHead5 } from "../../components/styled/styled";
import AddressesCart from "../../components/widgets/address/AdressesCart";
import Divider from "../../components/widgets/divider/Divider";
import TopHead from "../../components/widgets/head/TopHead";
import ModalEditMealSchedule from "../../components/widgets/modals/ModalEditMealSchedule";
import ModalRemoved from "../../components/widgets/modals/ModalRemoved";
import ModalSuccess from "../../components/widgets/modals/ModalSuccess";
import ModalSpinner from "../../components/widgets/modalspinner/ModalSpinner";
import Section from "../../components/widgets/section/Section";
import Spinner from "../../components/widgets/spinner/Spinner";
import store from '../../redux/store';
import { declineCalendar, getScheduleBySlug } from "../../services/schedule";
import { getUserInfo } from "../../services/user";
import { printTimeSlots, scheduleSAndFillSlotDateFormatter, switchSentenceCase, trackPixelEvent } from "../../utils/utilities";
import { setScheduleDetailsRedux, setScheduleSlotsRedux, updateSchedules } from "../schedules/redux/createScheduleReduxActions";


const Container = styled.div`
   margin: 35px 0px 35px 5px;
`;


const BlockContainer = styled.div`
    display: block;
    width: 100%;
    text-align: left;
`;


const Block = styled.div`
`;



export default function ScheduleDetailsRecipientsCreated(data: any) {

  const FRONTEND_URL = process.env.REACT_APP_URL;
  const history = useHistory();
  const dispatch = useDispatch();
  const { schedule, user } = useParams<any>();

  const userData = getUserInfo();

  // const isLocalStorageDataAvailable = (userData?.slug == user?.slice(0, -1) || userData?.id == user?.slice(0, -1)) ? true : false;
  const isLocalStorageDataAvailable = (userData?.id) ? true : false;

  const router = useIonRouter();
  const pagepath = router?.routeInfo?.pathname?.includes('http') ? router?.routeInfo?.pathname : FRONTEND_URL + "/#" + router?.routeInfo?.pathname;

  const [loading, setLoading] = useState(true);
  const [loader, setLoader] = useState(false);
  const [showModalRemove, setShowModalRemove] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [unAuthorize, setUnAuthorize] = useState(false);
  const [showModalSuccessForDeclineMeal, setShowModalSuccessForDeclineMeal] = useState(false);

  const GotoHandle = () => {
    history.push({
      pathname: '/calendar-home',
    })
  }

  const { scheduleDetails, scheduleEmails } = useSelector((state: any) => state?.createScheduleForMe);

  useEffect(() => {
    //***************** CHECK USER SESSION  *****************//
    if (isLocalStorageDataAvailable) {
      (async () => {
        try {
          // const checkAuthorization = await checkCalendarAuthentication(userData?.id, schedule);
          // if (checkAuthorization?.data?.authorize) {
          //   trackPixelEvent('Check calendar auth');
          //   setUnAuthorize(false);
          // }
          // else {
          //   setUnAuthorize(true);
          // }
          if (!schedule) {
            setLoading(false);
            return false;
          }
          else {
            const response = await getScheduleBySlug(schedule);
            trackPixelEvent('Get schedule by slug');
            let data = response?.data?.data[0]
            setScheduleDetailsRedux(data);
            if (data?.attributes?.user?.data?.id === userData?.id || data?.attributes?.from_user?.data?.id === userData?.id || data?.attributes?.from_user?.data?.attributes?.slug === userData?.slug || data?.attributes?.user?.data?.attributes?.slug === userData?.slug) {
              setLoading(false);
            }
            else {
              history.push({ pathname: `/calendar-slots/${encodeURIComponent(data?.id)}` })
            }
          }
        }
        catch (e) {
          console.log(e);
          setLoading(false);
        }
      })();
    }
    //if (user?.slice(0, -1) != userData?.slug && user?.slice(0, -1) != userData?.id)
    else {
      store.dispatch({
        type: "SET_LOGIN_STATE",
        payload: { schedule, user }
      })
      store.dispatch({
        type: "SHOW_ALERT",
        payload: { show: true }
      })
    }
    // })();
  }, [schedule]);

  // useEffect(() => {
  //   setLoading(true);
  //   callData();
  // }, [schedule]);


  useIonViewDidEnter(() => {
    trackPixelEvent('View calendar');
  }, []);


  // const callData = async () => {
  //   try {
  //     // || (user?.slice(0, -1) != userData?.slug && user?.slice(0, -1) != userData?.id))
  //     if (!schedule) {
  //       // setLoading(false);
  //       return false;
  //     }
  //     else {
  //       const response = await getScheduleBySlug(schedule);
  //       setScheduleDetailsRedux(response?.data?.data[0]);
  //       trackPixelEvent('Get schedule by slug');
  //       setLoading(false);
  //     }
  //   }
  //   catch (e) {
  //     console.log(e);
  //     setLoading(false);
  //   }
  // }

  const [showModal, setShowModal] = useState(false);

  const openModal = () => {
    setShowModal((prev) => !prev);
  };

  // const filledSlots = scheduleAndFillSlotFormat(scheduleDetails?.attributes?.slots?.data)

  const handleAddressId = (id: any) => {
    if (id === scheduleDetails?.attributes?.delivery_location?.data?.id) {
      return false;
    }
    const updatedData = {
      data: {
        delivery_location: id
      }
    }
    dispatch(updateSchedules(scheduleDetails?.id, updatedData) as any)
  }

  const openModalRemove = () => {
    setShowModalRemove((prev) => !prev);
  };

  const declineMealCalendar = async () => {
    try {
      const res = await declineCalendar(scheduleDetails?.id);
      if (res) {
        trackPixelEvent('Decline calendar');
        setShowModalSuccessForDeclineMeal(true);
      }
    }
    catch (e) {
      console.log(e);
    }
  }

  const onClickContinue = async () => {
    setLoader(true);
    store.dispatch({
      type: "SET_LOGIN_STATE",
      payload: { schedule, user }
    })

    // store.dispatch({
    //   type: "SET_ROUTE",
    //   payload: pageRoute
    // })

    store.dispatch({
      type: "SHOW_ALERT",
      payload: { show: true }
    })
    // setShowLoginPrompt(true);
    // const response = await tokenLogin(user, true);
    // if (response) {
    //   setShowVerifyModal(true);
    // }
    setLoader(false);
  }
  // const showMoreClickHandler = () => {
  //   setIsExpanded(prev => !prev);
  // };

  // const styleBlock = {
  //   marginLeft: "10px"
  // }
  return (
    <>
      {
        (!loading && isLocalStorageDataAvailable) ?
          <>
            {/* {
              (unAuthorize) ?
                null
                : */}
            <div className="screen">
              <TopHead
                title={switchSentenceCase("head text", scheduleDetails?.attributes?.name)}
                leftTextIcon={isLocalStorageDataAvailable && faAngleLeft}
                leftIconStyle={{ fontSize: "24px" }}
                goToHandle={GotoHandle}
              />
              <Divider />

              <div className="grow overflow-auto">
                <Section>
                  <Container >
                    <Flex className="my-[10px] ">
                      <ItemCount>1</ItemCount>
                      <BlockContainer onClick={() => { isLocalStorageDataAvailable && openModal() }} >
                        <FlexBetween>
                          <TextHead4>Calendar Name and Notes</TextHead4>
                          <TextHead5 className="text-green-600 !font-semibold underline cursor-pointer" >{isLocalStorageDataAvailable && "EDIT"}</TextHead5>
                        </FlexBetween >
                        <Block >
                          <SubText1 style={{ fontWeight: "500", wordBreak: "break-all" }}>{(switchSentenceCase("head text", scheduleDetails?.attributes?.name)) + " By " + (switchSentenceCase("head text", scheduleDetails?.attributes?.senderName))}</SubText1>
                          {(scheduleDetails?.attributes?.recipientName !== "") &&
                            <SubText1 style={{ fontWeight: "500" }}>For {switchSentenceCase("head text", scheduleDetails?.attributes?.recipientName)}</SubText1>}
                          {(scheduleDetails?.attributes?.description === "" || scheduleDetails?.attributes?.description === null) ?
                            null :
                            <SubText1 style={{ wordBreak: "break-all", }}> “{scheduleDetails?.attributes?.description}”</SubText1>}
                        </Block>
                      </BlockContainer>
                      {/* <Block>{isExpanded ? (
                      <button onClick={showMoreClickHandler}>Show more</button>
                    ) : null}</Block> */}

                    </Flex>
                    <Divider />
                    <Flex className="my-[10px]">
                      <ItemCount>2</ItemCount>
                      <BlockContainer>
                        <Block >
                          {/********************  Address Section ******************************/}
                          <AddressesCart
                            title="Delivery Address"
                            addressId={handleAddressId}
                            showDeleteBtn={false}
                            showList={false}
                            pageName="schedule"
                            pageDataId={scheduleDetails?.attributes?.delivery_location?.data?.id}
                            changeAddressTxtBtn={isLocalStorageDataAvailable ? "EDIT" : " "}
                            userId={scheduleDetails?.attributes?.user?.data?.id}
                            changeAddress={isLocalStorageDataAvailable}
                            setDeliveryAddress={true}
                            initialLength={1} />
                        </Block>
                      </BlockContainer>
                    </Flex>
                    <Divider />
                    <Flex className="my-[10px]">
                      <ItemCount>3</ItemCount>
                      <BlockContainer onClick={() => { isLocalStorageDataAvailable && history.push({ pathname: `/calendar-slots/${encodeURIComponent(scheduleDetails?.id)}` }); setScheduleSlotsRedux([]); }}>
                        <FlexBetween>
                          <TextHead4>Schedule & Fill Slots</TextHead4>
                          <TextHead5 className="text-green-600 !font-semibold underline cursor-pointer">{isLocalStorageDataAvailable && "EDIT"}</TextHead5>
                        </FlexBetween >
                        <Block >
                          <SubText1>Dates: {scheduleSAndFillSlotDateFormatter(scheduleDetails?.attributes?.start_date, scheduleDetails?.attributes?.end_date)}</SubText1>
                          {/* <SubText1>Dates: Mar 14 - July 1, 2022</SubText1> */}
                          {(scheduleDetails?.attributes?.slot_type === "Custom") ?
                            <SubText1>Meals/Day: 1, ({printTimeSlots(scheduleDetails?.attributes?.slot_time)})</SubText1>
                            :
                            <SubText1>Meals/Day: {scheduleDetails?.attributes?.slot_time?.length}, ({printTimeSlots(scheduleDetails?.attributes?.slot_time)})  {/*(filledSlots?.slotCount > 0) ? filledSlots?.slotCount + ", " : filledSlots?.slotCount} {filledSlots?.slotList */}</SubText1>
                          }
                          {
                            (scheduleDetails?.attributes?.slot_type === "Custom") ?
                              <SubText1>Custom Calendar</SubText1>
                              :
                              <SubText1>Repeat every {(scheduleDetails?.attributes?.slot_repeat > 1) ? scheduleDetails?.attributes?.slot_repeat : ""} {(scheduleDetails?.attributes?.slot_repeat > 1) ? String(scheduleDetails?.attributes?.slot_type).toLocaleLowerCase() : String(scheduleDetails?.attributes?.slot_type).toLocaleLowerCase().replace('s', '')}</SubText1>
                          }
                        </Block>
                      </BlockContainer>
                    </Flex>
                    <Divider />
                    <Flex className="my-[10px]">
                      <ItemCount>4</ItemCount>
                      <BlockContainer onClick={() => isLocalStorageDataAvailable && history.push({ pathname: "/invitefoodie" })}>
                        <FlexBetween>
                          <TextHead4>Invitations to Sponsor Meals</TextHead4>
                          <TextHead5 className="text-green-600 !font-semibold underline cursor-pointer">{isLocalStorageDataAvailable && "EDIT"}</TextHead5>
                        </FlexBetween >

                        <Block >
                          {(scheduleEmails?.length > 0)
                            ?
                            scheduleEmails?.map((item: any, index: any) =>
                              <SubText1 key={index}>{item?.attributes?.member}<span className={`${(item?.attributes?.status !== "pending") ? `${(item?.attributes?.status === "accepted") ? "text-green-500" : "text-red-500"}` : "text-orange-500"}`}> ({switchSentenceCase("head text", item?.attributes?.status)})</span></SubText1>
                              // <SubText1 key={index}>{item?.attributes?.member}<i className={`${(item?.attributes?.status !== "pending") ? `${(item?.attributes?.status === "accepted") ? "text-green-600" : "text-red-600"}` : "text-orange-600"}`}> ({switchSentenceCase("head text", item?.attributes?.status)})</i></SubText1>
                            )
                            :
                            <SubText1>No Sponsors Invited!</SubText1>
                          }
                        </Block>
                      </BlockContainer>
                    </Flex>
                  </Container>
                  {
                    isLocalStorageDataAvailable
                    &&
                    <div className="fixed bottom-36 right-12 rounded-[15px] shadow-md p-1 border-2 border-solid border-green-600">
                      <RWebShare
                        data={{
                          text: "Experience restaurant delivery designed for you and your friends. Try MealFave and get the most out of life and your meals.",
                          url: `${pagepath}`,
                          title: "Mealfave",
                        }}
                        onClick={() => console.log("shared successfully!")}
                      >
                        <ButtonGreen className="" >Share <FontAwesomeIcon icon={faShareAll} className="text-base" /></ButtonGreen>
                      </RWebShare>
                    </div>
                  }
                </Section>
                {isLocalStorageDataAvailable
                  ?
                  <Section>
                    <Divider />
                    <AccordDiv onClick={() => history.push({ pathname: "/newmealpayments" })}>
                      Detailed Settings
                      <FontAwesomeIcon
                        icon={faAngleRight}
                        style={{ float: "right" }}
                      />
                    </AccordDiv>
                    <Divider />
                  </Section>
                  :
                  <Section>
                    {(!loader) ?
                      <ButtonGreen
                        onClick={() => onClickContinue()}
                      >{switchSentenceCase("primary button", "CONTINUE TO CALENDAR")}
                      </ButtonGreen>
                      :
                      <div className="text-center">
                        <Spinner />
                      </div>
                    }
                    <ButtonGray
                      onClick={() => { openModalRemove() }}
                      style={{ marginTop: "15px" }}
                    >
                      {switchSentenceCase("primary button", "DECLINE MEAL CALENDAR")}
                    </ButtonGray>

                  </Section>
                }

                <ModalEditMealSchedule showModal={showModal} setShowModal={setShowModal} value={scheduleDetails} />

                {/* ---------------------------------Modal Confirm Delete Popup------------------- */}
                <ModalRemoved
                  firstText="Are you sure you want to"
                  secondText="decline this Meal Calendar?"
                  btnOneText="OOPS!  NEVER MIND..."
                  btnTwoText="YES, REMOVE IT"
                  showModal={showModalRemove}
                  setShowModal={setShowModalRemove}
                  deleteItem={declineMealCalendar}
                // id={selectedSlots}
                />

                {/* ---------------------------------Modal Success for Decline Meal Calendar------------------- */}

                <ModalSuccess
                  contentDivStyle={{ height: "auto" }}
                  firstText={switchSentenceCase(
                    "primary button",
                    `Invitation declined. Thanks for your response.`
                  )}
                  btnOneText={switchSentenceCase("primary button", "OKAY")}
                  btnOneGoto={() => {
                    // setShowModalSuccessForDeclineMeal((prev: any) => !prev);
                    history.push("/login")
                  }}
                  showModal={showModalSuccessForDeclineMeal}
                />

                {/* ********************* Modal Verify OTP ******************************
                    {showVerifyModal &&
                <ModalVerifyOtp
                  showModal={showVerifyModal}
                  setShowModal={setShowVerifyModal}
                />} */}
              </div>
            </div >
            {/* } */}
          </>
          :
          // <>
          //   {
          //     unAuthorize ?
          //       null
          //       :
          <div className="text-center">
            <ModalSpinner />
          </div>
        //   }
        // </>
      }
      <IonAlert
        isOpen={unAuthorize}
        backdropDismiss={false}
        keyboardClose={false}
        onDidDismiss={() => history.push("/calendar-home")}
        header="Unauthorized"
        // subHeader="Important message"
        message="You are unauthorized to view this calendar."
        buttons={['OK']}
      />
    </>
  );
}
