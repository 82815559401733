import { faQuestion } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useRef } from "react";
import styled from "styled-components";
import { moveTopBottom } from "../../styled/styled";
import useDarkMode from "../../hooks/darkmode/DarkMode";

const ModalDiv = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1000;
  overflow-y: auto;
`;

const ContentDiv = styled.div<{ isDarkMode: boolean }>`
    background-color: ${props => (props?.isDarkMode ? '#2e2e2e' : '#fff')};
    align-self: center;
    padding: 10px;
    z-index: 1000;
    width: 600px;
    max-width: 90%;
    border-radius: 20px;
    text-align: center;
    height: fit-content;
    animation: ${moveTopBottom} .4s ease-in-out;
`;

const DoneModalIcon = styled.div`
    margin-left: auto;
    margin-right: auto;
    margin-top: -50px;
    width: 70px;
    height: 70px;
    padding: 15px;
    color: white;
    border-radius: 40px;
`;

const ButtonOne = styled.button`
  background: #b3b3b3;
  padding: 5px;
  border-radius: 5px;
  color: white;
  font-weight: 600;
  margin: 12px 0px;
  width: 100%;
`;

const ButtonTwo = styled.button`
  background: #ca0b00;
  padding: 5px;
  border-radius: 5px;
  color: white;
  font-weight: 600;
  margin: 2px;
  width: 100%;
`;

const TextMeal = styled.h3`
  font-weight: 600;
  font-size: 24px;
`;

const TextRemoved = styled.h3`
  font-weight: 600;
  font-size: 24px;
  margin-bottom: 20px;
`;

export default function ModalConfirmationForCards(props: any) {
  const modalRef = useRef();
  const isDarkMode = useDarkMode();
  const closeModal = (e: any) => {
    if (modalRef.current === e.target) {
      props?.setShowModal(false);
    }
  };

  return (
    <>
      {props?.showModal
        &&
        <ModalDiv onClick={closeModal}>
          <ContentDiv isDarkMode={isDarkMode}>

            <DoneModalIcon style={{ background: props?.iconBackgroundColor ? props?.iconBackgroundColor : "#22a45d" }}>
              <FontAwesomeIcon icon={faQuestion} style={{ fontSize: "40px" }} />
            </DoneModalIcon>

            {((props?.firstText)?.length > 0)
              &&
              <TextMeal className="my-4">{props?.firstText}</TextMeal>
            }

            {((props?.secondText)?.length > 0)
              &&
              <TextRemoved >
                {props?.secondText}
              </TextRemoved>
            }

            {((props?.btnOneText)?.length > 0)
              &&
              <ButtonOne onClick={props?.rejectAction}>
                {props?.btnOneText}
              </ButtonOne>
            }

            {((props?.btnTwoText)?.length > 0)
              &&
              <ButtonTwo
                style={props?.styleBtnSecondText}
                onClick={() => {
                  props?.confirmAction(props?.id);
                  props?.setShowModal(false);
                }}
              >
                {props?.btnTwoText}
              </ButtonTwo>
            }

          </ContentDiv>
        </ModalDiv>
      }
    </>
  );
}
