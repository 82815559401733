
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { TextHead3, FlexBetween, InitialDiv, moveTopBottom, InputLabel } from "../../styled/styled";
import styled from "styled-components";
import { sleep, switchSentenceCase, trackPixelEvent } from "../../../utils/utilities";
import { deleteUserAccount, getUserInfo, loginRegisterUser } from "../../../services/user";
import Spinner from "../spinner/Spinner";
import useDarkMode from "../../hooks/darkmode/DarkMode";
import OtpInput from 'react18-input-otp';
import { setUserSearchLocation } from "../address/redux/addressActionCreator";
import { clearRestaurantList, setNearByRestaurants, setNearByScheduleRestaurants } from "../../../pages/search/redux/searchActionCreator";
import { setUserDeliveryAddress } from "../../../pages/profile/redux/locationActionCreator";
import { setRecentOrderList, setUserFaveMealList } from "../../../pages/faves/redux/favouriteActionCreator";
import ModalSuccess from "./ModalSuccess";


const ModalDiv = styled.div`
position: fixed;
display: flex;
justify-content: center;
top: 0;
left: 0;
width: 100%;
height: 100%;
background-color: rgba(0, 0, 0, 0.8);
z-index: 1000;
overflow-y: auto;
`;

const ContentDiv = styled.div<{ isDarkMode: boolean }>`
    background-color: ${props => (props?.isDarkMode ? '#2e2e2e' : '#fff')};
    margin-bottom: 85px;
    margin-top: 80px;
    padding: 10px;
    z-index: 1000;
    width: 600px;
    max-width: 90%;
    border-radius: 20px;
    text-align: center;
    height: fit-content;
    animation: ${moveTopBottom} .4s ease-in-out;
`;

const ButtonOne = styled.button`
  background: #b3b3b3;
  padding: 5px;
  border-radius: 5px;
  color: white;
  font-weight: 600;
  margin: 2px;
  width: 100%;
`;

const ButtonTwo = styled.button`
  background: #ca0b00;
  padding: 5px;
  border-radius: 5px;
  color: white;
  font-weight: 600;
  margin: 2px;
  width: 100%;
`;


export default function ModalDeleteAccount(props: any) {

    const history = useHistory();
    const isDarkMode = useDarkMode();


    const [otp, setOtp] = useState("");
    const [otpError, setOtpError] = useState("");
    const [awaiting, setAwaiting] = useState(false);
    const [resendWait, setResendWait] = useState(false);
    const [seconds, setSeconds] = useState(0);
    const time = 30;
    const [responseData, setResponseData] = useState<any>({});
    const [loading, setLoading] = useState(true);
    const [showSuccessModal, setShowSuccessModal] = useState(false);


    useEffect(() => {
        (async () => {
            const userData = await getUserInfo();
            if (userData) {
                const response = await loginRegisterUser(`${userData?.phone_no !== null ? userData?.phone_no : userData?.email}`, (userData?.phone_no !== null ? "phone" : "email"))
                let data = response?.data;
                trackPixelEvent('Login register user');
                if (data.status === "pending") {
                    setResponseData(data);
                    setLoading(false);
                }
            }
        })();

    }, []);

    //***************** HANDLE SUBMIT  *****************//
    const handleDelete = async (e: any) => {

        //* --------------------------Validation-----------------------
        if (otp === "") {
            setOtpError("Please Enter OTP First !");
        } else if (otp?.length < 6 || otp?.length > 6) {
            setOtpError("OTP Should Contain 6 Digits !");
        } else if (otp?.length === 6) {
            setOtpError("")

            const data = {
                "otp": otp,
                "provider": responseData?.provider,
                "contact": responseData?.contact
            }
            //* --------------------------Api Call--------------------------
            try {
                setAwaiting(true);
                await deleteUserAccount(data)
                    .then(async (response: any) => {
                        let data = response?.data;
                        trackPixelEvent('Delete user account');
                        if (data.status === "approved") {
                            localStorage.removeItem("MealTrendUser");
                            localStorage.removeItem("MealFaveUserDeliveryAddress");
                            localStorage.removeItem("CalendarData");
                            localStorage.removeItem("RestaurantData");
                            localStorage.removeItem("SomeoneElseOrderData");
                            setUserSearchLocation({});
                            clearRestaurantList();
                            setNearByRestaurants([]);
                            setUserDeliveryAddress({});
                            setUserFaveMealList([]);
                            setRecentOrderList([]);
                            setNearByScheduleRestaurants([]);
                            setShowSuccessModal(true);
                        }
                    })
            } catch (error) {
                console.log(error);
                setAwaiting(false);
                const err = error?.response?.data?.error?.message;
                if (String(err).toLowerCase().includes("invalid")) {
                    setOtpError("Code is invalid. Try again or →");
                }
            }
        }
    }

    //* Timer for user to wait between resending otp.
    const startCountdown = async () => {
        setResendWait(true);
        for (let i = time; i >= 0; i--) {
            await sleep(1000)
            setSeconds(i);

            if (i === 0) {
                setResendWait(false);
                break
            }
        }
    }

    //***************** HANDLE RESEND OTP  *****************//
    const resendOtp = async (e: any) => {
        try {
            if (awaiting || resendWait) { return false; }
            startCountdown();
            setOtp("");
            setOtpError("");

            //* Api Call--------------------------
            setAwaiting(true);
            await loginRegisterUser(responseData?.contact, responseData?.provider)
                .then(async (response: any) => {
                    let data = response?.data;
                    trackPixelEvent('Login register user');
                    if (data.status === "pending") {
                        setAwaiting(false);
                    }
                })

        } catch (error) {
            console.log(error);
            setAwaiting(false);
        }
    }

    //***************** HANDLE KEY DOWN FUNCTION *****************//
    const handleKeyDown = (e: any) => {
        if (e.key === 'Enter') {
            handleDelete(e);
        }
    };
    const handleCancel = () => {
        props?.setShowModal(false);
    };

    const handleConfirm = () => { }

    return (
        <>
            {(props?.showModal) &&
                <ModalDiv>
                    {!loading ?
                        <ContentDiv isDarkMode={isDarkMode}>
                            <div className="grow overflow-auto my-4">
                                <InitialDiv className="mx-2">
                                    <TextHead3>Delete Account</TextHead3>
                                </InitialDiv>

                                <InitialDiv className="mx-2 ">
                                    <InitialDiv className="w-full flex justify-center my-8">
                                        <TextHead3>Are you sure you want to delete your account? By confirming, you will delete all data from MealFave.</TextHead3>
                                    </InitialDiv>

                                    <InitialDiv>
                                        <FlexBetween>
                                            <InputLabel className="mb-[10px]">{`Enter OTP sent to ${responseData?.contact ? responseData?.contact + "." : "[email or phone number]"}`}
                                            </InputLabel>

                                        </FlexBetween>
                                        <div>
                                            <form onKeyDown={handleKeyDown}>
                                                <OtpInput
                                                    value={otp}
                                                    shouldAutoFocus={true}
                                                    onChange={setOtp}
                                                    numInputs={6}
                                                    isInputNum={true}
                                                    separator={<span>-</span>}
                                                    containerStyle="justify-center"
                                                    inputStyle="bg-transparent !w-10 h-10 border m-[0.2rem]"

                                                />
                                            </form>
                                        </div>

                                        <FlexBetween>
                                            {otpError ? <p className="mt-2 text-rose-800">{otpError}</p> : <p></p>}
                                            <p className="mt-2 text-red-600 cursor-pointer">
                                                <span onClick={resendOtp} style={{ color: ((seconds > 0) ? "grey" : "") }}>Resend Code {(seconds > 0) ? `(in ${seconds}s)` : ""}</span>
                                            </p>
                                        </FlexBetween>


                                        {(awaiting === false)
                                            ?
                                            <ButtonTwo
                                                onClick={handleDelete}
                                            >
                                                CONFIRM
                                            </ButtonTwo>
                                            :
                                            <div className="text-center">
                                                <Spinner />
                                            </div>
                                        }
                                        <ButtonOne onClick={() => handleCancel()}>
                                            CANCEL
                                        </ButtonOne>
                                    </InitialDiv>
                                </InitialDiv>
                            </div>
                        </ContentDiv>
                        :
                        <div className="text-center">
                            <Spinner />
                        </div>
                    }
                </ModalDiv >
            }
            <ModalSuccess
                contentDivStyle={{ height: "auto" }}
                firstText={switchSentenceCase(
                    "primary button",
                    "Your account and associated data has been deleted successfully."
                )}
                btnOneText={switchSentenceCase("primary button", "OKAY")}
                btnOneGoto={() =>
                    history.push({
                        pathname: "/",
                    })
                }
                showModal={showSuccessModal}
            />
        </>
    );
}
