import Section from "../../components/widgets/section/Section";
import { FlexBetween, TextHead4 } from "../../components/styled/styled";
import { useHistory } from "react-router";
import CategoryImg1 from '../../components/assets/restaurent-Meal-2.jpg';
import CategoryImg2 from '../../components/assets/restaurent-Meal-3.jpg';
import CategoryImg3 from '../../components/assets/restaurent-Meal-4.jpg';
import CategoryImg4 from '../../components/assets/restaurent-Meal-5.jpg';
import OrderCard from "../../components/widgets/cards/OrderCard";




export default function YourOrders() {
    const history = useHistory();

    const OrdersRestaurant = [
        {
            img: CategoryImg1,
            name: "McDonald’s",
            orders: "Shortbread, chocolate turtle cookies, and red velvet.",
            price: "USD7.4"
        },
        {
            img: CategoryImg2,
            name: "Uncle Boy's",
            orders: "Shortbread, chocolate turtle cookies, and red velvet.",
            price: "USD7.4"
        },
        {
            img: CategoryImg3,
            name: "The Halal Guys",
            orders: "Shortbread, chocolate turtle cookies, and red velvet.",
            price: "USD7.4"
        }
    ]

    const OrdersRestaurant1 = [
        {
            img: CategoryImg4,
            name: "Cafe Brichor's",
            orders: "Shortbread, chocolate turtle cookies, and red velvet.",
            price: "USD7.4"
        },
        {
            img: CategoryImg2,
            name: "Uncle Boy's",
            orders: "Shortbread, chocolate turtle cookies, and red velvet.",
            price: "USD7.4"
        }
    ]

    return (
        <div className="screen">
            <Section align="center">
                <TextHead4 >Your Orders</TextHead4>
            </Section>
            <div className="grow overflow-auto">
                <Section>
                    <FlexBetween>
                        <TextHead4 onClick={()=>history.push({pathname:"/notifications"})} className="text-gray-500">UPCOMING ORDERS</TextHead4>
                        <TextHead4 className="!text-base">CLEAR ALL</TextHead4>
                    </FlexBetween>
                </Section>
                <Section>
                    <OrderCard data={OrdersRestaurant} />
                </Section>
                <Section>
                    <FlexBetween>
                        <TextHead4 className="text-gray-500">PAST ORDERS</TextHead4>
                        <TextHead4 className="!text-base">CLEAR ALL</TextHead4>
                    </FlexBetween>
                </Section>
                <Section>
                    <OrderCard data={OrdersRestaurant1} />
                </Section>
            </div>
        </div >
    );
}