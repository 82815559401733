import { faCheck, faCircleF, faCircleM, faCircleS, faCircleT, faCircleW, faMinus, faPlus } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IonDatetime, IonDatetimeButton, IonModal, IonSelect, IonSelectOption } from "@ionic/react";
import { useState } from "react";
import useDarkMode from "../../components/hooks/darkmode/DarkMode";
import { Flex, FlexBetween, InitialDiv, InputText, SubText1, TextHead1 } from "../../components/styled/styled";
import RadioButtonText from "../../components/widgets/buttons/RadioButtonText";
import Section from "../../components/widgets/section/Section";

export default function SchedulingWidget() {
  const [repeatTypes, setRepeatTypes] = useState("");
  const [selectedEndBy, setSelectedEndBy] = useState("endByDate");
  const [startDateValue, setStartDateValue] = useState([{ startDate: "" }]);
  const isDarkMode = useDarkMode();

  const pushLog = (logData: any) => {
    setRepeatTypes(logData);
  };

  const addRow = () => {
    const list = startDateValue;
    setStartDateValue([{ startDate: "" }, ...list]);
  };

  const removeRow = (index: any) => {
    const list = [...startDateValue];
    list.splice(index, 1);
    setStartDateValue(list);
  };


  const handleOptionChange = (value: any) => {
    setSelectedEndBy(value);
  }


  return (
    <div className="screen">

      <div className="grow overflow-auto">
        <FlexBetween className="items-center">
          <TextHead1>Schedule</TextHead1>
          <FontAwesomeIcon icon={faCheck} />
        </FlexBetween>
        <Section>
          <InitialDiv className="my-2">
            <SubText1>Start Date</SubText1>
            <IonDatetimeButton className="my-2.5" datetime="date" style={{ border: "1px solid #e2e2e2", borderRadius: "8px" }}></IonDatetimeButton>
            <IonModal keepContentsMounted={true}>
              <IonDatetime className={isDarkMode ? "calendar-color" : ""} onIonChange={(e) => pushLog(e.detail.value)} presentation="date" preferWheel={true} showDefaultButtons={true} id="date" ></IonDatetime>
            </IonModal>
          </InitialDiv>
          <InitialDiv className="my-2">
            <SubText1>Repeat Every</SubText1>
            <Flex>
              <IonSelect onIonChange={(e) => pushLog(e.detail.value)} placeholder="Select Days" className="ion-select-for-schedule !mr-1" interface="popover">
                <IonSelectOption value="1">1</IonSelectOption>
                <IonSelectOption value="2">2</IonSelectOption>
                <IonSelectOption value="3">3</IonSelectOption>
              </IonSelect>
              <IonSelect onIonChange={(e) => pushLog(e.detail.value)} placeholder="Select Type" className="ion-select-for-schedule !ml-1" interface="popover">
                <IonSelectOption value="Days">Days</IonSelectOption>
                <IonSelectOption value="Weeks">Week(s)</IonSelectOption>
              </IonSelect>
            </Flex>
          </InitialDiv>

          <InitialDiv className="my-2" >
            <SubText1>Time</SubText1>
            {startDateValue.map((item: any, index: any) => (
              <FlexBetween className="items-center" key={index}>
                <IonDatetimeButton className="my-2.5 mr-2 w-full" datetime="time" style={{ border: "1px solid #e2e2e2", borderRadius: "8px" }}></IonDatetimeButton>
                <IonModal keepContentsMounted={true}>
                  <IonDatetime className={isDarkMode ? "calendar-color" : ""} presentation="time" name="startDate" preferWheel={true} id="time" ></IonDatetime>
                </IonModal>
                {startDateValue.length - 1 === index ?
                  < FontAwesomeIcon icon={faPlus} onClick={addRow} style={{
                    color: "white", background: "#22A45D", padding: "9px", borderRadius: "5px"
                  }} />
                  :
                  < FontAwesomeIcon icon={faMinus} onClick={() => removeRow(index)} style={{
                    color: "white", background: "#22A45D", padding: "9px", borderRadius: "5px"
                  }} />
                }
              </FlexBetween>
            ))}
          </InitialDiv>

          {repeatTypes === "Weeks" ?

            <InitialDiv className="my-2">
              <SubText1>Repeat On</SubText1>
              <Flex className="items-center ">
                <FontAwesomeIcon icon={faCircleS} className="text-gray-400 mr-2 text-xl" />
                <input type="Time" name="" id="" className="time-input-custom" />
              </Flex>
              <Flex className="items-center ">
                <FontAwesomeIcon icon={faCircleM} className="text-gray-400 mr-2 text-xl" />
                <input type="Time" name="" id="" className="time-input-custom" />
              </Flex>
              <Flex className="items-center ">
                <FontAwesomeIcon icon={faCircleT} className="text-gray-400 mr-2 text-xl" />
                <input type="Time" name="" id="" className="time-input-custom" />
              </Flex>
              <Flex className="items-center ">
                <FontAwesomeIcon icon={faCircleW} className="text-gray-400 mr-2 text-xl" />
                <input type="Time" name="" id="" className="time-input-custom" />
              </Flex>
              <Flex className="items-center ">
                <FontAwesomeIcon icon={faCircleT} className="text-gray-400 mr-2 text-xl" />
                <input type="Time" name="" id="" className="time-input-custom" />
              </Flex>
              <Flex className="items-center ">
                <FontAwesomeIcon icon={faCircleF} className="text-gray-400 mr-2 text-xl" />
                <input type="Time" name="" id="" className="time-input-custom" />
              </Flex>
              <Flex className="items-center ">
                <FontAwesomeIcon icon={faCircleS} className="text-gray-400 mr-2 text-xl" />
                <input type="Time" name="" id="" className="time-input-custom" />
              </Flex>
            </InitialDiv>
            :
            null
          }

          <InitialDiv className="my-2">
            <FlexBetween>
              <RadioButtonText
                // key={index}
                border={{ borderBottom: "none" }}
                index="endByDate"
                label="End by Date"
                checked={selectedEndBy}
                name="End by Date"
                value="endByDate"
                // option={type}
                onSelect={(e: any) => { handleOptionChange(e.target.value) }}
              />
              <RadioButtonText
                // key={index}
                border={{ borderBottom: "none" }}
                index="endByDays"
                label="End After Days"
                checked={selectedEndBy}
                name="End by Days"
                value="endByDays"
                // option={type}
                onSelect={(e: any) => { handleOptionChange(e.target.value) }}
              />
            </FlexBetween>

            {
              selectedEndBy === "endByDate"
                ?
                <InitialDiv className="my-2">
                  <SubText1>Choose End Date</SubText1>
                  <IonDatetimeButton className="my-2.5" datetime="date" style={{ border: "1px solid #e2e2e2", borderRadius: "8px" }}></IonDatetimeButton>
                  <IonModal keepContentsMounted={true}>
                    <IonDatetime className={isDarkMode ? "calendar-color" : ""} onIonChange={(e) => pushLog(e.detail.value)} presentation="date" preferWheel={true} showDefaultButtons={true} id="date" ></IonDatetime>
                  </IonModal>
                </InitialDiv>
                :
                <InitialDiv className="my-2">
                  {/* <SubText1>Enter Date</SubText1> */}
                  <InputText placeholder="Enter in Numbers" />
                </InitialDiv>
            }

          </InitialDiv>
        </Section>
      </div>
    </div >
  );
}
