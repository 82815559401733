const initialValue = {
  restaurantsMenuList: [],
  menuDataList: [],
  menuList: [],
  mealId: null,
  mealIndex: 0,
  addfavMealItem: [],
  replaceFavItemData: {}
};

const getRestaurantsMenuLists = (state = initialValue, action: any) => {
  try {
    switch (action.type) {

      case "GET_RESTAURANTS_MENU_LIST":
        return { ...state, restaurantsMenuList: action.payload };

      case "ADD_MEAL_ID":
        return { ...state, mealId: action.payload.mealId, mealIndex: action.payload.itemIndex };

      case "ADD_FAV_MEAL_ITEMS":
        const newList: any[] = action.payload.addFavMealItem;
        if (newList.length > 0) {
          if (action.payload.type === "update") {
            const index = state.addfavMealItem.findIndex((list: any, index: number) => list?.product_id === action.payload.addFavMealItem[0].product_id && index === action.payload.itemIndex);
            state.addfavMealItem[index] = action.payload.addFavMealItem[0];
            return { ...state, addfavMealItem: state.addfavMealItem };
          }
          else {
            newList?.forEach((element: any) => {
              state.addfavMealItem.push(element)
            });
            return { ...state, addfavMealItem: state.addfavMealItem };

            // Remove Duplicates
            // const _product_ids = state.addfavMealItem.map((o: any) => o?.product_id)
            // state.addfavMealItem = state.addfavMealItem.filter(({ product_id }, index) => !_product_ids.includes(product_id, index + 1))
          }
        }
        else {
          return { ...state, addfavMealItem: state.addfavMealItem }
        }

      // const index = state.addfavMealItem.findIndex((data) => data.product_id == action.payload.product_id);
      // if (index === -1) {      
      // return { ...state, addfavMealItem: state.addfavMealItem };
      // }
      // else {
      //   state.addfavMealItem[index].quantity = action.payload.quantity
      //   return { ...state, addfavMealItem: state.addfavMealItem }
      // }

      case "SET_MENU_DATA_LIST":
        let finalData = {};
        let quoteIds = action.payload?.quoteIds;
        const data = action.payload?.data;

        for (const [key, menu] of Object.entries<any>(data)) {
          for (let j = 0; j < menu.length; j++) {
            if (!menu[j].quantity) {
              menu[j].quantity = 0
            }
          }
          finalData[key] = menu;
        }
        state.menuDataList.push({ quoteIds: quoteIds, data: finalData })
        // Remove Duplicates
        const _quoteIds = state.menuDataList.map((o: any) => o?.quoteIds)
        state.menuDataList = state.menuDataList.filter(({ quoteIds }, index) => !_quoteIds.includes(quoteIds, index + 1))
        return { ...state, menuDataList: state.menuDataList, addfavMealItem: [] };

      case "REMOVE_MENU_DATA_LIST":
        state.menuDataList = state.menuDataList.filter(({ quoteIds }) => quoteIds !== action.payload)
        return { ...state, menuDataList: state.menuDataList };

      case "GET_SINGLE_MEAL":
        let filtering: any;

        if (!state.menuDataList) {
          return { ...state, menuList: [] };
        }

        const _menuItems = state.menuDataList?.filter((currentObj: any) => currentObj?.quoteIds === action.payload.quoteId)[0]?.data;

        let dataItems = Object.entries<any>(_menuItems || {});

        for (let item in dataItems) {
          filtering = dataItems[item].filter((state: any) => (state.product_id === action.payload.id))
        }

        return { ...state, menuList: filtering };


      case "CHANGE_QUANTITY":
        let menuItems = state.menuDataList?.filter((currentObj: any) => currentObj?.quoteIds === action.payload.quoteId)[0]?.data;

        for (const [key, item] of Object.entries<any>(menuItems || {})) {
          let isFindIndex = false;

          for (let j = 0; j < item.length; j++) {
            if (item[j].product_id === action.payload.product_id) {
              menuItems[key][j].quantity = action.payload.quantity;
              isFindIndex = true
              break;
            }
          }
          if (isFindIndex) {
            break;
          }
        }

        return {
          ...state,
          menuDataList: state.menuDataList,
        };

      case "REMOVE_FAV_MEAL_ITEMS":
        return { ...state, addfavMealItem: [] };

      case "REMOVE_MENU_ITEM_QUANTITY":
        const menuItemsList = state.menuDataList?.filter((currentObj: any) => currentObj?.quoteIds !== action.payload.quoteId);
        return { ...state, menuDataList: menuItemsList };

      // for (const [key, item] of Object.entries<any>(menuItemsList || {})) {
      //   for (let j = 0; j < item.length; j++) {
      //     menuItemsList[key][j].quantity = 0;
      //   }
      // }


      case "CHANGE_QUANTITY_OF_ADD_MEAL_ITEM":
        // for (let index = 0; index < state.addfavMealItem.length; index++) {
        //   if (state.addfavMealItem[index].product_id === action.payload.product_id) {
        //     state.addfavMealItem[index].quantity = action.payload.quantity
        //     break;
        //   }
        // }        
        const index = state.addfavMealItem.findIndex((list: any, index: number) => list?.product_id === action.payload.product_id && index === action.payload.itemIndex);
        state.addfavMealItem[index].quantity = action.payload.quantity;
        return { ...state, addfavMealItem: state.addfavMealItem };

      case "REMOVE_SINGLE_FAV_CART_ITEM":
        const filteredItem = state.addfavMealItem.filter(
          (data, index) => index !== action.payload.itemIndex
        );
        return { ...state, addfavMealItem: filteredItem };

      case "SET_REPLACE_FAV_ITEM_DATA":
        return { ...state, replaceFavItemData: action.payload };

      default:
        return state;
    }
  }
  catch (e) {
    console.log(e)
  }
};

export default getRestaurantsMenuLists;
