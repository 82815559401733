import { apiRequestService } from './apiRequestService';
import { getUserData } from './user';

const API_URL = process.env.REACT_APP_API_URL

export const getContent = async () => {
    try {
        const user = await getUserData()

        let config = {
            method: 'get',
            url: API_URL + `/v1/landing-pages?populate[icon]=*&sort[0]=id:asc`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + user?.jwt
            },
        };
        const request = apiRequestService(config);
        return request;
    } catch (error) {
        console.log(error);
    }
}