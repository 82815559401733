import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import order_single_icon from "../../components/assets/dashboard/order-single-icon.svg";
import useDarkMode from "../../components/hooks/darkmode/DarkMode";
import { useState } from "react";


export default function MyUpcomingOrdersButton() {

  const history = useHistory();
  // const isDarkMode = useDarkMode();

  const { totalUpcomingOrders } = useSelector((state: any) => state?.upcomingOrderState);
  // const { totalFavMeal } = useSelector((state: any) => state.getAllFavData);
  // const { totalExistedFriends } = useSelector((state: any) => state?.userAddresses);
  // const { totalForMe } = useSelector((state: any) => state.createScheduleForMe);

  const [getTotalUpcomingOrdersClass, setGetTotalUpcomingOrdersClass] = useState("");


  // useEffect(() => {
  //   getConditionsBasedClasses();

  //   // eslint-disable-next-line
  // }, [totalFavMeal, totalExistedFriends, totalForMe, totalUpcomingOrders]);


  // const getConditionsBasedClasses = () => {
  //   if (totalForMe === 1) {
  //     setGetTotalUpcomingOrdersClass("!opacity-100");
  //   } else if (totalFavMeal >= 1 && totalExistedFriends >= 1 && totalForMe >= 1 && totalUpcomingOrders === 0) {
  //     setGetTotalUpcomingOrdersClass("!opacity-100");
  //   } else if (totalFavMeal >= 1 && totalExistedFriends >= 1 && totalForMe >= 1 && totalUpcomingOrders >= 1) {
  //     setGetTotalUpcomingOrdersClass("!opacity-100");
  //   } else {
  //     setGetTotalUpcomingOrdersClass("");
  //   }
  // }


  return (
    <>
      <div className={`getFood_box food max-md:w-[50%] max-lg:w-[100%] lg:w-[50%] lg:min-h-32 ${getTotalUpcomingOrdersClass}`}
        onClick={(e: any) => {
          e.preventDefault();
          history.push('/manage-orders');
        }}>
        <div className="w-full">

          <div className="Food_box_icon">
            <img src={order_single_icon} alt="order-img" className="w-[35px]" />
            <span className="Food_order-green">{totalUpcomingOrders}  {(totalUpcomingOrders > 1 || totalUpcomingOrders === 0) ? "" : ""}</span>
          </div>

          <div className="Food_box_text text-center max-md:text-left my-3">
            <span className="text">Orders (Single Orders)</span>
            <p>Create and manage single orders for yourself or friends.</p>
          </div>
        </div>
      </div>
    </>
  );
}
