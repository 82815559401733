import { useState, useEffect } from "react";
import { InputText, SubText1, SubText2, TextHead4 } from "../../styled/styled";
import { Select } from "antd";
import { calculateTimeDifference, genTimeOptFromScheduleTime, groupRestaurantSchedule, trackPixelEvent } from "../../../utils/utilities";
import { getRestaurantSchedules } from "../../../services/restaurant";
import moment from "moment";
import useDarkMode from "../../hooks/darkmode/DarkMode";


export default function TimeWidget({ storeId, date, timezone, openingTime, closingTime, timeValuesData, restaurantSchedulesData, orderType, restaurantQuote }) {

    // ******************** other methods ************************
    const isDarkMode = useDarkMode();

    // ****************** Hooks **************************
    const [timeValues, setTimeValues] = useState("");
    const [scheduleTimeError, setScheduleTimeError] = useState(false);
    const [generatedScheduleTimeOptions, setGeneratedScheduleTimeOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [restaurantSchedules, setRestaurantSchedules] = useState([]);

    // ************************ Renders **********************************
    useEffect(() => {
        setLoading(true);
        callData();
    }, [storeId]);


    useEffect(() => {
        filterScheduleDatesAndTimes(restaurantSchedules);
    }, [date]);

    // ****************** Functions **************************
    const callData = async () => {
        setLoading(true);
        try {
            const res = await getRestaurantSchedules(storeId, orderType);
            restaurantQuote(res?.data);
            if (!res?.data?.quote.hasOwnProperty('error')) {
                trackPixelEvent('Get restaurant schedules');
                setRestaurantSchedules(groupRestaurantSchedule(res?.data?.quote?.time_estimate?.scheduled, timezone));
                restaurantSchedulesData(groupRestaurantSchedule(res?.data?.quote?.time_estimate?.scheduled, timezone));
                filterScheduleDatesAndTimes(groupRestaurantSchedule(res?.data?.quote?.time_estimate?.scheduled, timezone));
            }
            setLoading(false);
        }
        catch (e) {
            console.log(e);
            setLoading(false);
        }
    };

    const filterScheduleDatesAndTimes = (restaurantSchedules) => {
        setTimeValues("");
        timeValuesData("");
        const groupedSchedules = [];
        const timeDifference = [];

        // Filter and push matching dates to the array
        for (var scheduleArray in restaurantSchedules) {
            if (restaurantSchedules.hasOwnProperty(scheduleArray)) {
                if (moment(scheduleArray).format('YYYY-MM-DD') === moment(date).format('YYYY-MM-DD')) {
                    groupedSchedules[scheduleArray] = restaurantSchedules[scheduleArray];
                };
            };
        };

        // Calculating time difference-------
        Object.values(groupedSchedules)[0]?.forEach((timeObj: any) => {
            timeDifference.push(timeObj?.time);
        });

        const generatedScheduleTimes = genTimeOptFromScheduleTime(calculateTimeDifference(timeDifference), groupedSchedules, date, openingTime, closingTime, timezone);
        if (moment(date).format('YYYY-MM-DD') === moment(new Date()).format('YYYY-MM-DD')) {
            var newObject = { value: `${generatedScheduleTimes[0]?.value}@`, label: 'ASAP', className: '' }
            generatedScheduleTimes.splice(0, 0, newObject);
            if (generatedScheduleTimes[0]?.value !== "undefined@") {
                setTimeValues(generatedScheduleTimes[0]?.value)
                setGeneratedScheduleTimeOptions(generatedScheduleTimes);
                timeValuesData(generatedScheduleTimes[0]?.value)
            }
        }
        setGeneratedScheduleTimeOptions(generatedScheduleTimes);
    };

    const handleOnTimeChange = (value: any) => {
        setScheduleTimeError(false);
        if (value === "") {
            setScheduleTimeError(true);
        } else {
            setScheduleTimeError(false);
            setTimeValues(value);
            timeValuesData(value);
        }
    };

    return (
        <>
            <div className="ml-3 mt-1 w-full">
                <TextHead4 className="flex justify-between">Select Time</TextHead4>
                <SubText2 >
                    {
                        loading
                            ?
                            <>
                                <InputText value={"Loading..."} readOnly />
                            </>
                            :
                            <Select
                                allowClear
                                className={isDarkMode ? "time-selector" : ""}
                                showSearch
                                defaultValue="Please select time"
                                value={timeValues}
                                size="large"
                                optionFilterProp="label"
                                notFoundContent={<SubText1 className="m-2">No Schedule Found !</SubText1>}
                                bordered={false}
                                onChange={(e: any) => handleOnTimeChange(e)}
                                options={generatedScheduleTimeOptions}
                                dropdownStyle={{ zIndex: "unset" }}
                                style={
                                    {
                                        width: "100%",
                                        textAlign: "left",
                                        margin: "10px 0px",
                                        border: scheduleTimeError ? "1px solid red" : "1px solid #e2e2e2",
                                        borderRadius: scheduleTimeError ? "10px" : "8px"
                                    }
                                }
                            />
                    }
                </SubText2>
            </div>
        </>
    );
};


