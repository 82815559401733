import { faAngleDown, faAngleLeft, faBell, faBellOn, faTrashCan, faTrashCanList } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IonContent, IonRefresher, IonRefresherContent, RefresherEventDetail, useIonViewDidEnter } from "@ionic/react";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import styled from "styled-components";
import useDarkMode from "../../components/hooks/darkmode/DarkMode";
import { Block, ButtonGreen, FlexBetween, InitialDiv, SubText1, SubText2, TextPills } from "../../components/styled/styled";
import Accordion from "../../components/widgets/accordian/AccordionStyled";
import Divider from "../../components/widgets/divider/Divider";
import TopHead from "../../components/widgets/head/TopHead";
import ModalRemoved from "../../components/widgets/modals/ModalRemoved";
import Section from "../../components/widgets/section/Section";
import Spinner from "../../components/widgets/spinner/Spinner";
import { getUserInfo } from "../../services/user";
import { mergeSlotDateTime, nextSlotDateTimeFormatter, scheduleEndDateFormatter, switchSentenceCase, trackPixelEvent } from "../../utils/utilities";
import { clearNotification, createScheduleForMeClear, getScheduleDetails, getScheduleForOther, getSchedulesForMe, getUserNotifications, invitationScheduleDetails, notificationDetailsClear, removeSchedule, scheduleDetailsClear } from "./redux/createScheduleReduxActions";


const TransDiv = styled.div`
  padding: 10px 0px 10px 0px;
  font-size: 1.25rem;
  font-weight: 600;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
`;

export default function Schedules(data: any) {

  // ******************** Other Methods *********************************
  const isDarkMode = useDarkMode();
  const history = useHistory();
  const dispatch = useDispatch();
  const currentDateTime = new Date();
  const user = getUserInfo();

  // ******************** State Management *********************************************
  const { notificationsList, totalNotification, scheduleForMe, scheduleForOther, totalForMe, totalForOther } = useSelector((state: any) => state?.createScheduleForMe);

  // ********************** Hooks *****************************************
  const limit = 10;
  const [skip, setSkip] = useState(0);
  const [skipForMe, setSkipForMe] = useState(0);
  const [skipForOther, setSkipForOther] = useState(0);
  const [hideNotification, setHideNotification] = useState(true);
  const [loadingNotification, setLoadingNotification] = useState(false);
  const [loadingScheduleForMe, setLoadingScheduleForMe] = useState(false);
  const [loadingScheduleForOther, setLoadingScheduleForOther] = useState(false);
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const [scheduleType, setScheduleType] = useState("");
  const [isRefreshed, setIsRefreshed] = useState(false);

  // ************************** First Time Render ******************************************
  useEffect(() => {
    setLoadingNotification(true);
    setLoadingScheduleForMe(true);
    setLoadingScheduleForOther(true);
    callDataForNotification();
    callDataForMe();
    callDataForOther();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRefreshed]);


  useIonViewDidEnter(() => {
    trackPixelEvent('Calendar home');
  }, []);

  // ********************** Functions ********************************

  const handleRefresh = (event: CustomEvent<RefresherEventDetail>) => {
    setTimeout(() => {
      setIsRefreshed(prev => !prev);
      event.detail.complete();
    }, 2000);
  };


  const callDataForNotification = async (skippingValue = 0) => {
    try {
      await dispatch(getUserNotifications(skippingValue, limit) as any);
      setLoadingNotification(false);
    }
    catch (e) {
      console.log(e)
    }
  };

  const showMore = () => {
    const skippingValue = skip + limit
    setSkip(skippingValue)
    callDataForNotification(skippingValue);
  };

  const callDataForMe = async (skippingValue = 0) => {
    try {
      if (!user?.id) return false;
      await dispatch(getSchedulesForMe(user?.id, skippingValue, limit, "For_Me") as any)
      setLoadingScheduleForMe(false);
    }
    catch (e) {
      console.log(e)
    }
  };

  const callDataForOther = async (skippingValue = 0) => {
    try {
      if (!user?.id) return false;
      await dispatch(getScheduleForOther(user?.id, skippingValue, limit, "For_Other") as any)
      setLoadingScheduleForOther(false);
    }
    catch (e) {
      console.log(e)
    }
  };

  const showMoreForMe = () => {
    const skippingValue = skipForMe + limit
    setSkipForMe(skippingValue)
    callDataForMe(skippingValue);
  };

  const showMoreForOthers = () => {
    const skippingValue = skipForOther + limit
    setSkipForOther(skippingValue)
    callDataForOther(skippingValue);
  };

  const GotoHandle = () => {
    history.push({
      pathname: '/faves',
    })
  };

  const removeNotification = () => {
    try {
      let tempArray = []
      notificationsList?.forEach((obj: any) => {
        tempArray.push(obj?.id);
      });
      dispatch(clearNotification(tempArray) as any)
    }
    catch (e) {
      console.log(e);
    }
  };

  const removeNotificationById = (id: any) => {
    try {
      let tempArray = [id];
      dispatch(clearNotification(tempArray) as any);
    } catch (e) {
      console.log(e);
    }
  };

  function getNextSlot(slots: any) {
    const filteredSlots = slots?.filter((items: any) => ((new Date(mergeSlotDateTime(items?.attributes?.date, items?.attributes?.time)) > currentDateTime) && (items?.attributes?.favMeal?.data === null)) || ((new Date(mergeSlotDateTime(items?.attributes?.date, items?.attributes?.time)) > currentDateTime) && (items?.attributes?.favMeal?.data !== null) && (items?.attributes?.sponsoredBy?.data === null))).sort(compareDateTime)
    const value = nextSlotDateTimeFormatter(filteredSlots[0]?.attributes?.date, filteredSlots[0]?.attributes?.time)
    if (filteredSlots?.length > 0) {
      return `Next Slot: ${value}`;
    }
    else {
      return null;
    }
  };

  const compareDateTime = (a: any, b: any) => {
    var keyA = new Date(mergeSlotDateTime(a?.attributes?.date, a?.attributes?.time)),
      keyB = new Date(mergeSlotDateTime(b?.attributes?.date, b?.attributes?.time));
    // Compare the 2 dates
    if (keyA < keyB) return -1;
    if (keyA > keyB) return 1;
    return 0;
  };

  function getNextOrder(slots: any) {
    const filteredSlots = slots?.filter((items: any) => ((new Date(mergeSlotDateTime(items?.attributes?.date, items?.attributes?.time)) > currentDateTime) && (items?.attributes?.favMeal?.data !== null) && (items?.attributes?.sponsoredBy?.data !== null))).sort(compareDateTime)
    const value = nextSlotDateTimeFormatter(filteredSlots[0]?.attributes?.date, filteredSlots[0]?.attributes?.time)
    if (filteredSlots?.length > 0) {
      return `Next Order Ready: ${value}`;
    }
    else {
      return null;
    }
  };

  function findSlotDays(slots: any) {
    let tempArray = []
    const filteredSlots = slots?.filter((items: any) => new Date(mergeSlotDateTime(items?.attributes?.date, items?.attributes?.time)) > currentDateTime)
    const seven_data = filteredSlots.slice(0, 13);
    seven_data?.forEach((obj: any) => {
      tempArray.push(moment(obj?.attributes?.date).format("ddd"));
    });
    const uniqueDay = tempArray?.filter((value, index, array) => array.indexOf(value) === index);
    return uniqueDay.join(" | ");
  };

  const handleDeleteSchedule = (id: any, type: any) => {
    setSelectedId(id);
    setScheduleType(type);
    setShowRemoveModal(true);
  };

  const deleteSchedule = (selectedId: any) => {
    try {
      dispatch(removeSchedule(selectedId, scheduleType) as any)
        .then(() => {
        })
    }
    catch (e) {
      console.log(e);
    }
  };

  return (
    <div className="screen">
      <TopHead
        title="Meal Calendars"
        leftTextIcon={faAngleLeft}
        leftIconStyle={{ fontSize: "24px" }}
        rightTextIcon={faAngleDown}
        rightIconStyle={{ marginLeft: "4px", display: "none" }}
        // rightText="Filter"
        goToHandle={GotoHandle}
      />
      <Divider />

      {/* <div className="grow overflow-auto"> */}
      <IonContent>
        <IonRefresher slot="fixed" onIonRefresh={handleRefresh}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>
        <Section>
          {(notificationsList?.length > 0) &&
            <>
              <TransDiv onClick={() => setHideNotification((prev) => !prev)}>
                Calendars Alert
                <div className="flex items-center justify-center w-[18px]">
                  <FontAwesomeIcon
                    className="text-green-600"
                    icon={hideNotification ? faBellOn : faBell}
                    style={{ float: "right" }}
                  />
                </div>
              </TransDiv>
              <Divider />
            </>
          }
          {
            (hideNotification === false)
            &&
            <>
              {loadingNotification && <div className="flex items-center justify-center"><Spinner /></div>}

              {(notificationsList?.length > 0 && !loadingNotification)
                &&
                <>
                  {notificationsList?.filter((items: any) => items?.attributes?.type === "schedule").map((item: any, index: any) => {
                    return (
                      <FlexBetween key={index} className="mt-2">
                        <SubText1
                          style={{ width: "70%" }}>
                          {switchSentenceCase("head text", (item?.attributes?.from?.data?.attributes?.name) ? item?.attributes?.from?.data?.attributes?.name : (item?.attributes?.from?.data?.attributes?.email) ? item?.attributes?.from?.data?.attributes?.email : item?.attributes?.from?.data?.attributes?.phone_no)} set up a meal calendar for you.
                        </SubText1>
                        <TextPills>New Calendars</TextPills>
                      </FlexBetween>
                    )
                  })}

                  {
                    notificationsList?.filter((items: any) => items?.attributes?.type === "invite").map((item: any, index: any) => {
                      return (
                        <FlexBetween className="mt-3" key={index}>
                          <SubText1 style={{ width: "70%", cursor: "pointer" }}
                            onClick={() => {
                              history.push({
                                pathname: `/calendar-invitation/${encodeURIComponent(item?.attributes?.schedule?.data?.attributes?.slug)}/${encodeURIComponent(item?.attributes?.invitation?.data?.attributes?.token)}/${encodeURIComponent((user?.slug === "" || user?.slug === null) ? user?.id + "d" : user?.slug + "s")}`
                              })
                              dispatch(scheduleDetailsClear() as any)
                              dispatch(invitationScheduleDetails(item) as any);
                              // dispatch(getAllScheduleSlots(item?.attributes?.schedule?.data?.id, "", "", "") as any);
                            }} >
                            You’re invited to a meal calendar for {switchSentenceCase("head text", item?.attributes?.schedule?.data?.attributes?.recipientName)} called “{switchSentenceCase("head text", item?.attributes?.schedule?.data?.attributes?.name)}” by {switchSentenceCase("head text", (item?.attributes?.from?.data?.attributes?.name) ? item?.attributes?.from?.data?.attributes?.name : (item?.attributes?.from?.data?.attributes?.email) ? item?.attributes?.from?.data?.attributes?.email : item?.attributes?.from?.data?.attributes?.phone_no)}.
                          </SubText1>
                          <TextPills className="cursor-pointer">New Invites</TextPills>
                        </FlexBetween>
                      )
                    })
                  }

                  {
                    notificationsList?.filter((items: any) => items?.attributes?.type === "decline_invitation").map((item: any, index: any) => {
                      return (
                        <FlexBetween className="mt-3" key={index}>
                          <SubText1 style={{ width: "70%" }}>
                            Your invitation for meal calendar called “{item?.attributes?.schedule?.data?.attributes?.name}” has been declined by {(item?.attributes?.from?.data?.attributes?.name) ? item?.attributes?.from?.data?.attributes?.name : (item?.attributes?.from?.data?.attributes?.email) ? item?.attributes?.from?.data?.attributes?.email : item?.attributes?.from?.data?.attributes?.phone_no}.
                          </SubText1>
                          <TextPills style={{ color: "#dc2626" }}>{switchSentenceCase("head text", "DECLINED")}</TextPills>
                        </FlexBetween>
                      )
                    })
                  }

                  {
                    notificationsList?.filter((items: any) => items?.attributes?.type === "decline_calendar").map((item: any, index: any) => {
                      return (
                        <FlexBetween className="mt-3" key={index}>
                          <SubText1 style={{ width: "70%" }}>
                            Hey {item?.attributes?.to?.data?.attributes?.name}, thank you for setting up a meal calendar for me in Meal Fave, but I respectfully decline.
                          </SubText1>
                          <TextPills style={{ color: "#dc2626" }}>{switchSentenceCase("head text", "DECLINED")}</TextPills>
                        </FlexBetween>
                      )
                    })
                  }

                  {((totalNotification > limit) && !loadingNotification) &&
                    (
                      (totalNotification !== notificationsList?.length) ?

                        <SubText2
                          className="!text-green-600 text-center cursor-pointer mt-5"
                          onClick={() => { showMore() }}
                        >
                          See Old Notifications
                        </SubText2>
                        :
                        <SubText2
                          className="!text-green-600 text-center mt-5"
                        >
                          No More Notifications
                        </SubText2>
                    )}

                  {(notificationsList?.length > 0) && <SubText2 className="text-right !text-red-600 mt-3 cursor-pointer" onClick={() => removeNotification()}>Clear All</SubText2>}
                </>
              }

              {/* {(notificationsList?.length == 0 && !loadingNotification)
                &&
                <>
                  <SubText2
                    className="!text-green-600 text-center mt-2"
                  >
                    All caught up!
                  </SubText2>
                </>
              } */}
            </>
          }

          <ButtonGreen
            style={{ marginTop: "10px" }}
            onClick={() => {
              dispatch(createScheduleForMeClear() as any)
              history.push({
                pathname: '/schedules',
              })
            }}
          >
            {switchSentenceCase("primary button", "CREATE NEW CALENDAR")}
          </ButtonGreen>

          <Accordion title="Calendar Invites" opened={true}>
            <Divider />
            {loadingNotification && <div className="flex items-center justify-center"><Spinner /></div>}

            {notificationsList?.filter((items: any) => items?.attributes?.type === "invite").length === 0 && notificationsList?.filter((items: any) => items?.attributes?.type === "decline_invitation").length === 0 ? (
              <SubText1 className="text-center !text-gray-400 !text-base mt-10">
                No calendars alerts found.
              </SubText1>
            ) : (
              <>
                {
                  notificationsList?.filter((items: any) => items?.attributes?.type === "invite").map((item: any, index: any) => {
                    return (
                      <InitialDiv className="my-4" key={index}>
                        <FlexBetween>
                          <InitialDiv
                            className="w-[80%]"
                            onClick={() => {
                              history.push({
                                pathname: `/calendar-invitation/${encodeURIComponent(item?.attributes?.schedule?.data?.attributes?.slug)}/${encodeURIComponent(item?.attributes?.invitation?.data?.attributes?.token)}/${encodeURIComponent((user?.slug === "" || user?.slug === null) ? user?.id + "d" : user?.slug + "s")}`
                              })
                              dispatch(scheduleDetailsClear() as any)
                              dispatch(invitationScheduleDetails(item) as any);
                            }}>
                            <SubText1 className={isDarkMode ? "!text-white cursor-pointer" : "!text-black cursor-pointer"} >
                              <span className={"font-bold text-[#22ae62]"}>{switchSentenceCase("head text", `Invite by ${(item?.attributes?.from?.data?.attributes?.name) ? item?.attributes?.from?.data?.attributes?.name : (item?.attributes?.from?.data?.attributes?.email) ? item?.attributes?.from?.data?.attributes?.email : item?.attributes?.from?.data?.attributes?.phone_no}`)}</span>
                            </SubText1>
                            <SubText2 className="text-base text-left cursor-pointer">
                              {switchSentenceCase("head text", item?.attributes?.schedule?.data?.attributes?.name && `(${item?.attributes?.schedule?.data?.attributes?.name})`)}
                            </SubText2>
                            <SubText1 className="!w-[70%] cursor-pointer">
                              You’re invited to a meal calendar for {switchSentenceCase("head text", item?.attributes?.schedule?.data?.attributes?.recipientName)} called “{switchSentenceCase("head text", item?.attributes?.schedule?.data?.attributes?.name)}” by {switchSentenceCase("head text", (item?.attributes?.from?.data?.attributes?.name) ? item?.attributes?.from?.data?.attributes?.name : (item?.attributes?.from?.data?.attributes?.email) ? item?.attributes?.from?.data?.attributes?.email : item?.attributes?.from?.data?.attributes?.phone_no)}.
                            </SubText1>
                          </InitialDiv>
                          {/* <TextPills className="cursor-pointer">New Invites</TextPills> */}
                          <FontAwesomeIcon className="text-red-600 cursor-pointer mt-1" icon={faTrashCanList} onClick={() => removeNotificationById(item?.id)} />
                        </FlexBetween>
                      </InitialDiv>
                    )
                  })
                }
                {
                  notificationsList?.filter((items: any) => items?.attributes?.type === "decline_invitation").map((item: any, index: any) => {
                    return (
                      <InitialDiv className="my-4" key={index}>
                        <SubText1 className={isDarkMode ? "!text-white cursor-pointer" : "!text-black cursor-pointer"} >
                          <span className={"font-bold text-[#dc2626]"}>{switchSentenceCase("head text", "Invitation Declined")}</span>
                        </SubText1>
                        <SubText2 className="text-base text-left cursor-pointer">
                          {switchSentenceCase("head text", item?.attributes?.schedule?.data?.attributes?.name)}
                        </SubText2>
                        <FlexBetween className="">
                          <SubText1 style={{ width: "70%" }}>
                            Your invitation for meal calendar called “{item?.attributes?.schedule?.data?.attributes?.name}” has been declined by {(item?.attributes?.from?.data?.attributes?.name) ? item?.attributes?.from?.data?.attributes?.name : (item?.attributes?.from?.data?.attributes?.email) ? item?.attributes?.from?.data?.attributes?.email : item?.attributes?.from?.data?.attributes?.phone_no}.
                          </SubText1>
                          <TextPills style={{ color: "#dc2626" }}>{switchSentenceCase("head text", "DECLINED")}</TextPills>
                        </FlexBetween>
                      </InitialDiv>
                    )
                  })
                }
              </>
            )}
          </Accordion>

          <Accordion title="Calendars For Me" opened={true}>
            <Divider />

            {loadingScheduleForMe && <div className="flex items-center justify-center"><Spinner /></div>}

            {(scheduleForMe?.length > 0 && !loadingScheduleForMe)
              &&
              scheduleForMe?.map((item: any, index: any) => {

                return (

                  <div className="my-4" key={index}>
                    <FlexBetween className="mb-4">
                      <Block className="cursor-pointer mr-2"
                        onClick={() => {
                          dispatch(notificationDetailsClear() as any);
                          dispatch(
                            getScheduleDetails(item?.id, "for_me") as any
                          );
                          history.push({
                            pathname: `/view-calendar/${item?.attributes?.slug}/${(user?.slug === "" || user?.slug === null) ? user?.id + "d" : user?.slug + "s"}`,
                          })
                        }}
                      >
                        <SubText1 className={isDarkMode ? "!text-white cursor-pointer" : "!text-black cursor-pointer"} >
                          <span className={isDarkMode ? "font-bold text-white" : "font-bold text-black"}>{switchSentenceCase("head text", item?.attributes?.name)}</span>
                        </SubText1>
                        <SubText2 className="mt-1">
                          {findSlotDays(item?.attributes?.slots?.data)?.length > 0 ? findSlotDays(item?.attributes?.slots?.data) + "," : ""} {item?.attributes?.slot_time.join(", ")}, Until {scheduleEndDateFormatter(item?.attributes?.end_date)}
                        </SubText2>
                        <FlexBetween style={{ columnGap: '70px', marginTop: '5px' }}>
                          {getNextSlot(item?.attributes?.slots?.data) && <SubText2>{getNextSlot(item?.attributes?.slots?.data)}</SubText2>}
                          <SubText2 style={{ color: "#22A45D" }}>{getNextOrder(item?.attributes?.slots?.data)}</SubText2>
                        </FlexBetween>
                      </Block>
                      <FontAwesomeIcon className="text-red-600 cursor-pointer mt-2 ml-2" icon={faTrashCan} onClick={() => handleDeleteSchedule(item?.id, "for_me")} />
                    </FlexBetween>
                    <Divider />
                  </div>
                )
              }
              )
            }
            {(scheduleForMe?.length === 0 && !loadingScheduleForMe)
              &&
              <SubText1 className="text-center !text-gray-400 !text-base mt-10">
                You have no calendars.
              </SubText1>
            }

            {((totalForMe > limit) && !loadingScheduleForMe)
              &&
              (
                (totalForMe !== scheduleForMe?.length)
                  ?
                  <SubText2
                    className="!text-green-600 text-center cursor-pointer"
                    onClick={() => { showMoreForMe() }}
                  >
                    See More
                  </SubText2>
                  :
                  <SubText2
                    className="!text-green-600 text-center"
                  >
                    No More Schedules
                  </SubText2>
              )
            }
          </Accordion>

          <Accordion title="Calendars For Others" opened={true}>
            <Divider />
            {loadingScheduleForOther && <div className="flex items-center justify-center"><Spinner /></div>}

            {(scheduleForOther?.length > 0 && !loadingScheduleForOther)
              &&
              scheduleForOther?.map((item: any, index: any) =>
                <div className="my-4" key={index}>
                  <FlexBetween className="mb-4">
                    <Block className="cursor-pointer mr-2"
                      onClick={() => {
                        dispatch(notificationDetailsClear() as any);
                        dispatch(
                          getScheduleDetails(item?.id, "for_other") as any
                        );
                        history.push({
                          pathname: `/view-calendar/${item?.attributes?.slug}/${(user?.slug === "" || user?.slug === null) ? user?.id + "d" : user?.slug + "s"}`,
                        })
                      }}>

                      <SubText1 className={isDarkMode ? "!text-white cursor-pointer" : "!text-black cursor-pointer"} >
                        <span className={isDarkMode ? "font-bold text-white" : "font-bold text-black"}>{switchSentenceCase("head text", item?.attributes?.name)}</span>
                      </SubText1>
                      <SubText2 className="mt-1">
                        {findSlotDays(item?.attributes?.slots?.data)?.length > 0 ? findSlotDays(item?.attributes?.slots?.data) + "," : ""} {item?.attributes?.slot_time.join(", ")}, Until {scheduleEndDateFormatter(item?.attributes?.end_date)}
                      </SubText2>

                      <FlexBetween style={{ columnGap: '70px', marginTop: '5px' }}>
                        <SubText2 >{getNextSlot(item?.attributes?.slots?.data)}</SubText2>
                        <SubText2 style={{ color: "#22A45D" }}>{getNextOrder(item?.attributes?.slots?.data)}</SubText2>
                      </FlexBetween>
                    </Block>
                    <FontAwesomeIcon className="text-red-600 cursor-pointer mt-1" icon={faTrashCan} onClick={() => handleDeleteSchedule(item?.id, "for_other")} />
                  </FlexBetween>
                  <Divider />
                </div>
              )
            }

            {(scheduleForOther?.length === 0 && !loadingScheduleForOther)
              &&
              <SubText1 className="text-center !text-gray-400 !text-base mt-10">
                You have no calendars.
              </SubText1>
            }

            {((totalForOther > limit) && !loadingScheduleForOther) &&
              (
                (totalForOther !== scheduleForOther?.length) ?

                  <SubText2 className="text-green-600 text-center cursor-pointer" onClick={() => { showMoreForOthers() }}>
                    See More
                  </SubText2>
                  :
                  <SubText2 className="text-green-600 text-center">
                    No More Schedules
                  </SubText2>
              )
            }
          </Accordion>

        </Section>


        {/**************************************** Modal Confirm Delete Popup ******************************************/}
        <ModalRemoved
          firstText="Are you sure you want to"
          secondText="cancel this Calendar?"
          btnOneText="OOPS!  NEVER MIND..."
          btnTwoText="YES, CANCEL IT"
          showModal={showRemoveModal}
          setShowModal={setShowRemoveModal}
          deleteItem={deleteSchedule}
          id={selectedId}
        />
      </IonContent>
      {/* </div> */}
    </div >
  );
}
