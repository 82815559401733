import React, { useEffect, useState } from "react";
import Divider from "../../components/widgets/divider/Divider";
import Section from "../../components/widgets/section/Section";
import OneSideTextToggle from "../../components/widgets/buttons/OneSideTextToggle";
import TopHead from "../../components/widgets/head/TopHead";
import { switchSentenceCase, displayName, isAuthenticated, trackPixelEvent } from "../../utils/utilities";
import { faAngleLeft } from "@fortawesome/pro-solid-svg-icons";
import { useSelector } from "react-redux";
import { faveAddNewCategory, getUserFavoriteMeal } from "../../services/favourite";
import ModalSpinner from "../../components/widgets/modalspinner/ModalSpinner";
import { ButtonGreen, TextBox2 } from "../../components/styled/styled";
import ModalSuccess from "../../components/widgets/modals/ModalSuccess";
import { useHistory } from "react-router";
import { getUserInfo } from "../../services/user";
import { setUserFaveMealList } from "./redux/favouriteActionCreator";
import Spinner from "../../components/widgets/spinner/Spinner";
import { useIonViewDidEnter } from "@ionic/react";


export default function Faves() {
  const history = useHistory();
  const userData = getUserInfo();
  const limit = 10;

  const { userFaveMealsList } = useSelector((state: any) => state?.getAllFavData);
  const { userDeliveryAddress } = useSelector((state: any) => state.userAddresses);

  const [skip, setSkip] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [totalItems, setTotalItems] = useState(0);
  const [defaultMealState, setDefaultMealState] = useState(false);
  const [schedulesMealState, setSchedulesMealState] = useState(true);
  const [faveMealsState, setFaveMealsState] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [awaiting, setAwaiting] = useState(false);
  const [mealCategoryName, setMealCategoryName] = useState("");
  const [mealCategoryNameError, setMealCategoryNameError] = useState(false);
  const [inputList, setInputList] = useState([{ id: "", name: "", selected: false }]);

  useEffect(() => {
    setIsLoading(true);
    // CategoryData();
    callData();
  }, []);

  useIonViewDidEnter(() => {
    trackPixelEvent('Add new fave category');
  }, []);

  const callData = async (skippingValue = 0) => {
    if (isAuthenticated() === false) return false;
    if (userDeliveryAddress?.city === undefined || userDeliveryAddress?.city === "") {
      setIsLoading(false);
      return false;
    }
    try {
      const response = await getUserFavoriteMeal({
        id: userData?.id,
        type: "Fav",
        skip: skippingValue,
        limit: limit,
        lat: userDeliveryAddress?.lat,
        lang: userDeliveryAddress?.lang,
      });

      trackPixelEvent('Get user fave meal');
      setTotalItems(response?.total);
      let tempData = response?.data;
      let tempArray = [];

      if (skippingValue > 0) {
        setUserFaveMealList([...userFaveMealsList, ...tempData]);
        setIsLoading(false);

        tempData?.forEach((currentObj: any) => {
          tempArray.push({
            id: currentObj?.id,
            name: currentObj?.attributes?.name,
            selected: faveMealsState,
          });
        });
        setInputList([...inputList, ...tempArray]);
      } else {
        setUserFaveMealList(tempData);
        setIsLoading(false);
        tempData?.forEach((currentObj: any) => {
          tempArray.push({
            id: currentObj?.id,
            name: currentObj?.attributes?.name,
            selected: faveMealsState,
          });
        });
        setInputList(tempArray);
      }
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  };

  const showMore = () => {
    setIsLoading(true);
    const skippingValue = skip + limit;
    setSkip(skippingValue);
    callData(skippingValue);
  };

  function DefaultMealState() {
    setDefaultMealState(!defaultMealState);
  }
  function SchedulesMealState() {
    setSchedulesMealState(!schedulesMealState);
  }

  function selectAllButton() {
    setFaveMealsState(!faveMealsState);

    let tempArray = [];
    inputList?.forEach((currentObj: any) => {
      tempArray.push({
        id: currentObj?.id,
        name: currentObj?.name,
        selected: !faveMealsState,
      });
    });
    setInputList(tempArray);
  }

  const _setMealCategoryName = (value: any) => {
    setMealCategoryName(String(value));
    if (String(value).trim()?.length === 0) {
      setMealCategoryNameError(true);
    } else {
      setMealCategoryNameError(false);
    }
  };

  const FaveMealsSwitch = (value: boolean, index: number) => {
    const list: any = [...inputList];
    list[index]["selected"] = value;
    setInputList(list);

    // Select all button automation
    const filteredTempArrayLength: [] = list?.filter((el: any) => el?.selected == true);
    setFaveMealsState(filteredTempArrayLength?.length == list?.length);
  };

  const handleSubmit = async () => {
    let tempDataArray = [];
    inputList?.forEach((currentData: any) => {
      if (currentData?.selected === true) {
        tempDataArray.push(currentData?.id);
      }
    });

    // ---------------------Checking Validation-------------
    if (mealCategoryName.trim()?.length === 0) {
      document.getElementById("mealCategoryName").scrollIntoView();
      setMealCategoryNameError(true);
      return false;
    } else {
      setMealCategoryNameError(false);
    }

    //------------------  Api Call---------------

    setAwaiting(true);
    const categoryMealData = {
      defaultMealState: defaultMealState,
      suggestedMeal: schedulesMealState,
      categoryId: tempDataArray,
      categoryName: mealCategoryName,
    };

    // ----------Sending Data to Post API Function----------
    try {
      await faveAddNewCategory(categoryMealData).then((response: any) => {
        let data = response;
        if (data?.status === 200) {
          trackPixelEvent('Fav add new category');
          setAwaiting(false);
          window.history.back();
          // setShowModal(true);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="screen">
      <TopHead
        title="Fave Meal Category"
        leftTextIcon={faAngleLeft}
        leftIconStyle={{ fontSize: "24px" }}
      />
      <Divider />

      <div className="grow overflow-auto">
        <Section>
          {/* <SubText1 className="text-right mt-[0px]">
            {mealCategoryName?.length}/{30}
          </SubText1> */}
          <TextBox2
            style={{
              textAlign: "left",
              marginTop: "10px",
              fontWeight: "200",
              border: mealCategoryNameError ? "1px solid red" : "",
            }}
            id="mealCategoryName"
            name="mealCategoryName"
            value={mealCategoryName}
            onChange={(event) => _setMealCategoryName(event.target.value)}
            placeholder={switchSentenceCase("head text", `${displayName()} Faves 1`)}
            maxLength={30}
          />

          <OneSideTextToggle
            leftText="Default for New Fave Meals"
            id="defaultMealState"
            name="defaultMealState"
            switchState={defaultMealState}
            handleOnChangeToggle={DefaultMealState}
          />
          <OneSideTextToggle
            leftText="Visible to Others"
            id="schedulesMealState"
            name="schedulesMealState"
            switchState={schedulesMealState}
            handleOnChangeToggle={SchedulesMealState}
          />
          {userFaveMealsList?.length > 0 && (
            <OneSideTextToggle
              leftText={"My Fave Meals"}
              style={{ fontSize: "18", fontWeight: "500" }}
              beforeBtnText="Select All"
              id="faveMealsState"
              name="faveMealsState"
              switchState={faveMealsState}
              handleOnChangeToggle={selectAllButton}
            />
          )}

          <Divider />

          {userFaveMealsList?.map((favMeal: any, index: any) => {
            return (
              <React.Fragment key={index}>
                <OneSideTextToggle
                  leftText={favMeal?.attributes?.name}
                  style={{ paddingLeft: "15px" }}
                  name={favMeal?.attributes?.name}
                  id={favMeal?.id}
                  switchState={inputList[index]?.selected}
                  handleOnChangeToggle={() => {
                    FaveMealsSwitch(!inputList[index]?.selected, index);
                  }}
                />
              </React.Fragment>
            );
          })}

          {totalItems > limit &&
            (totalItems !== userFaveMealsList?.length ? (
              <div
                className="text-green-600 text-center cursor-pointer mt-4 font-semibold"
                onClick={() => {
                  showMore();
                }}
              >
                {!isLoading ? (
                  <span>See More</span>
                ) : (
                  <span>
                    <Spinner />
                  </span>
                )}
              </div>
            ) : (
              <div className="text-green-600 text-center mt-4 font-semibold">
                No More Fave Meals
              </div>
            ))}

          <ButtonGreen onClick={handleSubmit} style={{ marginTop: "25px" }}>
            {switchSentenceCase("primary button", "SAVE & BACK")}
          </ButtonGreen>

          {awaiting ? <ModalSpinner /> : null}

          <ModalSuccess
            firstText="Category Added Successfully"
            secondText="You created your category Successfully !"
            btnOneText="Done"
            btnTwoText="HOME"
            btnTwoStyle={{ border: "1px solid #e1e1e1", marginTop: "15px" }}
            btnOneGoto={() => setShowModal(false)}
            btnTwoGoto={() => history.push({ pathname: "/home" })}
            showModal={showModal}
            setShowModal={setShowModal}
          />
        </Section>
      </div>
    </div>
  );
}
