import { faAngleLeft, faMinus, faPlus } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { useHistory } from "react-router";
import { Astk, ButtonGreen, InputLabel, InputText, TextArea1, TextBox1, TextHead1, TextHead3 } from "../../components/styled/styled";
import OneSideTextToggle from "../../components/widgets/buttons/OneSideTextToggle";
import ToggleWithSubText from "../../components/widgets/buttons/ToggleWithSubText";
import Divider from "../../components/widgets/divider/Divider";
import TopHead from "../../components/widgets/head/TopHead";
import Section from "../../components/widgets/section/Section";
import { switchSentenceCase } from "../../utils/utilities";
import SchedulingWidget from "../scheduleotherpages/SchedulingWidget";


export default function CreateTrainV2() {
  const history = useHistory();

  const [count, setCount] = useState(1);
  const [switchState, setSwitchState] = useState(true);
  const [faveMealsState, setFaveMealsState] = useState(true);


  const GotoHandle = () => {
    history.push({
      pathname: '/schedules',
    })
  }


  function handleOnChangeToggle() {
    setSwitchState(switchState === false);
  }

  function FaveMealsState() {
    setFaveMealsState(faveMealsState === false);
  }


  return (
    <div className="screen">
      <TopHead
        title="New Meal Calendar For Someone Else"
        leftTextIcon={faAngleLeft}
        leftIconStyle={{ fontSize: "24px" }}
        goToHandle={GotoHandle}
      />
      <Divider />
      <div className="grow overflow-auto">

        <Section>
          <TextHead1>Meal Schedule Name</TextHead1>
          <TextBox1
            placeholder="Meal Schedule for MIkey"
          // data={data._RealtimeSummary}
          // step={step}
          // startEditable
          />

          <TextHead1>Meal Schedule Description</TextHead1>
          <TextArea1 placeholder="Let’s send Mikey food." />

          <OneSideTextToggle leftText="Set the Schedule for Meals Now" style={{ fontSize: "17px", fontWeight: "500" }} switchState={faveMealsState} handleOnChangeToggle={FaveMealsState} />


          {/* <TextHead3 style={{ marginTop: "15px" }}>***{`${displayName()}`} Scheduling Widget if selected</TextHead3>
          <TextHead3> Recipient will have ability to create or change the schedule.</TextHead3> */}
          <SchedulingWidget />

          <ToggleWithSubText
            style={{ marginLeft: "0px", marginTop: "50px" }}
            text="Invite Others to Sponsor Meals"
            subText="You can share the meal schedule with your foodies so they can send fave meals when and where they’re wanted!
            The invite will go out when the schedule is created."
            switchState={switchState}
            handleOnChangeToggle={handleOnChangeToggle}
          />
        </Section>

        <Section>
          <TextHead3 style={{ marginBottom: "10px" }}>Invite From Contacts</TextHead3>
          <ButtonGreen>{switchSentenceCase("primary button", "ADD FROM CONTACTS")}</ButtonGreen>
          <Divider />

          <div className="flex justify-between items-center">
            <TextHead3 style={{ margin: "15px 0px" }}>Invite By Email </TextHead3>
            <div>
              <FontAwesomeIcon className="bg-white p-0.5 rounded mr-1.5 shadow-green-50"
                style={{ boxShadow: "0px 0px 135px 20px #35bb67" }}
                icon={faMinus}
                onClick={() => { setCount(count - 1) }}
              />
              {count}
              <FontAwesomeIcon className="bg-white p-0.5 rounded ml-1.5" icon={faPlus} onClick={() => { setCount(count + 1) }} />
            </div>
          </div>

          <InputLabel>Email Address <Astk> *</Astk></InputLabel>
          <InputText style={{ marginBottom: "20px" }} placeholder="Enter text"></InputText>

          <InputLabel>Email Address <Astk> *</Astk></InputLabel>
          <InputText style={{ marginBottom: "20px" }} placeholder="Enter text"></InputText>

          <InputLabel>Email Address <Astk> *</Astk></InputLabel>
          <InputText style={{ marginBottom: "20px" }} placeholder="Enter text"></InputText>
        </Section>

        <Section>
          <ButtonGreen onClick={() => {
            history.push({
              pathname: '/newmealpayments',
            })
          }}>{switchSentenceCase("primary button", "SAVE & CONTINUE")}</ButtonGreen>
        </Section>

      </div>
    </div >
  );
}

