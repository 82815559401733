import { apiRequestService } from './apiRequestService';
import { getUserData } from './user';

const API_URL = process.env.REACT_APP_API_URL

// Get Notifications
export const getNotifications = async (skip: any, limit: any) => {
    try {
        const user = await getUserData()
        const user_id = user?.user?.id

        let config = {
            method: 'get',
            url: API_URL + `/v1/notifications?filters[to][id][$eq]=${user_id}&filters[trash][$eq]=false&populate[schedule][populate][delivery_location]=*&populate[schedule][populate][user]=*&populate[invitation]=*&populate[from]=*&populate[to]=*&sort=createdAt:desc&pagination[start]=${skip || 0}&pagination[limit]=${limit || 10}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + user?.jwt
            },
        };
        const request = apiRequestService(config);
        return request;
    } catch (error) {
        console.log(error);
    }
}

// Read Notification
export const updateNotification = async (id: any, requestData: any) => {
    try {
        const user = await getUserData()

        let config = {
            method: 'put',
            url: API_URL + `/v1/notifications/${id}?&populate[schedule]=*&populate[invitation]=*&populate[from]=*&populate[to]=*`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + user?.jwt
            },
            data: requestData
        };
        const request = apiRequestService(config);
        return request;
    } catch (error) {
        console.log(error);
    }
}


//Clear Notification
export const clearAllNotifications = async (requestData: any) => {
    try {
        const user = await getUserData()
        let config = {
            method: 'put',
            url: API_URL + `/v1/bulk/notifications`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + user?.jwt
            },
            data: requestData
        };
        const request = apiRequestService(config);
        return request;
    } catch (error) {
        console.log(error);
    }
}

// Gift Notification
export const giftNotifyForCreateFave = async (requestData: any) => {
    try {
        const user = await getUserData()

        let config = {
            method: 'POST',
            url: API_URL + `/v1/gift-notifications`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + user?.jwt
            },
            data: requestData
        };

        const request = apiRequestService(config);
        return request;
    } catch (error) {
        console.log(error);
    }
}

