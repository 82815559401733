import { useRef, useState, useEffect } from "react";
import styled from "styled-components";
import { ButtonGreen, FlexBetween, TextHead5, TextHead4, Flex, InitialDiv, TextHead1, moveTopBottom } from "../../styled/styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faCircleInfo, faXmark } from "@fortawesome/pro-solid-svg-icons";
import { faHeart } from "@fortawesome/pro-regular-svg-icons";
import Section from "../section/Section";
import PriceChnageConfirmModal from "./PriceChnageConfirmModal";
import { useHistory } from "react-router";
import { getSponsorFavItemsDetails, MealSponsored } from "../../../pages/schedules/redux/createScheduleReduxActions";
import { useDispatch, useSelector } from "react-redux";
import { slotDateTimeFormatter, switchSentenceCase, subtotalFunction, trackPixelEvent, priceFormatterUsd } from "../../../utils/utilities";
import { getUserInfo } from "../../../services/user";
import ModalSingleFavItemDetail from "./ModalSingleFavItemDetail";
import PaymentCart from "../paymentscomponent/PaymentCart";
import Accordion from "../accordian/AccordionStyled";
import { useIonAlert } from "@ionic/react";
import { userMe } from "../../../services/user";
import useDarkMode from "../../hooks/darkmode/DarkMode";
import ToolTip from "../alerts/tooltip";

const ModalDiv = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1000;
  overflow-y: auto;
`;

const ContentDiv = styled.div<{ isDarkMode: boolean }>`
    background-color: ${props => (props?.isDarkMode ? '#2e2e2e' : '#fff')};;
    margin-bottom: 85px;
    margin-top: 80px;
    padding: 10px;
    z-index: 1000;
    width: 600px;
    max-width: 90%;
    border-radius: 20px;
    text-align: center;
    height: fit-content;
    animation: ${moveTopBottom} .4s ease-in-out;
`;

const HeartModalIcon = styled.div`
  padding: 3px;
  background: #f03f3f29;
  border-radius: 40px;
  text-align: center;
  height: -webkit-fit-content;
  font-size: 14px;
  color: #ff000082;
  width: 28px;
  margin-top: 5px;
  margin-left: 10px;
`;

const DoneModalIcon = styled.div`
  width: 60px;
  height: 60px;
  padding: 18px;
  z-index: 10;
  background: #22a45d;
  color: white;
  border-radius: 40px;
  margin: auto;
  position: relative;
  bottom: 65px;
  margin-bottom: -90px;
`;

const BackModalBtn = styled.div`
  position: relative;
  right: 49%;
  font-size: 17px;
  cursor: pointer;
`;

const OverflowDiv = styled.div`
  overflow-y: scroll;
  height: auto;
  max-height: 200px;
  -ms-overflow-style: none;  
  scrollbar-width: none;
  ::-webkit-scrollbar { 
    display: none;  
}
`;

export default function ModalSponsorMeal(props) {

    const history = useHistory();
    const modalRef = useRef();
    const dispatch = useDispatch();
    const user = getUserInfo();
    const [presentAlert] = useIonAlert();
    const isDarkMode = useDarkMode();

    const { selectedSlots, scheduleInvitationDetail } = useSelector((state: any) => state?.createScheduleForMe);
    const { scheduleSlug, invitationToken } = useSelector((state: any) => state?.scheduleInvitationState);
    const { orderType } = useSelector((state: any) => state.getUserRestaurantsLists);

    const [loggedUserData, setLoggedUserData] = useState({ attributes: null, id: null });
    const [showModalprice, setShowModalprice] = useState(false);
    const [showModalSlotFill, setShowModalSlotFill] = useState(false);
    //(scheduleDetails !== undefined) ? scheduleDetails?.attributes?.confirm_before_order :
    const [confirmPrior, setConfirmPrior] = useState(selectedSlots[0]?.attributes?.notify_price_increase);
    const [paymentCardId, setPaymentCardId] = useState("");
    const [paymentCardError, setPaymentCardError] = useState(false);
    const [orderProcess, setOrderProcess] = useState(false);

    const slots = selectedSlots || [];
    useEffect(() => {
        callUserData();
    }, [])

    // Get Logged User Info
    const callUserData = async () => {
        try {
            const { data } = await userMe();
            trackPixelEvent('User me');
            setLoggedUserData({ attributes: data, id: data?.id });
        }
        catch (e) {
            console.log(e)
        }
    };
    const subTotalPrice = subtotalFunction(slots) * 100;

    // const tax = orderType === "pickup" ? (subTotalPrice * PICKUP_TAX_PERCENT) / 100 : (subTotalPrice * DELIVERY_TAX_PERCENT) / 100;
    // const fees = orderType === "pickup" ? (subTotalPrice * PICKUP_FEES_PERCENT) / 100 : (subTotalPrice * DELIVERY_FEES_PERCENT) / 100;
    const tips = (orderType === "delivery") ? Number(user?.driver_auto_tip) : Number(user?.auto_tip_amt) || 0;
    const tip_subtotal = tips * slots?.length * 100 || 0;
    const tax_fees_estimate = orderType === "pickup" ? subTotalPrice / 4 : subTotalPrice / 2;
    const totalPrice = subTotalPrice + tax_fees_estimate + tip_subtotal;
    const [skip, setSkip] = useState(3);

    const handlePaymentId = (paymentCardId: any) => {
        setPaymentCardId(paymentCardId);
        setPaymentCardError(false);
    }

    function showMoreSlots() {
        setSkip(prevSkip => prevSkip + 3);
    }

    const closeModal = (e: any) => {
        if (modalRef.current === e.target) {
            props?.setShowModal(false);
        }
        // setMappedArray([]);
    };

    const placeOrder = async () => {
        try {
            if (paymentCardId === "" || paymentCardId === undefined) {
                presentAlert({
                    header: '',
                    message: 'No Payment Method Found. Please Add your Card.',
                    buttons: ['OK'],
                })
                return false;
            }
            setOrderProcess(true);
            const updateData = {
                slotArray: slots,
                sponsoredBy: (scheduleInvitationDetail?.id) ? scheduleInvitationDetail?.attributes?.user?.data : loggedUserData,
                price_notify: confirmPrior,
                paymentCardId: paymentCardId,
                order_type: orderType
            }
            let tempArray = []
            slots?.forEach((obj: any) => {
                tempArray.push({ slotId: obj?.id, favMealId: obj?.attributes?.favMeal?.data?.id, date: obj?.attributes?.date, time: obj?.attributes?.time, deliveryAddress: obj?.attributes?.delivery_location?.data?.id });
            });
            const payloadData = {
                data: {
                    slots: tempArray,
                    price_notify: confirmPrior,
                    sponsoredBy: (scheduleInvitationDetail?.id) ? scheduleInvitationDetail?.attributes?.user?.data?.id : user?.id,
                    invitationId: scheduleInvitationDetail?.id,
                    paymentCardId: paymentCardId,
                    order_type: orderType
                }
            }
            dispatch(MealSponsored(payloadData, updateData) as any)
                .then(() => {
                    props?.setShowModal((prev: any) => !prev);
                    props?.setSelectedSlots([]);
                    setOrderProcess(false);
                    // setConfirmPrior(scheduleDetails?.attributes?.confirm_before_order);
                })
        }
        catch (e) {
            console.log(e);
            setOrderProcess(false);
        }
    }

    const openModalHandler = (id: any) => {
        dispatch(getSponsorFavItemsDetails(id) as any);
        setShowModalSlotFill((prev) => !prev);
    }

    return (
        <>
            {props?.showModal ? (
                <ModalDiv onClick={closeModal}>
                    <ContentDiv isDarkMode={isDarkMode}>
                        <BackModalBtn>
                            <FontAwesomeIcon
                                icon={faXmark}
                                className="cursor-pointer"
                                onClick={() => props?.setShowModal((prev: any) => !prev)}
                            />
                        </BackModalBtn>
                        <DoneModalIcon>
                            <FontAwesomeIcon icon={faCheck} style={{ fontSize: "25px" }} />
                        </DoneModalIcon>
                        <InitialDiv className="mt-8">
                            <TextHead1 style={{ color: isDarkMode ? "white" : "black" }}>
                                Sponsor Meal
                            </TextHead1>
                        </InitialDiv>

                        <Section>
                            <TextHead5 style={{ margin: "0px 0px", fontWeight: "500" }}>
                                Payment will process at time of order. Meal totals include
                                Taxes, Fees, and Tips.
                            </TextHead5>
                        </Section>

                        <InitialDiv className="mx-4 text-left">
                            <Accordion title="Slots" opened={true}>
                                <OverflowDiv >
                                    {slots?.slice(0, skip)?.map((item: any, index: any) =>
                                        <InitialDiv key={index} className="my-2">
                                            <InitialDiv className="flex">
                                                <TextHead5>
                                                    {slotDateTimeFormatter(item?.attributes?.date, item?.attributes?.time)}
                                                </TextHead5>
                                                {item?.attributes?.order_type === "pickup" &&
                                                    <TextHead5 className="!ml-2">
                                                        (Pickup)
                                                    </TextHead5>
                                                }
                                            </InitialDiv>

                                            <FlexBetween
                                                style={{ alignItems: "center" }}
                                                onClick={() => openModalHandler(item?.attributes?.favMeal?.data?.id)}
                                            >
                                                <TextHead4 className="cursor-pointer">
                                                    {switchSentenceCase("head text", ((item?.attributes?.favMeal?.data) ? item?.attributes?.favMeal?.data?.attributes?.name : "No Fav Meal Available for this slot time!"))}
                                                </TextHead4>
                                                <div className="flex items-center">
                                                    {(item?.attributes?.favMeal?.data !== null) &&
                                                        <HeartModalIcon>
                                                            <FontAwesomeIcon icon={faHeart} />
                                                        </HeartModalIcon>
                                                    }
                                                    <TextHead5 style={{ marginLeft: "10px" }}> {(item?.attributes?.favMeal?.data) ? `$${(item?.attributes?.favMeal?.data?.attributes.total.toFixed(2))}` : ""}</TextHead5>
                                                </div>
                                            </FlexBetween>
                                        </InitialDiv>
                                    )}
                                </OverflowDiv>

                                {(slots.length > 4 && slots.length >= skip) &&
                                    <div onClick={showMoreSlots}
                                        className="text-green-600 text-center cursor-pointer mt-2"
                                    >
                                        Show More
                                    </div>
                                }
                            </Accordion>
                        </InitialDiv>
                        <InitialDiv className="mx-4">
                            {/********************  Payment Section Section ******************************/}
                            <Accordion title="Payment" opened={true}>
                                <PaymentCart paymentId={handlePaymentId} userId={user?.id} changePayment={true} selectedCard={null} />
                                {paymentCardError && <TextHead5 style={{ color: "red" }}>
                                    Payment is required.
                                </TextHead5>}
                            </Accordion>
                        </InitialDiv>
                        <Section>
                            <FlexBetween>
                                {/* <TextHead5>Subtotal</TextHead5>
                                <TextHead5>${subTotalPrice.toFixed(2)}</TextHead5> */}
                                <Flex className="items-baseline">
                                    <TextHead5 style={{ fontWeight: "500" }}>
                                        Food Subtotal*
                                    </TextHead5>
                                    <ToolTip
                                        className="mt-10"
                                        instructions="Food costs are subject to restaurant price changes."
                                    />
                                </Flex>
                                <TextHead5 style={{ fontWeight: "500" }}>
                                    {priceFormatterUsd(subTotalPrice)}
                                </TextHead5>
                            </FlexBetween>

                            <FlexBetween>
                                {/* <TextHead5>Taxes</TextHead5>
                                <TextHead5>${tax.toFixed(2)}</TextHead5> */}
                                <TextHead5 style={{ fontWeight: "500" }}>
                                    Tip Subtotal
                                </TextHead5>
                                <TextHead5 style={{ fontWeight: "500" }}>
                                    {priceFormatterUsd(tip_subtotal)}
                                </TextHead5>
                            </FlexBetween>

                            <FlexBetween>
                                {/* <TextHead5>Fees</TextHead5>
                                <TextHead5>${fees.toFixed(2)}</TextHead5> */}
                                <Flex className="items-baseline">
                                    <TextHead5 style={{ fontWeight: "500" }}>
                                        Taxes and Fees Estimate**
                                    </TextHead5>
                                    <ToolTip
                                        className="!mt-[7px]"
                                        instructions={`Taxes and Fees are estimates only and subject to change. Actual cost will be calculated on the day of ${(orderType === "delivery") ? "delivery" : "pickup"}.`}
                                    />
                                </Flex>
                                <TextHead5 style={{ fontWeight: "500" }}>
                                    {priceFormatterUsd(tax_fees_estimate)}
                                </TextHead5>
                            </FlexBetween>
                            <FlexBetween>
                                {/* <TextHead5>Tips</TextHead5>
                                <TextHead5>${tips.toFixed(2)}</TextHead5> */}
                                <TextHead5 style={{ fontWeight: "500" }}>
                                    Estimated Total
                                </TextHead5>
                                <TextHead5 style={{ fontWeight: "500" }}>
                                    {priceFormatterUsd(totalPrice)}
                                </TextHead5>
                            </FlexBetween>
                            {/* <FlexBetween>
                                <TextHead5 style={{ fontWeight: "500" }}>Total</TextHead5>
                                <TextHead5 style={{ fontWeight: "500" }}>${totalPrice.toFixed(2)}</TextHead5>
                            </FlexBetween> */}
                            <InitialDiv className="text-left text-[#7a7a7aba] flex items-baseline">
                                <FontAwesomeIcon
                                    icon={faCircleInfo}
                                    className="mt-[8px] mr-1 text-[13px]"
                                />
                                <TextHead5 className="font-medium">
                                    {`Estimates are subject to change. Actual charges are calculated the day of ${(orderType === "delivery") ? "delivery" : "pickup"}.`}
                                </TextHead5>
                            </InitialDiv>
                            {/* <FlexBetween>
                                <Flex className="items-center mt-5">
                                    <input type="checkbox" style={{ height: '16px', width: '22px' }} className="w-8 accent-green-600 cursor-pointer" checked={confirmPrior} onChange={() => setConfirmPrior(!confirmPrior)} />
                                    <TextHead5 className="!ml-2">Confirm 48 hours prior if price increases
                                    </TextHead5>
                                </Flex>
                            </FlexBetween> */}
                            <InitialDiv className="text-left flex items-center mt-2">
                                {/* <Flex className="items-center mt-5"> */}
                                <input
                                    type="checkbox"
                                    style={{ height: "14px", width: "18px" }}
                                    className="w-8 accent-green-600 cursor-pointer mr-[-2px]"
                                    checked={confirmPrior}
                                    onChange={() => setConfirmPrior(!confirmPrior)}
                                />
                                <TextHead5 className="!ml-1">
                                    Confirm 48 hours prior if price increases
                                </TextHead5>
                                {/* </Flex> */}
                            </InitialDiv>
                        </Section>
                        <InitialDiv className="mx-4">
                            {(scheduleSlug !== "" && invitationToken !== "") ?
                                <>
                                    <ButtonGreen
                                        onClick={() => history.push("/login")}
                                    >
                                        {switchSentenceCase("primary button", "PLACE ORDER AFTER LOGIN")}
                                    </ButtonGreen>
                                    <ButtonGreen
                                        style={{ marginBottom: "20px", marginTop: "5px" }}
                                        onClick={() => placeOrder()}
                                    >
                                        {switchSentenceCase("primary button", "PLACE ORDER WITHOUT LOGIN")}
                                    </ButtonGreen>
                                </>
                                :
                                <ButtonGreen
                                    style={{ marginTop: "5px", marginBottom: "20px" }}
                                    onClick={() => placeOrder()}
                                >
                                    {switchSentenceCase("primary button", "PLACE ORDER")}
                                </ButtonGreen>
                            }
                        </InitialDiv>

                    </ContentDiv>

                    <PriceChnageConfirmModal
                        showModal={showModalprice}
                        setShowModal={setShowModalprice}
                    />

                    {/* ---------------------------------Modal Schedule Slot Filled------------------- */}
                    {showModalSlotFill &&
                        <ModalSingleFavItemDetail
                            showMealModal={showModalSlotFill}
                            setShowMealModal={setShowModalSlotFill}
                        >
                        </ModalSingleFavItemDetail>
                    }
                </ModalDiv>
            ) : null}
        </>
    );
}
