import Divider from "../../components/widgets/divider/Divider";
import TopHead from "../../components/widgets/head/TopHead";
import { faAngleLeft, faShare } from "@fortawesome/pro-solid-svg-icons";
import Section from "../../components/widgets/section/Section";
import { ButtonGreen, ButtonInitial, InputText2, SubText1, TextHead1 } from "../../components/styled/styled";
import { switchSentenceCase } from "../../utils/utilities";
import { useHistory } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SeyMourimg from '../../components/assets/SeymourImg.png';
import FriendQr from '../../components/assets/friendQR.png';



export default function ReferToFriends() {
    const history = useHistory();

    return (
        <div className="screen">

            <TopHead
                title="Invite Friends"
                leftTextIcon={faAngleLeft}
                leftIconStyle={{ fontSize: "24px" }}
            />

            <Divider />

            <div className="grow overflow-auto">
                <Section align="center">
                    <img className="ml-auto mr-auto w-60" src={SeyMourimg} alt="credit-card-center" />
                    <TextHead1 className="text-center mt-5">
                        Join <span className="text-green-600">Ryan</span> on Seymour!
                    </TextHead1>
                    <SubText1 className="mt-3">You’ve been invited to setup your profile which is fast easy and fun.</SubText1>
                    <Section>
                        <FontAwesomeIcon icon={faShare} style={{
                            position: "relative",
                            top: "38px",
                            left: "50px",
                        }} />
                        <InputText2 style={{ color: "black", textAlign: "center" }} value="https://ui8.net/76738b" ></InputText2>
                    </Section>
                    <Section>
                        <img className="ml-auto mr-auto" src={FriendQr} alt="credit-card-center" />
                    </Section>
                </Section>
                <Section>
                    <ButtonGreen >
                        {switchSentenceCase("primary button", "EMAIL")}
                    </ButtonGreen>
                    <ButtonInitial onClick={() => history.push({ pathname: "/scancard" })}
                        style={{ border: "1px solid #c4c4c4", marginTop: "15px" }}>
                        {switchSentenceCase("primary button", "OTHERS")}
                    </ButtonInitial>
                </Section>
            </div >
        </div >
    );
}