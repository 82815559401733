import { apiRequestService } from './apiRequestService';
import { getUserData } from './user';
const API_URL = process.env.REACT_APP_API_URL

export async function setLocalDeliveryAddress(data: any) {
    localStorage.setItem("MealFaveUserDeliveryAddress", JSON.stringify(data));
}

export function getDeliveryAddress() {
    return JSON.parse(localStorage.getItem("MealFaveUserDeliveryAddress"));
}

// Add user location
export const addUserLocation = async (requestData: any) => {
    try {
        const user = await getUserData()

        let config = {
            method: 'POST',
            url: API_URL + `/v1/bulk/user-addresses`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + user?.jwt
            },
            data: requestData
        };
        const request = apiRequestService(config);
        return request;
    } catch (error) {
        console.log(error);
    }
}

export const getUserAddress = async (default_address: any, id: any) => {
    //sort[0]=default_address:desc&
    //${default_address ? ("sort[0]=default_address:desc : "&sort[0]=id:asc"}
    try {
        const user = await getUserData()

        let config = {
            method: 'get',
            url: API_URL + `/v1/user-addresses?filters[user][id][$eq]=${id}${default_address ? "&sort[0]=default_address:desc" : "&sort[0]=id:asc"}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + user?.jwt
            },
        };
        const request = apiRequestService(config);
        return request;
    } catch (error) {
        console.log(error);
    }
}

//Delete Address
export async function deleteUserAddress(id: any) {
    try {
        const user = await getUserData()
        let config = {
            method: 'delete',
            url: API_URL + `/v1/user-addresses/${id}`,
            headers: {
                'Authorization': 'Bearer ' + user?.jwt
            },
        };
        const request = apiRequestService(config);
        return request;
    }
    catch (error) {
        console.log(error);
    }
}

// Change address to default
export const changeDefaultAddress = async (id: any, requestData: any) => {
    try {
        const user = await getUserData()

        let config = {
            method: 'put',
            url: API_URL + `/v1/update/${id}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + user?.jwt
            },
            data: requestData
        };
        const request = apiRequestService(config);
        return request;
    } catch (error) {
        console.log(error);
    }
}

// Update Address
export const updateAddress = async (id: any, requestData: any) => {
    try {
        const user = await getUserData()

        let config = {
            method: 'put',
            url: API_URL + `/v1/user-addresses/${id}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + user?.jwt
            },
            data: requestData
        };
        const request = apiRequestService(config);
        return request;
    } catch (error) {
        console.log(error);
    }
}

// Update Address
export const getLatLong = async (address: string) => {
    try {
        const response = await fetch(process.env.REACT_APP_GOOGLE_API_URL + `?address=${address}&key=${process.env.REACT_APP_GOOGLE_API_KEY}`);
        const data = await response.json();

        if (data.status === 'OK') {
            let streetNum = "";
            let streetName = "";
            let streetAddress = "";

            for (let i = 0; i < data?.results[0]?.address_components.length; i++) {
                for (let j = 0; j < data?.results[0]?.address_components[i].types.length; j++) {
                    switch (data?.results[0]?.address_components[i].types[j]) {
                        case "street_number":
                            streetNum = data?.results[0]?.address_components[i]?.long_name;
                            break;
                        case "route":
                            streetName = data?.results[0]?.address_components[i]?.long_name;
                            break;
                        case "street_address":
                            streetAddress = data?.results[0]?.address_components[i]?.long_name;
                            break;
                    }
                }
            }
            let lat = data?.results[0]?.geometry?.location.lat;
            let lng = data?.results[0]?.geometry?.location.lng;
            const placeObject = { streetAddress, streetNum, streetName, lat, lng }
            return placeObject;
        } else {
            console.error("Error geocoding address:", data.status);
        }
    } catch (error) {

    }
};