import { useState } from "react";
import TopHead from "../../components/widgets/head/TopHead";
import Divider from "../../components/widgets/divider/Divider";
import Section from "../../components/widgets/section/Section";
import {
  TextHead1,
  TextHead3,
  ButtonGreen,
  InputLabel,
  InputText,
  ButtonInitial,
  Astk
} from "../../components/styled/styled";
import ToggleWithSubText from "../../components/widgets/buttons/ToggleWithSubText";
import { faMessageQuestion } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ModalBottomToTop from "../../components/widgets/modals/ModalBottomToTop";
import ModalAccessControl from "../../components/widgets/modals/ModalAccessControl";
import SearchInput from "../../components/widgets/searchinput/SearchInput";
import ContactList from "../../components/widgets/contactlist/ContactList";
import { useHistory } from "react-router";
import { switchSentenceCase } from "../../utils/utilities";
import { faAngleLeft, faMagnifyingGlass } from "@fortawesome/pro-solid-svg-icons";


export default function CreateTrainV2() {
  const history = useHistory();

  const [switchState, setSwitchState] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showContactModal, setShowContactModal] = useState(false);
  const [showSelectContactModal, setShowSelectContactModal] = useState(false);
  const [allowAccessModal, setAllowAccessModal] = useState(false);


  const GotoHandle = () => {
    history.push({
      pathname: '/schedules',
    })
  }


  function handleOnChangeToggle() {
    setSwitchState(switchState === false);
  }


  const openModal = () => {
    setShowModal(prev => !prev)
  }

  const openAllowAccessModal = () => {
    setAllowAccessModal(prev => !prev)
  }


  const openContactModal = () => {
    setShowModal(prev => !prev)
    setAllowAccessModal(prev => !prev)
    setShowContactModal(prev => !prev)
  }

  const openSelectContactModal = () => {
    setShowSelectContactModal(prev => !prev)
    setShowContactModal(prev => !prev)
  }

  return (
    <div className="screen">
      <TopHead
        title="New Meal Calendar"
        leftTextIcon={faAngleLeft}
        leftIconStyle={{ fontSize: "24px" }}

        goToHandle={GotoHandle}
      />
      <Divider />
      <div className="grow overflow-auto">
        <Section>
          <TextHead1>Add Recipients</TextHead1>
          <ButtonInitial style={{ border: "1px solid #e1e1e1", color: "#010F07", margin: "10px 0px" }}>{switchSentenceCase("primary button", "ADD MYSELF")}</ButtonInitial>
        </Section>

        <Section>
          <TextHead1 style={{ marginBottom: "10px" }}>Recipient Details</TextHead1>
          <ButtonGreen onClick={openModal} >{switchSentenceCase("primary button", "ADD FROM CONTACTS")}</ButtonGreen>


          {/*---------- Modal Bottom to top - Allow Access----------- */}
          <ModalBottomToTop showModal={showModal} setShowModal={setShowModal} >
            <TextHead3>Add Recipient Contact</TextHead3>
            <Divider />
            <div style={{ marginTop: "30vh" }}>
              <InputLabel style={{ color: "black", textAlign: "center", marginBottom: "10px" }}>Allow access to your contacts to add the recipient’s  information.</InputLabel>
              <ButtonGreen onClick={openAllowAccessModal}>{switchSentenceCase("primary button", "ALLOW ACCESS")}</ButtonGreen>
            </div>
          </ModalBottomToTop>

          {/*----------Popup Modal - Allow Access----------- */}
          <ModalAccessControl firstText="“Seymour” Would Like To Access Your Conacts" secondText="Allow access to automatically add a contact." btnOneText="Don’t Allow" btnTwoText="Ok" showModal={allowAccessModal} setShowModal={setAllowAccessModal} handleBtn={openContactModal} />

          {/*---------- Modal Bottom to top - Contact Lists----------- */}
          <ModalBottomToTop showModal={showContactModal} setShowModal={setShowContactModal} >
            <TextHead3>Add Recipient Contact</TextHead3>
            <Divider />

            <Section>
              <TextHead3 style={{ textAlign: "left" }}>Search</TextHead3>
              <SearchInput icon={faMagnifyingGlass} placeholder="Search on foodly" />
              <ContactList />
            </Section>
            <ButtonGreen onClick={openSelectContactModal}>{switchSentenceCase("primary button", "SAVE")}</ButtonGreen>
          </ModalBottomToTop>


          {/*---------- Modal Bottom to top - Select Contact Lists----------- */}
          <ModalBottomToTop showModal={showSelectContactModal} setShowModal={setShowSelectContactModal} >
            <TextHead1 style={{ textAlign: "left" }}>Andrew Scholar</TextHead1>
            <InputLabel style={{ color: "black", textAlign: "left", marginBottom: "10px" }}>Select the number you want to add</InputLabel>
            <Divider />

            <Section>
              <ContactList />
            </Section>
            <ButtonGreen
              onClick={() => {
                history.push({
                  pathname: '/newmealaddparticipants',
                })
              }}
            >{switchSentenceCase("primary button", "SAVE")}</ButtonGreen>
            <ButtonInitial onClick={() => {
              history.push({
                pathname: '/newMealPayments',
              })
            }} style={{ border: "1px solid #e2e2e2", marginTop: "15px" }}>{switchSentenceCase("primary button", "GO BACK")}</ButtonInitial>
          </ModalBottomToTop>



          <TextHead3 style={{ margin: "20px 0px" }}>Recipient Name</TextHead3>
          <InputLabel>First Name <Astk> *</Astk></InputLabel>
          <InputText style={{ marginBottom: "20px" }} placeholder="Enter text"></InputText>

          <InputLabel>Last Name <Astk> *</Astk></InputLabel>
          <InputText style={{ marginBottom: "20px" }} placeholder="Enter text"></InputText>

          <div className="flex justify-between">
            <InputLabel>Phone Number * <span style={{ color: "#22A45D" }}>Why?</span></InputLabel>
            <FontAwesomeIcon icon={faMessageQuestion} />
          </div>
          <InputText style={{ marginBottom: "20px" }} placeholder="Enter text"></InputText>

          <ToggleWithSubText
            style={{ marginLeft: "0px" }}
            text="Recipient Can Schedule Arrivals"
            subText="Let the recipient choose the best time to receive their meals."
            switchState={switchState}
            handleOnChangeToggle={handleOnChangeToggle}
          />

          <Divider />

          <ToggleWithSubText
            style={{ marginLeft: "0px" }}
            text="Request Address"
            subText="Don’t have an address? We’ll send an email requesting their address."
            switchState={switchState}
            handleOnChangeToggle={handleOnChangeToggle}
          />

          {
            switchState === true
              ?
              <>
                <TextHead3 style={{ margin: "20px 0px" }}>Recipient Address</TextHead3>


                <InputLabel>Company</InputLabel>
                <InputText style={{ marginBottom: "20px" }} placeholder="Enter text"></InputText>

                <InputLabel>Address<Astk> *</Astk></InputLabel>
                <InputText style={{ marginBottom: "20px" }} placeholder="Enter text"></InputText>

                <InputLabel>Appartment, suit, etc.</InputLabel>
                <InputText style={{ marginBottom: "20px" }} placeholder="Enter text"></InputText>

                <InputLabel>City<Astk> *</Astk></InputLabel>
                <InputText style={{ marginBottom: "20px" }} placeholder="Enter text"></InputText>

                <InputLabel>Country / Region<Astk> *</Astk></InputLabel>
                <InputText style={{ marginBottom: "20px" }} placeholder="Enter text"></InputText>
              </>
              :
              null
          }
        </Section>

        <Section>

          <ButtonInitial onClick={() => {
            return history.push({
              pathname: '/newMealPayments',
            })
          }} style={{ border: "1px solid #e2e2e2", marginBottom: "20px" }}>{switchSentenceCase("primary button", "PREVIEW EMAIL")}</ButtonInitial>

          <ButtonGreen>{switchSentenceCase("primary button", "SAVE & CONTINUE")}</ButtonGreen>

        </Section>

      </div>
    </div >
  );
}
