import styled from 'styled-components';
import useDarkMode from '../../hooks/darkmode/DarkMode';

const Tab = styled.div<{ isDarkMode: boolean }>`
 overflow: hidden;
  border: none;
  border-radius: 30px;
  background-color:${props => (props?.isDarkMode ? '#2e2e2e' : '#f1f1f1')};
   border: 1px solid white;
  display: flex;
  justify-content: space-between;
  margin: 20px 0px;
`;

const Tablinks = styled.button<{ active?: boolean; isDarkMode: boolean; }>`
  background-color: inherit;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 10px 0px;
  transition: 0.3s;
  font-size: 15px;
  width: 100px;
 color:${props => (props?.isDarkMode ? 'white' : 'black')};
  &:hover {
    background-color: #ddd;
  }

  ${({ active }) =>
        active &&
        ` background: #22A45D;
   color: white;
   padding: 10px 0px;
   border-radius:30px;
   width: 140px;
   &:hover {
    background-color: #22A45D;
  }
  `}
`;

// const types = ['None', '$15', '$20', '$50', 'Other'];

export default function TabsForSpendingLimit(props: any) {
    const isDarkMode = useDarkMode();
    const changeTipValue = (type: any) => {
        props?.setActive(type);
        props?.setPerMealValue(type);
    }

    return (
        <>
            <Tab isDarkMode={isDarkMode}>
                {props?.types.map((type: any) => (
                    <Tablinks
                        isDarkMode={isDarkMode}
                        key={type?.name}
                        active={props?.selected === type?.value}
                        onClick={() => changeTipValue(type?.value)}
                    >
                        {type?.name}
                    </Tablinks>
                ))}
            </Tab>
            <p />
            {/* <p> Your payment selection: {active} </p> */}
        </>
    );
};


