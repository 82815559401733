import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart } from "@fortawesome/pro-solid-svg-icons";
import styled from 'styled-components';
import ModalRemoved from "../../widgets/modals/ModalRemoved";
import ModalMealClicked from "../../widgets/modals/ModalMealClicked";
import sampleMealImage from "../../assets/Meal.jpg"
import { countryCurrency, filterByHighestPriceAndSum, isRestaurantOpen, properNameFormate, sumOfMealInCart, switchSentenceCase } from "../../../utils/utilities";
import { preventAndStop } from "../preventandstop/preventAndStop";
import { Flex, SubText1, ClosedStripDiv2 } from "../../styled/styled";
import { deleteSingleFavouriteData } from "../../../pages/faves/redux/favouriteActionCreator";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { useIonRouter } from "@ionic/react";
import ModalSuccess from "../modals/ModalSuccess";
import { updateMealCategoryFavItem } from "../../../services/favourite";
import { getUserInfo } from "../../../services/user";

const ButtonRemove = styled.button`
    width:150px;
    color:#979797;
    border: 1px solid #9797976b;
    background: #9797971f;
    border-radius: 25px;
    font-size: 14px;
    font-weight: 500;
    padding:2px;
    padding-bottom: 5px;
`;

export const ButtonAdd = styled.button`
width:140px;
color: white;
border: 1px solid #9797976b;
background: #22A45D;
border-radius: 25px;
font-size: 14px;
font-weight: 500;
padding: 2px;
padding-bottom: 5px;
`;

const HeartModalIcons = styled.div`
    width:30px;
    height:30px;
    background: #f03f3f29;
    border-radius: 50%;
    text-align: center;
    font-size: 14px ;
    color: #ff000082;
    display:flex;
    justify-content:center;
    align-items: center;
    margin-bottom: "5px";
`;

const FlexBetween = styled.div`
    font-weight: 600;
    display: flex;
    width:100%;
    justify-content: space-between;
`;


const H3 = styled.div`
overflow: hidden;
text-overflow: ellipsis;
display: -webkit-box;
-webkit-line-clamp: 2;
-webkit-box-orient: vertical;
width: 150px;
`;

export default function MealCard({
  meal,
  favMealId,
  onClick,
  type
}) {

  const router = useIonRouter();
  const currentDateTime = new Date();
  const dispatch = useDispatch();
  const history = useHistory();
  const userData = getUserInfo();

  const showNav = (router.routeInfo.pathname === '/scheduleslotfill' || router.routeInfo.pathname === '/getfoodnow') ? true : false;
  const { userDeliveryAddress } = useSelector((state: any) => state.userAddresses);

  // *********************** Hooks *****************************
  const [showModal, setShowModal] = useState(false);
  const [showMealModal, setShowMealModal] = useState(false);
  const [showModalSuccess, setShowModalSuccess] = useState(false);


  const openModal = () => {
    setShowModal(prev => !prev)
  }
  const openMealModals = () => {
    setShowMealModal(prev => !prev)
  }

  const openMealModal = () => {
    setShowMealModal(prev => !prev)
  }


  if (meal?.attributes?.favItems?.data.length > 1) {
    const products = meal?.attributes?.favItems?.data;
    filterByHighestPriceAndSum(products)
    meal.attributes.favItems.data = products;
  }
  // else {
  //   // total = meal?.attributes?.favItems?.data[0].attributes?.product?.data?.attributes?.price;
  // }
  let productImage;
  if (meal?.attributes?.favItems?.data.length) {
    if (meal?.attributes?.favItems?.data[0].attributes?.product?.data?.attributes?.thumbnail_image) {
      productImage = meal?.attributes?.favItems?.data[0].attributes?.product?.data?.attributes?.thumbnail_image;
    } else if (meal?.attributes?.favItems?.data[0].attributes?.product?.data?.attributes?.image) {
      productImage = meal?.attributes?.favItems?.data[0].attributes?.product?.data?.attributes?.image;
    } else {
      productImage = meal?.attributes?.favItems?.data[0].attributes?.product?.data?.attributes?.restaurant?.data?.attributes?.logo_photos;
    }
  }

  const deleteMeal = (ids: any) => {
    setShowMealModal(false);
    setShowModal(false);
    setShowModalSuccess(true);
  }

  const dispatchEvent = (id: Number) => {
    setShowModalSuccess(false);
    const updatedData = {
      data: {
        type: null,
      }
    }
    updateMealCategoryFavItem(updatedData, id)
      .then(async (res) => {
        dispatch(deleteSingleFavouriteData(id, "Fav") as any)
        // const order_response = await getUserFavoriteMeal({
        //   id: userData?.id,
        //   type: "Order",
        //   skip: 0,
        //   limit: 10,
        //   lat: userDeliveryAddress?.lat,
        //   lang: userDeliveryAddress?.lang,
        // });
        // setRecentOrderList(order_response?.data);
        //   trackPixelEvent('Get user fave meal');
      })
    // dispatch(deleteSingleFavouriteData(ids) as any)
    // history.push({
    //   pathname: '/faves',
    // })
  }

  // ************************* Checking Restaurant Open/Closed Condition ************************************
  const isClosedStrip = ((meal?.attributes?.restaurant?.data !== null && userDeliveryAddress?.timezone !== null && userDeliveryAddress?.timezone !== undefined) && (!isRestaurantOpen(type === "delivery" ? meal?.attributes?.restaurant?.data?.attributes?.jsonData?.local_hours?.delivery : meal?.attributes?.restaurant?.data?.attributes?.jsonData?.local_hours?.pickup, currentDateTime, userDeliveryAddress?.timezone, false)) ? true : false);
  const isInActive = (meal?.attributes?.status === "INACTIVE") ? true : false
  return (
    <div
      className={"flex meal-card cursor-pointer"}>
      <div style={{ position: "relative" }}>
        <img alt="" src={productImage ? productImage : sampleMealImage} className={isInActive ? "opacity-30 mr-6 rounded-lg w-32 h-28 object-cover" : "mr-6 rounded-lg w-32 h-28 object-cover"} onClick={openMealModals} />
        {((showNav && (isClosedStrip || isInActive)) || isInActive)
          &&
          <ClosedStripDiv2>{isInActive ? "Inactive" : "Closed"}</ClosedStripDiv2>
        }
      </div>
      <div className="flex-content !justify-evenly" >
        <div onClick={openMealModals}>
          <FlexBetween>
            <H3 >{meal?.attributes?.name ? switchSentenceCase("head text", meal?.attributes?.name) : switchSentenceCase("head text", properNameFormate(meal?.attributes?.name))}</H3>
          </FlexBetween>
          <div className="subtext">{meal?.attributes?.description}</div>
          <FlexBetween>
            <Flex>
              <SubText1>{countryCurrency("dollor")}{sumOfMealInCart(meal?.attributes?.favItems?.data)}</SubText1>
            </Flex>
            <HeartModalIcons>
              <FontAwesomeIcon icon={faHeart} />
            </HeartModalIcons>
          </FlexBetween>
        </div>

        {showNav
          ?
          <ButtonAdd
            // className={isClosedStrip ? "!cursor-not-allowed !border !border-solid !border-[#5cb383] !bg-[#5cb383]" : ""}
            onClick={openMealModal}
          // disabled={isClosedStrip ? true : false}
          >
            {switchSentenceCase("primary button", "Order")}
          </ButtonAdd>
          :
          <ButtonRemove onClick={async (e: any) => {
            preventAndStop(e);
            openModal()
          }}
          >
            {switchSentenceCase("primary button", "REMOVE FAVE")}
          </ButtonRemove>
        }

      </div>
      <ModalRemoved
        firstText="Are you sure you want to"
        secondText="remove from faves?"
        btnOneText="OOPS! NEVER MIND..."
        btnTwoText="YES, REMOVE IT"
        showModal={showModal}
        id={meal?.id}
        deleteItem={deleteMeal}
        setShowModal={setShowModal} />

      {
        showMealModal
        &&
        <ModalMealClicked
          showMealModal={showMealModal}
          setShowMealModal={setShowMealModal}
          mealProps={meal}
          deleteMeal={deleteMeal}
          favMealId={favMealId} />
      }

      <ModalSuccess
        contentDivStyle={{ height: "auto" }}
        firstText="Successfully Deleted"
        secondText="Fave meal is deleted"
        btnOneText="OK"
        btnOneGoto={() => {
          dispatchEvent(meal?.id || 0);
        }}
        showModal={showModalSuccess}
        setShowModal={setShowModalSuccess}
      />
    </div >
  );

}
