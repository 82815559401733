
import { useRef, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { TextHead3, FlexBetween, InitialDiv, moveTopBottom, InputLabel, ButtonGreen } from "../../styled/styled";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { sleep, trackPixelEvent } from "../../../utils/utilities";
import { switchSentenceCase } from "../../../utils/utilities";
import { getUserData, loginRegisterUser, setUserData, verifyUser } from "../../../services/user";
import Spinner from "../spinner/Spinner";
import { invitationStateClear } from "../../../pages/scheduleInvitations/redux/invitationActionCreator";
import store from "../../../redux/store";
import useDarkMode from "../../hooks/darkmode/DarkMode";
import OtpInput from 'react18-input-otp';
import unlockPassword from "../../assets/onboarding/unlock-password.svg";


const ModalDiv = styled.div`
position: fixed;
display: flex;
justify-content: center;
top: 0;
left: 0;
width: 100%;
height: 100%;
background-color: rgba(0, 0, 0, 0.8);
z-index: 1000;
overflow-y: auto;
`;

const ContentDiv = styled.div<{ isDarkMode: boolean }>`
    background-color: ${props => (props?.isDarkMode ? '#2e2e2e' : '#fff')};
    margin-bottom: 85px;
    margin-top: 80px;
    padding: 10px;
    z-index: 1000;
    width: 600px;
    max-width: 90%;
    border-radius: 20px;
    text-align: center;
    height: fit-content;
    animation: ${moveTopBottom} .4s ease-in-out;
`;

export default function ModalVerifyOtp(props: any) {

    const history = useHistory();
    const modalRef = useRef();
    const dispatch = useDispatch();
    const isDarkMode = useDarkMode();


    const { showVerify, contact, provider } = useSelector((state: any) => state.sessionAlertState);
    const [otp, setOtp] = useState("");
    const [otpError, setOtpError] = useState("");
    const [awaiting, setAwaiting] = useState(false);
    const [resendWait, setResendWait] = useState(false);
    const [localData, setLocalData] = useState<any>({});
    const [seconds, setSeconds] = useState(0);
    const time = 30;

    const { scheduleSlug, invitationToken, userSlug } = useSelector((state: any) => state?.scheduleInvitationState);

    //***************** CHECK USER SESSION  *****************//
    async function checkUserLoggedIn() {
        const userData = await getUserData();
        setLocalData(userData);
        startCountdown();
    }

    useEffect(() => {
        checkUserLoggedIn();
    }, []);


    //***************** HANDLE SUBMIT  *****************//
    const verify = async (e: any) => {

        //* --------------------------Validation-----------------------
        if (otp === "") {
            setOtpError("Please Enter OTP First !");
        } else if (otp?.length < 6 || otp?.length > 6) {
            setOtpError("OTP Should Contain 6 Digits !");
        } else if (otp?.length === 6) {
            setOtpError("")

            //* --------------------------Api Call--------------------------
            try {
                setAwaiting(true);
                await verifyUser(otp, provider)
                    .then(async (response: any) => {
                        trackPixelEvent('Login register user');
                        let data = response?.data;
                        if (data.user.confirmed === true) {
                            await setUserData(data);
                            if (scheduleSlug !== "" && userSlug !== "") {
                                history.push({
                                    pathname: `/view-calendar/${encodeURIComponent(scheduleSlug)}/${encodeURIComponent(userSlug)}`
                                })
                                store.dispatch({
                                    type: "SET_VERIFY_MODAL",
                                    payload: { show: false, contact: "", provider: "" }
                                })
                                dispatch(invitationStateClear() as any);
                                setAwaiting(false);
                                window.location.reload();
                            }
                            else if (scheduleSlug !== "" && invitationToken !== "") {
                                history.push({
                                    pathname: `/schedule/${encodeURIComponent(scheduleSlug)}/${encodeURIComponent(invitationToken)}`
                                })
                                dispatch(invitationStateClear() as any);
                                setAwaiting(false);
                                store.dispatch({
                                    type: "SET_VERIFY_MODAL",
                                    payload: { show: false, contact: "", provider: "" }
                                })
                            }
                            else {
                                window.location.reload();
                                setAwaiting(false);
                                store.dispatch({
                                    type: "SET_VERIFY_MODAL",
                                    payload: { show: false, contact: "", provider: "" }
                                })
                            }
                        }
                    })
            } catch (error) {
                console.log(error);
                setAwaiting(false);
                const err = error?.response?.data?.error?.message;
                if (String(err).toLowerCase().includes("invalid")) {
                    setOtpError("Code is invalid. Try again or →");
                }
            }
        }
    }

    //* Timer for user to wait between resending otp.
    const startCountdown = async () => {
        setResendWait(true);
        for (let i = time; i >= 0; i--) {
            await sleep(1000)
            setSeconds(i);

            if (i === 0) {
                setResendWait(false);
                break
            }
        }
    }

    //***************** HANDLE RESEND OTP  *****************//
    const resendOtp = async (e: any) => {
        try {
            if (awaiting || resendWait) { return false; }
            startCountdown();
            setOtp("");
            setOtpError("");

            //* Api Call--------------------------
            setAwaiting(true);
            await loginRegisterUser(contact, provider)
                .then(async (response: any) => {
                    let data = response?.data;
                    trackPixelEvent('Login register user');
                    if (data.status === "pending") {
                        setAwaiting(false);
                    }
                })

        } catch (error) {
            console.log(error);
            setAwaiting(false);
        }
    }

    /* const handlePaste: React.ClipboardEventHandler = (event) => {
        const data = event.clipboardData.getData('text');
        setOtp(data)
    }; */

    //***************** HANDLE KEY DOWN FUNCTION *****************//
    const handleKeyDown = (e: any) => {
        if (e.key === 'Enter') {
            verify(e);
        }
    };
    const closeModal = (e: any) => {
        if (modalRef.current === e.target) {
            props?.setShowModal(false);
        }
    };

    return (
        <>
            {(showVerify) &&
                <ModalDiv>
                    <ContentDiv isDarkMode={isDarkMode}>
                        <div className="grow overflow-auto my-4">
                            <InitialDiv className="mx-2">
                                <TextHead3>Verification</TextHead3>
                            </InitialDiv>


                            <InitialDiv className="mx-2">
                                <InitialDiv className="w-full flex justify-center">
                                    <img src={unlockPassword} alt="img" width={250} />
                                </InitialDiv>

                                <InitialDiv>
                                    <FlexBetween>
                                        <InputLabel className="mb-[10px]">{`Enter code sent to ${contact ? contact + "." : "[email or phone number]"}`}
                                        </InputLabel>

                                    </FlexBetween>
                                    <div>
                                        <form onKeyDown={handleKeyDown}>
                                            <OtpInput
                                                value={otp}
                                                shouldAutoFocus={true}
                                                onChange={setOtp}
                                                numInputs={6}
                                                isInputNum={true}
                                                separator={<span>-</span>}
                                                containerStyle="justify-center"
                                                inputStyle="bg-transparent !w-10 h-10 border m-[0.2rem]"

                                            />
                                        </form>
                                    </div>

                                    <FlexBetween>
                                        {otpError ? <p className="mt-2 text-rose-800">{otpError}</p> : <p></p>}
                                        <p className="mt-2 text-red-600 cursor-pointer">
                                            <span onClick={resendOtp} style={{ color: ((seconds > 0) ? "grey" : "") }}>Resend Code {(seconds > 0) ? `(in ${seconds}s)` : ""}</span>
                                        </p>
                                    </FlexBetween>


                                    {(awaiting === false)
                                        ?
                                        <ButtonGreen
                                            className="mt-[15px] mb-2"
                                            onClick={verify}
                                        >
                                            {switchSentenceCase("primary button", "CONTINUE")}
                                        </ButtonGreen>
                                        :
                                        <div className="text-center">
                                            <Spinner />
                                        </div>
                                    }
                                </InitialDiv>
                            </InitialDiv>
                        </div>
                    </ContentDiv>
                </ModalDiv >
            }
        </>
    );
}
