import { faAngleLeft } from "@fortawesome/pro-solid-svg-icons";
import { useIonRouter, useIonViewDidEnter } from "@ionic/react";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import Divider from "../../components/widgets/divider/Divider";
import TopHead from "../../components/widgets/head/TopHead";
import ModalSearchFilter from "../../components/widgets/modals/ModalSearchFilter";
import ModalSpinner from "../../components/widgets/modalspinner/ModalSpinner";
import SearchRestaurantsByKeywords from "../../components/widgets/searchinput/SearchRestaurantsByKeywords";
import Section from "../../components/widgets/section/Section";
import { getDeliveryAddress } from "../../services/location";
import { restaurantsNearPlace } from "../../services/restaurant";
import { trackStandardPixelEvent } from "../../utils/utilities";
import SearchResults from "./SearchResults";
import { getRestaurantsList, setRestaurantsListLoading } from "./redux/searchActionCreator";


export default function Search(data: any) {
  const history = useHistory();
  const router = useIonRouter();
  const pageRoute = router.routeInfo?.search;
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const type = searchParams.get('type');

  const addressData = getDeliveryAddress();
  const dispatch = useDispatch();

  useIonViewDidEnter(() => {
    trackStandardPixelEvent('Search');
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [awaitingCurrentLocation, setAwaitingCurrentLocation] = useState(false);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const { userDeliveryAddress } = useSelector((state: any) => state.userAddresses);
  const { restaurantsList } = useSelector((state: any) => state.getUserRestaurantsLists);



  const searchFunction = useCallback(async () => {
    try {
      const placeObject = {
        lat: addressData?.lat,
        lang: addressData?.lang,
        streetName: addressData?.streetName,
        streetNum: addressData?.streetNum,
        streetAddress: addressData?.streetAddress,
        city: addressData?.city,
        state: addressData?.state,
        country: addressData?.country,
        zip: addressData?.zip,
        orderType: type
      };

      dispatch(setRestaurantsListLoading(true) as any);
      const response = await restaurantsNearPlace(placeObject);
      if (response) {
        trackStandardPixelEvent('Search');
        const restaurantData = response?.data?.data?.stores;
        const nextPage = response?.data?.data?.next_page;

        const filteredData = Array.from(new Set(restaurantData.map((a: any) => a?.name))).map(
          (name: any) => {
            return restaurantData.find((a: any) =>
              String(name).toLowerCase().includes(String(a?.name).toLowerCase())
            );
          }
        );
        dispatch(setRestaurantsListLoading(false) as any);
        dispatch(getRestaurantsList(filteredData, nextPage) as any);
      }
    } catch (error) {
      dispatch(setRestaurantsListLoading(false) as any);
      console.log(error);
    }
  }, [addressData, dispatch, type]);



  useEffect(() => {
    if (userDeliveryAddress?.lat === undefined || restaurantsList?.length === 0) {
      searchFunction();
    }
  }, [userDeliveryAddress?.lat]);



  const GotoHandle = () => {
    history.push("/home")
  }

  const openFilterModal = () => {
    setShowFilterModal(true);
  }


  return (
    <div className="screen">

      <TopHead
        title={(pageRoute === "?for=faves") ? "Find Faves" : "Select Restaurant"}
        leftTextIcon={faAngleLeft}
        leftIconStyle={{ fontSize: "24px" }}
        goto={GotoHandle}
        rightText={"Filter"}
        gotoRightTextHandle={openFilterModal}
      />

      <Divider />

      <Section>
        {/* <div>
          <AddressAutocomplete
            awaitingCurrentLocation={(val: any) => { setAwaitingCurrentLocation(val) }}
            onAddressChosen={onAddressChosen}
            place={currentPlace}
          />
          <ButtonInitial
            className="mt-2"
            style={{ border: "1px solid green" }}
            onClick={handleLocateMe}
          >
            <span className="text-green-600"><FontAwesomeIcon icon={faLocationCrosshairs} style={{ color: "#22A45D", marginRight: "10px" }} />{switchSentenceCase("primary button", "Locate Me")}</span>
          </ButtonInitial>
        </div> */}

        <SearchRestaurantsByKeywords pageRoute={pageRoute} goToSearch={true} type={type} />
      </Section>

      <div className="grow overflow-auto">
        <Section>
          <SearchResults />
        </Section>
      </div>

      {awaitingCurrentLocation ? <ModalSpinner /> : null}
      <ModalSearchFilter
        showModal={showFilterModal}
        setShowModal={setShowFilterModal}
        type={type}
      />
    </div>
  );
}


