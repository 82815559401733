import styled from "styled-components";
import { ButtonInitial } from "../../styled/styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass, faPlateUtensils } from "@fortawesome/pro-solid-svg-icons";
import { switchSentenceCase, trackStandardPixelEvent } from "../../../utils/utilities";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import {
  clearRestaurantList,
  getRestaurantsList,
  setRestaurantsListLoading,
  setSearchFilterRedux,
} from "../../../pages/search/redux/searchActionCreator";
import { restaurantsNearPlace } from "../../../services/restaurant";
import { useHistory } from "react-router";
import IonModalAlert from "../alerts/IonModalAlert";
import useDarkMode from "../../hooks/darkmode/DarkMode";
import { getDeliveryAddress } from "../../../services/location";


const InputAddress = styled.input<{ isDarkMode: boolean }>`
  background: ${props => (props?.isDarkMode ? "#3b3b3bb3" : "#fbfbfb")};
  border: 1px solid rgba(134, 134, 134, 0.103229);
  border-radius: 8px;
  color: ${props => (props?.isDarkMode ? "#ffffff" : "#868686")};
  padding-left: 2rem;
  margin-top: -20px;
  outline-color: rgb(134 134 134 / 14%);
  width: 100%;
`;

export default function SearchRestaurantsByKeywords(props: any) {
  // **************************** Other Methods ***********************************
  let screen: any;
  let user: any;
  let place: any;

  const dispatch = useDispatch();
  const history = useHistory();
  const isDarkMode = useDarkMode();
  const addressData = getDeliveryAddress();

  // **************************** States Managment***************************************
  const { searchFilter } = useSelector((state: any) => state.getUserRestaurantsLists);
  const { userSearchLocation } = useSelector((state: any) => state.getUserSearchLocation);
  const { userDeliveryAddress } = useSelector((state: any) => state.userAddresses);

  // ************************* Hooks ************************************
  const [filterQuery, setFilterQuery] = useState("");
  const [showIonAlertModal, setShowIonAlertModal] = useState(false);
  const [ionAlertHeader, setIonAlertHeader] = useState("");
  const [ionAlertMessage, setIonAlertMessage] = useState("");
  const [disabled, setDisabled] = useState(searchFilter.query === "" || searchFilter.query === undefined ? true : false);

  useEffect(() => {
    setFilterQuery(searchFilter?.query);
    if (searchFilter.query === "" || searchFilter.query === undefined) {
      setDisabled(true);
    }
  }, [searchFilter?.query]);

  // *************************** Functions *************************************
  const handleKeyPress = (e: any) => {
    if (e.key === "Enter") {
      searchRestaurant();
    }
  };

  const searchRestaurant = async () => {
    clearRestaurantList();
    if (filterQuery !== "") {
      const data = {
        ...searchFilter,
        query: filterQuery,
      };
      setSearchFilterRedux(data);
    } else {
      return false;
    }
    searchFunction();
    if (props?.goToSearch) {
      history.push("/search" + props?.pageRoute);
    }
  };

  const searchFunction = async () => {
    try {
      const placeObject = {
        lat: userDeliveryAddress?.lat || addressData?.lat || userSearchLocation?.lat,
        lang: userDeliveryAddress?.lang || addressData?.lang || userSearchLocation?.lang,
        streetName: userDeliveryAddress?.streetName || addressData?.streetName || userSearchLocation?.streetName,
        streetNum: userDeliveryAddress?.streetNum || addressData?.streetNum || userSearchLocation?.streetNum,
        streetAddress: userDeliveryAddress?.streetAddress || addressData?.streetAddress || userSearchLocation?.streetAddress,
        city: userDeliveryAddress?.city || addressData?.city || userSearchLocation?.city,
        state: userDeliveryAddress?.state || addressData?.state || userSearchLocation?.state,
        country: userDeliveryAddress?.country || addressData?.country || userSearchLocation?.country,
        zip: userDeliveryAddress?.zip || addressData?.zip || userSearchLocation?.zip,
        orderType: props?.type
      };

      dispatch(setRestaurantsListLoading(true) as any);
      const response = await restaurantsNearPlace(placeObject);
      if (response) {
        trackStandardPixelEvent('Search');
        const restaurantData = response?.data?.data?.stores;
        const nextPage = response?.data?.data?.next_page;

        // -----------Filtering for Duplicate Data---------------
        const filteredData = Array?.from(new Set(restaurantData?.map((a: any) => a?.name))).map(
          (name: any) => {
            return restaurantData?.find((a: any) =>
              String(name)?.toLowerCase()?.includes(String(a?.name)?.toLowerCase())
            );
          }
        );
        dispatch(setRestaurantsListLoading(false) as any);
        dispatch(getRestaurantsList(filteredData, nextPage) as any);
      }
    } catch (error) {
      dispatch(setRestaurantsListLoading(false) as any);
      console.log(error);
    }
  };

  const handleInputChange = (e: any) => {

    setFilterQuery(e.target.value);
    if (e.target.value.length === 0) {
      clearRestaurantList();
      const data = {
        ...searchFilter,
        query: "",
      };
      setSearchFilterRedux(data);
      searchFunction();
    }
    if (e.target.value === "" || e.target.value === null) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  };

  return (
    <>
      <FontAwesomeIcon
        icon={faPlateUtensils}
        style={{ color: "#868686", position: "relative", top: "13px", left: "6px" }}
      />
      <InputAddress
        type="text"
        placeholder="Search by restaurant, cuisine, or keyword…"
        value={filterQuery}
        onChange={(event: any) => handleInputChange(event)}
        onKeyDown={(e) => handleKeyPress(e)}
        isDarkMode={isDarkMode}
      />

      <ButtonInitial
        className="mt-2 !border !border-solid !border-green-600"
        disabled={disabled}
        onClick={searchRestaurant}
      >
        <span className="text-green-600">
          <FontAwesomeIcon icon={faMagnifyingGlass} className="text-green-600 mr-[10px]" />
          {switchSentenceCase("primary button", "Search")}
        </span>
      </ButtonInitial>

      {/* ********************* Alert Modal for validations ****************************** */}
      <IonModalAlert
        isOpened={showIonAlertModal}
        setIsOpened={setShowIonAlertModal}
        header={ionAlertHeader}
        subHeader="Please, Add Address. OR"
        message={ionAlertMessage}
      />
    </>
  );
}
