const initialValue = {
    show: false,
    showVerify: false,
    contact: "",
    provider: "",
    pageRoute: "",
    loginState: false
};

const sessionAlertState = (state = initialValue, action: any) => {

    switch (action?.type) {

        case "SHOW_ALERT":
            return {
                ...state,
                show: action?.payload?.show
            };

        case "SET_ROUTE":
            return {
                ...state,
                pageRoute: action?.payload
            };

        case "SET_LOGIN_STATE":
            return {
                ...state,
                loginState: action?.payload?.loginState
            };

        case "SET_VERIFY_MODAL":
            return {
                ...state,
                showVerify: action?.payload?.show, contact: action?.payload?.contact, provider: action?.payload?.provider
            };

        default:
            return state;
    }
};

export default sessionAlertState;