import Section from "../../components/widgets/section/Section";
import { Astk, Block, Flex, FlexBetween, SubText1, TextHead1, TextHead4, TextHead5 } from "../../components/styled/styled";
import Divider from "../../components/widgets/divider/Divider";


export default function Notifications() {

    return (
        <div className="screen">
            <Section>
                <TextHead1 >
                    Notifications
                </TextHead1>
            </Section>
            <div className="grow overflow-auto">
                <Section>
                    <FlexBetween className="my-3">
                        <Flex>
                            <Astk className="text-xl">•</Astk>
                            <Block className="ml-2">
                                <TextHead4>Your order has arrived</TextHead4>
                            </Block>
                        </Flex>
                        <TextHead5 className="text-gray-500">2m</TextHead5>
                    </FlexBetween>
                    <Divider />
                    <FlexBetween className="my-3">
                        <Flex>
                            <Block className="ml-2">
                                <TextHead4>Your order is on its way</TextHead4>
                            </Block>
                        </Flex>
                        <TextHead5 className="text-gray-500">50m</TextHead5>
                    </FlexBetween>
                    <Divider />
                    <FlexBetween className="my-3">
                        <Flex>
                            <Block className="ml-2">
                                <TextHead4>Your order has been placed</TextHead4>
                            </Block>
                        </Flex>
                        <TextHead5 className="text-gray-500">1h</TextHead5>
                    </FlexBetween>
                    <Divider />
                    <FlexBetween className="my-3">
                        <Flex>
                            <Block className="ml-2">
                                <TextHead4>Confirm your phone number</TextHead4>
                            </Block>
                        </Flex>
                        <TextHead5 className="text-gray-500">5d </TextHead5>
                    </FlexBetween>
                    <Divider />
                    <FlexBetween className="my-3">
                        <Flex>
                            <Block className="ml-2">
                                <TextHead4>We have updated our Privacy Policy</TextHead4>
                                <SubText1 className="mt1.5">View Privacy Policy</SubText1>
                            </Block>
                        </Flex>
                        <TextHead5 className="text-gray-500">6d</TextHead5>
                    </FlexBetween>
                    <Divider />
                    <FlexBetween className="my-3">
                        <Flex>
                            <Block className="ml-2">
                                <TextHead4>Your order has been canceled</TextHead4>
                            </Block>
                        </Flex>
                        <TextHead5 className="text-gray-500">1w</TextHead5>
                    </FlexBetween>
                    <Divider />
                    <FlexBetween className="my-3">
                        <Flex>
                            <Block className="ml-2">
                                <TextHead4>Your order has been placed</TextHead4>
                            </Block>
                        </Flex>
                        <TextHead5 className="text-gray-500">1w</TextHead5>
                    </FlexBetween>
                    <Divider />
                    <FlexBetween className="my-3">
                        <Flex>
                            <Block className="ml-2">
                                <TextHead4>Welcome to Foodeck</TextHead4>
                                <SubText1 className="mt1.5">Watch our video</SubText1>
                            </Block>
                        </Flex>
                        <TextHead5 className="text-gray-500">1w</TextHead5>
                    </FlexBetween>
                    <Divider />
                </Section>
            </div>
        </div >
    );
}