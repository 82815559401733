import { setRecentOrderList, setUserFaveMealList } from "../pages/faves/redux/favouriteActionCreator";
import { setUserDeliveryAddress } from "../pages/profile/redux/locationActionCreator";
import { clearRestaurantList, setNearByRestaurants, setNearByScheduleRestaurants } from "../pages/search/redux/searchActionCreator";
import { apiRequestService } from "../services/apiRequestService";
import { getUserBySlug, getUserInfo, loginRegisterUser, setUserData } from "../services/user";
import moment from "moment";
import store from '../redux/store';
declare let fbq: Function;

export const maxCharacterLimit = 300;
export const maxUserCharLimit = 35;
export const maxAddressCharLimit = 100;
export const maxCityCharLimit = 25;
export const maxStateCharLimit = 30;
export const maxCountryCharLimit = 25;
export const maxZipCodeCharLimit = 10;
export const maxUserNameCharLimit = 50;
export const maxEmailCharLimit = 50;


export const isAuthenticated = () => {
    const user = getUserInfo();
    if (!user?.id) {
        store.dispatch({
            type: "SHOW_ALERT",
            payload: { show: true }
        })
        return false;
    }
}

export const handleResponse = (response) => {
    if (response.status >= 200 && response.status < 300) {
        return response.data;
    } else {
        throw new Error(`Request failed with status: ${response.status}`);
    }
};

export const handleNetworkError = (error) => {
    console.error('Network error occurred:', error);
    throw new Error('Network error occurred. Please try again later.');
};

export const handleRequestError = (error) => {
    console.error('Error occurred during request:', error);
    throw new Error('Error occurred during the request. Please try again later.');
};


export const properNameFormate = (name: any) => {
    const validatedName = String(name).replace(/[^a-zA-Z\s]/g, ""); //.replace(/\d+/g, "").replace(/^[a-zA-Z0-9!@#\$%\^\&*\)\(+=._-]+$/g, "").replace(".", "")
    return validatedName;
}


export const textFormatter = (name: any) => {
    const validatedText = String(name).replace(/[^a-zA-Z ]/g, "");
    return validatedText;
}


export const displayName = () => {
    const USER_DATA = getUserInfo();
    if (USER_DATA) {

        let { phone_no, name } = USER_DATA;

        phone_no = USER_DATA?.phone_no?.substr(phone_no.length - 10);

        if (name?.includes(phone_no) || name === null)
            return "";
        else
            return name + "'s";
    }
    else {
        return "";
    }
}

export const displayFullName = () => {
    const USER_DATA = getUserInfo();
    if (USER_DATA) {
        let { phone_no, name } = USER_DATA;

        phone_no = USER_DATA?.phone_no?.substr(phone_no.length - 10);

        if (name?.includes(phone_no) || name === null)
            return "";
        else
            return name;
    }
    else {
        return "";
    }
}

export const displayEmail = () => {
    const USER_DATA = getUserInfo();
    if (USER_DATA) {

        let { phone_no, email } = USER_DATA;

        phone_no = USER_DATA?.phone_no?.substr(phone_no.length - 10);

        if (email?.includes(phone_no))
            return "";
        else
            return email;
    }
    else {
        return "";
    }
}

export const displayNumber = () => {

    const USER_DATA = getUserInfo();
    if (USER_DATA) {
        let { phone_no } = USER_DATA;

        if (phone_no?.includes("@"))
            return "";
        else
            return phone_no;
    }
    else {
        return "";
    }
}

export const filterByHighestPriceAndSum = (products: any) => {
    products.sort((a: any, b: any) => {
        // total += a.attributes?.product?.data?.attributes.price;
        return b.attributes?.product?.data?.attributes.price - a.attributes?.product?.data?.attributes.price;
    });
}

export const orderFilterByHighestPriceAndSum = (products: any) => {
    products.sort((a: any, b: any) => {
        // total += a.attributes?.product?.data?.attributes.price;
        return b.product?.price - a.product?.price;
    });
}

export const countryCurrency = (currency: any) => {
    switch (currency) {
        case "dollor": return "$"
        default:
            return "₹"
    }
}

export const increasePriceByQuantity = (price: any, quantity: any) => {
    return (price > 0) ? (price * quantity) : 0;
}

export const sumOfMealInCart = (products: any) => {
    let sum = 0;
    products?.forEach((item: any) => {
        const totalPrice = priceCalculator(item.attributes?.product?.data?.attributes?.price, item?.attributes?.customizations)
        sum += (totalPrice > 0) ? (totalPrice * item?.attributes?.quantity / 100) : 0;
    })
    return (sum).toFixed(2);
}

export const sumOfMealItemInCart = (priceInCartMealItem: any) => {
    try {
        let total = 0
        if (priceInCartMealItem?.length > 0) {
            priceInCartMealItem?.forEach((obj: any) => {
                let sum = 0; //Holds Temp Price For Current Object.
                sum += Number(obj?.price ? obj?.price : 0);
                // (obj?.inputList)?.forEach((childObj: any) => {
                //     if (childObj?.option_price) {
                //         sum += Number(childObj?.option_price);
                //     } else {
                //         sum += 0
                //     }
                // })

                sum = (sum * Number(obj?.quantity));
                total += sum
            })
        }
        return total;
    }
    catch (e) {
        console.log(e);
        return 0;
    }
}

export const priceFormater = (formatePrice: any) => {
    return "$" + String(Math.fround(Number(formatePrice) / 100).toFixed(2))
}

export const priceFormatterUsd = (formatePrice: any) => {
    try {
        return new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
        }).format(formatePrice / 100)
    }
    catch (e) {
        console.log(e)
    }
};

// eslint-disable-next-line
export const switchSentenceCase = (type: any, text: any) => {
    var i: any, j: any, str: any, lowers: any;
    // eslint-disable-next-line
    str = text?.replace(/([^\W_]+[^\s-]*) */g, function (txt: any) {
        return txt?.charAt(0)?.toUpperCase() + txt?.substr(1)?.toLowerCase();
    });

    // Certain minor words should be left lowercase unless 
    // they are the first or last words in the string
    lowers = ['A', 'An', 'The', 'And', 'But', 'Or', 'For', 'Nor', 'As', 'At',
        'By', 'For', 'From', 'In', 'Into', 'Near', 'Of', 'On', 'Onto', 'To', 'With'];


    for (i = 0, j = lowers?.length; i < j; i++)
        // eslint-disable-next-line
        str = str?.replace(new RegExp('\\s' + lowers[i] + '\\s', 'g'),
            function (txt: any) {
                return txt?.toLowerCase();
            });
    switch (type) {
        case "primary button"
            :
            return str;

        case "head text"
            :
            return str;

        default:
            return text;
    }
}

export function goBackPage() {
    window.history.back()
}

// export const itemPlusOptionPrice = (itemPrice: any = 0, optionPrice: any) => {
//     try {
//         let totalItemPrice = 0;
//         totalItemPrice += Number(itemPrice);
//         // for (let index = 0; index < optionPrice?.length; index++) {
//         //     if (optionPrice[index]?.option_price) {
//         //         totalItemPrice += optionPrice[index].option_price;
//         //     } else {
//         //         totalItemPrice += 0;
//         //     }
//         // }        
//         return totalItemPrice;
//     }
//     catch (e) {
//         console.log(e)
//     }
// }

// Prevent dot, minus, plus and e from input type number
export const onKeyDownNonDecimalFilter = (event: any) => {
    if (
        event.keyCode === 190 || // (.)
        event.keyCode === 189 || // (-)
        event.keyCode === 69 ||  // (e)
        event.keyCode === 187    // (+)
    ) {
        event.preventDefault()
    }
}

export const textToNumberFilter = (value: any) => {
    try {
        const val = String(value);

        if (val?.length > 0) {
            const filteredValue = val.trim().replace(/[^._@+0-9a-zA-Z-()]/, '');
            return (filteredValue);
        }
        else if (val?.length === 0) {
            return ("")
        }
    }
    catch (e) {
        console.log(e)
    }
}

export const numberInputFilter = (value: any) => {
    try {
        const val = String(value);

        if (val?.length > 0) {
            const filteredValue = val.trim().replace(/[^+0-9-()]/, '');
            return (filteredValue);
        }
        else if (val?.length === 0) {
            return ("")
        }
    }
    catch (e) {
        console.log(e)
    }
}

export const countryCodeFilter = (phoneNo: any) => {
    try {

        const countryCode = "1";

        let filteredNumber = "";
        if (String(phoneNo).trim().includes("+")) {
            for (var i = 0; i < phoneNo.length; i++) {
                if (phoneNo.charAt(i) !== "+") {
                    filteredNumber += ("" + phoneNo.charAt(i))
                }
            }
            filteredNumber = ("+" + filteredNumber);
        }
        else {
            filteredNumber = ("+" + countryCode + phoneNo);
        }

        return filteredNumber;
    }
    catch (e) {
        console.log(e)
    }
}

// Sleep function for wating.
export const sleep = (ms: any) => {
    return new Promise(val => setTimeout(val, ms));
}

export const validateEmail = (email: any) => {
    return String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
};

export const validatePhone = (phone: any) => {

    return String(phone).toLowerCase().match(
        // eslint-disable-next-line
        /^(\+\d{1,3}[]?)?\d{10}$/
    );
    // /^\+[1-9]{1}[0-9]{3,14}$/
    // /^([0|\+[0-9]{1,5})?([7-9][0-9]{9})$/
};


export const validateNumber = (phone: any) => {
    return String(phone).toLowerCase().match(
        // eslint-disable-next-line
        /^\+[1-9]{1}[0-9]{3,14}$/
    );
};

export const validateTwitter = (twitter: any) => {
    return String(twitter)
        .toLowerCase()
        .match(
            /http(?:s)?:\/\/(?:www\.)?twitter\.com\/([a-zA-Z0-9_]+)/
        );
};
export const validateFacebook = (facebook: any) => {
    return String(facebook)
        .toLowerCase()
        .match(
            // eslint-disable-next-line
            /(?:https?:\/\/)?(?:www\.)?(?:facebook|fb|m\.facebook)\.(?:com|me)\/(?:(?:\w)*#!\/)?(?:pages\/)?(?:[\w\-]*\/)*([\w\-\.]+)(?:\/)?/
        );
};
export const validateLinkedIn = (linkedin: any) => {
    return String(linkedin)
        .toLowerCase()
        .match(
            // eslint-disable-next-line
            /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/
        );
};

export function handleKeyDownNumber(e: any, number: any) {

    // Check if the input consists of only numeric digits
    const isNumeric = /^[0-9-]+$/.test(number);

    if (isNumeric && String(number).trim()?.length >= 3) {

        const keyCode = e.keyCode || e.which; // Get the key code

        if (keyCode === 8) { // Check if the key code corresponds to backspace (8)
            // If the last character is a dash, remove it
            if (number.charAt(number.length - 1) === "-") {
                return (number.slice(0, -1));
            }
        }
        else {
            // Apply the mask "XXX-XXX-XXXX"
            const cleanedPhoneNumber = number.replace(/\D/g, "");
            number =
                cleanedPhoneNumber.substring(0, 3) + "-" +
                cleanedPhoneNumber.substring(3, 6) + "-" +
                cleanedPhoneNumber.substring(6, 10);
            return (number);
        }
    }
};

export function formatPhoneNumber(phoneNumber) {
    const cleanedPhoneNumber = phoneNumber.replace(/\D/g, "");
    // Apply the mask "XXX-XXX-XXXX"
    const formattedPhoneNumber =
        cleanedPhoneNumber.substring(0, 3) + "-" +
        cleanedPhoneNumber.substring(3, 6) + "-" +
        cleanedPhoneNumber.substring(6, 10);

    return formattedPhoneNumber;
}


export const removeMask = (phoneNumber) => {
    if (typeof phoneNumber === 'string') {
        // return phoneNumber.replace(/[^\d+]/g, '');
        return phoneNumber.replace(/[^\w+]/g, '')
    }
    // Handle cases where phoneNumber is not a string (e.g., null or undefined)
    return phoneNumber;
};


export const addDaystoGetDate = (date: any, days: any) => {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
}

export const fullDateToShortConvert = (fullDate: any) => {
    // var date = new Date(fullDate),
    //     mnth = ("0" + (date.getMonth() + 1)).slice(-2),
    //     day = ("0" + date.getDate()).slice(-2);
    // return [date.getFullYear(), mnth, day].join("-");
    return moment(fullDate).toISOString();
}

export const slotDateTimeFormatter = (date: any, time: any) => {
    try {
        return `${moment(date).format("ddd DD MMM YYYY") + " " + moment(time, 'hh:mm:ss').format("hh:mm A")}`
    }
    catch (e) {
        console.log(e)
    }
}

export const mergeSlotDateTime = (date: any, time: any) => {
    try {
        return `${moment(date).format("ddd MMM DD YYYY") + " " + moment(time, 'HH:mm:ss').format("HH:mm:ss")}`
    }
    catch (e) {
        console.log(e)
    }
}

export const slotDateFormatter = (date: any) => {
    try {
        return `${moment(date).format("MMM DD YYYY")}`
    }
    catch (e) {
        console.log(e)
    }
}


export const slotTimeFormatter = (time: any) => {
    try {
        return `${moment(time, 'hh:mm:ss').format("hh:mm A")}`
    }
    catch (e) {
        console.log(e)
    }
}

export function convertToISODateTime(timeString: any) {
    let currentDate = moment.utc(new Date()).local().format().match(/\d\d\d\d-\d\d-\d\d/);
    let isoDateTime = currentDate + "T" + timeString;
    return isoDateTime;
}

export const scheduleSAndFillSlotDateFormatter = (startDate: any, endDate: any) => {
    // Start Date And End Date
    const isSameYear = moment(startDate).isSame(endDate, 'year')
    const formattedStartDate = isSameYear ? moment(startDate).format("MMM DD") : moment(startDate).format("MMM DD, YYYY");
    const formattedEndDate = moment(endDate).format("MMM DD, YYYY");
    try {
        return (
            `${formattedStartDate + " - " + formattedEndDate}`
        )
    }
    catch (e) {
        console.log(e)
    }
}

export const printTimeSlots = (array: any) => {
    if (array.length < 2) {
        return array
    }
    else if (array.length === 2) {
        return array.join(" and ")
    }
    else if (array.length >= 2) {
        return `${array.slice(0, -1).join(", ")}, and ${array[array.length - 1]}`
    };
}

export const nextSlotDateTimeFormatter = (date: any, time: any) => {
    try {
        return `${moment(date).format("MM/DD/YYYY") + " " + moment(time, 'hh:mm:ss').format("hh:mm A")}`
    }
    catch (e) {
        console.log(e)
    }
}


export const scheduleEndDateFormatter = (date: any) => {
    try {
        return `${moment(date).format("MM/DD/YYYY")}`
    }
    catch (e) {
        console.log(e)
    }
}

export const getFoodDateTimeFormatter = (slot1: any, slot2: any) => {
    try {
        return `${moment(slot1?.attributes?.date).format("DD MMM") + " " + moment(slot1?.attributes?.time, 'hh:mm:ss').format("hh:mm A") + " - " + moment(slot2?.attributes?.date).format("DD MMM") + " " + moment(slot2?.attributes?.time, 'hh:mm:ss').format("hh:mm A")}`
    }
    catch (e) {
        console.log(e)
    }
}

export const subtotalFunction = (slots: any) => {
    try {
        let subtotal = 0
        slots.forEach((item: any) => {
            subtotal += Number(`${(item?.attributes?.favMeal?.data) ? item?.attributes?.favMeal?.data?.attributes?.total : 0}`)
        })
        return subtotal
    }
    catch (e) {
        console.log(e);
        return 0;
    }
}

export const subtotalForAutofill = (slots: any) => {
    try {
        let subtotal = 0
        slots.forEach((item: any) => {
            subtotal += Number(`${(item?.favMeal) ? item?.favMeal?.total : 0}`)
        })
        return subtotal
    }
    catch (e) {
        console.log(e);
        return 0;
    }
}

export const scheduleAndFillSlotFormat = (data: any) => {
    try {
        const allSlots: any[] = data;
        let slotList = [];
        let slotCount = 0;

        const randomSlot = allSlots[Math.floor(Math.random() * allSlots.length)]
        allSlots?.forEach((currentObj: any) => {
            const slotDate = moment(currentObj?.attributes?.date).format("DD MMM YYYY");
            const slotTime = moment(currentObj?.attributes?.time, 'hh:mm:ss').format("hh:mm A");
            const randomPickedDate = moment(randomSlot?.attributes?.date).format("DD MMM YYYY");

            if (randomPickedDate === slotDate) {
                slotList.push(slotTime)
                slotCount += 1;
            }
        })
        return { slotList: slotList.join(", "), slotCount }
    }
    catch (e) {
        console.log(e)
    }
}

export const expensiveItem = (obj: any) => {
    const items = obj.sort(comparePrice)
    return items[0];
};

const comparePrice = (a: any, b: any) => {
    var keyA = a?.price,
        keyB = b?.price;
    // Compare the 2 prices
    if (keyA < keyB) return 1;
    if (keyA > keyB) return -1;
    return 0;
}


export const getLocationFromLatLong = async (param: any) => {
    try {
        let locationConfig = {
            method: 'GET',
            url: process.env.REACT_APP_GOOGLE_API_URL + `?latlng=${param?.latitude},${param?.longitude}&key=${process.env.REACT_APP_GOOGLE_API_KEY}`,
            headers: {
                'Content-Type': 'application/json',
            },
        };
        const response = (await apiRequestService(locationConfig))?.data?.results[0]?.address_components || [];
        let addressObject: any = {}
        response?.forEach((element: any) => {
            addressObject[element?.types[0]] = element?.short_name;
        });

        return addressObject;
    }
    catch (e) {
        throw e
    }
};


export const isRestaurantOpen = (delivery_hour: any, dateTimeStr: any, timeZoneData: any, schedule: any) => {
    try {
        const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
        const dateTime = new Date(dateTimeStr);
        const dayOfWeek = days[dateTime.getDay()];
        const openingHours = delivery_hour[dayOfWeek];

        if (openingHours === "Open 24 hours") {
            return true;
        };

        if (!openingHours || openingHours === "Closed") {
            return false; // restaurant is closed on this day
        };

        // ***************** Function to getting date time according to timezone *****************************
        const localeTimeZone = getLocationLocalTime(timeZoneData, dateTime);

        // ------------- Getting Only time from responded time and date ------------------
        let localeTime = (localeTimeZone).match(/\d\d:\d\d/)[0];

        const [openTimeStr, closeTimeStr] = openingHours?.split(" - ");

        // ------------- Converting 12hours opening and closing timing to 24hours time ------------------

        let openTime24: any;
        let closeTime24: any;

        if (schedule) {
            openTime24 = moment(openTimeStr, 'hh:mm A').add(1, 'hours').format('HH:mm');
            closeTime24 = moment(closeTimeStr, 'hh:mm A').subtract(1, 'hours').format('HH:mm');
        }
        else {
            openTime24 = moment(openTimeStr, 'hh:mm A').format('HH:mm');
            closeTime24 = moment(closeTimeStr, 'hh:mm A').format('HH:mm');
        }

        if (closeTime24 < openTime24) {
            if (localeTime >= openTime24 || localeTime < closeTime24) {
                return true; // restaurant is open at this time
            }
        } else {
            if (localeTime >= openTime24 && localeTime < closeTime24) {
                return true; // restaurant is open at this time
            }
            else {
                return false; // restaurant is closed at this time
            }
        }
    }
    catch (e) {
        console.log(e);
    }
};

export const checkRestaurantAvailabilityForSelectedSlots = (delivery_hour: any, dateTimeStr: any, timeZoneData: any) => {
    try {
        const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
        const dateTime = new Date(dateTimeStr);
        const dayOfWeek = days[dateTime.getDay()];
        const openingHours = delivery_hour[dayOfWeek];

        let infoMessage = "";
        let restaurant = "";

        if (openingHours === "Open 24 hours") {
            infoMessage = '';
            restaurant = 'Open'
            return { infoMessage, restaurant };
        };
        if (!openingHours || openingHours === "Closed") {
            infoMessage = `Restaurant will be Closed on ${dayOfWeek}`;
            restaurant = 'Close';
            return { infoMessage, restaurant }; // restaurant is closed on this day
        };

        // ***************** Function to getting date time according to timezone *****************************
        // const localeTimeZone = getLocationLocalTime(timeZoneData, dateTime);

        // ------------- Getting Only time from responded time and date ------------------
        // let localeTime = (localeTimeZone).match(/\d\d:\d\d/)[0];

        // ------------- Getting Only time from restaurant time and date and slot date time ------------------
        const slotTime24 = moment(dateTime, 'hh:mm A').format('HH:mm');
        const [openTimeStr, closeTimeStr] = openingHours?.split(" - ");

        // ------------- Converting 12hours opening and closing timing to 24hours time ------------------

        const openTime24 = moment(openTimeStr, 'hh:mm A').format('HH:mm');
        const closeTime24 = moment(closeTimeStr, 'hh:mm A').format('HH:mm');


        if (closeTime24 < openTime24) {
            if (slotTime24 >= openTime24 || slotTime24 < closeTime24) {
                infoMessage = '';
                restaurant = 'Open';
                return { infoMessage, restaurant }; // restaurant is open at this time
            }
        } else {
            if (slotTime24 >= openTime24 && slotTime24 < closeTime24) {
                infoMessage = '';
                restaurant = 'Open';
                return { infoMessage, restaurant }; // restaurant is open at this time
            }
            else {
                infoMessage = `Delivery available between ${openTimeStr} - ${closeTimeStr}`;
                restaurant = 'Close';
                return { infoMessage, restaurant }; // restaurant is closed at this time
            }
        }
        // infoMessage = `Delivery available between ${openTimeStr} - ${closeTimeStr}`;
        // restaurant = 'Close';
        // return { infoMessage, restaurant }; // restaurant is closed at this time
    }
    catch (e) {
        console.log(e);
    }
};


export const restaurantOpenAndClosingTime = (delivery_hour: any, dateTimeStr: any, orderType: string) => {
    try {
        const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
        const dateTime = new Date(dateTimeStr);
        let restaurantAvailability = "";
        let openingTime = "";
        let closingTime = "";

        const dayOfWeek = days[dateTime.getDay()];
        const openingHours = delivery_hour[dayOfWeek];

        if (openingHours === "Closed") {
            restaurantAvailability = `Closed on ${dayOfWeek}`;
            openingTime = "";
            closingTime = "";
            return { restaurantAvailability, openingTime, closingTime }; // restaurant is closed on this day
        };
        if (openingHours === "Open 24 hours") {
            restaurantAvailability = `${(orderType === "delivery") ? "Delivery available for 24 hours" : "Pickup available for 24 hours"}`;
            openingTime = '00:00';
            closingTime = '23:59';
            return { restaurantAvailability, openingTime, closingTime };
        };
        const [openTimeStr, closeTimeStr] = openingHours?.split(" - ");
        const openTime24 = moment(openTimeStr, 'hh:mm A').format('hh:mm A');
        const closeTime24 = moment(closeTimeStr, 'hh:mm A').format('hh:mm A');
        restaurantAvailability = `${(orderType === "delivery") ? `Delivery available between ${openTime24} - ${closeTime24}` : `Pickup available between ${openTime24} - ${closeTime24}`}`;
        openingTime = moment(openTimeStr, 'hh:mm A').format('HH:mm');
        closingTime = moment(closeTimeStr, 'hh:mm A').format('HH:mm');
        return { restaurantAvailability, openingTime, closingTime };
    }
    catch (e) {
        console.log(e);
    }
};

export const findRestaurantDistance = (lat1: any, lang1: any, lat2: any, lang2: any) => {
    const earthRadius = 6371; // Radius of the Earth in kilometers
    function degToRad(deg: any) {
        return deg * (Math.PI / 180);
    }
    // Convert latitude and longitude to radians
    const lat1Rad = degToRad(lat1);
    const lon1Rad = degToRad(lang1);
    const lat2Rad = degToRad(lat2);
    const lon2Rad = degToRad(lang2);

    // Calculate the differences between coordinates
    const latDiff = lat2Rad - lat1Rad;
    const lonDiff = lon2Rad - lon1Rad;

    // Calculate the distance using the Haversine formula
    const a =
        Math.sin(latDiff / 2) ** 2 +
        Math.cos(lat1Rad) *
        Math.cos(lat2Rad) *
        Math.sin(lonDiff / 2) ** 2;
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const result = earthRadius * c;
    const distanceInMiles = result * 0.62137119;

    return distanceInMiles;
};

export const findAndModifySelectedOption = (data: any, customizationId: any, optionId: any, quantity: any) => {
    try {
        for (let i = 0; i < data.length; i++) {
            const item = data[i];
            if (item.hasOwnProperty('customization_id') && item.customization_id === customizationId) {

                // For Radio Buttons
                if (item?.max_choice_options === 1 && quantity === null) {

                    // Unselect Other
                    const optionUnselect = item?.options.filter((opt: any) => opt.option_id !== optionId);
                    if (optionUnselect?.length > 0) {
                        optionUnselect?.forEach((element: any) => {
                            element.is_selected = false;
                            element.selected_quantity = 0;
                        });
                    }

                    // Select Target Option
                    const option = item.options.find((opt: any) => opt.option_id === optionId);
                    if (option) {
                        option.is_selected = true;
                        option.selected_quantity = 1;
                        return data;
                    }
                }


                if (item?.max_choice_options === -1 && quantity === null) {

                    // Unselect Other
                    const optionUnselect = item?.options.filter((opt: any) => opt.option_id !== optionId);
                    if (optionUnselect?.length > 0) {
                        optionUnselect?.forEach((element: any) => {
                            element.is_selected = false;
                            element.selected_quantity = 0;
                        });
                    }

                    // Select Target Option
                    const option = item.options.find((opt: any) => opt.option_id === optionId);
                    if (option) {
                        option.is_selected = true;
                        option.selected_quantity = 1;
                        return data;
                    }
                }


                // For CheckBox Buttons
                if (item?.max_choice_options > 1 && quantity === null) {

                    // Select/Unselect Target Option
                    const option = item.options.find((opt: any) => opt.option_id === optionId);
                    if (option) {
                        option.is_selected = !option.is_selected;
                        option.selected_quantity = (option.is_selected ? 1 : 0);
                        return data;
                    }
                }
                // For Quantity Selectors
                if (item?.max_choice_options > 1 && quantity >= 0) {

                    // Change Target Option Quantity
                    const option = item.options.find((opt: any) => opt.option_id === optionId);
                    if (option) {
                        option.is_selected = (quantity > 0 ? true : false);
                        option.selected_quantity = quantity;
                        return data;
                    }
                }
            }
            if (item.hasOwnProperty('options') && Array.isArray(item.options)) {
                const updatedOptions = findAndModifySelectedOption(item.options, customizationId, optionId, quantity);
                if (updatedOptions) {
                    item.options = updatedOptions;
                    return data;
                }
            }
            if (item.hasOwnProperty('customizations') && Array.isArray(item.customizations)) {
                const updatedCustomizations = findAndModifySelectedOption(item.customizations, customizationId, optionId, quantity);
                if (updatedCustomizations) {
                    item.customizations = updatedCustomizations;
                    return data;
                }
            }
        }
        return null;
    }
    catch (e) {
        console.log(e)
        // throw e
    }
};


const moveObjectsToStart = (arr: any) => {
    const requiredList = arr.filter((obj: any) => obj?.min_choice_options > 0);
    const noneRequiredList = arr.filter((obj: any) => obj?.min_choice_options === 0);
    return [...requiredList, ...noneRequiredList];
};

export const initiateCustomization = (customizations: any) => {
    try {
        if (customizations?.length > 0) {

            const data = customizations.map((item: any) => {

                item["options"] = item?.options?.map((sub_item: any) => {

                    sub_item["is_selected"] = (sub_item?.default_qty > 0 ? true : false);
                    sub_item["selected_quantity"] = sub_item?.default_qty;

                    if (sub_item?.customizations?.length > 0) {
                        sub_item["customizations"] = initiateCustomization(sub_item?.customizations);
                    }

                    return sub_item;
                });

                return item;
            })
            return moveObjectsToStart(data);
        }
        else {
            return []
        }
    }
    catch (e) {
        console.log(e)
    }
};

export const customizationValidator = (customizations: any) => {

    if (customizations?.length > 0) {

        let list = []
        customizations?.forEach((item: any) => {

            let selectedOptionList = []

            item?.options?.forEach((sub_item: any) => {

                if (sub_item?.is_selected) {

                    if (sub_item?.customizations?.length > 0) {
                        list = [...customizationValidator(sub_item?.customizations)]
                    }

                    selectedOptionList.push(sub_item);
                }

            });

            // check here --- (this is just for exceptional case which is max_choice_recieved -1 sometimes from mealme (don't know why) api )
            if (item?.max_choice_options === -1) {
                item.max_choice_options = 1;
            }

            if (item?.min_choice_options !== item?.max_choice_options) {
                if ((item?.min_choice_options > 0) && (selectedOptionList?.length < item?.min_choice_options || selectedOptionList?.length > item?.max_choice_options)) {
                    list.push({
                        name: item?.name,
                        max_choice_options: item?.max_choice_options,
                        min_choice_options: item?.min_choice_options,
                    });
                }
            }
            else {
                let sum = 0;
                item?.options?.forEach((optObj: any) => sum += optObj?.selected_quantity);
                if ((item?.min_choice_options > 0) && (item?.min_choice_options > sum)) {
                    list.push({
                        name: item?.name,
                        max_choice_options: item?.max_choice_options,
                        min_choice_options: item?.min_choice_options,
                    });
                }
            }
        })

        return list;
    }
    else {
        return []
    }
};


export const faveMealFormatter = (mealList: any) => {
    try {
        let favItems: any = []

        mealList?.forEach((currentObj: any) => {
            let mealObj = {
                "name": currentObj?.name,
                "product": currentObj?.product_id,
                "product_image": currentObj?.product_image,
                "product_price": currentObj?.product_price,
                "quantity": currentObj?.quantity,
                "customizations": currentObj?.customizationList,
                "instructions": currentObj?.instructions,
            }
            favItems.push(mealObj)
        })

        return favItems;
    }
    catch (e) {
        console.log(e);
    }
};

export const orderItemsFormatter = (items: any) => {
    try {
        let favItems: any = [];
        items?.forEach((currentObj: any) => {

            let mealObj = {
                "name": currentObj?.product?.name,
                "product": currentObj?.product?.mealme_product_id,
                "product_image": currentObj?.product?.image,
                "product_price": currentObj?.product?.price,
                "quantity": currentObj?.quantity,
                "customizations": currentObj?.customizations,
                "instructions": currentObj?.instructions,
            }
            favItems.push(mealObj)
        })

        return favItems;
    }
    catch (e) {
        console.log(e);
    }
}

export const existingOrderItemsFormatter = (items: any) => {
    try {
        let favItems: any = [];
        items?.forEach((currentObj: any) => {

            let mealObj = {
                "name": currentObj?.attributes?.product?.data?.attributes?.name,
                "product": currentObj?.attributes?.product?.data?.attributes?.mealme_product_id,
                "product_image": currentObj?.attributes?.product?.data?.attributes?.image,
                "product_price": currentObj?.attributes?.product?.data?.attributes?.price,
                "quantity": currentObj?.attributes?.quantity,
                "customizations": currentObj?.attributes?.customizations,
                "instructions": currentObj?.attributes?.instructions,
            }
            favItems.push(mealObj)
        })

        return favItems;
    }
    catch (e) {
        console.log(e);
    }
}

function calculateTotalPrice(data: any) {
    let totalPrice = 0;

    for (let i = 0; i < data.length; i++) {
        const item = data[i];

        if (item.hasOwnProperty('options') && Array.isArray(item.options)) {
            totalPrice += calculateTotalPrice(item.options);
        }

        if (item.hasOwnProperty('customizations') && Array.isArray(item.customizations)) {
            totalPrice += calculateTotalPrice(item.customizations);
        }

        if (item.hasOwnProperty('is_selected') && item.is_selected && item.hasOwnProperty('price')) {
            totalPrice += item.price * item.selected_quantity;
        }
    }

    return totalPrice;
}

export const priceCalculator = (mainPrice: any, customization: any) => {
    const customizationData = customization || []
    try {
        let customizationPrice = calculateTotalPrice(customizationData);
        return (Number(mainPrice) + customizationPrice)
        // if (Number(mainPrice) > customizationPrice) {
        //     return (Number(mainPrice) + customizationPrice)
        // }
        // else if (Number(mainPrice) < customizationPrice) {
        //     return (customizationPrice)
        // }
    }
    catch (e) {
        console.log(e)
    }
}

// ************************ Getting Customizations is true Value **************************************
export function getCustomizationsSelectedData(obj: any, result = []) {
    if (typeof obj !== 'object' || obj === null) {
        return result;
    }

    if (Array.isArray(obj)) {
        for (let i = 0; i < obj.length; i++) {
            getCustomizationsSelectedData(obj[i], result);
        }
    } else {
        for (let key in obj) {
            if (key === 'is_selected' && obj[key] === true) {
                result.push(obj);
            } else {
                getCustomizationsSelectedData(obj[key], result);
            }
        }
    };
    let parentCustomiseArray = [];
    let childCustomiseArray = [];

    result?.forEach((objData: any) => {
        if (objData?.customizations.length > 0) {
            parentCustomiseArray.push({ objData });
        } else {
            childCustomiseArray.push({ objData });
        }
    });

    return ({ parentCustomiseArray, childCustomiseArray });
};

// ************************ Getting Customisations isTrue Value **************************************
export function getFinalizedCustomizations(obj: any, result = []) {
    if (typeof obj !== 'object' || obj === null) {
        return result;
    }

    if (Array.isArray(obj)) {
        for (let i = 0; i < obj.length; i++) {
            getCustomizationsSelectedData(obj[i], result);
        }
    } else {
        result.push(obj);
    }

    return result;
};

export const finalizeItemPriceCalculate = (customizations: any) => {
    try {
        let totalPrice: number = 0;
        customizations?.forEach((item: any) => {
            item?.selected_options?.forEach((sub_item: any) => {
                // if (sub_item?.customizations?.length === 0) {
                totalPrice += (sub_item?.price * sub_item?.quantity);
                // }
                if (sub_item?.customizations?.length > 0) {
                    totalPrice += finalizeItemPriceCalculate(sub_item?.customizations)
                }
            });
        })
        return totalPrice;
    }
    catch (e) {
        console.log(e)
    }
};

// ************************** Getting time and date(time-zone) according to lat long ******************************************
export function getLocationLocalTime(timeZoneData: any, datetime: any) {

    const timestamp = new Date(datetime).getTime() / 1000;
    if (timeZoneData?.timeZoneId) {
        const timezoneId = timeZoneData?.timeZoneId;

        const timezoneOffset = timeZoneData.dstOffset + timeZoneData.gmtOffset;
        const localTime = new Date(timestamp * 1000 + timezoneOffset * 1000);

        localTime.setMinutes(localTime.getMinutes() + 1);

        const options: Intl.DateTimeFormatOptions = {
            year: 'numeric',
            month: 'long',
            day: '2-digit', // Use 2-digit to ensure two digits for the day
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
            hour12: false,
            timeZone: timezoneId
        };
        const inputDateString = localTime.toLocaleString('en-US', options);
        return inputDateString;
    } else {
        throw new Error('Failed to retrieve timezone information.');
    }
};

export function getLocationTimeFromTimeStamp(timeZoneData: any, timestamp: any) {

    if (timeZoneData?.timeZoneId) {
        const timezoneId = timeZoneData?.timeZoneId;

        const timezoneOffset = timeZoneData.dstOffset + timeZoneData.gmtOffset;
        const localTime = new Date(timestamp * 1000 + timezoneOffset * 1000);

        localTime.setMinutes(localTime.getMinutes() + 1);

        const options: Intl.DateTimeFormatOptions = {
            year: 'numeric',
            month: 'long',
            day: '2-digit', // Use 2-digit to ensure two digits for the day
            hour: 'numeric',
            minute: 'numeric',
            hour12: false,
            timeZone: timezoneId
        };

        const inputDateString = localTime.toLocaleString('en-US', options);
        return getFormattedDateTime(inputDateString);
    } else {
        throw new Error('Failed to retrieve timezone information.');
    }
};

export const getFormattedDateTime = (inputDateString) => {

    const months = {
        January: 0,
        February: 1,
        March: 2,
        April: 3,
        May: 4,
        June: 5,
        July: 6,
        August: 7,
        September: 8,
        October: 9,
        November: 10,
        December: 11
    };
    const parts = inputDateString.match(/(\w+) (\d+), (\d+) at (\d+):(\d+)/);
    if (!parts) return null;

    const [, monthStr, day, year, hours, minutes] = parts;

    const month = months[monthStr];
    if (month === undefined) return null;

    const formattedDate = `${year}-${String(month + 1).padStart(2, '0')}-${day} ${hours}:${minutes}`;
    return formattedDate;
};


export const generateValidTimeOptions = (externalDate) => {
    const options = [];
    const now = new Date(); // Current date and time
    const currentDateTime = externalDate ? new Date(externalDate) : now;

    const currentYear = currentDateTime.getFullYear();
    const currentMonth = currentDateTime.getMonth();
    const currentDay = currentDateTime.getDate();


    for (let hour = 0; hour <= 23; hour++) {
        for (let minute = 0; minute <= 45; minute += 15) {
            const formattedHour24 = hour.toString().padStart(2, '0');
            const formattedHour12 = (hour % 12 === 0) ? '12' : (hour % 12).toString().padStart(2, '0');
            const formattedMinute = minute.toString().padStart(2, '0');
            const time24 = `${formattedHour24}:${formattedMinute}`;
            const time12 = `${formattedHour12}:${formattedMinute} ${hour >= 12 ? 'PM' : 'AM'}`;

            const timeDate = new Date(currentYear, currentMonth, currentDay, hour, minute);
            if (timeDate <= now) {
                // Time has already passed, disable the option
                options.push({ value: time24, label: time12, disabled: true });
            } else {
                options.push({ value: time24, label: time12 });
            }
        }
    }
    return options;
};


export const generateTimeOptions = () => {
    const options = [];

    // for (let hour = 0; hour <= 23; hour++) {
    //     for (let minute = 0; minute <= 45; minute += 15) {
    //         const formattedHour24 = hour.toString().padStart(2, '0');
    //         const formattedHour12 = (hour % 12 === 0) ? '12' : (hour % 12).toString().padStart(2, '0');
    //         const formattedMinute = minute.toString().padStart(2, '0');
    //         const time24 = `${formattedHour24}:${formattedMinute}`;
    //         const time12 = `${formattedHour12}:${formattedMinute} ${hour >= 12 ? 'PM' : 'AM'}`;
    //         options.push({ value: time24, label: time12 });
    //     }
    // }

    // Generate time options from 12 PM to 11:45 PM
    for (let hour = 12; hour <= 23; hour++) {
        for (let minute = 0; minute <= 45; minute += 15) {
            const formattedHour24 = hour.toString().padStart(2, '0');
            const formattedMinute = minute.toString().padStart(2, '0');
            const time24 = `${formattedHour24}:${formattedMinute}`;
            const formattedHour12 = (hour % 12 === 0) ? '12' : (hour % 12).toString().padStart(2, '0');
            const time12 = `${formattedHour12}:${formattedMinute} ${hour >= 12 ? 'PM' : 'AM'}`;
            options.push({ value: time24, label: time12 });
        }
    }

    // Generate time options from 12:00 AM to 11:45 AM
    for (let hour = 0; hour <= 11; hour++) {
        for (let minute = 0; minute <= 45; minute += 15) {
            const formattedHour24 = hour.toString().padStart(2, '0');
            const formattedMinute = minute.toString().padStart(2, '0');
            const time24 = `${formattedHour24}:${formattedMinute}`;
            const formattedHour12 = (hour === 0) ? '12' : hour.toString().padStart(2, '0');
            const time12 = `${formattedHour12}:${formattedMinute} AM`;
            options.push({ value: time24, label: time12 });
        }
    }
    return options;
};


export const groupRestaurantSchedule = (schedules: any, userAddressTimezone: any) => {
    schedules = schedules?.map((schedule: any) => {
        return {
            ...schedule,
            minimum: getLocationTimeFromTimeStamp(userAddressTimezone, schedule?.minimum),
            maximum: getLocationTimeFromTimeStamp(userAddressTimezone, schedule?.maximum)
        };
    });

    let groupedSchedules = [];

    schedules?.forEach((schedule: any) => {
        let date = schedule.minimum?.split(" ")[0];
        if (!groupedSchedules[date]) {
            groupedSchedules[date] = []; // Initialize the array if it doesn't exist
        }
        groupedSchedules[date].push({ id: schedule.id, time: schedule.minimum, delivery_fee: schedule.delivery_fee });
    });
    return groupedSchedules;
}

export const genTimeOptFromScheduleTime = (timeDiff: any, scheduleArray: any, externalDate: any, minTime?: string, maxTime?: string, userTimezone?: any) => {
    if (!minTime || !maxTime) {
        return []; // Return an empty array if no minTime or maxTime is provided
    }

    const scheduleData: any = (Object.values(scheduleArray)[0] !== undefined ? Object.values(scheduleArray)[0] : []);
    const options = [];

    const now = getFormattedDateTime(getLocationLocalTime(userTimezone, new Date())); // Current date and time

    let selectedDate = externalDate?.split("T")[0];
    let currentTime = now?.split(" ")[1];
    if (currentTime?.startsWith("24")) {
        currentTime = "00" + currentTime?.slice(2);
    }
    let currentDate = now?.split(" ")[0];

    for (let hour = 0; hour <= 23; hour++) {
        for (let minute = 0; minute <= 45; minute += (timeDiff[0] ? timeDiff[0] : 15)) {
            const formattedHour24 = hour.toString().padStart(2, '0');
            const formattedHour12 = (hour % 12 === 0) ? '12' : (hour % 12).toString().padStart(2, '0');
            const formattedMinute = minute.toString().padStart(2, '0');
            const time24 = `${formattedHour24}:${formattedMinute}`;
            const time12 = `${formattedHour12}:${formattedMinute} ${hour >= 12 ? 'PM' : 'AM'}`;

            if (selectedDate === currentDate && time24 < currentTime) {
                // options.push({ value: time24, label: time12, disabled: true });
            } else {
                let style = '';
                let id = null;
                let optionLabel = time12;
                let optionValue = time24;

                for (const schedule of scheduleData) {
                    let scheduleTime = schedule?.time?.split(" ")[1];
                    if (scheduleTime === time24) {
                        id = schedule?.id;
                        optionLabel = `${time12}`;
                        optionValue = `${time24}@${id}~${(selectedDate === currentDate) ? 'verified' : 'unverified'}`;
                        style = "!text-green-600";
                        break; // Exit the loop once a match is found
                    }
                }

                const isTimeInRange = (!minTime || !maxTime) || (
                    minTime <= maxTime
                        ? (time24 >= minTime && time24 <= maxTime)
                        : (time24 >= minTime || time24 <= maxTime)
                );

                if (isTimeInRange === true) {
                    options.push({ value: optionValue, label: optionLabel, className: style });
                }
            }
        }
    }
    return options;
};


export function calculateTimeDifference(times) {
    const timeDifferences = [];

    for (let i = 0; i < times.length - 1; i++) {
        const currentTime: any = new Date(times[i]);
        const nextTime: any = new Date(times[i + 1]);
        const timeDiffMs = nextTime - currentTime;

        // Convert time difference to minutes (or any desired unit)
        const timeDiffMinutes = Math.floor(timeDiffMs / (1000 * 60));
        timeDifferences.push(timeDiffMinutes);
    }
    return timeDifferences;
};

export const getTimeDifference = (userAddressTimezone, selectedScheduleTime) => {
    const now = getFormattedDateTime(getLocationLocalTime(userAddressTimezone, new Date()));

    let currentTime = now?.split(" ")[1];
    let currentDate = now?.split(" ")[0];

    let selectedTime = selectedScheduleTime?.split("@")[0];
    const time1Parts = currentTime?.split(':');
    const time2Parts = selectedTime?.split(':');

    const hours1 = parseInt(time1Parts[0]);
    const minutes1 = parseInt(time1Parts[1]);

    const hours2 = parseInt(time2Parts[0]);
    const minutes2 = parseInt(time2Parts[1]);

    const totalMinutes1 = hours1 * 60 + minutes1;
    const totalMinutes2 = hours2 * 60 + minutes2;

    const differenceInMinutes = Math.abs(totalMinutes1 - totalMinutes2);

    return { differenceInMinutes, currentDate };
}

export const filterRestaurantSchedulesByDateTime = (restaurantSchedules: any, targetDateTime: any) => {

    const targetDate = new Date(targetDateTime);

    const filteredScheduleByDate = [];

    // Filter and push matching dates to the array
    for (var scheduleArray in restaurantSchedules) {
        if (restaurantSchedules.hasOwnProperty(scheduleArray)) {
            // Apply filtering condition to the date (for example, filtering out dates after a certain date)
            if (moment(scheduleArray).format('YYYY-MM-DD') === moment(targetDate).format('YYYY-MM-DD')) {
                filteredScheduleByDate[scheduleArray] = restaurantSchedules[scheduleArray];
            };
        };
    };

    // Now, filter the filteredByDate array by the target time
    const targetTime = targetDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', second: '2-digit' });
    const filteredByTime = Object?.values(filteredScheduleByDate)[0]?.filter(item => {
        const itemTime = new Date(item.time).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', second: '2-digit' });
        return itemTime === targetTime;
    });

    return filteredByTime;
}

export const getUserDataByToken = async (token: any) => {
    const response = await getUserBySlug(token);
    if (response?.data?.length > 0) {
        trackPixelEvent('Get user by slug');
        return response?.data[0];
    }
    else {
        return null;
    }
}

export const tokenLogin = async (token: any, verify: boolean) => {
    try {

        if (verify) {
            const userData = await getUserDataByToken(token);
            localStorage.removeItem("MealTrendUser");
            localStorage.removeItem("MealFaveUserDeliveryAddress");
            localStorage.removeItem("CalendarData");
            localStorage.removeItem("RestaurantData");
            localStorage.removeItem("SomeoneElseOrderData");
            clearRestaurantList();
            setNearByRestaurants([]);
            setUserDeliveryAddress({});
            setUserFaveMealList([]);
            setRecentOrderList([]);
            setNearByScheduleRestaurants([]);

            if (userData) {
                const response = await loginRegisterUser(`${userData?.phone_no !== null ? userData?.phone_no : userData?.email}`, (userData?.phone_no !== null ? "phone" : "email"))
                let data = response?.data;
                trackPixelEvent('Login register user');
                if (data.status === "pending") {
                    await setUserData(data);
                    store.dispatch({
                        type: "SET_VERIFY_MODAL",
                        payload: { show: true, contact: data?.contact, provider: data?.provider }
                    })
                }
            }
            else {
                return null
            }
        }
    } catch (e) {
        console.log("Verification failed", e);
    }
}

export const maskEmail = (email: string) => {
    const [username, domain] = email?.split('@');
    if (username) {
        if (username.length > 5) {
            // If username length is greater than 5, use this masking logic
            const maskedUsername = username.slice(0, 5) + '*'.repeat(username.length - 5);
            return maskedUsername + '@' + domain;
        } else {
            // If username length is less than or equal to 5, use this masking logic
            const maskedUsername = username?.slice(0, 2) + '*'.repeat(Math.max(0, username.length - 1));
            return maskedUsername + '@' + domain;
        }
    } else {
        // Handle case where username is undefined or null
        return email;
    }
};

export const maskPhoneNumber = (number: string) => {
    // Remove all non-digit characters from the phone number
    const cleanedNumber = number.replace(/\D/g, '');
    // Mask all digits except the last 2 characters with asterisks
    const maskedNumber = cleanedNumber.slice(0, -2).replace(/\d/g, '*') + cleanedNumber.slice(-2);
    return maskedNumber;
};

export const maskCurrencyInput = (input) => {
    let rawValue = input.replace(/[^\d]/g, '');

    // Extract cents (last two characters)
    let cents = rawValue.slice(-2);

    // Extract dollars (all remaining characters except last two)
    let dollars = rawValue.slice(0, -2) || '0';

    // Remove leading zeros from dollars
    dollars = String(parseInt(dollars, 10));

    // Define min and max limits for dollars
    const minDollars = 0;
    const maxDollars = 1000; // Set your desired maximum limit here

    // Ensure dollars value is within the specified range
    dollars = Math.max(minDollars, Math.min(maxDollars, dollars));

    // Construct the masked value
    let maskedValue = `$${dollars}.${cents}`;
    return maskedValue;
};

export const toPascalCase = (string) => {
    return `${string}`
        .toLowerCase()
        .replace(new RegExp(/[-_]+/, 'g'), ' ')
        .replace(new RegExp(/[^\w\s]/, 'g'), '')
        .replace(
            new RegExp(/\s+(.)(\w*)/, 'g'),
            ($1, $2, $3) => `${$2.toUpperCase() + $3}`
        )
        .replace(new RegExp(/\w/), s => s.toUpperCase());
}

export const trackStandardPixelEvent = (eventName: string, data?: any) => {
    fbq('track', eventName, data);
};

export const trackPixelEvent = (eventName: string, data?: any) => {
    fbq('trackCustom', toPascalCase(eventName), data);
};

// export const trackPageViewPixelEvent = (pageName: string) => {
//     fbq('track', 'PageView');
// };

export const wrapText = (text: string) => {
    if (window.innerWidth <= 430) {
        if (text.length > 35) return text.substring(0, 35) + "...";
    }

    if (window.innerWidth < 325) {
        if (text.length > 25) return text.substring(0, 25) + "...";
    } else return text;

    return text;
};

