import Divider from "../../components/widgets/divider/Divider";
import TopHead from "../../components/widgets/head/TopHead";
import { faAngleLeft, faSearch } from "@fortawesome/pro-solid-svg-icons";
import Section from "../../components/widgets/section/Section";
import { ButtonGreen, FlexBetween, InitialDiv, InputLabel, InputText, SubText1, TextHead3, TextHead5 } from "../../components/styled/styled";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { countryCodeFilter, handleKeyDownNumber, removeMask, textToNumberFilter, trackPixelEvent, validateEmail, validatePhone } from "../../utils/utilities";
import { addInvitations, removeInvitation } from "../schedules/redux/createScheduleReduxActions";
import { switchSentenceCase } from "../../utils/utilities";
import ModalRemoved from "../../components/widgets/modals/ModalRemoved";
import { useIonAlert, useIonViewDidEnter } from "@ionic/react";
import Spinner from "../../components/widgets/spinner/Spinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { findFriends } from "../../services/friends";
import React from "react";
import useDarkMode from "../../components/hooks/darkmode/DarkMode";
import no_user_pic from "../../components/assets/profile/no-profile-photo.png";
import DebouncedSelect from "../../components/styled/DebouncedSelect";
import { objectType } from "../../type";
import { addNewInvitation } from "../../services/schedule";
import { getUserInfo } from "../../services/user";

export default function InviteFoodie() {
    const dispatch = useDispatch();
    const [presentAlert] = useIonAlert();
    const isDarkMode = useDarkMode();
    const user = getUserInfo();

    const showAlert = (header: string, message: string, btnMessage: string) => {
        presentAlert({
            header: header,
            message: message,
            buttons: [btnMessage],
        });
    };

    const { scheduleDetails, scheduleEmails } = useSelector((state: any) => state?.createScheduleForMe);
    const [sponsorContact, setSponsorContact] = useState("");
    const [showModalRemove, setShowModalRemove] = useState(false);
    const [selectedId, setSelectedId] = useState("");
    const [loading, setLoading] = useState(false);
    const [inputLength, setInputLength] = useState(false);
    const [friendValue, setFriendValue] = useState([]);
    const [inviteMembers, setInviteMembers] = useState([]);

    useEffect(() => {
    }, []);

    useIonViewDidEnter(() => {
        trackPixelEvent('Invite sponsor');
    }, []);


    // Error Handler
    const [sponsorContactError, setSponsorContactError] = useState(false)

    const handleChange = (e: any) => {

        const value = e.target.value
        const validEmail = validateEmail(value);
        const validPhoneNo = validatePhone(removeMask(value));
        const isNumeric = /^[0-9-]+$/.test(value);

        if (validPhoneNo && isNumeric) {
            setInputLength(true);
        }
        else {
            setInputLength(false);
        }
        if (!validEmail && !validPhoneNo) {
            setSponsorContactError(true);
        }
        else if (validEmail || validPhoneNo) {
            setSponsorContactError(false);
        }
        else {
            setSponsorContactError(false);
        }
        setSponsorContact(textToNumberFilter(value));
    }

    const handleKeyDown = (e: any) => {
        const number = handleKeyDownNumber(e, sponsorContact);
        setSponsorContact(number)
    };


    function addInvite() {
        if (sponsorContact !== "" && !sponsorContactError) {
            const validPhoneNo = validatePhone(removeMask(sponsorContact));
            const filterSponsors = scheduleEmails?.filter((data: any) => data?.attributes?.member === ((validPhoneNo) ? removeMask(countryCodeFilter(sponsorContact)) : sponsorContact.toLowerCase()));

            if (validPhoneNo && user?.phone_no === removeMask(countryCodeFilter(sponsorContact))) {
                setSponsorContact("");
                return showAlert("", "You cannot send invitation to yourself.", "OK");
            }
            else if (user?.email === sponsorContact.toLowerCase()) {
                setSponsorContact("");
                return showAlert("", "You cannot send invitation to yourself.", "OK");
            }
            else if (inviteMembers.includes((validPhoneNo) ? removeMask(countryCodeFilter(sponsorContact)) : sponsorContact.toLowerCase())) {
                setSponsorContact("");
                return showAlert("", "Already Added.", "OK");
            }
            else if (filterSponsors?.length > 0) {
                setSponsorContact("");
                return showAlert("", "This invited member already exists.", "OK");
            }
            else {
                inviteMembers.push((validPhoneNo) ? removeMask(countryCodeFilter(sponsorContact)) : sponsorContact.toLowerCase());
                setSponsorContactError(false);
                setSponsorContact("");
            }
        }
        else {
            setSponsorContactError(true);
        }
    };

    function removeEmail(index: number, item: string) {
        const list = [...inviteMembers];
        const friendList = [...friendValue]

        list.splice(index, 1);
        setInviteMembers(list);
        const itemIndex = friendList.findIndex((val) => val === item);
        if (itemIndex !== -1) {
            friendList.splice(itemIndex, 1);
            // Update the state with the modified arrays
            setFriendValue(friendList);
        }
    };

    async function sendInvite() {
        setLoading(true);
        if (inviteMembers?.length === 0) {
            setLoading(false);
            return showAlert("", "No any selected members.", "OK");
        }
        else {
            const payloadData = {
                data: {
                    members: inviteMembers,
                    schedule: scheduleDetails?.id,
                    user: scheduleDetails?.attributes?.user?.data?.id
                }
            };
            try {
                const response = await addNewInvitation(payloadData);
                if (response) {
                    trackPixelEvent('Add new schedule invitation');
                    dispatch(addInvitations(response?.data?.data) as any);
                    setLoading(false);
                    setInviteMembers([]);
                    setFriendValue([]);
                }
            }
            catch (e) {
                console.log("e", e);
                setLoading(false);
            }
        }
    }

    const openModalRemove = (id: any) => {
        setSelectedId(id)
        setShowModalRemove(true);
    };

    const removeExistingMembers = () => {
        try {
            dispatch(removeInvitation(selectedId) as any)
                .then(() => {
                })
        }
        catch (e) {
            console.log(e);
        }
    }

    const fetchUserList = async (username: string) => {
        if (username.length < 3) {
            // Return an empty array if username length is less than 3
            return [];
        }
        try {
            // Call userFind with the username
            const { data } = await findFriends(username);

            // Map the results to OptionType with custom JSX label
            const options = data?.data.map((user: { name: string; city: string; profile_pic: objectType; email: string; phone_no: string }, index: number) => {
                let labelJSX = (
                    <React.Fragment key={index}>
                        <div className="flex">
                            <img
                                alt={user.name + index}
                                src={user.profile_pic !== null ? process.env.REACT_APP_API_URL + user.profile_pic?.url : no_user_pic}
                                className="mr-6 mt-1 rounded-[20px] border border-solid border-gray-500 w-8 h-8"
                            />
                            <div>
                                <h3>{user.name !== null ? user.name : "-----"}</h3>
                                <SubText1>{user.city !== null ? user.city : "-----"}</SubText1>
                            </div>
                        </div>
                    </React.Fragment>
                );
                return {
                    title: user.name,
                    label: labelJSX,
                    value: user.email !== null && user.email !== "" ? user.email : user.phone_no ? user.phone_no : "",
                };
            });
            return options;
        } catch (error) {
            console.error('Error fetching users:', error);
            // Return an empty array in case of an error
            return [];
        }
    }

    const onChange = (newValue: any[]) => {

        const lastValue = newValue.filter(item => !friendValue.includes(item));
        const filterSponsors = scheduleEmails?.filter((data: any) => data?.attributes?.member === lastValue[0]);

        if (filterSponsors?.length > 0) {
            return showAlert("", "This invited member already exists.", "OK");
        }
        setFriendValue(newValue);
        // Identify values to be added to inviteEmails
        const valuesToAdd = newValue.filter(item => !inviteMembers.includes(item));

        // Identify values to be removed from newValue that are already present in inviteEmails
        const valueToRemove = newValue.filter(item => inviteMembers.includes(item));

        // Update inviteEmails by concatenating valuesToAdd with inviteEmails and removing valuesToRemoveFromNewValue
        setInviteMembers([...inviteMembers, ...valuesToAdd.filter(item => !valueToRemove.includes(item))]);

        if (friendValue.length > newValue.length) {
            // Identify values lastly removed from newValue
            const lastRemovedValues = friendValue.filter(item => !newValue.includes(item));
            if (lastRemovedValues.length > 0) {
                const inviteList = [...inviteMembers];
                const itemIndex = inviteList.findIndex((val) => val === lastRemovedValues[0]);
                if (itemIndex !== -1) {
                    inviteList.splice(itemIndex, 1);
                    // Update the state with the modified arrays
                    setInviteMembers(inviteList);
                }
            }
        }
    };

    return (
        <div className="screen">

            <TopHead
                title="Invited Foodies"
                leftTextIcon={faAngleLeft}
                leftIconStyle={{ fontSize: "24px" }}
            />
            <Divider />
            <div className="grow overflow-auto">

                {/* ********************* Search Friends Section ******************************* */}
                <InitialDiv className="mx-4 mt-4">
                    <TextHead3>
                        Search for Friend
                    </TextHead3>
                    <FontAwesomeIcon icon={faSearch} className="relative top-[32px] left-[10px] z-10" />
                    <DebouncedSelect
                        mode="multiple"
                        value={friendValue}
                        placeholder={"Name, phone, or email"}
                        fetchOptions={fetchUserList}
                        onChange={(newValue) => onChange(newValue)}
                        style={{ width: "100%", paddingLeft: "22px", border: "1px solid #7c7c7c69", borderRadius: "10px", marginBottom: "20px", background: `${isDarkMode ? "black" : "#FBFBFB"}` }}
                        allowClear
                        showSearch
                        size="large"
                        bordered={false}
                        className={isDarkMode ? "time-selector multiple-time-selector" : ""}
                        optionLabelProp="title"
                    />
                </InitialDiv>
                <SubText1 className="!font-bold text-center !text-green-600">OR</SubText1>
                <Section className="!mt-[-8px]">
                    <div className="flex justify-between items-center">
                        <TextHead3 style={{ margin: "15px 0px" }}>Invite By Email/Phone </TextHead3>
                    </div>
                    <InputLabel className="mb-1">Email/Phone</InputLabel>
                    <FlexBetween className="items-center">
                        <InputText
                            placeholder="Enter Email or Phone"
                            style={{
                                border: sponsorContactError ? "1px solid red" : "",
                            }}
                            name="email"
                            maxLength={(inputLength) ? 10 : 50}
                            value={sponsorContact}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                        >
                        </InputText>

                        <ButtonGreen
                            className={"ml-2 h-10 !w-2/5"}
                            disabled={loading}
                            onClick={addInvite}>Add
                        </ButtonGreen>
                    </FlexBetween>
                </Section>
                <Section>
                    {(inviteMembers?.length > 0) && <InputLabel>New Invited Members</InputLabel>}
                    {(inviteMembers?.length > 0) ?
                        inviteMembers?.map((item, index: number) =>
                            <FlexBetween className="items-center my-3" key={index}>
                                <TextHead5>{item}</TextHead5>
                                <TextHead5 className="text-red-500 underline cursor-pointer" onClick={() => removeEmail(index, item)}>Remove</TextHead5>
                            </FlexBetween>
                        )
                        :
                        ""
                    }
                </Section>
                <Section>
                    {(scheduleEmails?.length > 0) && <InputLabel>Existing Invited Members</InputLabel>}
                    {(scheduleEmails?.length > 0) ?
                        scheduleEmails?.map((item: any, index: any) =>
                            <FlexBetween className="items-center my-3" key={index}>
                                <TextHead5>{item?.attributes?.member}<span className={`${(item?.attributes?.status !== "pending") ? `${(item?.attributes?.status === "accepted") ? "text-green-500" : "text-red-500"}` : "text-orange-500"}`}> ({switchSentenceCase("head text", item?.attributes?.status)})</span></TextHead5>
                                {(item?.attributes?.status !== "accepted") && <TextHead5 className="text-red-500 underline cursor-pointer" onClick={() => openModalRemove(item?.id)}>Remove</TextHead5>}
                            </FlexBetween>
                        )
                        :
                        ""
                    }
                </Section>
                <Section>
                    {
                        loading
                            ?
                            <div className="text-center">
                                <Spinner />
                            </div>
                            :
                            // <ButtonGreen
                            //     className={!loading ? "ml-2 h-10 !w-2/5" : "ml-2 h-10 !w-2/5 !cursor-not-allowed"}
                            //     disabled={loading}
                            //     onClick={sendInvite}>{loading ? <Spinner /> : "Invite"}
                            // </ButtonGreen>
                            <ButtonGreen className="h-10" onClick={sendInvite}>Invite</ButtonGreen>
                    }
                </Section>

                {/* ---------------------------------Modal Confirm Delete Popup------------------- */}
                <ModalRemoved
                    firstText="Are you sure you want to"
                    secondText="remove this Invitation?"
                    btnOneText="OOPS!  NEVER MIND..."
                    btnTwoText="YES, REMOVE IT"
                    showModal={showModalRemove}
                    setShowModal={setShowModalRemove}
                    deleteItem={removeExistingMembers}
                    id={selectedId}
                />
            </div>
        </div >
    );
}