import { faCamera } from "@fortawesome/pro-regular-svg-icons";
import { faAngleLeft, faChevronLeft } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IonContent, IonRefresher, IonRefresherContent, RefresherEventDetail, useIonAlert, useIonViewDidEnter } from "@ionic/react";
import { useEffect, useState } from "react";
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { useHistory } from "react-router";
import OtpInput from "react18-input-otp";
import styled from "styled-components";
import loader from "../../components/assets/loading.svg";
import exProfileImg from "../../components/assets/profile/no-profile-bg.jpg";
import useDarkMode from "../../components/hooks/darkmode/DarkMode";
import { ButtonGreen, Flex, FlexBetween, InitialDiv, InputLabel, InputText, SubText1, TextHead3, TextHead5 } from "../../components/styled/styled";
import Divider from "../../components/widgets/divider/Divider";
import TopHead from "../../components/widgets/head/TopHead";
import InitialModalCenter from "../../components/widgets/modals/InitialModalCenter";
import ModalSuccess from "../../components/widgets/modals/ModalSuccess";
import Section from "../../components/widgets/section/Section";
import Spinner from "../../components/widgets/spinner/Spinner";
import { changeEmailPhone, getUserData, getUserInfo, setUserData, userProfileUpdate, verifyEmailPhone } from "../../services/user";
import { imageUploader } from "../../utils/imageUploader";
import { countryCodeFilter, displayEmail, displayFullName, displayNumber, isAuthenticated, maxUserCharLimit, removeMask, sleep, switchSentenceCase, textToNumberFilter, trackPixelEvent, validateEmail, validatePhone } from "../../utils/utilities";

const HeadText = styled.h5`
  text-align: left;
  color: #868686;
`;

export default function UserProfileDetails() {

  const user_email = displayEmail();
  const user_phone = displayNumber();
  const isDarkMode = useDarkMode();
  const [presentAlert] = useIonAlert();
  const history = useHistory();

  const [userName, setUserName] = useState(displayFullName);
  const [userNameError, setUserNameError] = useState(false);
  const [userEmail, setUserEmail] = useState(displayEmail);
  const [userEmailError, setUserEmailError] = useState(false);
  const [userPhoneNo, setUserPhoneNo] = useState(displayNumber);
  const [userPhoneNoError, setUserPhoneNoError] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [prevProfilePic, setPrevProfilePic] = useState("");
  const [image, setImage] = useState([]);
  const [userImageLoading, setUserImageLoading] = useState(true);
  const [showChangeModal, setShowChangeModal] = useState(false);
  const [type, setType] = useState("");
  const [awaiting, setAwaiting] = useState(false);
  const [apiResponse, setApiResponse] = useState("");
  const [showVerify, setShowVerify] = useState(false);
  const [isRefreshed, setIsRefreshed] = useState(false);
  const [responseData, setResponseData] = useState<any>({});

  const [otp, setOtp] = useState("");
  const [otpError, setOtpError] = useState("");
  const [resendWait, setResendWait] = useState(false);
  const [seconds, setSeconds] = useState(0);
  const time = 30;


  const fetchingUserData = async () => {
    if (isAuthenticated() === false) return false;
    const user = await getUserInfo();
    setPrevProfilePic(`${(user?.profile_pic?.url) ? (`${process.env.REACT_APP_API_URL}${user?.profile_pic?.url}`) : ""}`);
  }

  // *********************** First time redering ********************************

  useEffect(() => {
    fetchingUserData()
  }, [isRefreshed]);

  useIonViewDidEnter(() => {
    trackPixelEvent('Profile Details');
  }, []);


  function handleRefresh(event: CustomEvent<RefresherEventDetail>) {
    setTimeout(() => {
      setIsRefreshed(prev => !prev);
      event.detail.complete();
    }, 2000);
  }

  // User Name Validation
  const _setUserName = (value: any) => {
    setUserName(value);
    if (String(value).trim()?.length < 3) {
      setUserNameError(true);
    } else {
      setUserNameError(false);
    }
  };


  // Email Validation
  const _setUserEmail = (value: any) => {
    setUserEmail(textToNumberFilter(value));
    const validEmail = validateEmail(textToNumberFilter(value));
    if (String(value).trim()?.length === 0 || !validEmail) {
      setUserEmailError(true);
    } else {
      setUserEmailError(false);
    }
  };

  // user Phone Number Validation
  const _setUserPhoneNo = (value: any) => {
    if (value === "" || value === undefined) {
      setUserPhoneNoError(true);
    } else {
      setUserPhoneNoError(false);
    }
    setUserPhoneNo(value)
  };

  const onChangeProfilePic = async (value: any) => {
    setUserImageLoading(true);
    setAwaiting(true);
    const file = value[0];
    if (file?.size < 5000000 && (file?.type === "image/jpeg" || file?.type === "image/png" || file?.type === "image/heif")) {
      if (file) {
        const image = await imageUploader(file);
        setImage(image);
        setPrevProfilePic(URL.createObjectURL(file) as any);
        setUserImageLoading(false);
        setAwaiting(false);
      }
    } else {
      presentAlert({
        header: '',
        message: 'Image must be jpeg/png. Size would be maximum 5mb.',
        buttons: ['OK'],
      })
      setUserImageLoading(false);
      setAwaiting(false);
    }
  };

  // on Updating User Data
  const onHandleSubmit = async () => {
    if (userName.trim()?.length < 3) {
      setUserNameError(true);
    } else {
      setUserNameError(false);
    }
    if (
      userName.trim() === "" ||
      userNameError
    ) {
      return false;
    }
    const data = {
      "name": userName,
    };

    if (image?.length > 0) {
      data['profile_pic'] = image[0]?.id
    }

    const localData = await getUserData();
    await userProfileUpdate(data).then(async (res) => {
      if (res.status === 200) {
        trackPixelEvent('User profile update');
        const newUpdatedUserInfo = {
          ...localData,
          user: ({
            ...localData.user,
            "name": res?.data?.name,
            "profile_pic": res?.data?.profile_pic
          })
        };
        await setUserData(newUpdatedUserInfo);
        setShowModal(true);
      }
    });
  };
  const GotoHandle = () => {
    history.push("/profile");
  };

  const openChangeModal = (name: any) => {
    setType(name);
    setShowChangeModal(prev => !prev)
  };


  const updateEmailPhone = async (e: any) => {
    try {
      setApiResponse("")
      // Validation-----------------------

      if (type === "email") {
        const validEmail = validateEmail(userEmail);
        if (userEmail.trim()?.length === 0 || !validEmail) {
          setUserEmailError(true);
          return false;
        } else {
          setUserEmailError(false);
        }
      }
      else if (type === "phone") {
        const validPhoneNo = validatePhone(userPhoneNo);
        if (userPhoneNo === "" || userPhoneNo === undefined || !validPhoneNo) {
          setUserPhoneNoError(true);
          return false;
        } else {
          setUserPhoneNoError(false);
        }
      };

      if (userEmailError || userPhoneNoError) {
        return false;
      };

      const data = {
        "contact": (type === "email") ? userEmail.toLowerCase() : removeMask(countryCodeFilter(userPhoneNo)),
        "provider": (type === "email") ? "email" : "phone"
      };


      // Api Call--------------------------
      setAwaiting(true);
      await changeEmailPhone(data)
        .then(async (response: any) => {
          trackPixelEvent('Chnage email phone');
          let data = response?.data;
          if (data.status === "pending") {
            setAwaiting(false);
            setResponseData(data);
            setShowVerify(true);
          }
        });
    } catch (error) {
      setAwaiting(false);
      const errMsg = error?.response?.data?.error?.message || "";
      setApiResponse(errMsg)
    }
  };

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      if (showVerify) {
        verify(e);
      }
      else {
        updateEmailPhone(e);
      }
    }
  };

  const verify = async (e: any) => {
    // Validation-----------------------

    if (otp === "") {
      setOtpError("Please Enter OTP First !");
    } else if (otp?.length < 6 || otp?.length > 6) {
      setOtpError("OTP Should Contain 6 Digits !");
    } else if (otp?.length === 6) {
      setOtpError("");

      // Api Call--------------------------
      try {
        setAwaiting(true);
        const data = {
          "contact": responseData?.contact,
          "otp": otp,
          "provider": responseData?.provider
        }

        const localData = await getUserData();
        await verifyEmailPhone(data)
          .then(async (response: any) => {
            trackPixelEvent('Verify email phone');
            let data = response?.data;
            if (response.status === 200) {
              const newUpdatedUserInfo = {
                ...localData,
                user: ({
                  ...localData.user,
                  "email": data?.email,
                  "phone_no": data?.phone_no,
                })
              };
              await setUserData(newUpdatedUserInfo);
              setResponseData({});
              setShowVerify(false);
              setShowChangeModal(false);
              setAwaiting(false);
              setOtp("");
            };
          });
      } catch (error) {
        setAwaiting(false);
        const err = error?.response?.data?.error?.message;
        if (String(err).toLowerCase().includes("invalid")) {
          // setOtpError(err);
          setOtpError("Code is invalid. Try again or →");
        }
      };
    };
  };

  // Timer for user to wait between resending otp.
  const startCountdown = async () => {
    setResendWait(true);
    for (let i = time; i >= 0; i--) {
      await sleep(1000)
      setSeconds(i);
      if (i === 0) {
        setResendWait(false);
        break
      }
    }
  }

  const resendOtp = async (e: any) => {
    try {
      if (awaiting || resendWait) { return false; }
      startCountdown();
      setOtp("");
      setOtpError("");

      const data = {
        "contact": (type === "email") ? userEmail.toLowerCase() : removeMask(countryCodeFilter(userPhoneNo)),
        "provider": (type === "email") ? "email" : "phone"
      }

      // Api Call--------------------------
      setAwaiting(true);
      await changeEmailPhone(data)
        .then(async (response: any) => {
          trackPixelEvent('Chnage email phone');
          let data = response?.data;
          if (data.status === "pending") {
            setAwaiting(false);
            setResponseData(data);
            setShowVerify(true);
          }
        })
    } catch (error) {
      setAwaiting(false);
      const errMsg = error?.response?.data?.error?.message || "";
      setApiResponse(errMsg)
    }
  };

  const closeModal = () => {
    setShowChangeModal((prev: any) => !prev);
    setShowVerify(false);
    setResponseData({});
  };

  return (
    <div className="screen">
      <TopHead
        title="Profile Settings"
        leftTextIcon={faAngleLeft}
        leftIconStyle={{ fontSize: "24px" }}
        goToHandle={GotoHandle}
      />
      <Divider />

      {/* <div className="grow overflow-auto"> */}
      <IonContent>
        <IonRefresher slot="fixed" onIonRefresh={handleRefresh}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>
        <Section align="center">
          <img
            alt="User Profile"
            className="inline w-36 h-36 rounded-full"
            onLoad={() => setUserImageLoading(false)}
            src={(userImageLoading) ? loader : prevProfilePic ? prevProfilePic : exProfileImg}
            // src={prevProfilePic ? prevProfilePic : exProfileImg}
            style={{ objectFit: "cover" }}
          />
          <FontAwesomeIcon
            icon={faCamera}
            style={{
              background: "#22a45d",
              color: "white",
              padding: "15px",
              borderRadius: "25px",
              position: "relative",
              top: "60px",
              right: "50px",
              cursor: "pointer"
            }}
          />

          <input
            type="file"
            accept="image/*"
            className="input-image-field cursor-pointer"
            onChange={(e) => onChangeProfilePic(e.target.files)}
          />
          {/* <SubText1 className="!text-red-600 mt-[-15px] mb-[15px]">{imageError}</SubText1> */}

          <HeadText>Full Name</HeadText>
          {/* <SubText1 className="text-right mt-[20px]">{userName?.length}/{maxUserCharLimit}</SubText1> */}
          <InputText
            type="text"
            placeholder="Joe Doe"
            maxLength={maxUserCharLimit}
            style={{
              marginTop: "10px",
              border: userNameError ? "1px solid red" : "",
            }}
            name="username"
            value={userName}
            onChange={(event) => _setUserName((event.target.value))}
          />

          <FlexBetween style={{ marginTop: "20px" }}>
            <HeadText >Email Address:</HeadText>
            <TextHead5 className="text-red-600 cursor-pointer" onClick={() => openChangeModal("email")}>{(user_email) ? "Change Email" : "Add Email"}</TextHead5>

          </FlexBetween>
          {(user_email) &&
            <InputText
              type="email"
              placeholder="jdoe122@icloud.com"
              style={{
                marginTop: "10px",
                backgroundColor: isDarkMode ? "#1c1c1d" : "#e1e1e159",
                cursor: "not-allowed"
              }}
              name="useremail"
              value={user_email}
              onChange={(event) => _setUserEmail(event.target.value)}
              readOnly
            />
          }

          <FlexBetween style={{ marginTop: "20px" }}>
            <HeadText >Phone Number:</HeadText>
            <TextHead5 className="text-red-600 cursor-pointer" onClick={() => openChangeModal("phone")}>{(user_phone) ? "Change Number" : "Add Number"}</TextHead5>
          </FlexBetween>
          {(user_phone) &&
            <InputText
              type="text"
              style={{
                marginTop: "10px",
                backgroundColor: isDarkMode ? "#1c1c1d" : "#e1e1e159",
                cursor: "not-allowed"
              }}
              name="phoneno"
              value={user_phone}
              placeholder="+1501333982"
              onChange={(event) => _setUserPhoneNo(event.target.value)}
              readOnly
            />
          }

          <ButtonGreen style={{ marginTop: "20px" }}
            className={awaiting ? `!bg-[#22a45d85]` : ""}
            disabled={awaiting}
            onClick={onHandleSubmit}>
            {switchSentenceCase("primary button", "UPDATE PROFILE")}
          </ButtonGreen>

          <ModalSuccess
            contentDivStyle={{ height: "auto" }}
            firstText={switchSentenceCase(
              "primary button",
              "Profile Updated Successfully"
            )}
            btnOneText={switchSentenceCase("primary button", "OKAY")}
            btnOneGoto={() =>
              history.push({
                pathname: "/profile",
              })
            }
            showModal={showModal}
          />

          {/* ********************************** Change Email and verify Modal********************************************** */}
          {
            (showChangeModal)
            &&
            <InitialModalCenter
              showModal={showChangeModal}
              setShowModal={setShowChangeModal}
            >
              <InitialDiv className="m-4">
                <Flex className="items-center">
                  <FontAwesomeIcon
                    className="cursor-pointer"
                    icon={faChevronLeft}
                    onClick={() => closeModal()}
                  />
                  <InitialDiv className="w-full text-center">
                    <TextHead3 className="">{(type === "email") ? `${(user_email) ? "Change Email" : "Add Email"}` : `${(user_phone) ? "Change Number" : "Add Number"}`}</TextHead3>
                  </InitialDiv>
                </Flex>
              </InitialDiv>
              <Divider />

              <Divider />
              <InitialDiv className="m-4">
                <InitialDiv className="text-left">
                  {(!showVerify) ?
                    <>
                      {(type === "phone") &&
                        <Section>
                          <HeadText >Enter Your Phone Number</HeadText>
                          <PhoneInput
                            className={isDarkMode ? "country-selector" : ""}
                            international
                            countryCallingCodeEditable={false}
                            defaultCountry="US"
                            name="phoneno"
                            value={userPhoneNo}
                            placeholder="e.g.+1 501 333 9827"
                            limitMaxLength={true}
                            onChange={(event) => _setUserPhoneNo(event)}
                            style={{
                              marginTop: "10px",
                              border: userPhoneNoError ? "1px solid red" : "",
                            }}
                          />
                        </Section>
                      }
                      {
                        (type === "email") &&
                        <Section>
                          <HeadText >Enter Your Email</HeadText>
                          <InputText
                            type="email"
                            placeholder="jdoe122@icloud.com"
                            style={{
                              marginTop: "10px",
                              border: userEmailError ? "1px solid red" : "",
                            }}
                            name="useremail"
                            value={userEmail}
                            onChange={(event) => _setUserEmail(event.target.value)}
                          />
                        </Section>
                      }
                    </>
                    :
                    <>
                      <Section>
                        <FlexBetween>
                          <InputLabel className="mb-[10px]">{`Enter code sent to ${responseData?.contact}`}
                          </InputLabel>
                        </FlexBetween>
                        <div>
                          <form onKeyDown={handleKeyDown}>
                            <OtpInput
                              value={otp}
                              shouldAutoFocus={true}
                              onChange={setOtp}
                              numInputs={6}
                              isInputNum={true}
                              separator={<span>-</span>}
                              containerStyle="justify-center"
                              inputStyle="bg-transparent !w-10 h-10 border m-[0.2rem]"

                            />
                          </form>
                        </div>
                        <FlexBetween>
                          {otpError ? <p className="mt-2 text-rose-800">{otpError}</p> : <p></p>}
                          <p className="mt-2 text-red-600">
                            <p onClick={resendOtp} style={{ color: ((seconds > 0) ? "grey" : "") }}>Resend Code {(seconds > 0) ? `(in ${seconds}s)` : ""}</p>
                          </p>
                        </FlexBetween>
                      </Section>
                    </>
                  }
                  <Section>
                    {(awaiting === false)
                      ?
                      <ButtonGreen
                        className="mt-[15px]"
                        onClick={(showVerify) ? verify : updateEmailPhone}
                      >
                        {switchSentenceCase("primary button", "CONTINUE")}
                      </ButtonGreen>
                      :
                      <div className="text-center">
                        <Spinner />
                      </div>
                    }
                    {(apiResponse.length > 0)
                      &&
                      <SubText1 className="text-center mt-5">
                        <label className="text-red-600 mt-5">
                          {apiResponse}
                        </label>
                      </SubText1>
                    }
                  </Section>
                </InitialDiv>
              </InitialDiv>
            </InitialModalCenter>
          }
        </Section>
      </IonContent>
      {/* <Divider /> */}
      {/* </div> */}
    </div>
  );
}
