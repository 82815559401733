import React, { useEffect, useState } from "react";
import TopHead from "../../components/widgets/head/TopHead";
import Divider from "../../components/widgets/divider/Divider";
import { ButtonGray, ButtonGreen, ButtonInitial, ButtonLimeGreen, ButtonLiteGreen, Flex, FlexAround, FlexBetween, InitialDiv, InputText, SubText1, TextHead3, TextHead4, TextHead5 } from "../../components/styled/styled";
import { countryCodeFilter, handleNetworkError, handleRequestError, handleResponse, removeMask, switchSentenceCase, textToNumberFilter, trackPixelEvent, validateEmail, validatePhone } from "../../utils/utilities";
import { faAngleDown, faAngleLeft, faBell, faCheck, faChevronLeft, faSearch, faUserPlus } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Select } from "antd";
import no_user_pic from "../../components/assets/profile/no-profile-photo.png";
import { DynamicTab, DynamicToggleTabs } from "../../components/widgets/toggleable-tabs/DynamicToggleTabs";
import RadioButtonText from "../../components/widgets/buttons/RadioButtonText";
import { useHistory } from "react-router";
import { getUserInfo, updateUser, userFind } from "../../services/user";
import { updateInvitationData, deleteFriends, getAllFriendRequestsStatus, getMyContacts, getMyExistedFriend, getMyFriendRequests, importContacts, importFacebookFriends, sendInvitation } from "../../services/friends";
import exProfileImg from "../../components/assets/profile/no-profile-photo.png";
import { useDispatch } from "react-redux";
import { calendarRecipientData, createScheduleForMeClear, createScheduleForMeStep1 } from "../schedules/redux/createScheduleReduxActions";
import { setOrderForSomeone } from "../orders/redux/upcomingOrderActionCreator";
import Spinner from "../../components/widgets/spinner/Spinner";
import ModalConfirmationForCards from "../../components/widgets/modals/ModalConfirmationForCards";
import { IonContent, IonRefresher, IonRefresherContent, RefresherEventDetail, useIonAlert, useIonViewDidEnter } from "@ionic/react";
import NoDataFound from "../../components/widgets/nodatafound/NoDataFound";
import useDarkMode from "../../components/hooks/darkmode/DarkMode";
import styled from "styled-components";
import { setCalendarData } from "../../services/schedule";
import { Button, Dropdown } from 'antd';
import { faFacebook, faGoogle } from "@fortawesome/free-brands-svg-icons";
import { objectType } from "../../type";
import { GoogleAuth } from "@codetrix-studio/capacitor-google-auth";
import { FacebookLogin, FacebookLoginResponse } from '@capacitor-community/facebook-login';
import { FACEBOOK_PERMISSIONS } from '../../common/constant';
import ModalChooseOption from "../../components/widgets/modals/ModalChooseOption";
import InitialModalCenter from "../../components/widgets/modals/InitialModalCenter";
import Section from "../../components/widgets/section/Section";
import PhoneInput from 'react-phone-number-input';

const WrapChoice = styled.div`
  padding: 5px;
`;
const HeadText = styled.h5`
  text-align: left;
  color: #868686;
`;

const items = [
  {
    label: 'Google',
    key: '1',
    icon: <FontAwesomeIcon icon={faGoogle} />,
  },
  {
    label: 'Facebook',
    key: '2',
    icon: <FontAwesomeIcon icon={faFacebook} />,
  },
];


export default function Friends() {
  // *************************** Other Methods **************************************
  const history = useHistory();
  const API_URL = process.env.REACT_APP_API_URL;
  const user = getUserInfo();
  const dispatch = useDispatch();
  const isDarkMode = useDarkMode();
  const limit = 10;
  const [presentAlert] = useIonAlert();
  const showAlert = (header: string, message: string, btnMessage: string) => {
    presentAlert({
      header: header,
      message: message,
      buttons: [btnMessage],
    });
  };

  // ************************ Hooks ********************************************
  const [friendType, setFriendType] = useState("existing_friends");
  const [searchedFriendText, setSearchedFriendText] = useState("");
  const [usersData, setUsersData] = useState([]);
  const [myFriendRequest, setMyFriendRequest] = useState<any>([]);
  const [dataForCreateCalendar, setDataForCreateCalendar] = useState<any>([]);
  const [existedFriendData, setExistedFriendData] = useState([]);
  const [contactsData, setContactsData] = useState([]);
  const [allFriendRequestStatus, setAllFriendRequestStatus] = useState([]);
  const [toggleFriends, setToggleFriends] = useState("");
  const [totalForExisted, setTotalForExisted] = useState(0);
  const [totalForContacts, setTotalForContacts] = useState(0);
  const [totalForPending, setTotalForPending] = useState(0);
  const [skip, setSkip] = useState(0);
  const [loadingForFriends, setLoadingForFriends] = useState(false);
  const [loadingForSeeMore, setLoadingForSeeMore] = useState(false);
  const [awaiting, setAwaiting] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isRefreshed, setIsRefreshed] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [selectedData, setSelectedData] = useState<objectType>({});
  const [showOptionModal, setShowOptionModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [apiResponse, setApiResponse] = useState("");
  const [friendEmail, setFriendEmail] = useState("");
  const [friendPhoneNo, setfriendPhoneNo] = useState("");
  const [friendEmailError, setFriendEmailError] = useState(false);
  const [friendPhoneError, setFriendPhoneError] = useState(false);

  // Email Validation
  const handleFriendEmail = (value: any) => {
    setApiResponse("");
    setFriendEmail(textToNumberFilter(value));
    const validEmail = validateEmail(textToNumberFilter(value));
    if (String(value).trim()?.length === 0 || !validEmail) {
      setFriendEmailError(true);
    } else {
      setFriendEmailError(false);
    }
  };

  // user Phone Number Validation
  const handleFriendPhone = (value: any) => {
    setApiResponse("");
    if (value === "" || value === undefined) {
      setFriendPhoneError(true);
    } else {
      setFriendPhoneError(false);
    }
    setfriendPhoneNo(value)
  };

  const updateFriendEmailPhone = async (e: any) => {
    try {
      setApiResponse("");
      const friendValidEmail = validateEmail(friendEmail);
      const friendValidPhoneNo = validatePhone(removeMask(friendPhoneNo));

      if (friendEmail?.length === 0 && friendPhoneNo?.length === 0) {
        setApiResponse("Either Phone or Email is Required.");
        return false
      }
      if (friendEmail?.length > 0 && !friendValidEmail) {
        setFriendEmailError(true);
        return false
      } else {
        setFriendEmailError(false);
      }

      if (friendPhoneNo?.length > 0 && !friendValidPhoneNo) {
        setFriendPhoneError(true);
        return false
      } else {
        setFriendPhoneError(false);
      }

      setAwaiting(true);
      let update_data = {};
      if (friendEmail?.length > 0 && friendValidEmail) {
        update_data = {
          email: friendEmail.toLowerCase()
        }
      }

      if (friendPhoneNo?.length > 0 && friendValidPhoneNo) {
        update_data = {
          phone_no: removeMask(countryCodeFilter(friendPhoneNo))
        }
      }
      const friendData = (user?.id === selectedData?.attributes?.to_id?.data?.id) ? selectedData?.attributes?.from_id?.data : selectedData?.attributes?.to_id?.data;

      await updateUser(update_data, friendData?.id).then(async (response: any) => {
        let data = response?.data;
        if (response.status === 200) {
          trackPixelEvent("Update user");
          handleSendInvitation(selectedData);
          setAwaiting(false);
          setShowModal(false);
        }
      });
    } catch (error) {
      setAwaiting(false);
      const errMsg = error?.response?.data?.error?.message || "";
      setApiResponse(errMsg);
    }
  };
  useEffect(() => {
    GoogleAuth.initialize();
  }, []);

  const handleMenuClick = async (e: objectType) => {
    if (e.key === "1") {
      try {
        setLoading(true);
        const data = await GoogleAuth.refresh();
        if (data.accessToken) {
          const payloadData = {
            token: data.accessToken,
            userId: user?.id
          };
          const response = await importContacts(payloadData);
          if (response?.data?.status === true) {
            // handleGetContacts();
            setFriendType("other_contacts");
            setLoading(false);
          }
        }
      } catch (error) {
        try {
          const result = await GoogleAuth.signIn();
          if (result.authentication.accessToken) {
            const payloadData = {
              token: result.authentication.accessToken,
              userId: user?.id
            };
            const response = await importContacts(payloadData);
            if (response?.data?.status === true) {
              // handleGetContacts();
              setFriendType("other_contacts");
              setLoading(false);
            }
          }
        } catch (signInError) {
          // Handle sign-in error
          setLoading(false);
          return showAlert('Error', 'Importing failed. Please try again.', 'OK');
        }
      }
    }
    else if (e.key === "2") {
      try {
        setLoading(true);
        const token: FacebookLoginResponse = await FacebookLogin.getCurrentAccessToken();
        if (token.accessToken.token) {
          const payloadData = {
            token: token.accessToken.token,
            userId: user?.id
          };
          const response = await importFacebookFriends(payloadData);
          if (response?.data?.status === true) {
            // handleGetContacts();
            setFriendType("other_contacts");
            setLoading(false);
          }
        }
      } catch (error) {
        try {
          const result = await FacebookLogin.login({ permissions: FACEBOOK_PERMISSIONS });
          if (result?.accessToken?.token) {
            const payloadData = {
              token: result?.accessToken?.token,
              userId: user?.id
            };
            const response = await importFacebookFriends(payloadData);
            if (response?.data?.status === true) {
              // handleGetContacts();
              setFriendType("other_contacts");
              setLoading(false);
            }
          }
        } catch (signInError) {
          // Handle sign-in error
          setLoading(false);
          return showAlert('Error', 'Importing failed. Please try again.', 'OK');
        }
      }
    }
  };

  const menuProps = {
    items,
    onClick: handleMenuClick,
  };

  // ******************* Rendering ***************************************
  useEffect(() => {
    setLoadingForFriends(true);
    if (friendType === "existing_friends") {
      handleExistedFriend();
    } else if (friendType === "pending_friends") {
      handlePendingFriend();
    }
    else {
      handleGetContacts();
    }
  }, [isRefreshed, friendType]);

  useEffect(() => {
    getFriendRequestNotification();
  }, [isRefreshed]);

  const handleChangeFriendType = (val: string) => {
    setFriendType(val);
    setSkip(0);
  }

  useIonViewDidEnter(() => {
    trackPixelEvent('Add Friend');
  }, []);


  // ****************** Functions *************************************
  function handleRefresh(event: CustomEvent<RefresherEventDetail>) {
    setTimeout(() => {
      setIsRefreshed(prev => !prev);
      event.detail.complete();
    }, 2000);
  };


  const getFriendRequestNotification = async () => {
    try {
      // setLoading(true);
      const friendRequestsResponse = await getMyFriendRequests();  //Calling Friend Request api---
      const friendRequestsData = handleResponse(friendRequestsResponse);
      trackPixelEvent('Get my friend requests');
      setMyFriendRequest(friendRequestsData?.data[0]);
      // setLoading(false);
    } catch (error) {
      // setLoading(false);
      if (error.response) {
        handleResponse(error.response); // Server responded with a non-2xx status code
      } else if (error.request) {
        handleRequestError(error.request);  // The request was made but no response was received
      } else {
        handleNetworkError(error); // Network error or something else happened
      }
    }
  };

  const handleExistedFriend = async (skippingValue = 0) => {
    try {
      const existedFriendResponse = await getMyExistedFriend(skippingValue, limit); //Calling Existing friends api---
      const response = handleResponse(existedFriendResponse);
      trackPixelEvent('Get my existing friends');
      setTotalForExisted(response?.meta?.pagination?.total);
      let tempData = response?.data;

      if (skippingValue > 0) {
        setExistedFriendData([...existedFriendData, ...tempData]);
      }
      else {
        setExistedFriendData(response?.data);
      }
      setLoadingForFriends(false);
      setLoadingForSeeMore(false);
    } catch (error) {
      setLoadingForFriends(false);
      setLoadingForSeeMore(false);
      if (error.response) {
        handleResponse(error.response); // Server responded with a non-2xx status code
      } else if (error.request) {
        handleRequestError(error.request);  // The request was made but no response was received
      } else {
        handleNetworkError(error); // Network error or something else happened
      }
    }
  };

  const showMore = () => {
    const skippingValue = skip + limit
    setSkip(skippingValue);
    setLoadingForSeeMore(true);
    if (friendType === "existing_friends") {
      handleExistedFriend(skippingValue);
    }
    else if (friendType === "pending_friends") {
      handlePendingFriend(skippingValue);
    }
    else {
      handleGetContacts(skippingValue);
    }
  };

  const handlePendingFriend = async (skippingValue = 0) => {
    try {
      const allFriendStatusResponse = await getAllFriendRequestsStatus(skippingValue, limit); //Calling All friend Requests(Send by me , Recieved from others Requests) Status api---
      const allFriendStatusData = handleResponse(allFriendStatusResponse);
      trackPixelEvent('Get All Requests Status');
      setTotalForPending(allFriendStatusData?.meta?.pagination?.total);
      let tempData = allFriendStatusData?.data;
      if (skippingValue > 0) {
        setAllFriendRequestStatus([...allFriendRequestStatus, ...tempData]);
      }
      else {
        setAllFriendRequestStatus(allFriendStatusData?.data);
      }
      setLoadingForSeeMore(false);
      setLoadingForFriends(false);
    } catch (error) {
      setLoadingForFriends(false);
      setLoadingForSeeMore(false);
      if (error.response) {
        handleResponse(error.response); // Server responded with a non-2xx status code
      } else if (error.request) {
        handleRequestError(error.request);  // The request was made but no response was received
      } else {
        handleNetworkError(error); // Network error or something else happened
      }
    }
  };

  const handleGetContacts = async (skippingValue = 0) => {
    try {
      const contacts_response = await getMyContacts(skippingValue, limit); //Calling Existing friends api---
      const response = handleResponse(contacts_response);
      trackPixelEvent('Get my contacts');
      setTotalForContacts(response?.meta?.pagination?.total);
      let tempData = response?.data;

      if (skippingValue > 0) {
        setContactsData([...contactsData, ...tempData]);
      }
      else {
        setContactsData(response?.data);
      }
      setLoadingForFriends(false);
      setLoadingForSeeMore(false);
    } catch (error) {
      setLoadingForFriends(false);
      setLoadingForSeeMore(false);
      if (error.response) {
        handleResponse(error.response); // Server responded with a non-2xx status code
      } else if (error.request) {
        handleRequestError(error.request);  // The request was made but no response was received
      } else {
        handleNetworkError(error); // Network error or something else happened
      }
    }
  };

  const handleGetUserFriends = async (e: string) => { // Finding friend lists from api On search.... || calling api when length > 3     
    setSearchedFriendText(e);
    if (e?.length >= 3) {
      setAwaiting(true);
      setUsersData([]);
      try {
        const { data } = await userFind(e);
        trackPixelEvent('User find');
        setUsersData(data?.data);
        setAwaiting(false);
      } catch (error) {
        setAwaiting(false);
        if (error.response) {
          handleResponse(error.response); // Server responded with a non-2xx status code
        } else if (error.request) {
          handleRequestError(error.request);  // The request was made but no response was received
        } else {
          handleNetworkError(error); // Network error or something else happened
        }
      }
      //if (e?.length <= 3) 
    } else {
      setUsersData([]);
    }
  };

  let options = [];
  options = usersData?.map((item: any, index: any) => (
    {
      id: item?.id,
      slug: item?.slug,
      value: (item?.name !== null ? item?.name + index : (item?.email !== null ? item?.email : item?.phone_no)),
      label: (
        <React.Fragment key={index}>
          <div className="flex">
            <img
              alt={item?.name + index}
              src={item?.profile_pic !== null ? API_URL + item?.profile_pic?.url : no_user_pic}
              className="mr-6 rounded-[20px] border border-solid border-gray-500 w-8 h-8" />
            <div>
              <h3>{item?.name !== null ? item?.name : "-----"}</h3>
              <SubText1>{item?.city !== null ? item?.city : "-----"}</SubText1>
            </div>
          </div>
        </React.Fragment>
      ),
    }))

  const onSelect = (val: any, option: any) => {
    history.push(`/friends-profile/${user?.slug ? user?.slug + `s` : user?.id + `d`}/${option?.slug ? option?.slug + `s` : option?.id + `d`}`);
  };

  const handleUpdate = async (id: number, friendName: any) => {
    const data = { data: { "status": "ACCEPTED" } }
    try {
      const res = await updateInvitationData(data, id); //Calling Friend Update api---
      const resData = handleResponse(res);
      if (resData) {
        trackPixelEvent('Update friends data');
        if (friendType === "existing_friends") {
          handleExistedFriend();
        }
        else {
          setFriendType("existing_friends");
        }
        getFriendRequestNotification();
        setSkip(0);
        return showAlert('Friend List Updated !', `${friendName} Added as Friend in Your Friend List!`, 'OK');
      }
      // setLoading(false);
    } catch (error) {
      // setLoading(false);
      if (error.response) {
        handleResponse(error.response); // Server responded with a non-2xx status code
      } else if (error.request) {
        handleRequestError(error.request);  // The request was made but no response was received
      } else {
        handleNetworkError(error); // Network error or something else happened
      }
    }
  };

  const handleDelete = async (data: objectType) => {
    try {
      let res: objectType
      if (data?.attributes?.contact_invitation) {
        const update_data = { data: { "status": "NULL" } }
        res = await updateInvitationData(update_data, data?.id); //Calling Friend Update api---
      }
      else {
        res = await deleteFriends(data?.id); //Calling Friend DELETE api---
      }
      const resData = handleResponse(res);
      if (resData) {
        trackPixelEvent('Delete friend');
        if (friendType === "pending_friends") {
          handlePendingFriend();
        }
        else {
          handleExistedFriend();
        }
        setSkip(0);
      }
      // setLoading(false);
    } catch (error) {
      // setLoading(false);
      if (error.response) {
        handleResponse(error.response); // Server responded with a non-2xx status code
      } else if (error.request) {
        handleRequestError(error.request);  // The request was made but no response was received
      } else {
        handleNetworkError(error); // Network error or something else happened
      }
    }
  };

  const handleContactsInvite = async (contactData: objectType) => {
    const friendData = (user?.id === contactData?.attributes?.to_id?.data?.id) ? contactData?.attributes?.from_id?.data : contactData?.attributes?.to_id?.data;
    setSelectedData(contactData);
    if (friendData?.attributes?.email === null && friendData?.attributes?.phone_no === null) {
      setShowModal(true);
    }
    else {
      handleSendInvitation(contactData);
    }
  };

  const handleSendInvitation = async (contactData: objectType) => {
    try {
      const data = {
        "status": "PENDING",
      };
      const res = await sendInvitation(contactData?.id, data);
      const resData = handleResponse(res);
      if (resData?.status === true) {
        trackPixelEvent('Friend invitation send');
        setSkip(0);
        setFriendType("pending_friends");
        setSelectedData({});
        return showAlert('Friend Invited !', 'Invitation Sent Successfully.', 'OK');
      }
    } catch (error) {
      if (error.response) {
        handleResponse(error.response); // Server responded with a non-2xx status code
      } else if (error.request) {
        handleRequestError(error.request);  // The request was made but no response was received
      } else {
        handleNetworkError(error); // Network error or something else happened
      }
    }
  };


  const closeModal = () => {
    setShowModal((prev: any) => !prev);
    setFriendPhoneError(false);
    setFriendEmailError(false);
    setfriendPhoneNo("");
    setFriendEmail("");
    setAwaiting(false);
    setSelectedData({})
    setApiResponse("");
  };


  const handleOnClickForOther = async () => {
    dispatch(createScheduleForMeClear() as any);
    dispatch(createScheduleForMeStep1("for_other") as any);
    let data = {
      step1: "for_other",
      recipientData: dataForCreateCalendar
    }
    calendarRecipientData(dataForCreateCalendar);
    await setCalendarData(data);
    trackPixelEvent('Calendar for someone else');
    history.push({
      pathname: '/schedules',
      state: dataForCreateCalendar
    })
  };


  const handleExistedFriendsToggle = (id: any, data: any) => {
    setToggleFriends(id);
    let data_forCalendar = {
      recipientName: data?.attributes?.name,
      recipientContact: data?.attributes?.phone_no,
      recipientId: data?.id,
      recipientEmail: data?.attributes?.email,
      addressId: data?.attributes?.address?.data ? data?.attributes?.address?.data?.id : "",
    };
    setDataForCreateCalendar(data_forCalendar)
  };

  const handleSubmit = async (value: string) => {
    if (value === "") {
      return showAlert('', 'Please select any one option.', 'OK');
    }
    else {
      const data = (user?.id === selectedData?.attributes?.to_id?.data?.id) ? selectedData?.attributes?.from_id?.data : selectedData?.attributes?.to_id?.data;
      let data_forOrder = {
        recipientName: data?.attributes?.name,
        recipientContact: data?.attributes?.phone_no,
        recipientId: data?.id,
        recipientEmail: data?.attributes?.email,
        addressId: data?.attributes?.address?.data ? data?.attributes?.address?.data?.id : "",
        shortNotes: "",
        type: "for_other"
      };
      setOrderForSomeone(data_forOrder);
      history.push(`/getfoodnow?type=${value}`)
    }
  }

  return (
    <div className="screen">
      <TopHead
        title="Friends"
        leftTextIcon={faAngleLeft}
        leftIconStyle={{ fontSize: "24px" }}
      />

      <Divider />
      {/* <div className="grow overflow-auto"> */}
      <IonContent>
        <IonRefresher slot="fixed" onIonRefresh={handleRefresh}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>
        <InitialDiv className={`m-5 ${friendType === "existing_friends" ? "pb-[145px]" : ""}`}>
          <FlexBetween className="items-baseline">
            {/* ********************* Search Friends Section ******************************* */}
            <TextHead3>
              Search for Friends
            </TextHead3>
            <InitialDiv>
              <Dropdown menu={menuProps}>
                <Button style={{ color: "#22a45d", borderColor: "#22a45d" }}>
                  Import Contacts <FontAwesomeIcon icon={faAngleDown} className="ml-2" />
                </Button>
              </Dropdown>
            </InitialDiv>
          </FlexBetween>
          <FontAwesomeIcon icon={faSearch} className="relative top-[32px] left-[10px] z-10" />
          <Select
            allowClear
            showSearch
            size="large"
            bordered={false}
            filterOption={false}
            options={options}
            loading={awaiting}
            placeholder={"Name, phone, or email"}
            onSearch={(e) => handleGetUserFriends(e)}
            onSelect={(val, option) => onSelect(val, option)}
            style={{ width: "100%", paddingLeft: "22px", border: "1px solid #7c7c7c69", borderRadius: "10px", marginBottom: "20px", background: `${isDarkMode ? "black" : "#FBFBFB"}` }}
            notFoundContent={
              !awaiting && searchedFriendText?.length > 2
              && <FlexBetween className={isDarkMode ? "bg-black items-center cursor-pointer" : "items-center cursor-pointer"} onClick={() => history.push(`/friendsinvite/${searchedFriendText}`)}>
                <InitialDiv>
                  <SubText1 className="m-2 !mb-[2px]">No Result Found</SubText1>
                  <h3 className={isDarkMode ? "mx-2 mb-2 text-white" : "mx-2 mb-2"}>Invite and add {searchedFriendText} ?</h3>
                </InitialDiv>
                <FontAwesomeIcon icon={faUserPlus} className="mr-3 text-green-600 cursor-pointer" />
              </FlexBetween>
            }
          />
          {
            (loading) ?
              <div className="text-center">
                <Spinner />
              </div>
              :
              <>
                {/* ********************* Requests Notifications Section ******************************* */}
                {
                  myFriendRequest?.attributes?.status === "PENDING"
                  &&
                  <InitialDiv className="my-5">
                    <Flex className="items-center">
                      <TextHead5 className="text-green-600 !font-semibold whitespace-nowrap !mr-2">NEW</TextHead5>
                      <FontAwesomeIcon icon={faBell} className="text-green-600 mr-2" />
                      <TextHead4 className="!font-normal leading-6"><span className="text-green-600" >New Friend Request</span> | {myFriendRequest?.attributes?.from_id?.data?.attributes?.name} wants to share faves and food!</TextHead4>
                    </Flex>
                    <InitialDiv className="mx-5 my-3 w-[300px]">
                      <FlexAround className="gap-3">
                        <ButtonGreen className="!rounded-[25px] h-[30px] !p-0" onClick={() => handleUpdate(myFriendRequest?.id, myFriendRequest?.attributes?.from_id?.data?.attributes?.name)}>+Accept</ButtonGreen>
                        <ButtonInitial className="text-[#979797] !p-0 !rounded-[25px] border border-#979797] border-solid h-[30px]" onClick={() => { setShowConfirmationModal(true); setSelectedData(myFriendRequest) }}>Cancel</ButtonInitial>
                      </FlexAround>
                    </InitialDiv>
                  </InitialDiv>
                }
                <Divider />

                {/* ******************* Existing Friends Section ************************************* */}
                <DynamicToggleTabs>
                  <DynamicTab label={"existing_friends"} tabName={"Existing Friends"} setOrderType={handleChangeFriendType} selectedTab={friendType}>
                    {loadingForFriends && skip === 0 &&
                      <div className="flex items-center justify-center">
                        <Spinner />
                      </div>
                    }
                    {existedFriendData?.length > 0 && !loadingForFriends
                      &&
                      existedFriendData?.map((friend: any, index: number) => {
                        return (
                          <React.Fragment key={index}>
                            {
                              friend?.attributes?.from_id?.data?.id === user?.id && friend?.attributes?.status === "ACCEPTED"
                                ?
                                <InitialDiv
                                  key={index}
                                  style={{
                                    boxShadow: "1px 3px 8px -1px #dcf0dccf",
                                    padding: "5px 15px",
                                    borderRadius: "7px",
                                    margin: "15px 0px",
                                    textAlign: "center"
                                  }}
                                >
                                  <InitialDiv className="text-left">
                                    <Flex className="items-center">
                                      <img
                                        alt=""
                                        className="inline w-[2rem] h-[2rem] rounded-full mr-2"
                                        src={friend?.attributes?.to_id?.data?.attributes?.profile_pic?.data !== null ? `${process.env.REACT_APP_API_URL}${friend?.attributes?.to_id?.data?.attributes?.profile_pic?.data?.attributes?.url}` : exProfileImg}
                                        style={{ objectFit: "cover" }} />
                                      <FlexBetween className="w-full">
                                        <InitialDiv>
                                          <TextHead4 className="mt-[15px]">{friend?.attributes?.to_id?.data?.attributes?.name}</TextHead4>
                                          <SubText1>{friend?.attributes?.to_id?.data?.attributes?.city ? friend?.attributes?.to_id?.data?.attributes?.city : "----"}</SubText1>
                                        </InitialDiv>
                                        <RadioButtonText
                                          key={index}
                                          index={index}
                                          name="existedFriends"
                                          value={friend?.id}
                                          checked={toggleFriends}
                                          onSelect={() => { handleExistedFriendsToggle(friend?.id, friend?.attributes?.to_id?.data); setSelectedData(friend); }}
                                        />
                                      </FlexBetween>
                                    </Flex>
                                  </InitialDiv>
                                </InitialDiv>
                                :
                                (
                                  friend?.attributes?.to_id?.data?.id === user?.id && friend.attributes.status === "ACCEPTED"
                                    ?
                                    <InitialDiv
                                      key={index}
                                      style={{
                                        boxShadow: "1px 3px 8px -1px #dcf0dccf",
                                        padding: "5px 15px",
                                        borderRadius: "7px",
                                        margin: "15px 0px",
                                        textAlign: "center"
                                      }}
                                    >
                                      <InitialDiv className="text-left">
                                        <Flex className="items-center">
                                          <img
                                            alt=""
                                            className="inline w-[2rem] h-[2rem] rounded-full mr-2"
                                            src={friend?.attributes?.from_id?.data?.attributes?.profile_pic?.data !== null ? `${process.env.REACT_APP_API_URL}${friend?.attributes?.from_id?.data?.attributes?.profile_pic?.data?.attributes?.url}` : exProfileImg}
                                            style={{ objectFit: "cover" }}
                                          />
                                          <FlexBetween className="w-full">
                                            <InitialDiv>
                                              <TextHead4 className="mt-[15px]">{friend?.attributes?.from_id?.data?.attributes?.name}</TextHead4>
                                              <SubText1>{friend?.attributes?.from_id?.data?.attributes?.city ? friend?.attributes?.from_id?.data?.attributes?.city : "----"}</SubText1>
                                            </InitialDiv>
                                            <RadioButtonText
                                              key={index}
                                              index={index}
                                              name="existedFriends"
                                              value={friend?.id}
                                              checked={toggleFriends}
                                              onSelect={() => { handleExistedFriendsToggle(friend?.id, friend?.attributes?.from_id?.data); setSelectedData(friend); }}
                                            />
                                          </FlexBetween>
                                        </Flex>
                                      </InitialDiv>
                                    </InitialDiv>
                                    :
                                    null
                                )
                            }
                            <InitialDiv className={`fixed right-0 left-0 bottom-[90px] mx-[25px] ${isDarkMode ? "bg-black" : "bg-white"}`}>
                              <ButtonGreen onClick={() => setShowOptionModal(true)} className={toggleFriends === "" ? `!bg-[#22a45d85]` : ""} disabled={toggleFriends === "" ? true : false}>{switchSentenceCase("primary button", "ORDER NOW (OR LATER)")}</ButtonGreen>
                              <ButtonLimeGreen
                                className={toggleFriends === "" ? `!bg-[#bbd97c63] my-[10px]` : "my-[10px]"} disabled={toggleFriends === "" ? true : false}
                                onClick={() => handleOnClickForOther()}
                              >{switchSentenceCase("primary button", "CREATE CALENDAR")}</ButtonLimeGreen>
                              <ButtonGray onClick={() => setShowConfirmationModal(true)} className={toggleFriends === "" ? `!bg-[#b3b3b357]` : ""} disabled={toggleFriends === "" ? true : false}>{switchSentenceCase("primary button", "DELETE FRIEND")}</ButtonGray>
                            </InitialDiv>

                          </React.Fragment>
                        )
                      })}

                    {(existedFriendData?.length === 0 && !loadingForFriends) &&
                      <NoDataFound
                        btnStyle={{
                          display: "none"
                        }}
                        message={"No Friends Found !"}
                      />
                    }
                    {
                      ((totalForExisted > limit) && !loadingForFriends) &&
                      (
                        (totalForExisted !== existedFriendData?.length) ?
                          <div
                            className="text-green-600 text-center cursor-pointer"
                            onClick={() => { showMore() }}
                          >
                            {loadingForSeeMore ? <div className="flex items-center justify-center"><Spinner /></div> : "See More"}
                          </div>
                          :
                          <div
                            className="text-green-600 text-center"
                          >
                            No More Existing Friends.
                          </div>
                      )
                    }
                  </DynamicTab>


                  {/* ******************* All type of Friend requests Section ************************************* */}
                  <DynamicTab label={"pending_friends"} tabName={"Pending Friends"} setOrderType={handleChangeFriendType} selectedTab={friendType}>
                    <div title="Pending Friends">
                      {(loadingForFriends && skip === 0) &&
                        (<div className="flex items-center justify-center">
                          <Spinner />
                        </div>
                        )
                      }
                      <WrapChoice>
                        {(allFriendRequestStatus?.length > 0 && !loadingForFriends)
                          &&
                          allFriendRequestStatus?.map((requestItems: any, index: any) => {
                            return (
                              <React.Fragment key={index}>
                                {
                                  requestItems?.attributes?.from_id?.data?.id === user?.id && requestItems.attributes.status === "PENDING"
                                    ?
                                    <InitialDiv
                                      key={index}
                                      style={{
                                        boxShadow: "1px 3px 8px -1px #dcf0dccf",
                                        padding: "15px",
                                        borderRadius: "7px",
                                        margin: "15px 0px",
                                        textAlign: "center"
                                      }}>
                                      <InitialDiv className="text-left">
                                        <Flex className="items-center">
                                          <img
                                            alt=""
                                            className="inline w-[2rem] h-[2rem] rounded-full mr-2"
                                            src={requestItems?.attributes?.to_id?.data?.attributes?.profile_pic?.data !== null ? `${process.env.REACT_APP_API_URL}${requestItems?.attributes?.to_id?.data?.attributes?.profile_pic?.data?.attributes?.url}` : exProfileImg}
                                            style={{ objectFit: "cover" }}
                                          />
                                          <InitialDiv>
                                            <TextHead4>{requestItems?.attributes?.to_id?.data?.attributes?.name}</TextHead4>
                                            <SubText1 className="mb-2">{requestItems?.attributes?.to_id?.data?.attributes?.city ? requestItems?.attributes?.to_id?.data?.attributes?.city : "------"}</SubText1>
                                          </InitialDiv>
                                        </Flex>
                                        <Flex className="gap-3 w-[250px]">
                                          <ButtonLiteGreen className="!rounded-[25px] h-[30px] !p-0" >Requested <FontAwesomeIcon icon={faCheck} /></ButtonLiteGreen>
                                          <ButtonInitial className="text-[#979797] !p-0 !rounded-[25px] border border-#979797] border-solid h-[30px]" onClick={() => { setShowConfirmationModal(true); setSelectedData(requestItems) }}>Cancel</ButtonInitial>
                                        </Flex>
                                      </InitialDiv>
                                    </InitialDiv>
                                    :
                                    (
                                      requestItems?.attributes?.to_id?.data?.id === user?.id && requestItems.attributes.status === "PENDING"
                                        ?
                                        <InitialDiv
                                          key={index}
                                          style={{
                                            boxShadow: "1px 3px 8px -1px #dcf0dccf",
                                            padding: "15px",
                                            borderRadius: "7px",
                                            margin: "15px 0px",
                                            textAlign: "center"
                                          }}>
                                          <InitialDiv className="text-left">
                                            <Flex className="items-center">
                                              <img
                                                alt=""
                                                className="inline w-[2rem] h-[2rem] rounded-full mr-2"
                                                src={requestItems?.attributes?.from_id?.data?.attributes?.profile_pic?.data !== null ? `${process.env.REACT_APP_API_URL}${requestItems?.attributes?.from_id?.data?.attributes?.profile_pic?.data?.attributes?.url}` : exProfileImg}
                                                style={{ objectFit: "cover" }}
                                              />
                                              <InitialDiv>
                                                <TextHead4>{requestItems?.attributes?.from_id?.data?.attributes?.name}</TextHead4>
                                                <SubText1 className="mb-2">{requestItems?.attributes?.to_id?.data?.attributes?.city ? requestItems?.attributes?.to_id?.data?.attributes?.city : "------"}</SubText1>
                                              </InitialDiv>
                                            </Flex>
                                            <Flex className="gap-3 w-[250px]">
                                              <ButtonGreen className="!rounded-[25px] h-[30px] !p-0" onClick={() => handleUpdate(requestItems?.id, requestItems?.attributes?.from_id?.data?.attributes?.name)}>+Accept</ButtonGreen>
                                              <ButtonInitial className="text-[#979797] !p-0 !rounded-[25px] border border-#979797] border-solid h-[30px]" onClick={() => { setShowConfirmationModal(true); setSelectedData(requestItems) }}>Cancel</ButtonInitial>
                                            </Flex>
                                          </InitialDiv>
                                        </InitialDiv>
                                        :
                                        null
                                    )
                                }
                              </React.Fragment>
                            )
                          })
                        }
                        {(allFriendRequestStatus?.length === 0 && !loadingForFriends) &&
                          <NoDataFound
                            btnStyle={{
                              display: "none"
                            }}
                            message={"No Request Found !"}
                          />
                        }
                      </WrapChoice>
                      {totalForPending > limit && !loadingForFriends &&
                        (totalForPending !== allFriendRequestStatus?.length ? (
                          <div
                            className="text-green-600 text-center cursor-pointer"
                            onClick={() => { showMore() }}
                          >
                            {loadingForSeeMore ? (<div className="flex items-center justify-center"><Spinner /></div>
                            ) : ("See More")}
                          </div>
                        ) :
                          (<div
                            className="text-green-600 text-center"
                          >
                            No More Pending Friends
                          </div>
                          ))}
                    </div>
                  </DynamicTab>
                  {/* ******************* All type of contacts Section ************************************* */}
                  <DynamicTab label={"other_contacts"} tabName={"Other Contacts"} setOrderType={handleChangeFriendType} selectedTab={friendType}>
                    <div title="Pending Friends">
                      {(loadingForFriends && skip === 0) &&
                        (<div className="flex items-center justify-center">
                          <Spinner />
                        </div>
                        )
                      }
                      <WrapChoice>
                        {(contactsData?.length > 0 && !loadingForFriends)
                          &&
                          contactsData?.map((requestItems: any, index: any) => {
                            return (
                              <React.Fragment key={index}>
                                {
                                  requestItems?.attributes?.from_id?.data?.id === user?.id
                                    ?
                                    <InitialDiv
                                      key={index}
                                      style={{
                                        boxShadow: "1px 3px 8px -1px #dcf0dccf",
                                        padding: "15px",
                                        borderRadius: "7px",
                                        margin: "15px 0px",
                                        textAlign: "center"
                                      }}>
                                      <InitialDiv className="text-left">
                                        <FlexBetween className="items-center">
                                          <Flex className="items-center">
                                            <img
                                              alt=""
                                              className="inline w-[2rem] h-[2rem] rounded-full mr-2"
                                              src={requestItems?.attributes?.to_id?.data?.attributes?.profile_pic?.data !== null ? `${process.env.REACT_APP_API_URL}${requestItems?.attributes?.to_id?.data?.attributes?.profile_pic?.data?.attributes?.url}` : exProfileImg}
                                              style={{ objectFit: "cover" }}
                                            />
                                            <InitialDiv>
                                              <TextHead4 className="ml-2">{requestItems?.attributes?.to_id?.data?.attributes?.name}</TextHead4>
                                              <SubText1 className="mb-2">{requestItems?.attributes?.to_id?.data?.attributes?.email || requestItems?.attributes?.to_id?.data?.attributes?.phone_no || ""}</SubText1>
                                            </InitialDiv>
                                          </Flex>
                                          {(requestItems?.attributes?.status === "NULL") && <SubText1 className="underline !text-green-600 cursor-pointer" onClick={() => handleContactsInvite(requestItems)}>Invite</SubText1>}
                                          {(requestItems?.attributes?.status === "PENDING") && <SubText1 className="!text-gray-600">Pending Request</SubText1>}
                                          {(requestItems?.attributes?.status === "ACCEPTED") && <FontAwesomeIcon icon={faCheck} className="mr-3 text-green-600 cursor-pointer" />}
                                        </FlexBetween>
                                      </InitialDiv>
                                    </InitialDiv>
                                    :
                                    (
                                      requestItems?.attributes?.to_id?.data?.id === user?.id
                                        ?
                                        <InitialDiv
                                          key={index}
                                          style={{
                                            boxShadow: "1px 3px 8px -1px #dcf0dccf",
                                            padding: "15px",
                                            borderRadius: "7px",
                                            margin: "15px 0px",
                                            textAlign: "center"
                                          }}>
                                          <InitialDiv className="text-left">
                                            <FlexBetween className="items-center">
                                              <Flex className="items-center">
                                                <img
                                                  alt=""
                                                  className="inline w-[2rem] h-[2rem] rounded-full mr-2"
                                                  src={requestItems?.attributes?.from_id?.data?.attributes?.profile_pic?.data !== null ? `${process.env.REACT_APP_API_URL}${requestItems?.attributes?.from_id?.data?.attributes?.profile_pic?.data?.attributes?.url}` : exProfileImg}
                                                  style={{ objectFit: "cover" }}
                                                />
                                                <InitialDiv>
                                                  <TextHead4 className="ml-2">{requestItems?.attributes?.from_id?.data?.attributes?.name}</TextHead4>
                                                  <SubText1 className="mb-2">{requestItems?.attributes?.from_id?.data?.attributes?.email || requestItems?.attributes?.from_id?.data?.attributes?.phone_no || ""}</SubText1>
                                                </InitialDiv>
                                              </Flex>
                                              {(requestItems?.attributes?.status === "NULL") && <SubText1 className="underline !text-green-600 cursor-pointer" onClick={() => handleContactsInvite(requestItems)}>Invite</SubText1>}
                                              {(requestItems?.attributes?.status === "PENDING") && <SubText1 className="!text-green-600">Pending Request</SubText1>}
                                              {(requestItems?.attributes?.status === "ACCEPTED") && <FontAwesomeIcon icon={faCheck} className="mr-3 text-green-600 cursor-pointer" />}
                                            </FlexBetween>
                                          </InitialDiv>
                                        </InitialDiv>
                                        :
                                        null
                                    )
                                }
                              </React.Fragment>
                            )
                          })
                        }
                        {(contactsData?.length === 0 && !loadingForFriends) &&
                          <NoDataFound
                            btnStyle={{
                              display: "none"
                            }}
                            message={"No Contacts Found !"}
                          />
                        }
                      </WrapChoice>
                      {totalForContacts > limit && !loadingForFriends &&
                        (totalForContacts !== contactsData?.length ? (
                          <div
                            className="text-green-600 text-center cursor-pointer"
                            onClick={() => { showMore() }}
                          >
                            {loadingForSeeMore ? (<div className="flex items-center justify-center"><Spinner /></div>
                            ) : ("See More")}
                          </div>
                        ) :
                          (<div
                            className="text-green-600 text-center"
                          >
                            No More Contacts
                          </div>
                          ))}
                    </div>
                  </DynamicTab>
                </DynamicToggleTabs>
              </>
          }
        </InitialDiv>
        {/* Confirm Modal------ */}

        <ModalConfirmationForCards
          firstText={"Are you sure you want to"}
          secondText={selectedData?.attributes?.status === "ACCEPTED" ? "remove this friend?" : "cancel this request?"}
          btnOneText="NO"
          btnTwoText="YES"
          showModal={showConfirmationModal}
          setShowModal={setShowConfirmationModal}
          rejectAction={() => setShowConfirmationModal(false)}
          confirmAction={() => handleDelete(selectedData)}
          // styleBtnSecondText={{ backgroundColor: !method ? "#22A45D" : "" }}
          iconBackgroundColor={"#ca0b00"}
        />
        {setShowOptionModal &&
          <ModalChooseOption
            showModal={showOptionModal}
            setShowModal={setShowOptionModal}
            selectedValue={handleSubmit}
          />
        }
      </IonContent>
      {/* ********************************** Add Email/Phone number********************************************** */}
      {
        <InitialModalCenter
          showModal={showModal}
          setShowModal={setShowModal}
        >
          <InitialDiv className="m-4">
            <Flex className="items-center">
              <FontAwesomeIcon
                className="cursor-pointer"
                icon={faChevronLeft}
                onClick={() => closeModal()}
              />
              <InitialDiv className="w-full text-center">
                <TextHead3 className="">Add Friend's Email Or Phone Number</TextHead3>
              </InitialDiv>
            </Flex>
          </InitialDiv>
          <Divider />

          <Divider />
          <InitialDiv className="m-4">
            <InitialDiv className="text-left">
              <Section>
                <HeadText>Enter Friend's Phone Number</HeadText>
                <PhoneInput
                  className={isDarkMode ? "country-selector" : ""}
                  international
                  countryCallingCodeEditable={false}
                  defaultCountry="US"
                  name="phoneno"
                  value={friendPhoneNo}
                  placeholder="e.g.+1 501 333 9827"
                  limitMaxLength={true}
                  onChange={(event) => handleFriendPhone(event)}
                  style={{
                    marginTop: "10px",
                    border: friendPhoneError ? "1px solid red" : "",
                  }}
                />
              </Section>
              <Section>
                <HeadText>Enter Friend's Email</HeadText>
                <InputText
                  type="email"
                  placeholder="jdoe122@icloud.com"
                  style={{
                    marginTop: "10px",
                    border: friendEmailError ? "1px solid red" : "",
                  }}
                  name="useremail"
                  value={friendEmail}
                  onChange={(event) => handleFriendEmail(event.target.value)}
                />
              </Section>
              <Section>
                {(awaiting === false)
                  ?
                  <ButtonGreen
                    className="mt-[15px]"
                    onClick={updateFriendEmailPhone}
                  >
                    {switchSentenceCase("primary button", "CONTINUE")}
                  </ButtonGreen>
                  :
                  <div className="text-center">
                    <Spinner />
                  </div>
                }
                {(apiResponse.length > 0)
                  &&
                  <SubText1 className="text-center mt-5">
                    <label className="text-red-600 mt-5">
                      {apiResponse}
                    </label>
                  </SubText1>
                }
              </Section>
            </InitialDiv>
          </InitialDiv>
        </InitialModalCenter>
      }
      {/* </div > */}
    </div >
  );
}
