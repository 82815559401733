import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from 'styled-components';

const Tab = styled.div`
 overflow: hidden;
  border: none;
  border-radius: 30px;
  display: flex;
  justify-content: space-around;
  margin: 20px 0px;
`;

const Tablinks = styled.button<{ active?: boolean; }>`
  background-color: inherit;
  float: left;
  border: 1px solid #dbdbdb;
  color: #949494;
  border-radius: 30px;
  outline: none;
  cursor: pointer;
  padding: 10px;
  transition: 0.3s;
  font-size: 15px;
  width: 100px;
  margin: 0px 5px;
  &:hover {
    background-color: #ddd;
  }

  ${({ active }) =>
    active &&
    ` background: #22A45D;
   color: white;
   border-radius:30px;
   width: 110px;
   &:hover {
    background-color: #22A45D;
  }
  `}
`;

// const addressTypes = [
//   {
//     icon: faHome,
//     name: "Home",
//   },

//   {
//     icon: faWork,
//     name: "work",
//   },

//   {
//     icon: faOther,
//     name: "Other",
//   },
// ]


export default function TabsForAddress(props: any) {


  const changeValue = (type: any) => {
    props?.setActive(type);
    props?.setAddressType(type);
  }

  return (
    <>
      <Tab>
        {props?.types.map((type: any) => (
          <Tablinks
            key={type?.name}
            active={props?.selected === type?.name}
            onClick={() => changeValue(type?.name)}
          >
            <FontAwesomeIcon icon={type?.icon} /> {type?.name}
          </Tablinks>
        ))}
      </Tab>
      <p />
      {/* <p> Your payment selection: {active} </p> */}
    </>
  );
};


