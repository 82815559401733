import { faAngleLeft } from "@fortawesome/pro-solid-svg-icons";
import { IonContent, IonRefresher, IonRefresherContent, RefresherEventDetail, useIonAlert, useIonViewDidEnter } from "@ionic/react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import styled from "styled-components";
import exProfileImg from "../../components/assets/profile/no-profile-photo.png";
import { Astk, ButtonGray, ButtonGreen, ButtonInitial, ButtonLiteGreen, InitialDiv, TextArea1, TextBox1, TextHead4 } from "../../components/styled/styled";
import AccordianCartegoryChild from "../../components/widgets/accordian/AccordianCartegoryChild";
import Divider from "../../components/widgets/divider/Divider";
import TopHead from "../../components/widgets/head/TopHead";
import ModalConfirmationForCards from "../../components/widgets/modals/ModalConfirmationForCards";
import ModalSpinner from "../../components/widgets/modalspinner/ModalSpinner";
import NoDataFound from "../../components/widgets/nodatafound/NoDataFound";
import Section from "../../components/widgets/section/Section";
import { userFavMeal } from "../../services/favourite";
import { deleteFriends, inviteFriend, invitedFriendsStatus, sendInvitation, updateInvitationData } from "../../services/friends";
import { findUserByUid, getUserData, getUserInfo, setUserData, updateUser } from "../../services/user";
import { handleNetworkError, handleRequestError, handleResponse, maskEmail, maskPhoneNumber, maxCharacterLimit, switchSentenceCase, tokenLogin, trackPixelEvent } from "../../utils/utilities";
import { setOrderForSomeone } from "../orders/redux/upcomingOrderActionCreator";
import FavoriteMealGrid from "./FavoriteMealGrid";
import { setUserDeliveryAddress, setUserFriendProfileData } from "./redux/locationActionCreator";

export const BodyWrapper = styled.div`
display:flex;
overflow-x: scroll;
::-webkit-scrollbar {
  display: none;
}
`;

const HeadText = styled.h5`
  text-align: left;
  color: #868686;
`;

export default function FriendsRequest() {
  // ************************ Other Methods ************************************************
  const API_URL = process.env.REACT_APP_API_URL;
  const user = getUserInfo();
  const history = useHistory();
  const [presentAlert] = useIonAlert();


  // **************************** Hooks ***************************************
  const { userId, uid } = useParams<any>();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const isLocalStorageDataAvailable = (user?.slug == userId?.slice(0, -1) || user?.id == userId?.slice(0, -1)) ? true : false;
  const { userFriendDataList } = useSelector((state: any) => state.userAddresses);
  const _userFriendDataList = userFriendDataList?.filter((currentObj: any) => currentObj?.uid === uid?.slice(0, -1))[0]?.data[0] || [];
  const [loading, setLoading] = useState(true);
  const [isRefreshed, setIsRefreshed] = useState(false);
  const [myFavMeals, setMyFavMeals] = useState([]);
  const [message, setMessage] = useState('Hey! Add me as a friend so I can see your faves and buy you food on special occasions!');
  const [friendsInvitedData, setFriendsInvitedData] = useState<any>([]);
  const [userName, setUserName] = useState("");
  const [userNameError, setUserNameError] = useState(false);

  // ******************* Render ******************************
  useEffect(() => {
    if (isLocalStorageDataAvailable) {
      callData(uid);
    }
    else {
      (async () => {
        await tokenLogin(userId, true);
      })();
    }
  }, [uid, isRefreshed]);

  useIonViewDidEnter(() => {
    trackPixelEvent('Friend profile visit');
  }, []);

  // ****************** Function ****************************
  function handleRefresh(event: CustomEvent<RefresherEventDetail>) {
    setTimeout(() => {
      setIsRefreshed(prev => !prev);
      event.detail.complete();
    }, 2000);
  };

  const handleChange = (e: any) => {
    const { value } = e.target;
    setUserName(value)
    if (value.trim() === "" || value.length < 3) {
      setUserNameError(true);
    } else {
      setUserNameError(false);
    }
  }

  // Inside component...
  const callData = async (uid: string) => {
    try {
      if (!uid?.slice(0, -1) || !isLocalStorageDataAvailable) {
        setLoading(false);   // Set loading to false if there's no UID
        return;
      }
      const cachedData = userFriendDataList.find(item => item.uid === uid?.slice(0, -1));
      if (cachedData) {   //Showing Cached Data---- no api hitting
        handleAddressStateData(cachedData);
        handleSomeoneElseData(cachedData);
        callFaveMealData(cachedData?.data[0]?.id);
        callGetFriendData(cachedData?.data[0]?.id);
        setLoading(false);
      } else {
        const response = await findUserByUid(uid);
        setUserFriendProfileData(uid?.slice(0, -1), response?.data);
        handleAddressStateData(response);
        handleSomeoneElseData(response);
        callFaveMealData(response?.data[0]?.id);
        callGetFriendData(response?.data[0]?.id);
        trackPixelEvent('Find user by uid');
        setLoading(false);
      }
    } catch (error) {
      // console.error("Error fetching user data:", error);
      setLoading(false);
    }
  };

  const handleAddressStateData = (responseData) => {
    const getFormatAddress = {
      lat: responseData?.data[0]?.address?.latitude,
      lang: responseData?.data[0]?.address?.longitude,
      streetName: responseData?.data[0]?.address?.addressLine1,
      streetNum: responseData?.data[0]?.address?.addressLine2,
      streetAddress: responseData?.data[0]?.address?.street_address,
      city: responseData?.data[0]?.address?.city,
      state: responseData?.data[0]?.address?.state,
      country: responseData?.data[0]?.address?.country,
      zip: responseData?.data[0]?.address?.zip_code,
      defaultAddressType: responseData?.data[0]?.address?.default_address,
      timezone: responseData?.data[0]?.address?.timezone,
      id: responseData?.data[0]?.address?.id
    };
    setUserDeliveryAddress(getFormatAddress);
  }

  const handleSomeoneElseData = (responseData) => {
    let Data = {
      recipientName: responseData?.data[0]?.name,
      recipientContact: responseData?.data[0]?.phone_no ? responseData?.data[0]?.phone_no : responseData?.data[0]?.email,
      recipientId: responseData?.data[0]?.id,
      addressId: responseData?.data[0]?.address?.id,
      shortNotes: "",
      type: "for_other"
    };
    setOrderForSomeone(Data);
  }

  const callFaveMealData = async (id: number) => {
    try {
      const responseData = await userFavMeal(id);
      setMyFavMeals(responseData?.data?.data);
      trackPixelEvent('User fave meal');
      setLoading(false);
    } catch (error) {
      // console.error("Error fetching user data:", error);
      setLoading(false);
    }
  };

  const callGetFriendData = async (id: number) => {
    try {
      const responseData = await invitedFriendsStatus(id);
      if (responseData?.statusText === "OK" || responseData?.status === 200) {
        trackPixelEvent('Invite friends status');
        setFriendsInvitedData(responseData?.data?.data[0]);
      }
      setLoading(false);
    } catch (error) {
      // console.error("Error fetching user data:", error);
      setLoading(false);
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    if (!user?.name) {
      if (userName !== "" && !userNameError) {
        const data = {
          "name": userName,
        };
        const localData = await getUserData();
        await updateUser(data, user?.id).then(async (response: any) => {
          if (response.status === 200) {
            trackPixelEvent("Update user");
            const newUpdatedUserInfo = {
              ...localData,
              user: {
                ...localData.user,
                name: userName,
              },
            };
            await setUserData(newUpdatedUserInfo);
          }
        });
      }
      else {
        presentAlert({
          header: `${userName?.length === 0 ? 'Please fill your name.' : "Name must be greater and equal to 3 character."}`,
          // subHeader: `Friend Request Send Successfully.`,
          buttons: ['OK'],
        })
        setLoading(false);
        setUserNameError(true);
        return false;
      }
    }

    if (friendsInvitedData?.attributes?.status === "NULL") {
      const data = {
        "status": "PENDING",
        "message": message
      };
      const res = await sendInvitation(friendsInvitedData?.id, data);
      if (res?.data?.status === true) {
        trackPixelEvent('Invite Friend');
        // Google Analytics event snippet
        if (process.env.REACT_APP_GTAG_ENABLED_FOR_PRODUCTION === "true") {
          window.gtag('event', 'conversion', { 'send_to': 'AW-16498935235/22DeCKCIjLkZEMOLp7s9', 'value': 1.0, 'currency': 'USD' });
        }
        presentAlert({
          header: 'Friend Request Sent Successfully.',
          // subHeader: `Friend Request Send Successfully.`,
          buttons: ['OK'],
        })
        callData(uid);
      }
    }
    else {
      const payloadData = {
        data: {
          "from_id": user?.id,
          "to_id": _userFriendDataList?.id,
          "status": "PENDING",
          "message": message
        }
      };
      try {
        await inviteFriend(payloadData).then((response: any) => {
          if (response?.statusText === "OK" || response?.status === 200) {
            trackPixelEvent('Invite Friend');
            // Google Analytics event snippet
            if (process.env.REACT_APP_GTAG_ENABLED_FOR_PRODUCTION === "true") {
              window.gtag('event', 'conversion', { 'send_to': 'AW-16498935235/22DeCKCIjLkZEMOLp7s9', 'value': 1.0, 'currency': 'USD' });
            }
            presentAlert({
              header: 'Friend Request Sent Successfully.',
              // subHeader: `Friend Request Send Successfully.`,
              buttons: ['OK'],
            })
            callData(uid);
          }
        });
        setLoading(false);
      } catch (error) {
        setLoading(false);
        // console.error("Something Went Wrong:", error);
      }
    }
  };


  const handleDelete = async (id: any) => {
    try {
      const res = await deleteFriends(id); //Calling Friend DELETE api---
      const resData = handleResponse(res);
      trackPixelEvent('Delete friend');
      window.history.back()
    } catch (error) {
      if (error.response) {
        handleResponse(error.response); // Server responded with a non-2xx status code
      } else if (error.request) {
        handleRequestError(error.request);  // The request was made but no response was received
      } else {
        handleNetworkError(error); // Network error or something else happened
      }
    }
  };

  const handleUpdate = async (id: number) => {
    const data = { data: { "status": "ACCEPTED" } }
    try {
      const res = await updateInvitationData(data, id); //Calling Friend Update api---
      const resData = handleResponse(res);
      if (resData) {
        trackPixelEvent('Update friends data');
        // presentAlert({
        //   header: 'Friend List Updated !',
        //   subHeader: `Friend Added in Friend List.`,
        //   buttons: ['OK'],
        // })
        history.push("/friends");
      }
    } catch (error) {
      if (error.response) {
        handleResponse(error.response); // Server responded with a non-2xx status code
      } else if (error.request) {
        handleRequestError(error.request);  // The request was made but no response was received
      } else {
        handleNetworkError(error); // Network error or something else happened
      }
    }
  };

  return (
    <>
      {(loading && !isLocalStorageDataAvailable) ? (
        <div className="text-center">
          <ModalSpinner />
        </div>
      ) : (
        <div className="screen">
          <TopHead
            title="Add Friend"
            leftTextIcon={faAngleLeft}
            leftIconStyle={{ fontSize: "24px" }}
          />
          <Divider />

          {/* <div className="grow overflow-auto"> */}
          <IonContent>
            <IonRefresher slot="fixed" onIonRefresh={handleRefresh}>
              <IonRefresherContent></IonRefresherContent>
            </IonRefresher>
            <InitialDiv>
              {/*className={`pb-[100px]`} */}
              <Section align="center">
                <img
                  alt=""
                  className="inline w-36 h-36 rounded-full"
                  src={_userFriendDataList?.profile_pic !== null ? API_URL + _userFriendDataList?.profile_pic?.url : exProfileImg}
                  style={{ objectFit: "cover" }}
                />
              </Section>
              {loading
                ?
                <ModalSpinner />
                :
                <InitialDiv className="my-4 mx-4">
                  <InitialDiv>
                    <HeadText>FULL NAME</HeadText>
                    <TextHead4 className="my-3 !font-normal">{_userFriendDataList?.name}</TextHead4>
                  </InitialDiv>
                  <Divider />
                  <InitialDiv className="my-4">
                    <HeadText>EMAIL ADDRESS</HeadText>
                    <TextHead4 className="my-3 !font-normal">{_userFriendDataList?.email && maskEmail(_userFriendDataList?.email)}</TextHead4>
                  </InitialDiv>
                  <Divider />
                  <InitialDiv className="my-4">
                    <HeadText>PHONE NUMBER</HeadText>
                    <TextHead4 className="my-3 !font-normal">{_userFriendDataList?.phone_no && maskPhoneNumber(_userFriendDataList?.phone_no)}</TextHead4>
                  </InitialDiv>

                  {user?.name === null && (
                    <InitialDiv className="text-left">
                      <TextHead4 id="username" className="mb-[-15px] mt-4">
                        Your Name<Astk> *</Astk>
                      </TextHead4>
                      <TextBox1
                        type="text"
                        placeholder="Enter Your Name"
                        name="username"
                        style={{
                          border: userNameError ? "1px solid red" : "",
                        }}
                        value={userName}
                        onChange={handleChange}
                      />
                    </InitialDiv>
                  )}
                  <Divider />

                  {friendsInvitedData?.attributes?.status == "PENDING" && friendsInvitedData?.attributes?.to_id?.data?.id === user?.id
                    ?
                    <HeadText className="mt-4"><span className="uppercase" >{_userFriendDataList?.name} says:</span> {friendsInvitedData?.attributes?.message}</HeadText>
                    :
                    ""
                  }

                  {friendsInvitedData?.attributes?.status == "PENDING" ||
                    friendsInvitedData?.attributes?.status == "ACCEPTED"
                    ?
                    ""
                    :
                    <InitialDiv className="mt-4">
                      <HeadText className="uppercase">{_userFriendDataList?.name} says:</HeadText>
                      <TextArea1
                        placeholder="Hey! Join MealFave and save your favorite restaurant meals as “Faves.” Then I can see exactly what you like and send you meals on special occasions!"
                        name="invitationmessage"
                        className="!mt-4 h-[120px]"
                        maxLength={maxCharacterLimit}
                        value={message}
                        onChange={(e) => setMessage(e?.target?.value)}
                      />
                    </InitialDiv>}
                </InitialDiv>}
              {(myFavMeals?.length > 0 && !loading)
                && <InitialDiv className="mx-4 mb-4">
                  <AccordianCartegoryChild text={{ categoryName: `${_userFriendDataList?.name != null ? switchSentenceCase("head text", _userFriendDataList?.name) + "’s" : "Friend"} Fave Meals`, seeMore: '' }} />
                  {myFavMeals.length > 0
                    ?
                    <FavoriteMealGrid data={myFavMeals} />
                    :
                    <NoDataFound
                      btnStyle={{
                        display: "none"
                      }}
                    />
                  }
                </InitialDiv>}

              {!loading
                &&
                // className={`mx-7 fixed bottom-[70px] left-0 right-0 ${isDarkMode ? "bg-black" : "bg-white"} z-10`}
                <Section>
                  {friendsInvitedData?.attributes?.status == "PENDING"
                    ?
                    (
                      friendsInvitedData?.attributes?.from_id?.data?.id === user?.id
                        ?
                        <ButtonLiteGreen className="mb-[15px]" >{switchSentenceCase("primary button", "REQUESTED")}</ButtonLiteGreen>
                        :
                        <>
                          <ButtonGreen className="mb-[10px]" onClick={() => handleUpdate(friendsInvitedData?.id)}>{switchSentenceCase("primary button", "ACCEPT REQUEST")}</ButtonGreen>
                          <ButtonInitial className="text-[#979797] border border-#979797] border-solid mb-[15px]" onClick={() => { setShowConfirmationModal(true); }}>Cancel</ButtonInitial>
                        </>
                    )
                    :
                    (
                      friendsInvitedData?.attributes?.status == "ACCEPTED"
                        ?
                        <ButtonGray className="mb-[15px]" onClick={() => setShowConfirmationModal(true)}>{switchSentenceCase("primary button", "REMOVE")}</ButtonGray>
                        :
                        <ButtonGreen className="mb-[15px]" onClick={handleSubmit}>{switchSentenceCase("primary button", "ADD AS FRIEND")}</ButtonGreen>
                    )
                  }
                </Section>
              }
            </InitialDiv>
            <ModalConfirmationForCards
              firstText={"Are you sure you want to"}
              secondText={"remove this friend?"}
              btnOneText="NO"
              btnTwoText="YES"
              showModal={showConfirmationModal}
              setShowModal={setShowConfirmationModal}
              rejectAction={() => setShowConfirmationModal(false)}
              confirmAction={() => handleDelete(friendsInvitedData?.id)}
              // styleBtnSecondText={{ backgroundColor: !method ? "#22A45D" : "" }}
              iconBackgroundColor={"#ca0b00"}
            />
          </IonContent>
          {/* </div> */}
        </div >
      )}
    </>
  );
}
