import styled from 'styled-components';
import useDarkMode from '../../hooks/darkmode/DarkMode';

const Tab = styled.div`
 overflow: hidden;
  border: none;
  display: inline-block;
  /*border: 1px solid white;*/
  justify-content: space-around;
  margin: 20px 0px;
`;

// background-color: ${isDarkMode ? '#2e2e2e' : '#f1f1f1'};
const TabLinks = styled.button<{ active?: boolean; isDarkMode: boolean; }>`
 background-color: inherit;
  float: left;
  border: 1px solid #dbdbdb;
  color:${props => (props?.isDarkMode ? 'white' : '#949494')};
  border-radius: 10px;
  margin: 5px;
  outline: none;
  cursor: pointer;
  padding: 10px 16px;
  transition: 0.3s;
  font-size: 15px;
  font-weight: 500;
  width: auto;
  &:hover {
    background-color: #ddd;
  }

  ${({ active }) =>
    active &&
    ` background: #22A45D;
   color: white;
   border-radius: 10px;
   width: auto;
   &:hover {
    background-color: #22A45D;
  }
  `}
`;


export default function TabsForFilters(props: any) {
  const isDarkMode = useDarkMode();
  const changeValue = (type: any) => {
    props?.setFilterType(type);
  }

  return (
    <>
      <Tab>
        {props?.types?.map((type: any) => (
          <TabLinks
            key={type?.name}
            active={type?.value === props?.selected}
            onClick={() => changeValue(type?.value)}
            isDarkMode={isDarkMode}
          >
            {type?.name}
          </TabLinks>
        ))}
      </Tab>
    </>
  );
};


