import { useRef } from "react";
import { TextHead1, FlexBetween, InitialDiv, TextHead4, moveTopBottom } from "../../styled/styled";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/pro-solid-svg-icons";
import useDarkMode from "../../hooks/darkmode/DarkMode";
import deliveryIcon from "../../assets/food-delivery.svg";
import pickupIcon from "../../assets/food-pickup-fill.svg";
import Section from "../section/Section";
import { setNearByRestaurants, setOrderType } from "../../../pages/search/redux/searchActionCreator";
import { setRecentOrderList, setUserFaveMealList } from "../../../pages/faves/redux/favouriteActionCreator";

const ModalDiv = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1000;
  overflow-y: auto;
`;

const ContentDiv = styled.div<{ isDarkMode: boolean }>`
  position: absolute;
  background-color: ${props => (props?.isDarkMode ? '#2e2e2e' : '#fff')};
  margin-top: 80px;
  margin-bottom: 50px;
  padding: 10px;
  z-index: 1000;
  width: 600px;
  max-width: 90%;
  border-radius: 20px;
  text-align: center;
  animation: ${moveTopBottom} .4s ease-in-out;
`;

const BackModalBtn = styled.div`
  position: absolute;
  top: 23px;
  right: 15px;
  font-size: 17px;
`;
const ItemImage = styled.img`
  transition: transform 0.3s ease-in-out;
  &:hover {
    transform: scale(1.1); 
  }
`;


const items = [
    {
        name: "Delivery",
        value: "delivery",
        icon: deliveryIcon

    },
    {
        name: "Pickup",
        value: "pickup",
        icon: pickupIcon

    },
]

export default function ModalChooseOption(props: any) {
    const modalRef = useRef();
    const isDarkMode = useDarkMode();

    const handleOptionChange = (value: string) => {
        props?.selectedValue(value);
        setOrderType(value);
        setNearByRestaurants([]);
        setUserFaveMealList([]);
        setRecentOrderList([]);
    };

    const closeModal = (e: any) => {
        if (modalRef.current === e.target) {
            props?.setShowModal(false);
            props?.selectedValue("");
        }
    };

    const closeModalManual = (e: any) => {
        props?.setShowModal(false);
        props?.selectedValue("");
    };

    return (
        <>
            {props?.showModal ? (
                <ModalDiv onClick={closeModal}>
                    <ContentDiv isDarkMode={isDarkMode}>
                        <BackModalBtn>
                            <FontAwesomeIcon
                                icon={faXmark}
                                className="cursor-pointer"
                                onClick={closeModalManual}
                            />
                        </BackModalBtn>
                        <TextHead1>Select your Choice</TextHead1>
                        <InitialDiv className="flex justify-evenly m-4">
                            {items?.map((item: any, index: any) =>
                                <FlexBetween key={index} className="mt-2 flex cursor-pointer" >
                                    <InitialDiv className="icon-link" onClick={() => { handleOptionChange(item?.value) }}>
                                        <ItemImage src={item?.icon} alt={item?.name} width={80} height={70} />
                                        <TextHead4>{item?.name}</TextHead4>
                                    </InitialDiv>
                                </FlexBetween>
                            )}
                        </InitialDiv>
                        <Section>
                        </Section>
                    </ContentDiv>
                </ModalDiv>
            )
                :
                null}
        </>
    );
}