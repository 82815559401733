import { faMinus, faPlus } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { changeQuantity, changeQuantityOfAddFavMealItem } from "../restaurant/redux/menuItemActionCreatior";

const FlexBetween = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px;
`;

const Span = styled.span``;

export const CheckBoxWrapper = styled.div`
  position: relative;
  margin-left: 5px;
  margin-right: 5px;
`;
export const CheckBoxLabel = styled.label`
  position: absolute;
  top: 0;
  left: 0;
  width: 42px;
  height: 26px;
  border-radius: 15px;
  background: #bebebe;
  cursor: pointer;
  &::after {
    content: "";
    display: block;
    border-radius: 50%;
    width: 18px;
    height: 18px;
    margin: 3px;
    background: #ffffff;
    box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.2);
    transition: 0.2s;
  }
`;
export const CheckBox = styled.input`
  opacity: 0;
  z-index: 1;
  border-radius: 15px;
  width: 42px;
  height: 26px;
  &:checked + ${CheckBoxLabel} {
    background: #4fbe79;
    &::after {
      content: "";
      display: block;
      border-radius: 50%;
      width: 18px;
      height: 18px;
      margin-left: 21px;
      transition: 0.2s;
    }
  }
`;

const ItemQtyBtn = styled.button``;

export default function ItemQtyCompo({
  itemCount,
  leftText,
  style,
  product_Id,
}: {
  itemCount: any;
  leftText: any;
  style: any;
  product_Id: any;
}) {
  const dispatch = useDispatch();
  const { menuDataList } = useSelector(
    (state: any) => state.getRestaurantsMenuLists
  );
  const { searchedRestaurants } = useSelector((state: any) => state.getUserSearchedRestaurants);
  const storeId = searchedRestaurants?.id || "";

  // let product_ids;
  // let product_qty;
  // for (const item in menuDataList) {
  //   for (let index = 0; index < menuDataList[item].length; index++) {
  //     product_ids = menuDataList[item][index].product_id
  //     product_qty = menuDataList[item][index].quantity
  //   }
  // }

  return (
    <FlexBetween>
      <Span style={style}>{leftText}</Span>
      <CheckBoxWrapper>
        <ItemQtyBtn>
          {/* <FontAwesomeIcon icon={faMinus} /> */}
          <FontAwesomeIcon
            icon={faMinus}
            style={{ marginRight: "8px" }}
            onClick={() => {
              if (itemCount > 1) {
                dispatch(changeQuantity(storeId, product_Id, Number(itemCount) - 1) as any)
                dispatch(changeQuantityOfAddFavMealItem(product_Id, Number(itemCount) - 1, 0) as any)
              }
            }
            }
          />
          {itemCount}
          <FontAwesomeIcon
            icon={faPlus}
            style={{ marginLeft: "8px" }}
            onClick={() => {
              dispatch(changeQuantity(storeId, product_Id, Number(itemCount) + 1) as any)
              dispatch(changeQuantityOfAddFavMealItem(product_Id, Number(itemCount) + 1, 0) as any)
            }
            }
          />
        </ItemQtyBtn>
      </CheckBoxWrapper>
    </FlexBetween>
  );
}
