import { faMessageQuestion } from "@fortawesome/pro-regular-svg-icons";
import { faAngleLeft } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { useHistory } from "react-router";
import {
  Astk,
  ButtonGreen,
  ButtonInitial,
  InputLabel,
  InputText,
  TextHead1,
  TextHead3
} from "../../components/styled/styled";
import AddressAutocomplete2 from "../../components/widgets/address/AddressAutocomplete2";
import ToggleWithSubText from "../../components/widgets/buttons/ToggleWithSubText";
import Divider from "../../components/widgets/divider/Divider";
import TopHead from "../../components/widgets/head/TopHead";
import ModalBottomToTop from "../../components/widgets/modals/ModalBottomToTop";
import Section from "../../components/widgets/section/Section";
import { switchSentenceCase } from "../../utils/utilities";
import SchedulingWidget from "../scheduleotherpages/SchedulingWidget";



export default function MealForMultiPeople(
  data: any
) {

  const history = useHistory();

  const [switchState, setSwitchState] = useState(true);
  const [switchBtnState, setSwitchBtnState] = useState(false);
  const [showModal, setShowModal] = useState(false);


  const GotoHandle = () => {
    history.push({
      pathname: '/schedules',
    })
  }


  function handleOnChangeToggle() {
    setSwitchState(switchState === false);
  }

  const changeBtnState = () => {
    if (switchBtnState === false) {
      setSwitchBtnState(true)
    } else {
      setSwitchBtnState(false)
    }
  }


  const openModal = () => {
    setShowModal(prev => !prev)
  }


  return (
    <div className="screen">
      <TopHead
        title="New Meal Calendar For Multiple People"
        leftTextIcon={faAngleLeft}
        leftIconStyle={{ fontSize: "24px" }}
        goToHandle={GotoHandle}
      />
      <Divider />
      <div className="grow overflow-auto">
        <Section>
          <TextHead1>Is this a one-time or recurring event?</TextHead1>
          <ButtonInitial style={{ border: "1px solid #22A45D", color: "#22A45D", margin: "10px 0px" }}>{switchSentenceCase("primary button", "JUST ONE TIME")}</ButtonInitial>
          {
            showModal === false
              ?
              < ButtonInitial onClick={openModal} style={{ border: "1px solid #22A45D", color: "#22A45D", margin: "10px 0px" }}>{switchSentenceCase("primary button", "RECURRING EVENT")}</ButtonInitial>
              :
              <>
                {/* ---------- Modal Bottom to top -----------
                <ModalBottomToTop title="Liza Weber and others want to send you food!" showModal={showModal} setShowModal={setShowModal} >
                  <Divider />
                  <Section>
                    <ButtonGreen>{switchSentenceCase("primary button", "GET THE MEAL FAVE APP")}</ButtonGreen>
                  </Section>
                  <Section>
                    <InputLabel>Liza Weber set up a meal schedule for you in Meal Fave, so they can send you food from your favorite restaurants!</InputLabel>
                  </Section>
                  <Section>
                    <InputLabel style={{ color: "#22A45D", textDecoration: 'underline' }}>Open Meal Fave to do the following:</InputLabel>
                    <InputLabel>-Accept or Decline</InputLabel>
                    <InputLabel>-Choose your favorite meals so they know exactly what you want to eat</InputLabel>
                    <InputLabel>-View and Edit the schedule</InputLabel>
                    <InputLabel>-Invite Others </InputLabel>
                    <InputLabel>-Say "Thank You"</InputLabel>
                  </Section>

                  <MealFaveLogo >Meal Fave Logo</MealFaveLogo>

                </ModalBottomToTop> */}


                {/*---------- Modal Bottom to top ----------- */}
                <ModalBottomToTop showModal={showModal} setShowModal={setShowModal} >
                  <SchedulingWidget />
                </ModalBottomToTop>


                <ButtonInitial onClick={openModal} style={{ border: "1px solid #22A45D", color: "white", background: "#22A45D", margin: "10px 0px" }}>{switchSentenceCase("primary button", "RECURRING EVENT")}</ButtonInitial>
                {/* <p className="text-center">POPUP MATT’S SCHEDULE WIDGET</p> */}
              </>
          }
        </Section>

        <Section>
          <TextHead1>Will all the food be delivered to the same place?</TextHead1>

          {
            switchBtnState === false
              ?
              <>
                <ButtonInitial onClick={changeBtnState} style={{ border: "1px solid #22A45D", color: "#22A45D", margin: "10px 0px" }}>{switchSentenceCase("primary button", "YES, JUST ONE PLACE")}</ButtonInitial>
                <ButtonInitial style={{ border: "1px solid #22A45D", color: "#22A45D", margin: "10px 0px" }}>{switchSentenceCase("primary button", "NO, TO EACH RECIPIENT’S LOCATION")}</ButtonInitial>
              </>
              :
              <>
                <ButtonInitial onClick={changeBtnState} style={{ border: "1px solid #22A45D", color: "white", background: "#22A45D", margin: "10px 0px" }}>{switchSentenceCase("primary button", "YES, JUST ONE PLACE")}</ButtonInitial>
                <ButtonInitial style={{ border: "1px solid #22A45D", color: "#22A45D", margin: "10px 0px" }}>{switchSentenceCase("primary button", "NO, TO EACH RECIPIENT’S LOCATION")}</ButtonInitial>
                <AddressAutocomplete2
                  onAddressChosen={(val: any) => { }}
                  place={null}
                // setSelectedLocation={undefined}
                />
              </>
          }

        </Section>

        <Section>
          <TextHead1 style={{ marginBottom: "10px" }}>Add Recipients</TextHead1>
          <ButtonGreen>{switchSentenceCase("primary button", "ADD FROM CONTACTS")}</ButtonGreen>
          <Divider />


          <TextHead3 style={{ margin: "20px 0px" }}>Recipient Name </TextHead3>


          <InputLabel>First Name <Astk> *</Astk></InputLabel>
          <InputText style={{ marginBottom: "20px" }} placeholder="Enter text"></InputText>

          <InputLabel>Last Name <Astk> *</Astk></InputLabel>
          <InputText style={{ marginBottom: "20px" }} placeholder="Enter text"></InputText>

          <div className="flex justify-between">
            <InputLabel>Phone Number * <span style={{ color: "#22A45D" }}>Why?</span></InputLabel>
            <FontAwesomeIcon icon={faMessageQuestion} />
          </div>
          <InputText style={{ marginBottom: "20px" }} placeholder="Enter text"></InputText>


          {
            switchBtnState === false
              ?
              <ToggleWithSubText
                style={{ marginLeft: "0px", marginTop: "50px" }}
                text="Request Address"
                subText="Don’t have an address? We’ll send an email requesting their address."
                switchState={switchState}
                handleOnChangeToggle={handleOnChangeToggle}
              />
              :
              null
          }


        </Section>
        <Section>
          <ButtonInitial style={{ border: "1px solid #e2e2e2" }}>{switchSentenceCase("primary button", "SAVE AND CONTINUE")}</ButtonInitial>
        </Section>

      </div>
    </div >
  );
}


