import { faCheck } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useRef } from "react";
import styled from "styled-components";
import { ButtonGreen, ButtonInitial, SubText1} from "../../styled/styled";
import Section from "../section/Section";
import Spinner from "../spinner/Spinner";
import useDarkMode from "../../hooks/darkmode/DarkMode";

const ModalDiv = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1000;
  overflow-y: auto;
`;

const ContentDiv = styled.div<{ isDarkMode: boolean }>`
    position: absolute;
    float: left;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    background-color:${props => (props?.isDarkMode ? '#2e2e2e' : '#fff')};
    padding: 20px;
    z-index: 1000;
    width: 600px;
    max-width: 90%;
    border-radius: 0.5em;
    text-align: center;
`;

const DoneModalIcon = styled.div`
  position: relative;
  top: -55px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: -30px;
  width: 60px;
  height: 60px;
  padding: 18px;
  z-index: 10;
  background: #22a45d;
  color: white;
  border-radius: 40px;
`;

const TextMeal = styled.h3`
  font-weight: 600;
  font-size: 24px;
`;

export default function ModalSuccessForCards(props: any) {
  const modalRef = useRef();
  const isDarkMode = useDarkMode();

  const closeModal = (e: any) => {
    if (modalRef.current === e.target) {
      props?.setShowModal(false);
    }
  };

  return (
    <>
      {props?.showModal
        &&
        <ModalDiv onClick={closeModal}>
          <ContentDiv style={props?.contentDivStyle} isDarkMode={isDarkMode}>

            <DoneModalIcon>
              <FontAwesomeIcon icon={faCheck} className="text-2xl" />
            </DoneModalIcon>

            {((props?.firstText)?.length > 0)
              &&
              <TextMeal>{props?.firstText}</TextMeal>
            }

            {((props?.secondText)?.length > 0)
              &&
              <Section align="center">
                <SubText1>{props?.secondText}</SubText1>
              </Section>
            }

            {((props?.btnOneText)?.length > 0)
              &&
              ((props?.buttonOneLoader)
                ?
                <div className="text-center">
                  <Spinner />
                </div>
                :
                <div>
                  <ButtonGreen
                    onClick={props?.btnOneGoto}
                    style={props?.btnOneStyle}
                    disabled={props?.buttonOneDisabled}
                    className={props?.buttonOneDisabled && "!cursor-not-allowed"}
                  >
                    {props?.btnOneText}
                  </ButtonGreen>
                </div>
              )
            }

            {((props?.btnTwoText)?.length > 0)
              &&
              ((props?.buttonTwoLoader)
                ?
                <div className="text-center">
                  <Spinner />
                </div>
                :
                <div>
                  <ButtonInitial
                    onClick={props?.btnTwoGoto}
                    style={props?.btnTwoStyle}
                    disabled={props?.buttonTwoDisabled}
                    className={props?.buttonTwoDisabled && "!cursor-not-allowed"}
                  >
                    {props?.btnTwoText}
                  </ButtonInitial>
                </div>)
            }

          </ContentDiv>
        </ModalDiv>
      }
    </>
  );
}
