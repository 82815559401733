import { useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, } from "@fortawesome/pro-solid-svg-icons";
import { ButtonGreen, FlexBetween, InitialDiv, TextHead2, TextHead4, moveTopBottom } from "../../styled/styled";
import { switchSentenceCase, trackStandardPixelEvent } from "../../../utils/utilities";
import { useDispatch, useSelector } from "react-redux";
import { clearRestaurantList, getRestaurantsList, setRestaurantsListLoading, setSearchFilterRedux } from "../../../pages/search/redux/searchActionCreator";
import styled from "styled-components";
import Divider from "../divider/Divider";
import Section from "../section/Section";
import TabsForFilters from "../toggleable-tabs/TabsForFilters";
import { restaurantsNearPlace } from "../../../services/restaurant";
import useDarkMode from "../../hooks/darkmode/DarkMode";
import { getDeliveryAddress } from "../../../services/location";

// ************************** Styled Components & CSS  *********************************
const ModalDiv = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1000;
  overflow-y: auto;
`;

const ContentDiv = styled.div<{ isDarkMode: boolean }>`
   background-color: ${props => (props?.isDarkMode ? '#2e2e2e' : '#fff')};
    margin-bottom: 85px;
    margin-top: 80px;
    /* padding: 10px; */
    z-index: 1000;
    width: 600px;
    max-width: 90%;
    border-radius: 20px;
    text-align: center;
    height: fit-content;
    animation: ${moveTopBottom} .4s ease-in-out;
`;

export default function ModalSearchFilter(props: any) {

  const modalRef = useRef();
  const dispatch = useDispatch();
  const isDarkMode = useDarkMode();
  const addressData = getDeliveryAddress();

  // ********************  State Management ***********************************
  const { searchFilter } = useSelector((state: any) => state.getUserRestaurantsLists);
  const { userSearchLocation } = useSelector((state: any) => state.getUserSearchLocation);
  const { userDeliveryAddress } = useSelector((state: any) => state.userAddresses);


  // *********************** Hooks *********************************************
  const [filterSort, setFilterSort] = useState(searchFilter?.sort || "distance");
  const [filterCuisine, setFilterCuisine] = useState(searchFilter?.cuisine || "");
  const [filterDollarSign, setFilterDollarSign] = useState(searchFilter?.dollar_signs || "");
  const [filterMaximumMiles, setFilterMaximumMiles] = useState(searchFilter?.maximum_miles || "All");
  const [filterAvailability, setFilterAvailability] = useState(searchFilter?.open || "");


  //*******************  Functions *************************
  const closeModal = (e: any) => {
    if (modalRef.current === e.target) {
      props?.setShowModal(false);
    }
  };

  const applyFilter = () => {
    clearRestaurantList();
    const data = {
      sort: filterSort,
      cuisine: filterCuisine,
      open: filterAvailability,
      dollar_signs: filterDollarSign,
      maximum_miles: filterMaximumMiles === "All" ? "10" : filterMaximumMiles,
      query: searchFilter?.query
    }
    setSearchFilterRedux(data);
    searchFunction();
    props?.setShowModal(false);
  }

  const searchFunction = async () => {
    try {
      const placeObject = {
        lat: userDeliveryAddress?.lat || addressData?.lat || userSearchLocation?.lat,
        lang: userDeliveryAddress?.lang || addressData?.lang || userSearchLocation?.lang,
        orderType: props?.type
      };

      dispatch(setRestaurantsListLoading(true) as any)

      const response = await restaurantsNearPlace(placeObject);

      if (response) {
        trackStandardPixelEvent('Search');
        const restaurantData = response?.data?.data?.stores;
        const nextPage = response?.data?.data?.next_page;

        // -----------Filtering for Duplicate Data---------------
        const filteredData = Array?.from(
          new Set(restaurantData?.map((a: any) => a?.name))
        ).map((name: any) => {
          return restaurantData?.find((a: any) =>
            String(name)?.toLowerCase()?.includes(String(a?.name)?.toLowerCase())
          );
        });

        dispatch(setRestaurantsListLoading(false) as any);
        dispatch(getRestaurantsList(filteredData, nextPage) as any);
      }

    }
    catch (error) {
      dispatch(setRestaurantsListLoading(false) as any);
      console.log(error);
    }
  }

  const resetFilter = () => {
    setFilterSort("distance");
    setFilterCuisine("");
    setFilterDollarSign("");
    setFilterMaximumMiles("All");
    setFilterAvailability("");
  }

  const filterCuisineTypes = [
    {
      name: "All",
      value: ""
    },
    {
      name: "Italian",
      value: "Italian"
    },
    {
      name: "Thai",
      value: "Thai"
    },
    {
      name: "Indian",
      value: "Indian"
    },
    {
      name: "Chinese",
      value: "Chinese"
    },
    {
      name: "Mexican",
      value: "Mexican"
    },
    {
      name: "French",
      value: "French"
    },
    {
      name: "Japanese",
      value: "Japanese"
    },
    {
      name: "Greek",
      value: "Greek"
    },
    {
      name: "Lebanese",
      value: "Lebanese"
    },
    {
      name: "Brazilian",
      value: "Brazilian"
    },
  ]

  const filterPriceTypes = [
    {
      name: "All",
      value: ""
    },
    {
      name: "$",
      value: "1"
    },
    {
      name: "$$",
      value: "2"
    },
    {
      name: "$$$",
      value: "3"
    },
    {
      name: "$$$$",
      value: "4"
    }
  ]

  const filterSortTypes = [
    {
      name: "Distance",
      value: "distance"
    },
    {
      name: "Cheapest",
      value: "cheapest"
    },
    {
      name: "Fastest",
      value: "fastest"
    },
    {
      name: "Rating",
      value: "rating"
    },
    {
      name: "Relevance",
      value: "relevance"
    }
  ]

  const filterRanges = [
    {
      name: "All",
      value: "All"
    },
    {
      name: "1 Mile",
      value: "1"
    },
    {
      name: "5 Miles",
      value: "5"
    },
    {
      name: "10 Miles",
      value: "10"
    },
    // {
    //   name: "15 Miles",
    //   value: "15"
    // }
  ]

  const filterAvailabilityTypes = [
    {
      name: "All",
      value: ""
    },
    {
      name: "Open Only",
      value: "true"
    },
  ]

  return (
    <>
      {(props?.showModal)
        &&
        (
          <ModalDiv onClick={closeModal}>
            <ContentDiv className="bg-white" isDarkMode={isDarkMode}>
              <div className="h-14 flex justify-between items-center">
                <div className="flex justify-between items-center">
                  <FontAwesomeIcon
                    className="ml-4 cursor-pointer"
                    icon={faChevronLeft}
                    onClick={() => props?.setShowModal((prev: any) => !prev)}
                  />
                  <TextHead2 className={`m-0 ml-6 ${isDarkMode ? "text-white" : "text-black"}`}>Filters</TextHead2>
                </div>

                <TextHead4 onClick={resetFilter} className="text-gray-500 !text-base mr-4 cursor-pointer">CLEAR ALL</TextHead4>
              </div>

              <div className="">
                <div className="overflow-y-auto rounded-[20px]">
                  <div className="grow overflow-auto">
                    <Divider />

                    <Section>
                      <FlexBetween>
                        <TextHead4 >AVAILABILITY</TextHead4>
                      </FlexBetween>
                      <TabsForFilters selected={filterAvailability} types={filterAvailabilityTypes} setFilterType={setFilterAvailability} />
                    </Section>

                    <Divider />

                    <Section>
                      <FlexBetween>
                        <TextHead4 >SORT</TextHead4>
                        {/* <TextHead4 className="text-gray-500 !text-base">CLEAR ALL</TextHead4> */}
                      </FlexBetween>
                      <TabsForFilters selected={filterSort} types={filterSortTypes} setFilterType={setFilterSort} />
                    </Section>

                    <Divider />

                    <Section>
                      <FlexBetween>
                        <TextHead4 >CUISINE</TextHead4>
                      </FlexBetween>
                      <TabsForFilters selected={filterCuisine} types={filterCuisineTypes} setFilterType={setFilterCuisine} />
                    </Section>

                    <Divider />

                    <Section>
                      <FlexBetween>
                        <TextHead4 >RANGE</TextHead4>
                      </FlexBetween>
                      <TabsForFilters selected={filterMaximumMiles} types={filterRanges} setFilterType={setFilterMaximumMiles} />
                    </Section>

                    <Divider />

                    <Section>
                      <FlexBetween>
                        <TextHead4 >PRICE RANGE</TextHead4>
                      </FlexBetween>
                      <TabsForFilters selected={filterDollarSign} types={filterPriceTypes} setFilterType={setFilterDollarSign} />
                    </Section>
                    {/* <Divider /> */}
                    <InitialDiv className="apply-filter-parent mx-4 mb-4">
                      {/* apply-filter-parent */}
                      <ButtonGreen className="apply-filter-button" onClick={applyFilter}>
                        {switchSentenceCase("primary button", "APPLY FILTERS")}
                      </ButtonGreen>
                    </InitialDiv>
                  </div>
                </div>
              </div>
            </ContentDiv >
          </ModalDiv >
        )
      }
    </>
  );
}
