import React, { useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { ButtonGray, ButtonGreen, ButtonInitial, ButtonLimeGreen, Flex, FlexBetween, InitialDiv, InputText, ItemCount, SubText1, SubText2, TextArea1, TextHead1, TextHead3, TextHead4, TextHead5, moveTopBottom } from "../../styled/styled";
import styled from "styled-components";
import { existingOrderItemsFormatter, filterByHighestPriceAndSum, fullDateToShortConvert, getTimeDifference, increasePriceByQuantity, isRestaurantOpen, maskCurrencyInput, maxCharacterLimit, priceCalculator, priceFormater, priceFormatterUsd, properNameFormate, restaurantOpenAndClosingTime, sumOfMealInCart, switchSentenceCase, trackPixelEvent } from "../../../utils/utilities";
import { deleteSingleFavMealItem, deleteSingleFavouriteData, increaseQuantity, setFaveModalStatus, updateFavMealData, updateFavMealItemData } from "../../../pages/faves/redux/favouriteActionCreator";
import { useSelector, useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarPen, faChevronLeft, faCircleInfo, faClockThree, faStore, faTrash } from "@fortawesome/pro-solid-svg-icons";
import { faHeart } from "@fortawesome/pro-regular-svg-icons";
import { deleteSingleFavItem, updateFavItem, updateMealCategoryFavItem } from "../../../services/favourite";
import { getUserInfo } from "../../../services/user";
import ModalRemoved from "./ModalRemoved";
import { preventAndStop } from "../preventandstop/preventAndStop";
import ModalSuccess from "./ModalSuccess";
import AddressesCart from "../address/AdressesCart";
import PaymentCart from "../paymentscomponent/PaymentCart";
import Accordion from "../accordian/AccordionStyled";
import { previewOrder } from "../../../services/orderFood";
import ToolTip from "../alerts/tooltip";
import Spinner from "../spinner/Spinner";
import IonModalAlert from "../alerts/IonModalAlert";
import AccordionCheckoutCustomization from "../accordian/AccordionCheckoutCustomization";
import CheckoutProductCustomization from "../../../pages/orderFood/CheckoutProductCustomization";
import QuantitySelectorGlobal from "../buttons/QuantitySelectorGlobal";
import { IonDatetime, IonDatetimeButton, IonModal } from "@ionic/react";
import TabsForTipPreference from "../toggleable-tabs/TabsForTipPreference";
import ModalFinalizeOrder from "./ModalFinalizeOrder";
import momenttimezone from "moment-timezone";
import TimeWidget from "../time/TimeWidget";
import useDarkMode from "../../hooks/darkmode/DarkMode";
import { objectType } from "../../../type";
import CardApplyCoupon from "../cards/CardApplyCoupon";
import { getCoupon } from "../../../services/discounts";

const ModalDiv = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1000;
  overflow-y: auto;
`;

const ContentDiv = styled.div<{ isDarkMode: boolean }>`
    background-color: ${props => (props?.isDarkMode ? '#2e2e2e' : '#fff')};
    margin-bottom: 85px;
    margin-top: 80px;
    padding: 10px;
    z-index: 1000;
    width: 600px;
    max-width: 90%;
    border-radius: 20px;
    text-align: center;
    height: fit-content;
    animation: ${moveTopBottom} .4s ease-in-out;
`;

const HeartModalIcon = styled.div`
    padding: 10px;
    background: #f03f3f29;
    border-radius: 15px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: -65px;
    width: fit-content;
    position: relative;
    top: -60px;
    backdrop-filter: blur(7.5px);
`;

const BlockContainer = styled.div`
  display: block;
  width: -webkit-fill-available;
  text-align: left;
`;

const BackModalBtn = styled.div`
  position: relative;
  right: 49%;
  font-size: 17px;
  cursor: pointer;
`;

const TextMealChildren1 = styled.h3`
  color: #868686;
  font-size: 18px;
  font-weight: 400;
  font-style: italic;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

export default function ModalMealClicked(props: any) {
  // ********************************* State Management ***********************************************
  const { userDeliveryAddress } = useSelector((state: any) => state.userAddresses);
  const { orderForSomeone } = useSelector((state: any) => state?.upcomingOrderState);
  const { orderType } = useSelector((state: any) => state.getUserRestaurantsLists);


  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const type = searchParams.get('type') || orderType;
  const modalRef = useRef();
  const user = getUserInfo();
  const isDarkMode = useDarkMode();
  const currentDateTime = new Date();
  const mealModelData = props?.mealProps?.attributes?.favItems?.data;
  const mealData = props?.mealProps;

  const minDate: any = momenttimezone().tz(userDeliveryAddress?.timezone?.timeZoneId)?.format();

  const Tips = [
    {
      name: "None",
      value: "0"
    },
    {
      name: "$5",
      value: "5"
    },
    {
      name: "$10",
      value: "10"
    },
    {
      name: "$15",
      value: "15"
    },
    {
      name: "Other",
      value: "Other"
    }
  ]

  const [storeTipActive, setStoreTipActive] = useState("");
  const [driverTipActive, setDriverTipActive] = useState("");

  const [storeTipValue, setStoreTipValue] = useState("");
  const [driverTipValue, setDriverTipValue] = useState("");

  const [otherStoreTipValue, setOtherStoreTipValue] = useState("");
  const [otherStoreTipError, setOtherStoreTipError] = useState(false);

  const [otherDriverTipValue, setOtherDriverTipValue] = useState("");
  const [otherDriverTipError, setOtherDriverTipError] = useState(false);

  const [couponDetails, setCouponDetails] = useState<any>();
  const [appliedCoupon, setAppliedCoupon] = useState(null);
  const [discountPrice, setDiscountPrice] = useState<number>(0);
  const [discountCouponObj, setDiscountCouponObj] = useState<any>([]);

  const handleOtherStoreTip = (val: any) => {
    let maskedValue = maskCurrencyInput(val);
    setOtherStoreTipError(false);
    setOtherStoreTipValue(maskedValue);
  };

  const handleOtherDriverTip = (val: any) => {
    let maskedValue = maskCurrencyInput(val);
    setOtherDriverTipError(false);
    setOtherDriverTipValue(maskedValue);
  };

  const [showModal, setShowModal] = useState(false);
  const [inputList, setInputList] = useState([{ id: null, quantity: null }]);
  const [mealItemList, setMealItemList] = useState([{ id: null, name: null, price: null, customizations: null, quantity: null, instructions: null }]);
  const [showModalSuccess, setShowModalSuccess] = useState(false);
  const [hideBackModal, setHideBackModal] = useState(false);
  const [addressIdError, setAddressIdError] = useState(false);
  const [addressId, setAddressId] = useState("");
  const [paymentCardId, setPaymentCardId] = useState("");
  const [paymentCardError, setPaymentCardError] = useState(false);
  const [method, setMethod] = useState("");
  const [orderProcess, setOrderProcess] = useState(false);
  const [responseError, setResponseError] = useState("");
  const [showIonAlertModal, setShowIonAlertModal] = useState(false);
  const [ionAlertHeader, setIonAlertHeader] = useState("");
  const [ionAlertMessage, setIonAlertMessage] = useState("");
  const [scheduleValues, setScheduleValues] = useState({
    time: "",
    date: fullDateToShortConvert(new Date())
  });
  const [restaurantSchedules, setRestaurantSchedules] = useState([]);
  const [scheduleTimeError, setScheduleTimeError] = useState(false);
  const [notes, setNotes] = useState(mealData?.attributes?.drop_off_notes !== null ? mealData?.attributes?.drop_off_notes : "");
  const [showFinalizeModal, setShowFinalizeModal] = useState(false);
  const [scheduleOrderedData, setScheduleOrderedData] = useState<any>([]);
  const [restaurantTime, setRestaurantTime] = useState(restaurantOpenAndClosingTime(type === "delivery" ? mealData?.attributes?.restaurant?.data?.attributes?.jsonData?.local_hours?.delivery : mealData?.attributes?.restaurant?.data?.attributes?.jsonData?.local_hours?.pickup, currentDateTime, type));
  const [restaurantQuote, setRestaurantQuote] = useState<objectType>({});

  const openModal = () => {
    setMethod("delete");
    setShowModal(prev => !prev)
  };

  useEffect(() => {
    callData();
    callGetCoupon();
  }, [restaurantQuote]);


  const callGetCoupon = async () => {
    try {
      const response = await getCoupon(user?.id, sumOfMealInCart(mealModelData), "for_me");
      // const response = await getCoupon("15", "10", orderForSomeone?.type);
      let couponData = response?.data;
      if (couponData) {
        setCouponDetails(couponData);
      }
    } catch (e) {
      console.log("error==>", e);
    }
  };

  const callData = () => {
    let tempArray = [];
    let tempMealItemList = [];

    mealModelData?.forEach((currentObj: any) => {
      tempArray.push({
        id: Number(currentObj?.id),
        quantity: Number(currentObj?.attributes?.quantity),
      });

      //********************** */ Calling the recursive function to retrieve selected data ************************************
      const data = {
        id: currentObj?.id,
        name: currentObj?.attributes?.product?.data?.attributes?.name,
        price: Number(currentObj?.attributes?.product?.data?.attributes?.price),
        customizations: currentObj?.attributes?.customizations,
        quantity: Number(currentObj?.attributes?.quantity),
        instructions: currentObj?.attributes?.instructions
      }
      if (mealData?.attributes?.status === "INACTIVE" && mealData?.attributes?.error !== null) {
        mealData?.attributes?.error?.forEach((errorObj: objectType) => {
          Object.keys(errorObj).forEach(key => {
            if (key.includes(currentObj?.id)) {
              // Assign the error message to 'data' object
              data['product_error'] = currentObj?.attributes?.product_error;
            }
          });
        });
      }
      tempMealItemList?.push(data);
    });
    setInputList(tempArray);
    setMealItemList(tempMealItemList);

    if (restaurantQuote?.quote?.hasOwnProperty('error') || (!restaurantQuote?.quote?.hasOwnProperty('error') && !restaurantQuote?.quote?.accepts_pickup_tip)) {
      setStoreTipValue(Tips[0]?.value);
      setStoreTipActive(Tips[0]?.value);
    }
    else {
      const filterStoreTip = (Tips).filter((data) => data?.value === String(user?.auto_tip_amt));

      if (filterStoreTip?.length > 0) {
        setStoreTipValue(filterStoreTip[0]?.value);
        setStoreTipActive(filterStoreTip[0]?.value);
      }
      else if (user?.auto_tip_amt === null) {
        setStoreTipValue(Tips[0]?.value);
        setStoreTipActive(Tips[0]?.value);
      }
      else {
        setStoreTipValue("Other");
        setStoreTipActive(Tips[4]?.value);
        setOtherStoreTipValue("$" + user?.auto_tip_amt);
      }
    }

    if (restaurantQuote?.quote?.hasOwnProperty('error') || (!restaurantQuote?.quote?.hasOwnProperty('error') && !restaurantQuote?.quote?.accepts_delivery_tip)) {
      setDriverTipValue(Tips[0]?.value);
      setDriverTipActive(Tips[0]?.value);
    }
    else {
      const filterDriverTip = (Tips).filter((data) => data?.value === String(user?.driver_auto_tip));
      if (filterDriverTip?.length > 0) {
        setDriverTipValue(filterDriverTip[0]?.value);
        setDriverTipActive(filterDriverTip[0]?.value);
      }
      else if (user?.driver_auto_tip === null) {
        setDriverTipValue(Tips[0]?.value);
        setDriverTipActive(Tips[0]?.value);
      }
      else {
        setDriverTipValue("Other");
        setDriverTipActive(Tips[4]?.value);
        setOtherDriverTipValue("$" + user?.driver_auto_tip);
      }
    }
  };

  // Calculate and format discounted price
  const cartValueString = sumOfMealInCart(mealModelData);
  const cartValue = parseFloat(cartValueString);
  const discountedPrice = (cartValue - discountPrice).toFixed(2);

  let disable_buttons = false;
  if (mealData?.attributes?.status === "INACTIVE" && mealData?.attributes?.error !== null) {
    disable_buttons = mealData?.attributes?.error?.length === mealModelData?.length;
  }

  const handleOptionChange = (value: any, index: number, id: any) => {
    // For API
    const list: any = [...inputList];
    list[index]["quantity"] = value;

    // For list to show
    const itemList: any = [...mealItemList];
    if (itemList.length > 0 && value === 0) {
      itemList.splice(index, 1);
      setMealItemList(itemList);
    }
    else {
      itemList[index]["quantity"] = value;
      setMealItemList(itemList);
    }
    // For order Meal
    const index1 = mealModelData.findIndex((obj: any) => obj.id === id)
    if (mealModelData.length > 0 && value === 0) {
      mealModelData.splice(index1, 1);
    }
    else {
      mealModelData[index1].attributes.quantity = value;
    }
  };

  const closeModal = (e: any) => {
    if (modalRef.current === e.target) {
      props?.setShowMealModal(false);
    }
  };

  if (mealModelData?.length > 1) {
    const products = mealModelData;
    filterByHighestPriceAndSum(products);
    props.mealProps.attributes.favItems.data = products;
  }

  let restaurants: any;

  mealModelData?.forEach((element: any) => {
    let restaurantName: any;
    let restaurantDescription: any;
    restaurantName = element?.attributes?.product?.data?.attributes?.restaurant?.data?.attributes?.name;
    restaurantDescription = element?.attributes?.product?.data?.attributes?.restaurant?.data?.attributes?.description;
    restaurants = { restaurantName, restaurantDescription };
    return restaurants;
  });

  const dispatchFunction = () => {
    mealItemList?.forEach((currentObj) => {
      if (Number(currentObj?.quantity > 0)) {
        dispatch(
          increaseQuantity(
            props?.mealProps?.id,
            currentObj?.id,
            Number(currentObj?.quantity)
          ) as any
        )
      } else {
        if (mealItemList?.length > 1) {
          dispatch(
            deleteSingleFavMealItem(
              props?.mealProps?.id,
              currentObj?.id
            ) as any
          );
        }
      }
      dispatch(
        increaseQuantity(
          props?.mealProps?.id,
          currentObj?.id,
          Number(currentObj?.quantity)
        ) as any
      )
    })
  }

  const handleAddressId = (id: any) => {
    setAddressId(id);
    setAddressIdError(false);
  }

  const handlePaymentId = (id: any) => {
    setPaymentCardId(id);
    setPaymentCardError(false);
  }

  const handleOnDateChange = (e: any) => {
    const { name, value } = e.target;
    setResponseError("");
    setScheduleValues((prevValues) => {
      return {
        ...prevValues,
        [name]: value,
        time: ""
      };
    });
    setRestaurantTime(restaurantOpenAndClosingTime((type === "delivery" ? mealData?.attributes?.restaurant?.data?.attributes?.jsonData?.local_hours?.delivery : mealData?.attributes?.restaurant?.data?.attributes?.jsonData?.local_hours?.pickup), value, type));
  };



  const saveChanges = async (value: any) => {
    try {
      // Calculating Total Price
      const total_Price = Number(sumOfMealInCart(mealModelData));

      const data = {
        id: props?.mealProps?.id,
        name: mealData?.attributes?.name,
        favItems: inputList,
        total: total_Price,
        address: (type === "pickup") ? userDeliveryAddress?.id : addressId,
        notes: notes,
      };
      await updateFavItem(data).then(async (res: any) => {
        if (res?.status == 200) {
          setMethod(value);
          trackPixelEvent('Update fav item');
          dispatch(updateFavMealData(props.favMealId, res?.data?.data) as any);
          if (value === "update") {
            setShowModalSuccess(true);
            setHideBackModal(true);
          }
          dispatchFunction();
          setFaveModalStatus(false);
        }
      });
    } catch (e) {
      console.log(e);
    }
  };

  const timeValuesHandler = (value: any) => {
    setScheduleValues((prevValues) => {
      return {
        ...prevValues,
        time: value,
      };
    });
  };

  const restaurantSchedulesHandler = (data: any) => {
    setRestaurantSchedules(data);
  };

  const orderNow = async () => {
    try {
      setResponseError("");

      if (orderProcess) { return false; }
      let scheduleTimeFirstIndexId = Object.values(restaurantSchedules);
      let selectedScheduleId = (scheduleValues?.time?.split("@")[1] !== undefined) ? scheduleValues?.time?.split("@")[1] : "";

      const timeDifferences = getTimeDifference(userDeliveryAddress?.timezone, scheduleValues?.time);
      let selectedDate = scheduleValues?.date?.split("T")[0];

      if (type === "delivery" && (addressId === "" || addressId === undefined)) {
        setIonAlertHeader("No Address Found!"); setIonAlertMessage("Please, Add Address."); setShowIonAlertModal(true);
      }
      if (paymentCardId === "" || paymentCardId === undefined) { setIonAlertHeader("No Payment Method Found!"); setIonAlertMessage("Please, Add Card."); setShowIonAlertModal(true); }

      if (scheduleValues?.time?.split("@")[0] === "" || scheduleValues?.time?.split("@")[0] === undefined) { setScheduleTimeError(true); setIonAlertHeader("Please select time."); setIonAlertMessage(""); setShowIonAlertModal(true); }
      else { setScheduleTimeError(false); }

      if (type === "pickup" && storeTipValue === "Other" && otherStoreTipValue === "") {
        setOtherStoreTipError(true);
      } else {
        setOtherStoreTipError(false);
      }

      if (type === "delivery" && driverTipValue === "Other" && otherDriverTipValue === "") {
        setOtherDriverTipError(true);
      } else {
        setOtherDriverTipError(false);
      }

      if (
        (type === "delivery" && (addressId === "" || addressId === undefined || addressIdError)) ||
        (paymentCardId === "" || paymentCardId === undefined) ||
        (scheduleValues?.time?.split("@")[0] === "" || scheduleValues?.time?.split("@")[0] === undefined) ||
        (type === "pickup" && (storeTipValue === "Other" && otherStoreTipValue === "") || otherStoreTipError) ||
        (type === "delivery" && (driverTipValue === "Other" && otherDriverTipValue === "") || otherDriverTipError) ||
        (driverTipValue === "Other" && otherDriverTipValue === "") ||
        paymentCardError || scheduleTimeError
      ) {
        return false;
      }
      setOrderProcess(true);

      const orderData = {
        mealId: mealData?.id,
        items: existingOrderItemsFormatter(mealModelData),
        delivery_location: (type === "pickup") ? userDeliveryAddress?.id : addressId,
        newMeal: false,
        mealType: mealData?.attributes?.type,
        userId: orderForSomeone?.recipientId ? orderForSomeone?.recipientId : user?.id,
        type: (orderForSomeone?.type !== undefined) ? orderForSomeone?.type : "for_me",
        order_status: (selectedDate === timeDifferences?.currentDate && timeDifferences?.differenceInMinutes < 15) ? "ORDERED" : "SCHEDULED",
        from_userId: user?.id,
        date: selectedDate,
        time: scheduleValues?.time?.split("@")[0],
        recipientName: orderForSomeone?.recipientName ? orderForSomeone?.recipientName : "",
        recipientNotes: orderForSomeone?.shortNotes ? orderForSomeone?.shortNotes : "", sponsoredBy: user?.id,
        paymentCardId: paymentCardId,
        verifiedSlot: (scheduleValues?.time?.split("~")[1] === 'verified') ? true : false,
        orderType: type,
        discount_json: discountCouponObj,
        discount_type: discountCouponObj?.discount_type || "flat",
        discount_name: discountCouponObj?.coupon_code || "",
        discount_amt: discountCouponObj?.discount_amt || "0",
        discounted_amt: appliedCoupon !== null ? discountedPrice : "0",
        accepts_pickup_tip: restaurantQuote?.quote?.accepts_pickup_tip || false,
        accepts_delivery_tip: restaurantQuote?.quote?.accepts_delivery_tip || false
      };
      if (type === "pickup") {
        orderData['storeTip'] = storeTipValue === "Other" ? Number(otherStoreTipValue?.slice(1)) : Number(storeTipValue)
      }
      if (type === "delivery") {
        orderData['driverTip'] = driverTipValue === "Other" ? Number(otherDriverTipValue?.slice(1)) : Number(driverTipValue)
        orderData['instructions'] = notes
      }

      const payloadData = {
        data: {
          items: existingOrderItemsFormatter(mealModelData),
          delivery_location: (type === "pickup") ? userDeliveryAddress?.id : addressId,
          paymentCardId: paymentCardId,
          userId: orderForSomeone?.recipientId ? orderForSomeone?.recipientId : user?.id,
          scheduled_id: (Object.keys(restaurantSchedules).length === 0 ? "" : (selectedDate === timeDifferences?.currentDate && timeDifferences?.differenceInMinutes < 15) ? "" : (selectedScheduleId !== "" ? selectedScheduleId?.split("~")[0] : scheduleTimeFirstIndexId[0][0]?.id)),
          store_id: mealData?.attributes?.restaurant?.data?.attributes?.mealme_id,
          recipientName: orderForSomeone?.recipientName,
          newMeal: false,
          date: selectedDate,
          time: scheduleValues?.time?.split("@")[0],
          orderType: type,
          discount_json: discountCouponObj,
          discount_type: discountCouponObj?.discount_type || "flat",
          discount_name: discountCouponObj?.coupon_code || "",
          discount_amt: discountCouponObj?.discount_amt || "0",
          discounted_amt: appliedCoupon !== null ? discountedPrice : "0",
          accepts_pickup_tip: restaurantQuote?.quote?.accepts_pickup_tip || false,
          accepts_delivery_tip: restaurantQuote?.quote?.accepts_delivery_tip || false
        }
      };
      if (type === "pickup") {
        payloadData.data['storeTip'] = storeTipValue === "Other" ? Number(otherStoreTipValue?.slice(1)) : Number(storeTipValue)
      }
      if (type === "delivery") {
        payloadData.data['driverTip'] = driverTipValue === "Other" ? Number(otherDriverTipValue?.slice(1)) : Number(driverTipValue)
        payloadData.data['instructions'] = notes
      }
      await previewOrder(payloadData).then((res) => {
        if (res?.status === 200) {
          trackPixelEvent('Preview order');
          saveChanges("order");
          const scheduleResData = res?.data;
          const newData = ({
            ...scheduleResData,
            orderData: orderData
          })
          setScheduleOrderedData(newData);
          setHideBackModal(true);
          setShowFinalizeModal(prev => !prev);
          setOrderProcess(false);
        }
      })
    }
    catch (e) {
      console.log(e);
      setResponseError(e?.response?.data?.data?.error);
      setOrderProcess(false);
    }
  };

  const onPushLocation = (favItem_id: any) => {
    let data = {
      favItem_id: favItem_id,
      fav_meal_id: mealData?.id,
      restaurantId: mealData?.attributes?.restaurant?.data?.attributes?.mealme_id,
      redirect_link: location?.pathname
    }
    history.push({
      pathname: `/restaurant/${mealData?.attributes?.restaurant?.data?.attributes?.mealme_id}`,
      state: data
    })
  };

  const handleInactiveItem = async (id: number) => {
    if (mealModelData?.length < 2) {
      setMethod("remove");
      setShowModal(true);
    }
    else {
      handleRemoveInactiveItem(id);
    }
  }

  const handleRemoveInactiveItem = async (inactiveItemId: number) => {
    if (mealModelData?.length < 2) {
      await deleteSingleFavItem(mealData?.id)
        .then((res) => {
          trackPixelEvent('Delete Fav Meal');
          setMethod("delete");
          dispatch(deleteSingleFavouriteData(mealData?.id, "Fav") as any);
          setShowModalSuccess(true);
        })
        .catch((error) => {
          console.error('Error:', error);
        });
    }
    else {
      try {
        const remove_item = mealModelData?.filter((item: objectType) => item?.id === inactiveItemId);
        const new_price = mealData?.attributes?.total - Number(sumOfMealInCart(remove_item));
        const filteredArray = mealModelData.filter((item: objectType) => item.id !== inactiveItemId);
        const unmatchedIds = filteredArray.map((item: objectType) => item.id);
        const error = mealData?.attributes?.error?.filter((errorObj: objectType) => { return !Object.keys(errorObj).some(key => key.includes(String(inactiveItemId))); });
        const payloadData = {
          data: {
            favItems: unmatchedIds,
            total: Number(new_price).toFixed(2),
            error: error?.length > 0 ? error : null,
            status: error?.length > 0 ? "INACTIVE" : "ACTIVE"
          }
        }
        updateMealCategoryFavItem(payloadData, mealData?.id)
          .then((res) => {
            setMethod("remove_item");
            dispatch(updateFavMealItemData(mealData?.id, res?.data?.data, "Fav") as any);
            trackPixelEvent('Remove inactive fav item');
            setShowModalSuccess(true);
            setHideBackModal(true);
            setFaveModalStatus(false);
          })
      }
      catch (e) {
        console.log(e);
      }
    }
  }


  const handleApplyCoupon = (couponCode: string) => {
    setAppliedCoupon(couponCode);
    const coupon = couponDetails?.data?.find((coupon: any) => coupon?.coupon_code === couponCode);
    setDiscountCouponObj(coupon);
    if (coupon?.discount_type === "flat") {
      setDiscountPrice(parseFloat(coupon?.discount_amt));
    } else {
      setDiscountPrice(parseFloat(coupon?.discount_amt) / 100 * Number(sumOfMealInCart(mealModelData)));
    }
  };


  const handleRemoveCoupon = () => {
    setAppliedCoupon(null);
    setDiscountCouponObj([]);
    setDiscountPrice(0);
  };


  // ************************* Checking Restaurant Open/Closed & Active/Inactive Condition ************************************
  const isClosedStrip = ((mealData?.attributes?.restaurant?.data !== null && userDeliveryAddress?.timezone !== null && userDeliveryAddress?.timezone !== undefined) && !isRestaurantOpen(type === "delivery" ? mealData?.attributes?.restaurant?.data?.attributes?.jsonData?.local_hours?.delivery : mealData?.attributes?.restaurant?.data?.attributes?.jsonData?.local_hours?.pickup, currentDateTime, userDeliveryAddress?.timezone, false)) ? true : false;
  const isInActive = (mealData?.attributes?.status === "INACTIVE") ? true : false;

  return (
    <>
      {(props?.showMealModal)
        &&
        (
          <ModalDiv onClick={closeModal}>
            <ContentDiv className={hideBackModal ? "invisible" : ""} isDarkMode={isDarkMode}>
              <BackModalBtn>
                <FontAwesomeIcon
                  icon={faChevronLeft}
                  onClick={() => { props?.setShowMealModal((prev: any) => !prev); setFaveModalStatus(false); }}
                />
              </BackModalBtn>
              <HeartModalIcon>
                <FontAwesomeIcon
                  icon={faHeart}
                  style={{ fontSize: "30px", color: "#ff000082" }}
                />
              </HeartModalIcon>
              <TextHead1>{switchSentenceCase("head text", props?.mealProps?.attributes?.name)}</TextHead1>

              <SubText1 className="mt-1.5">
                {(restaurants?.restaurantName) && restaurants?.restaurantName}
              </SubText1>
              {mealData?.attributes?.restaurant?.data !== null &&
                <SubText1 className="!text-red-600">
                  {isInActive ? "Warning: This meal is no longer valid.  Please replace or remove all inactive items." : isClosedStrip ? "(Closed)" : ""}
                </SubText1>
              }
              <TextMealChildren1>
                {(restaurants?.restaurantDescription) && restaurants?.restaurantDescription}
              </TextMealChildren1>

              <InitialDiv className="mx-4">
                {/********************  Meal Cart Section ******************************/}

                <Accordion title="Meal Items" opened={true}>
                  {mealItemList?.map((mealItem: any, index: any) => {
                    return (
                      <React.Fragment key={index}>
                        <Flex className="mb-2">
                          {/* className={mealItem?.product_error ? "opacity-20 mb-2" : "mb-2"} */}
                          <ItemCount>{index + 1}</ItemCount>
                          <BlockContainer>
                            <Flex className="mb-2">
                              {/* w-[200px] */}
                              <TextHead4 className="underline">
                                {properNameFormate(mealItem?.name)}
                              </TextHead4>
                              {(mealItem?.instructions !== "") &&
                                <ToolTip instructions={mealItem?.instructions} />
                              }
                            </Flex>
                            {mealItem?.product_error &&
                              <FlexBetween className="items-baseline">
                                <InitialDiv className="text-left flex items-baseline">
                                  <FontAwesomeIcon
                                    icon={faCircleInfo}
                                    className="mr-1 mt-2 text-[12px] text-red-600"
                                  />
                                  <TextHead5 className="!text-red-600">
                                    {/* {mealItem?.product_error} */}Inactive Item
                                  </TextHead5>
                                </InitialDiv>
                                {(user?.id === mealData?.attributes?.user?.data?.id) &&
                                  <InitialDiv className="text-left flex items-baseline">
                                    <TextHead5
                                      className="text-green-600 underline !font-medium cursor-pointer !m-2"
                                      onClick={() => onPushLocation(mealItem?.id)}
                                    >
                                      Replace Item
                                    </TextHead5>
                                    < FontAwesomeIcon icon={faTrash} className="text-green-600 text-sm cursor-pointer" onClick={() => handleInactiveItem(mealItem?.id)} />
                                  </InitialDiv>
                                }
                              </FlexBetween>
                            }
                            {/* < FontAwesomeIcon icon={faPen} className="text-green-600 text-sm" onClick={() => onPushLocation(mealItem?.id)} /> */}
                            {(mealItem?.customizations?.length > 0)
                              &&
                              <>
                                <AccordionCheckoutCustomization
                                  title={"Selected Customizations"}
                                  titleSize={"16px"}
                                >
                                  <div className="block">
                                    <CheckoutProductCustomization customizations={mealItem?.customizations} />
                                  </div>
                                </AccordionCheckoutCustomization>
                              </>
                            }
                            <div className="flex justify-between items-center">
                              <QuantitySelectorGlobal
                                selected_quantity={mealItem?.quantity}
                                increaseQuantity={() => {
                                  const count = (Number(inputList[index]?.quantity) + 1)
                                  handleOptionChange(count, index, mealItem?.id)
                                }}
                                decreaseQuantity={() => {
                                  const totalCount = mealItemList.reduce((n, { quantity }) => (Number(n) + Number(quantity)), 0)
                                  if (Number(mealItem?.quantity) > 0 && totalCount > 1) {
                                    const count = (Number(inputList[index]?.quantity) - 1)
                                    handleOptionChange(count, index, mealItem?.id)
                                  }
                                }}
                              />
                              <SubText1>
                                {
                                  priceFormatterUsd(
                                    increasePriceByQuantity(
                                      priceCalculator(mealItem?.price, mealItem?.customizations),
                                      mealItem?.quantity
                                    ),
                                  )
                                }
                              </SubText1>
                            </div>
                          </BlockContainer>
                        </Flex>
                      </React.Fragment >
                    )
                  }
                  )}
                </Accordion>

                <InitialDiv className="mt-4">
                  <Flex className="items-baseline">
                    <FontAwesomeIcon icon={faStore} className="text-gray-600 text-s mt-1" />
                    <TextHead3 className="ml-2 text-left">{mealData?.attributes?.restaurant?.data?.attributes?.name}</TextHead3>
                  </Flex>

                  <InitialDiv className="flex mb-4">
                    <FontAwesomeIcon icon={faCircleInfo} className={(restaurantTime?.openingTime === "") ? "mt-[5px] mr-1 text-red-600" : "mt-[5px] mr-1 text-[#7a7a7aba]"} />
                    <TextHead5 style={{ fontWeight: "500", marginLeft: "4px" }} className={(restaurantTime?.openingTime === "") ? "text-red-600 text-left" : "text-[#7a7a7aba] text-left"}>{restaurantTime?.restaurantAvailability}</TextHead5>
                  </InitialDiv>
                </InitialDiv>

                {/********************  Schedule Section ******************************/}
                {/* <FlexBetween> */}
                <Flex className="mt-4">
                  <TextHead3 className="mb-1">
                    {`${(type === "delivery") ? "Delivery" : "Pickup"}  Date/Time`}
                  </TextHead3>
                </Flex>

                <InitialDiv className="text-left text-[#7a7a7aba] flex mb-4">
                  <FontAwesomeIcon icon={faCircleInfo} className="mt-[7px] mr-1" />
                  <TextHead5 style={{ fontWeight: "500", marginLeft: "4px" }}>
                    {`${(type === "delivery") ? "Delivery" : "Pickup"} time is our best estimate. Green indicates confirmed appointments, while black will either be confirmed on the morning of ${(type === "delivery") ? "delivery" : "pickup"}, an hour before, or canceled. The time zone is based on the  ${(type === "delivery") ? "delivery" : "pickup"} location.`}
                  </TextHead5>
                </InitialDiv>

                {/* </FlexBetween> */}

                <InitialDiv className="">
                  <div className="flex">
                    <FontAwesomeIcon icon={faCalendarPen} className="mt-1" />
                    <div className="ml-3 mt-1 w-full">
                      <TextHead4 className="flex justify-between">Select Date</TextHead4>
                      <SubText2 className="mt-2">
                        <IonDatetimeButton className="my-2.5" datetime="date" style={{ border: "1px solid #e2e2e2", borderRadius: "8px" }}></IonDatetimeButton>
                        <IonModal keepContentsMounted={true}>
                          <IonDatetime className={isDarkMode ? "calendar-color" : ""} name="date" value={scheduleValues?.date} color={isDarkMode ? "dark" : "success"} onIonChange={(e: any) => handleOnDateChange(e)} min={minDate} showDefaultButtons={true} presentation="date" preferWheel={false} id="date" ></IonDatetime>
                        </IonModal>
                      </SubText2>
                    </div>
                  </div>
                  <div className="flex mt-2">
                    <FontAwesomeIcon icon={faClockThree} className="mt-1" />
                    <TimeWidget
                      storeId={mealData?.attributes?.restaurant?.data?.attributes?.mealme_id}
                      date={scheduleValues?.date}
                      timezone={userDeliveryAddress?.timezone}
                      openingTime={restaurantTime?.openingTime}
                      closingTime={restaurantTime?.closingTime}
                      timeValuesData={timeValuesHandler}
                      restaurantSchedulesData={restaurantSchedulesHandler}
                      orderType={type}
                      restaurantQuote={setRestaurantQuote}
                    />
                  </div>
                </InitialDiv>

                {/********************  Store Tip Section ******************************/}
                {type === "pickup" &&
                  <InitialDiv className="my-4">
                    <TextHead3 className="text-left">Store Tip</TextHead3>
                    {restaurantQuote?.quote && (!restaurantQuote?.quote?.hasOwnProperty('error') && !restaurantQuote?.quote?.accepts_pickup_tip) && (
                      <InitialDiv className="text-left">
                        <FontAwesomeIcon icon={faCircleInfo} className="text-red-500 mt-2 mr-1" />
                        <span
                          style={{
                            fontWeight: "350",
                            color: "#dc2626",
                            fontSize: "17px",
                          }}
                        >
                          Store tip is not available for this restaurant.
                        </span>
                      </InitialDiv>
                    )}
                    <TabsForTipPreference
                      types={Tips}
                      setTipValue={setStoreTipValue}
                      setActive={setStoreTipActive}
                      selected={storeTipActive}
                      disabled={(restaurantQuote?.quote ? false : true) || (!restaurantQuote?.quote?.hasOwnProperty('error') && !restaurantQuote?.quote?.accepts_pickup_tip)}
                    />
                    {(storeTipValue === "Other")
                      ?
                      <InputText
                        type="text"
                        inputMode="numeric"
                        style={{
                          border: otherStoreTipError ? "1px solid red" : "",
                        }}
                        min={0}
                        value={otherStoreTipValue}
                        disabled={(restaurantQuote?.quote ? false : true) || (!restaurantQuote?.quote?.hasOwnProperty('error') && !restaurantQuote?.quote?.accepts_pickup_tip)}
                        placeholder="Enter Amount"
                        onChange={(event: any) => handleOtherStoreTip(event.target.value)}
                      />
                      :
                      null
                    }
                  </InitialDiv>
                }

                {/********************  Driver's Tip Section ******************************/}
                {type === "delivery" && (
                  <InitialDiv className="my-4">
                    <TextHead3 className="text-left">Driver's Tip</TextHead3>
                    {restaurantQuote?.quote && (!restaurantQuote?.quote?.hasOwnProperty('error') && !restaurantQuote?.quote?.accepts_delivery_tip) && (
                      <InitialDiv className="text-left">
                        <FontAwesomeIcon icon={faCircleInfo} className="text-red-500 mt-2 mr-1" />
                        <span
                          style={{
                            fontWeight: "350",
                            color: "#dc2626",
                            fontSize: "17px",
                          }}
                        >
                          Driver tip is not available for this restaurant.
                        </span>
                      </InitialDiv>
                    )}
                    <TabsForTipPreference
                      types={Tips}
                      setTipValue={setDriverTipValue}
                      setActive={setDriverTipActive}
                      selected={driverTipActive}
                      disabled={(restaurantQuote?.quote ? false : true) || (!restaurantQuote?.quote?.hasOwnProperty('error') && !restaurantQuote?.quote?.accepts_delivery_tip)}
                    />
                    {(driverTipValue === "Other")
                      ?
                      <InputText
                        type="text"
                        inputMode="numeric"
                        style={{
                          border: otherDriverTipError ? "1px solid red" : "",
                        }}
                        min={0}
                        value={otherDriverTipValue}
                        disabled={(restaurantQuote?.quote ? false : true) || (!restaurantQuote?.quote?.hasOwnProperty('error') && !restaurantQuote?.quote?.accepts_delivery_tip)}
                        placeholder="Enter Amount"
                        onChange={(event: any) => handleOtherDriverTip(event.target.value)}
                      />
                      :
                      null
                    }
                  </InitialDiv>
                )}

                {/********************  Address Section ******************************/}
                {type === "delivery" &&
                  <Accordion title="Delivery Address" opened={false}>
                    <AddressesCart
                      title=""
                      addressId={handleAddressId}
                      showDeleteBtn={false}
                      showList={false}
                      pageName="newMealCheckout"
                      pageDataId={orderForSomeone?.addressId ? orderForSomeone?.addressId : mealData?.attributes?.address?.data?.id}
                      changeAddressTxtBtn="CHANGE ADDRESS"
                      userId={orderForSomeone?.recipientId ? orderForSomeone?.recipientId : user?.id}
                      changeAddress={true}
                      setDeliveryAddress={false}
                      initialLength={1} />
                    {/* {addressIdError && <TextHead5 style={{ color: "red" }}>
                    Address is required.
                  </TextHead5>} */}
                  </Accordion>
                }


                {/********************  Payment Section Section ******************************/}
                <Accordion title="Payment" opened={(paymentCardId === "" || paymentCardId === undefined) ? true : false}>
                  <PaymentCart paymentId={handlePaymentId} userId={user?.id} changePayment={true} selectedCard={null} />
                  {/* {paymentCardError && <TextHead5 style={{ color: "red" }}>
                    Payment is required.
                  </TextHead5>} */}
                </Accordion>

                {/********************  Notes Section ******************************/}
                {type === "delivery" &&
                  <InitialDiv className="mt-8">
                    <TextHead3 className="text-left">Drop Off Notes</TextHead3>
                    <SubText1 className="text-right mt-1">{notes?.length}/{maxCharacterLimit}</SubText1>
                    <TextArea1
                      className="!mt-0"
                      placeholder=""
                      name="description"
                      value={notes}
                      onChange={(event: any) => setNotes(event.target.value)}
                      maxLength={maxCharacterLimit}
                    />
                  </InitialDiv>
                }


                {/********************  Discount Section ******************************/}
                {process.env.REACT_APP_ENABLED_DISCOUNT_COUPON === "true" &&
                  <InitialDiv className="my-4">
                    <Accordion title="Apply Coupon" opened={true}>
                      {
                        couponDetails?.status
                          ?
                          couponDetails?.data?.map((list: any, index: any) => {
                            return (
                              <React.Fragment key={index}>
                                <CardApplyCoupon
                                  couponCode={list?.coupon_code}
                                  description={list?.description}
                                  discountType={list?.discount_type}
                                  discountAmt={list?.discount_amt}
                                  cartValue={priceFormater(sumOfMealInCart(mealModelData)).slice(1)}
                                  handleApply={handleApplyCoupon}
                                  handleRemove={handleRemoveCoupon}
                                  isApplied={appliedCoupon === list?.coupon_code}
                                />
                              </React.Fragment>
                            )
                          })
                          :
                          <SubText2 className="text-center">{couponDetails?.data?.message}</SubText2>
                      }

                    </Accordion>
                  </InitialDiv>
                }

                <FlexBetween className="my-3.5">
                  <TextHead4 className="!font-medium whitespace-nowrap">Subtotal</TextHead4>
                  <TextHead4 className="!font-medium whitespace-nowrap">${sumOfMealInCart(mealModelData)}</TextHead4>
                </FlexBetween>

                {appliedCoupon !== null &&
                  <>
                    <FlexBetween className="mt-2">
                      <SubText1 className="!font-medium whitespace-nowrap">
                        Discount
                      </SubText1>
                      <SubText1 className="!font-medium whitespace-nowrap">
                        ${(discountPrice).toFixed(2)}
                      </SubText1>
                    </FlexBetween>
                    <FlexBetween className="mt-2 mb-4">
                      <SubText1 className="!font-medium whitespace-nowrap">
                        Discounted Price
                      </SubText1>
                      <SubText1 className="!font-medium whitespace-nowrap">
                        ${discountedPrice}
                      </SubText1>
                    </FlexBetween>
                  </>
                }

              </InitialDiv>

              <InitialDiv className="mx-4">
                {(user?.id === mealData?.attributes?.user?.data?.id) &&
                  <>
                    <ButtonInitial
                      className={orderProcess ? "text-green-600 !border !border-solid !border-green-600 !cursor-not-allowed" : "text-green-600 !border !border-solid !border-green-600"}
                      disabled={orderProcess}
                      onClick={() => {
                        history.push({
                          pathname: ("/addtofavemealcategory/" + props?.mealProps?.id),
                        });
                        setFaveModalStatus(false);
                      }
                      }
                    >
                      {switchSentenceCase("primary button", "EDIT MEAL CATEGORY")}
                    </ButtonInitial>

                    <ButtonGreen
                      className={(orderProcess || disable_buttons) ? "opacity-30 mt-2 !cursor-not-allowed" : "mt-2"}
                      disabled={orderProcess || disable_buttons}
                      onClick={() => saveChanges("update")}>
                      {switchSentenceCase("primary button", "SAVE CHANGES")}
                    </ButtonGreen>
                  </>
                }
                {orderProcess ?
                  <div className="text-center">
                    <Spinner />
                  </div>
                  :
                  <ButtonLimeGreen
                    className={disable_buttons || orderProcess ? `opacity-50 mt-2 !cursor-not-allowed ${(user?.id !== mealData?.attributes?.user?.data?.id) && "mb-4"}` : `${(user?.id !== mealData?.attributes?.user?.data?.id) && "mb-4"} mt-2`}
                    disabled={orderProcess || disable_buttons}
                    onClick={() => orderNow()}
                  >
                    {switchSentenceCase("primary button", "CONTINUE TO PREVIEW ORDER")}
                  </ButtonLimeGreen>
                }
                {responseError && <p className="text-red-600 text-center">{(responseError === "Scheduled id is not valid or not available.") ? "Something went wrong. Please retry or 'Contact Us' in your profile." : responseError}</p>}
                {(user?.id === mealData?.attributes?.user?.data?.id) &&
                  <ButtonGray
                    className={orderProcess ? "my-2 !cursor-not-allowed" : "my-2"}
                    disabled={orderProcess}
                    onClick={async (e: any) => {
                      preventAndStop(e);
                      openModal()
                    }}
                  >
                    {switchSentenceCase("primary button", "REMOVE FROM FAVES")}
                  </ButtonGray>
                }
              </InitialDiv>

              <ModalRemoved
                firstText={(method === "delete") ? "Are you sure you want to" : (method === "remove") ? "This will remove your fave meal and any associated orders will be canceled." : ""}
                secondText={(method === "delete") ? "remove from faves?" : (method === "remove") ? "Do you want to delete this fave meal and any associated orders?" : ""}
                btnOneText={(method === "delete") ? "OOPS! NEVER MIND..." : (method === "remove") ? "NO" : ""}
                btnTwoText={(method === "delete") ? "YES, REMOVE IT" : (method === "remove") ? "YES" : ""}
                showModal={showModal}
                setShowModal={setShowModal}
                id={props?.mealProps?.id}
                deleteItem={(method === "delete") ? props?.deleteMeal : (method === "remove") ? handleRemoveInactiveItem : ""}
              />
            </ContentDiv>
          </ModalDiv >
        )
      }

      <ModalSuccess
        contentDivStyle={{ height: "auto" }}
        firstText={(method === "update") ? "Your Fave Meal Successfully Updated!" : (method === "remove_item") ? "Item  Removed Successfully" : (method === "delete") ? "Fav Meal is removed Successfully." : "You Place the Order Successfully"}
        secondText={(method === "update") ? "You updated the fave meal successfully." : (method === "remove_item" || method === "delete") ? "" : "You created the order successfully."}
        btnOneText="OK"
        btnOneGoto={() => {
          setShowModalSuccess(false);
          props?.setShowMealModal(false);
        }}
        showModal={showModalSuccess}
        setShowModal={setShowModalSuccess}
      />

      {/* ********************* Alert Modal for validations ****************************** */}
      <IonModalAlert
        isOpened={showIonAlertModal}
        setIsOpened={setShowIonAlertModal}
        header={ionAlertHeader}
        subHeader=""
        message={ionAlertMessage}
      />

      {/* *********************Modal Finalize Order****************************** */}
      {showFinalizeModal &&
        <ModalFinalizeOrder
          showFinalizeModal={showFinalizeModal}
          setShowFinalizeModal={setShowFinalizeModal}
          finalizeData={scheduleOrderedData}
          setHideBackModal={setHideBackModal}
          favMealObj={scheduleOrderedData?.data}
        />
      }
    </>

  );
}



