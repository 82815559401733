import { ButtonInitial, Flex, FlexBetween, InitialDiv, SubText1, TextHead4 } from "../../styled/styled";
import { switchSentenceCase, trackPixelEvent } from "../../../utils/utilities";
import RadioButtonText from "../buttons/RadioButtonText";
import React, { useEffect, useState } from "react";
import { getUserInfo } from "../../../services/user";
import { userPaymentCardListApi } from "../../../services/paymentCards";
import ModalAddPaymentCart from "../modals/ModalAddPaymentCart";
import Spinner from "../spinner/Spinner";

export default function PaymentCart({ paymentId, userId, changePayment, selectedCard }) {

    //******************* Fetching States *************************
    const user = getUserInfo();

    //******************* Hooks *************************
    const [showBelowPaymentSection, setShowBelowPaymentSection] = useState(false);
    const [selectedPayment, setSelectedPayment] = useState("");
    const [showNewPaymentModal, setShowNewPaymentModal] = useState(false);
    const [cardList, setCardList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [updatingPage, setUpdatingPage] = useState(false);
    const [emailRequired, setEmailRequired] = useState(false);


    //******************* first time page rendering *************************
    useEffect(() => {
        callPaymentData()
    }, []);

    //*******************  Functions *************************
    const callPaymentData = async () => {
        try {
            const response = await userPaymentCardListApi(userId);
            const data = response?.data?.data || [];
            setCardList(data);
            trackPixelEvent('User Payment card lists');
            const default_card = data?.filter((item: any) => item?.default_card === true);
            setSelectedPayment(selectedCard ? selectedCard : default_card[0]?.id);
            paymentId(default_card[0]?.id);
            setLoading(false);
            setUpdatingPage(false);
            setEmailRequired(false);
        }
        catch (e) {
            console.log(e);
            setLoading(false)
            setEmailRequired(e?.response?.data?.data?.message === "Please add email")
        }
    };

    const compareDate = (inputMonth: any, inputYear: any) => {
        const month = inputMonth;
        const year = inputYear;
        const currentDateTime = new Date();
        const currentYear = currentDateTime.getFullYear();
        const currentMonth = currentDateTime.getMonth() + 1;

        // Check if the year is in the past
        if (Number(year) < currentYear) {
            return "Card Expired";
        }

        // Check if the year is the current year and the month is in the past
        if (Number(year) === currentYear && Number(month) < currentMonth) {
            return "Card Expired";
        }
    }


    const handlePaymentChange = (id: any) => {
        setSelectedPayment(id);
        paymentId(id);
    };


    const handleCloseModal = (value: any) => {
        setShowNewPaymentModal(value);
    };

    const handleUpdateData = () => {
        setUpdatingPage(true);
        callPaymentData();
    };

    return (
        <>

            {(loading || updatingPage) && <InitialDiv style={{ display: "flex", justifyContent: "center", alignItems: "center" }} ><Spinner /></InitialDiv>}

            {(cardList?.length > 0 && !loading && !updatingPage)
                &&
                <>
                    {(changePayment) &&
                        <SubText1 className="!text-green-600 underline !font-semibold text-right cursor-pointer" onClick={() => setShowBelowPaymentSection(prev => !prev)}>CHANGE PAYMENT</SubText1>
                    }
                    <InitialDiv className="text-left">
                        {(cardList?.length > 0 && !loading && !updatingPage)
                            &&
                            cardList?.map((item: any, index: any) => {
                                return (
                                    (item?.id == selectedPayment)
                                    &&
                                    <React.Fragment key={index}>
                                        <FlexBetween className="mt-2"><TextHead4>XXXX XXXX XXXX {item?.last4}</TextHead4></FlexBetween>
                                        <SubText1 className="mt-1">{item?.exp_month}/{String(item?.exp_year).slice(2, 4)} <label className="text-red-600">{compareDate(item?.exp_month, item?.exp_year)}</label> - {switchSentenceCase("head text", item?.brand)}</SubText1>
                                    </React.Fragment>
                                )
                            })
                        }

                        {/* ***********************Bottom Section for Existing Payment and for Adding New Payment Card-less****************************** */}
                        {(showBelowPaymentSection === true && changePayment)
                            &&
                            <>
                                <InitialDiv className="my-6">
                                    <ButtonInitial onClick={() => setShowNewPaymentModal(prev => !prev)} style={{ color: "#22A45D", border: "1px solid #22A45D" }}>
                                        {switchSentenceCase("primary button", "ADD NEW CARD")}
                                    </ButtonInitial>
                                </InitialDiv>

                                <InitialDiv>
                                    {(cardList?.length > 0 && !loading && !updatingPage)
                                        &&
                                        cardList?.map((item: any, index: any) =>
                                            <React.Fragment key={index}>
                                                <FlexBetween
                                                    style={{
                                                        boxShadow: "1px 3px 8px -1px #dcf0dccf",
                                                        padding: "15px",
                                                        borderRadius: "7px",
                                                        margin: "15px 0px"
                                                    }}
                                                >
                                                    <InitialDiv >
                                                        <Flex>
                                                            <TextHead4>XXXX XXXX XXXX {item?.last4}</TextHead4>
                                                        </Flex>
                                                        <SubText1 className="mt-1">{item?.exp_month}/{String(item?.exp_year).slice(2, 4)} <label className="text-red-600">{compareDate(item?.exp_month, item?.exp_year)}</label> - {switchSentenceCase("head text", item?.brand)}</SubText1>
                                                    </InitialDiv>

                                                    <InitialDiv style={{
                                                        display: "flex",
                                                        flexFlow: "column",
                                                        justifyContent: "space-between"
                                                    }}>
                                                        <RadioButtonText
                                                            key={index}
                                                            index={index}
                                                            name="payment"
                                                            value={item?.id}
                                                            checked={selectedPayment}
                                                            option={item}
                                                            onSelect={() => { handlePaymentChange(item?.id) }}
                                                            border={{ borderBottom: "none" }}
                                                        />
                                                    </InitialDiv>
                                                </FlexBetween>
                                            </React.Fragment>
                                        )
                                    }
                                </InitialDiv>
                            </>
                        }
                    </InitialDiv >
                </>
            }

            {(cardList?.length == 0 && !loading && !updatingPage)
                &&
                <>
                    <SubText1 className="mb-2 text-center">No Card Found! Please Add One.</SubText1>
                    <ButtonInitial onClick={() => setShowNewPaymentModal(prev => !prev)} style={{ color: "#22A45D", border: "1px solid #22A45D" }}>
                        {switchSentenceCase("primary button", "ADD NEW CARD")}
                    </ButtonInitial>
                </>
            }
            {/* *********************Modal Add New Address****************************** */}
            {showNewPaymentModal
                &&
                <ModalAddPaymentCart
                    showPaymentModal={showNewPaymentModal}
                    setShowPaymentModal={handleCloseModal}
                    emailRequired={emailRequired}
                    updateData={handleUpdateData}
                />
            }
        </>
    );
}