import React from "react";
import Divider from "../../components/widgets/divider/Divider";
import Section from "../../components/widgets/section/Section";
import ItemQtyCompo from "./ItemQtyCompo";
import TopHead from "../../components/widgets/head/TopHead";
import { useHistory } from "react-router";
import { ButtonGreen, TextHead2, TextHead5 } from "../../components/styled/styled";
import { switchSentenceCase, properNameFormate } from "../../utils/utilities";
import { faAngleDown, faAngleLeft } from "@fortawesome/pro-solid-svg-icons";
import { useSelector } from "react-redux";


export default function Faves() {

  const history = useHistory();

  const GotoHandle = () => {
    history.push('/productdetail');
  }

  const { addfavMealItem } = useSelector((state: any) => state.getRestaurantsMenuLists)

  return (
    <div className="screen">

      <TopHead
        title="Add to Fave Meals"
        leftTextIcon={faAngleLeft}
        leftIconStyle={{ fontSize: "24px" }}
        rightTextIcon={faAngleDown}
        rightIconStyle={{ marginLeft: "4px" }}
        rightText="Filter"
        goToHandle={GotoHandle}
      />

      <Divider />

      <div className="grow overflow-auto">

        <Section>
          <TextHead2 className="mb-2 !text-black">My Fave Meals</TextHead2>
          {/* <OneSideTextToggle leftText="My Fave Meals" style={{ fontWeight: "500" }} beforeBtnText="Select All" switchState={faveMealsState} handleOnChangeToggle={FaveMealsState} /> */}
          <Divider />

          {(addfavMealItem?.length > 0) && addfavMealItem?.map((elem: any, index: any) => (<React.Fragment key={index}><ItemQtyCompo leftText={properNameFormate(elem?.name)} style={{ paddingLeft: "15px" }} itemCount={elem?.quantity} product_Id={elem?.product_id} /><Divider /></React.Fragment>))}


          <TextHead5
            className="text-green-600 underline italic !mt-4 cursor-pointer"
            onClick={() => history.push("/restaurant")}
          >
            Add Another Item...
          </TextHead5>
          <ButtonGreen onClick={() =>
            history.push("/newmealfave")
          }
            style={{ marginTop: "25px" }}
          >
            {switchSentenceCase("primary button", "CREATE NEW FAVE MEAL")}
          </ButtonGreen>
        </Section>
      </div>
    </div >
  );
}

