import React, { useRef } from "react";
import styled from "styled-components";
import { finalizeItemPriceCalculate, priceCalculator, priceFormatterUsd, properNameFormate, sumOfMealInCart, switchSentenceCase } from "../../../utils/utilities";
import { ButtonGreen, FlexBetween, TextHead5, TextHead4, TextHead1, moveTopBottom, InitialDiv, Flex, SubText1, ItemCount } from "../../styled/styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector } from "react-redux";
import Section from "../section/Section";
import { faXmark } from "@fortawesome/pro-solid-svg-icons";
import Accordion from "../accordian/AccordionStyled";
import ToolTip from "../alerts/tooltip";
import AccordionCheckoutCustomization from "../accordian/AccordionCheckoutCustomization";
import PaymentCart from "../paymentscomponent/PaymentCart";
import ShowProductCustomizations from "./ShowProductCustomizations";
import useDarkMode from "../../hooks/darkmode/DarkMode";
import CheckoutProductCustomization from "../../../pages/orderFood/CheckoutProductCustomization";

const ModalDiv = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1000;
  overflow-y: auto;
`;

const ContentDiv = styled.div<{ isDarkMode: boolean }>`
  background-color:${props => (props?.isDarkMode ? "#2e2e2e" : "#fff")};
  margin-bottom: 85px;
  margin-top: 80px;
  padding: 10px;
  z-index: 1000;
  width: 600px;
  max-width: 90%;
  border-radius: 20px;
  text-align: center;
  height: fit-content;
  animation: ${moveTopBottom} 0.4s ease-in-out;
`;

const BackModalBtn = styled.div`
  position: relative;
  right: 50%;
  font-size: 17px;
  cursor: pointer;
  margin-top: 2px;
`;

const BlockContainer = styled.div`
  display: block;
  width: -webkit-fill-available;
  text-align: left;
`;

export default function ModalOrderDetails(props: any) {

    const { upcomingOrdersMealDetails } = useSelector((state: any) => state?.upcomingOrderState);
    const rescheduleData = upcomingOrdersMealDetails[0];
    const totalPrice = (Number(rescheduleData?.attributes?.favMeal?.data?.attributes?.total) + Number(rescheduleData?.attributes?.driver_tip?.toFixed(2)) + Number(1.50)).toFixed(2);

    const isDarkMode = useDarkMode();
    const modalRef = useRef();

    const handlePaymentId = (id: any) => {
    }

    const closeModal = (e: any) => {
        if (modalRef.current === e.target) {
            props?.setShowMealModal(false);
        }
    };

    console.log("rescheduleData", rescheduleData);


    return (
        <>
            {(props?.showMealModal)
                &&
                (
                    <ModalDiv onClick={closeModal}>
                        <ContentDiv isDarkMode={isDarkMode}>
                            <div className="grow overflow-auto">

                                <InitialDiv className="mx-2">
                                    <BackModalBtn>
                                        <FontAwesomeIcon icon={faXmark} onClick={() => props?.setShowMealModal((prev: any) => !prev)} />
                                    </BackModalBtn>

                                    <TextHead1
                                        style={{ marginBottom: "15px", marginTop: "-25px", textAlign: "center" }}
                                        className="text-green-600 text-center"
                                    >
                                        Order Details
                                    </TextHead1>

                                    <SubText1 className="mt-1.5">
                                        {(rescheduleData?.attributes?.favMeal?.data?.attributes?.restaurant?.data?.attributes?.name) && rescheduleData?.attributes?.favMeal?.data?.attributes?.restaurant?.data?.attributes?.name}
                                    </SubText1>
                                </InitialDiv>

                                <InitialDiv className="mx-4">
                                    {/********************  Meal Cart Section ******************************/}
                                    <Accordion title="Meal Items" opened={true}>
                                        {rescheduleData?.attributes?.mealMeOrderObject !== null ?
                                            rescheduleData?.attributes?.mealMeOrderObject?.final_quote?.items.map((item: any, index: any) => {
                                                return (
                                                    <React.Fragment key={index}>
                                                        <Flex className="mb-4" >
                                                            <ItemCount>{index + 1}</ItemCount>
                                                            <BlockContainer>
                                                                <FlexBetween style={{ alignItems: "center" }}>
                                                                    {/* w-[200px] */}
                                                                    <Flex>
                                                                        <TextHead4 className="underline">
                                                                            {item?.name}
                                                                        </TextHead4>
                                                                        {(item?.notes) &&
                                                                            <ToolTip instructions={item?.notes} />
                                                                        }
                                                                    </Flex>
                                                                    <div className="flex ">
                                                                        <SubText1>
                                                                            {priceFormatterUsd(
                                                                                finalizeItemPriceCalculate(item?.customizations) + item?.base_price)}{" "}[x {item?.quantity}]
                                                                        </SubText1>
                                                                    </div>
                                                                    {/* <div></div> */}
                                                                    {/* <div style={{ marginLeft: "10px", display: "block" }}>
                                                                <div>
                                                                    {priceFormatterUsd(
                                                                        finalizeItemPriceCalculate(item?.customizations) + item?.base_price
                                                                    )}
                                                                </div>{"  "}
                                                                <div>[x {item?.quantity}]</div>
                                                            </div> */}
                                                                </FlexBetween>
                                                                {
                                                                    (item?.customizations?.length > 0)
                                                                    &&
                                                                    <>
                                                                        <AccordionCheckoutCustomization
                                                                            title={"Selected Customizations"}
                                                                            titleSize={"16px"}
                                                                        >
                                                                            <div className="block">
                                                                                <ShowProductCustomizations customizations={item?.customizations} />
                                                                            </div>
                                                                        </AccordionCheckoutCustomization>
                                                                    </>
                                                                }

                                                            </BlockContainer>
                                                        </Flex>
                                                    </React.Fragment >
                                                )
                                            })
                                            :
                                            rescheduleData?.attributes?.favMeal?.data?.attributes?.favItems?.data?.map((faveItem: any, index: any) => {
                                                // <FlexBetween style={{ alignItems: "center" }}>
                                                return (

                                                    <React.Fragment key={index}>
                                                        <Flex className="mb-4" >
                                                            <ItemCount>{index + 1}</ItemCount>
                                                            <BlockContainer>
                                                                <FlexBetween style={{ alignItems: "center" }}>
                                                                    {/* w-[200px] */}
                                                                    <Flex>
                                                                        <TextHead4 className="underline">
                                                                            {properNameFormate(faveItem?.attributes?.product?.data?.attributes?.name)}
                                                                        </TextHead4>
                                                                        {(faveItem?.attributes?.instructions !== "") &&
                                                                            <ToolTip instructions={faveItem?.attributes?.instructions} />
                                                                        }
                                                                    </Flex>
                                                                    <div className="flex ">
                                                                        <SubText1>
                                                                            {priceFormatterUsd(
                                                                                priceCalculator(faveItem?.attributes?.product?.data?.attributes?.price, faveItem?.attributes?.customizations)

                                                                            )}{" "}[x {faveItem?.attributes?.quantity}]
                                                                        </SubText1>
                                                                    </div>
                                                                </FlexBetween>
                                                                {
                                                                    (faveItem?.attributes?.customizations?.length > 0)
                                                                    &&
                                                                    <>
                                                                        <AccordionCheckoutCustomization
                                                                            title={"Selected Customizations"}
                                                                            titleSize={"16px"}
                                                                        >
                                                                            <div className="block">
                                                                                <CheckoutProductCustomization customizations={faveItem?.attributes?.customizations} />
                                                                            </div>
                                                                        </AccordionCheckoutCustomization>
                                                                    </>
                                                                }
                                                            </BlockContainer>
                                                        </Flex>
                                                    </React.Fragment >
                                                )
                                            })
                                        }
                                    </Accordion>

                                    {/********************  Payment Section Section ******************************/}
                                    <Accordion title="Payment" opened={false}>
                                        <PaymentCart paymentId={handlePaymentId} userId={rescheduleData?.attributes?.sponsoredBy?.data?.id} changePayment={false} selectedCard={rescheduleData?.attributes?.payment_card} />
                                    </Accordion>
                                </InitialDiv>
                                <Section>
                                    <FlexBetween>
                                        <TextHead5>Subtotal</TextHead5>
                                        <TextHead5>
                                            {rescheduleData?.attributes?.mealMeOrderObject !== null ? priceFormatterUsd(
                                                rescheduleData?.attributes?.mealMeOrderObject?.final_quote?.quote?.subtotal || 0
                                            ) :
                                                "$" + (sumOfMealInCart(rescheduleData?.attributes?.favMeal?.data?.attributes?.favItems?.data))
                                            }
                                        </TextHead5>
                                    </FlexBetween>
                                    <FlexBetween>
                                        <TextHead5>Taxes</TextHead5>
                                        <TextHead5>
                                            {priceFormatterUsd(
                                                rescheduleData?.attributes?.mealMeOrderObject?.final_quote?.quote?.sales_tax_cents || 0
                                            )}
                                        </TextHead5>
                                    </FlexBetween>
                                    <FlexBetween>
                                        <TextHead5>Service fee</TextHead5>
                                        <TextHead5>
                                            {priceFormatterUsd(
                                                rescheduleData?.attributes?.mealMeOrderObject?.final_quote?.quote?.service_fee_cents || 0
                                            )}
                                        </TextHead5>
                                    </FlexBetween>
                                    <FlexBetween>
                                        <TextHead5>Fees</TextHead5>
                                        <TextHead5>
                                            {priceFormatterUsd(
                                                rescheduleData?.attributes?.mealMeOrderObject?.final_quote?.added_fees?.total_fee_cents || 0
                                            )}
                                        </TextHead5>
                                    </FlexBetween>
                                    <FlexBetween>
                                        <TextHead5>Delivery fee</TextHead5>
                                        <TextHead5>
                                            {priceFormatterUsd(
                                                rescheduleData?.attributes?.mealMeOrderObject?.final_quote?.quote?.delivery_fee_cents || 0
                                            )}
                                        </TextHead5>
                                    </FlexBetween>
                                    <FlexBetween>
                                        <TextHead5>MealFave fee</TextHead5>
                                        <TextHead5>
                                            {rescheduleData?.attributes?.mealMeOrderObject !== null ? priceFormatterUsd(
                                                rescheduleData?.attributes?.mealMeOrderObject?.final_quote?.misc_fees[0]?.fee_amount || 0
                                            ) :
                                                "$1.50"}
                                        </TextHead5>
                                    </FlexBetween>
                                    <FlexBetween>
                                        <TextHead5>Tip</TextHead5>
                                        <TextHead5>
                                            {rescheduleData?.attributes?.mealMeOrderObject !== null ? priceFormatterUsd(
                                                rescheduleData?.attributes?.mealMeOrderObject?.final_quote?.tip || 0
                                            ) :
                                                "$" + rescheduleData?.attributes?.driver_tip?.toFixed(2)
                                            }
                                        </TextHead5>
                                    </FlexBetween>
                                    {rescheduleData?.attributes?.mealMeOrderObject?.final_quote?.promotion &&
                                        <FlexBetween>
                                            <TextHead5>Discount</TextHead5>
                                            <TextHead5>
                                                - {priceFormatterUsd(rescheduleData?.attributes?.mealMeOrderObject?.final_quote?.promotion?.discount_total || 0)}
                                            </TextHead5>
                                        </FlexBetween>}
                                    <FlexBetween>
                                        <TextHead5 className="!font-medium">Total</TextHead5>
                                        <TextHead5 className="!font-medium">
                                            {rescheduleData?.attributes?.mealMeOrderObject !== null ?
                                                (priceFormatterUsd(
                                                    (rescheduleData?.attributes?.mealMeOrderObject?.final_quote?.quote?.subtotal || 0) +
                                                    (rescheduleData?.attributes?.mealMeOrderObject?.final_quote?.quote?.sales_tax_cents || 0) +
                                                    (rescheduleData?.attributes?.mealMeOrderObject?.final_quote?.quote?.service_fee_cents || 0) +
                                                    (rescheduleData?.attributes?.mealMeOrderObject?.final_quote?.added_fees?.total_fee_cents || 0) +
                                                    (rescheduleData?.attributes?.mealMeOrderObject?.final_quote?.quote?.delivery_fee_cents || 0) +
                                                    (rescheduleData?.attributes?.mealMeOrderObject?.final_quote?.misc_fees[0]?.fee_amount || 0) +
                                                    (rescheduleData?.attributes?.mealMeOrderObject?.final_quote?.tip || 0) -
                                                    (rescheduleData?.attributes?.mealMeOrderObject?.final_quote?.promotion?.discount_total || 0)
                                                ))
                                                : totalPrice
                                            }
                                        </TextHead5>
                                    </FlexBetween>
                                </Section>
                                <InitialDiv className="mx-4">
                                    <ButtonGreen
                                        className="my-2"
                                        onClick={() => props?.setShowMealModal((prev: any) => !prev)}
                                    >
                                        {switchSentenceCase("primary button", "CLOSE")}
                                    </ButtonGreen>
                                </InitialDiv>
                            </div>
                        </ContentDiv>
                    </ModalDiv >
                )
            }
        </>
    );
}
