import styled from 'styled-components';
import { useDispatch, useSelector } from "react-redux";
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode, A11y, Scrollbar, Navigation, Pagination } from 'swiper';
import sampleMealImage from "../../components/assets/restaurent-Meal-3.jpg";
import 'swiper/css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight } from '@fortawesome/pro-solid-svg-icons';
import { ClosedStripDiv, SubText1 } from '../../components/styled/styled';
import { countryCurrency, isRestaurantOpen, orderFilterByHighestPriceAndSum, properNameFormate, sumOfMealInCart, switchSentenceCase } from '../../utils/utilities';
import { useIonRouter } from '@ionic/react';
import { useState } from 'react';
import ModalViewFavMealItems from '../../components/widgets/modals/ModalViewFavMealItems';

export const PageWrapper = styled.div`
  width: 100%;
  min-width: 343px;
`;

export const Container = styled.div`
  width: 100%;
  border-radius: 0.75rem;
  background: ${({ theme }) => theme.background};
  padding: 4px 20px 28px;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    max-width: 480px;
  `};
  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 0 16px 24px;
  `};
`
export const BodyWrapper = styled.div`
display:flex;
overflow-x: scroll;
::-webkit-scrollbar {
  display: none;
}
`;

export const ButtonRemove = styled.button`
width:140px;
color:#979797;
border: 1px solid #9797976b;
background: #9797971f;
border-radius: 25px;
font-size: 14px;
font-weight: 500;
padding: 2px;
padding-bottom: 5px;
`;

export const ButtonAdd = styled.button`
width:140px;
color: white;
border: 1px solid #9797976b;
background: #22A45D;
border-radius: 25px;
font-size: 14px;
font-weight: 500;
padding: 2px;
padding-bottom: 5px;
`;

export const Block = styled.div`
height: 245px;
display: flex;
flex-direction: column;
justify-content: space-around;
margin: 0px 7px;
`;

const MealName = styled.h3`
overflow: hidden;
text-overflow: ellipsis;
display: -webkit-box;
-webkit-line-clamp: 1;
-webkit-box-orient: vertical;
`;

const ButtonArrowLeft = styled.button`
border-radius: 38px;
    font-size: 22px;
    position: relative;
    bottom: 40px;
    z-index: 10;
    color: #22ae62;
`;

const ButtonArrowRight = styled.button`
    border-radius: 38px;
    font-size: 22px;
    margin-left: 5px;
    position: relative;
    bottom: 48px;
    z-index: 10;
    color: #22ae62;
`;

export default function PreferredMealGrid(props: any) {
  // ******************************* Other Methods *******************************************************
  const router = useIonRouter();
  const dispatch = useDispatch();
  const currentDateTime = new Date();

  // ******************************* States Management *******************************************************
  const { userDeliveryAddress } = useSelector((state: any) => state.userAddresses);
  const showNav = (router.routeInfo.pathname === '/scheduleslotfill') ? true : false;

  // ******************************* Hooks *******************************************************
  const [swipe, setSwipe] = useState<any>();
  const [showMealModal, setShowMealModal] = useState(false);
  const [mealItems, setMealItems] = useState<any>({});

  // ******************************* Functions *******************************************************
  const openMealModal = (mealList: any) => {
    setShowMealModal(prev => !prev)
    setMealItems(mealList)
  }

  return (
    <PageWrapper>
      <BodyWrapper className='faves-swiper-cards'>
        <ButtonArrowLeft onClick={() => swipe?.slidePrev()}><FontAwesomeIcon icon={faAngleLeft} /></ButtonArrowLeft>
        <Swiper
          onBeforeInit={(swipper) => setSwipe(swipper)}
          modules={[FreeMode, A11y, Navigation, Pagination, Scrollbar]}
          slidesPerView={"auto"}
          centeredSlides={false}
          freeMode={true}
          grabCursor={true}
        // navigation={true}
        >
          {
            props?.data?.map((meal: any, index: any) => {
              let productImage: any;
              if (meal?.attributes?.favItems?.data.length) {
                if (meal?.attributes?.favItems?.data[0].attributes?.product?.data?.attributes?.thumbnail_image) {
                  productImage = meal?.attributes?.favItems?.data[0].attributes?.product?.data?.attributes?.thumbnail_image;
                } else if (meal?.attributes?.favItems?.data[0].attributes?.product?.data?.attributes?.image) {
                  productImage = meal?.attributes?.favItems?.data[0].attributes?.product?.data?.attributes?.image;
                } else {
                  productImage = meal?.attributes?.favItems?.data[0].attributes?.product?.data?.attributes?.restaurant?.data?.attributes?.logo_photos;
                }
              }

              // ************************* Checking Restaurant Open/Closed & Active/Inactive Condition ************************************
              const isClosedStrip = ((meal?.attributes?.restaurant?.data !== null && userDeliveryAddress?.timezone !== null && userDeliveryAddress?.timezone !== undefined) && !isRestaurantOpen(props?.type === "delivery" ? meal?.attributes?.restaurant?.data?.attributes?.jsonData?.local_hours?.delivery : meal?.attributes?.restaurant?.data?.attributes?.jsonData?.local_hours?.pickup, currentDateTime, userDeliveryAddress?.timezone, false)) ? true : false;
              const isInActive = (meal?.attributes?.status === "INACTIVE") ? true : false;

              // ************************* Getting Cuisiens ************************************
              const mealProp = meal?.attributes?.product?.data?.attributes;

              if (meal?.attributes?.favItems?.data?.length > 1) {
                const products = meal?.attributes?.favItems?.data;
                orderFilterByHighestPriceAndSum(products)
                meal.attributes.favItems.data = products;
              }

              return (
                <SwiperSlide key={index}>
                  <Block className="w-max">
                    <img src={productImage ? productImage : sampleMealImage} alt="" className={isInActive ? "opacity-30 mr-6 rounded-lg h-36 w-36 cursor-pointer object-cover" : "mr-6 rounded-lg h-36 w-36 cursor-pointer object-cover"} onClick={() => openMealModal(meal)} />
                    <div className="flex-content cursor-pointer" style={{ width: "148px", marginTop: (isClosedStrip || isInActive) ? "-20px" : "10px" }} onClick={() => openMealModal(meal)}>
                      {(isClosedStrip || isInActive)
                        &&
                        <ClosedStripDiv>{isInActive ? "Inactive" : "Closed"}</ClosedStripDiv>
                      }
                      <MealName >{meal?.attributes?.name ? switchSentenceCase("head text", meal?.attributes?.name) : switchSentenceCase("head text", properNameFormate(meal?.attributes?.name))}</MealName>
                      <div >{mealProp?.cuisine}</div>
                      <SubText1 className="flex-fav text-sm text-gray-700 my-1.5">
                        {countryCurrency("dollor")}{sumOfMealInCart(meal.attributes?.favItems?.data)}
                        <div>{mealProp?.category?.data?.attributes?.name}</div>
                      </SubText1>
                    </div>
                    {showNav ?
                      <ButtonAdd onClick={() => openMealModal(meal)}>
                        {switchSentenceCase("primary button", "Add to Schedule")}
                      </ButtonAdd>
                      :
                      <ButtonAdd
                        // className={isClosedStrip ? "!cursor-not-allowed !border !border-solid !border-[#5cb383] !bg-[#5cb383]" : ""}
                        onClick={() => openMealModal(meal)}
                      // disabled={isClosedStrip ? true : false}
                      >
                        {switchSentenceCase("primary button", "Order")}
                      </ButtonAdd>
                    }

                  </Block >
                </SwiperSlide>
              )
            })
          }

        </Swiper>
        {showMealModal
          &&
          <ModalViewFavMealItems
            showMealModal={showMealModal}
            setShowMealModal={setShowMealModal}
            mealProps={mealItems}
            favMealId={mealItems?.id}
          />
        }
        <ButtonArrowRight onClick={() => swipe?.slideNext()}><FontAwesomeIcon icon={faAngleRight} /></ButtonArrowRight>
      </BodyWrapper>
    </PageWrapper>
  );
}

