import { IonAlert } from '@ionic/react';


export default function IonModalAlert(props: any) {
    return (
        <>
            <IonAlert
                isOpen={props?.isOpened}
                header={props?.header}
                subHeader={props?.subHeader}
                message={props?.message}
                cssClass={"alertButtonCss"}
                buttons={['OK']}
                onDidDismiss={() => props?.setIsOpened(false)}
            ></IonAlert>
        </>
    );
};



