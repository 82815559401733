import { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import calendar_icon from "../../components/assets/dashboard/calendar-icon.svg";
import GetWindowWidth from "../../components/hooks/GetWindowWidth";


export default function MySchedulesButton() {
  const { windowWidth } = GetWindowWidth();
  const isMobileWidth = windowWidth <= 768;
  const history = useHistory();
  // const { totalFavMeal } = useSelector((state: any) => state.getAllFavData);
  // const { totalExistedFriends } = useSelector((state: any) => state?.userAddresses);
  const { totalForMe } = useSelector((state: any) => state.createScheduleForMe);

  const [getTotalForMeClass, setGetTotalForMeClass] = useState("");


  // useEffect(() => {
  //   getConditionsBasedClasses();

  //   // eslint-disable-next-line
  // }, [totalFavMeal, totalExistedFriends, totalForMe]);


  // const getConditionsBasedClasses = () => {
  //   if (totalFavMeal >= 1 && totalExistedFriends >= 1 && totalForMe >= 1) {
  //     setGetTotalForMeClass("!opacity-100");
  //   } else if (totalFavMeal >= 1 && totalExistedFriends >= 1 && totalForMe === 0) {
  //     setGetTotalForMeClass("!opacity-100");
  //   } else if (totalExistedFriends === 0) {
  //     setGetTotalForMeClass("");
  //   } else {
  //     setGetTotalForMeClass("");
  //   }
  // }


  return (
    <>
      <div className={`getFood_box getfoodNow max-md:w-[50%] max-lg:w-[100%] lg:w-[50%] lg:min-h-32 ${getTotalForMeClass}`}
        onClick={(e: any) => {
          e.preventDefault();
          history.push('/calendar-home');
        }}>
        <div className="w-full">


          <div className="Food_box_icon">
            <img src={calendar_icon} alt="order-img" className="w-[28px]" />
            <span className="Food_order-orange">{totalForMe} {(totalForMe > 1 || totalForMe === 0) ? "" : ""}</span>
          </div>

          <div className="Food_box_text text-center max-md:text-left my-3">
            <span className="text">Meal Calendars {isMobileWidth && <br />}(Recurring Orders) </span>
            <p>Create and manage preset schedules for yourself or friends. Invite others to contribute a meal.</p>
          </div>
        </div >
      </div>
    </>
  );
}

