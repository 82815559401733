import Section from "../../components/widgets/section/Section";
import TopHead from "../../components/widgets/head/TopHead";
import Divider from "../../components/widgets/divider/Divider";
import { Astk, ButtonGreen, InitialDiv, TextArea1, TextBox1, TextHead3, TextHead5 } from "../../components/styled/styled";
import { countryCodeFilter, maxCharacterLimit, removeMask, switchSentenceCase, trackPixelEvent, validateEmail, validatePhone } from "../../utils/utilities";
import { faAngleLeft } from "@fortawesome/pro-solid-svg-icons";
import { sendFriendInvitation } from "../../services/friends";
import { useEffect, useState } from "react";
import { getUserInfo } from "../../services/user";
import IonModalAlert from "../../components/widgets/alerts/IonModalAlert";
import { useIonAlert, useIonViewDidEnter } from "@ionic/react";
import { Tooltip } from "antd";
import { useParams } from "react-router";
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import useDarkMode from "../../components/hooks/darkmode/DarkMode";

export default function FriendsInvite() {
  // *************************** Other Methods **************************************
  const user = getUserInfo();
  const { userinfo } = useParams<any>();
  const isDarkMode = useDarkMode();

  // ************************ Hooks ********************************************
  const [showIonAlertModal, setShowIonAlertModal] = useState(false);
  const [ionAlertHeader, setIonAlertHeader] = useState("");
  const [ionAlertMessage, setIonAlertMessage] = useState("");
  const [presentAlert] = useIonAlert();
  const [values, setValues] = useState({
    fullname: "",
    phonenumber: "",
    email: "",
    invitationmessage: "“Hey! Join MealFave and save your favorite restaurant meals as “Faves.” Then I can see exactly what you like and send you meals on special occasions!”"
  });

  // ******************* Render ******************************
  useEffect(() => {
    if (/^\+?\d+$/.test(userinfo)) {
      setValues((prevValues) => {
        return {
          ...prevValues,
          phonenumber: countryCodeFilter(userinfo),
        };
      });
    } else if (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(userinfo)) {
      setValues((prevValues) => {
        return {
          ...prevValues,
          email: userinfo,
        };
      });
    } else {
      setValues((prevValues) => {
        return {
          ...prevValues,
          fullname: userinfo,
        };
      });
    }
  }, [userinfo]);

  useIonViewDidEnter(() => {
    trackPixelEvent('Friend invite');
  }, []);

  // ********************* Error ********************************
  const [fullnameError, setFullnameError] = useState(false);
  const [phonenumberError, setPhonenumberError] = useState(false);
  const [emailError, setEmailError] = useState(false);

  // ****************** Functions *************************************
  const handleSubmit = async () => {
    const { fullname, phonenumber, email, invitationmessage } = values;
    const validPhoneNo = validatePhone(removeMask(phonenumber));

    if (fullname === "" || fullname.length < 3) {
      setFullnameError(true);
      return false;
    } else {
      setFullnameError(false);
    }

    if (phonenumber === "" || !validPhoneNo) {
      setPhonenumberError(true);
      return false;
    } else {
      setPhonenumberError(false);
    }
    if (emailError) {
      return false;
    }
    const payloadData = {
      data: {
        name: fullname,
        email: email,
        mobile_no: removeMask(countryCodeFilter(phonenumber)),
        message: invitationmessage,
        from_id: user?.id
      }
    };
    try {
      const response = await sendFriendInvitation(payloadData);
      if (response?.statusText === "OK" || response?.status === 200) {
        trackPixelEvent('Friend invitation send');
        presentAlert({
          header: 'Friend Invited !',
          subHeader: `${response?.data?.data?.name} Invitation Sent.`,
          buttons: ['OK'],
        })
        window.history.back();
      }
    } catch (error) {
      console.log(error);
      setShowIonAlertModal(true)
      setIonAlertHeader(error?.response?.data?.data?.message);
      setIonAlertMessage("");
    }
  }

  const handleOnChange = (e: any) => {
    const { name, value } = e.target;
    if (name === "fullname") {
      if (value.trim() === "" || value.length < 3) {
        setFullnameError(true);
      } else {
        setFullnameError(false);
      }
    }
    if (name === "email") {
      if (String(value).trim()?.length > 0) {
        const validEmail = validateEmail(value);
        if (!validEmail) {
          setEmailError(true);
        } else {
          setEmailError(false);
        }
      }
      else {
        setEmailError(false);
      }
    }
    setValues((prevValues) => {
      return {
        ...prevValues,
        [name]: value,
      };
    })
  };

  //Phone Number Validation                            
  const _setUserPhoneNo = (value: string) => {
    const validPhoneNo = validatePhone(removeMask(value));
    if (value === "" || !validPhoneNo) {
      setPhonenumberError(true);
    } else {
      setPhonenumberError(false);
    }
    setValues((prevValues) => {
      return {
        ...prevValues,
        phonenumber: value,
      };
    })
  };

  return (
    <div className="screen">
      <TopHead
        title="Friends"
        leftTextIcon={faAngleLeft}
        leftIconStyle={{ fontSize: "24px" }}
      />
      <Divider />
      <div className="grow overflow-auto">
        <Section>

          {/* ********************* Search Friends Section ******************************* */}
          <TextHead3>
            Invite Friends
          </TextHead3>

          {/* ********************* Message Input Section ******************************* */}
          <InitialDiv className="my-6">
            <TextHead5 className="text-[#595959]">Full Name <Astk>*</Astk></TextHead5>
            <TextBox1
              type="text"
              name="fullname"
              placeholder="Enter Full Name"
              className="!mt-4 mb-6"
              value={values?.fullname}
              onChange={handleOnChange}
              style={{
                border: fullnameError ? "1px solid red" : "",
              }}
            />
            <TextHead5 className="text-[#595959]">Phone Number <Astk>*</Astk> <Tooltip title="This information is needed to send an invitation.">
              <span className="text-green-600 cursor-pointer underline" title="Detailed info here!">
                Why?</span>
            </Tooltip>
            </TextHead5>
            {/* <TextBox1
              type="text"
              name="phonenumber"
              placeholder="Enter Phone Number.."
              className="!mt-4 mb-6"
              value={values?.phonenumber}
              onChange={handleOnChange}
              onKeyDown={handleKeyDown}
              style={{
                border: phonenumberError ? "1px solid red" : "",
              }}
            /> */}
            <PhoneInput
              className={isDarkMode ? "country-selector" : ""}
              international
              countryCallingCodeEditable={false}
              defaultCountry="US"
              limitMaxLength={true}
              placeholder="Enter Your Phone Number"
              name="phonenumber"
              value={values?.phonenumber}
              onChange={_setUserPhoneNo}
              style={{
                border: phonenumberError ? "1px solid red" : "",
                marginBottom: "20px",
              }}
            />

            <TextHead5 className="text-[#595959]">Email (optional) <Tooltip title="This information is needed to send an invitation.">
              <span className="text-green-600 cursor-pointer underline" title="Detailed info here!">
                Why?</span>
            </Tooltip>
            </TextHead5>
            <TextBox1
              type="text"
              name="email"
              placeholder="Enter Email Address"
              className="!mt-4 mb-6"
              value={values?.email}
              onChange={handleOnChange}
              style={{
                border: emailError ? "1px solid red" : "",
              }}
            />
            <TextHead5 className="text-[#595959]">Send a Personalized Invitation</TextHead5>
            <TextArea1
              placeholder="Hey! Join MealFave and save your favorite restaurant meals as “Faves.” Then I can see exactly what you like and send you meals on special occasions!"
              name="invitationmessage"
              className="!mt-4 mb-6 h-[120px]"
              maxLength={maxCharacterLimit}
              value={values?.invitationmessage}
              onChange={handleOnChange}
            />
          </InitialDiv>
          <ButtonGreen className="my-[15px]" onClick={handleSubmit}>{switchSentenceCase("primary button", "SEND INVITATION")}</ButtonGreen>
          {/* <ButtonGray >{switchSentenceCase("primary button", "NO THANKS")}</ButtonGray> */}
        </Section>
      </div >


      {/* *********************Error Alert Modal****************************** */}
      <IonModalAlert
        isOpened={showIonAlertModal}
        setIsOpened={setShowIonAlertModal}
        header={ionAlertHeader}
        subHeader=""
        message={ionAlertMessage}
      />

    </div >
  );
}
