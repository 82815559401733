import styled from 'styled-components';
import useDarkMode from '../../hooks/darkmode/DarkMode';


const FlexBetween = styled.div`
display: flex;
  justify-content: space-between;
  `;


const Title = styled.h2<{ isDarkMode: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 0;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.25;
  color: ${props => (props?.isDarkMode ? 'white' : '#000000bf')};
  cursor: pointer;
  `;


export default function AccordianCartegoryChild(props: any) {
  const isDarkMode = useDarkMode();

  return (
    <>
      < FlexBetween >
        <Title style={props?.style} isDarkMode={isDarkMode} >{props?.text?.categoryName}</Title>
        <button className="text-green-600 font-bold" onClick={props?.handleOnClick}>{props?.text.seeMore}</button>
      </ FlexBetween>
    </>
  );
};

