import store from "../../../redux/store";
import { deleteSingleCategory, getMealCategory, getUserFavoriteMeal, updateCategory } from "../../../services/favourite"
import { getUserInfo } from "../../../services/user";
import { objectType } from "../../../type";
import { trackPixelEvent } from "../../../utils/utilities";

export const setUserFaveMealList = (value: any) => {
    store.dispatch({
        type: "SET_USER_FAVE_MEAL_LIST",
        payload: value
    })
}

export const updateFavMealData = (id: string, updatedData: objectType) => async (dispatch: any) => {
    try {
        dispatch(
            {
                type: "UPDATE_FAV_MEAL_DATA",
                payload: { data: updatedData, id: id }
            })
    }
    catch (error) {
        console.log(error);
    }
}
export const updateFavMealItemData = (id: string, updatedData: objectType, type: string) => async (dispatch: any) => {
    try {
        dispatch(
            {
                type: "UPDATE_FAV_MEAL_ITEM_DATA",
                payload: { data: updatedData, id: id, type }
            })
    }
    catch (error) {
        console.log(error);
    }
}

export const setTotalFaveMealsCount = (value: any) => {
    store.dispatch({
        type: "SET_TOTAL_FAV_MEAL_COUNT",
        payload: value
    })
}

export const setOtherFavMeals = (value: any) => {
    store.dispatch({
        type: "SET_OTHER_FAV_MEALS",
        payload: value
    })
}

export const setUserMealCategory = (value: any) => {
    store.dispatch({
        type: "SET_USER_MEAL_CATEGORY",
        payload: value
    })
}

// export const setUserMealCategory = (id: number) => async (dispatch: any) => {
//     try {
//         let query = {
//             suggested: "All"
//         }
//         const response = await getMealCategory(id, query)
//         if (response) {
//             trackPixelEvent('Get category');
//             dispatch(
//                 {
//                     type: "SET_USER_MEAL_CATEGORY",
//                     payload: response?.data?.data
//                 })
//         }
//     } catch (error) {
//         console.log(error);
//     }
// };

export const deleteSingleFavouriteData = (id: Number, type: string) => async (dispatch: any) => {
    try {
        dispatch(
            {
                type: "DELETE_SINGLE_FAV_ITEM",
                payload: { id, type }
            })

    } catch (error) {
        console.log(error);
    }
}

export const increaseQuantity = (id1: any, id2: any, quantity: any) => async (dispatch: any) => {
    dispatch(
        {
            type: "INCREASE_QUANTITY",
            payload: { id1, id2, quantity }
        })
}


export const deleteSingleCategories = (id: any) => async (dispatch: any) => {
    try {
        await deleteSingleCategory(id,).then((response: any) => {
            trackPixelEvent('Delete single category');
            dispatch(
                {
                    type: "DELETE_SINGLE_CATEGORY_ITEM",
                    payload: response?.data?.data?.id
                })
        })
    } catch (error) {
        console.log(error);
    }
}

export const updatedCategories = (id: any, updatedData: any) => async (dispatch: any) => {
    try {
        await updateCategory(id, updatedData).then((response: any) => {
            trackPixelEvent('Update Category');
            dispatch(
                {
                    type: "UPDATE_CATEGORY",
                    payload: { id: response?.data?.data?.id, updatedData }
                })
        })
    } catch (error) {
        console.log(error);
    }
}


export const deleteSingleFavMealItem = (meal_id: any, item_id: any) => async (dispatch: any) => {
    dispatch(
        {
            type: "DELETE_SINGLE_FAV_MEAL_ITEM",
            payload: { meal_id, item_id }
        })
}

export const setRecentOrderList = (value: any) => {
    store.dispatch({
        type: "SET_ORDER_LIST_DATA",
        payload: value
    })
}

export const setRecentOrderCount = (value: any) => {
    store.dispatch({
        type: "RECENT_ORDER_COUNT",
        payload: value
    })
}

export const makeOrderToFav = (id: any, lat: any, lang: any) => async (dispatch: any) => {
    try {
        const user = getUserInfo();

        const favRes = await getUserFavoriteMeal({
            id: user?.id,
            type: "Fav",
            skip: 0,
            limit: 10,
            lat: lat,
            lang: lang,
        });

        if (favRes) {
            trackPixelEvent('Get user fave meal');
            dispatch(
                {
                    type: "MAKE_ORDER_TO_FAV",
                    payload: { mealId: id, data: favRes.data }
                })
        }
    } catch (error) {
        console.log(error);
    }

}

export const setFaveModalStatus = (value: boolean) => {
    store.dispatch({
        type: "SET_FAVE_MEAL_MODAL_STATUS",
        payload: value
    })
}
