import { ButtonInitial, Flex, FlexBetween, InitialDiv, Pills, SubText1, TextHead4 } from "../../styled/styled";
import { switchSentenceCase, trackPixelEvent } from "../../../utils/utilities";
import React, { useEffect, useState } from "react";
import { changeDefaultAddress, deleteUserAddress, getUserAddress, setLocalDeliveryAddress } from "../../../services/location";
import { useSelector } from "react-redux";
import RadioButtonText from "../buttons/RadioButtonText";
import ModalAddAddressCart from "../modals/ModalAddAddressCart";
import Spinner from "../spinner/Spinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faTrashCan } from "@fortawesome/pro-solid-svg-icons";
import ModalRemoved from "../modals/ModalRemoved";
import { setUserDeliveryAddress } from "../../../pages/profile/redux/locationActionCreator";
import IonModalAlert from "../alerts/IonModalAlert";
import ModalConfirmationForCards from "../modals/ModalConfirmationForCards";
import { getUserData, setUserData } from "../../../services/user";
import { clearRestaurantList, setNearByRestaurants } from "../../../pages/search/redux/searchActionCreator";
import { setRecentOrderList, setUserFaveMealList } from "../../../pages/faves/redux/favouriteActionCreator";
import ModalSuccessForCards from "../modals/ModalSuccessForCards";

export default function AddressesCart({ addressId, title, showDeleteBtn, showList, pageName, pageDataId, changeAddressTxtBtn, userId, setDeliveryAddress, changeAddress, initialLength }) {

    //******************* Fetching States *************************
    const { userDeliveryAddress } = useSelector((state: any) => state.userAddresses);

    //******************* Hooks *************************
    const [showBelowAddressSection, setShowBelowAddressSection] = useState(showList);
    const [userFetchedAddress, setUserFetchedAddress] = useState([]);
    const [selectedLocation, setSelectedLocation] = useState("");
    const [showNewAddressModal, setShowNewAddressModal] = useState(false);
    const [loading, setLoading] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [showDefaultModal, setShowDefaultModal] = useState(false)
    const [deleteAddressId, setDeleteAddressId] = useState();
    const [length, setLength] = useState(0);
    const [showIonAlertModal, setShowIonAlertModal] = useState(false);
    const [ionAlertHeader, setIonAlertHeader] = useState("");
    const [ionAlertMessage, setIonAlertMessage] = useState("");
    const [showModalSuccess, setShowModalSuccess] = useState(false);

    let showUserNotFilledDataInput = (pageName == "odersHome" || pageName == "/schedules") ? true : false;

    //******************* first time page rendering *************************
    useEffect(() => {
        setUserFetchedAddress([]);
        callAddressData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userId]);

    //*******************  Functions *************************
    if (showNewAddressModal) {
        initialLength = 0;
    }

    const callAddressData = async () => {
        if (!userId) return false;
        try {
            setLoading(true);
            const response = await getUserAddress(showList ? true : false, userId)
            if (response) {
                trackPixelEvent('Get user address');
                const data = response?.data?.data || [];
                setUserFetchedAddress(data);
                let newLength = data?.length;

                const defaultAddress = (
                    (pageName === "schedule" && pageDataId != null && initialLength === 1) ||
                        (pageName === "scheduleSlotsEdit" && pageDataId != null && initialLength === 1) ||
                        (pageName === "newMealCheckout" && pageDataId != null && initialLength === 1)
                        ?
                        pageDataId
                        :
                        // (data[0]?.id))
                        ((newLength > length && initialLength === 0) ? (data.slice(-1)[0]?.id) : ((data?.filter((item: any) => item?.attributes?.default_address === true)?.length > 0) ? (data?.filter((item: any) => item?.attributes?.default_address === true)[0]?.id) : (data.slice(-1)[0]?.id)))
                    // ((userId === loggedUser?.id) ? (data?.filter((item: any) => item?.attributes?.default_address == true)[0]?.id) : (data[0]?.id)));
                );

                setSelectedLocation(defaultAddress);
                addressId(defaultAddress);
                const selectedAddress = (data?.filter((item: any) => item?.id === defaultAddress))
                const getFormatAddress = {
                    lat: selectedAddress[0]?.attributes?.latitude,
                    lang: selectedAddress[0]?.attributes?.longitude,
                    streetName: selectedAddress[0]?.attributes?.addressLine1,
                    streetNum: selectedAddress[0]?.attributes?.addressLine2,
                    streetAddress: selectedAddress[0]?.attributes?.street_address,
                    city: selectedAddress[0]?.attributes?.city,
                    state: selectedAddress[0]?.attributes?.state,
                    country: selectedAddress[0]?.attributes?.country,
                    zip: selectedAddress[0]?.attributes?.zip_code,
                    defaultAddressType: selectedAddress[0]?.attributes?.default_address,
                    timezone: selectedAddress[0]?.attributes?.timezone,
                    id: selectedAddress[0]?.id
                };
                if (data?.length > 0 && setDeliveryAddress && userDeliveryAddress?.id !== selectedAddress[0]?.id) {
                    setUserDeliveryAddress(getFormatAddress);
                    await setLocalDeliveryAddress(getFormatAddress);
                }
                setLoading(false);
                setLength(data?.length);
            }
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    };
    const handleLocationChange = async (id: any) => {
        setSelectedLocation(id);
        addressId(id);
        const selectedAddress = (userFetchedAddress?.filter((item: any) => item?.id === id))
        const getFormatAddress = {
            lat: selectedAddress[0]?.attributes?.latitude,
            lang: selectedAddress[0]?.attributes?.longitude,
            streetName: selectedAddress[0]?.attributes?.addressLine1,
            streetNum: selectedAddress[0]?.attributes?.addressLine2,
            streetAddress: selectedAddress[0]?.attributes?.street_address,
            city: selectedAddress[0]?.attributes?.city,
            state: selectedAddress[0]?.attributes?.state,
            country: selectedAddress[0]?.attributes?.country,
            zip: selectedAddress[0]?.attributes?.zip_code,
            defaultAddressType: selectedAddress[0]?.attributes?.default_address,
            timezone: selectedAddress[0]?.attributes?.timezone,
            id: selectedAddress[0]?.id
        }
        if (selectedAddress?.length > 0 && setDeliveryAddress) {
            setUserDeliveryAddress(getFormatAddress);
            await setLocalDeliveryAddress(getFormatAddress);
        }
    };

    
    const handleCloseModal = (value: any) => {
        setShowNewAddressModal(value);
        callAddressData();
    };


    const openModal = (id: any) => {
        setDeleteAddressId(id);
        setShowModal((prev) => !prev);
    };

    const openModalDefault = (id: any) => {
        setSelectedLocation(id)
        setShowDefaultModal((prev) => !prev)
    }

    const handleRejectModal = () => {
        setShowDefaultModal(false);
    };

    const handleUpdateDefaultAddress = async () => {
        try {
            const updatedData = {
                user: userId
            }
            const response = await changeDefaultAddress(selectedLocation, updatedData);

            if (response) {
                const data = response?.data?.data;
                trackPixelEvent('Changed to default address');

                const getFormatAddress = {
                    lat: data?.latitude,
                    lang: data?.longitude,
                    streetName: data?.addressLine1,
                    streetNum: data?.addressLine2,
                    streetAddress: data?.street_address,
                    city: data?.city,
                    state: data?.state,
                    country: data?.country,
                    zip: data?.zip_code,
                    defaultAddressType: data?.default_address,
                    timezone: data?.timezone,
                    id: data?.id
                }
                const localData = await getUserData();
                const newUpdatedUserInfo = {
                    ...localData,
                    user: ({
                        ...localData.user,
                        "city": data?.city,
                    })
                };
                await setUserData(newUpdatedUserInfo);
                clearRestaurantList();
                setNearByRestaurants([]);
                setUserFaveMealList([]);
                setRecentOrderList([]);
                setUserDeliveryAddress(getFormatAddress);
                await setLocalDeliveryAddress(getFormatAddress);
                callAddressData();
                setSelectedLocation(data?.id);
                setShowModalSuccess(true);
            };
        }
        catch (e) {
            setShowIonAlertModal(true)
            setIonAlertHeader(e?.response?.status);
            setIonAlertMessage(e?.response?.statusText);
            console.log(e)
        }
    };

    const deleteAddress = (id: any) => {
        try {
            deleteUserAddress(id).then((response) => {
                let filteredData = userFetchedAddress?.filter((elements: any) => elements?.id !== response?.data?.data?.id);
                setUserFetchedAddress(filteredData);
                trackPixelEvent('Delete user address');
            })
        }
        catch (e) {
            setShowIonAlertModal(true)
            setIonAlertHeader(e?.response?.status);
            setIonAlertMessage(e?.response?.statusText);
            console.log(e);
        }
    }


    return (
        <>
            {loading &&
                <InitialDiv style={{ display: "flex", justifyContent: "center", alignItems: "center" }} >
                    {(userId === "") ?
                        <ButtonInitial className="mt-2" onClick={() => setShowNewAddressModal(prev => !prev)} style={{ color: "#e1e1e1", border: "1px solid #e1e1e1" }}>
                            {switchSentenceCase("primary button", "ADD ADDRESS")}
                        </ButtonInitial>
                        :
                        <Spinner />}

                    {/* {(userId === "") ?
                        "No Address Found" :
                        <Spinner />} */}
                </InitialDiv>}
            {(userFetchedAddress?.length > 0 && !loading)
                &&
                <>
                    <FlexBetween>
                        <TextHead4>{title}</TextHead4>
                        {changeAddress &&
                            ((changeAddressTxtBtn)
                                ?
                                <SubText1 className="!text-green-600 underline !font-semibold text-right cursor-pointer" onClick={() => setShowBelowAddressSection(prev => !prev)}>{changeAddressTxtBtn}</SubText1>
                                :
                                <FontAwesomeIcon className="text-green-600 text-xs cursor-pointer" icon={faPen} onClick={() => setShowBelowAddressSection(prev => !prev)} />)
                        }
                    </FlexBetween>
                    <InitialDiv className="text-left">
                        <InitialDiv onClick={() => setShowBelowAddressSection(prev => !prev)}>
                            {(userFetchedAddress !== null)
                                ?
                                userFetchedAddress?.map((defaultAddressItem: any, index: any) => {
                                    return (
                                        defaultAddressItem?.id === selectedLocation ?
                                            <React.Fragment key={index}>
                                                <FlexBetween><TextHead4>{defaultAddressItem?.attributes?.city}</TextHead4></FlexBetween>
                                                <SubText1>{switchSentenceCase("head text", defaultAddressItem?.attributes?.addressLine1) + (defaultAddressItem?.attributes?.addressLine2 ? " " + switchSentenceCase("head text", defaultAddressItem?.attributes?.addressLine2) : "") + ", " + (defaultAddressItem?.attributes?.city)}</SubText1>
                                                <SubText1>{defaultAddressItem?.attributes?.state}, {defaultAddressItem?.attributes?.country} - {defaultAddressItem?.attributes?.zip_code}</SubText1>
                                            </React.Fragment>
                                            :
                                            ""
                                    )
                                })
                                :
                                <SubText1>No Location Added yet, Please Add At-least One !</SubText1>
                            }
                        </InitialDiv>


                        {/* *********************** Bottom Section for Existing Address and for Adding New Address ****************************** */}
                        {(showBelowAddressSection === true && changeAddress)
                            ?
                            <>
                                <InitialDiv className="my-6">
                                    <ButtonInitial onClick={() => setShowNewAddressModal(prev => !prev)} style={{ color: "#22A45D", border: "1px solid #22A45D" }}>
                                        {switchSentenceCase("primary button", "ADD NEW ADDRESS")}
                                    </ButtonInitial>
                                </InitialDiv>
                                <InitialDiv>
                                    {
                                        userFetchedAddress?.map((item: any, index: any) =>
                                            <React.Fragment key={index}>
                                                <FlexBetween
                                                    style={{
                                                        boxShadow: "1px 3px 8px -1px #dcf0dccf",
                                                        padding: "15px",
                                                        borderRadius: "7px",
                                                        margin: "15px 0px",
                                                        overflow: "auto"
                                                    }}
                                                >
                                                    <InitialDiv >
                                                        <Flex className="items-end">
                                                            <TextHead4>{item?.attributes?.city} </TextHead4>{item?.attributes?.default_address ?
                                                                <i className="text-gray-500 !ml-2"><SubText1>(Default)</SubText1></i> : showList === true ? <p className="text-green-600 font-medium underline mt-[-2px] ml-2 py-0 text-[12px]" onClick={() => { openModalDefault(item?.id) }}>Make Default</p> : ""}
                                                        </Flex>
                                                        <SubText1 className="mt-2">{switchSentenceCase("head text", item?.attributes?.addressLine1) + (item?.attributes?.addressLine2 ? " " + switchSentenceCase("head text", item?.attributes?.addressLine2) : "") + ", " + (item?.attributes?.city)}, {" "}</SubText1>
                                                        <SubText1>{item?.attributes?.state}, {item?.attributes?.country} - {item?.attributes?.zip_code}</SubText1>
                                                    </InitialDiv>

                                                    <InitialDiv style={{
                                                        display: "flex",
                                                        flexFlow: "column",
                                                        justifyContent: "space-between",
                                                        textTransform: "capitalize"
                                                    }}>

                                                        <InitialDiv >
                                                            <Pills style={{
                                                                background: "#21734633",
                                                                color: "#22A45D",
                                                                fontSize: "10px"
                                                            }}
                                                            >
                                                                {item?.attributes?.type}
                                                            </Pills>
                                                        </InitialDiv>
                                                        <InitialDiv style={{
                                                            display: "flex",
                                                            flexFlow: "column",
                                                            alignItems: "end"
                                                        }}>
                                                            {
                                                                showDeleteBtn
                                                                    ?
                                                                    (item?.attributes?.default_address !== true) && <FontAwesomeIcon className="text-red-600 text-xs cursor-pointer" icon={faTrashCan} onClick={() => { openModal(item?.id) }} />
                                                                    :
                                                                    < RadioButtonText
                                                                        key={index}
                                                                        index={index}
                                                                        name="location"
                                                                        value={item?.id}
                                                                        checked={selectedLocation}
                                                                        option={item}
                                                                        onSelect={() => { handleLocationChange(item?.id) }}
                                                                        border={{ borderBottom: "none" }}
                                                                    />
                                                            }
                                                        </InitialDiv>
                                                    </InitialDiv>

                                                </FlexBetween>
                                            </React.Fragment>
                                        )}
                                </InitialDiv>
                            </>
                            :
                            ""
                        }
                    </InitialDiv >
                </>
            }

            {
                (userFetchedAddress?.length === 0 && !loading)
                &&
                <>
                    <SubText1 className="mb-2 text-center">No Address Found! Please Add One.</SubText1>
                    <ButtonInitial onClick={() => setShowNewAddressModal(prev => !prev)} style={{ color: "#22A45D", border: "1px solid #22A45D" }}>
                        {switchSentenceCase("primary button", "ADD NEW ADDRESS")}
                    </ButtonInitial>
                </>
            }
            {/* *********************Modal Add New Address****************************** */}
            {showNewAddressModal &&
                <ModalAddAddressCart
                    showAddressModal={showNewAddressModal}
                    setShowAddressModal={handleCloseModal}
                    addressList={userFetchedAddress}
                    setShowNewAddressModal={setShowNewAddressModal}
                    userId={userId}
                    showUserNotFilledDataInput={showUserNotFilledDataInput}
                />
            }

            {/* *********************Modal Delete Address****************************** */}
            <ModalConfirmationForCards
                firstText="Are you sure you want to"
                secondText="make this Address Default?"
                btnOneText="NO"
                btnTwoText="YES"
                showModal={showDefaultModal}
                setShowModal={setShowDefaultModal}
                rejectAction={handleRejectModal}
                confirmAction={handleUpdateDefaultAddress}
                styleBtnSecondText={{ backgroundColor: "#22A45D" }}
                iconBackgroundColor={""}
            // id= {makeDefaultId}
            // deleteItem={deleteAddress}
            // id={deleteAddressId}
            />

            <ModalSuccessForCards
                firstText={"Address Changed!"}
                secondText={"Your address set to default successfully."}
                btnOneText="OK"
                btnTwoText=""
                btnTwoStyle={{ border: "1px solid #e1e1e1", marginTop: "15px" }}
                btnOneGoto={() => {
                    setShowModalSuccess(false);
                }}
                btnTwoGoto={() => { }}
                showModal={showModalSuccess}
                setShowModal={setShowModalSuccess}
                buttonOneLoader={false}
                buttonTwoLoader={false}
            />

            {/* *********************Modal Delete Address****************************** */}
            <ModalRemoved
                firstText="Are you sure you want to"
                secondText="remove this Address?"
                btnOneText="OOPS!  NEVER MIND..."
                btnTwoText="YES, REMOVE IT"
                showModal={showModal}
                setShowModal={setShowModal}
                deleteItem={deleteAddress}
                id={deleteAddressId}
            />

            {/* *********************Error Alert Modal****************************** */}
            <IonModalAlert
                isOpened={showIonAlertModal}
                setIsOpened={setShowIonAlertModal}
                header={ionAlertHeader}
                subHeader=""
                message={ionAlertMessage}
            />
        </>
    );
}