import { useEffect, useRef, useState } from "react";
import {
  Flex,
  FlexBetween,
  ItemCount,
  SubText1,
  TextHead1,
  TextHead4,
  TextHead5,
  moveTopBottom,
} from "../../styled/styled";
import styled from "styled-components";
import {
  priceCalculator,
  priceFormatterUsd,
  sumOfMealInCart,
  switchSentenceCase,
  textFormatter,
} from "../../../utils/utilities";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft
} from "@fortawesome/pro-solid-svg-icons";
import { faHeart } from "@fortawesome/pro-regular-svg-icons";
import Section from "../section/Section";
import Divider from "../divider/Divider";
import ToolTip from "../alerts/tooltip";
import AccordionCheckoutCustomization from "../accordian/AccordionCheckoutCustomization";
import CheckoutProductCustomization from "../../../pages/orderFood/CheckoutProductCustomization";
import useDarkMode from "../../hooks/darkmode/DarkMode";

// **************************CSS in Styled Components for this page*******************************************
const ModalDiv = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1000;
  overflow-y: auto;
`;

const ContentDiv = styled.div<{ isDarkMode: boolean }>`
    background-color: ${props => (props?.isDarkMode ? '#2e2e2e' : '#fff')};
    margin-bottom: 85px;
    margin-top: 80px;
    padding: 10px;
    z-index: 1000;
    width: 600px;
    max-width: 90%;
    border-radius: 20px;
    text-align: center;
    height: fit-content;
    animation: ${moveTopBottom} .4s ease-in-out;
`;

const HeartModalIcon = styled.div`
    padding: 10px;
    background: #f03f3f29;
    border-radius: 15px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: -65px;
    width: fit-content;
    position: relative;
    top: -60px;
    backdrop-filter: blur(7.5px);
`;

const BlockContainer = styled.div`
  display: block;
  width: -webkit-fill-available;
  text-align: left;
`;

const BackModalBtn = styled.div`
  position: relative;
  right: 49%;
  font-size: 17px;
  cursor: pointer;
`;

const TextMealChildren1 = styled.h3`
  color: #868686;
  font-size: 18px;
  font-weight: 400;
  font-style: italic;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

export default function ModalSingleFavItemDetail(props: any) {

  // **************************Other Methods*******************************************
  const modalRef = useRef();
  const isDarkMode = useDarkMode();

  // *************************States Managment**************************************
  const { SingleFaveItemsDetail } = useSelector((state: any) => state?.createScheduleForMe);

  // ****************************** Hooks ******************************************
  const [mealItemList, setMealItemList] = useState([]);
  const [favItem, setFavItems] = useState([]);
  const [mealDetails, setMealDetails] = useState({ favMealName: null, restaurantName: null, restaurantDescription: null })

  // ****************************** First time Render ******************************************
  useEffect(() => {
    callData();
  }, []);


  // *************************Functions**************************************
  const callData = () => {
    let tempMealItemList = [];
    setFavItems(SingleFaveItemsDetail?.items?.attributes?.favItems?.data);
    setMealDetails({
      favMealName: SingleFaveItemsDetail?.items?.attributes?.name,
      restaurantName: SingleFaveItemsDetail?.items?.attributes?.favItems?.data[0]?.attributes?.product?.data?.attributes?.restaurant?.data?.attributes?.name,
      restaurantDescription: SingleFaveItemsDetail?.items?.attributes?.favItems?.data[0]?.attributes?.product?.data?.attributes?.restaurant?.data?.attributes?.description
    })
    SingleFaveItemsDetail?.items?.attributes?.favItems?.data?.forEach((currentObj: any) => {
      //********************** */ Calling the recursive function to retrieve selected data ************************************
      tempMealItemList?.push({
        id: currentObj?.id,
        name: currentObj?.attributes?.product?.data?.attributes?.name,
        price: Number(currentObj?.attributes?.product?.data?.attributes?.price),
        customizations: currentObj?.attributes?.customizations,
        quantity: Number(currentObj?.attributes?.quantity),
        instructions: currentObj?.attributes?.instructions
      })
    });
    setMealItemList(tempMealItemList);
  };

  const closeModal = (e: any) => {
    if (modalRef.current === e.target) {
      props?.setShowMealModal(false);
    }
  };

  return (
    <>
      {(props?.showMealModal)
        &&
        (
          <ModalDiv onClick={closeModal}>
            <ContentDiv isDarkMode={isDarkMode}>
              <BackModalBtn>
                <FontAwesomeIcon
                  icon={faChevronLeft}
                  onClick={() => props?.setShowMealModal((prev: any) => !prev)}
                />
              </BackModalBtn>
              <HeartModalIcon>
                <FontAwesomeIcon
                  icon={faHeart}
                  style={{ fontSize: "30px", color: "#ff000082" }}
                />
              </HeartModalIcon>
              <TextHead1>{switchSentenceCase("head text", mealDetails?.favMealName)}</TextHead1>

              <SubText1 className="mt-1.5">
                {switchSentenceCase("head text", mealDetails?.restaurantName)}
              </SubText1>

              <TextMealChildren1>
                {switchSentenceCase("head text", mealDetails?.restaurantDescription)}
              </TextMealChildren1>

              <Section>
                {mealItemList?.map((mealItem: any, index: any) => (
                  <Flex key={index} className="mb-4 items-baseline">
                    <ItemCount>{index + 1}</ItemCount>

                    <BlockContainer>
                      <FlexBetween style={{ alignItems: "center" }}>

                        <Flex>
                          <TextHead4 className="underline">
                            {switchSentenceCase("head text", textFormatter(mealItem?.name))}
                          </TextHead4>

                          {(mealItem?.instructions !== "") &&
                            <ToolTip instructions={mealItem?.instructions} />
                          }
                        </Flex>
                        <div className="flex">
                          <SubText1>
                            {priceFormatterUsd(priceCalculator(mealItem?.price, mealItem?.customizations))}{" "}[x {mealItem?.quantity}]
                          </SubText1>
                        </div>
                      </FlexBetween>
                      {
                        (mealItem?.customizations?.length > 0)
                        &&
                        <>
                          <AccordionCheckoutCustomization
                            title={"Selected Customizations"}
                            titleSize={"16px"}
                          >
                            <div className="block">
                              <CheckoutProductCustomization customizations={mealItem?.customizations} />
                            </div>
                          </AccordionCheckoutCustomization>
                        </>
                      }
                      {/* <div className="my-2 flex justify-end items-center">
                        <TextHead5 className="!font-medium">
                          {priceFormatterUsd(priceCalculator(mealItem?.price, mealItem?.customizations))} [x{mealItem?.quantity}]
                        </TextHead5>
                      </div> */}
                    </BlockContainer>
                  </Flex>
                ))}

                <FlexBetween className="mt-8">
                  <TextHead5 className="!font-medium">Subtotal</TextHead5>
                  <TextHead5 className="!font-medium">${sumOfMealInCart(favItem)}</TextHead5>
                </FlexBetween>
                <Divider />
              </Section>
            </ContentDiv>
          </ModalDiv >
        )
      }
    </>
  );
}
