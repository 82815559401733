import { faMagnifyingGlass } from "@fortawesome/pro-solid-svg-icons";
import Section from "../../components/widgets/section/Section";
import { Flex, InputText, TextHead1, TextHead4 } from "../../components/styled/styled";
import { useHistory } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import styled from "styled-components";
import CategoryImg1 from "../../components/assets/topCatego-MealImg-1.jpg";
import CategoryImg2 from "../../components/assets/topCatego-MealImg-2.jpg";
import CategoryImg3 from "../../components/assets/chow-fun.png";
import CategoryImg4 from "../../components/assets/oysters-on-fire.png";
import CategoryImg5 from "../../components/assets/Meal.jpg";
import CategoryImg6 from "../../components/assets/oysters-on-ice.png";


const ContentDiv = styled.div`
display: grid;
grid-template-columns: auto auto;
justify-content: center;
`;


const CardDiv = styled.div`
    background: #000000e0;
    width: 140px;
    height: 140px;
    border-radius: 20px;
    margin: 10px;
`;


const TextFood = styled.h4`
    position: relative;
    bottom: 75px;
    left: 25px;
    color: white;
    font-size: 15px;
    width:94px;
`;



export default function SearchFood() {
    const history = useHistory();
    const [showBox, setShowBox] = useState(false);

    return (
        <div className="screen">
            <Section>
                <Flex className="items-center" onClick={() => setShowBox(true)}>
                    <FontAwesomeIcon icon={faMagnifyingGlass} className="text-gray-500 mr-3.5 mt-3.5" />
                    <TextHead1 >
                        Search
                    </TextHead1>
                </Flex>
                {showBox ?
                    <InputText className="mt-2" placeholder="Search Food Categories" />
                    :
                    null
                }
            </Section>
            <div className="grow overflow-auto">
                <Section>
                    <TextHead4 >Top Categories</TextHead4>
                </Section>
                <Section align="center">
                    <ContentDiv>
                        <CardDiv onClick={() => history.push({ pathname: "/yourorders" })}>
                            <img src={CategoryImg1} alt="" className="top-Category-Card" />
                            <TextFood>Fast Food</TextFood>
                        </CardDiv>
                        <CardDiv>
                            <img src={CategoryImg2} alt="" className="top-Category-Card" />
                            <TextFood>Breakfast & Brunch</TextFood>
                        </CardDiv>
                    </ContentDiv>
                    <ContentDiv>
                        <CardDiv>
                            <img src={CategoryImg3} alt="" className="top-Category-Card" />
                            <TextFood>Mexican</TextFood>
                        </CardDiv>
                        <CardDiv>
                            <img src={CategoryImg4} alt="" className="top-Category-Card" />
                            <TextFood>Bakery</TextFood>
                        </CardDiv>
                    </ContentDiv>
                    <ContentDiv>
                        <CardDiv>
                            <img src={CategoryImg5} alt="" className="top-Category-Card" />
                            <TextFood>Deserts</TextFood>
                        </CardDiv>
                        <CardDiv>
                            <img src={CategoryImg6} alt="" className="top-Category-Card" />
                            <TextFood>Burgers</TextFood>
                        </CardDiv>
                    </ContentDiv>
                </Section>
            </div>
        </div >
    );
}