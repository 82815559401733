import { faAngleLeft } from "@fortawesome/pro-solid-svg-icons";
import { useIonViewDidEnter } from "@ionic/react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import styled from 'styled-components';
import { SubText1, TextHead4 } from "../../components/styled/styled";
import AccordianCartegoryChild from "../../components/widgets/accordian/AccordianCartegoryChild";
import Accordion, { SubAccordion } from "../../components/widgets/accordian/AccordionStyled";
import AddressList from "../../components/widgets/address/AddressList";
import BothSideTextToggle from "../../components/widgets/buttons/BothSideTextToggle";
import RestaurantCardGrid from "../../components/widgets/cards/RestaurantCardGrid";
import RestaurantCardList from "../../components/widgets/cards/RestaurantCardList";
import Divider from "../../components/widgets/divider/Divider";
import TopHead from "../../components/widgets/head/TopHead";
import SearchRestaurantsByKeywords from "../../components/widgets/searchinput/SearchRestaurantsByKeywords";
import Section from "../../components/widgets/section/Section";
import Spinner from "../../components/widgets/spinner/Spinner";
import { getMealCategory, getUserFavoriteMeal } from "../../services/favourite";
import { restaurantsNearPlace } from "../../services/restaurant";
import { getUserInfo } from "../../services/user";
import { switchSentenceCase, trackPixelEvent, trackStandardPixelEvent } from "../../utils/utilities";
import { setFaveModalStatus, setRecentOrderList, setUserFaveMealList, setUserMealCategory } from "../faves/redux/favouriteActionCreator";
import PreferredMealGrid from "../orderFood/PreferredMealGrid";
import PreferredMealList from "../orderFood/PreferredMealList";
import FavoriteMealGrid from "../profile/FavoriteMealGrid";
import FavoriteMealList from "../profile/FavoriteMealList";
import { clearRestaurantList, setNearByRestaurants, setRestaurantsListLoading, setSearchFilterRedux } from "../search/redux/searchActionCreator";
// import ReactPullToRefresh from 'react-pull-to-refresh';

const Text = styled.div`
    padding: 10px 0px;
    border-top: 1px solid #8080801f;
    border-bottom: 1px solid #8080801f;
    cursor: pointer;
  `;

export default function GetFoodNow(data: any) {

  // ******************************* Other Methods **********************************************
  const dispatch = useDispatch();
  const history = useHistory();
  const userData = getUserInfo();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const type = searchParams.get('type');

  // ******************************* States Management **********************************************
  const { mealCategory, userFaveMealsList, recentOrderList, faveMealModalStatus } = useSelector((state: any) => state.getAllFavData);
  const { nearByRestaurants, isResultLoading } = useSelector((state: any) => state.getUserRestaurantsLists);

  const { userDeliveryAddress, selectedLocationId } = useSelector((state: any) => state.userAddresses);
  const { orderForSomeone } = useSelector((state: any) => state?.upcomingOrderState);

  // ******************************* Hooks **********************************************
  const restaurantData = nearByRestaurants?.slice(0, 10);
  const [switchState, setSwitchState] = useState(true);
  const [faveMealLoading, setFaveMealLoading] = useState(true);
  const [recentOrderLoading, setRecentOrderLoading] = useState(true);
  const [isRefreshed, setIsRefreshed] = useState(false);
  const [nearbyRestaurantLoading, setNearbyRestaurantLoading] = useState(Object.keys(userDeliveryAddress).length === 0 ? true : false);
  const formattedAddress = (userDeliveryAddress?.city) ? ((userDeliveryAddress?.streetNum ? userDeliveryAddress?.streetNum + ", " : "") + userDeliveryAddress?.streetName + ", " + userDeliveryAddress?.city + ", " + userDeliveryAddress?.state + " - " + userDeliveryAddress?.zip) : "";


  // ******************************* First Time Rendering **********************************************
  useEffect(() => {
    setSearchFilterRedux({});
    setFaveModalStatus(false);
    (async () => {
      try {
        let query = {
          suggested: "All"
        }
        const response = await getMealCategory(userData?.id, query);
        setUserMealCategory(response?.data?.data);
      }
      catch (e) {
        console.log(e);
      }
    })();
    setFaveMealLoading(true);
    setRecentOrderLoading(true);
  }, [isRefreshed]);

  useIonViewDidEnter(() => {
    trackPixelEvent('Get food now');
  }, []);


  // ******************************* Functions **********************************************
  // function handleRefresh(event: CustomEvent<RefresherEventDetail>) {
  //   setTimeout(() => {
  //     setIsRefreshed(prev => !prev);
  //     setNearByRestaurants([]);
  //     setUserFaveMealList([]);
  //     setRecentOrderList([]);
  //     event.detail.complete();
  //   }, 2000);
  // }
  // const handleRefresh = (): Promise<void> => {
  //   return new Promise((resolve) => {
  //     setTimeout(() => {
  //       setIsRefreshed(prev => !prev);
  //       setNearByRestaurants([]);
  //       setUserFaveMealList([]);
  //       setRecentOrderList([]);
  //       setFaveModalStatus(false);
  //       resolve();
  //     }, 2000);
  //   });
  // };

  const isAddressTrue = userDeliveryAddress?.id !== undefined ? true : false;

  useEffect(() => {
    setFaveMealLoading(true);
    setRecentOrderLoading(true);

    (async () => {
      if (userDeliveryAddress?.city === undefined || userDeliveryAddress?.city === "") {
        setFaveMealLoading(false);
        setRecentOrderLoading(false);
        return false;
      }

      if (userFaveMealsList?.length === 0) {
        const response = await getUserFavoriteMeal({
          id: (orderForSomeone.recipientId) ? orderForSomeone.recipientId : userData?.id,
          type: "Fav",
          skip: 0,
          limit: 10,
          lat: userDeliveryAddress?.lat,
          lang: userDeliveryAddress?.lang,
          order_type: type
        });
        trackPixelEvent('Get user fave meal');
        setUserFaveMealList(response?.data);
        setFaveMealLoading(false);
      }
      else {
        setFaveMealLoading(false);
      }

      if (recentOrderList?.length === 0) {
        const order_response = await getUserFavoriteMeal({
          id: userData?.id,
          type: "Order",
          skip: 0,
          limit: 10,
          lat: userDeliveryAddress?.lat,
          lang: userDeliveryAddress?.lang,
          order_type: type
        });
        trackPixelEvent('Get user fave meal');
        setRecentOrderList(order_response?.data)
        setRecentOrderLoading(false);
      }
      else {
        setRecentOrderLoading(false);
      }
    })();
    searchFunction();
  }, [userDeliveryAddress, isRefreshed]);


  const searchFunction = async () => {
    setNearbyRestaurantLoading(true);

    if (userDeliveryAddress?.city === "" || userDeliveryAddress?.city === undefined || nearByRestaurants?.length > 0) {
      setNearbyRestaurantLoading(false);
      return false
    }
    try {
      const placeObject = {
        lat: userDeliveryAddress.lat,
        lang: userDeliveryAddress?.lang,
        streetName: userDeliveryAddress?.streetName,
        streetNum: userDeliveryAddress?.streetNum,
        streetAddress: userDeliveryAddress?.streetAddress,
        city: userDeliveryAddress?.city,
        state: userDeliveryAddress?.state,
        country: userDeliveryAddress?.country,
        zip: userDeliveryAddress?.zip,
        orderType: type
      };

      const response = await restaurantsNearPlace(placeObject);
      if (response) {
        trackStandardPixelEvent('Search');
        const restaurantData = response?.data?.data?.stores;

        // -----------Filtering for Duplicate Data---------------
        const filteredData = Array?.from(
          new Set(restaurantData?.map((a: any) => a?.name))).map((name: any) => {
            return restaurantData?.find((a: any) =>
              String(name)?.toLowerCase()?.includes(String(a?.name)?.toLowerCase())
            );
          });

        dispatch(setRestaurantsListLoading(false) as any);
        setNearByRestaurants(filteredData);
        setNearbyRestaurantLoading(false);
      }
    }
    catch (error) {
      dispatch(setRestaurantsListLoading(false) as any);
      setNearbyRestaurantLoading(false);
      console.log(error);
    }
  };

  function handleOnChangeToggle() {
    setSwitchState(switchState === false);
  };

  return (
    <div className="screen">
      <>
        <div className="d-flex">
          <TopHead
            title="Get Food"
            rightText={'\u00A0' + " "}
            leftTextIcon={faAngleLeft}
            leftIconStyle={{ fontSize: "24px" }}
          />
        </div>
        <Divider />
        <div className="grow overflow-auto">
          {/* <IonContent >
          <IonRefresher slot="fixed" onIonRefresh={handleRefresh}>
            <IonRefresherContent></IonRefresherContent>
          </IonRefresher> */}
          {/* <ReactPullToRefresh
          onRefresh={handleRefresh}
          className="pull-refresh"
          style={{ textAlign: 'center' }}
          icon={<span className="genericon genericon-next"></span>}
        > */}
          <Section>
            <AddressList userAddress={formattedAddress} isAddressAvailable={isAddressTrue} selectedId={orderForSomeone?.addressId ? orderForSomeone?.addressId : selectedLocationId ? selectedLocationId : null} userId={orderForSomeone?.recipientId ? orderForSomeone?.recipientId : userData?.id} showList={true} type={type} />
            {(isAddressTrue)
              ?
              <Accordion title="Search">
                <SearchRestaurantsByKeywords pageRoute={`?for=get-food-now&type=${type}`} goToSearch={true} type={type} />
              </Accordion>
              :
              null
            }
            {/* <TextHead1 className="text-center !mt-[2px] mb-2">{type === "pickup" ? "Pickup" : "Delivery"}</TextHead1> */}
            {/* } */}
            <BothSideTextToggle leftText="List" rightText="Grid" switchState={switchState} handleOnChangeToggle={handleOnChangeToggle} />
            {
              switchState === false ? (
                <>
                  <Accordion title={`${orderForSomeone?.recipientName ? orderForSomeone?.recipientName + "'s" : "My"} Fave Meals`}>
                    {faveMealLoading && (
                      <div className="flex items-center justify-center">
                        <Spinner />
                      </div>
                    )}
                    {!faveMealLoading && (
                      <>
                        {(userFaveMealsList?.length > 0) ?
                          <>
                            <FavoriteMealList
                              // header={null} 
                              data={userFaveMealsList}
                              type={type}
                            />
                            <TextHead4 className="flex justify-center text-center text-green-600 mt-2"
                              onClick={() => history.push(`/favesallitems/for-order?type=${type}`)}>
                              <div className="cursor-pointer w-[80px]">See all</div>
                            </TextHead4>
                          </>
                          :
                          (orderForSomeone?.recipientName) ?
                            <SubText1 className="text-center !text-gray-400 !text-base mb-10">
                              {`${orderForSomeone?.recipientName} hasn't created any Faves.`}
                            </SubText1>
                            :
                            <SubText1 className="text-center !text-gray-400 !text-base mb-10">
                              You haven’t created any Faves. Go to <span className="cursor-pointer !text-[#22a45d]" onClick={() => history.push({ pathname: "/faves" })}>“My Faves”</span> to create new Faves.
                            </SubText1>
                        }
                      </>
                    )}
                  </Accordion>
                </>
              ) : (
                <>
                  <AccordianCartegoryChild text={{ categoryName: `${orderForSomeone?.recipientName ? orderForSomeone?.recipientName + "'s" : "My"} Fave Meals`, seeMore: (userFaveMealsList?.length > 0 && !faveMealLoading) ? 'See all' : "" }}
                    handleOnClick={() => history.push(`/favesallitems/for-order?type=${type}`)} />
                  {faveMealLoading && (
                    <div className="flex items-center justify-center">
                      <Spinner />
                    </div>
                  )}

                  {!faveMealLoading && (
                    <>
                      {(userFaveMealsList?.length > 0) ?
                        <FavoriteMealGrid
                          // header={null} 
                          data={userFaveMealsList}
                          type={type}
                        />
                        :
                        (orderForSomeone?.recipientName) ?
                          <SubText1 className="text-center !text-gray-400 !text-base mb-10">
                            {`${orderForSomeone?.recipientName} hasn’t created any Faves.`}
                          </SubText1>
                          :
                          <SubText1 className="text-center !text-gray-400 !text-base mb-10">
                            You haven’t created any Faves. Go to <span className="cursor-pointer !text-[#22a45d]" onClick={() => history.push({ pathname: "/faves" })}>“My Faves”</span> to create new Faves.
                          </SubText1>
                      }
                    </>
                  )}
                </>
              )}
            {
              switchState === false ? (
                <>
                  <Accordion title="Nearby Restaurants">
                    {(nearbyRestaurantLoading || isResultLoading) && (
                      <div className="flex items-center justify-center">
                        <Spinner />
                      </div>
                    )}
                    {(!nearbyRestaurantLoading && !isResultLoading) && (
                      <>
                        {(nearByRestaurants?.length > 0) ?
                          <>
                            <RestaurantCardList
                              data={restaurantData}
                              page="for-order"
                              type={type}
                            />
                            <TextHead4 className="flex justify-center text-center text-green-600 mt-2"
                              onClick={() => {
                                history.push(`/restaurantallitems/for-order?type=${type}`);
                                clearRestaurantList();
                              }}>
                              <div className="cursor-pointer w-[80px]">See all</div></TextHead4>
                          </>
                          :
                          <SubText1 className="text-center !text-gray-400 !text-base mb-10">
                            No restaurants are within the distance and search criteria.
                          </SubText1>
                        }
                      </>
                    )}
                  </Accordion>
                </>
              ) : (
                <>
                  <AccordianCartegoryChild
                    text={{ categoryName: 'Nearby Restaurants', seeMore: (!nearbyRestaurantLoading && !isResultLoading && nearByRestaurants?.length > 0) ? 'See all' : "" }}
                    handleOnClick={() => {
                      history.push(`/restaurantallitems/for-order?type=${type}`);
                      clearRestaurantList();
                    }} />

                  {(nearbyRestaurantLoading || isResultLoading) && (
                    <div className="flex items-center justify-center">
                      <Spinner />
                    </div>
                  )}

                  {(!nearbyRestaurantLoading && !isResultLoading) && (
                    <>
                      {(nearByRestaurants?.length > 0) ?
                        !faveMealModalStatus && <RestaurantCardGrid
                          data={restaurantData}
                          page="for-order"
                          type={type}
                        />
                        :
                        <SubText1 className="text-center !text-gray-400 !text-base mb-10">
                          No restaurants are within the distance and search criteria.
                        </SubText1>
                      }
                    </>
                  )}
                </>
              )}

            <Accordion title="Suggested Meals">
              <Divider />
              {
                switchState === false ?
                  (
                    <SubAccordion title="Recent Orders">
                      {recentOrderLoading && (
                        <div className="flex items-center justify-center">
                          <Spinner />
                        </div>
                      )}
                      {!recentOrderLoading && (
                        <>
                          {(recentOrderList?.length > 0) ?
                            <>
                              < PreferredMealList
                                // header={null}
                                data={recentOrderList}
                                type={type}
                              />
                              <TextHead4 className="flex justify-center text-center text-green-600 mt-2"
                                onClick={() => history.push(`/ordersallitems/for-faves?type=${type}`)}>
                                <div className="cursor-pointer w-[80px]">See all</div>
                              </TextHead4>
                            </>
                            :
                            <SubText1 className="text-center !text-gray-400 !text-base mb-10">
                              No recent orders.
                            </SubText1>
                            // <NoDataFound
                            //   message="No recent orders."
                            //   btnStyle={{ display: "none" }}
                            // />
                          }
                        </>
                      )}
                    </SubAccordion>
                  ) : (
                    <>
                      <AccordianCartegoryChild text={{ categoryName: 'Recent Orders', seeMore: (!recentOrderLoading && recentOrderList?.length > 0) ? 'See all' : "" }}
                        handleOnClick={() => history.push(`/ordersallitems/for-faves?type=${type}`)} />
                      {recentOrderLoading && (
                        <div className="flex items-center justify-center">
                          <Spinner />
                        </div>
                      )}
                      {!recentOrderLoading && (
                        <>
                          {(recentOrderList?.length > 0) ?
                            !faveMealModalStatus && <PreferredMealGrid
                              //  header={null} 
                              data={recentOrderList}
                              type={type}
                            />
                            :
                            // <NoDataFound
                            //   message="No recent orders."
                            //   btnStyle={{ display: "none" }}
                            // />
                            <SubText1 className="text-center !text-gray-400 !text-base mb-10">
                              No recent orders.
                            </SubText1>
                          }
                        </>
                      )}
                    </>
                  )
              }
            </Accordion>
            <Accordion title="Fave Meal Categories">
              {/* <Text>Matt’s Anytime<span style={{ float: "right", fontStyle: "italic" }}>(Default for New Faves)</span></Text> */}
              {
                mealCategory?.map((favMeal: any, index: any) => {
                  return (
                    <React.Fragment key={index}>
                      <Text
                        onClick={() => history.push({
                          pathname: `/editmealcategory/${favMeal.id}`,
                        })}
                      >
                        {switchSentenceCase("head text", favMeal?.attributes?.name)} <i>{favMeal?.attributes?.defaultMeal === true ? `(Default for New Faves)` : ""}</i>
                      </Text>
                    </React.Fragment>
                  )
                })
              }
              <Text
                className="italic"
                onClick={() =>
                  history.push({
                    pathname: "/faveaddnewcategory",
                  })
                }
              >Add New Category</Text>
            </Accordion>
          </Section>
          <Divider />
          {/* </ReactPullToRefresh> */}
          {/* </IonContent> */}
        </div>
      </>
    </div >
  );
}

