import { objectType } from '../type';
import { apiRequestService } from './apiRequestService';
import { getUserData } from './user';
const API_URL = process.env.REACT_APP_API_URL

export async function inviteFriend(data: any) {
    try {
        const user = await getUserData();
        let config = {
            method: 'POST',
            url: API_URL + `/v1/invite-friends`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + user?.jwt
            },
            data: data
        };
        const request = apiRequestService(config);
        return request;
    }
    catch (error) {
        console.log(error);
    }
};

export async function sendFriendInvitation(data: any) {
    try {
        const user = await getUserData();
        let config = {
            method: 'POST',
            url: API_URL + `/v1/custom-invite`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + user?.jwt
            },
            data: data
        };
        const request = apiRequestService(config);
        return request;
    }
    catch (error) {
        console.log(error);
    }
};

export async function invitedFriendsStatus(to_id: any) {
    try {
        const user = await getUserData();

        let config = {
            method: 'GET',
            url: API_URL + `/v1/invite-friends?filters[from_id][id][$eq]=${user?.user?.id}&filters[to_id][id][$eq]=${to_id}&filters[from_id][id][$eq]=${to_id}&filters[to_id][id][$eq]=${user?.user?.id}&populate[to_id]=*&populate[from_id]=*`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + user?.jwt
            },
        };
        const request = apiRequestService(config);
        return request;
    }
    catch (error) {
        console.log(error);
    }
};

export async function getAllFriendRequestsStatus(skip: any, limit: any) {
    try {
        const user = await getUserData();
        let config = {
            method: 'GET',
            url: API_URL + `/v1/invite-friends?filters[$or][0][to_id][id][$eq]=${user?.user?.id}&filters[$or][1][from_id][id][$eq]=${user?.user?.id}&filters[status][$eq]=PENDING&populate[from_id][populate][profile_pic]=*&populate[to_id][populate][profile_pic]=*&pagination[start]=${skip || 0}&pagination[limit]=${limit || 1000}&sort[0]=id:desc`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + user?.jwt
            },
        };
        const request = apiRequestService(config);
        return request;
    }
    catch (error) {
        console.log(error);
    }
};

export async function getMyContacts(skip: any, limit: any) {
    try {
        //&filters[contact_invitation][$eq]=true
        const user = await getUserData();
        let config = {
            method: 'GET',
            url: API_URL + `/v1/invite-friends?filters[$or][0][to_id][id][$eq]=${user?.user?.id}&filters[$or][1][from_id][id][$eq]=${user?.user?.id}&filters[contact_invitation][$eq]=true&populate[from_id][populate][profile_pic]=*&populate[to_id][populate][profile_pic]=*&pagination[start]=${skip || 0}&pagination[limit]=${limit || 1000}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + user?.jwt
            },
        };
        const request = apiRequestService(config);
        return request;
    }
    catch (error) {
        console.log(error);
    }
};

export async function importContacts(data: any) {
    try {
        const user = await getUserData();
        let config = {
            method: 'POST',
            url: API_URL + `/v1/import/contacts`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + user?.jwt
            },
            data: data
        };
        const request = apiRequestService(config);
        return request;
    }
    catch (error) {
        console.log(error);
    }
};

export async function importFacebookFriends(data: any) {
    try {
        const user = await getUserData();
        let config = {
            method: 'POST',
            url: API_URL + `/v1/import/facebook-friends`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + user?.jwt
            },
            data: data
        };
        const request = apiRequestService(config);
        return request;
    }
    catch (error) {
        console.log(error);
    }
};


export const sendInvitation = async (id: string, data: objectType) => {
    try {
        const user = await getUserData()
        let config = {
            method: 'PUT',
            url: API_URL + `/v1/custom-update/${id}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + user?.jwt
            },
            data: data
        };
        const request = apiRequestService(config);
        return request;
    } catch (error) {
        console.log(error);
    }
}

export async function getMyFriendRequests() {
    try {
        const user = await getUserData();
        let config = {
            method: 'GET',
            url: API_URL + `/v1/invite-friends?filters[to_id][id][$eq]=${user?.user?.id}&populate[from_id]=*&sort[0]=id:desc`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + user?.jwt
            },
        };
        const request = apiRequestService(config);
        return request;
    }
    catch (error) {
        console.log(error);
    }
};

export async function getMyExistedFriend(skip: any, limit: any) {
    try {
        const user = await getUserData();
        let config = {
            method: 'GET',
            url: API_URL + `/v1/invite-friends?filters[$or][0][to_id][id][$eq]=${user?.user?.id}&filters[$or][1][from_id][id][$eq]=${user?.user?.id}&filters[status][$eq]=ACCEPTED&populate[to_id][populate][address]=*&populate[from_id][populate][address]=*&populate[to_id][populate][profile_pic]=*&populate[from_id][populate][profile_pic]=*&pagination[start]=${skip || 0}&pagination[limit]=${limit || 1000}&sort[0]=id:desc`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + user?.jwt
            },
        };
        const request = apiRequestService(config);
        return request;
    }
    catch (error) {
        console.log(error);
    }
};

export const updateInvitationData = async (data: any, id: number) => {
    try {
        const user = await getUserData()
        let config = {
            method: 'PUT',
            url: API_URL + `/v1/invite-friends/${id}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + user?.jwt
            },
            data: data
        };
        const request = apiRequestService(config);
        return request;
    } catch (error) {
        console.log(error);
    }
}

export async function deleteFriends(id: any) {
    try {
        const user = await getUserData()
        let config = {
            method: 'DELETE',
            url: API_URL + `/v1/invite-friends/${id}`,
            headers: {
                'Authorization': 'Bearer ' + user?.jwt
            },
        };
        const request = apiRequestService(config);
        return request;
    }
    catch (error) {
        console.log(error);
    }
}

export const findFriends = async (query_string: string): Promise<any> => {
    try {
        const user = await getUserData();

        const config = {
            method: 'get',
            url: `${API_URL}/v1/find/friends/${user?.user?.id}?query_string=${query_string}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user?.jwt}`
            }
        };
        const response = await apiRequestService(config);
        return response;
    } catch (error) {
        console.log(error);
    }
};