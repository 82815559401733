import Section from "../section/Section";
import styled from 'styled-components';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { goBackPage } from "../../../utils/utilities";


const CenterDiv = styled.div`
display: flex;
justify-content: center;
  `;


const Text = styled.div`
font-size: 17px;
font-weight: 500;
align-self: center;
cursor: pointer;
`;

const HeadText = styled.h1<{ rightText: boolean }>`
  font-size: 24px;
  font-weight: 500;
  margin: 0px 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: ${props => (props?.rightText ? "400px" : "")};;
   @media (max-width: 1024px) {
   width: 100%;
  }
  @media (min-width: 1400px) {
   width: 100%;
  }
`;
//className="z-[999]"

export default function TopHead(props: any) {

    return (
        <>
            <Section align="center" className="ion-safe-top">
                <CenterDiv>
                    <Text onClick={() => goBackPage()} style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", position: "relative", marginRight: "auto", width: props.leftText ? "90px" : "auto" }}>
                        {props.leftTextIcon == null ? null : <FontAwesomeIcon icon={props.leftTextIcon} style={props.leftIconStyle} />} {props.leftText}
                    </Text>
                    <HeadText rightText={props?.rightText} style={props.titleStyle}>
                        {props.title}
                    </HeadText>
                    <Text style={{
                        marginLeft: "auto",
                        display: "flex",
                        alignItems: "center"
                    }}
                        onClick={props?.gotoRightTextHandle}
                    >{props.rightText} {props.rightTextIcon == null ? null : <FontAwesomeIcon icon={props.rightTextIcon} style={props.rightIconStyle} />}
                    </Text>
                </CenterDiv>
            </Section >
        </>
    );
};



