import { useState } from "react";
import Divider from "../../components/widgets/divider/Divider";
import Section from "../../components/widgets/section/Section";
import styled from 'styled-components';
import TopHead from "../../components/widgets/head/TopHead";
import { ButtonGreen, ButtonInitial, Flex, FlexBetween, InitialDiv, InputText, ItemCount, SubText1, SubText2, TextArea1, TextHead4, TextHead5 } from "../../components/styled/styled";
import { switchSentenceCase } from "../../utils/utilities";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleRight } from "@fortawesome/pro-regular-svg-icons";
import { faAngleLeft, faClockThree, faUtensils } from "@fortawesome/pro-solid-svg-icons";
import cardIconImg from "../../components/assets/RectangleCardIcon.png"
import ModalSuccess from "../../components/widgets/modals/ModalSuccess";
import { useHistory } from "react-router";



const BlockContainer = styled.div`
    display: block;
    width: -webkit-fill-available;
    text-align: left;
`;


const Container = styled.div`
   margin: 13px 5px;
`;



export default function RecipetVeto(data: any) {
  const history = useHistory();
  const [showPromoInput, setShowPromoInput] = useState(true);
  const [showModalSuccess, setShowModalSuccess] = useState(false);


  return (
    <div className="screen">

      <TopHead
        title="Meal Notifications"
        leftTextIcon={faAngleLeft}
        leftIconStyle={{ fontSize: "24px" }}
      />
      <Divider />

      <div className="grow overflow-auto">
        <Section>
          <Container >
            <FlexBetween className="flex my-4">
              <Flex>
                <FontAwesomeIcon icon={faUtensils} className="mt-1" />
                <InitialDiv className="ml-3">
                  <TextHead4>Super Duper Icecream Sandwhich</TextHead4>
                  <SubText2 className="mt-1">Choc Mint, Sugar Cookie</SubText2>
                </InitialDiv>
              </Flex>
              <TextHead5 className="text-green-600 underline">EDIT</TextHead5>
            </FlexBetween>
            <Divider />
            <FlexBetween className="flex my-4">
              <Flex>
                <FontAwesomeIcon icon={faClockThree} className="mt-1" />
                <InitialDiv className="ml-3">
                  <TextHead4>Time</TextHead4>
                  <SubText2 className="mt-1">Arrives at 7:00PM</SubText2>
                  <SubText2>Dates: Mar 07 2022</SubText2>
                  <SubText2>Items: 2</SubText2>
                </InitialDiv>
              </Flex>
              <TextHead5 className="text-green-600 underline">EDIT</TextHead5>
            </FlexBetween>
            <Divider />

            <InitialDiv className="my-5">
              <TextHead4>Customize Meal Choices</TextHead4>
              <SubText1 className="my-3">You have until [time stamp] to make edits on time slots and meals.</SubText1>
            </InitialDiv>

            <InitialDiv>
              <SubText2>Notes</SubText2>
              <TextArea1 className="!bg-gray-50" />
            </InitialDiv>

          </Container>
          <ButtonGreen onClick={() => setShowModalSuccess(prev => !prev)}>{switchSentenceCase("primary button", "APPROVE")}</ButtonGreen>
          <ButtonInitial style={{ border: "1px solid #22A45D", color: "#22A45D", margin: "10px 0px" }}>{switchSentenceCase("primary button", "DECLINE")}</ButtonInitial>
        </Section>

        <ModalSuccess
          firstText="You Place the Order Successfully"
          secondText="You placed the order successfully."
          btnOneText="SHARE"
          btnTwoText="HOME"
          btnTwoStyle={{ border: "1px solid #e1e1e1", marginTop: "15px" }}
          btnOneGoto={() => history.push({ pathname: "/refertofriends" })}
          btnTwoGoto={() => history.push({ pathname: "/home" })}
          showModal={showModalSuccess}
          setShowModal={setShowModalSuccess} />
      </div>
    </div >
  );
}