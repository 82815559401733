import { useRef, useState } from "react";
import styled from "styled-components";
import { ButtonGreen, FlexBetween, TextHead5, TextHead4, Flex, InitialDiv, TextHead1, moveTopBottom } from "../../styled/styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faXmark, faCircleInfo } from "@fortawesome/pro-solid-svg-icons";
import { faHeart } from "@fortawesome/pro-regular-svg-icons";
import Section from "../section/Section";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { slotDateTimeFormatter, switchSentenceCase, priceFormatterUsd, subtotalForAutofill, trackPixelEvent } from "../../../utils/utilities";
import { getUserInfo } from "../../../services/user";
import { createScheduleForMeClear, getSingleFavItemsDetails, setScheduleDetailsRedux } from "../../../pages/schedules/redux/createScheduleReduxActions";
import ModalSingleFavItemDetail from "./ModalSingleFavItemDetail";
import PaymentCart from "../paymentscomponent/PaymentCart";
import Accordion from "../accordian/AccordionStyled";
import { useIonRouter } from "@ionic/react";
import ToolTip from "../alerts/tooltip";
import useDarkMode from "../../hooks/darkmode/DarkMode";
import { addNewSchedule, getCalendarData, getScheduleById } from "../../../services/schedule";
import Spinner from "../spinner/Spinner";

const ModalDiv = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1000;
  overflow-y: auto;
`;


const ContentDiv = styled.div<{ isDarkMode: boolean }>`
    background-color: ${props => (props?.isDarkMode ? '#2e2e2e' : '#fff')};
    margin-bottom: 85px;
    margin-top: 80px;
    padding: 10px;
    z-index: 1000;
    width: 600px;
    max-width: 90%;
    border-radius: 20px;
    text-align: center;
    height: fit-content;
    animation: ${moveTopBottom} .4s ease-in-out;
`;

const HeartModalIcon = styled.div`
  padding: 3px;
  background: #f03f3f29;
  border-radius: 40px;
  text-align: center;
  height: -webkit-fit-content;
  font-size: 14px;
  color: #ff000082;
  width: 28px;
  margin-top: 5px;
  margin-left: 10px;
`;

const DoneModalIcon = styled.div`
  width: 60px;
  height: 60px;
  padding: 18px;
  z-index: 10;
  background: #22a45d;
  color: white;
  border-radius: 40px;
  margin: auto;
  position: relative;
  bottom: 65px;
  margin-bottom: -90px;
`;

const BackModalBtn = styled.div`
  position: relative;
  right: 49%;
  font-size: 17px;
  cursor: pointer;
`;

const OverflowDiv = styled.div`
overflow-y: auto;
  max-height: 200px; /* Adjust the height as needed */

  /* Chrome, Safari, and newer Edge versions */
  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #bebcbc;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-track {
    background-color: #f1f1f1;
    border-radius: 5px;
  }

  /* Firefox */
  scrollbar-width: thin;
  scrollbar-color: #888888 #f1f1f1;

  &::-moz-scrollbar {
    width: 4px;
  }

  &::-moz-scrollbar-thumb {
    background-color: #bebcbc;
    border-radius: 5px;
  }

  &::-moz-scrollbar-track {
    background-color: #f1f1f1;
    border-radius: 5px;
  }
`;

export default function ModalShowPaymentDetailsForAutofill(props) {
    const isDarkMode = useDarkMode();
    const history = useHistory();
    const router = useIonRouter();
    const modalRef = useRef();
    const dispatch = useDispatch();
    const user = getUserInfo();

    const [showModalSlotFill, setShowModalSlotFill] = useState(false);
    const { orderType } = useSelector((state: any) => state.getUserRestaurantsLists);
    //(scheduleDetails !== undefined) ? scheduleDetails?.attributes?.confirm_before_order :
    const [confirmPrior, setConfirmPrior] = useState(true);
    const [paymentCardId, setPaymentCardId] = useState("");
    const [awaiting, setAwaiting] = useState(false);

    const subTotalPrice = (subtotalForAutofill(props?.data?.slots)) * 100;
    // const tax = (orderType === "pickup") ? (subTotalPrice * PICKUP_TAX_PERCENT) / 100 : (subTotalPrice * DELIVERY_TAX_PERCENT) / 100;
    // const fees = (orderType === "pickup") ? (subTotalPrice * PICKUP_FEES_PERCENT) / 100 : (subTotalPrice * DELIVERY_FEES_PERCENT) / 100;
    const tips = (orderType === "delivery") ? Number(user?.driver_auto_tip) : Number(user?.auto_tip_amt) || 0;
    const tip_subtotal = (tips * props?.data?.slots?.length) * 100 || 0;
    const tax_fees_estimate = orderType === "pickup" ? subTotalPrice / 4 : subTotalPrice / 2;
    const totalPrice = subTotalPrice + tax_fees_estimate + tip_subtotal;

    const [skip, setSkip] = useState(3);

    const handlePaymentId = (paymentCardId: any) => {
        setPaymentCardId(paymentCardId);
        // setPaymentCardError(false);
    };

    function showMoreSlots() {
        setSkip(prevSkip => prevSkip + 3);
    };

    const closeModal = (e: any) => {
        if (modalRef.current === e.target) {
            props?.setShowModal(false);
        }
    };

    const createCalendar = async () => {
        try {
            setAwaiting(true);
            let calendarData = await getCalendarData();

            const payloadData = {
                data: calendarData?.step3
            }
            const response = await addNewSchedule(payloadData);
            if (response) {
                trackPixelEvent('Add new schedule');
                dispatch(createScheduleForMeClear() as any);
                localStorage.removeItem("CalendarData");
                const scheduleResponse = await getScheduleById(response?.data?.data?.id);
                if (scheduleResponse) {
                    trackPixelEvent('Get schedule by id');
                    setScheduleDetailsRedux(scheduleResponse?.data?.data);
                    setAwaiting(false);
                    history.push({
                        pathname: `/calendar-slots/${encodeURIComponent(response?.data?.data?.id)}`,
                    });
                }
            }
        } catch (e) {
            setAwaiting(false);
            // setShowIonAlertModal(true);
            // setIonAlertHeader(e?.response?.status);
            // setIonAlertMessage(e?.response?.statusText);
            console.log(e);
        }
    };

    const openModalHandler = (id: any, date: any, time: any) => {
        dispatch(getSingleFavItemsDetails(id, date, time) as any);
        setShowModalSlotFill((prev) => !prev);
    };
    return (
        <>
            {props?.showModal ? (
                <ModalDiv onClick={closeModal}>
                    <ContentDiv isDarkMode={isDarkMode}>
                        <BackModalBtn>
                            <FontAwesomeIcon
                                icon={faXmark}
                                className="cursor-pointer"
                                onClick={() => props?.setShowModal((prev: any) => !prev)}
                            />
                        </BackModalBtn>
                        <DoneModalIcon>
                            <FontAwesomeIcon icon={faCheck} className="text-[25px]" />
                        </DoneModalIcon>
                        {(props?.data?.fav_meal?.length > 0) &&
                            <>
                                <InitialDiv className="mt-8">
                                    <TextHead1 className={isDarkMode ? "text-white" : "text-black"}>
                                        {`Autofill [${props?.data?.slots?.length}] ${(props?.data?.slots?.length > 1) ? "Slots with Faves" : "Slot with Fave"}`}
                                    </TextHead1>

                                </InitialDiv>
                                <Section>
                                    <TextHead5 style={{ fontWeight: "500" }}>
                                        Meals match Autofill Settings determined in the Meal Calendar
                                        Detailed Settings.
                                    </TextHead5>
                                    <TextHead5 style={{ margin: "10px 0px", fontWeight: "500" }}>
                                        {`Payment will process on the day of ${(orderType === "delivery") ? "delivery" : "pickup"}, and will include Taxes and Fees, which can be 50% or more of food costs.`}
                                    </TextHead5>
                                </Section>

                            </>
                        }
                        {(props?.data?.fav_meal?.length > 0) ?
                            <>
                                <InitialDiv className="mx-4 text-left">
                                    <Accordion title="Slots" opened={true}>
                                        <OverflowDiv>
                                            {props?.data?.slots?.slice(0, skip)?.map((item: any, index: any) =>
                                                <InitialDiv key={index} className="my-2">
                                                    <TextHead5>
                                                        {slotDateTimeFormatter(item?.date, item?.time)}
                                                    </TextHead5>
                                                    <FlexBetween
                                                        style={{ alignItems: "center" }}
                                                        onClick={() => openModalHandler(item?.favMeal?.id, item?.date, item?.time)} >
                                                        <TextHead4 className="cursor-pointer">
                                                            {switchSentenceCase("head text", ((item?.favMeal) ? item?.favMeal?.name : "No Fav Meal Available for this slot time!"))}
                                                        </TextHead4>
                                                        <div className="flex items-center">

                                                            {(item?.favMeal) &&
                                                                <HeartModalIcon>
                                                                    <FontAwesomeIcon icon={faHeart} />
                                                                </HeartModalIcon>
                                                            }

                                                            <TextHead5 className="ml-[10px]"> {(item?.favMeal) ? `$${(item?.favMeal?.total.toFixed(2))}` : ""}</TextHead5>
                                                        </div>
                                                    </FlexBetween>
                                                </InitialDiv>
                                            )}
                                        </OverflowDiv>

                                        {
                                            (props?.data?.slots?.length > 3 && props?.data?.slots?.length >= skip) &&
                                            <div onClick={showMoreSlots}
                                                className="text-green-600 text-center cursor-pointer mt-2"
                                            >
                                                Show More
                                            </div>
                                        }

                                    </Accordion>
                                </InitialDiv>
                                <InitialDiv className="mx-4">
                                    {/********************  Payment Section Section ******************************/}
                                    <Accordion title="Payment" opened={(paymentCardId === "" || paymentCardId === undefined) ? true : false}>
                                        <PaymentCart paymentId={handlePaymentId} userId={user?.id} changePayment={false} selectedCard={null} />
                                    </Accordion>
                                </InitialDiv>
                                <Section>
                                    <FlexBetween>
                                        <Flex className="items-baseline">
                                            <TextHead5 style={{ fontWeight: "500" }}>Food Subtotal*</TextHead5>
                                            <ToolTip className="mt-10" instructions="Food costs are subject to restaurant price changes." />
                                        </Flex>
                                        <TextHead5 style={{ fontWeight: "500" }}>{priceFormatterUsd(subTotalPrice)}</TextHead5>
                                    </FlexBetween>

                                    <FlexBetween>
                                        <TextHead5 style={{ fontWeight: "500" }}>Tip Subtotal</TextHead5>
                                        <TextHead5 style={{ fontWeight: "500" }}>{priceFormatterUsd(tip_subtotal)}</TextHead5>
                                    </FlexBetween>

                                    <FlexBetween>
                                        <Flex className="items-baseline">
                                            <TextHead5 style={{ fontWeight: "500" }}>Taxes and Fees Estimate**</TextHead5>
                                            <ToolTip className="!mt-[7px]"
                                                instructions={`Taxes and Fees are estimates only and subject to change. Actual cost will be calculated on the day of ${(orderType === "delivery") ? "delivery" : "pickup"}.`} />
                                        </Flex>
                                        <TextHead5 style={{ fontWeight: "500" }}>{priceFormatterUsd(tax_fees_estimate)}</TextHead5>
                                    </FlexBetween>

                                    <FlexBetween>
                                        <TextHead5 style={{ fontWeight: "500" }}>Estimated Total</TextHead5>
                                        <TextHead5 style={{ fontWeight: "500" }}>{priceFormatterUsd(totalPrice)}</TextHead5>
                                    </FlexBetween>

                                    <InitialDiv className="text-left text-[#7a7a7aba] flex items-baseline">
                                        <FontAwesomeIcon icon={faCircleInfo} className="mt-[8px] mr-1 text-[13px]" />
                                        <TextHead5 className="font-medium">{`Estimates are subject to change. Actual charges are calculated the day of ${(orderType === "delivery") ? "delivery" : "pickup"}.`}</TextHead5>
                                    </InitialDiv>

                                    <InitialDiv className="text-left flex items-center mt-2">
                                        {/* <Flex className="items-center mt-5"> */}
                                        <input type="checkbox" style={{ height: '14px', width: '18px' }} className="w-8 accent-green-600 cursor-pointer mr-[-2px]" checked={confirmPrior} onChange={() => setConfirmPrior(!confirmPrior)} />
                                        <TextHead5 className="!ml-1">Confirm 48 hours prior if price increases
                                        </TextHead5>
                                        {/* </Flex> */}
                                    </InitialDiv>

                                </Section>
                                <InitialDiv className="mx-4">
                                    {awaiting ?
                                        <div className="text-center">
                                            <Spinner />
                                        </div>
                                        :
                                        <ButtonGreen
                                            style={{ marginBottom: "20px", marginTop: "5px" }}
                                            onClick={createCalendar}
                                        // className={"cursor-not-allowed"}
                                        // disabled={(filledSlotIds.length === 0)}
                                        >
                                            {switchSentenceCase("primary button", "PROCEED to AUTOFILL")}
                                        </ButtonGreen>
                                    }
                                </InitialDiv>
                            </>
                            :
                            <InitialDiv className="mt-8">
                                <TextHead1 style={{ color: isDarkMode ? "white" : "black" }}>
                                    No faves are available for autofill!
                                </TextHead1>
                                <ButtonGreen
                                    style={{ marginTop: "20px" }}
                                    onClick={() => props?.setShowModal((prev: any) => !prev)}
                                >
                                    {switchSentenceCase("primary button", "OKAY")}
                                </ButtonGreen>
                            </InitialDiv>
                        }
                    </ContentDiv>

                    {/* ---------------------------------Modal Schedule Slot Filled------------------- */}
                    {showModalSlotFill &&
                        <ModalSingleFavItemDetail
                            showMealModal={showModalSlotFill}
                            setShowMealModal={setShowModalSlotFill}
                        >
                        </ModalSingleFavItemDetail>
                    }
                </ModalDiv>
            ) : null}
        </>
    );
}
