import { useState } from "react";
import styled from 'styled-components';
import ModalViewFavMealItems from "../modals/ModalViewFavMealItems";
import sampleMealImage from "../../assets/Meal.jpg"
import { countryCurrency, switchSentenceCase, properNameFormate, isRestaurantOpen, filterByHighestPriceAndSum, sumOfMealInCart } from "../../../utils/utilities";
import { ClosedStripDiv2, Flex, SubText1 } from "../../styled/styled";
import { useSelector } from "react-redux";
import { useIonRouter } from "@ionic/react";

export const ButtonAdd = styled.button`
width:140px;
color: white;
border: 1px solid #9797976b;
background: #22A45D;
border-radius: 25px;
font-size: 14px;
font-weight: 500;
padding: 2px;
padding-bottom: 5px;
`;

const HeartModalIcons = styled.div`
    width:30px;
    height:30px;
    background: #f03f3f29;
    border-radius: 50%;
    text-align: center;
    font-size: 14px ;
    color: #ff000082;
    display:flex;
    justify-content:center;
    align-items: center;
    margin-bottom: "5px";
`;

const FlexBetween = styled.div`
    font-weight: 600;
    display: flex;
    width:100%;
    justify-content: space-between;
`;

const H3 = styled.div`
overflow: hidden;
text-overflow: ellipsis;
display: -webkit-box;
-webkit-line-clamp: 2;
-webkit-box-orient: vertical;
width: 150px;
`;

export default function PreferredMealCard({
  meal,
  fav_meal_id,
  onClick,
  type
}) {

  const router = useIonRouter();
  const currentDateTime = new Date();
  const { userDeliveryAddress } = useSelector((state: any) => state.userAddresses);
  const showNav = (router.routeInfo.pathname === '/scheduleslotfill') ? true : false;
  const [showMealModal, setShowMealModal] = useState(false);

  const openMealModal = () => {
    setShowMealModal(prev => !prev)
  }

  if (meal?.attributes?.favItems?.data.length > 1) {
    const products = meal?.attributes?.favItems?.data;
    filterByHighestPriceAndSum(products)
    meal.attributes.favItems.data = products;
  }

  // else {
  //   // total = meal?.attributes?.favItems?.data[0].attributes?.product?.data?.attributes?.price;
  // }

  let productImage;
  if (meal?.attributes?.favItems?.data.length) {
    if (meal?.attributes?.favItems?.data[0].attributes?.product?.data?.attributes?.thumbnail_image) {
      productImage = meal?.attributes?.favItems?.data[0].attributes?.product?.data?.attributes?.thumbnail_image;
    } else if (meal?.attributes?.favItems?.data[0].attributes?.product?.data?.attributes?.image) {
      productImage = meal?.attributes?.favItems?.data[0].attributes?.product?.data?.attributes?.image;
    } else {
      productImage = meal?.attributes?.favItems?.data[0].attributes?.product?.data?.attributes?.restaurant?.data?.attributes?.logo_photos;
    }
  }

  // ************************* Checking Restaurant Open/Closed & Active/Inactive Condition ************************************

  const isClosedStrip = ((meal?.attributes?.restaurant?.data !== null && userDeliveryAddress?.timezone !== null && userDeliveryAddress?.timezone !== undefined) && (!isRestaurantOpen(type === "delivery" ? meal?.attributes?.restaurant?.data?.attributes?.jsonData?.local_hours?.delivery : meal?.attributes?.restaurant?.data?.attributes?.jsonData?.local_hours?.pickup, currentDateTime, userDeliveryAddress?.timezone, false)) ? true : false);
  const isInActive = (meal?.attributes?.status === "INACTIVE") ? true : false

  return (
    <div
      className={"flex meal-card cursor-pointer"}>
      <div style={{ position: "relative" }}>
        <img alt="" src={productImage ? productImage : sampleMealImage} className={isInActive ? "opacity-30 mr-6 rounded-lg w-32 h-28 object-cover" : "mr-6 rounded-lg w-32 h-28 object-cover"} onClick={openMealModal} />
        {(isClosedStrip || isInActive)
          &&
          <ClosedStripDiv2>{isInActive ? "Inactive" : "Closed"}</ClosedStripDiv2>
        }
      </div>

      <div className="flex-content !justify-evenly" >
        <div onClick={openMealModal}>
          <FlexBetween>
            <H3 >{meal?.attributes?.name ? switchSentenceCase("head text", meal?.attributes?.name) : switchSentenceCase("head text", properNameFormate(meal?.attributes?.name))}</H3>
          </FlexBetween>
          <div className="subtext">{meal?.attributes?.description}</div>
          <FlexBetween>
            <Flex>
              <SubText1>{countryCurrency("dollor")}{sumOfMealInCart(meal?.attributes?.favItems?.data)}</SubText1>
            </Flex>
            {/* <HeartModalIcons>
              <FontAwesomeIcon icon={faHeart} />
            </HeartModalIcons> */}
          </FlexBetween>
        </div>

        {showNav ?
          <ButtonAdd onClick={() => openMealModal()}>
            {switchSentenceCase("primary button", "Add to Schedule")}
          </ButtonAdd>
          :
          <ButtonAdd
            // className={isClosedStrip ? "!cursor-not-allowed !border !border-solid !border-[#5cb383] !bg-[#5cb383]" : ""}
            onClick={openMealModal}
          // disabled={isClosedStrip ? true : false}
          >
            {switchSentenceCase("primary button", "Order")}
          </ButtonAdd>
        }
      </div>
      <ModalViewFavMealItems showMealModal={showMealModal} setShowMealModal={setShowMealModal} mealProps={meal} favMealId={fav_meal_id} />
    </div >
  );

}
