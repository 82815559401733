import React, { useState } from "react";
import {
    Flex,
    FlexBetween,
    ItemCount,
    SubText1,
    TextHead4,
    InitialDiv,
    TextHead5,
} from "../../styled/styled";
import styled from "styled-components";
import {
    switchSentenceCase,
    textFormatter,
    priceCalculator,
    priceFormatterUsd
} from "../../../utils/utilities";
import ToolTip from "../alerts/tooltip";
import ModalProductDetails from "../modals/ModalProductDetails";
import AccordionCheckoutCustomization from "../accordian/AccordionCheckoutCustomization";
import CheckoutProductCustomization from "../../../pages/orderFood/CheckoutProductCustomization";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo, faTrash } from "@fortawesome/pro-solid-svg-icons";
import { useHistory, useLocation } from "react-router";
import { getUserInfo } from "../../../services/user";

const BlockContainer = styled.div`
  display: block;
  width: -webkit-fill-available;
  text-align: left;
`;

const ItemQtyBtn = styled.button`
  font-size: 15px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px;
  gap: 2px;
`;


export default function MealItemsList({ mealItems, favMealId, restaurantId, selectedId, faveMealUser }) {
    const history = useHistory();
    const location = useLocation();
    const user = getUserInfo();
    const [showProductDetailsModal, setShowProductDetailsModal] = useState(false);

    const handleCloseModal = (value: any) => {
        setShowProductDetailsModal(value);
    };

    const onPushLocation = (favItem_id: any) => {
        let data = {
            favItem_id: favItem_id,
            fav_meal_id: favMealId,
            restaurantId: restaurantId,
            redirect_link: location?.pathname
        }
        history.push({
            pathname: `/restaurant/${restaurantId}`,
            state: data
        })
    };

    return (
        <>
            <InitialDiv>
                {mealItems?.map((mealItem: any, index: any) => {
                    return (
                        <React.Fragment key={index}>
                            <Flex className={mealItem?.product_error ? " mb-4" : "mb-4"}>
                                <ItemCount>{index + 1}</ItemCount>
                                <BlockContainer>
                                    <FlexBetween style={{ alignItems: "baseline" }}>
                                        <Flex>
                                            <TextHead4 className="underline">
                                                {switchSentenceCase("head text", textFormatter(mealItem?.name))}
                                            </TextHead4>

                                            {(mealItem?.instructions !== "") &&
                                                <ToolTip instructions={mealItem?.instructions} />
                                            }
                                        </Flex>
                                        <div className="flex">
                                            <SubText1>
                                                {priceFormatterUsd(priceCalculator(mealItem?.price, mealItem?.customizations))}{" "}[x {mealItem?.quantity}]
                                            </SubText1>
                                        </div>
                                    </FlexBetween>
                                    {mealItem?.product_error &&
                                        <FlexBetween className="items-baseline">
                                            <InitialDiv className="text-left flex items-baseline">
                                                <FontAwesomeIcon
                                                    icon={faCircleInfo}
                                                    className="mr-1 mt-1 text-[12px] text-red-600"
                                                />
                                                <TextHead5 className="!text-red-600">
                                                    {/* {mealItem?.product_error} */}Inactive Item
                                                </TextHead5>
                                            </InitialDiv>
                                            {(user?.id === faveMealUser) &&
                                                <InitialDiv className="text-left flex items-baseline">
                                                    <TextHead5
                                                        className="text-green-600 underline !font-medium cursor-pointer !m-2"
                                                        onClick={() => onPushLocation(mealItem?.id)}
                                                    >
                                                        Replace Item
                                                    </TextHead5>
                                                    < FontAwesomeIcon icon={faTrash} className="text-green-600 text-sm cursor-pointer" onClick={() => selectedId(mealItem?.id)} />
                                                </InitialDiv>
                                            }
                                        </FlexBetween>
                                    }
                                    {
                                        (mealItem?.customizations?.length > 0)
                                        &&
                                        <>
                                            <AccordionCheckoutCustomization
                                                title={"Selected Customizations"}
                                                titleSize={"16px"}
                                            >
                                                <div className="block">
                                                    <CheckoutProductCustomization customizations={mealItem?.customizations} />
                                                </div>
                                            </AccordionCheckoutCustomization>
                                        </>
                                    }
                                </BlockContainer>
                            </Flex>
                        </React.Fragment >
                    )
                })}
            </InitialDiv >
            {/* *********************Modal Product Details****************************** */}
            {showProductDetailsModal && < ModalProductDetails showProductDetailsModal={showProductDetailsModal} setShowProductDetailsModal={handleCloseModal} pageName="FromOrderPage" btnText="Update Changes" />}
        </>
    );
}


