import Section from "../../components/widgets/section/Section";
import { ButtonGreen, InputLabel } from "../../components/styled/styled";
import { useHistory } from "react-router";
import { useState } from "react";
import { setUserData } from "../../services/user";
import Spinner from "../../components/widgets/spinner/Spinner";
import { countryCodeFilter, switchSentenceCase, trackPixelEvent, validatePhone } from "../../utils/utilities";
import { loginRegisterUser } from "../../services/user";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { useIonRouter } from "@ionic/react";
import useDarkMode from "../../components/hooks/darkmode/DarkMode";
import { objectType } from "../../type";
import store from "../../redux/store";

export default function SignInWithPhone(props: objectType) {
  const history = useHistory();
  const router = useIonRouter();
  const isDarkMode = useDarkMode();
  let checkInputTypeIsEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(props?.inputTypeValue?.inputValue);

  const [inputValue, setInputValue] = useState(
    (checkInputTypeIsEmail ? "" : props?.inputTypeValue?.inputValue) || ""
  );
  const [awaiting, setAwaiting] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  //***************** HANDLE SUBMIT FUNCTION *****************//
  const handleSubmit = async () => {
    try {
      // Validation-----------------------
      if (inputValue === "") {
        setErrorMessage("Phone number is required.");
        return false;
      }

      const validPhoneNo = validatePhone(inputValue);
      if (!validPhoneNo) {
        setErrorMessage("Please enter a valid phone number.");
        return false;
      } else if (validPhoneNo) {
        setErrorMessage("");
      }

      store.dispatch({
        type: "SET_LOGIN_STATE",
        payload: { loginState: true }
      })
      // Api Call--------------------------
      setAwaiting(true);
      await loginRegisterUser(countryCodeFilter(inputValue), "phone").then(
        async (response: any) => {
          trackPixelEvent('Login register user');
          let data = response?.data;
          if (data.status === "pending") {
            store.dispatch({
              type: "SET_LOGIN_STATE",
              payload: { loginState: false }
            })
            await setUserData(data);
            if (router.routeInfo.pathname === '/login') {
              history.push('/verify-otp');
            }
            else {
              props?.setShowVerifyModal(true);
            }
          }
        }
      );
    } catch (error) {
      // console.log(error);
      setAwaiting(false);
      store.dispatch({
        type: "SET_LOGIN_STATE",
        payload: { loginState: false }
      })
    }
  };

  //***************** HANDLE KEY DOWN FUNCTION *****************//
  const handleKeyDown = (e: any) => {
    if (e.key === "Enter") {
      handleSubmit();
    }
  };

  return (
    <>
      <Section>
        <InputLabel className="mb-[10px] mt-4">Please enter your phone number.</InputLabel>
        <PhoneInput
          className={isDarkMode ? "country-selector" : ""}
          international
          countryCallingCodeEditable={false}
          defaultCountry="US"
          value={inputValue}
          limitMaxLength={true}
          onChange={setInputValue}
          onKeyDown={handleKeyDown}
          disabled={props?.loginState}
        />

        {errorMessage ? <p className="mt-2 text-red-600">{errorMessage}</p> : ""}

        {awaiting === false ? (
          <ButtonGreen className="mt-[15px]" onClick={handleSubmit} disabled={props?.loginState}>
            {switchSentenceCase("primary button", "CONTINUE")}
          </ButtonGreen>
        ) : (
          <div className="text-center">
            <Spinner />
          </div>
        )}
      </Section>
    </>
  );
}
