import React, { SyntheticEvent } from "react";

export default function Section({
  children,
  align = "left",
  className,
  onClick,
  nopad,
}: {
  children?: React.ReactNode;
  align?: "center" | "left" | "right";
  className?: string;
  onClick?: (e: SyntheticEvent) => void;
  nopad?: boolean;
}) {
  return (
    <div
      onClick={onClick}
      className={`${nopad ? "" : "p-4"} text-${align} ${className || ""}`}
    >
      {children}
    </div>
  );
}
