import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import styled from "styled-components";
import { priceFormatterUsd, sumOfMealItemInCart, trackPixelEvent, trackStandardPixelEvent } from "../../utils/utilities";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCartCirclePlus } from "@fortawesome/pro-solid-svg-icons";

const ButtonOne = styled.button`
  background: #22a45d;
  color: white;
  border-radius: 10px;
  font-weight: 600;
  padding: 15px;
  position: fixed;
  bottom: 85px;
  box-shadow: 11px 11px 30px #0e4225, 11px 11px 30px #36ff95;
`;

export default function AddToOrderFloating(props: any) {
    const history = useHistory();
    const { addMealItem } = useSelector((state: any) => state.getRestaurantData);

    const onAddItemToOrder = () => {
        trackStandardPixelEvent('AddToCart', {
            content_ids: null,
            content_name: null,
            content_type: null,
            contents: null,
            currency: 'USD',
            value: priceFormatterUsd(sumOfMealItemInCart(addMealItem)).slice(1)
        });
        history.push(`/newMealCheckout?type=${props?.type}`);
    };

    return (
        <>
            {
                (addMealItem?.length > 0)
                &&

                <ButtonOne
                    onClick={onAddItemToOrder}
                >
                    <FontAwesomeIcon icon={faCartCirclePlus} className="mr-3" />
                    Checkout ({priceFormatterUsd(sumOfMealItemInCart(addMealItem))})
                </ButtonOne>
            }
        </>
    )
}