import { useState } from "react";
import TopHead from "../../components/widgets/head/TopHead";
import Divider from "../../components/widgets/divider/Divider";
import Section from "../../components/widgets/section/Section";
import {
  TextHead1,
  TextHead3,
  ButtonGreen,
  InputLabel,
  InputText,
  ButtonInitial,
  Astk
} from "../../components/styled/styled";
import ModalBottomToTop from "../../components/widgets/modals/ModalBottomToTop";
import SearchInput from "../../components/widgets/searchinput/SearchInput";
import ContactList from "../../components/widgets/contactlist/ContactList";
import { faAngleLeft, faMagnifyingGlass, faMinus, faPlus } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router";
import { switchSentenceCase } from "../../utils/utilities";


export default function CreateTrainV2(data: any) {

  const history = useHistory();

  const [showContactModal, setShowContactModal] = useState(false);
  const [showParticipants, setShowParticipants] = useState(false);
  const [count, setCount] = useState(1);


  const GotoHandle = () => {
    history.push({
      pathname: '/schedules',
    })
  }


  const openContactModal = () => {
    setShowContactModal(prev => !prev)
  }


  const handleParticipants = () => {
    setShowContactModal(prev => !prev)
    setShowParticipants(true)
  }


  return (
    <div className="screen">
      <TopHead
        title="New Meal Calendar"
        leftTextIcon={faAngleLeft}
        leftIconStyle={{ fontSize: "24px" }}
        goToHandle={GotoHandle}
      />
      <Divider />
      <div className="grow overflow-auto">
        <Section>
          <TextHead1>Add Participants</TextHead1>
          <ButtonInitial style={{ border: "1px solid #e1e1e1", color: "#010F07", margin: "10px 0px" }}>ADD MYSELF</ButtonInitial>
        </Section>

        <Section>
          <TextHead1 style={{ marginBottom: "10px" }}>From Contacts</TextHead1>
          <ButtonGreen onClick={openContactModal} >{switchSentenceCase("primary button", "ADD FROM CONTACTS")}</ButtonGreen>

        </Section>

        {showParticipants === true ?
          <Section>
            <TextHead3>Participants</TextHead3>

            <InputLabel>Hashim Velez</InputLabel>
            <InputLabel>Soraya Taylor</InputLabel>
            <InputLabel>Yanis Patton</InputLabel>
          </Section>
          :
          null
        }
        <Divider />


        {/*---------- Modal Bottom to top - Contact Lists----------- */}
        <ModalBottomToTop showModal={showContactModal} setShowModal={setShowContactModal} >
          <TextHead3>Add Recipient Contact</TextHead3>
          <Divider />

          <Section>
            <TextHead3 style={{ textAlign: "left" }}>Search</TextHead3>
            <SearchInput icon={faMagnifyingGlass} placeholder="Search on foodly" />
            <ContactList />
          </Section>
          <ButtonGreen onClick={handleParticipants}>{switchSentenceCase("primary button", "SAVE")}</ButtonGreen>
        </ModalBottomToTop>


        <Section>
          <div className="flex justify-between items-center">
            <TextHead3 style={{ margin: "15px 0px" }}>Invite By Email </TextHead3>
            <div>
              <FontAwesomeIcon className="bg-white p-0.5 rounded mr-1.5 shadow-green-50"
                style={{ boxShadow: "0px 0px 135px 20px #35bb67" }}
                icon={faMinus}
                onClick={() => { setCount(count - 1) }}
              />
              {count}
              <FontAwesomeIcon className="bg-white p-0.5 rounded ml-1.5" icon={faPlus} onClick={() => { setCount(count + 1) }} />
            </div>
          </div>

          <InputLabel>Email Address <Astk> *</Astk></InputLabel>
          <InputText style={{ marginBottom: "20px" }} placeholder="Enter text"></InputText>

          <InputLabel>Email Address <Astk> *</Astk></InputLabel>
          <InputText style={{ marginBottom: "20px" }} placeholder="Enter text"></InputText>

          <InputLabel>Email Address <Astk> *</Astk></InputLabel>
          <InputText style={{ marginBottom: "20px" }} placeholder="Enter text"></InputText>

        </Section>

        <Section>
          <ButtonGreen onClick={() => {
            history.push({
              pathname: '/allaboard',
            })
          }}>{switchSentenceCase("primary button", "SAVE & CONTINUE")}</ButtonGreen>
        </Section>

      </div>
    </div >
  );
}


