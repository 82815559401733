import { ButtonGreen, InitialDiv, SubText1 } from "../../styled/styled";
import notFoundimage from "../../assets/not-found-img.png";


export default function NoDataFound(props: any) {
  return (
    <>
      <InitialDiv className="grid justify-center justify-items-center">
        <img src={notFoundimage} alt="" className="w-44" />
        <SubText1 className="text-center !text-gray-400 !text-base !text-[25px] mb-[10px]">Oh, Snap!</SubText1>
        <SubText1 className="text-center !text-gray-400 !text-base">{(props?.message) ? props?.message : "No Data Found!"} </SubText1>
        <ButtonGreen style={props?.btnStyle} onClick={props?.goTo}>{props?.btnText}</ButtonGreen>
      </InitialDiv>
    </>
  );

}
