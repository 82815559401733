import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart } from "@fortawesome/pro-solid-svg-icons";
import styled from 'styled-components';
import { Flex, SubText1 } from "../../styled/styled";
import DotSeprator from "../dotseprator/DotSeprator";


const HeartModalIcons = styled.div`
    width:30px;
    height:30px;
    background: #f03f3f29;
    border-radius: 50%;
    text-align: center;
    font-size: 14px ;
    color: #ff000082;
    display:flex;
    justify-content:center;
    align-items: center;
    margin-bottom: "5px";
`;

const FlexBetween = styled.div`
    font-weight: 600;
    display: flex;
    width:100%;
    justify-content: space-between;
`;


const H3 = styled.div`
overflow: hidden;
text-overflow: ellipsis;
display: -webkit-box;
-webkit-line-clamp: 2;
-webkit-box-orient: vertical;
width: 150px;
`;

export default function OrderCard(props: any) {


  return (
    <>
      {props?.data?.map((RestaurantOrder: any, index: any) => {
        return (
          <div className="flex meal-card cursor-pointer my-2">
            <img alt="" src={RestaurantOrder.img} className="mr-6 rounded-lg w-28 h-28" />
            <div className="flex-content !justify-evenly" >
              <FlexBetween>
                <H3 >{RestaurantOrder.name}</H3>
              </FlexBetween>
              <SubText1 className="my-1">{RestaurantOrder.orders}</SubText1>
              <FlexBetween>
                <Flex>
                  <SubText1>$$<DotSeprator />Chenese</SubText1>
                </Flex>
                <SubText1 className="!text-green-600">{RestaurantOrder.price}</SubText1>
              </FlexBetween>
            </div>
          </div >
        )
      })}
    </>
  );
}
