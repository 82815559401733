import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function SearchInput(props) {
  return (
    <>
      <FontAwesomeIcon icon={props.icon} style={{ color: "#868686", position: "absolute", top: "214px", marginLeft: "10px" }} />
      <input style={{
        background: "#FBFBFB",
        border: "1px solid rgba(134, 134, 134, 0.103229",
        borderRadius: "8px",
        color: "#868686",
        outlineColor: "rgb(134 134 134 / 14%)",
        paddingLeft: "2rem"
      }} type="text" placeholder={props?.placeholder}></input>
    </>
  );
}
