import Divider from "../../components/widgets/divider/Divider";
import TopHead from "../../components/widgets/head/TopHead";
import { faAngleLeft, faBuilding, faHome, faLocationCrosshairs, faMapLocationDot } from "@fortawesome/pro-solid-svg-icons";
import Section from "../../components/widgets/section/Section";
import { Astk, ButtonGreen, ButtonInitial, FlexBetween, InitialDiv, InputLabel, InputText, TextHead3 } from "../../components/styled/styled";
import { switchSentenceCase, trackPixelEvent } from "../../utils/utilities";
import { useHistory } from "react-router";
import AddressAutocomplete2 from "../../components/widgets/address/AddressAutocomplete2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useLocationComponents from "../../components/widgets/address/useLocationComponents";
import TabsForAddress from "../../components/widgets/toggleable-tabs/TabsForAddress";
import OneSideTextToggle from "../../components/widgets/buttons/OneSideTextToggle";
import { useState, useEffect } from "react";
import { getUserInfo, getUserData, setUserData } from "../../services/user";
import { addUserLocation } from "../../services/location";
import {  setUserSearchLocation } from "../../components/widgets/address/redux/addressActionCreator";
import { useDispatch, useSelector } from "react-redux";
import { getUserAddresses } from "./redux/locationActionCreator";

export default function AddLocation(data: any) {

    const history = useHistory();
    const dispatch = useDispatch();
    const { screen, user, place } = data;
    const [addressState, setAddressState] = useState(true);
    const userData = getUserInfo();

    const { locationList } = useSelector((state: any) => state?.userAddresses);

    useEffect(() => {
        callData()
    }, []);

    const callData = async () => {
        try {
            dispatch(getUserAddresses(userData?.id) as any);
            trackPixelEvent('Get user address');
        }
        catch (e) {
            console.log(e)
        }
    }

    const addressTypes = [
        {
            icon: faHome,
            name: "Home",
        },

        {
            icon: faBuilding,
            name: "Work",
        },

        {
            icon: faMapLocationDot,
            name: "Other",
        },
    ]
    const [onAddressChosen, onUseCurrentLocation, currentPlace] = useLocationComponents(screen, user, place);
    const { userSearchLocation } = useSelector((state: any) => state.getUserSearchLocation);

    const [selectedLocation, setSelectedLocation] = useState({
        streetNum: "",
        streetAddress: "",
        streetName: "",
        city: "",
        state: "",
        country: "",
        zip: "",
        lat: "",
        lang: ""
    });

    // Error Handler
    const [streetNumError, setStreetNumError] = useState(false);
    const [streetNameError, setStreetNameError] = useState(false);
    const [cityError, setCityError] = useState(false);
    const [stateError, setStateError] = useState(false);
    const [countryError, setCountryError] = useState(false);
    const [zipError, setZipError] = useState(false);

    const [active, setActive] = useState(addressTypes[0]?.name);

    const [addressType, setAddressType] = useState(addressTypes[0]?.name);
    const [otherValue, setOtherValue] = useState("");
    const [otherError, setOtherError] = useState(false);

    function handleChangeState() {
        if (locationList?.length === 0) {
            setAddressState(true);
        }
        else {
            setAddressState(!addressState);
        }
    }

    const handleChangeOther = (val: any) => {
        setOtherError(false);
        setOtherValue(val);
    }

    useEffect(() => {
        setSelectedLocation(userSearchLocation);
    }, [userSearchLocation])

    const fetchCurrentLocation = () => {
        onUseCurrentLocation()
        setStreetNumError(false);
        setStreetNameError(false);
        setCityError(false);
        setStateError(false);
        setCountryError(false);
        setZipError(false);
    }

    const onChooseAddress = async (val: any) => {
        setSelectedLocation(val)
        setStreetNumError(false);
        setStreetNameError(false);
        setCityError(false);
        setStateError(false);
        setCountryError(false);
        setZipError(false);
    }

    const handleChange = (e: any) => {
        e.preventDefault();
        const { name, value } = e.target;
        setSelectedLocation({
            ...selectedLocation,
            [name]: value
        });
        if (name === "streetNum") {
            if ((String(value).trim()?.length === 0) ||
                (value === undefined)) {
                setStreetNumError(true);
            } else {
                setStreetNumError(false);
            }
        }

        if (name === "streetName") {
            if ((String(value).trim()?.length === 0) ||
                (value === undefined)) {
                setStreetNameError(true);
            } else {
                setStreetNameError(false);
            }
        }

        if (name === "city") {
            if ((String(value).trim()?.length === 0) ||
                (value === undefined)) {
                setCityError(true);
            } else {
                setCityError(false);
            }
        }

        if (name === "state") {
            if ((String(value).trim()?.length === 0) ||
                (value === undefined)) {
                setStateError(true);
            } else {
                setStateError(false);
            }
        }

        if (name === "country") {
            if ((String(value).trim()?.length === 0) ||
                (value === undefined)) {
                setCountryError(true);
            } else {
                setCountryError(false);
            }
        }
        if (name === "zip") {
            if ((String(value).trim()?.length === 0) ||
                (value === undefined)) {
                setZipError(true);
            } else {
                setZipError(false);
            }
        }
    };

    const onHandleSubmit = async () => {
        try {
            const {
                streetNum,
                streetAddress,
                streetName,
                city,
                state,
                country,
                zip,
                lat,
                lang
            } = selectedLocation;

            if (addressType === "Other" && otherValue === "") {
                setOtherError(true)
            }
            else {
                setOtherError(false)
            }

            if ((String(streetNum).trim()?.length === 0) ||
                (streetNum === undefined)) {
                setStreetNumError(true);
            } else {
                setStreetNumError(false);
            }

            if ((String(streetName).trim()?.length === 0) ||
                (streetName === undefined)) {
                setStreetNameError(true);
            } else {
                setStreetNameError(false);
            }

            if ((String(city).trim()?.length === 0) ||
                (city === undefined)) {
                setCityError(true);
            } else {
                setCityError(false);
            }

            if ((String(state).trim()?.length === 0) ||
                (state === undefined)) {
                setStateError(true);
            } else {
                setStateError(false);
            }

            if ((String(country).trim()?.length === 0) ||
                (country === undefined)) {
                setCountryError(true);
            } else {
                setCountryError(false);
            }

            if ((String(zip).trim()?.length === 0) ||
                (zip === undefined)) {
                setZipError(true);
            } else {
                setZipError(false);
            }

            if (
                String(streetNum).trim() === "" ||
                streetNum === undefined ||
                String(streetName).trim() === "" ||
                streetName === undefined ||
                String(city).trim() === "" ||
                city === undefined ||
                String(state).trim() === "" ||
                state === undefined ||
                String(country).trim() === "" ||
                country === undefined ||
                String(zip).trim() === "" ||
                zip === undefined ||
                otherError
            ) {
                return false;
            }
            const payloadObject = {
                data: {
                    "house_no": streetNum,
                    "street_address": streetAddress,
                    "area": streetName,
                    "city": city,
                    "state": state,
                    "country": country,
                    "zip_code": zip,
                    "type": ((addressType === "Other") ? otherValue : addressType),
                    "default_address": addressState,
                    "latitude": lat,
                    "longitude": lang,
                    "user": userData?.id
                }
            };
            const localData = await getUserData();
            await addUserLocation(payloadObject).then(async (res) => {
                if (res.status === 200) {
                    trackPixelEvent('Add user location');
                    if (addressState === true) {
                        const newUpdatedUserInfo = {
                            ...localData,
                            user: ({
                                ...localData.user,
                                "city": city,
                            })
                        };
                        await setUserData(newUpdatedUserInfo);
                    }
                    setUserSearchLocation({});
                    history.push({ pathname: "/addlocationlist" })
                }
            });
        }
        catch (e) {
            console.log(e)
        }
    };

    return (
        <div className="screen">

            <TopHead
                title="Locations"
                leftTextIcon={faAngleLeft}
                leftIconStyle={{ fontSize: "24px" }}
            />
            <Divider />
            <div className="grow overflow-auto">
                <Section>
                    <FlexBetween className="items-center">
                        <AddressAutocomplete2
                            onAddressChosen={(val: any) => { onChooseAddress(val) }}
                            place={null}
                        />
                        <ButtonInitial
                            className="ml-2 h-10 !w-6/12"
                            style={{ border: "1px solid green" }}
                            onClick={fetchCurrentLocation}
                        // onClick={() => { onUseCurrentLocation(); setSelectedLocation(currentPlace) }}
                        >
                            <span className="text-green-600">
                                <FontAwesomeIcon icon={faLocationCrosshairs} style={{ color: "#22A45D", marginRight: "10px" }} />
                                {switchSentenceCase("primary button", "Locate Me")}
                            </span>
                        </ButtonInitial>
                    </FlexBetween>
                </Section>
                <Section>
                    <TextHead3 style={{ margin: "20px 0px" }}>ADD ADDRESS DETAILS</TextHead3>
                    <InputLabel>House No., Building Name <Astk> *</Astk></InputLabel>
                    <InputText
                        style={{
                            marginBottom: "20px",
                            border: streetNumError ? "1px solid red" : "",
                        }}
                        placeholder="e.g. P.O. Box 283 8562"
                        name="streetNum"
                        value={selectedLocation?.streetNum || ""}
                        onChange={(e) => handleChange(e)}
                    >
                    </InputText>

                    <InputLabel>Street Address</InputLabel>
                    <InputText
                        style={{
                            marginBottom: "20px",
                        }}
                        placeholder="e.g. P.O. Box 283 8562"
                        name="streetAddress"
                        value={selectedLocation?.streetAddress || ""}
                        onChange={(e) => handleChange(e)}
                    >
                    </InputText>

                    <InputLabel>Area, Colony <Astk> *</Astk></InputLabel>
                    <InputText
                        style={{
                            marginBottom: "20px",
                            border: streetNameError ? "1px solid red" : "",
                        }}
                        placeholder="e.g. Fusce Rd."
                        name="streetName"
                        value={selectedLocation?.streetName || ""}
                        onChange={(e) => handleChange(e)}
                    >
                    </InputText>

                    <FlexBetween>
                        <InitialDiv className="mx-1 w-6/12">
                            <InputLabel>City <Astk> *</Astk></InputLabel>
                            <InputText
                                style={{
                                    marginBottom: "20px",
                                    border: cityError ? "1px solid red" : "",
                                }}
                                placeholder="e.g. Frederick"
                                name="city"
                                value={selectedLocation?.city || ""}
                                onChange={(e) => handleChange(e)}
                            >
                            </InputText>
                        </InitialDiv>
                        <InitialDiv className="mx-1 w-6/12">
                            <InputLabel>State <Astk> *</Astk></InputLabel>
                            <InputText
                                style={{
                                    marginBottom: "20px",
                                    border: stateError ? "1px solid red" : "",
                                }}
                                placeholder="e.g. FD.."
                                name="state"
                                value={selectedLocation?.state || ""}
                                onChange={(e) => handleChange(e)}
                            >
                            </InputText>
                        </InitialDiv>
                    </FlexBetween>

                    <FlexBetween>
                        <InitialDiv className="mx-1 w-6/12">
                            <InputLabel>Country <Astk> *</Astk></InputLabel>
                            <InputText
                                style={{
                                    marginBottom: "20px",
                                    border: countryError ? "1px solid red" : "",
                                }}
                                placeholder="e.g. Nebraska"
                                name="country"
                                value={selectedLocation?.country || ""}
                                onChange={(e) => handleChange(e)}
                            >
                            </InputText>
                        </InitialDiv>
                        <InitialDiv className="mx-1 w-6/12">
                            <InputLabel>Zip Code <Astk> *</Astk></InputLabel>
                            <InputText
                            type="number"
                            inputMode="numeric"
                                style={{
                                    marginBottom: "20px",
                                    border: zipError ? "1px solid red" : "",
                                }}
                                placeholder="e.g. 20620"
                                name="zip"
                                value={selectedLocation?.zip || ""}
                                onChange={(e) => handleChange(e)}
                            >
                            </InputText>
                        </InitialDiv>
                    </FlexBetween>
                    <TabsForAddress types={addressTypes} setAddressType={setAddressType} setActive={setActive} selected={active} />
                    {(addressType === "Other")
                        ?
                        <InputText
                            type="text"
                            style={{
                                border: otherError ? "1px solid red" : "",
                            }}
                            min={0}
                            value={otherValue}
                            placeholder="Other"
                            onChange={(event: any) => handleChangeOther(event.target.value)}
                        />
                        :
                        null
                    }
                    <OneSideTextToggle leftText="Save as Default Address?" style={{ fontWeight: "500" }} switchState={addressState} handleOnChangeToggle={() => handleChangeState()} />
                </Section>
                <Section>
                    <ButtonGreen onClick={onHandleSubmit} >
                        {switchSentenceCase("primary button", "SAVE ADDRESS")}
                    </ButtonGreen>
                </Section>
            </div>
        </div>
    );
}