import { faStar } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

export default function RatingSummary({
  stars,
  count,
}: {
  stars: number;
  count: number;
}) {
  return (
    <div className="text-sm">
      {stars.toFixed(1)}{" "}
      <FontAwesomeIcon icon={faStar} style={{ color: "#AA9427", position: "relative" }} />{" "}
      {count.toLocaleString()} Ratings
    </div>
  );
}
