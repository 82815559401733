import { apiRequestService } from './apiRequestService';
import { getUserData } from './user';

export const getFaqs = async () => {
    try {
        const user = await getUserData()


        let config = {
            method: 'get',
            url: process.env.REACT_APP_API_URL + `/v1/faqs`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + user?.jwt
            },
        };
        const request = apiRequestService(config);

        return request;
    } catch (error) {
        console.log(error);
    }
}