import React, { useEffect, useState } from "react";
import Divider from "../../components/widgets/divider/Divider";
import Section from "../../components/widgets/section/Section";
import OneSideTextToggle from "../../components/widgets/buttons/OneSideTextToggle";
import styled from "styled-components";
import TopHead from "../../components/widgets/head/TopHead";
import { useHistory, useParams } from "react-router";
import ModalRemoved from "../../components/widgets/modals/ModalRemoved";
import { displayName, switchSentenceCase, isAuthenticated, trackPixelEvent } from "../../utils/utilities";
import { faAngleLeft } from "@fortawesome/pro-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { ButtonGreen, ButtonGray, TextBox2 } from "../../components/styled/styled";
import { deleteSingleCategories, setUserFaveMealList, updatedCategories } from "./redux/favouriteActionCreator";
import { getUserInfo } from "../../services/user";
import { getUserFavoriteMeal } from "../../services/favourite";
import Spinner from "../../components/widgets/spinner/Spinner";
import { IonContent, IonRefresher, IonRefresherContent, RefresherEventDetail, useIonViewDidEnter } from "@ionic/react";


export default function Faves() {
  const { id } = useParams<any>();
  const history = useHistory();
  const dispatch = useDispatch();
  const userData = getUserInfo();
  const limit = 10;

  const { userFaveMealsList, mealCategory } = useSelector((state: any) => state?.getAllFavData);
  const { userDeliveryAddress } = useSelector((state: any) => state.userAddresses);

  const [inputList, setInputList] = useState([{ id: "", name: "", selected: false }]);
  const [initialName, setInitialName] = useState("");
  const [mealCategoryNameError, setMealCategoryNameError] = useState(false);
  const [defaultMealState, setDefaultMealState] = useState(false);
  const [checkDefaultMealToggle, setCheckDefaultMealToggle] = useState(false);
  const [suggested, setSuggested] = useState(false);
  const [selectAllState, setSelectAllState] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [skip, setSkip] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefreshed, setIsRefreshed] = useState(false);
  const [totalItems, setTotalItems] = useState(0);


  useEffect(() => {
    setIsLoading(true);
    extractCategoryDetails();
    callData();
  }, [isRefreshed]);


  useIonViewDidEnter(() => {
    trackPixelEvent('Edit meal category');
  }, []);


  // ************************ Functions ******************************************************
  function handleRefresh(event: CustomEvent<RefresherEventDetail>) {
    setTimeout(() => {
      setIsRefreshed(prev => !prev);
      event.detail.complete();
    }, 2000);
  };

  const _setMealCategoryName = (value: any) => {
    setInitialName(String(value));
    if (String(value).trim()?.length === 0) {
      setMealCategoryNameError(true);
    } else {
      setMealCategoryNameError(false);
    }
  };


  // Extract Selected Category Details
  const extractCategoryDetails = () => {
    try {
      const categoryListRedux: [] = mealCategory;
      const categoryDetail: any = categoryListRedux?.filter((el: any) => el?.id == id)[0];
      setInitialName(categoryDetail?.attributes?.name);
      setDefaultMealState(categoryDetail?.attributes?.defaultMeal);
      setCheckDefaultMealToggle(categoryDetail?.attributes?.defaultMeal);
      setSuggested(categoryDetail?.attributes?.suggestedMeal);
      // CategoryData(categoryDetail?.attributes?.favMeals?.data);
    }
    catch (e) {
      console.log(e);
    }
  }

  const callData = async (skippingValue = 0) => {
    if (isAuthenticated() === false) return false;
    if (userDeliveryAddress?.city === undefined || userDeliveryAddress?.city === "") {
      setIsLoading(false);
      return false;
    }
    try {
      const response = await getUserFavoriteMeal(
        {
          id: userData?.id,
          type: "Fav",
          skip: skippingValue,
          limit: limit,
          lat: userDeliveryAddress?.lat,
          lang: userDeliveryAddress?.lang,
        });

      trackPixelEvent('Get user fave meal');

      setTotalItems(response?.total);
      let tempData = response?.data;

      if (skippingValue > 0) {
        setUserFaveMealList([...userFaveMealsList, ...tempData]);
        setIsLoading(false);

        const newArray = tempData?.map((currentObj: any) => {
          const isSelected = currentObj?.attributes?.meal_category?.data.some((e: any) => e?.id == id);
          return {
            id: currentObj?.id,
            name: currentObj?.attributes?.name,
            selected: isSelected,
          };
        });
        let tempArray = [...inputList, ...newArray]
        setInputList(tempArray);
        const filteredTempArrayLength: any[] = tempArray?.filter((el: any) => el?.selected == true)
        setSelectAllState((filteredTempArrayLength?.length == tempArray?.length))

      }
      else {
        setUserFaveMealList(tempData);
        setIsLoading(false);

        const newArray = tempData?.map((currentObj: any) => {
          let categoryMealList = currentObj?.attributes?.meal_category?.data

          const isSelected = categoryMealList?.some((e: any) => id == e.id);

          return {
            id: currentObj?.id,
            name: currentObj?.attributes?.name,
            selected: isSelected,
          };
        });
        setInputList(newArray);
        const filteredTempArrayLength: any[] = newArray?.filter((el: any) => el?.selected == true)
        setSelectAllState((filteredTempArrayLength?.length == newArray?.length))
      }
    }
    catch (e) {
      console.log(e)
      setIsLoading(false);
    }
  };

  const showMore = () => {
    setIsLoading(true);
    const skippingValue = skip + limit
    setSkip(skippingValue)
    callData(skippingValue);
  };

  function DefaultMealState() {
    setDefaultMealState(!defaultMealState);
  }

  function Suggested() {
    setSuggested(!suggested);
  }

  function selectAllButton() {
    setSelectAllState(!selectAllState);

    let tempArray = [];
    inputList?.forEach((currentObj: any) => {
      tempArray.push({
        id: currentObj?.id,
        name: currentObj?.name,
        selected: (!selectAllState),
      });
    });

    setInputList(tempArray);
  }

  const FaveMealsSwitch = (value: boolean, index: number) => {
    const list: any = [...inputList];
    list[index]["selected"] = value;
    setInputList(list);

    // Select all button automation
    const filteredTempArrayLength: [] = list?.filter((el: any) => el?.selected == true)
    setSelectAllState((filteredTempArrayLength?.length == list?.length))
  };

  const openModal = () => {
    setShowModal((prev) => !prev);
  };

  const updateAndBack = async () => {
    try {

      // Filter Out All Ids From Object Array
      let tempDataArray = [];
      inputList?.forEach((currentData: any) => {
        if (currentData?.selected === true) {
          tempDataArray.push(currentData?.id);
        }
      });

      // ---------------------Checking Validation-------------
      if (initialName.trim()?.length === 0) {
        setMealCategoryNameError(true);
        return false;
      } else {
        setMealCategoryNameError(false);
      }

      const data = {
        name: initialName,
        suggestedMeal: suggested,
        defaultMeal: defaultMealState,
        favMeals: tempDataArray,
      };

      dispatch(updatedCategories(id, data) as any)
        .then(() => {
          history.push({
            pathname: '/faves',
          })
        })
    }
    catch (e) {
      console.log(e);
    }
  };

  const deleteCategory = (ids: any) => {
    try {
      dispatch(deleteSingleCategories(ids) as any)
        .then(() => {
          history.push({
            pathname: '/faves',
          })
        })
    }
    catch (e) {
      console.log(e);
    }
  }

  return (
    <div className="screen">
      <TopHead
        title="Fave Meal Category"
        leftTextIcon={faAngleLeft}
        leftIconStyle={{ fontSize: "24px" }}
      />
      <Divider />

      {/* <div className="grow overflow-auto"> */}
      <IonContent>
        <IonRefresher slot="fixed" onIonRefresh={handleRefresh}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>
        <Section>
          <TextBox2
            style={{
              textAlign: "left",
              marginTop: "10px",
              fontWeight: "200",
              border: mealCategoryNameError ? "1px solid red" : "",
            }}
            value={switchSentenceCase("head text", initialName)}
            onChange={(event) => _setMealCategoryName(event.target.value)}
            placeholder={switchSentenceCase("head text", `${displayName()} Faves 1`)}
            maxLength={30}
          />

          <OneSideTextToggle
            leftText="Default for New Fave Meals"
            id="defaultMealState"
            name="defaultMealState"
            switchState={defaultMealState}
            handleOnChangeToggle={DefaultMealState}
            disabled={checkDefaultMealToggle}
          />

          <OneSideTextToggle
            leftText="Visible to Others"
            id="suggested"
            name="suggested"
            switchState={suggested}
            handleOnChangeToggle={Suggested}
          />

          {(userFaveMealsList?.length > 0)
            &&
            <OneSideTextToggle
              leftText={"My Fave Meals"}
              style={{ fontSize: "18", fontWeight: "500" }}
              beforeBtnText="Select All"
              id="selectAllState"
              name="selectAllState"
              switchState={selectAllState}
              handleOnChangeToggle={selectAllButton}
            />
          }

          <Divider />

          {userFaveMealsList?.map((favMeal: any, index: any) => {
            return (
              <React.Fragment key={index}>
                <OneSideTextToggle
                  leftText={switchSentenceCase("head text", favMeal?.attributes?.name)}
                  // style={{ paddingLeft: "15px" }}
                  name={favMeal?.attributes?.name}
                  id={favMeal?.id}
                  switchState={inputList[index]?.selected}
                  handleOnChangeToggle={() => {
                    FaveMealsSwitch(!inputList[index]?.selected, index);
                  }}
                />
              </React.Fragment>
            );
          })}

          {(totalItems > limit) &&
            (
              (totalItems != userFaveMealsList?.length) ?

                <div
                  className="text-green-600 text-center cursor-pointer mt-4 font-semibold"
                  onClick={() => { showMore() }}
                >
                  {(!isLoading) ? <span>See More</span> : <span><Spinner /></span>}
                </div>
                :
                <div
                  className="text-green-600 text-center mt-4 font-semibold"
                >
                  No More Fave Meals
                </div>
            )
          }

          <ButtonGreen style={{ marginTop: "25px" }} onClick={updateAndBack}>
            {switchSentenceCase("primary button", "UPDATE & BACK")}
          </ButtonGreen>

          {(!checkDefaultMealToggle)
            &&
            <ButtonGray onClick={openModal} style={{ marginTop: "10px" }}>
              {switchSentenceCase("primary button", "DELETE CATEGORY")}
            </ButtonGray>
          }

          <ModalRemoved
            firstText="Are you sure you want to"
            secondText="remove this category?"
            btnOneText="OOPS!  NEVER MIND..."
            btnTwoText="YES, REMOVE IT"
            showModal={showModal}
            setShowModal={setShowModal}
            deleteItem={deleteCategory}
            id={id}
          />
        </Section>
        <Divider />
      </IonContent>
      {/* </div> */}
    </div >
  );
}
