import imgUrl from "../../assets/train.svg";

export default function Divider({
  style = "solid",
  logo = false,
  className,
}: {
  style?: "dotted" | "dashed" | "solid" | "double";
  logo?: boolean;
  className?: string;
}) {
  let logoComp;
  if (logo) {
    logoComp = (
      <img
        src={imgUrl}
        className="inline-block mx-auto relative w-8 h-8 -top-4"
      ></img>
    );
  }
  return (
    <div
      className={`border-t h-0 border-${style} text-center flex divider ${className || ""
        }`}
    >
      {logoComp}
    </div>
  );
}
