const initialValue = {
    type: "",
    senderName: "",
    name: "",
    description: "",
    recipientName: "",
    recipient: "",
    recipientEmail: "",
    recipientId: "",
    addressId: "",
    selectedEndBy: "",
    endAfterDays: "",
    endByDate: "",
    slots: {},
    invitations: [],
    limit_per_meal: 0,
    single_sponsor: false,
    includes_fees_n_tips: false,
    autofill_slots_with_favs: false,
    notify_price_increase: false,
    push_notify: false,
    sms_notify: false,
    alert_hour: false,
    schedulesList: [],
    scheduleForMe: [],
    totalForMe: 0,
    scheduleForOther: [],
    totalForOther: 0,
    scheduleDetails: {},
    scheduleSlots: [],
    awaiting: false,
    totalSlots: 0,
    scheduleEmails: [],
    addressList: [],
    slotDetails: {},
    favMealList: [],
    selectedSlots: [],
    notificationDetails: {},
    scheduleInvitationDetail: {},
    notificationsList: [],
    totalNotification: 0,
    orderRedirectUrl: ""
};

const createScheduleForMe = (state = initialValue, action: any) => {

    switch (action?.type) {

        case "STEP_1":
            return {
                ...state,
                type: action?.payload?.type
            };

        case "SET_RECIPIENT_DATA":
            return {
                ...state,
                recipientName: action?.payload?.recipientName,
                recipient: action?.payload?.recipientContact,
                recipientEmail: action?.payload?.recipientEmail,
                recipientId: action?.payload?.recipientId,
                addressId: action?.payload?.addressId,
            };

        case "STEP_2":
            return {
                ...state,
                senderName: action?.payload?.senderName,
                name: action?.payload?.name,
                description: action?.payload?.description,
                slots: action?.payload?.slots,
                invitations: action?.payload?.invitations,
                addressId: action?.payload?.addressId,
                selectedEndBy: action?.payload?.selectedEndBy,
                endAfterDays: action?.payload?.endAfterDays,
                endByDate: action?.payload?.endByDate,
                endAfterDaysFormatted: action?.payload?.endAfterDaysFormatted
            };

        case "STEP_3":
            return { ...state, scheduleDetails: action?.payload?.response };

        case "CLEAR":
            return {
                type: "",
                senderName: "",
                name: "",
                description: "",
                recipientName: "",
                recipientEmail: "",
                recipient: "",
                recipientId: "",
                selectedEndBy: "",
                endAfterDays: "",
                endByDate: "",
                endAfterDaysFormatted: "",
                slots: {},
                invitations: [],
                limit_per_meal: 0,
                single_sponsor: false,
                includes_fees_n_tips: false,
                autofill_slots_with_favs: false,
                notify_price_increase: false,
                push_notify: false,
                sms_notify: false,
                alert_hour: false,
                addressId: "",
            };

        case "CLEAR_SCHEDULES_DETAILS":
            return {
                ...state, scheduleDetails: {}, selectedSlots: [], slotDetails: {}, awaiting: true
            };

        case "CLEAR_INVITATION_DETAILS":
            return {
                ...state, notificationDetails: {}, scheduleInvitationDetail: {}, awaiting: true
            };

        case "GET_SCHEDULES":
            return { ...state, schedulesList: action.payload };

        case "GET_SCHEDULES_FOR_ME":
            if (action.payload.skip > 0) {
                const oldData = state.scheduleForMe
                const newData = [...oldData, ...action.payload.action.data];
                return { ...state, scheduleForMe: newData, totalForMe: action.payload.action.meta.pagination.total };
            }
            else {
                return { ...state, scheduleForMe: action.payload.action.data, totalForMe: action.payload.action.meta.pagination.total };
            }

        case "GET_SCHEDULE_FOR_OTHER":

            if (action.payload.skip > 0) {
                const oldData = state.scheduleForOther
                const newData = [...oldData, ...action.payload.action.data];
                return { ...state, scheduleForOther: newData, totalFromMe: action.payload.action.meta.pagination.total };
            }

            else {
                return { ...state, scheduleForOther: action.payload.action.data, totalForOther: action.payload.action.meta.pagination.total };
            }

        case "SET_SCHEDULE_DETAILS":
            let invitedMembers = [];
            action?.payload?.attributes?.invitations?.data?.forEach((obj: any) => {
                invitedMembers.push(obj);
            });
            return { ...state, scheduleDetails: action?.payload, scheduleEmails: invitedMembers };

        case "GET_SCHEDULE_DETAILS":

            if (action.payload.type === "for_me") {
                let tempArray = [];
                const filteredSchedule = state.scheduleForMe.filter((data) => data.id === action.payload.id);

                filteredSchedule[0]?.attributes?.invitations?.data?.forEach((obj: any) => {
                    tempArray.push(obj);
                });

                // const slotsData = filteredSchedule[0]?.attributes?.slots?.data
                return { ...state, scheduleDetails: filteredSchedule[0], scheduleEmails: tempArray };
            }
            else if (action.payload.type === "for_other") {
                let tempArray = [];
                const filteredSchedule = state.scheduleForOther.filter((data) => data.id === action.payload.id);

                filteredSchedule[0]?.attributes?.invitations?.data?.forEach((obj: any) => {
                    tempArray.push(obj);
                });

                return { ...state, scheduleDetails: filteredSchedule[0], scheduleEmails: tempArray };
            }
            else {
                return { ...state }
            }

        case "SET_SCHEDULE_SLOTS":
            return { ...state, scheduleSlots: action?.payload };

        case "SET_TOTAL_SLOTS":
            return { ...state, totalSlots: action?.payload };

        case "SET_SCHEDULE_SLOTS_LOADER":
            return { ...state, awaiting: action?.payload };

        case "GET_ALL_SCHEDULE_SLOTS":
            if (action.payload.skip > 0) {
                const oldData = state.scheduleSlots
                const newData = [...oldData, ...action.payload.action.data];
                const data = newData.map((item: any) => {
                    item.attributes["is_selected"] = false;
                    return item;
                })
                return { ...state, scheduleSlots: data, totalSlots: action.payload.action.meta.pagination.total, awaiting: action?.payload?.setAwaiting };
            }
            else {
                const values = action.payload.action.data
                const newSlots = values.map((item: any) => {
                    item.attributes["is_selected"] = false;
                    return item;
                })
                return { ...state, scheduleSlots: newSlots, totalSlots: action.payload.action.meta.pagination.total, awaiting: action?.payload?.setAwaiting };
            }

        case "GET_SLOT_DETAILS":
            const slot = state.scheduleSlots.filter((data) => data.id === action.payload);
            return { ...state, slotDetails: slot[0] };

        case "UPDATE_SLOT_DATA":
            const updatedData = action.payload.data;
            const index = state.scheduleSlots.findIndex((obj) => obj.id === action.payload.id)
            if (state.scheduleSlots[index].attributes["is_selected"] === false) {
                updatedData.attributes["is_selected"] = false;
            }
            else {
                updatedData.attributes["is_selected"] = true;
            }
            state.scheduleSlots[index] = updatedData;
            return { ...state, slotDetails: updatedData, scheduleSlots: state.scheduleSlots };

        case "ADD_NEW_INVITATION":
            const data = state.scheduleEmails
            const add = [...action.payload, ...data]
            return { ...state, scheduleEmails: add };

        case "REMOVE_INVITATION":
            const filteredData = state.scheduleEmails.filter(
                (element) => element.id !== action.payload
            );
            return { ...state, scheduleEmails: filteredData };

        case "UPDATE_SCHEDULES_DATA":
            return { ...state, scheduleDetails: action.payload };

        case "GET_USER_ADDRESS":
            return { ...state, addressList: action.payload };

        case "REMOVE_MULTIPLE_SLOTS":

            const arrayListTemp = state.scheduleSlots
            const b = action.payload
            const filtered = arrayListTemp.filter((currentObj) => !b.includes(currentObj?.id))
            // action.payload.forEach(function (id: any) {
            //     arrayListTemp.forEach(function (element) {
            //         if (element.id !== id) {
            //             tempArr.push(element)
            //         }
            //     })
            // });

            return { ...state, scheduleSlots: filtered };

        case "REMOVE_SINGLE_SLOT":
            const filteredSlot = state.scheduleSlots.filter(
                (element) => element.id !== action.payload
            );
            return { ...state, scheduleSlots: filteredSlot };

        case "GET_FAV_MEAL":
            // const slots = action.payload
            // let favSlotName = [];
            // slots?.forEach((obj: any) => {
            //     favSlotName.push({ id: obj.id, name: obj.attributes.name, total: obj.attributes.total });
            // });
            return { ...state, favMealList: action.payload };

        case "SET_USER_FAV_MEAL":
            return { ...state, favMealList: action?.payload };


        case "SELECT_EMPTY_SLOTS":
            const slotsList = state.scheduleSlots
            const selected = action.payload
            const selectedSlots = slotsList.filter((currentObj) => selected.includes(currentObj?.id))
            return { ...state, selectedSlots: selectedSlots };

        case "ORDER_REDIRECT_URL":
            return { ...state, orderRedirectUrl: action.payload };

        case "ASSIGN_MEAL_TO_SLOT":
            const updateData = action.payload.slotArray
            updateData?.forEach((obj: any) => {
                const index = state.scheduleSlots.findIndex((currentObj) => obj.id === currentObj?.id)
                obj.attributes.order_type = action.payload.order_type;
                obj.attributes["is_selected"] = false;
                state.scheduleSlots[index] = obj;
            });
            return { ...state, scheduleSlots: state.scheduleSlots, selectedSlots: [] };

        case "MEAL_SPONSORED":
            const item = action.payload.slotArray
            item?.forEach((obj: any) => {
                const index = state.scheduleSlots.findIndex((currentObj) => obj.id === currentObj?.id)
                obj.attributes.sponsoredBy.data = action.payload.sponsoredBy;
                obj.attributes.order_type = action.payload.order_type;
                obj.attributes["is_selected"] = false;
                state.scheduleSlots[index] = obj;
            });
            return { ...state, scheduleSlots: state.scheduleSlots, selectedSlots: [] };

        case "ORDER_FOOD_FOR_SLOT":
            const index1 = state.scheduleSlots.findIndex((currentObj) => action.payload.slotId === currentObj?.id)
            state.scheduleSlots[index1].attributes.sponsoredBy.data = action.payload.sponsoredBy;
            state.scheduleSlots[index1].attributes.order_type = action.payload.order_type;
            state.scheduleSlots[index1].attributes.mealMeOrderObject = action.payload.mealMeOrderObject;
            state.scheduleSlots[index1].attributes["is_selected"] = false;
            return { ...state, scheduleSlots: state.scheduleSlots, selectedSlots: [] };

        case "SELF_COOKED_SLOTS":
            action.payload.slots?.forEach((id: any) => {
                const index = state.scheduleSlots.findIndex((currentObj) => id === currentObj?.id)
                state.scheduleSlots[index].attributes["is_selected"] = false;
                state.scheduleSlots[index].attributes.self_cooked_description = action.payload.description;
                state.scheduleSlots[index].attributes.self_cooked = true;
            });
            return { ...state, scheduleSlots: state.scheduleSlots, selectedSlots: [] };

        case "INVITATION_SCHEDULE_DETAILS":
            return { ...state, notificationDetails: action.payload };


        case "GET_USER_NOTIFICATIONS":
            if (action.payload.skip > 0) {
                const oldData = state.notificationsList
                const newData = [...oldData, ...action.payload.action.data];
                return { ...state, notificationsList: newData, totalNotification: action.payload.action.meta.pagination.total };
            }
            else {
                return { ...state, notificationsList: action.payload.action.data, totalNotification: action.payload.action.meta.pagination.total };
            }

        case "READ_NOTIFICATION":
            return { ...state, notificationDetails: action.payload };

        case "CLEAR_NOTIFICATION":
            return {
                ...state, notificationsList: state.notificationsList.filter(notification => !action.payload.includes(notification.id))
            };

        case "GET_ INDIVIDUAL_FAV_ITEMS_DETAILS":
            const singlefaveitems = state.favMealList.filter((data) => data.id === action.payload.id);
            return { ...state, SingleFaveItemsDetail: { items: singlefaveitems[0], date: action.payload.date, time: action.payload.time } };

        case "GET_SPONSOR_FAVE_ITEM_DETAILS":
            const filteredFaveData = state.scheduleSlots.filter((data) => data?.attributes?.favMeal?.data?.id === action.payload.faveId);
            return { ...state, SingleFaveItemsDetail: { items: filteredFaveData[0]?.attributes?.favMeal?.data } };

        case "GET_INVITATION_SCHEDULE_DETAILS":
            return { ...state, scheduleDetails: action.payload[0] };

        case "GET_INVITATION_DATA":
            return { ...state, scheduleInvitationDetail: action.payload[0] };

        case "REMOVE_SCHEDULE":
            if (action.payload.type === "for_me") {
                const filteredSchedules = state.scheduleForMe.filter((obj) => obj.id !== action.payload.id);
                return { ...state, scheduleForMe: filteredSchedules };
            }
            else {
                const filteredSchedules = state.scheduleForOther.filter((obj) => obj.id !== action.payload.id);
                return { ...state, scheduleForOther: filteredSchedules };
            }

        default:
            return state;

        case "UPDATE_SLOT_RESTAURANT_SCHEDULE_DATA":
            const slotIndex = state.selectedSlots.findIndex((obj) => obj.id === action.payload.id)
            state.selectedSlots[slotIndex].attributes.favMeal.data.attributes.restaurant.data.attributes.scheduleData = action.payload.data;
            return { ...state, slotDetails: state.selectedSlots[slotIndex] };
    }
};

export default createScheduleForMe;