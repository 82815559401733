import styled from 'styled-components';
import useDarkMode from '../../hooks/darkmode/DarkMode';
import step_success from "../../assets/dashboard/step_success.svg"

const Tab = styled.div<{ isDarkMode: boolean }>`
  overflow: hidden;
  border: 1px solid white;
  border-radius: 30px;
  background-color: ${props => (props?.isDarkMode ? '#2e2e2e' : '#f1f1f1')};
  display: flex;
  justify-content: center;
  margin: 20px 0px;
`;

const Tablinks = styled.button<{ active?: boolean; isDarkMode: boolean; disabled?: boolean }>`
  background-color: inherit;
  float: left;
  border: none;
  outline: none;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  padding: 10px 20px;
  transition: 0.3s;
  font-size: 15px;
  color: ${props => (props?.isDarkMode ? 'white' : 'black')};
  width:  -webkit-fill-available;
  display: flex; /* Added display flex */
  align-items: center; /* Added align-items center to vertically center the content */
  justify-content: center;

  &:not(:disabled):hover {
    background-color: #ddd;
  }
  ${({ active, disabled }) => `
    ${active && !disabled && `
      background: #22A45D;
      color: white;
      padding: 10px 20px;
      &:not(:disabled):hover {
        background-color: #22A45D;
      }
    `}

    ${!disabled &&
        `&:first-of-type {
         border-top-right-radius: 0;
         border-bottom-right-radius: 0;
        }
    `}
  `}
   img {
    border: 1px solid #d8d7d7; /* Add white border directly to the image */
    border-radius: 50%;
    margin-right: 5px; /* Adjust the margin as needed */
   }
`;

export default function TabsForRecipientDetails(props: any) {
    const isDarkMode = useDarkMode();

    const changeTipValue = (value: string) => {
        const selectedTab = props.types.find((tab: any) => tab.value === value);
        if (!selectedTab.disabled && !props.disabled) {
            props.setActive(value);
        }
    }

    return (
        <>
            <Tab isDarkMode={isDarkMode}>
                {props?.types.map((type: any) => (
                    <Tablinks
                        isDarkMode={isDarkMode}
                        key={type?.value}
                        active={props?.selected === type?.value}
                        disabled={props.disabled || type.disabled} // Pass disabled prop to Tablinks
                        onClick={() => changeTipValue(type?.value)}
                    >
                        {type.active && <img src={step_success} alt="step_success" width={18} />} {/* Conditionally render the image or text */}
                        {type?.name}
                    </Tablinks>
                ))}
            </Tab>
            <p />
        </>
    );
};


