import { useRef, useState } from "react";
import styled from 'styled-components';
import { switchSentenceCase } from "../../../utils/utilities";
import { ButtonGray, ButtonGreen, FlexBetween, TextHead5, TextHead4, TextHead1, moveTopBottom } from "../../styled/styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight, faHeart } from "@fortawesome/pro-regular-svg-icons";

import Section from "../section/Section";
import RadioButtonText from "../buttons/RadioButtonText";
import cardIconImg from "../../assets/RectangleCardIcon.png"
import { useHistory } from "react-router";
import useDarkMode from "../../hooks/darkmode/DarkMode";

const ModalDiv = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0, 0.8);
  z-index: 1000;
  overflow-y: auto;
`;


const ContentDiv = styled.div<{ isDarkMode: boolean }>`
 position: absolute;
  background-color: ${props => (props?.isDarkMode ? '#2e2e2e' : '#fff')};
  margin-top: 80px;
  padding: 10px;
  z-index: 1000;
  width: 600px;
  max-width: 100%;
  border-radius: .5em;
  text-align: center;
  animation: ${moveTopBottom} .4s ease-in-out;
`;


const HeartModalIcon = styled.div`
    padding: 3px;
    background: #f03f3f29;
    border-radius: 40px;
    text-align: center;
    height: -webkit-fit-content;
    font-size: 14px;
    color: #ff000082;
    width: 28px;
    margin-top: 5px;
    margin-left: 10px;
`;





export default function PriceChnageConfirmModal(props: any) {
    const history = useHistory();
    const isDarkMode = useDarkMode();
    const modalRef = useRef();

    const [chocolateTopCookie, setChocolateTopCookie] = useState();

    const closeModal = (e: any) => {
        if (modalRef.current === e.target) {
            props?.setShowModal(false)
        }
    }

    return (
        <>
            {props?.showModal ? (
                <ModalDiv ref={modalRef} onClick={closeModal}>

                    <ContentDiv isDarkMode={isDarkMode}>
                        <Section>
                            <TextHead1 style={{ textAlign: "left" }}>Work Fuel </TextHead1>
                            <TextHead1 style={{ textAlign: "left" }}>Tue 08 Mar 07:00P</TextHead1>
                            <TextHead1 style={{ color: "#22A45D" }}>Price Update</TextHead1>
                        </Section>

                        <Section>
                            <TextHead5 style={{ fontWeight: "500" }}>Items in your order changed price from when you initially scheduled delivery.</TextHead5>
                            <TextHead5 style={{ margin: "10px 0px", fontWeight: "500" }}> Please accept the changes below or the order will be canceled.</TextHead5>
                        </Section>

                        <Section>
                            <TextHead5 style={{ textAlign: "left" }}>Previous Total</TextHead5>
                            <FlexBetween style={{ alignItems: "center" }}>
                                <TextHead4 style={{ textAlign: "left", marginLeft: "5px" }}>
                                    Cookie Sandwich Meal
                                </TextHead4>
                                <div className="flex items-center">
                                    <HeartModalIcon >
                                        <FontAwesomeIcon icon={faHeart} />
                                    </HeartModalIcon>
                                    <TextHead5 style={{ marginLeft: "10px" }}> $16.90</TextHead5>
                                </div>
                            </FlexBetween>
                        </Section>
                        <Section>
                            <TextHead5 style={{ textAlign: "left" }}>New Total</TextHead5>
                            <FlexBetween style={{ alignItems: "center" }}>
                                <TextHead4 style={{ textAlign: "left", marginLeft: "5px" }}>
                                    Cookie Sandwich Meal
                                </TextHead4>

                                <div className="flex items-center">
                                    <HeartModalIcon >
                                        <FontAwesomeIcon icon={faHeart} />
                                    </HeartModalIcon>
                                    <TextHead5 style={{ marginLeft: "10px" }}> $14.11</TextHead5>
                                </div>
                            </FlexBetween>
                        </Section>

                        <Section>
                            <FlexBetween>
                                <TextHead5>Subtotal</TextHead5>
                                <TextHead5>$20.05</TextHead5>
                            </FlexBetween>
                            <FlexBetween>
                                <TextHead5>Taxes</TextHead5>
                                <TextHead5>$4.00</TextHead5>
                            </FlexBetween>
                            <FlexBetween>
                                <TextHead5>Fees</TextHead5>
                                <TextHead5>$3.96</TextHead5>
                            </FlexBetween>
                            <FlexBetween>
                                <TextHead5>Tips</TextHead5>
                                <TextHead5>$4.00</TextHead5>
                            </FlexBetween>
                            <FlexBetween>
                                <TextHead5 style={{ fontWeight: "500" }}>Total</TextHead5>
                                <TextHead5 style={{ fontWeight: "500" }}>$31.01</TextHead5>
                            </FlexBetween>
                            <RadioButtonText
                                label="Confirm 24 hours prior if price increases"
                                labelStyle={{ fontSize: "15px" }}
                                name="cofirm24hours"
                                value="cofirm24hours"
                                checked={chocolateTopCookie}
                                setter={setChocolateTopCookie}
                            />
                            <FlexBetween style={{ alignItems: "center" }}>
                                <div className="flex"><img alt="" src={cardIconImg} className="mr-2" /><TextHead5>•••• 1234</TextHead5></div><FontAwesomeIcon icon={faAngleRight} />
                            </FlexBetween>
                        </Section>
                        <ButtonGreen style={{ margin: "15px 0px" }} onClick={() => history.push({ pathname: "/paymentmethod" })}>{switchSentenceCase("primary button", "PLACE ORDER")}</ButtonGreen>
                        <ButtonGray onClick={() => props?.setShowModal(prev => !prev)}>{switchSentenceCase("primary button", "OOPS!  NEVER MIND...")}</ButtonGray>
                    </ContentDiv>
                </ModalDiv >
            ) : null
            }
        </>
    );
};


