import { faAngleRight } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { Flex, FlexBetween } from "../../styled/styled";

const AccordionContainer = styled.div`
  border-radius: 5px;
  overflow: hidden;
`;

const AccordionTitle = styled.div`
  padding: 8px 10px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
`;


const AccordionContent = styled.div <{ isExpanded: boolean }>`
  padding: 8px;
  display: ${({ isExpanded }) => (isExpanded ? "block" : "none")};
`;


const ChevronIcon = styled.span <{ isExpanded: boolean }>`
  margin-left: 5px;
  transform: ${({ isExpanded }) => (isExpanded ? "rotate(90deg)" : "rotate(0)")};
  display: inline-block;
  transition: transform 0.3s ease;
`;


const AccordionNew = ({ title, children, isOpen, setIsOpen = undefined, requireText = "", leftTextIconClassName, titleClassName, leftTextIcon, leftSubTitle, error = false, rightIcon }) => {

    const [isExpanded, setExpanded] = useState(isOpen || false);


    useEffect(() => {
        setExpanded(isOpen || false);
    }, [isOpen]);

    const toggleAccordion = () => {
        setExpanded((prevExpanded) => !prevExpanded);
        setIsOpen(!isOpen)
    };

    return (
        <AccordionContainer>
            <AccordionTitle onClick={rightIcon ? toggleAccordion : undefined} style={{ color: error ? "red" : null }}>
                <FlexBetween className="items-center">
                    <Flex className="items-center">
                        {leftTextIcon == null ? null : (
                            <FontAwesomeIcon
                                icon={leftTextIcon}
                                className={leftTextIconClassName}
                            />
                        )}
                        <div className="grid">
                            <span className={titleClassName}>
                                {title} {requireText && <>[{requireText}]</>}
                            </span>
                            <span className="mt-[-5px]">
                                {leftSubTitle === null ? null : (
                                    <span className="text-sm font-semibold text-gray-400">{leftSubTitle}</span>
                                )}
                            </span>
                        </div>
                    </Flex>
                    {rightIcon && <ChevronIcon isExpanded={isExpanded}><FontAwesomeIcon icon={faAngleRight} /></ChevronIcon>}
                </FlexBetween>
            </AccordionTitle>
            <AccordionContent isExpanded={isExpanded}>{children}</AccordionContent>
        </AccordionContainer>
    );
};

export default AccordionNew;
