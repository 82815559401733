import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHouse,
  faHeart,
  faCalendar,
  faReceipt,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router";
import { useIonRouter } from "@ionic/react";
import { SubText1 } from "../../styled/styled";
import { getUserInfo } from "../../../services/user";
import useDarkMode from "../../hooks/darkmode/DarkMode";

const TABS = {
  'manage-orders': {
    icons: faReceipt,
    title: 'Orders',
    slug: ['manage-orders', 'orders', 'getfoodnow', 'search?for=get-food-now', 'restaurantDetail', 'newMealCheckout', 'favesallitems/for-order', 'restaurantallitems/for-order', 'ordersallitems/for-order', 'for=get-food-now']
  },
  'faves': {
    icons: faHeart,
    title: 'Faves',
    slug: ['faves', 'search?for=faves', 'restaurant', 'newmealfave', 'restaurantallitems/for-fave', 'ordersallitems/for-faves', 'favesallitems/for-faves', 'for=faves', 'faveaddnewcategory', 'editmealcategory', 'addtofavemealcategory']
  },
  'home': {
    icons: faHouse,
    title: 'Home',
    slug: ['home', '']
  },
  'calendar-home': {
    icons: faCalendar,
    title: 'Calendars',
    slug: ['calendar-home', 'schedules', 'create-calendar', 'calendar-slots', 'newmealpayments', 'schedulewslots', 'view-calendar', 'invitefoodie', 'scheduleslotfill']
  },
  'profile': {
    icons: faUser,
    title: 'Profile',
    slug: ['profile', 'userprofiledetails', 'paymentmethod', 'tippreference', 'addlocationlist', 'socialaccounts', 'friends', 'manage-accounts', 'contactus', 'faqs', 'friendsinvite', 'friends-profile']
  },
};



export default function BottomNav() {
  const history = useHistory();
  const router = useIonRouter();
  const user = getUserInfo();
  const isDarkMode = useDarkMode();


  const showNav =
    !(
      router.routeInfo.pathname === '/onboarding' ||
      router.routeInfo.pathname === '/login' ||
      router.routeInfo.pathname === '/verifyPhone' ||
      router.routeInfo.pathname === '/scancard' ||
      router.routeInfo.pathname === '/timeslot' ||
      router.routeInfo.pathname === '/termsandconditions' ||
      router.routeInfo.pathname === '/privacypolicy' ||
      router.routeInfo.pathname.includes("/invitation") ||
      user === undefined
    );


  return (
    <>
      {showNav && (
        <div
          className={
            isDarkMode
              ? "flex border-t z-[1] absolute bottom-0 w-full bg-black"
              : "flex border-t z-[1] absolute bottom-0 w-full bg-white"
          }
        >
          {Object.entries(TABS)?.map(([tab, { icons, title, slug }], index) => {
            const routePathname = router.routeInfo.pathname?.replace('/', '');
            const isActive = slug?.includes(routePathname) || slug.includes(routePathname?.split('/')?.[0]) || slug.includes(router.routeInfo?.search?.split('?')?.[1]);

            return (
              <div key={index} className={`grow text-center ${isActive ? "border-t-2 border-solid border-[#22a45d]" : ""}`}>
                <FontAwesomeIcon
                  icon={icons}
                  className={`py-1 icon text-[1.7rem] ${isActive ? "activeicon" : ""}`}
                  onClick={(e) => {
                    e.preventDefault();
                    history.push(`/${tab}`);
                  }}
                />
                <SubText1 onClick={
                  (e) => {
                    e.preventDefault();
                    history.push(`/${tab}`);
                  }
                }
                  className={`mb-2 ${isActive ? "!text-[#22a45d] !font-semibold cursor-pointer" : "cursor-pointer"}`}>
                  {title}
                </SubText1>
              </div>
            );
          })}
        </div>
      )}
    </>
  );
}
