import { objectType } from '../type';
import { apiRequestService } from './apiRequestService';

export async function setUserData(data: any) {
    localStorage.setItem("MealTrendUser", JSON.stringify(data));
}

export async function getUserData() {
    return JSON.parse(localStorage.getItem("MealTrendUser"));
}

export function getUserInfo() {
    return JSON.parse(localStorage.getItem("MealTrendUser"))?.user;
}

const API_URL = process.env.REACT_APP_API_URL


export async function loginRegisterUser(contact: string, provider: string) {
    const data = JSON.stringify({
        "contact": contact,
    });
    let config = {
        method: 'post',
        url: `${API_URL}/v1/auth/login/${provider}`,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    };
    const request = apiRequestService(config);
    return request;
}

export async function verifyUser(otp: string, provider: string) {
    let user = await getUserData();

    const data = JSON.stringify({
        "contact": user?.contact,
        "otp": otp
    });

    let config = {
        method: 'post',
        url: `${API_URL}/v1/auth/verify-otp/${provider}`,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    };
    const request = apiRequestService(config);
    return request;
}

export async function socialLogin(data: any, provider: string) {
    let config = {
        method: 'post',
        url: `${API_URL}/v1/auth/social-login/${provider}`,
        headers: {
            'Content-Type': 'application/json',
        },
        data: data
    };
    const request = apiRequestService(config);
    return request;
}

export const userProfileUpdate = async (data: objectType) => {
    try {
        const user = await getUserData()
        let config = {
            method: 'PUT',
            url: API_URL + `/v1/users/${user?.user?.id}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + user?.jwt
            },
            data: data
        };
        const request = apiRequestService(config);
        return request;
    } catch (error) {
        console.log(error);
    }
}

export const userMe = async () => {
    try {
        const user = await getUserData()

        let config = {
            method: 'get',
            url: API_URL + `/v1/users/me`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + user?.jwt
            },
        };
        const request = apiRequestService(config);
        return request;
    } catch (error) {
        console.log(error);
    }
}
/**
 * Retrieves user data from the API.
 * @returns {Promise<any>} The response from the API request.
 */
export const userFind = async (query_string: string): Promise<any> => {
    try {
        const user = await getUserData();

        const config = {
            method: 'get',
            url: `${API_URL}/v1/users/findFriends/${user?.user?.id}?query_string=${query_string}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user?.jwt}`
            }
        };
        const response = await apiRequestService(config);
        return response;
    } catch (error) {
        console.log(error);
    }
};

export const contactUsService = async (payloadData: objectType) => {
    try {
        const user = await getUserData();

        let config = {
            method: 'POST',
            url: API_URL + `/v1/contact-uses`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + user?.jwt
            },
            data: payloadData
        };
        const request = apiRequestService(config);
        return request;

    } catch (error) {
        console.log(error);
    }
};

export async function changeEmailPhone(data: objectType) {
    try {
        const user = await getUserData();

        let config = {
            method: 'POST',
            url: API_URL + `/v1/users/updateEmailPhone`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + user?.jwt
            },
            data: data
        };
        const request = apiRequestService(config);
        return request;
    }
    catch (error) {
        console.log(error);

    }
};


export async function verifyEmailPhone(data: objectType) {
    try {

        const user = await getUserData();

        let config = {
            method: 'PUT',
            url: API_URL + `/v1/users/verifyEmailPhone/${user?.user?.id}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + user?.jwt
            },
            data: data
        };
        const request = apiRequestService(config);
        return request;
    }
    catch (error) {
        console.log(error);

    }
};

export const updateUser = async (data: objectType, id: any) => {
    try {
        const user = await getUserData();

        let config = {
            method: 'PUT',
            url: API_URL + `/v1/users/${id}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + user?.jwt
            },
            data: data
        };
        const request = apiRequestService(config);
        return request;
    } catch (error) {
        console.log(error);
    }
}

export const getUserBySlug = async (token: string): Promise<any> => {

    try {
        const config = {
            method: 'get',
            url: `${API_URL}/v1/users?${(token.includes("s") ? ("filters[slug][$eq]=" + token?.slice(0, -1)) : ("filters[id][$eq]=" + token?.slice(0, -1)))}`,
            headers: {
                'Content-Type': 'application/json',
            }
        };
        const response = await apiRequestService(config);
        return response;
    } catch (error) {
        console.log(error);
    }
};

export const findUserByUid = async (uid: string) => {

    try {
        const user = await getUserData();

        let config = {
            method: 'GET',
            url: API_URL + `/v1/users/?${(uid.includes("s") ? ("filters[slug][$eq]=" + uid?.slice(0, -1)) : ("filters[id][$eq]=" + uid?.slice(0, -1)))}&populate[address]=*&populate[profile_pic]=*`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + user?.jwt
            },
        };
        const request = apiRequestService(config);
        return request;
    } catch (error) {
        console.log(error);
    }
}

export async function deleteUserAccount(data: objectType) {
    const user = await getUserData();
    let config = {
        method: 'post',
        url: `${API_URL}/v1/users/delete-account/${user?.user?.id}`,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + user?.jwt
        },
        data: data
    };
    const request = apiRequestService(config);
    return request;
}

export async function getGoogleUserToken(data: objectType) {
    let config = {
        method: 'post',
        url: `https://oauth2.googleapis.com/token`,
        data: data
    };
    const request = apiRequestService(config);
    return request;
}

export async function getGoogleUserData(token: string) {
    let config = {
        method: 'get',
        url: `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${token}`,
    };
    const request = apiRequestService(config);
    return request;
}

export async function getGoogleUserDetails(token: string) {
    let config = {
        method: 'get',
        url: `https://people.googleapis.com/v1/people/me?personFields=addresses,phoneNumbers,locations,calendarUrls,emailAddresses`,
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };
    const request = apiRequestService(config);
    return request;
}
export async function getGoogleUserCalendars(token: string) {
    let config = {
        method: 'get',
        url: `https://content.googleapis.com/calendar/v3/users/me/calendarList?key=${process.env.REACT_APP_GOOGLE_LOGIN_API_KEY}`,
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };
    const request = apiRequestService(config);
    return request;
}

export async function getGoogleUserContacts(token: string) {
    let config = {
        method: 'get',
        url: `https://content-people.googleapis.com/v1/people/me/connections?personFields=phoneNumbers,names,addresses,emailAddresses,photos,metadata,coverPhotos&sortOrder=LAST_MODIFIED_DESCENDING&key=${process.env.REACT_APP_GOOGLE_LOGIN_API_KEY}`,
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };
    const request = apiRequestService(config);
    return request;
}

export async function getFacebookUserLocations(token: string) {
    let config = {
        method: 'get',
        url: `https://graph.facebook.com/v13.0/me?fields=location&access_token=${token}`,
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };
    const request = apiRequestService(config);
    return request;
}

export async function getFacebookUserData(token: string) {
    let config = {
        method: 'get',
        url: `https://graph.facebook.com/v13.0/me?fields=id,name,email,picture,gender,location`,
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };
    const request = apiRequestService(config);
    return request;
}

export async function getFacebookUserImage(token: string, user_id: string) {
    let config = {
        method: 'get',
        url: `https://graph.facebook.com/${user_id}/picture?type=large&redirect=false&access_token=${token}`,
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };
    const request = apiRequestService(config);
    return request;
}


export async function getGoogleToken(provider: string, data: objectType) {
    let config = {
        method: 'post',
        headers: {
            'Content-Type': 'application/json'
        },
        url: `${API_URL}/v1/auth/google-login/${provider}`,
        data: data
    };
    const request = apiRequestService(config);
    return request;
}