import { faCheck } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useRef } from "react";
import styled from "styled-components";
import { ButtonGreen, ButtonInitial, SubText1, moveTopBottom } from "../../styled/styled";
import Section from "../section/Section";
import useDarkMode from "../../hooks/darkmode/DarkMode";

const ModalDiv = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1000;
  overflow-y: auto;
`;

const ContentDiv = styled.div<{ isDarkMode: boolean }>`
    background-color: ${props => (props?.isDarkMode ? '#2e2e2e' : '#fff')};
    align-self: center;
    padding: 10px;
    z-index: 1000;
    width: 600px;
    max-width: 90%;
    border-radius: 20px;
    text-align: center;
    height: fit-content;
    animation: ${moveTopBottom} .4s ease-in-out;
`;

const DoneModalIcon = styled.div`
    margin-left: auto;
    margin-right: auto;
    margin-top: -50px;
    width: 65px;
    height: 65px;
    padding: 20px;
    color: white;
    border-radius: 40px;
    background: #22a45d;
`;

const TextMeal = styled.h3`
  font-weight: 600;
  font-size: 24px;
`;

export default function ModalSuccess(props: any) {
  const modalRef = useRef();
  const isDarkMode = useDarkMode();

  const closeModal = (e: any) => {
    if (modalRef.current === e.target) {
      props?.setShowModal(false);
    }
  };

  return (
    <>
      {props?.showModal ? (
        <ModalDiv ref={modalRef} onClick={closeModal}>
          <ContentDiv style={props?.contentDivStyle} isDarkMode={isDarkMode}>
            <DoneModalIcon>
              <FontAwesomeIcon icon={faCheck} className="text-2xl" />
            </DoneModalIcon>
            <TextMeal>{props?.firstText}</TextMeal>
            <Section align="center">
              <SubText1>{props?.secondText}</SubText1>
            </Section>
            <div>
              <ButtonGreen style={props?.btnOneStyle} onClick={props?.btnOneGoto}>
                {props?.btnOneText}
              </ButtonGreen>
            </div>
            <div>
              <ButtonInitial
                onClick={props?.btnTwoGoto}
                style={props?.btnTwoStyle}
              >
                {props?.btnTwoText}
              </ButtonInitial>
            </div>
          </ContentDiv>
        </ModalDiv>
      ) : null}
    </>
  );
}
