import Section from "../../components/widgets/section/Section";
import Divider from "../../components/widgets/divider/Divider";
import { Astk, ButtonGreen, InitialDiv, InputLabel, SubText1, TextBox1, TextHead1, TextHead3, TextHead4 } from "../../components/styled/styled";
import TopHead from "../../components/widgets/head/TopHead";
import { useHistory, useLocation } from "react-router";
import { countryCodeFilter, handleNetworkError, handleRequestError, handleResponse, removeMask, switchSentenceCase, trackPixelEvent, validateEmail, validatePhone } from "../../utils/utilities";
import { faAngleLeft, faChevronLeft, faSearch } from "@fortawesome/pro-solid-svg-icons";
import { useDispatch } from "react-redux";
import { createScheduleForMeStep1, createScheduleForMeClear, calendarRecipientData } from "./redux/createScheduleReduxActions";
import { addNewRecipient, getCalendarData, setCalendarData } from "../../services/schedule";
import { getUserData, getUserInfo, setUserData, updateUser } from "../../services/user";
import React, { useEffect, useState } from "react";
import useDarkMode from "../../components/hooks/darkmode/DarkMode";
import { findFriends, inviteFriend } from "../../services/friends";
import no_user_pic from "../../components/assets/profile/no-profile-photo.png";
import InitialModalCenter from "../../components/widgets/modals/InitialModalCenter";
import { Flex, Select } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PhoneInput from "react-phone-number-input";
import AddressesCart from "../../components/widgets/address/AdressesCart";
import { useIonAlert } from "@ionic/react";
import TabsForRecipientDetails from "../../components/widgets/toggleable-tabs/TabsForRecipientDetails";
import { objectType } from "../../type";



export default function Schedules(data: any) {

  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation<any>();
  const user = getUserInfo();
  const isDarkMode = useDarkMode();
  const API_URL = process.env.REACT_APP_API_URL;
  const [presentAlert] = useIonAlert();
  const showAlert = (header: string, message: string, btnMessage: string) => {
    presentAlert({
      header: header,
      message: message,
      buttons: [btnMessage],
    });
  };

  const GotoHandle = () => {
    history.push({
      pathname: '/home',
    })
  };


  useEffect(() => {
    if (location?.state?.recipientEmail) {
      setRecipientEmail(location.state.recipientEmail);
      setDisabledEmail(true);
    }
  }, [location]);

  // ********************** Hooks ************************************
  const [showRecipientModal, setShowRecipientModal] = useState((location?.state) ? true : false);
  const [awaiting, setAwaiting] = useState(false);
  const [respondedData, setRespondedData] = useState<any>({});
  const [recipientEmail, setRecipientEmail] = useState("");
  const [values, setValues] = useState({
    recipientName: (location?.state?.recipientName !== undefined ? location?.state?.recipientName : ""),
    recipientContact: (location?.state?.recipientContact !== undefined ? location?.state?.recipientContact : ""),
    recipientId: (location?.state?.recipientId !== undefined ? location?.state?.recipientId : ""),
    addressId: (location?.state?.addressId !== undefined ? location?.state?.addressId : ""),
  });

  const [userDatavalues, setUserDatavalues] = useState({
    username: "",
    useremail: "",
    userphonenumber: "",
  });

  const [searchedFriendText, setSearchedFriendText] = useState("");
  const [friendsData, setFriendsData] = useState([]);

  // ********************Error Handler****************************
  const [addressIdError, setAddressIdError] = useState(false);
  const [recipientContactError, setRecipientContactError] = useState(false);
  const [recipientEmailError, setRecipientEmailError] = useState(false);
  const [recipientEmailErrorMsg, setRecipientEmailErrorMsg] = useState("");
  const [userResponseError, setUserResponseError] = useState(false);
  const [recipientNameError, setRecipientNameError] = useState(false);
  const [userNameError, setUserNameError] = useState(false);
  const [userEmailError, setUserEmailError] = useState(false);
  const [userPhoneError, setUserPhoneError] = useState(false);
  const [disabledEmail, setDisabledEmail] = useState(false);

  const userDetails = [
    {
      name: "Details",
      value: "details",
      disabled: false,
      active: respondedData?.id ? true : false
    },
    {
      name: "Address",
      value: "address",
      disabled: respondedData?.id ? false : true,
      active: values?.addressId ? true : false
    }
  ];

  const [activeBar, setActiveBar] = useState(userDetails[0].value);

  const handleGetUserFriends = async (e: string) => { // Finding friend lists from api On search.... || calling api when length > 3     
    setSearchedFriendText(e);
    setValues((prevValues) => {
      return {
        ...prevValues,
        recipientName: "",
        recipientContact: "",
        recipientId: ""
      };
    });
    if (e?.length >= 3) {
      setAwaiting(true);
      setFriendsData([]);
      try {
        const { data } = await findFriends(e);
        trackPixelEvent('User find');
        setFriendsData(data?.data);
        setAwaiting(false);
      } catch (error) {
        setAwaiting(false);
        if (error.response) {
          handleResponse(error.response); // Server responded with a non-2xx status code
        } else if (error.request) {
          handleRequestError(error.request);  // The request was made but no response was received
        } else {
          handleNetworkError(error); // Network error or something else happened
        }
      }
    } else {
      setFriendsData([]);
    }
  };

  // ************************* Functions *************************************
  const handleAddressId = (id: any) => {
    setAddressIdError(false);
    setValues((prevValues) => {
      return {
        ...prevValues,
        addressId: id,
      };
    });
  };

  const handleGetData = async () => {
    const { recipientName, recipientContact } = values;
    if (
      removeMask(recipientContact) !== "" &&
      !recipientContactError &&
      recipientName !== "" &&
      !recipientNameError
    ) {
      setRecipientEmailError(false);
      const validPhoneNo = validatePhone(removeMask(recipientContact));
      if (!validPhoneNo || recipientContactError || recipientNameError) {
        return false;
      }
      setAwaiting(true);
      try {
        const payloadData = {
          recipientName: recipientName,
          recipient: removeMask(countryCodeFilter(recipientContact)),
          recipientEmail: recipientEmail
        };

        const response = await addNewRecipient(payloadData);
        if (response) {
          trackPixelEvent("Add new recipient");
          setRespondedData(response?.data?.data);
          if (response?.data?.data?.email !== null && response?.data?.data?.email !== "") {
            setRecipientEmail(response?.data?.data?.email);
          }
          setValues((prevValues) => {
            return {
              ...prevValues,
              recipientId: response?.data?.data?.id,
            };
          });
          setAddressIdError(false);
          setAwaiting(false);
          return true;
        } else {
          setAddressIdError(true);
        }
      } catch (e) {
        setAwaiting(false);
        setRespondedData({});
        setRecipientEmail("")
        setValues((prevValues) => {
          return {
            ...prevValues,
            recipientId: "",
            addressId: ""
          };
        });
        return showAlert(e?.response?.data?.error?.name ? e?.response?.data?.error?.name : e?.response?.status, e?.response?.data?.error?.message ? e?.response?.data?.error?.message : e?.response?.statusText, "OK");
      }
    }
  };

  const handleChangePhoneNumber = (value: string) => {
    if (value === "" || value === undefined) {
      setRecipientContactError(true);
    } else {
      setRecipientContactError(false);
    }
    setValues((prevValues) => {
      return {
        ...prevValues,
        recipientContact: value,
      };
    });
  };

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setValues((prevValues) => {
      return {
        ...prevValues,
        [name]: value,
      };
    });

    if (name === "recipientName") {
      if (value.trim() === "" || value.length < 3) {
        setRecipientNameError(true);
      } else {
        setRecipientNameError(false);
      }
    }
  };

  const handleUserPhoneChange = (value: string) => {
    setUserResponseError(false);
    if (value === "" || value === undefined) {
      setUserPhoneError(true);
    } else {
      setUserPhoneError(false);
    }
    setUserDatavalues((prevValues) => {
      return {
        ...prevValues,
        userphonenumber: value,
      };
    });
  };

  const handleUserChange = (e: any) => {
    const { name, value } = e.target;
    setUserDatavalues((prevValues) => {
      return {
        ...prevValues,
        [name]: value,
      };
    });
    setUserResponseError(false);
    if (name === "username") {
      if (value.trim() === "" || value.length < 3) {
        setUserNameError(true);
      } else {
        setUserNameError(false);
      }
    }

    if (name === "useremail") {
      const validEmail = validateEmail(value);
      if (!validEmail) {
        setUserEmailError(true);
      } else {
        setUserEmailError(false);
      }
    }
  };

  const handleEmailChange = (e: any) => {
    setRecipientEmailErrorMsg("");
    setRecipientEmailError(false);
    setRecipientEmail(e?.target?.value);

    const validEmail = validateEmail(e?.target?.value);
    if (String(e?.target?.value).trim()?.length === 0 || !validEmail) {
      setRecipientEmailError(true);
    } else {
      setRecipientEmailError(false);
    }
  };

  const handleUpdateData = async () => {
    setAwaiting(true);
    setRecipientEmailErrorMsg("");
    if (recipientEmail !== "" && !recipientEmailError) {
      const validEmail = validateEmail(recipientEmail);
      if (!validEmail || recipientEmailError) {
        return false;
      }
      try {
        const data = {
          email: recipientEmail.toLowerCase(),
        };
        const res = await updateUser(data, respondedData?.id);
        if (res?.status === 200) {
          trackPixelEvent("Update user");
          setDisabledEmail(true);
        }
      } catch (error) {
        setRecipientEmailErrorMsg(error?.response?.data?.error?.message);
        setRecipientEmailError(true);
        setAwaiting(false);
      }
    }
  };

  const handleSubmit = async () => {
    const {
      recipientName,
      recipientContact,
      addressId,
      recipientId,
    } = values;
    const { username, useremail, userphonenumber } = userDatavalues;

    // *********************** validation Email and phone *************************************
    const validEmail = validateEmail(recipientEmail);
    const validPhoneNo = validatePhone(removeMask(recipientContact));
    const userValidEmail = validateEmail(useremail);
    const userValidPhoneNo = validatePhone(removeMask(userphonenumber));
    let recipientContactErr = false;

    if (activeBar === "details") {

      if (!validPhoneNo) {
        setRecipientContactError(true);
        recipientContactErr = true;
      }
      else {
        setRecipientContactError(false);
        recipientContactErr = false;
      }

      // *********************** validation recipient name*************************************
      if (recipientName === "") {
        setRecipientNameError(true);
        return false;
      } else {
        setRecipientNameError(false);
      }

      // *********************** validation user name, email, phone*************************************
      if (user?.name === null && (username === "" || username?.length < 3)) {
        setUserNameError(true);
        return false;
      } else {
        setUserNameError(false);
      }

      if (user?.email === null && (!userValidEmail || userResponseError)) {
        setUserEmailError(true);
        return false;
      } else {
        setUserEmailError(false);
      }

      if (user?.phone_no === null && (!userValidPhoneNo || userResponseError)) {
        setUserPhoneError(true);
        return false;
      } else {
        setUserPhoneError(false);
      }

      if (recipientContactErr || recipientNameError || recipientContactError || userNameError || userEmailError || userPhoneError) return false;

      if ((user?.email === null || user?.name === null || user?.phone_no === null)) {
        // *********************** Calling API to update user name, email, phone, if not************************************
        try {
          const data = {
            email: user?.email === null ? useremail.toLowerCase() : user?.email,
            name: user?.name === null ? username : user?.name,
            phone_no: user?.phone_no === null ? removeMask(countryCodeFilter(userphonenumber)) : user?.phone_no,
          };
          const localData = await getUserData();
          await updateUser(data, user?.id).then(async (response: any) => {
            let data = response?.data;
            if (response.status === 200) {
              trackPixelEvent("Update user");
              const newUpdatedUserInfo = {
                ...localData,
                user: {
                  ...localData.user,
                  name: data?.name,
                  email: data?.email,
                  phone_no: data?.phone_no,
                },
              };
              await setUserData(newUpdatedUserInfo);
            }
          });
        } catch (error) {
          setUserResponseError(true);
          setUserPhoneError(true);
          setUserEmailError(true);
          return showAlert("Error", error?.response?.data?.error?.message, "OK");
        }
      }
      let response = await handleGetData();
      if (response === true) {
        setActiveBar("address");
      }
    }
    else {
      if (respondedData?.email === null) {
        if (!validEmail || recipientEmailError) {
          setRecipientEmailError(true);
          return false;
        } else if (validEmail) {
          setRecipientEmailError(false);
        }
      }
      if (recipientEmailErrorMsg || recipientEmailError || addressIdError) return false;
      if (values?.recipientId) {
        const payloadData = {
          data: {
            "from_id": user?.id,
            "to_id": recipientId,
            "status": "PENDING",
            "message": 'Hey! Add me as a friend so I can see your faves and buy you food on special occasions!'
          }
        }
        await inviteFriend(payloadData).then((response: any) => {
          if (response?.statusText === "OK" || response?.status === 200) {
            trackPixelEvent('Invite Friend');
          }
        });
      }
      // ************************validation Address ID**************************************
      if (addressId === "" || addressId === undefined) {
        setAddressIdError(true);
        return false;
      } else {
        setAddressIdError(false);
      }

      // **************Data Object for state********************
      let data = {
        recipientName: recipientName,
        recipientContact: removeMask(countryCodeFilter(recipientContact)),
        recipientEmail: recipientEmail,
        recipientId: recipientId,
        addressId: addressId,
      };
      calendarRecipientData(data);
      let calendarData = await getCalendarData();
      const newUpdatedCalendarData = {
        ...calendarData,
        recipientData: data
      };
      await setCalendarData(newUpdatedCalendarData)
      trackPixelEvent('Calendar for someone else');
      return history.push({ pathname: '/create-calendar' })
    }
  };

  const handleOnClickForMe = async () => {
    dispatch(createScheduleForMeClear() as any);
    dispatch(createScheduleForMeStep1("for_me") as any);
    trackPixelEvent('Calendar for me');
    data = {
      step1: "for_me"
    }
    await setCalendarData(data);
    return history.push({
      pathname: '/create-calendar',
    })
  }

  const handleOnClickForOther = async () => {
    dispatch(createScheduleForMeClear() as any);
    dispatch(createScheduleForMeStep1("for_other") as any);
    let data = {
      step1: "for_other"
    }
    await setCalendarData(data);
    setShowRecipientModal((prev) => !prev);
  }

  let options = [];
  options = friendsData?.map((item: any, index: any) => (
    {
      id: item?.id,
      name: item?.name,
      phone_no: item?.phone_no,
      email: item?.email,
      value: (item?.name !== null ? item?.name + index : (item?.email !== null ? item?.email : item?.phone_no)),
      label: (
        <React.Fragment key={index}>
          <div className="flex">
            <img
              alt={item?.name + index}
              src={item?.profile_pic !== null ? API_URL + item?.profile_pic?.url : no_user_pic}
              className="mr-6 mt-1 rounded-[20px] border border-solid border-gray-500 w-8 h-8" />
            <div>
              <h3>{item?.name !== null ? item?.name : "-----"}</h3>
              <SubText1>{item?.city !== null ? item?.city : "-----"}</SubText1>
            </div>
          </div>
        </React.Fragment>
      ),
    }))

  const onSelect = (val: any, option: objectType) => {
    setRespondedData({});
    setRecipientContactError(false);
    setRecipientNameError(false);
    setValues((prevValues) => {
      return {
        ...prevValues,
        recipientName: option?.name,
        recipientContact: option?.phone_no,
        recipientId: option?.id
      };
    });
    if (option?.email) {
      setRecipientEmail(option?.email);
    }
    setFriendsData([]);
  };

  return (
    <>
      <div className="screen">
        <TopHead
          title="Meal Calendars"
          leftTextIcon={faAngleLeft}
          leftIconStyle={{ fontSize: "24px" }}
          goToHandle={GotoHandle}
        />
        <Divider />

        <div className="grow overflow-auto">
          <Section>
            <TextHead1>Who is the food for?</TextHead1>
          </Section>

          <Section>
            <ButtonGreen
              onClick={() => handleOnClickForMe()}
            >{switchSentenceCase("primary button", "Food For Me")}</ButtonGreen>

          </Section>

          <Section>
            <ButtonGreen onClick={() => handleOnClickForOther()}>
              {switchSentenceCase("primary button", "FOOD FOR SOMEONE ELSE")}</ButtonGreen>
          </Section>

          {/* <Section>
          <ButtonGreen onClick={() => {
            history.push({
              pathname: '/mealformultipeople',
            })
          }}>{switchSentenceCase("primary button", "FOOD FOR MULTIPLE PEOPLE")}</ButtonGreen>

        </Section> */}

          {/* ********************************** Show Recipient's Modal********************************************** */}
          {showRecipientModal && (
            <InitialModalCenter
              showModal={showRecipientModal}
              setShowModal={setShowRecipientModal}
            >
              <InitialDiv className="m-4">
                <Flex className="items-center">
                  <FontAwesomeIcon
                    className="cursor-pointer"
                    icon={faChevronLeft}
                    onClick={() => setShowRecipientModal((prev: any) => !prev)}
                  />
                  <InitialDiv className="w-full text-center">
                    <TextHead3 className="">Add Recipient Details</TextHead3>
                  </InitialDiv>
                </Flex>
              </InitialDiv>
              <Divider />
              <Section>

                <InitialDiv className="m-4">
                  <TabsForRecipientDetails
                    types={userDetails}
                    setActive={setActiveBar}
                    selected={activeBar}
                  />

                  {(activeBar === "details") &&
                    <>
                      {/* ********************* Search Friends Section ******************************* */}
                      <InitialDiv>
                        <TextHead3>
                          Search for Friend
                        </TextHead3>
                        <FontAwesomeIcon icon={faSearch} className="relative top-[32px] left-[10px] z-10" />
                        <Select
                          allowClear
                          showSearch
                          size="large"
                          bordered={false}
                          filterOption={false}
                          options={options}
                          loading={awaiting}
                          placeholder={"Search by name, phone, or email"}
                          onSearch={(e) => handleGetUserFriends(e)}
                          onSelect={(val, option) => onSelect(val, option)}
                          style={{ width: "100%", paddingLeft: "22px", border: "1px solid #7c7c7c69", borderRadius: "10px", marginBottom: "20px", background: `${isDarkMode ? "#3b3b3bb3" : "#FBFBFB"}` }}
                          notFoundContent={
                            !awaiting && searchedFriendText?.length > 2
                            &&
                            <SubText1 className="m-2 !mb-[2px]">No Result Found</SubText1>
                          }
                        />
                      </InitialDiv>
                      <SubText1 className="!font-bold text-center !text-green-600">OR</SubText1>

                      <TextHead3>
                        Add a Friend
                      </TextHead3>
                      <InitialDiv className="text-left">
                        <TextHead4 id="recipientname" className="mb-[-15px] mt-4">
                          Recipient Name<Astk> *</Astk>
                        </TextHead4>
                        <TextBox1
                          type="text"
                          placeholder="Enter Recipient Name"
                          name="recipientName"
                          style={{ border: recipientNameError ? "1px solid red" : "" }}
                          //value={respondedData?.name ? respondedData?.name : values?.recipientName}
                          value={values?.recipientName}
                          onChange={handleChange}
                        // readOnly={respondedData?.name ? true : false}
                        />
                      </InitialDiv>

                      <InitialDiv className="text-left">
                        <TextHead4 id="recipientcontact" className="mb-[-15px] mt-4">
                          Recipient Phone Number<Astk> *</Astk>
                        </TextHead4>
                        <div className="relative mb-4 flex mt-6">
                          <PhoneInput
                            className={isDarkMode ? "country-selector" : ""}
                            international
                            countryCallingCodeEditable={false}
                            defaultCountry="US"
                            limitMaxLength={true}
                            placeholder="Enter Recipient Phone Number"
                            name="recipientContact"
                            value={values?.recipientContact}
                            onChange={handleChangePhoneNumber}
                            style={{
                              borderRadius: "8px",
                              width: "100%",
                              border: recipientContactError ? "1px solid red" : "",
                            }}
                          />
                          {/* <button
                        className={
                          recipientContactError ||
                            recipientNameError ||
                            values?.recipientContact === "" ||
                            values?.recipientName === ""
                            ? "z-[2] inline-block rounded-r-lg bg-[#e1e1e1] px-6 pb-2 pt-2.5 text-xs text-white !cursor-not-allowed border border-solid-[1px] border-[#c0c0c0]"
                            : "z-[2] inline-block rounded-r-lg bg-[#22A45D] px-6 pb-2 pt-2.5 text-xs text-white cursor-pointer border border-solid-[1px] border-[#22A45D]"
                        }
                        disabled={
                          recipientContactError ||
                          recipientNameError ||
                          values?.recipientContact === "" ||
                          values?.recipientName === ""
                        }
                        onClick={handleGetData}
                      >
                        <FontAwesomeIcon icon={faSearch} />
                      </button> */}
                        </div>
                      </InitialDiv>

                      {user?.name === null && (
                        <InitialDiv className="text-left">
                          <TextHead4 id="username" className="mb-[-15px] mt-4">
                            Your Name<Astk> *</Astk>
                          </TextHead4>
                          <TextBox1
                            type="text"
                            placeholder="Enter Your Name"
                            name="username"
                            style={{
                              border: userNameError ? "1px solid red" : "",
                            }}
                            value={userDatavalues?.username}
                            onChange={handleUserChange}
                          />
                        </InitialDiv>
                      )}

                      {user?.email === null && (
                        <InitialDiv className="text-left">
                          <TextHead4 id="useremail" className="mb-[-15px] mt-4">
                            Your Email<Astk> *</Astk>
                          </TextHead4>
                          <TextBox1
                            type="text"
                            placeholder="Enter Your Email Address"
                            name="useremail"
                            style={{
                              border: userEmailError ? "1px solid red" : "",
                            }}
                            value={userDatavalues?.useremail}
                            onChange={handleUserChange}
                          />
                        </InitialDiv>
                      )}

                      {user?.phone_no === null && (
                        <InitialDiv className="text-left">
                          <TextHead4 id="userphonenumber" className="mb-12 mt-4">
                            Your Phone Number<Astk> *</Astk>
                          </TextHead4>
                          <PhoneInput
                            className={isDarkMode ? "country-selector" : ""}
                            international
                            countryCallingCodeEditable={false}
                            defaultCountry="US"
                            limitMaxLength={true}
                            placeholder="Enter Your Phone Number"
                            name="userphonenumber"
                            value={userDatavalues?.userphonenumber}
                            onChange={handleUserPhoneChange}
                            style={{
                              border: userPhoneError ? "1px solid red" : "",
                            }}
                          />
                        </InitialDiv>
                      )}
                    </>}
                </InitialDiv>

                {(activeBar === "address") &&
                  <InitialDiv className="text-left m-4">
                    {(respondedData?.email === null || respondedData?.email === "") && (
                      <>
                        <TextHead4
                          id="recipientcontact"
                          className="mb-[-15px] mt-4"
                        >
                          Recipient Email<Astk> *</Astk>
                        </TextHead4>
                        <TextBox1
                          placeholder="Enter Recipient Email"
                          name="recipientContact"
                          onBlur={handleUpdateData}
                          onChange={handleEmailChange}
                          style={{ border: recipientEmailError ? "1px solid red" : "" }}
                          value={recipientEmail}
                          readOnly={disabledEmail ? true : false}
                        />
                        <SubText1 className="!text-red-600 mt-[-10px] mb-[10px]">
                          {recipientEmailErrorMsg}
                        </SubText1>
                      </>)}


                    {/*----------------------------------- Address Component Section  ------------------------*/}
                    <InitialDiv className="text-left mb-8">
                      <TextHead4>
                        Delivery Address<Astk> *</Astk>
                      </TextHead4>
                      <AddressesCart
                        title=""
                        addressId={handleAddressId}
                        showDeleteBtn={false}
                        showList={false}
                        pageName="odersHome"
                        pageDataId=""
                        changeAddressTxtBtn="CHANGE ADDRESS"
                        userId={values?.recipientId}
                        setDeliveryAddress={true}
                        changeAddress={true}
                        initialLength={1}
                      />
                      {addressIdError && (
                        <InputLabel className="!text-red-600 !text-center mt-2">
                          Address is required.
                        </InputLabel>
                      )}
                    </InitialDiv>
                  </InitialDiv>
                }

                <InitialDiv className="m-4">
                  <ButtonGreen onClick={handleSubmit}>
                    {switchSentenceCase("head text", "NEXT")}
                  </ButtonGreen>
                </InitialDiv>
              </Section>
            </InitialModalCenter>
          )}
          <Divider />
        </div>
      </div >
    </>
  );
}


