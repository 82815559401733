import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/pro-solid-svg-icons";
import { useState, useRef } from "react";
import { IonContent, IonPopover } from '@ionic/react';


export default function ToolTip(props: any) {

    const popover = useRef<HTMLIonPopoverElement>(null);
    const [popoverOpen, setPopoverOpen] = useState(false);

    const openPopover = (e: any) => {
        popover.current!.event = e;
        setPopoverOpen(true);
    };

    return (
        <>
            <FontAwesomeIcon icon={faInfoCircle} onClick={openPopover} className="ml-1 mt-1 text-[12px] text-[#a4a4a4] cursor-pointer" />
            <IonPopover show-backdrop="false" arrow={true} ref={popover} isOpen={popoverOpen} mode={"ios"} onDidDismiss={() => setPopoverOpen(false)}>
                <IonContent class="ion-padding">{props?.instructions}</IonContent>
            </IonPopover>
        </>
    );
};


