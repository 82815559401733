import styled from 'styled-components';
import { Flex, FlexBetween, SubText1 } from "../../styled/styled";
import defaultRestaurant from "../../assets/restaurent-Meal-3.jpg"
import { Rate } from 'antd';
import { switchSentenceCase } from '../../../utils/utilities';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { removeOrderMealItem, setSearchedRestaurantData } from '../../../pages/orderFood/redux/orderFoodActionCreator';
import { getSearchedRestaurantsData } from '../../../pages/search/redux/searchActionCreator';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode, A11y, Scrollbar, Navigation, Pagination } from 'swiper';
import 'swiper/css';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight } from '@fortawesome/pro-solid-svg-icons';
import { setSelectedRestaurantData } from '../../../services/restaurant';
import { removeFavsMealItem } from '../../../pages/restaurant/redux/menuItemActionCreatior';

const PageWrapper = styled.div`
  width: 100%;
  min-width: 343px;
`;

const BodyWrapper = styled.div`
display:flex;
overflow-x: scroll;
::-webkit-scrollbar {
  display: none;
}
`;

const Block = styled.div`
height: 245px;
display: flex;
flex-direction: column;
justify-content: space-around;
margin: 0px 7px;
`;

const MealName = styled.h3`
overflow: hidden;
text-overflow: ellipsis;
display: -webkit-box;
-webkit-line-clamp: 1;
-webkit-box-orient: vertical;
`;

const ButtonArrowLeft = styled.button`
    border-radius: 38px;
    font-size: 22px;
    position: relative;
    bottom: 40px;
    z-index: 10;
    color: #22ae62;
`;

const ButtonArrowRight = styled.button`
    border-radius: 38px;
    font-size: 22px;
    margin-left: 5px;
    position: relative;
    bottom: 48px;
    z-index: 10;
    color: #22ae62;
`;

export default function RestaurantCardGrid(props: any) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [swipe, setSwipe] = useState<any>();

  const onRestaurantClick = async (r: any) => {
    const restaurantSendData = {
      id: r._id,
      name: r.name,
      logoPhoto: r.logo_photos[0],
      description: r.description,
      rating: r.weighted_rating_value,
      ratingCount: r.aggregated_rating_count,
      quoteIds: r.quote_ids,
      is_open: r.is_open,
      dollar_signs: r.dollar_signs,
      local_hours: r.local_hours
    }
    if (props?.page === "for-order") {
      dispatch(setSearchedRestaurantData(restaurantSendData) as any);
      await setSelectedRestaurantData(restaurantSendData);
      dispatch(removeOrderMealItem(restaurantSendData?.id) as any);
      history.push(`/restaurantDetail/${restaurantSendData?.id}?type=${props?.type}`);
    }
    else if (props?.page === "for-fave") {
      dispatch(getSearchedRestaurantsData(restaurantSendData) as any);
      await setSelectedRestaurantData(restaurantSendData);
      dispatch(removeFavsMealItem(restaurantSendData?.id) as any);
      history.push(`/restaurant/${restaurantSendData?.id}?type=${props?.type}`);
    }
  };


  return (
    <>
      <PageWrapper >
        <BodyWrapper className='restaurant-swiper-cards'>
          <ButtonArrowLeft onClick={() => swipe?.slidePrev()}><FontAwesomeIcon icon={faAngleLeft} /></ButtonArrowLeft>
          <Swiper
            onBeforeInit={(swipper) => setSwipe(swipper)}
            modules={[FreeMode, A11y, Navigation, Pagination, Scrollbar]}
            slidesPerView={"auto"}
            centeredSlides={false}
            freeMode={true}
            grabCursor={true}
          // navigation={true}
          >
            {props?.data?.map((Restaurant: any, index: any) => {
              return (
                <SwiperSlide key={index}>
                  <Block className="w-max cursor-pointer" key={index} onClick={() => onRestaurantClick(Restaurant)}>
                    <img
                      alt="Restaurant Logo"
                      src={Restaurant?.logo_photos?.length ? Restaurant?.logo_photos[0] : defaultRestaurant}
                      className="mr-6 rounded-lg h-36 w-52 object-cover"
                      // onClick={() => onRestaurantClick(Restaurant)}
                      style={{ cursor: "pointer" }}
                    />
                    <div className="flex-content" style={{ width: "200px" }}>
                      <FlexBetween>
                        <MealName >{switchSentenceCase("head text", Restaurant?.name)}</MealName>
                        <SubText1 >{Restaurant.is_open ? <span className="text-green-600">[Open]</span> : <span className="text-red-600">[Closed]</span>}</SubText1>

                        {/* <SubText1 className={`flex-fav text-sm ${Restaurant?.is_open ? "!text-green-600" : "!text-red-600"}`}>{Restaurant?.is_open ? "Open" : "Closed"}</SubText1> */}
                      </FlexBetween>
                      <SubText1 className="flex-fav text-sm text-gray-700 my-1">{Restaurant?.address?.street_addr}</SubText1>
                      <FlexBetween className="flex-fav text-sm text-gray-700">
                        <Flex className="items-baseline">
                          <Rate disabled allowHalf defaultValue={Restaurant.weighted_rating_value} style={{ color: "#c1a000", fontSize: "16px" }} />
                          <SubText1 className="flex-fav text-sm text-gray-700">({Restaurant?.aggregated_rating_count || 0})</SubText1>
                        </Flex>
                        <div className="grow text-right text-asprimary">
                          {Restaurant.miles?.toFixed(1)} mi
                        </div>
                        {/* <Pills className="!p-0.5 !w-9 text-center">{Restaurant?.weighted_rating_value}</Pills>
                    <div>{Restaurant.deliveryTime}</div><DotSeprator />
                    <div>{Restaurant.deliveryType}</div> */}
                      </FlexBetween>
                    </div>
                  </Block >
                </SwiperSlide>
              )
            })}
          </Swiper>
          <ButtonArrowRight onClick={() => swipe?.slideNext()}><FontAwesomeIcon icon={faAngleRight} /></ButtonArrowRight>
        </BodyWrapper>
      </PageWrapper>
    </>
  );
}
