import styled from 'styled-components';
import { switchSentenceCase } from '../../../utils/utilities';

const Tab = styled.div`
  overflow: hidden;
  border: none;
  display: inline-block;
  justify-content: space-around;
  margin: 20px 0px;
`;

const TabLinks = styled.button<{ active?: boolean }>`
background-color: inherit;
  float: left;
  border: 1px solid #dbdbdb;
  color: #949494;
  border-radius: 10px;
  margin: 5px;
  outline: none;
  cursor: pointer;
  padding: 10px 16px;
  transition: 0.3s;
  font-size: 15px;
  font-weight: 500;
  width: auto;

  &:hover {
    background-color: #ddd;
  }

  ${({ active }) =>
    active &&
    ` background: #22A45D;
     color: white;
     border-radius: 10px;
     width: auto;
     
     &:hover {
       background-color: #22A45D;
     }
  `}
`;

export default function TabsForMultiple(props: any) {
  const toggleValue = (type: any) => {
    const selectedTypes = props.selected || [];
    if (selectedTypes.includes(type.value)) {
      // Type is already selected, so remove it
      props.setSelected(selectedTypes.filter((t: any) => t !== type.value));
    } else {
      // Type is not selected, so add it
      props.setSelected([...selectedTypes, type.value]);
    }
  };

  const isTypeSelected = (type: any) => {
    const selectedTypes = props.selected || [];
    return selectedTypes.includes(type.value);
  };

  return (
    <Tab>
      {props.types.map((type: any, index: any) => (
        <TabLinks
          key={index}
          active={isTypeSelected(type)}
          onClick={() => toggleValue(type)}
        >
          {switchSentenceCase("head text", type.name)}
          {/* {type.name} */}
        </TabLinks>
      ))}
    </Tab>
  );
}
