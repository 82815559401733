import React, { useEffect, useRef, useState } from "react";
import { ButtonGreen, Flex, FlexBetween, InitialDiv, InputText, ItemCount, SubText1, SubText2, TextArea1, TextHead1, TextHead3, TextHead4, TextHead5, moveTopBottom, } from "../../styled/styled";
import styled from "styled-components";
import { existingOrderItemsFormatter, fullDateToShortConvert, getTimeDifference, increasePriceByQuantity, isRestaurantOpen, maxCharacterLimit, priceFormatterUsd, properNameFormate, restaurantOpenAndClosingTime, sumOfMealInCart, switchSentenceCase, maskCurrencyInput, trackPixelEvent, finalizeItemPriceCalculate } from "../../../utils/utilities";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarPen, faChevronLeft, faCircleInfo, faClockThree, faStore } from "@fortawesome/pro-solid-svg-icons";
import { faHeart } from "@fortawesome/pro-regular-svg-icons";
import { getUserInfo } from "../../../services/user";
import AddressesCart from "../address/AdressesCart";
import PaymentCart from "../paymentscomponent/PaymentCart";
import Accordion from "../accordian/AccordionStyled";
import { previewOrder } from "../../../services/orderFood";
import ToolTip from "../alerts/tooltip";
import Spinner from "../spinner/Spinner";
import IonModalAlert from "../alerts/IonModalAlert";
import AccordionCheckoutCustomization from "../accordian/AccordionCheckoutCustomization";
// import CheckoutProductCustomization from "../../../pages/orderFood/CheckoutProductCustomization";
import QuantitySelectorGlobal from "../buttons/QuantitySelectorGlobal";
import { IonDatetime, IonDatetimeButton, IonModal } from "@ionic/react";
import TabsForTipPreference from "../toggleable-tabs/TabsForTipPreference";
import ModalFinalizeOrder from "./ModalFinalizeOrder";
import momenttimezone from "moment-timezone";
import TimeWidget from "../time/TimeWidget";
import useDarkMode from "../../hooks/darkmode/DarkMode";
import ShowProductCustomizations from "./ShowProductCustomizations";
import { objectType } from "../../../type";

const ModalDiv = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1000;
  overflow-y: auto;
`;

const ContentDiv = styled.div<{ isDarkMode: boolean }>`
    background-color: ${props => (props?.isDarkMode ? '#2e2e2e' : '#fff')};
    margin-bottom: 85px;
    margin-top: 80px;
    padding: 10px;
    z-index: 1000;
    width: 600px;
    max-width: 90%;
    border-radius: 20px;
    text-align: center;
    height: fit-content;
    animation: ${moveTopBottom} .4s ease-in-out;
`;

const HeartModalIcon = styled.div`
    padding: 10px;
    background: #f03f3f29;
    border-radius: 15px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: -65px;
    width: fit-content;
    position: relative;
    top: -60px;
    backdrop-filter: blur(7.5px);
`;

const BlockContainer = styled.div`
  display: block;
  width: -webkit-fill-available;
  text-align: left;
`;

const BackModalBtn = styled.div`
  position: relative;
  right: 49%;
  font-size: 17px;
  cursor: pointer;
`;

const TextMealChildren1 = styled.h3`
  color: #868686;
  font-size: 18px;
  font-weight: 400;
  font-style: italic;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;


export default function ModalReschedule(props: any) {

  // ********************** Other Methods ********************************************
  const modalRef = useRef();
  const isDarkMode = useDarkMode();
  const user = getUserInfo();

  // ********************************* State Management ***********************************************
  const { upcomingOrdersMealDetails } = useSelector((state: any) => state?.upcomingOrderState);
  const rescheduleData = upcomingOrdersMealDetails[0];
  const favMealItems = rescheduleData?.attributes?.favMeal?.data?.attributes?.favItems?.data;
  const mealMeItems = rescheduleData?.attributes?.mealMeOrderObject?.final_quote?.items;
  const slotDeliveryAddress = rescheduleData?.attributes?.delivery_location?.data;
  const currentDateTime = new Date();
  const minDate: any = momenttimezone().tz(slotDeliveryAddress?.attributes?.timezone?.timeZoneId).format();

  const Tips = [
    {
      name: "None",
      value: "0"
    },
    {
      name: "$5",
      value: "5"
    },
    {
      name: "$10",
      value: "10"
    },
    {
      name: "$15",
      value: "15"
    },
    {
      name: "Other",
      value: "Other"
    }
  ]

  // ***************** Hooks *********************************
  const [storeTipActive, setStoreTipActive] = useState("");
  const [driverTipActive, setDriverTipActive] = useState("");

  const [storeTipValue, setStoreTipValue] = useState("");
  const [driverTipValue, setDriverTipValue] = useState("");

  const [otherStoreTipValue, setOtherStoreTipValue] = useState("");
  const [otherStoreTipError, setOtherStoreTipError] = useState(false);

  const [otherDriverTipValue, setOtherDriverTipValue] = useState("");
  const [otherDriverTipError, setOtherDriverTipError] = useState(false);

  const handleOtherStoreTip = (val: any) => {
    let maskedValue = maskCurrencyInput(val);
    setOtherStoreTipError(false);
    setOtherStoreTipValue(maskedValue);
  };

  const handleOtherDriverTip = (val: any) => {
    let maskedValue = maskCurrencyInput(val);
    setOtherDriverTipError(false);
    setOtherDriverTipValue(maskedValue);
  };

  const [inputList, setInputList] = useState([{ id: null, quantity: null }]);
  // const [mealItemList, setMealItemList] = useState([{ id: null, name: null, price: null, customizations: null, quantity: null, instructions: null }]);
  const [hideBackModal, setHideBackModal] = useState(false);
  const [addressIdError, setAddressIdError] = useState(false);
  const [addressId, setAddressId] = useState("");
  const [paymentCardId, setPaymentCardId] = useState("");
  const [paymentCardError, setPaymentCardError] = useState(false);
  const [orderProcess, setOrderProcess] = useState(false);
  const [responseError, setResponseError] = useState("");
  const [showIonAlertModal, setShowIonAlertModal] = useState(false);
  const [ionAlertHeader, setIonAlertHeader] = useState("");
  const [ionAlertMessage, setIonAlertMessage] = useState("");
  const [scheduleValues, setScheduleValues] = useState({
    time: "",
    date: fullDateToShortConvert(new Date())
  });
  const [scheduleTimeError, setScheduleTimeError] = useState(false);
  const [notes, setNotes] = useState(rescheduleData?.attributes?.instructions);
  const [showFinalizeModal, setShowFinalizeModal] = useState(false);
  const [scheduleOrderedData, setScheduleOrderedData] = useState<any>([]);
  const [restaurantSchedules, setRestaurantSchedules] = useState([]);
  const [restaurantTime, setRestaurantTime] = useState(restaurantOpenAndClosingTime(rescheduleData?.attributes?.order_type === "delivery" ? rescheduleData?.attributes?.restaurant?.data?.attributes?.jsonData?.local_hours?.delivery : rescheduleData?.attributes?.restaurant?.data?.attributes?.jsonData?.local_hours?.pickup, currentDateTime, rescheduleData?.attributes?.order_type));
  const [restaurantQuote, setRestaurantQuote] = useState<objectType>({});

  useEffect(() => {
    if (restaurantQuote?.quote?.hasOwnProperty('error') || (!restaurantQuote?.quote?.hasOwnProperty('error') && !restaurantQuote?.quote?.accepts_pickup_tip)) {
      setStoreTipValue(Tips[0]?.value);
      setStoreTipActive(Tips[0]?.value);
    }
    else {
      const filterStoreTip = (Tips).filter((data) => data?.value === String(rescheduleData?.attributes?.tip));
      if (filterStoreTip?.length > 0) {
        setStoreTipValue(filterStoreTip[0]?.value);
        setStoreTipActive(filterStoreTip[0]?.value);
      }
      else if (rescheduleData?.attributes?.tip === null) {
        setStoreTipValue(Tips[0]?.value);
        setStoreTipActive(Tips[0]?.value);
      }
      else {
        setStoreTipValue("Other");
        setStoreTipActive(Tips[4]?.value);
        setOtherStoreTipValue("$" + rescheduleData?.attributes?.tip);
      }
    }

    if (restaurantQuote?.quote?.hasOwnProperty('error') || (!restaurantQuote?.quote?.hasOwnProperty('error') && !restaurantQuote?.quote?.accepts_delivery_tip)) {
      setDriverTipValue(Tips[0]?.value);
      setDriverTipActive(Tips[0]?.value);
    }
    else {
      const filterDriverTip = (Tips).filter((data) => data?.value === String(rescheduleData?.attributes?.driver_tip));
      if (filterDriverTip?.length > 0) {
        setDriverTipValue(filterDriverTip[0]?.value);
        setDriverTipActive(filterDriverTip[0]?.value);
      }
      else if (rescheduleData?.attributes?.driver_tip === null) {
        setDriverTipValue(Tips[0]?.value);
        setDriverTipActive(Tips[0]?.value);
      }
      else {
        setDriverTipValue("Other");
        setDriverTipActive(Tips[4]?.value);
        setOtherDriverTipValue("$" + rescheduleData?.attributes?.driver_tip);
      }
    }
  }, [restaurantQuote]);


  useEffect(() => {
    callData();
  }, []);

  const callData = () => {
    let tempArray = [];
    // let tempMealItemList = [];

    mealMeItems.forEach((currentObj: any) => {
      tempArray.push({
        id: currentObj?.product_id,
        quantity: Number(currentObj?.quantity),
      });

      //********************** */ Calling the recursive function to retrieve selected data ************************************
      // tempMealItemList?.push({
      //   id: currentObj?.product_id,
      //   name: currentObj?.name,
      //   price: Number(currentObj?.base_price),
      //   customizations: currentObj?.customizations,
      //   quantity: Number(currentObj?.quantity),
      //   instructions: currentObj?.notes ? currentObj?.notes : ""
      // });

    });
    setInputList(tempArray);
    // setMealItemList(tempMealItemList);

    // Iterate through the first array
    for (let i = 0; i < favMealItems.length; i++) {
      const item1 = favMealItems[i];
      const productId1 = item1?.attributes?.product?.data?.attributes?.mealme_product_id;

      // Find the corresponding item in the second array
      const item2 = mealMeItems.find((item: objectType, index: number) => item?.product_id === productId1 && index === i);

      // If a corresponding item is found, update the quantity in the first array
      if (item2) {
        favMealItems[i].attributes.quantity = item2.quantity;
      }
    }
    // Now array1 has updated quantities
    // console.log(favMealItems);

  };

  const handleOptionChange = (value: any, index: number, id: any) => {
    // For Quantity selector
    const list: any = [...inputList];
    const match_index = list.findIndex((obj: any) => obj.id === id)

    list[match_index]["quantity"] = value;
    setInputList(list);

    // For list to show
    // const itemList: any = [...mealItemList];
    // const find_index = itemList.findIndex((obj: any) => obj.id === id)

    // if (itemList.length > 0 && value === 0) {
    //   itemList.splice(find_index, 1);
    //   setMealItemList(itemList);
    // }
    // else {
    //   itemList[find_index]["quantity"] = value;
    //   setMealItemList(itemList);
    // }
    // For order Meal

    const index1 = favMealItems.findIndex((obj: any) => obj.attributes?.product?.data?.attributes?.mealme_product_id === id)
    if (favMealItems.length > 0 && value === 0) {
      favMealItems.splice(index1, 1);
    }
    else {
      favMealItems[index1].attributes.quantity = value;
    }

    const index2 = mealMeItems.findIndex((obj: any) => obj.product_id === id)
    if (mealMeItems.length > 0 && value === 0) {
      mealMeItems.splice(index2, 1);
    }
    else {
      mealMeItems[index2].quantity = value;
    }
  };

  const closeModal = (e: any) => {
    if (modalRef.current === e.target) {
      props?.setShowMealModal(false);
    }
  };

  let restaurants: any;
  favMealItems?.forEach((element: any) => {
    let restaurantName: any;
    let restaurantDescription: any;
    restaurantName = element?.attributes?.product?.data?.attributes?.restaurant?.data?.attributes?.name;
    restaurantDescription = element?.attributes?.product?.data?.attributes?.restaurant?.data?.attributes?.description;
    restaurants = { restaurantName, restaurantDescription };
    return restaurants;
  });

  const handleAddressId = (id: any) => {
    setAddressId(id);
    setAddressIdError(false);
  }

  const handlePaymentId = (id: any) => {
    setPaymentCardId(id);
    setPaymentCardError(false);
  }

  const handleOnDateChange = (e: any) => {
    const { name, value } = e.target;
    setResponseError("");
    setScheduleValues((prevValues) => {
      return {
        ...prevValues,
        [name]: value,
        time: ""
      };
    });
    setRestaurantTime(restaurantOpenAndClosingTime(rescheduleData?.attributes?.order_type === "delivery" ? rescheduleData?.attributes?.restaurant?.data?.attributes?.jsonData?.local_hours?.delivery : rescheduleData?.attributes?.restaurant?.data?.attributes?.jsonData?.local_hours?.pickup, value, rescheduleData?.attributes?.order_type));
  };

  const timeValuesHandler = (value: any) => {
    setScheduleValues((prevValues) => {
      return {
        ...prevValues,
        time: value,
      };
    });
  };

  const restaurantSchedulesHandler = (data: any) => {
    setRestaurantSchedules(data);
  };

  const handleRescheduleOrder = async () => {
    try {
      setResponseError("");

      if (orderProcess) { return false; }

      let scheduleTimeFirstIndexId = Object.values(restaurantSchedules);

      let selectedScheduleId = (scheduleValues?.time?.split("@")[1] !== undefined) ? scheduleValues?.time?.split("@")[1] : "";

      const timeDifferences = getTimeDifference(slotDeliveryAddress?.attributes?.timezone, scheduleValues?.time);
      let selectedDate = scheduleValues?.date?.split("T")[0];

      if (rescheduleData?.attributes?.order_type === "delivery" && (addressId === "" || addressId === undefined)) {
        setIonAlertHeader("No Address Found!"); setIonAlertMessage("Please, Add Address."); setShowIonAlertModal(true);
      }
      if (paymentCardId === "" || paymentCardId === undefined) { setIonAlertHeader("No Payment Method Found!"); setIonAlertMessage("Please, Add Card."); setShowIonAlertModal(true); }

      if (scheduleValues?.time?.split("@")[0] === "" || scheduleValues?.time?.split("@")[0] === undefined) { setScheduleTimeError(true); setIonAlertHeader("Please select time."); setShowIonAlertModal(true); }
      else {
        setScheduleTimeError(false);
      }

      if (rescheduleData?.attributes?.order_type === "pickup" && storeTipValue === "Other" && otherStoreTipValue === "") {
        setOtherStoreTipError(true);
      } else {
        setOtherStoreTipError(false);
      }

      if (rescheduleData?.attributes?.order_type === "delivery" && driverTipValue === "Other" && otherDriverTipValue === "") {
        setOtherDriverTipError(true);
      } else {
        setOtherDriverTipError(false);
      }

      if (
        (rescheduleData?.attributes?.order_type === "delivery" && (addressId === "" || addressId === undefined || addressIdError)) ||
        (paymentCardId === "" || paymentCardId === undefined) ||
        (scheduleValues?.time?.split("@")[0] === "" || scheduleValues?.time?.split("@")[0] === undefined) ||
        (scheduleValues?.date === "" || scheduleValues?.date === undefined) ||
        (rescheduleData?.attributes?.order_type === "pickup" && (storeTipValue === "Other" && otherStoreTipValue === "") || otherStoreTipError) ||
        (rescheduleData?.attributes?.order_type === "delivery" && (driverTipValue === "Other" && otherDriverTipValue === "") || otherDriverTipError) ||
        paymentCardError || scheduleTimeError
      ) {
        return false;
      }
      setOrderProcess(true);
      const orderData = {
        mealId: rescheduleData?.attributes?.favMeal?.data?.id,
        items: existingOrderItemsFormatter(favMealItems),
        delivery_location: (rescheduleData?.attributes?.order_type === "pickup") ? rescheduleData?.attributes?.delivery_location?.data?.id : addressId,
        newMeal: false,
        mealType: rescheduleData?.attributes?.favMeal?.data?.attributes?.type,
        userId: rescheduleData?.attributes?.user?.data?.id,
        type: rescheduleData?.attributes?.type,
        order_status: (selectedDate === timeDifferences?.currentDate && timeDifferences?.differenceInMinutes < 15) ? "ORDERED" : "SCHEDULED",
        from_userId: user?.id,
        date: selectedDate,
        time: scheduleValues?.time?.split("@")[0],
        recipientName: rescheduleData?.attributes?.user?.data?.attributes?.name ? rescheduleData?.attributes?.user?.data?.attributes?.name : "",
        schedule: (rescheduleData?.attributes?.schedule?.data !== null) ? rescheduleData?.attributes?.schedule?.data?.id : null,
        sponsoredBy: rescheduleData?.attributes?.sponsoredBy?.data?.id,
        paymentCardId: paymentCardId,
        slotId: rescheduleData?.id,
        discount_json: [],
        discount_type: "flat",
        discount_name: "",
        discount_amt: "0",
        discounted_amt: "0",
        verifiedSlot: (scheduleValues?.time?.split("~")[1] === 'verified') ? true : false,
        orderType: rescheduleData?.attributes?.order_type,
        accepts_pickup_tip: restaurantQuote?.quote?.accepts_pickup_tip || false,
        accepts_delivery_tip: restaurantQuote?.quote?.accepts_delivery_tip || false
      };
      if (rescheduleData?.attributes?.order_type === "pickup") {
        orderData['storeTip'] = storeTipValue === "Other" ? Number(otherStoreTipValue?.slice(1)) : Number(storeTipValue)
      }
      if (rescheduleData?.attributes?.order_type === "delivery") {
        orderData['driverTip'] = driverTipValue === "Other" ? Number(otherDriverTipValue?.slice(1)) : Number(driverTipValue)
        orderData['instructions'] = notes
      }

      const payloadData = {
        data: {
          items: existingOrderItemsFormatter(favMealItems),
          delivery_location: (rescheduleData?.attributes?.order_type === "pickup") ? rescheduleData?.attributes?.delivery_location?.data?.id : addressId,
          paymentCardId: paymentCardId,
          userId: rescheduleData?.attributes?.user?.data?.id,
          scheduled_id: (Object.keys(restaurantSchedules).length === 0 ? "" : (selectedDate === timeDifferences?.currentDate && timeDifferences?.differenceInMinutes < 15) ? "" : (selectedScheduleId !== "" ? selectedScheduleId?.split("~")[0] : scheduleTimeFirstIndexId[0][0]?.id)),
          store_id: rescheduleData?.attributes?.restaurant?.data?.attributes?.mealme_id,
          newMeal: false,
          date: selectedDate,
          time: scheduleValues?.time?.split("@")[0],
          orderType: rescheduleData?.attributes?.order_type,
          discount_json: [],
          discount_type: "flat",
          discount_name: "",
          discount_amt: "0",
          discounted_amt: "0",
          accepts_pickup_tip: restaurantQuote?.quote?.accepts_pickup_tip || false,
          accepts_delivery_tip: restaurantQuote?.quote?.accepts_delivery_tip || false
        }
      };
      if (rescheduleData?.attributes?.order_type === "pickup") {
        payloadData.data['storeTip'] = storeTipValue === "Other" ? Number(otherStoreTipValue?.slice(1)) : Number(storeTipValue)
      }
      if (rescheduleData?.attributes?.order_type === "delivery") {
        payloadData.data['driverTip'] = driverTipValue === "Other" ? Number(otherDriverTipValue?.slice(1)) : Number(driverTipValue)
        payloadData.data['instructions'] = notes
      }
      await previewOrder(payloadData).then((res) => {
        if (res?.status === 200) {
          trackPixelEvent('Preview order');
          const scheduleResData = res?.data;
          const newData = ({
            ...scheduleResData,
            orderData: orderData
          })
          setScheduleOrderedData(newData);
          setHideBackModal(true);
          setShowFinalizeModal(prev => !prev);
          setOrderProcess(false);
        }
      })
    }
    catch (e) {
      console.log(e);
      setResponseError(e?.response?.data?.data?.error);
      setOrderProcess(false);
    }
  };

  return (
    <>
      {(props?.showMealModal)
        &&
        (
          <ModalDiv onClick={closeModal} >
            <ContentDiv className={hideBackModal ? "invisible" : ""} isDarkMode={isDarkMode}>
              <BackModalBtn>
                <FontAwesomeIcon
                  icon={faChevronLeft}
                  onClick={() => props?.setShowMealModal((prev: any) => !prev)}
                />
              </BackModalBtn>
              <HeartModalIcon>
                <FontAwesomeIcon
                  icon={faHeart}
                  style={{ fontSize: "30px", color: "#ff000082" }}
                />
              </HeartModalIcon>
              <TextHead1>{switchSentenceCase("head text", rescheduleData?.attributes?.favMeal?.data?.attributes?.name)}</TextHead1>

              <SubText1 className="mt-1.5">
                {(rescheduleData?.attributes?.restaurant?.data?.attributes?.name) && rescheduleData?.attributes?.restaurant?.data?.attributes?.name}
              </SubText1>

              {rescheduleData?.attributes?.restaurant?.data !== null &&
                <SubText1 className="!text-red-600">
                  {(slotDeliveryAddress?.attributes?.timezone !== null) && (!isRestaurantOpen(rescheduleData?.attributes?.order_type === "delivery" ? rescheduleData?.attributes?.restaurant?.data?.attributes?.jsonData?.local_hours?.delivery : rescheduleData?.attributes?.restaurant?.data?.attributes?.jsonData?.local_hours?.pickup, currentDateTime, slotDeliveryAddress?.attributes?.timezone, false) && "(Closed)")}
                </SubText1>
              }

              {rescheduleData?.attributes?.type === "for_other" ?
                <>
                  <SubText1 className="mt-1.5">
                    {(rescheduleData?.attributes?.user?.data?.attributes?.name ? rescheduleData?.attributes?.user?.data?.attributes?.name : "")} - {(rescheduleData?.attributes?.user?.data?.attributes?.phone_no ? rescheduleData?.attributes?.user?.data?.attributes?.phone_no : rescheduleData?.attributes?.user?.data?.attributes?.email)}
                  </SubText1>
                </>
                :
                ""
              }
              <TextMealChildren1>
                {(rescheduleData?.attributes?.restaurant?.data?.attributes?.description) && rescheduleData?.attributes?.restaurant?.data?.attributes?.description}
              </TextMealChildren1>

              <InitialDiv className="mx-4">
                {/********************  Meal Cart Section ******************************/}
                <Accordion title="Meal Items" opened={true}>
                  {rescheduleData?.attributes?.mealMeOrderObject?.final_quote?.items.map((item: any, index: any) => {
                    return (
                      <React.Fragment key={index}>
                        <Flex key={index} >
                          <ItemCount>{index + 1}</ItemCount>
                          <BlockContainer>
                            <Flex>
                              <TextHead4 className="underline w-[200px]" style={{ cursor: "pointer" }}>
                                {properNameFormate(item?.name)}
                              </TextHead4>
                              {(item?.notes) &&
                                <ToolTip instructions={item?.notes} />
                              }
                            </Flex>
                            {
                              (item?.customizations?.length > 0)
                              &&
                              <>
                                <AccordionCheckoutCustomization
                                  title={"Selected Customizations"}
                                  titleSize={"16px"}
                                >
                                  <div className="block">
                                    <ShowProductCustomizations customizations={item?.customizations} />
                                  </div>
                                </AccordionCheckoutCustomization>
                              </>
                            }

                            <div className="my-2 flex justify-between items-center">

                              {(rescheduleData?.attributes?.sponsoredBy?.data?.id === user?.id)
                                &&
                                <QuantitySelectorGlobal
                                  selected_quantity={item?.quantity}
                                  increaseQuantity={() => {
                                    const count = (Number(inputList[index]?.quantity) + 1)
                                    handleOptionChange(count, index, item?.product_id)
                                  }}

                                  decreaseQuantity={() => {
                                    const totalCount = inputList.reduce((n, { quantity }) => (Number(n) + Number(quantity)), 0)
                                    if (Number(item?.quantity) > 0 && totalCount > 1) {
                                      const count = (Number(inputList[index]?.quantity) - 1)
                                      handleOptionChange(count, index, item?.product_id)
                                    }
                                  }}
                                />}

                              <SubText1>
                                {priceFormatterUsd(
                                  increasePriceByQuantity(
                                    (finalizeItemPriceCalculate(item?.customizations) + item?.base_price), item?.quantity))
                                }
                              </SubText1>
                            </div>
                          </BlockContainer>
                        </Flex>
                      </React.Fragment >
                    )
                  })}
                </Accordion>

                <InitialDiv>
                  <Flex className="items-baseline">
                    <FontAwesomeIcon icon={faStore} className="text-gray-600 text-s mt-1" />
                    <TextHead3 className="ml-2 text-left">{rescheduleData?.attributes?.restaurant?.data?.attributes?.name}</TextHead3>
                  </Flex>
                  <InitialDiv className="flex mb-4">
                    <FontAwesomeIcon icon={faCircleInfo} className={(restaurantTime?.openingTime === "") ? "mt-[5px] mr-1 text-red-600" : "mt-[5px] mr-1 text-[#7a7a7aba]"} />
                    <TextHead5 style={{ fontWeight: "500", marginLeft: "4px" }} className={(restaurantTime?.openingTime === "") ? "text-red-600 text-left" : "text-[#7a7a7aba] text-left"}>{restaurantTime?.restaurantAvailability}</TextHead5>
                  </InitialDiv>
                </InitialDiv>

                {/********************  Schedule Section ******************************/}
                {/* <FlexBetween> */}
                <Flex className="items-center mt-4">
                  <TextHead3 className="mb-1">
                    {`${(rescheduleData?.attributes?.order_type === "delivery") ? "Delivery" : "Pickup"}  Date/Time`}
                  </TextHead3>
                </Flex>

                <InitialDiv className="text-left text-[#7a7a7aba] flex mb-4">
                  <FontAwesomeIcon icon={faCircleInfo} className="mt-[7px] mr-1" />
                  <TextHead5 style={{ fontWeight: "500", marginLeft: "4px" }}>
                    {`${(rescheduleData?.attributes?.order_type === "delivery") ? "Delivery" : "Pickup"} time is our best estimate. Green indicates confirmed appointments, while black will either be confirmed on the morning of ${(rescheduleData?.attributes?.order_type === "delivery") ? "delivery" : "pickup"}, an hour before, or canceled. The time zone is based on the  ${(rescheduleData?.attributes?.order_type === "delivery") ? "delivery" : "pickup"} location.`}
                  </TextHead5>
                </InitialDiv>

                {/* </FlexBetween> */}

                <InitialDiv className="">
                  <div className="flex">
                    <FontAwesomeIcon icon={faCalendarPen} className="mt-1" />
                    <div className="ml-2 w-full">
                      <TextHead4 className="flex justify-between">Select Date</TextHead4>
                      <SubText2 className="mt-2">
                        <IonDatetimeButton className="my-2.5" datetime="date" style={{ border: "1px solid #e2e2e2", borderRadius: "8px" }}></IonDatetimeButton>
                        <IonModal keepContentsMounted={true}>
                          <IonDatetime className={isDarkMode ? "calendar-color" : ""} name="date" value={scheduleValues?.date} color={isDarkMode ? "dark" : "success"} onIonChange={(e: any) => handleOnDateChange(e)} min={minDate} showDefaultButtons={true} presentation="date" preferWheel={false} id="date" ></IonDatetime>
                        </IonModal>
                      </SubText2>
                    </div>
                  </div>
                  <div className="flex mt-2">
                    <FontAwesomeIcon icon={faClockThree} className="mt-1" />
                    <TimeWidget
                      storeId={rescheduleData?.attributes?.restaurant?.data?.attributes?.mealme_id}
                      date={scheduleValues?.date}
                      timezone={slotDeliveryAddress?.attributes?.timezone}
                      openingTime={restaurantTime?.openingTime}
                      closingTime={restaurantTime?.closingTime}
                      timeValuesData={timeValuesHandler}
                      restaurantSchedulesData={restaurantSchedulesHandler}
                      orderType={rescheduleData?.attributes?.order_type}
                      restaurantQuote={setRestaurantQuote}

                    />
                  </div>
                </InitialDiv>


                {/********************  Store Tip Section ******************************/}
                {rescheduleData?.attributes?.order_type === "pickup" &&
                  <InitialDiv className="my-4">
                    <TextHead3 className="text-left">Store Tip</TextHead3>
                    {restaurantQuote?.quote && (!restaurantQuote?.quote?.hasOwnProperty('error') && !restaurantQuote?.quote?.accepts_pickup_tip) && (
                      <InitialDiv className="text-left">
                        <FontAwesomeIcon icon={faCircleInfo} className="text-red-500 mt-2 mr-1" />
                        <span
                          style={{
                            fontWeight: "350",
                            color: "#dc2626",
                            fontSize: "17px",
                          }}
                        >
                          Store tip is not available for this restaurant.
                        </span>
                      </InitialDiv>
                    )}
                    <TabsForTipPreference
                      types={Tips}
                      setTipValue={setStoreTipValue}
                      setActive={setStoreTipActive}
                      selected={storeTipActive}
                      disabled={restaurantQuote?.quote ? false : true || (!restaurantQuote?.quote?.hasOwnProperty('error') && !restaurantQuote?.quote?.accepts_pickup_tip)}
                    />
                    {(storeTipValue === "Other")
                      ?
                      <InputText
                        type="text"
                        inputMode="numeric"
                        style={{
                          border: otherStoreTipError ? "1px solid red" : "",
                        }}
                        min={0}
                        value={otherStoreTipValue}
                        disabled={restaurantQuote?.quote ? false : true || (!restaurantQuote?.quote?.hasOwnProperty('error') && !restaurantQuote?.quote?.accepts_pickup_tip)}
                        placeholder="Enter Amount"
                        onChange={(event: any) => handleOtherStoreTip(event.target.value)}
                      />
                      :
                      null
                    }
                  </InitialDiv>
                }

                {/********************  Driver's Tip Section ******************************/}
                {rescheduleData?.attributes?.order_type === "delivery" &&
                  <InitialDiv className="my-4">
                    <TextHead3 className="text-left">Driver's Tip</TextHead3>
                    {restaurantQuote?.quote && (!restaurantQuote?.quote?.hasOwnProperty('error') && !restaurantQuote?.quote?.accepts_delivery_tip) && (
                      <InitialDiv className="text-left">
                        <FontAwesomeIcon icon={faCircleInfo} className="text-red-500 mt-2 mr-1" />
                        <span
                          style={{
                            fontWeight: "350",
                            color: "#dc2626",
                            fontSize: "17px",
                          }}
                        >
                          Driver tip is not available for this restaurant.
                        </span>
                      </InitialDiv>
                    )}
                    <TabsForTipPreference
                      types={Tips}
                      setTipValue={setDriverTipValue}
                      setActive={setDriverTipActive}
                      selected={driverTipActive}
                      disabled={restaurantQuote?.quote ? false : true || (!restaurantQuote?.quote?.hasOwnProperty('error') && !restaurantQuote?.quote?.accepts_delivery_tip)}
                    />
                    {(driverTipValue === "Other")
                      ?
                      <InputText
                        type="text"
                        inputMode="numeric"
                        style={{
                          border: otherDriverTipError ? "1px solid red" : "",
                        }}
                        min={0}
                        value={otherDriverTipValue}
                        disabled={restaurantQuote?.quote ? false : true || (!restaurantQuote?.quote?.hasOwnProperty('error') && !restaurantQuote?.quote?.accepts_delivery_tip)}
                        placeholder="Enter Amount"
                        onChange={(event: any) => handleOtherDriverTip(event.target.value)}
                      />
                      :
                      null
                    }
                  </InitialDiv>
                }

                {/********************  Address Section ******************************/}
                {rescheduleData?.attributes?.order_type === "delivery" &&
                  <Accordion title="Delivery Address" opened={false}>
                    <AddressesCart title="" addressId={handleAddressId} showDeleteBtn={false} showList={false} pageName="newMealCheckout" pageDataId={slotDeliveryAddress?.id} changeAddressTxtBtn="CHANGE ADDRESS" userId={rescheduleData?.attributes?.user?.data?.id} setDeliveryAddress={false} changeAddress={true} initialLength={1} />
                  </Accordion>
                }


                {/********************  Payment Section ******************************/}
                <Accordion title="Payment" opened={false}>
                  <PaymentCart paymentId={handlePaymentId} userId={rescheduleData?.attributes?.sponsoredBy?.data?.id} changePayment={false} selectedCard={rescheduleData?.attributes?.payment_card} />
                </Accordion>


                {/********************  Notes Section ******************************/}
                {rescheduleData?.attributes?.order_type === "delivery" &&
                  <InitialDiv className="mt-8">
                    <TextHead3 className="text-left">Drop Off Notes</TextHead3>
                    <SubText1 className="text-right mt-1">{notes?.length}/{maxCharacterLimit}</SubText1>
                    <TextArea1
                      className="!mt-0"
                      placeholder=""
                      name="description"
                      value={notes}
                      onChange={(event: any) => setNotes(event.target.value)}
                      maxLength={maxCharacterLimit}
                    />
                  </InitialDiv>
                }

                <FlexBetween className="my-3.5">
                  <TextHead4 style={{ fontWeight: "500" }}>Subtotal</TextHead4>
                  <TextHead4 style={{ fontWeight: "500" }}>${sumOfMealInCart(favMealItems)}</TextHead4>
                </FlexBetween>
              </InitialDiv>

              <InitialDiv className="mx-4">

                {orderProcess ?
                  <Spinner /> :
                  <ButtonGreen
                    className="mt-4"
                    onClick={handleRescheduleOrder}
                  >
                    {switchSentenceCase("primary button", "Reschedule Meal")}
                  </ButtonGreen>
                }
                {responseError && <p className="text-red-600 text-center">{(responseError === "Scheduled id is not valid or not available.") ? "Something went wrong. Please retry or 'Contact Us' in your profile." : responseError}</p>}
              </InitialDiv>
            </ContentDiv>
          </ModalDiv >
        )
      }

      {/* ********************* Alert Modal for validations ****************************** */}
      <IonModalAlert
        isOpened={showIonAlertModal}
        setIsOpened={setShowIonAlertModal}
        header={ionAlertHeader}
        subHeader=""
        message={ionAlertMessage}
      />

      {/* *********************Modal Finalize Order****************************** */}
      {showFinalizeModal &&
        <ModalFinalizeOrder
          showFinalizeModal={showFinalizeModal}
          setShowFinalizeModal={setShowFinalizeModal}
          finalizeData={scheduleOrderedData}
          setHideBackModal={setHideBackModal}
          favMealObj={scheduleOrderedData?.data}
          closeBackModal={props?.setShowMealModal}
          updatePage={props?.updatePage}
        />
      }
    </>

  );
}



