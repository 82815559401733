import React, { useState, useEffect } from "react";
import Section from "../../components/widgets/section/Section";
import TopHead from "../../components/widgets/head/TopHead";
import Divider from "../../components/widgets/divider/Divider";
import { ButtonGray, ButtonGreen, ButtonLimeGreen, SubText1, TextHead1, FlexBetween, Flex, ButtonInitial, InitialDiv, TextHead3, TextArea1, Block, TextHead4 } from "../../components/styled/styled";
import styled from "styled-components";
import { useHistory, useParams } from "react-router";
import { switchSentenceCase, slotDateTimeFormatter, mergeSlotDateTime, maxCharacterLimit, trackPixelEvent, trackStandardPixelEvent } from "../../utils/utilities";
import { faAngleLeft, faChevronLeft, faHatChef, faPanFrying, faPen, faPotFood } from "@fortawesome/pro-solid-svg-icons";
import ModalEditSlots from "../../components/widgets/modals/ModalEditSlots";
import ModalAutofillConfirm from "../../components/widgets/modals/ModalAutofillConfirm";
import { useSelector, useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { removeScheduleSlots, getSlotDetails, getSelectedSlots, setScheduleSlotsRedux, setScheduleSlotsLoaderRedux, setUserFavMeals, selfCookedSlots, setScheduleDetailsRedux } from "../schedules/redux/createScheduleReduxActions";
import ModalRemoved from "../../components/widgets/modals/ModalRemoved";
import OneSideTextToggle from "../../components/widgets/buttons/OneSideTextToggle";
import ModalSpinner from "../../components/widgets/modalspinner/ModalSpinner";
import { getScheduleById, getScheduleSlots } from "../../services/schedule";
import { useIonAlert, useIonViewDidEnter } from "@ionic/react";
import { getMealCategory, getUserFavoriteMeal } from "../../services/favourite";
import { getUserInfo } from "../../services/user";
import InitialModalCenter from "../../components/widgets/modals/InitialModalCenter";
import Spinner from "../../components/widgets/spinner/Spinner";
import ModalChooseOption from "../../components/widgets/modals/ModalChooseOption";
import { setUserMealCategory } from "../faves/redux/favouriteActionCreator";

const WrapChoice = styled.div`
  padding: 5px;
`;

const Trans = styled.div`
  font-size: 18px;
  font-weight: 500;
  margin-top: 10px;
`;

// const TabText = styled.button`
//   padding: 5px;
//   color: #217346;
//   background: #21734633;
//   border-radius: 5px;
//   float: right;
//   font-size: 15px;
// `;

// const EmptySlottext = styled.button`
//   color: #22a45d;
//   font-size: 13px;
// `;

export default function ScheduleWSlots() {
  const history = useHistory();
  const dispatch = useDispatch();
  const currentDateTime = new Date();
  const user = getUserInfo();
  const { scheduleId } = useParams<any>();
  const [presentAlert] = useIonAlert();
  const showAlert = (header: string, message: string, btnMessage: string) => {
    presentAlert({
      header: header,
      message: message,
      buttons: [btnMessage],
    });
  };

  const { scheduleDetails, scheduleSlots, awaiting } = useSelector((state: any) => state?.createScheduleForMe);
  const filterEmptySlot = scheduleSlots?.filter((el: any) => el?.attributes?.favMeal?.data !== null)
  const [showModalFill, setShowModalFill] = useState(false);
  const [showModalSlotUnFill, setShowModalSlotUnFill] = useState(false);
  const [selectedSlotsId, setSelectedSlots] = useState([]);
  const [showModalRemove, setShowModalRemove] = useState(false);
  const limit = 10;
  const [skip, setSkip] = useState(0);
  const [selectEmptySlots, setSelectEmptySlots] = useState<Boolean>(false);
  const [totalSlots, setTotalSlots] = useState(0);
  const [showHomeCookedMeal, setShowHomeCookedMeal] = useState(false);
  const [selfCookedDescription, setSelfCookedDescription] = useState("");
  const [descriptionError, setDescriptionError] = useState(false);
  const [autoFillLoader, setAutoFillLoader] = useState(false);
  const [showOptionModal, setShowOptionModal] = useState(false);

  useEffect(() => {
    callData();
  }, [])

  useEffect(() => {
    (async () => {
      try {
        let query = {
          suggested: (scheduleDetails?.attributes?.user?.data?.id !== user?.id) ? true : "All"
        }
        const response = await getMealCategory(scheduleDetails?.attributes?.user?.data?.id, query);
        setUserMealCategory(response?.data?.data);
      }
      catch (e) {
        console.log(e);
      }
    })();
  }, []);

  useIonViewDidEnter(() => {
    trackStandardPixelEvent('Schedule');
  }, []);

  function handleToggleSelectAll() {
    setSelectEmptySlots(!selectEmptySlots);

    if (!selectEmptySlots) {
      let tempArray = [];
      scheduleSlots?.forEach((currentObj: any) => {
        if (currentObj?.attributes?.favMeal?.data === null) {
          tempArray.push(currentObj?.id)
        }
      });
      setSelectedSlots(tempArray);
    }
    else {
      setSelectedSlots([]);
    };

    const updatedData = scheduleSlots?.map((item: any) => {
      if (item?.attributes?.favMeal?.data === null) {
        item.attributes["is_selected"] = !selectEmptySlots;
        return item;
      }
      else {
        item.attributes["is_selected"] = false;
        return item;
      }
    })
    setScheduleSlotsRedux(updatedData)
  };

  const GotoHandle = () => {
    history.push({
      pathname: "/scheduledetailsrecipientsnew",
    });
  };

  const openModalEditSlot = async (id: any) => {
    dispatch(getSlotDetails(id) as any);
    dispatch(getSelectedSlots([id]) as any);
    setShowModalSlotUnFill((prev) => !prev);
  };

  const handleClickOnOrder = (type: string) => {
    if (selectedSlotsId?.length === 0) {
      return showAlert('', 'Please select the schedule slots you want to edit.', 'OK');
    }
    if (type === "autofill") {
      setAutoFillLoader(true);
    }
    setShowOptionModal(true);
  };

  const handleFillSlots = async (value: string) => {
    if (value === "") {
      setAutoFillLoader(false);
      return showAlert('', 'Please select any one option.', 'OK');
    }
    setShowOptionModal(false);
    dispatch(getSelectedSlots(selectedSlotsId) as any);
    let mealCategoriesId = []
    scheduleDetails?.attributes?.meal_categories?.data?.forEach((currentObj: any) => {
      mealCategoriesId.push(Number(currentObj?.id));
    })
    if (selectedSlotsId?.length === 0) {
      return showAlert('', 'Please select the schedule slots you want to edit.', 'OK');
    }
    else {
      if (autoFillLoader) {
        trackPixelEvent('autofill calendar');
        try {
          const query = {
            id: scheduleDetails?.attributes?.user?.data?.id,
            type: "Fav",
            skip: 0,
            limit: 100,
            lat: scheduleDetails?.attributes?.delivery_location?.data?.attributes?.latitude,
            lang: scheduleDetails?.attributes?.delivery_location?.data?.attributes?.longitude,
            category: mealCategoriesId,
            limit_per_meal: Number(scheduleDetails?.attributes?.limit_per_meal),
            status: "ACTIVE",
            order_type: value,
            public_meal: (scheduleDetails?.attributes?.user?.data?.id !== user?.id) ? true : "All",
            suggested: (scheduleDetails?.attributes?.user?.data?.id !== user?.id) ? true : "All"
          }
          const response = await getUserFavoriteMeal(query);
          trackPixelEvent('Get user fave meal');
          setUserFavMeals(response?.data);
          setShowModalFill(true);
          setAutoFillLoader(false);
        }
        catch (error) {
          setAutoFillLoader(false);
        }
      }
      else {
        // dispatch(getRestaurants(scheduleDetails?.attributes?.user?.data?.id) as any);
        history.push(`/scheduleslotfill?type=${value}`);
      }
    }
  };

  const callData = async (skippingValue = 0, showEmptySlots = selectEmptySlots) => {
    try {
      if (!scheduleId) return false;
      if (!scheduleDetails?.id) {
        const response = await getScheduleById(scheduleId);
        setScheduleDetailsRedux(response?.data?.data);
        trackPixelEvent('Get schedule by id');
      }
      const response = await getScheduleSlots(scheduleId, skippingValue, limit, "");

      if (response) {
        trackPixelEvent('Get schedule slots');
        let tempData = response?.data?.data;
        const filteredData = tempData?.filter((items: any) => new Date(mergeSlotDateTime(items?.attributes?.date, items?.attributes?.time)) > currentDateTime)

        const updatedResponseData = (filteredData)?.map((item: any) => {
          item.attributes["is_selected"] = showEmptySlots;
          return item;
        })

        // Remove Duplicates
        // const ids = upcomingSlots.map(({ id }) => id);
        // const filtered = upcomingSlots.filter(({ id }, index: any) => !ids.includes(id, index + 1));

        // Set new array to state
        if (skippingValue > 0) {
          setScheduleSlotsRedux([...scheduleSlots, ...updatedResponseData]);
          setScheduleSlotsLoaderRedux(false);
        }
        else {
          setScheduleSlotsRedux(updatedResponseData);
          setScheduleSlotsLoaderRedux(false);

          if (tempData?.length === filteredData?.length) {
            setTotalSlots(response?.data?.meta?.pagination?.total);
          }
          else {
            const difference = tempData?.length - filteredData?.length;
            setTotalSlots(response?.data?.meta?.pagination?.total - difference);
          }
        }

        if (showEmptySlots) {
          let tempArray = [];
          tempData?.forEach((currentObj: any) => {
            if (currentObj?.attributes?.favMeal?.data === null) {
              tempArray.push(currentObj?.id)
            }
          });
          setSelectedSlots([...selectedSlotsId, ...tempArray,]);
        }
      }
    }
    catch (e) {
      console.log(e);
    }
  };


  const showMore = () => {
    //setLoading(true);
    const skippingValue = skip + limit
    setSkip(skippingValue)
    callData(skippingValue);
  };


  const handleCheckBox = (value: boolean, id: any, index: number) => {

    const allSlots: any = [...scheduleSlots];
    allSlots[index].attributes["is_selected"] = value;
    setScheduleSlotsRedux(allSlots);

    const filterEmptySlot = allSlots?.filter((el: any) => el?.attributes?.favMeal?.data === null)

    const filteredTempArrayLength: [] = allSlots?.filter((el: any) => el?.attributes?.is_selected === true)
    setSelectEmptySlots((filteredTempArrayLength?.length === filterEmptySlot?.length))

    const list = [...selectedSlotsId];
    if ((selectedSlotsId.indexOf(id) === -1) && (allSlots[index].attributes["is_selected"] === true)) {
      const newData = [id, ...list]
      setSelectedSlots(newData)
    }
    else {
      const i = selectedSlotsId.indexOf(id);
      list.splice(i, 1);
      setSelectedSlots(list);
    }
  };

  const openModalRemove = () => {
    if (selectedSlotsId.length === 0) {
      return showAlert('', 'Please select the schedule slots you want to delete.', 'OK');
    }
    else {
      setShowModalRemove((prev) => !prev);
    }
  };

  const deleteSLots = () => {
    const payloadData = {
      data: {
        selectedSlots: selectedSlotsId
      }
    }
    setSelectedSlots([]);
    try {
      dispatch(removeScheduleSlots(payloadData) as any)
        .then(() => {
          setSelectEmptySlots(false);
          setTotalSlots(totalSlots - selectedSlotsId?.length);
          callData(0, false);
        })
    }
    catch (e) {
      console.log(e);
    }
  };


  const handleCookMyself = async () => {
    if (selectedSlotsId?.length === 0) {
      return showAlert('', 'Please select the schedule slots you want to edit.', 'OK');
    }
    else {
      setShowHomeCookedMeal(prev => !prev);
    }
  };


  const handleSubmitSelfCookedMeal = async () => {
    if (selfCookedDescription === "") {
      setDescriptionError(true);
      return false;
    }
    else {
      setDescriptionError(false);
      const payloadData = {
        data: {
          slots: selectedSlotsId,
          description: selfCookedDescription
        }
      }
      dispatch(selfCookedSlots(payloadData) as any)
        .then(() => {
          setShowHomeCookedMeal((prev: any) => !prev);
          setSelectedSlots([]);
        })
    }
  };


  return (
    <>
      {
        (awaiting === false) ?
          (
            <div className="screen">
              <TopHead
                title="Calendar Delivery"
                leftTextIcon={faAngleLeft}
                leftIconStyle={{ fontSize: "24px" }}
                goToHandle={GotoHandle}
              />
              <Divider />

              <div className="grow overflow-auto">
                <Section>
                  <TextHead1 style={{ fontWeight: "700", marginBottom: "8px" }}>
                    Calendar for {switchSentenceCase("head text", (scheduleDetails?.attributes?.recipientName !== scheduleDetails?.attributes?.senderName ? scheduleDetails?.attributes?.recipientName : scheduleDetails?.attributes?.senderName))}
                  </TextHead1>
                  <SubText1 style={{ fontWeight: "600" }}>{switchSentenceCase("head text", scheduleDetails?.attributes?.name)} (Edit the recurring meal calendar.)</SubText1>
                </Section>

                <Section>
                  <FlexBetween className="mb-3">
                    <Trans>Upcoming Time Slots</Trans>
                    {/* <EmptySlottext className="!font-semibold">SELECT ALL EMPTY</EmptySlottext> */}
                    <OneSideTextToggle
                      className="!font-semibold !mt-5"
                      leftText="SELECT ALL EMPTY"
                      style={{ color: "#22a45d", fontSize: "15px", fontWeight: "600", textAlign: "right" }}
                      switchState={selectEmptySlots}
                      handleOnChangeToggle={handleToggleSelectAll}
                      disabled={filterEmptySlot?.length === scheduleSlots?.length}
                    />
                    {/* <Required>REQUIRED</Required> */}
                  </FlexBetween>
                  <Divider />

                  <WrapChoice>
                    {scheduleSlots?.length > 0 ?
                      scheduleSlots.map((item: any, index: any) => {

                        return (
                          <React.Fragment key={index}>
                            <FlexBetween className="items-center mb-[10px] min-h-[103px]" >
                              <Flex className="items-baseline">
                                <input type="checkbox" className="w-8 accent-green-600 cursor-pointer h-[14px]" id={item?.id} checked={item?.attributes?.is_selected} disabled={item?.attributes?.sponsoredBy?.data !== null} name={item?.id} onChange={(e) => handleCheckBox((!(item?.attributes?.is_selected)), item?.id, index)} />
                                <Block>
                                  <InitialDiv className="flex text-[17px] font-medium my-[5px] items-baseline">
                                    <TextHead4 className="">
                                      {slotDateTimeFormatter(item?.attributes?.date, item?.attributes?.time)} {" "}
                                    </TextHead4>
                                    {item?.attributes?.order_type === "pickup" &&
                                      <TextHead4 className="ml-1">(Pickup)</TextHead4>
                                    }
                                  </InitialDiv>
                                  <span className={`${(item?.attributes?.favMeal?.data !== null || item?.attributes?.self_cooked) ? "text-[16px] text-[#8b8b8b]" : "text-[16px] text-[#c22727]"} `} >
                                    {
                                      (item?.attributes?.self_cooked)
                                        ?
                                        <div className="flex items-baseline">
                                          <FontAwesomeIcon icon={faPanFrying} className="mr-2 text-[#bbd97c]" /><div>(Home-Cooked) <br /> {item?.attributes?.self_cooked_description}</div>
                                        </div>
                                        :
                                        (
                                          (item?.attributes?.favMeal?.data !== null)
                                            ?
                                            <div className="flex items-baseline"><FontAwesomeIcon icon={faPotFood} className="mr-1 text-[#bbd97c]" /><div>({switchSentenceCase("head text", item?.attributes?.favMeal?.data?.attributes?.name)})</div></div>
                                            :
                                            <div className="flex items-baseline"><FontAwesomeIcon icon={faHatChef} className="mr-2 text-[#b3b3b3] text-[14px]" /><div>({switchSentenceCase("head text", "Empty Slot")})</div></div>
                                        )
                                    }
                                  </span>

                                  {(item?.attributes?.favMeal?.data !== null && item?.attributes?.sponsoredBy?.data === null)
                                    ?
                                    <SubText1 className="mt-[2px] ml-[23px] !font-light !text-[#c22727]">
                                      {switchSentenceCase("head text", `Payment authorization required`)}
                                    </SubText1>
                                    :
                                    null}

                                  {
                                    (item?.attributes?.sponsoredBy?.data !== null) &&
                                    <SubText1 className="mt-[2px] ml-[23px] !font-light">
                                      {
                                        (item?.attributes?.sponsoredBy?.data?.id !== user?.id)
                                          ?
                                          switchSentenceCase("head text", `Sponsored By ${(item?.attributes?.sponsoredBy?.data?.attributes?.name !== null) ? (item?.attributes?.sponsoredBy?.data?.attributes?.name) : (item?.attributes?.sponsoredBy?.data?.attributes?.email !== null) ? (item?.attributes?.sponsoredBy?.data?.attributes?.email) : (item?.attributes?.sponsoredBy?.data?.attributes?.phone_no)}`)
                                          :
                                          "Sponsored by You"
                                      }
                                    </SubText1>
                                  }
                                </Block>
                              </Flex>
                              <FontAwesomeIcon icon={faPen} className="text-green-600 text-xs cursor-pointer m-[10px]" onClick={() => { openModalEditSlot(item?.id) }} />
                            </FlexBetween>
                            <Divider />
                          </React.Fragment>
                        )
                      })
                      :
                      <SubText1 className="text-center !text-gray-400 !text-base mb-10">There are no upcoming slots.</SubText1>
                    }
                  </WrapChoice>

                  {(totalSlots > limit) &&
                    (
                      (totalSlots !== scheduleSlots?.length) ?

                        <div
                          className="text-green-600 text-center cursor-pointer"
                          onClick={() => { showMore() }}
                        >
                          See More
                        </div>
                        :
                        <div
                          className="text-green-600 text-center"
                        >
                          No More Slots
                        </div>
                    )
                  }
                </Section>

                <Section>
                  <ButtonGreen
                    onClick={() => handleClickOnOrder("order")}
                    style={{ margin: "15px 0px" }}
                    className={autoFillLoader && "!cursor-not-allowed opacity-30"}
                    disabled={autoFillLoader}
                  >
                    {switchSentenceCase("primary button", "ORDER FOOD FOR SELECTED SLOTS")}
                  </ButtonGreen>

                  {autoFillLoader ?
                    <div className="text-center">
                      <Spinner />
                    </div>
                    :
                    <ButtonLimeGreen
                      style={{ marginBottom: "15px" }}
                      onClick={() => handleClickOnOrder("autofill")}
                    >
                      {switchSentenceCase(
                        "primary button",
                        "AUTOFILL SELECTED WITH FAVES"
                      )}
                    </ButtonLimeGreen>
                  }
                  {(scheduleDetails?.attributes?.user?.data?.id === user?.id || scheduleDetails?.attributes?.from_user?.data?.id === user?.id || scheduleDetails?.attributes?.from_user?.data?.attributes?.slug === user?.slug || scheduleDetails?.attributes?.user?.data?.attributes?.slug === user?.slug)
                    &&
                    <>
                      <ButtonInitial
                        className={autoFillLoader ? "!cursor-not-allowed text-green-600 !border !border-solid !border-green-600 opacity-30" : "text-green-600 !border !border-solid !border-green-600"}
                        style={{ marginBottom: "15px" }}
                        onClick={handleCookMyself}
                        disabled={autoFillLoader}
                      >
                        {switchSentenceCase("primary button", "I’ll Cook for Selected Slots Myself.")}
                      </ButtonInitial>

                      <ButtonGray onClick={() => { openModalRemove() }}
                        className={`${(autoFillLoader) && "!cursor-not-allowed opacity-30"}`}
                        disabled={autoFillLoader}
                      >
                        {switchSentenceCase("primary button", "REMOVE SELECTED SLOTS")}
                      </ButtonGray>
                    </>
                  }
                </Section>

                {/*---------- Modal Center----------- */}
                {showModalFill
                  &&
                  <ModalAutofillConfirm
                    showModal={showModalFill}
                    setShowModal={setShowModalFill}
                    setSelectedSlots={setSelectedSlots}
                    setSelectEmptySlot={setSelectEmptySlots}
                  />
                }

                {/* ---------------------------------Modal Schedule Slot Filled------------------- */}
                {showModalSlotUnFill
                  &&
                  <ModalEditSlots
                    containerStyle={{ height: "unset" }}
                    showModal={showModalSlotUnFill}
                    setShowModal={setShowModalSlotUnFill}
                    setShowModalFill={setShowModalFill}
                    showModalFill={showModalFill}
                    schedule={scheduleDetails}
                  />
                }

                {/* ---------------------------------Modal Confirm Delete Popup------------------- */}
                {showModalRemove
                  &&
                  <ModalRemoved
                    firstText="Are you sure you want to"
                    secondText="remove all selected Slots?"
                    btnOneText="OOPS!  NEVER MIND..."
                    btnTwoText="YES, REMOVE IT"
                    showModal={showModalRemove}
                    setShowModal={setShowModalRemove}
                    deleteItem={deleteSLots}
                    id={selectedSlotsId}
                  />}

                {setShowOptionModal &&
                  <ModalChooseOption
                    showModal={showOptionModal}
                    setShowModal={setShowOptionModal}
                    selectedValue={handleFillSlots}
                  />}

                {/* **********************************Add Slots for Home-Cooked********************************************** */}
                {
                  showHomeCookedMeal
                  &&
                  <InitialModalCenter
                    showModal={showHomeCookedMeal}
                    setShowModal={setShowHomeCookedMeal}
                  >
                    <InitialDiv className="m-4">
                      <Flex className="items-center">
                        <FontAwesomeIcon
                          className="cursor-pointer"
                          icon={faChevronLeft}
                          onClick={() => { setShowHomeCookedMeal((prev: any) => !prev); setDescriptionError(false); }}
                        />
                        <InitialDiv className="w-full text-center">
                          <TextHead3 className="">Add Home-Cooked Meal</TextHead3>
                        </InitialDiv>
                      </Flex>
                    </InitialDiv>
                    <Divider />
                    <InitialDiv className="m-4">
                      <InitialDiv className="text-left">
                        <TextHead3 id="shortNotes" className="mb-[-15px] mt-4">Enter a description of what you’ll prepare.</TextHead3>
                        <SubText1 className="text-right mt-[20px]">{selfCookedDescription?.length}/{maxCharacterLimit}</SubText1>
                        <TextArea1
                          className="!mt-[0px]"
                          name="shortNotes"
                          value={selfCookedDescription}
                          onChange={(e) => setSelfCookedDescription(e.target.value)}
                          style={{
                            border: (descriptionError) ? "1px solid red" : "",
                          }}
                          maxLength={maxCharacterLimit}
                        />
                      </InitialDiv>
                      <InitialDiv>
                        <ButtonGreen onClick={handleSubmitSelfCookedMeal}>{switchSentenceCase("head text", "SUBMIT")}</ButtonGreen>
                      </InitialDiv>

                    </InitialDiv>
                  </InitialModalCenter>
                }
              </div>
            </div >
          )
          :
          <div className="text-center">
            <ModalSpinner />
          </div>
      }
    </>
  );
}
