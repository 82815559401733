import { faAngleLeft } from "@fortawesome/pro-solid-svg-icons";
import { useIonViewDidEnter } from "@ionic/react";
import { SubText1, TextHead1, TextHead2, TextHead4 } from "../../components/styled/styled";
import Divider from "../../components/widgets/divider/Divider";
import TopHead from "../../components/widgets/head/TopHead";
import Section from "../../components/widgets/section/Section";
import { trackPixelEvent } from "../../utils/utilities";


export default function PrivacyPolicy() {
  useIonViewDidEnter(() => {
    trackPixelEvent('Privacy policy');
  }, []);


  return (
    <div className="screen !h-full">
      <TopHead
        title="Privacy Policy"
        leftTextIcon={faAngleLeft}
        leftIconStyle={{ fontSize: "24px" }}
      />
      <Divider />

      <div className="grow overflow-auto">
        <Section>
          <TextHead1 className="text-center" id="top">Website Privacy Policy</TextHead1>
        </Section>
        <Section>
          <SubText1 >Last modified: 4/3/2024</SubText1>
          <TextHead2 className="underline">Introduction</TextHead2>
          <TextHead4 className="!font-normal">
            Meal Fave, LLC <span className="font-medium">(“Company” or “We”)</span> respect your privacy and are committed to protecting it through our compliance with this policy.
          </TextHead4>
          <TextHead4 className="!font-normal mt-6">
            This policy describes the types of information we may collect from you or that you may provide when you visit the website https://www.mealfave.com or MealFave app (our <span className="font-medium">“Website”</span>) and our practices for collecting, using, maintaining, protecting, and disclosing that information.
          </TextHead4>

          <TextHead4 className="!font-normal mt-4">
            This policy applies to information we collect:
          </TextHead4>
          <TextHead4 className="!font-normal mt-4">
            •	On this Website.
          </TextHead4>
          <TextHead4 className="!font-normal mt-4">
            •	In email, text, and other electronic messages between you and this Website.
          </TextHead4>
          <TextHead4 className="!font-normal mt-4">
            •	When you interact with our advertising and applications on third-party websites and services, if those applications or advertising include links to this policy.
          </TextHead4>


          <TextHead4 className="!font-normal mt-4">
            It does not apply to information collected by:
          </TextHead4>
          <TextHead4 className="!font-normal mt-4">
            •	Us offline or through any other means, including on any other website operated by Company or any third party (including our affiliates and subsidiaries); or
          </TextHead4>
          <TextHead4 className="!font-normal mt-4">
            •	Any third party (including our affiliates and subsidiaries), including through any application or content (including advertising) that may link to or be accessible from or through the Website.
          </TextHead4>
          <TextHead4 className="!font-normal mt-4">
            Please read this policy carefully to understand our policies and practices regarding your information and how we will treat it. If you do not agree with our policies and practices, your choice is not to use our Website. By accessing or using this Website, you agree to this privacy policy. This policy may change from time to time (see <span className="text-green-600 underline cursor-pointer" onClick={() => document.getElementById("ChangestoOurPrivacyPolicy").scrollIntoView({ behavior: "smooth" })}>Changes to Our Privacy Policy</span>). Your continued use of this Website after we make changes is deemed to be acceptance of those changes, so please check the policy periodically for updates.
          </TextHead4>
          <TextHead2 className="underline">Children Under the Age of 16</TextHead2>
          <TextHead4 className="!font-normal">
            Our Website is not intended for children under 16 years of age. No one under age 16 may provide any personal information to or on the Website. We do not knowingly collect personal information from children under 16. If you are under 16, do not use or provide any information on this Website or through any of its features, register on the Website, make any purchases through the Website, use any of the interactive or public comment features of this Website, or provide any information about yourself to us, including your name, address, telephone number, email address, or any screen name or user name you may use. If we learn we have collected or received personal information from a child under 16 without verification of parental consent, we will delete that information. If you believe we might have any information from or about a child under 16, please contact us at [CONTACT INFORMATION].
          </TextHead4>
          <TextHead4 className="!font-normal mt-2">
            California residents under 16 years of age may have additional rights regarding the collection and sale of their personal information. Please see  <span className="text-green-600 underline cursor-pointer" onClick={() => document.getElementById("YourStatePrivacyRights").scrollIntoView({ behavior: "smooth" })}>Your State Privacy Rights</span> for more information.
          </TextHead4>
          <TextHead2 className="underline">Information We Collect About You and How We Collect It</TextHead2>
          <TextHead4 className="!font-normal">
            We collect several types of information from and about users of our Website, including information:
          </TextHead4>
          <TextHead4 className="!font-normal mt-4">
            •	By which you may be personally identified, such as name, postal address, email address, telephone number, any other identifier by which you may be contacted online or offline <span className="font-medium">(”personal information”);</span>
          </TextHead4>
          <TextHead4 className="!font-normal mt-4">
            •	That is about you but individually does not identify you; or
          </TextHead4>
          <TextHead4 className="!font-normal mt-4">
            •	About your internet connection, the equipment you use to access our Website, and usage details.
          </TextHead4>
          <TextHead4 className="!font-normal mt-4">
            •	Information on your personal contacts, if you opt to import contacts from third party services like Google, Facebook, or Apple, according to their applicable limited use policies.
          </TextHead4>
          <TextHead4 className="!font-normal mt-4">
            We collect this information:
          </TextHead4>
          <TextHead4 className="!font-normal mt-4">
            •	Directly from you when you provide it to us.
          </TextHead4>
          <TextHead4 className="!font-normal mt-4">
            •	Automatically as you navigate through the site. Information collected automatically may include usage details, IP addresses, and information collected through cookies, web beacons, and other tracking technologies.
          </TextHead4>
          <TextHead4 className="!font-normal mt-4">
            •	From third parties, for example, our business partners.
          </TextHead4>
          <TextHead2 className="underline">Information You Provide to Us</TextHead2>
          <TextHead4 className="!font-normal">
            The information we collect on or through our Website may include:
          </TextHead4>
          <TextHead4 className="!font-normal mt-4">
            •	Information that you provide by filling in forms on our Website. This includes information provided at the time of registering to use our Website, subscribing to our service, posting material, or requesting further services. We may also ask you for information when you enter a contest or promotion sponsored by us, and when you report a problem with our Website.
          </TextHead4>
          <TextHead4 className="!font-normal mt-4">
            •	Records and copies of your correspondence (including email addresses), if you contact us.
          </TextHead4>
          <TextHead4 className="!font-normal mt-4">
            •	Your responses to surveys that we might ask you to complete for research purposes.
          </TextHead4>
          <TextHead4 className="!font-normal mt-4">
            •	Details of transactions you carry out through our Website and of the fulfillment of your orders. You may be required to provide financial information before placing an order through our Website.
          </TextHead4>
          <TextHead4 className="!font-normal mt-4">
            •	Your search queries on the Website.
          </TextHead4>
          <TextHead4 className="!font-normal mt-4">
            •	Information through third party APIs for which you opt-in during use of the Website, like Google, Facebook, or Apple, according to their applicable limited use policies.  Only basic contact information can be read through the APIs. For example, full name, email address, and phone number.
          </TextHead4>
          <TextHead4 className="!font-normal mt-4">
            You also may provide information to be published or displayed (hereinafter, <span className="font-medium">“posted”</span>) on public areas of the Website, or transmitted to other users of the Website or third parties (collectively, <span className="font-medium">“User Contributions”</span>). Your User Contributions are posted on and transmitted to others at your own risk. Although we limit access to certain pages/you may set certain privacy settings for such information by logging into your account profile, please be aware that no security measures are perfect or impenetrable. Additionally, we cannot control the actions of other users of the Website with whom you may choose to share your User Contributions. Therefore, we cannot and do not guarantee that your User Contributions will not be viewed by unauthorized persons.
          </TextHead4>
          <TextHead2 className="underline">Information We Collect Through Automatic Data Collection Technologies</TextHead2>
          <TextHead4 className="!font-normal">
            As you navigate through and interact with our Website, we may use automatic data collection technologies to collect certain information about your equipment, browsing actions, and patterns, including:
          </TextHead4>
          <TextHead4 className="!font-normal mt-4">
            •	Details of your visits to our Website, including traffic data, location data, logs, and other communication data and the resources that you access and use on the Website.
          </TextHead4>
          <TextHead4 className="!font-normal mt-4">
            •	Information about your computer and internet connection, including your IP address, operating system, and browser type.
          </TextHead4>
          <TextHead4 className="!font-normal mt-4">
            We also may use these technologies to collect information about your online activities over time and across third-party websites or other online services (behavioral tracking).
          </TextHead4>
          <TextHead4 className="!font-normal mt-4">
            The information we collect automatically may include personal information, but we may maintain it or associate it with personal information we collect in other ways or receive from third parties. It helps us to improve our Website and to deliver a better and more personalized service, including by enabling us to:
          </TextHead4>
          <TextHead4 className="!font-normal mt-4">
            •	Estimate our audience size and usage patterns.
          </TextHead4>
          <TextHead4 className="!font-normal mt-4">
            •	Store information about your preferences, allowing us to customize our Website according to your individual interests.
          </TextHead4>
          <TextHead4 className="!font-normal mt-4">
            •	Speed up your searches.
          </TextHead4>
          <TextHead4 className="!font-normal mt-4">
            •	Recognize you when you return to our Website.
          </TextHead4>
          <TextHead4 className="!font-normal mt-4">
            The technologies we use for this automatic data collection may include:
          </TextHead4>
          <TextHead4 className="!font-normal mt-4">
            •	<span className="font-medium">Cookies (or browser cookies).</span> A cookie is a small file placed on the hard drive of your computer. You may refuse to accept browser cookies by activating the appropriate setting on your browser. However, if you select this setting you may be unable to access certain parts of our Website. Unless you have adjusted your browser setting so that it will refuse cookies, our system will issue cookies when you direct your browser to our Website.
          </TextHead4>
          <TextHead4 className="!font-normal mt-4">
            •	<span className="font-medium">Web Beacons.</span> Pages of our Website and our emails may contain small electronic files known as web beacons (also referred to as clear gifs, pixel tags, and single-pixel gifs) that permit the Company, for example, to count users who have visited those pages or opened an email and for other related website statistics (for example, recording the popularity of certain website content and verifying system and server integrity).
          </TextHead4>
          <TextHead4 className="!font-normal mt-4">
            •	<span className="font-medium">Flash Cookies.</span> Certain features of our Website may use local stored objects (or Flash cookies) to collect and store information about your preferences and navigation to, from, and on our Website. Flash cookies are not managed by the same browser settings as are used for browser cookies. For information about managing your privacy and security settings for Flash cookies, see  <span className="text-green-600 underline cursor-pointer" onClick={() => document.getElementById("top").scrollIntoView({ behavior: "smooth" })}>Choices About How We Use and Disclose Your Information</span>.
          </TextHead4>
          <TextHead4 className="!font-normal mt-4">
            We do not collect personal information automatically, but we may tie this information to personal information about you that we collect from other sources or you provide to us.
          </TextHead4>
          <TextHead2 className="underline">Third-Party Use of Cookies and Other Tracking Technologies</TextHead2>
          <TextHead4 className="!font-normal">
            Some content or applications, including advertisements, on the Website are served by third-parties, including advertisers, ad networks and servers, content providers, and application providers. These third parties may use cookies alone or in conjunction with web beacons or other tracking technologies to collect information about you when you use our website. The information they collect may be associated with your personal information or they may collect information, including personal information, about your online activities over time and across different websites and other online services. They may use this information to provide you with interest-based (behavioral) advertising or other targeted content.
          </TextHead4>
          <TextHead4 className="!font-normal mt-2">
            We do not control these third parties’ tracking technologies or how they may be used. If you have any questions about an advertisement or other targeted content, you should contact the responsible provider directly. For information about how you can opt out of receiving targeted advertising from many providers, see <span className="text-green-600 underline cursor-pointer" onClick={() => document.getElementById("top").scrollIntoView({ behavior: "smooth" })}>Choices About How We Use and Disclose Your Information</span>.
          </TextHead4>
          <TextHead2 className="!text-black underline">How We Use Your Information</TextHead2>
          <TextHead4 className="!font-normal">
            We use information that we collect about you or that you provide to us, including any personal information:
          </TextHead4>
          <TextHead4 className="!font-normal mt-4">
            •	To present our Website and its contents to you.
          </TextHead4>
          <TextHead4 className="!font-normal mt-4">
            •	To provide you with information, products, or services that you request from us.
          </TextHead4>
          <TextHead4 className="!font-normal mt-4">
            •	To fulfill any other purpose for which you provide it.
          </TextHead4>
          <TextHead4 className="!font-normal mt-4">
            •	To provide you with notices about your account, including expiration and renewal notices.
          </TextHead4>
          <TextHead4 className="!font-normal mt-4">
            •	To carry out our obligations and enforce our rights arising from any contracts entered into between you and us, including for billing and collection.
          </TextHead4>
          <TextHead4 className="!font-normal mt-4">
            •	To notify you about changes to our Website or any products or services we offer or provide though it.
          </TextHead4>
          <TextHead4 className="!font-normal mt-4">
            •	To allow you to participate in interactive features on our Website.
          </TextHead4>
          <TextHead4 className="!font-normal mt-4">
            •	In any other way we may describe when you provide the information.
          </TextHead4>
          <TextHead4 className="!font-normal mt-4">
            •	For any other purpose with your consent.
          </TextHead4>
          <TextHead4 className="!font-normal mt-4">
            MealFave’s use and transfer to any other app of information received from Google APIs will adhere to   <span className="text-green-600 underline cursor-pointer" onClick={() => window.open("https://developers.google.com/terms/api-services-user-data-policy", "_blank")}>Google API Services User Data Policy</span> , including the Limited Use requirements.
          </TextHead4>
          <TextHead4 className="!font-normal mt-4">
            We may also use your information to contact you about our own and third-parties’ goods and services that may be of interest to you. If you do not want us to use your information in this way, please adjust your user preferences in your account profile. For more information, see <span className="text-green-600 underline cursor-pointer" onClick={() => document.getElementById("top").scrollIntoView({ behavior: "smooth" })}>Choices About How We Use and Disclose Your Information.</span>
          </TextHead4>
          <TextHead4 className="!font-normal mt-2">
            We may use the information we have collected from you to enable us to display advertisements to our advertisers’ target audiences. Even though we do not disclose your personal information for these purposes without your consent, if you click on or otherwise interact with an advertisement, the advertiser may assume that you meet its target criteria.]
          </TextHead4>
          <TextHead2 className="underline">Disclosure of Your Information</TextHead2>
          <TextHead4 className="!font-normal">
            We may disclose aggregated information about our users, and information that does not identify any individual, without restriction.
          </TextHead4>
          <TextHead4 className="!font-normal mt-4">
            We may disclose personal information that we collect or you provide as described in this privacy policy:
          </TextHead4>
          <TextHead4 className="!font-normal mt-4">
            •	To our subsidiaries and affiliates.
          </TextHead4>
          <TextHead4 className="!font-normal mt-4">
            •	To contractors, service providers, and other third parties we use to support our business and who are bound by contractual obligations to keep personal information confidential and use it only for the purposes for which we disclose it to them.
          </TextHead4>
          <TextHead4 className="!font-normal mt-4">
            •	To a buyer or other successor in the event of a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of the Company’s assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which personal information held by the Company about our Website users is among the assets transferred.
          </TextHead4>
          <TextHead4 className="!font-normal mt-4">
            •	To third parties to market their products or services to you if you have not opted out of these disclosures. For more information, see <span className="text-green-600 underline cursor-pointer" onClick={() => document.getElementById("top").scrollIntoView({ behavior: "smooth" })}>Choices About How We Use and Disclose Your Information.</span>
          </TextHead4>
          <TextHead4 className="!font-normal mt-4">
            •	To fulfill the purpose for which you provide it. For example, if you give us an email address to use the “email a friend” feature of our Website, we will transmit the contents of that email and your email address to the recipients.
          </TextHead4>
          <TextHead4 className="!font-normal mt-4">
            •	For any other purpose disclosed by us when you provide the information.
          </TextHead4>
          <TextHead4 className="!font-normal mt-4">
            •	With your consent.
          </TextHead4>
          <TextHead4 className="!font-normal mt-4">
            We may also disclose your personal information:
          </TextHead4>
          <TextHead4 className="!font-normal mt-4">
            •	To comply with any court order, law, or legal process, including to respond to any government or regulatory request.
          </TextHead4>
          <TextHead4 className="!font-normal mt-4">
            •	To enforce or apply our terms of use and other agreements, including for billing and collection purposes.
          </TextHead4>
          <TextHead4 className="!font-normal mt-4">
            •	If we believe disclosure is necessary or appropriate to protect the rights, property, or safety of the Company, our customers, or others. This includes exchanging information with other companies and organizations for the purposes of fraud protection and credit risk reduction.
          </TextHead4>
          <TextHead2 className="underline">Choices About How We Use and Disclose Your Information</TextHead2>
          <TextHead4 className="!font-normal">
            We strive to provide you with choices regarding the personal information you provide to us. We have created mechanisms to provide you with the following control over your information:
          </TextHead4>
          <TextHead4 className="!font-normal mt-4">
            •	<span className="font-medium">Tracking Technologies and Advertising.</span> You can set your browser to refuse all or some browser cookies, or to alert you when cookies are being sent. To learn how you can manage your Flash cookie settings, visit the Flash player settings page on Adobe’s <span className="text-green-600 underline cursor-pointer" onClick={() => window.open("http://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager07.html", "_blank")}>website</span>. If you disable or refuse cookies, please note that some parts of this site may then be inaccessible or not function properly.
          </TextHead4>
          <TextHead4 className="!font-normal mt-4">
            •	<span className="font-medium">Disclosure of Your Information for Third-Party Advertising.</span> If you do not want us to share your personal information with unaffiliated or non-agent third parties for promotional purposes, you can opt-out by checking the relevant box located on the form on which we collect your data (e.g., the registration form). You can also always opt-out by logging into the Website and adjusting your user preferences in your account profile, checking or unchecking the relevant boxes or by sending us an email with your request to support@mealfave.com.
          </TextHead4>
          <TextHead4 className="!font-normal mt-4">
            •	<span className="font-medium">Promotional Offers from the Company. </span> If you do not wish to have your information used by the Company to promote our own or third parties’ products or services, you can opt-out by checking the relevant box located on the form on which we collect your data (the registration form) or at any other time by logging into the Website and adjusting your user preferences in your account profile by checking or unchecking the relevant boxes or by sending us an email stating your request to support@mealfave.com. If we have sent you a promotional email, you may send us a return email asking to be omitted from future email distributions. This opt out does not apply to information provided to the Company as a result of a product purchase, warranty registration, product service experience or other transactions.
          </TextHead4>
          <TextHead4 className="!font-normal mt-4">
            •	<span className="font-medium">Targeted Advertising.</span> If you do not want us to use information that we collect or that you provide to us to deliver advertisements according to our advertisers’ target-audience preferences, you can opt-out by sending us an email stating your request to support@mealfave.com.
          </TextHead4>
          <TextHead4 className="!font-normal mt-4">
            We do not control third parties’ collection or use of your information to serve interest-based advertising. However these third parties may provide you with ways to choose not to have your information collected or used in this way. You can opt out of receiving targeted ads from members of the Network Advertising Initiative <span className="font-medium">(”NAI”)</span> on the NAI’s <span className="text-green-600 underline cursor-pointer" onClick={() => window.open("https://thenai.org/opt-out/", "_blank")}>website.</span>
          </TextHead4>
          <TextHead4 className="!font-normal mt-2">
            Residents of certain states, such as California, Nevada, Colorado, Connecticut, Virginia, and Utah may have additional personal information rights and choices. Please see  <span className="text-green-600 underline cursor-pointer" onClick={() => document.getElementById("YourStatePrivacyRights").scrollIntoView({ behavior: "smooth" })}>Your State Privacy Rights</span> for more information.
          </TextHead4>
          <TextHead2 className="underline">Accessing and Correcting Your Information</TextHead2>
          <TextHead4 className="!font-normal">
            You can review and change your personal information by logging into the Website and visiting your account profile page.
          </TextHead4>
          <TextHead4 className="!font-normal mt-2">
            You may also send us an email at support@mealfave.com to request access to, correct or delete any personal information that you have provided to us. We cannot delete your personal information except by also deleting your user account. We may not accommodate a request to change information if we believe the change would violate any law or legal requirement or cause the information to be incorrect.
          </TextHead4>
          <TextHead4 className="!font-normal mt-2">
            If you delete your User Contributions from the Website, copies of your User Contributions may remain viewable in cached and archived pages, or might have been copied or stored by other Website users. Proper access and use of information provided on the Website, including User Contributions, is governed by our terms of use.
          </TextHead4>
          <TextHead4 className="!font-normal mt-2">
            Residents of certain states, such as California, Nevada, Colorado, Virginia, and Utah may have additional personal information rights and choices. Please see  <span className="text-green-600 underline cursor-pointer" onClick={() => document.getElementById("YourStatePrivacyRights").scrollIntoView({ behavior: "smooth" })}>Your State Privacy Rights</span> for more information.
          </TextHead4>
          <TextHead2 className="underline" id="YourStatePrivacyRights">Your State Privacy Rights</TextHead2>
          <TextHead4 className="!font-normal">
            Colorado, Connecticut, Virginia, and Utah each provide their state residents with rights to:
          </TextHead4>
          <TextHead4 className="!font-normal mt-4">
            •	Confirm whether we process their personal information.
          </TextHead4>
          <TextHead4 className="!font-normal mt-4">
            •	Access and delete certain personal information.
          </TextHead4>
          <TextHead4 className="!font-normal mt-4">
            •	Data portability.
          </TextHead4>
          <TextHead4 className="!font-normal mt-4">
            •	Opt-out of personal data processing for targeted advertising and sales.
          </TextHead4>
          <TextHead4 className="!font-normal mt-4">
            Colorado, Connecticut, and Virginia also provide their state residents with rights to:
          </TextHead4>
          <TextHead4 className="!font-normal mt-4">
            •	Correct inaccuracies in their personal information, taking into account the information’s nature processing purpose.
          </TextHead4>
          <TextHead4 className="!font-normal mt-4">
            •	Opt-out of profiling in furtherance of decisions that produce legal or similarly significant effects.
          </TextHead4>
          <TextHead4 className="!font-normal mt-4">
            To exercise any of these rights please send us an email at support@mealfave.com. To appeal a decision regarding a consumer rights request send us an email at support@mealfave.com and reference the adverse decision.
          </TextHead4>
          <TextHead4 className="!font-normal mt-2">
            Nevada provides its residents with a limited right to opt-out of certain personal information sales. Residents who wish to exercise this sale opt-out rights may submit a request to this designated address: support@mealfave.com. However, please know we do not currently sell data triggering that statute’s opt-out requirements.
          </TextHead4>

          <TextHead2 className="underline">EU GDPR Privacy Rights</TextHead2>
          <TextHead4 className="!font-normal">
            The right to access – you have the right to request from the Company copies of your personal data. We may charge you a small fee for this service.
          </TextHead4>
          <TextHead4 className="!font-normal mt-2">
            The right to rectification – You have the right to request that the Company correct any information you believe is inaccurate. You also have the right to request that the Company complete information that you believe is incomplete.
          </TextHead4>
          <TextHead4 className="!font-normal mt-2">
            The right to erasure – You have the right to request that the Company erase your personal data, under certain circumstances.
          </TextHead4>
          <TextHead4 className="!font-normal mt-2">
            The right to restrict processing – You have the right to request that the Company restrict the processing of your personal data, under certain conditions.
          </TextHead4>
          <TextHead4 className="!font-normal mt-2">
            The right to object to processing – You have the right to object to the Company’s processing of your personal data, under certain conditions.
          </TextHead4>
          <TextHead4 className="!font-normal mt-2">
            The right to data portability – You have the right to request that the Company transfer the data that We have collected to another organization, or directly to you, under certain conditions.
          </TextHead4>
          <TextHead4 className="!font-normal mt-2">
            If you make a request, We have one month to respond to you. If you would like to exercise any of these rights, please contact us using the contact information found at the end of this Privacy Policy.
          </TextHead4>
          <TextHead2 className="underline">Data Security</TextHead2>
          <TextHead4 className="!font-normal">
            We have implemented measures designed to secure your personal information from accidental loss and from unauthorized access, use, alteration, and disclosure. All information you provide to us is stored on our secure servers behind firewalls. Any payment transactions will be encrypted using SSL technology.
          </TextHead4>
          <TextHead4 className="!font-normal mt-2">
            The safety and security of your information also depends on you. Where we have given you (or where you have chosen) a password for access to certain parts of our Website, you are responsible for keeping this password confidential. We ask you not to share your password with anyone. We urge you to be careful about giving out information in public areas of the Website like message boards. The information you share in public areas may be viewed by any user of the Website.
          </TextHead4>
          <TextHead4 className="!font-normal mt-2">
            Unfortunately, the transmission of information via the internet is not completely secure. Although we do our best to protect your personal information, we cannot guarantee the security of your personal information transmitted to our Website. Any transmission of personal information is at your own risk. We are not responsible for circumvention of any privacy settings or security measures contained on the Website.
          </TextHead4>
          <TextHead2 className="underline" id="ChangestoOurPrivacyPolicy">Changes to Our Privacy Policy</TextHead2>
          <TextHead4 className="!font-normal">
            It is our policy to post any changes we make to our privacy policy on this page. If we make material changes to how we treat our users’ personal information, we will notify you by email to the primary email address specified in your account or through a notice on the Website home page. The date the privacy policy was last revised is identified at the top of the page. You are responsible for ensuring we have an up-to-date active and deliverable email address for you, and for periodically visiting our Website and this privacy policy to check for any changes.
          </TextHead4>
          <TextHead2 className="underline">Contact Information</TextHead2>
          <TextHead4 className="!font-normal">
            To ask questions or comment about this privacy policy and our privacy practices, contact us at:
          </TextHead4>
          <TextHead4 className="!font-normal mt-4">
            support@mealfave.com
          </TextHead4>
        </Section>
      </div>
    </div >
  );
}