import {
  faAngleDown,
  faAngleRight,
  faCircleXmark
} from "@fortawesome/pro-regular-svg-icons";
import { faMinus, faPlus } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import styled from "styled-components";
import sampleMealImage from "../../components/assets/Meal.jpg";
import {
  ButtonGreen,
  SubText1,
  TextArea1,
  TextHead1,
  TextHead5
} from "../../components/styled/styled";
import FeatureImage from "../../components/widgets/FeatureImage/FeatureImage";
import RadioButtonText from "../../components/widgets/buttons/RadioButtonText";
import { goBackPage, maxCharacterLimit, priceCalculator, properNameFormate, switchSentenceCase } from "../../utils/utilities";
import {
  addFavsMealItem,
  changeQuantity,
} from "./redux/menuItemActionCreatior";

const Container = styled.div`
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 0.7rem;
  text-align: left;
  background-color: white;
`;

const QuantityDiv = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  margin: 30px;
  font-weight: 600;
  font-size: 20px;
`;

const ChoiceHead = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Trans = styled.div`
  font-size: 18px;
  font-weight: 500;
`;

const TransDiv = styled.div`
  padding: 10px 0px 20px 8px;
  font-size: 17px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
`;

// const Required = styled.button`
//   padding: 5px;
//   color: #217346;
//   background: #21734633;
//   border-radius: 5px;
//   float: right;
// `;

const WrapChoice = styled.div`
  padding: 5px;
`;

// const HeartModalIcon = styled.div`
//   padding: 5px;
//   background: #f03f3f29;
//   border-radius: 40px;
//   text-align: center;
//   height: -webkit-fit-content;
//   font-size: 22px;
//   color: #ff000082;
//   width: 45px;
//   margin-top: 5px;
//   margin-left: 10px;
// `;

export default function ProductDetail() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [singleProduct, setSingleProduct] = useState<any>({});
  const [showPromoInput, setShowPromoInput] = useState(true);
  const { menuDataList, mealId } = useSelector((state: any) => state.getRestaurantsMenuLists);

  const { searchedRestaurants } = useSelector((state: any) => state.getUserSearchedRestaurants);
  const storeId = searchedRestaurants?.id || "";
  const _menuDataList = menuDataList?.filter((currentObj: any) => currentObj?.quoteIds === storeId)[0] || [];

  const [inputList, setInputList] = useState([]);
  const [instructions, setInstructions] = useState("");
  const [errorMsg, setErrorMsg] = useState(false);


  const inputInstructions = (val: string) => {
    setInstructions(val);
  }

  const matchedData = () => {
    let dataList: any;

    for (const item in _menuDataList?.data || {}) {
      dataList = _menuDataList?.data[item].findIndex((list: any) => list?.product_id === mealId);

      if (dataList >= 0) {
        const mainData = _menuDataList?.data[item][dataList];
        setSingleProduct(mainData);
        const customizations: any = mainData?.customizations;

        const data = [];
        for (let index = 0; index < customizations?.length; index++) {
          data.push({ customization_id: customizations[index]?.customization_id, customization_name: customizations[index]?.name, option_id: customizations[index]?.options[0].option_id, option_name: customizations[index]?.options[0].name, option_price: customizations[index]?.options[0].price })
        }
        setInputList(data)
        break;
      }
    }
  }


  useEffect(() => {
    matchedData();
  }, []);

  // const oneProduct
  const onAddItemToFavMeal = () => {
    const favItemMeal = [{
      name: singleProduct?.name,
      product_id: singleProduct?.product_id,
      quantity: singleProduct?.quantity,
      price: priceCalculator(singleProduct?.price, inputList),
      customizationList: inputList,
      instructions: instructions,
    }];


    if (favItemMeal[0]?.quantity === 0) {
      setErrorMsg(true);
    } else {

      dispatch(addFavsMealItem(favItemMeal, "add", 0) as any);

      history.push({
        pathname: "/newmealfave",
      });
    }

  };


  const handleOptionChange = (e: any, mainIndex: number, option_name: string, option_price: number) => {
    const list: any = [...inputList];
    list[mainIndex].customization_id = e?.customization_id;
    list[mainIndex].option_id = e?.value;
    list[mainIndex].option_name = option_name;
    list[mainIndex].option_price = option_price;
    setInputList(list);
  };


  return (
    <>
      <div className="relative screen">
        <div className="overflow-y-auto">
          <FontAwesomeIcon
            icon={faCircleXmark}
            className="back-chevron text-white z-10 p-1.5 rounded-20px w-5"
            // onClick={() =>
            //   history.push({
            //     pathname: "/restaurant",
            //   })
            // }
            onClick={() => goBackPage()}
          />

          {/* <FontAwesomeIcon
            icon={faCircleInfo}
            className="info-circle text-white z-10 p-1.5 rounded-20px w-5"
          /> */}

          <FeatureImage img={singleProduct?.image || sampleMealImage} />

          <Container
            style={{
              position: "sticky",
              top: "0",
              zIndex: "10",
            }}
          >
            <ChoiceHead>
              <TextHead1>{properNameFormate(singleProduct?.name)}</TextHead1>
              {/* <HeartModalIcon>
                <FontAwesomeIcon icon={faHeart} />
              </HeartModalIcon> */}
            </ChoiceHead>

            <h6 className="text-gray-500 max-w-xs underline mt-2">
              {singleProduct?.restaurantName}
            </h6>
            <h6 className="text-gray-500  mb-2">
              {singleProduct?.description}
            </h6>

            {/* <div className="flex text-gray-400 max-w-xs justify-between">
              <h6>$$</h6><DotSeprator />
              <h6>Chinese</h6><DotSeprator />
              <h6>American</h6><DotSeprator />
              <h6>Deshi food</h6>
            </div> */}

          </Container>
          <Container>
            {errorMsg ?
              <TextHead5 className="text-red-600 text-center">Please Add at least 1 Quantity !</TextHead5> :
              null
            }
            <QuantityDiv>
              <FontAwesomeIcon
                icon={faMinus}
                style={{
                  padding: " 20px",
                  background: "#9797971B",
                  borderRadius: "30px",
                  cursor: "pointer",
                  marginRight: "8px",
                }}
                onClick={() =>
                  singleProduct.quantity >= 1 &&
                  dispatch(
                    changeQuantity(
                      storeId,
                      singleProduct?.product_id,
                      singleProduct?.quantity - 1
                    ) as any
                  )
                }
              />
              <span
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "row",
                }}
              >
                Quantity {singleProduct?.quantity}
              </span>

              <FontAwesomeIcon
                icon={faPlus}
                style={{
                  padding: " 20px",
                  background: "#9797971B",
                  borderRadius: "30px",
                  cursor: "pointer",
                  marginLeft: "8px",
                }}
                onClick={() =>
                  dispatch(
                    changeQuantity(
                      storeId,
                      singleProduct?.product_id,
                      singleProduct?.quantity + 1
                    ) as any
                  )
                }
              />

            </QuantityDiv>

            {singleProduct?.customizations?.map((coustomises: any, mainIndex: any) => {

              return (
                <div key={mainIndex}>
                  <ChoiceHead>
                    <Trans>{coustomises?.name}</Trans>
                  </ChoiceHead>

                  <WrapChoice>
                    {coustomises?.options?.map((type: any, index: any) => {

                      return (
                        <RadioButtonText
                          key={index}
                          index={index}
                          label={type?.name}
                          checked={type?.name}
                          value={inputList[mainIndex]?.option_name}
                          name={coustomises?.customization_id}
                          option={type}
                          onSelect={() => {
                            handleOptionChange({ customization_id: coustomises?.customization_id, value: type?.option_id }, mainIndex, type?.name, type?.price)
                          }}
                        />
                      );
                    })}
                  </WrapChoice>
                </div>
              );
            })}

            <TransDiv onClick={() => setShowPromoInput((prev) => !prev)}>
              Add Special Instructions
              <FontAwesomeIcon
                icon={showPromoInput ? faAngleRight : faAngleDown}
                style={{ float: "right" }}
              />
            </TransDiv>
            {
              showPromoInput === false ? (
                <>
                  <SubText1 className="text-right mt-[20px]">{instructions?.length}/{maxCharacterLimit}</SubText1>
                  <TextArea1
                    value={instructions}
                    style={{ marginBottom: "20px", marginTop: "0px" }}
                    placeholder="Enter Special Instructions"
                    onChange={(e: any) => inputInstructions(e?.target?.value)}
                    maxLength={maxCharacterLimit}
                  ></TextArea1>
                </>
              )
                :
                null
            }

            <ButtonGreen
              style={{ margin: "15px 0px" }}
              onClick={() => onAddItemToFavMeal()}
            >
              {switchSentenceCase("primary button", "ADD ITEM TO A FAVE MEAL")}

            </ButtonGreen>

            {/* <ButtonLimeGreen

              onClick={() =>
                history.push({
                  pathname: '/removefavemeal',
                })
              }>REMOVE ITEM FROM A FAVE MEAL<i className="fa-regular fa-angle-right float-right mt-1"></i></ButtonLimeGreen> */}

          </Container>
        </div>
      </div>
    </>
  );
}
