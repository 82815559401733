import Section from "../../components/widgets/section/Section";
import { ButtonLimeGreen, InitialDiv, SubText1, SubText2, TextHead3 } from "../../components/styled/styled";
import { useHistory, useLocation } from "react-router";
import { useCallback, useEffect, useState } from "react";
import { getUserData } from "../../services/user";
import { switchSentenceCase, trackPixelEvent } from "../../utils/utilities";
import Divider from "../../components/widgets/divider/Divider";
import mealFaveLogo from "../../components/assets/onboarding/logo.png";
import { useIonRouter, useIonViewDidEnter } from "@ionic/react";
import store from '../../redux/store'
import SignInWithPhone from "./SignInWithPhone";
import SignInWithEmail from "./SignInWithEmail";
import SignInWithGoogle from "./SignInWithGoogle";
import SignInWithMeta from "./SignInWithMeta";
import SigninWithApple from "./SignInWithApple";
import { isPlatform } from '@ionic/react';
import { useSelector } from "react-redux";


export default function SignUpIn() {
  interface LocationStateProps {
    loginType?: boolean; // loginType is a boolean, change it to the correct type if different from verification page
  }

  const history = useHistory();
  const router = useIonRouter();
  const pageRoute = router.routeInfo?.search;

  const location = useLocation<LocationStateProps>();
  const { state } = location;
  const [changeLoginType, setChangeLoginType] = useState<boolean>(state?.loginType || false);
  const { loginState } = useSelector((state: any) => state.sessionAlertState);

  //***************** CHECK USER SESSION *****************//
  const checkUserLoggedIn = useCallback(async () => {
    if (pageRoute !== "") {
      store.dispatch({
        type: "SET_ROUTE",
        payload: pageRoute
      });
    }

    const userData = await getUserData();

    if (userData?.jwt != null) {
      if (pageRoute !== "?for=manage-order") {
        history.push("/manage-orders?for_me");
      }
      history.push("/home");
    }
  }, [pageRoute, history]);


  useIonViewDidEnter(() => {
    trackPixelEvent('Login');
  }, []);


  useEffect(() => {
    checkUserLoggedIn();
  }, [checkUserLoggedIn]);


  return (
    <>
      <InitialDiv className="flex flex-col justify-between h-full overflow-auto">
        <Section>
          <Section>
            <TextHead3 className="text-center">Log In / Create Account</TextHead3>
          </Section>
          <Section>
            <div className="bg-[#22a45d] w-[180px] ml-auto mr-auto rounded-[110px] p-[28px]">
              <img src={mealFaveLogo} alt="Send Meal" className="mx-auto logo-animate rounded-[22px]" width={120} height={120} />
            </div>
            <div className="text-below-image text-center">
              <SubText2 className="subtext text-center text-gray-400 my-1 !text-[18px]">Log in to save your favorites, gift meals, and create meal plans.</SubText2>
            </div>
          </Section>
          {changeLoginType
            ?
            <SignInWithEmail inputTypeValue={state || ""} loginState={loginState} />
            :
            <SignInWithPhone inputTypeValue={state || ""} loginState={loginState} />
          }
          <SubText1 className="!font-bold text-center">OR</SubText1>
          <InitialDiv className="mx-4">
            <ButtonLimeGreen
              className="mt-[15px]"
              disabled={loginState}
              onClick={() => setChangeLoginType((prev) => !prev)}
            >
              {switchSentenceCase("primary button", changeLoginType ? "SIGN IN WITH PHONE" : "SIGN IN WITH EMAIL")}
            </ButtonLimeGreen>
          </InitialDiv>
          {
            process.env.REACT_APP_GOOGLE_ENABLED === "false" && process.env.REACT_APP_FACEBOOK_ENABLED === "false"
              ? ''
              : (
                <InitialDiv className="ml-3 mr-3">
                  {
                    process.env.REACT_APP_GOOGLE_ENABLED === "false"
                      ? ''
                      : <SignInWithGoogle loginState={loginState} />
                  }
                  {
                    process.env.REACT_APP_FACEBOOK_ENABLED === "false"
                      ? ''
                      : <SignInWithMeta loginState={loginState} />
                  }
                  {
                    isPlatform('android') || process.env.REACT_APP_APPLE_ENABLED === "false"
                      ? ''
                      : <SigninWithApple loginState={loginState} />
                  }
                </InitialDiv>
              )
          }
        </Section>
        <InitialDiv className="mb-4 mx-4">
          <Divider />
          <SubText1 className="text-center  mt-4">By continuing, you agree to MealFave's Terms & Conditions and Privacy Policy. Also, you may receive a one-time verification code and delivery notifications via text message to the phone number on your account. Message and data rates may apply.</SubText1>
          <SubText1 className="text-center"><span className="text-green-500 underline cursor-pointer" onClick={() => history.push({ pathname: '/termsandconditions' })}>Terms & Conditions</span> and <span className="text-green-500 underline cursor-pointer" onClick={() => history.push({ pathname: '/privacypolicy' })}>Privacy Policy</span>.</SubText1>
        </InitialDiv>
      </InitialDiv>
    </>
  );
}

