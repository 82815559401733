import { objectType } from '../type';
import { apiRequestService } from './apiRequestService';
import { getUserData } from './user';

const API_URL = process.env.REACT_APP_API_URL


function getById(ids: any) {
    let query = "";
    for (let i = 0; i < ids?.length; i++) {
        query += `${"&filters[id][$in][" + i + "] =" + ids[i]}`
    }
    return query;
};


export const getUserFavoriteMeal = async (query: objectType) => {
    try {
        const user = await getUserData();
        let filterConfig = {
            method: 'get',
            url: API_URL + `/v1/fav-meals/filter?filters[user]=${query?.id}&filters[type]=${query?.type}&pagination[start]=${query?.skip || 0}&pagination[limit]=${query?.limit || 10}${(query?.lat && query?.lang) ? "&filters[latitude]=" + query?.lat + "&filters[longitude]=" + query?.lang : ""}${(query?.category !== undefined) ? "&filters[meal_category]=" + JSON.stringify(query?.category) : ""}${(query?.suggested !== undefined && query?.suggested !== "All") ? "&filters[suggested_meal]=" + query?.suggested : ""}${(query?.limit_per_meal !== 0 && query?.limit_per_meal !== undefined) ? ("&filters[limit_per_meal]=" + query?.limit_per_meal) : ""}${(query?.order_type !== undefined) ? ("&filters[order_type]=" + query?.order_type) : ""}${(query?.status !== undefined) ? ("&filters[status]=" + query?.status) : ""}${(query?.public_meal !== undefined && query?.public_meal !== "All") ? ("&filters[public_meal]=" + query?.public_meal) : ""}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + user?.jwt
            },
        };
        const response = (await apiRequestService(filterConfig));

        if (response?.data?.ids?.length > 0) {
            let config = {
                method: 'get',
                url: API_URL + `/v1/fav-meals?populate[favItems][populate][product][populate][restaurant]=*&populate[restaurant]=*&populate[meal_category]=*&populate[user]=*&populate[address]=*${getById(response?.data?.ids)}&sort=id:desc`,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + user?.jwt
                },
            };
            const request = await apiRequestService(config);
            return ({ data: request?.data.data, total: response?.data?.total });
        }
    } catch (error) {
        console.log(error);
    }
};

export const getFavesCount = async (query: objectType) => {
    try {
        const user = await getUserData()
        let config = {
            method: 'get',
            url: API_URL + `/v1/fav-meals?filters[user]=${query?.id}${(query?.type === "Both") ? "&filters[type][0]=Fav&filters[type][1]=Order" : "&filters[type]=" + query?.type}&pagination[start]=${query?.skip || 0}&pagination[limit]=${query?.limit || 10}${(query?.storeId !== undefined) ? "&filters[restaurant][mealme_id][$eq]=" + query?.storeId : ""}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + user?.jwt
            },
        };
        const request = apiRequestService(config);
        return request;
    } catch (error) {
        console.log(error);
    }
}


export const getMealCategory = async (id: any, query: objectType) => {
    try {
        const user = await getUserData()
        let config = {
            method: 'get',
            url: API_URL + `/v1/meal-categories?sort=defaultMeal:desc&populate[favMeals]=*&filters[user][id][$eq]=${id}${(query?.suggested !== "All") ? ("&filters[suggestedMeal]=" + query?.suggested) : ""}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + user?.jwt
            },
        };
        const request = apiRequestService(config);
        return request;
    } catch (error) {
        console.log(error);
    }
};

export const deleteSingleFavItem = async (id: number) => {
    try {
        const user = await getUserData();
        let config = {
            method: 'delete',
            url: API_URL + `/v1/fav-meals/${id}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + user?.jwt
            },
        };
        const request = apiRequestService(config);
        return request;
    } catch (error) {
        console.log(error);
    }
}

export const userFavMeal = async (id: any) => {
    try {
        const user = await getUserData();

        let config = {
            method: 'GET',
            url: API_URL + `/v1/fav-meals/?filters[user][id][$eq]=${id}&filters[type][$eq]=Fav&filters[public][$eq]=true&populate[favItems][populate][product]=*&populate[restaurant]=*&populate[meal_category]=*&populate[user]=*&populate[address]=*`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + user?.jwt
            },
        };
        const request = apiRequestService(config);
        return request;
    } catch (error) {
        console.log(error);
    }
}

// Create New Fav Items
export const createNewFavItem = async (requestData: objectType) => {
    try {
        const user = await getUserData()
        const data = JSON.stringify({
            "data": {
                "name": requestData?.name,
                "user": user?.user?.id,
                "meal_category": requestData?.meal_category,
                "favItems": requestData?.favItems,
                "public_meal": requestData?.public_meal,
                "total": requestData?.total_price,
                "drop_off_notes": requestData?.notes,
                "address": requestData?.address
            }
        });
        let config = {
            method: 'post',
            url: API_URL + `/v1/bulk/fav-meals/`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + user?.jwt
            },
            data: data
        };
        const request = apiRequestService(config);
        return request;
    } catch (error) {
        console.log(error);
    }
}

// Update Existing Fav Items
export const updateExistingFavItem = async (requestData: objectType) => {
    try {
        const user = await getUserData();
        const data = JSON.stringify({
            "data": {
                "user": user?.user?.id,
                "favItems": requestData?.favItems,
                "favMeals": requestData?.favMeals,
                "total_price": Number(requestData?.total_price),
                "replaceItemId": requestData?.replaceItemId
            }
        });
        let config = {
            method: 'put',
            url: API_URL + `/v1/existing/fav-meals/`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + user?.jwt
            },
            data: data
        };
        const request = apiRequestService(config);
        return request;
    } catch (error) {
        console.log(error);
    }
}

// Update Meal Category Fav Items
export const updateMealCategoryFavItem = async (updateData: objectType, id: any) => {
    try {
        const user = await getUserData()
        let config = {
            method: 'put',
            url: API_URL + `/v1/fav-meals/${id}?populate[favItems][populate][product]=*&populate[restaurant]=*&populate[meal_category]=*&populate[user]=*&populate[address]=*`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + user?.jwt
            },
            data: updateData
        };
        const request = apiRequestService(config);
        return request;
    } catch (error) {
        console.log(error);
    }
}

// Update Fav Items
export const updateFavItem = async (requestData: objectType) => {
    try {
        const user = await getUserData()
        const data = JSON.stringify({
            "data": requestData
        });
        let config = {
            method: 'put',
            url: API_URL + `/v1/bulk/fav-meals/`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + user?.jwt
            },
            data: data
        };
        const request = apiRequestService(config);
        return request;
    } catch (error) {
        console.log(error);
    }
}


// Faves Add New Category
export const faveAddNewCategory = async (requestData: objectType) => {
    try {
        const user = await getUserData()
        const categoryData = {
            data: {
                name: requestData?.categoryName,
                user: user?.user?.id,
                suggestedMeal: requestData?.suggestedMeal,
                defaultMeal: requestData?.defaultMealState,
                favMeals: requestData?.categoryId,
            }
        }
        let config = {
            method: 'POST',
            url: API_URL + `/v1/bulk/meal-categories`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + user?.jwt
            },
            data: categoryData
        };
        const request = apiRequestService(config);
        return request;
    } catch (error) {
        console.log(error);
    }
}

export const deleteSingleCategory = async (id: any) => {
    try {
        const user = await getUserData()
        let config = {
            method: 'delete',
            url: API_URL + `/v1/meal-categories/${id}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + user?.jwt
            },
        };
        const request = apiRequestService(config);
        return request;
    } catch (error) {
        console.log(error);
    }
}

export const updateCategory = async (id: any, updatedData: objectType) => {
    try {
        const user = await getUserData()
        const data = JSON.stringify({
            "data": {
                "id": id,
                "name": updatedData?.name,
                "user": user?.user?.id,
                "suggestedMeal": updatedData?.suggestedMeal,
                "defaultMeal": updatedData?.defaultMeal,
                "favMeals": updatedData?.favMeals
            }
        });
        let config = {
            method: 'PUT',
            url: API_URL + `/v1/bulk/meal-categories`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + user?.jwt
            },
            data: data
        };
        const request = apiRequestService(config);
        return request;
    } catch (error) {
        console.log(error);
    }
}
