import axios from 'axios';
import { getLocationFromLatLong } from '../utils/utilities';
import { apiRequestService } from './apiRequestService';
import store from '../redux/store';
import { getUserData } from './user';
import { objectType } from '../type';

const API_URL = process.env.REACT_APP_API_URL

export async function setSelectedRestaurantData(data: any) {
    localStorage.setItem("RestaurantData", JSON.stringify(data));
}

export function getSelectedRestaurantData() {
    return JSON.parse(localStorage.getItem("RestaurantData"));
}

export async function restaurantsNearPlace(queryData: any) {
    try {
        const stateRedux: any = store.getState();
        const filters = stateRedux?.getUserRestaurantsLists?.searchFilter;
        const { lat, lang, streetNum, streetName, city, state, zip, country, nextPage, limit, orderType } = queryData;
        const addressFromLatLong = await getLocationFromLatLong({ latitude: lat, longitude: lang })

        // To API
        const locationData = {
            pickup: (orderType === "pickup"),
            latitude: lat,
            longitude: lang,
            user_street_num: (streetNum && streetNum !== undefined) ? streetNum : addressFromLatLong?.street_number,
            user_street_name: (streetName && streetName !== undefined) ? streetName : addressFromLatLong?.route,
            user_city: (city && city !== undefined) ? city : addressFromLatLong?.locality,
            user_state: (state && state !== undefined) ? state : addressFromLatLong?.administrative_area_level_1,
            user_zipcode: (zip && zip !== undefined) ? zip : addressFromLatLong?.postal_code,
            user_country: (country && country !== undefined) ? country : addressFromLatLong?.country,
            page: nextPage,
            maximum_miles: "10",
            budget: limit,
            ...filters,
        }
        const serialize = (params: any) => {
            var str = [];
            for (var p in params)
                if (params.hasOwnProperty(p)) {
                    if (params[p] != null && params[p] !== "")
                        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(params[p]));
                }
            return str.join("&");
        }

        let userData = await getUserData();
        let token = userData.jwt;

        let config = {
            method: 'GET',
            url: `${process.env.REACT_APP_API_URL}/v1/restaurant/mealme?${serialize(locationData)}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
        };
        return apiRequestService(config);
    }
    catch (e) {
        throw e
    }
}


let isRequestInProgress = false;
export async function restaurantsMenus(queryData: any) {

    if (isRequestInProgress) {
        console.log("API request already in progress, please wait.");
        return ({ data: { loading: true } });
    }

    isRequestInProgress = true;

    try {
        // Query Data
        const { store_id, user_latitude, user_longitude, user_street_num, user_street_name, user_city, user_state, user_zipcode, user_country, orderType } = queryData;
        const addressFromLatLong = await getLocationFromLatLong({ latitude: user_latitude, longitude: user_longitude })
        // To API
        const locationData = {
            store_id,
            pickup: (orderType === "pickup"),
            user_latitude,
            user_longitude,
            user_street_num: (user_street_num && user_street_num !== undefined) ? user_street_num : addressFromLatLong?.street_number,
            user_street_name: (user_street_name && user_street_name !== undefined) ? user_street_name : addressFromLatLong?.route,
            user_city: (user_city && user_city !== undefined) ? user_city : addressFromLatLong?.locality,
            user_state: (user_state && user_state !== undefined) ? user_state : addressFromLatLong?.administrative_area_level_1,
            user_zipcode: (user_zipcode && user_zipcode !== undefined) ? user_zipcode : addressFromLatLong?.postal_code,
            user_country: (user_country && user_country !== undefined) ? user_country : addressFromLatLong?.country,
        }
        const queryString = Object.keys(locationData).map((key: any) => {
            return `${key}=${encodeURIComponent(locationData[key])}`;
        }).join('&')

        let config = {
            method: 'GET',
            url: `${API_URL}/v1/restaurant/mealme/menu?${queryString}`,
            headers: {
                'Content-Type': 'application/json',
            },
        };
        return apiRequestService(config);
    }
    catch (e) {
        throw e
    } finally {
        isRequestInProgress = false;
    }
}


export const getProductCustomizations = async (productId: string, queryString: objectType) => {
    try {
        const query = Object.keys(queryString).map((key: any) => {
            return `${key}=${encodeURIComponent(queryString[key])}`;
        }).join('&')

        let config = {
            method: 'get',
            url: `${API_URL}/v1/restaurant/mealme/product/${productId}?${query}`,
            headers: {
                'Content-Type': 'application/json'
            },
        };
        const request = axios(config);
        return request;
    } catch (error) {
        console.log(error);
    }
}

export async function getRestaurantSchedules(storeId: string, type: string) {
    try {
        const user = await getUserData();

        let config = {
            method: 'GET',
            url: `${API_URL}/v1/restaurant/schedules/${storeId}?order_type=${type}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + user?.jwt
            },
        };
        return apiRequestService(config);
    }
    catch (e) {
        throw e
    }
}
//Get Restaurant By MealMe ID
export const getRestaurantById = async (id: any) => {
    try {
        const user = await getUserData()
        let config = {
            method: 'GET',
            url: API_URL + `/v1/restaurants/?filters[mealme_id]=${id}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + user?.jwt
            },
        };
        const request = apiRequestService(config);
        return request;

    } catch (error) {
        console.log(error);
    }
};