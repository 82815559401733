import store from "../../../../redux/store";
// export const setUserSearchLocation = (searchData: any) => (dispatch: any) => {

//     try {
//         dispatch(
//             {
//                 type: "SET_USER_SEARCH_LOCATION",
//                 payload: searchData
//             })
//     } catch (error) {
//         console.log(error);
//     }
// };

export const setUserSearchLocation = (searchData: any) => {

    try {
        store.dispatch({
            type: "SET_USER_SEARCH_LOCATION",
            payload: searchData
        })

    } catch (error) {
        console.log(error);
    }
};

export const getLocationName = (areaName: any) => (dispatch: any) => {
    try {
        dispatch(
            {
                type: "LOCATION_NAME",
                payload: areaName
            })
    } catch (error) {
        console.log(error);

    }
};

export const clearLocation = () => (dispatch: any) => {
    try {
        dispatch(
            {
                type: "CLEAR",
            })
    } catch (error) {
        console.log(error);
    }
};