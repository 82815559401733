import React, { ReactNode } from "react";

export default function FeatureImage({
  img,
  children,
  filter,
}: {
  img: string | null;
  children?: ReactNode;
  filter?: string;
}) {
  if (img == null) {
    return (
      <div className="relative" style={{ width: "100%", height: 180, backgroundColor: "white" }}>
        {children}
      </div>
    );
  }
  return (
    <div className="relative" style={{ width: "100%", height: 180, backgroundColor: "white" }} id="restaurant_meal_category">
      <div
        style={{
          backgroundImage: `url("${img}")`,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          filter,
        }}
      >
        {children}
      </div>
    </div>
  );
}
