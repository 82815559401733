const initialValue = {
    restaurants: [],
    restaurantsLoading: false,
};

const autocompleteSearch = (state = initialValue, action: any) => {

    switch (action?.type) {

        case "GET_RESTAURANTS":
            return { ...state, restaurants: action.payload };

        case "SET_RESTAURANTS_LOADING":
            return { ...state, restaurantsLoading: action.payload };

        default:
            return state;
    }
};

export default autocompleteSearch;