import { createStore, applyMiddleware } from 'redux'
import rootReducer from './allReducer';
import { composeWithDevTools } from 'redux-devtools-extension';

/* Instruments */
import thunk from 'redux-thunk';



const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)));


export default store;