import { useState } from "react";
import Divider from "../../components/widgets/divider/Divider";
import Section from "../../components/widgets/section/Section";
// import FavoriteMealList from "../profile/FavoriteMealList";
import OneSideTextToggle from "../../components/widgets/buttons/OneSideTextToggle";
import ItemQtyCompo from "./ItemQtyCompo";
import TopHead from "../../components/widgets/head/TopHead";
import { useHistory } from "react-router";
import { faAngleDown, faAngleLeft } from "@fortawesome/pro-solid-svg-icons";




export default function Faves() {


  const history = useHistory();
  const [faveMealsState, setFaveMealsState] = useState(true);


  const GotoHandle = () => {
    history.push('/productdetail');
  }

  function FaveMealsState() {
    setFaveMealsState(faveMealsState === false);
  }

  return (
    <div className="screen">

      <TopHead
        title="Remove from Fave Meals"
        leftTextIcon={faAngleLeft}
        leftIconStyle={{ fontSize: "24px" }}
        rightTextIcon={faAngleDown}
        rightIconStyle={{ marginLeft: "4px" }}
        rightText="Filter"
        goToHandle={GotoHandle}
      />
      <Divider />

      <div className="grow overflow-auto">
        <Section>
          <OneSideTextToggle leftText="My Fave Meals" style={{ fontWeight: "500" }} beforeBtnText="Select All" switchState={faveMealsState} handleOnChangeToggle={FaveMealsState} />
          <Divider />

          <ItemQtyCompo leftText="Cookie Sandwich Meal" style={{ paddingLeft: "15px" }} itemCount="1" product_Id={""}/>
          <Divider />

          <ItemQtyCompo leftText="Chow Fun Meal" style={{ paddingLeft: "15px" }} itemCount="0" product_Id={""}/>
          <Divider />

          <ItemQtyCompo leftText="Dim Sum Meal" style={{ paddingLeft: "15px" }} itemCount="0" product_Id={""}/>
          <Divider />

        </Section>
      </div>
    </div >
  );
}