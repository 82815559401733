import { apiRequestService } from './apiRequestService';
import { getUserData } from './user';
import moment from "moment";
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL

export async function setCalendarData(data: any) {
    localStorage.setItem("CalendarData", JSON.stringify(data));
}

export function getCalendarData() {
    return JSON.parse(localStorage.getItem("CalendarData"));
}

// Add New Schedule
export const addNewSchedule = async (requestData: any) => {
    try {
        const user = await getUserData()

        let config = {
            method: 'POST',
            url: API_URL + `/v1/bulk/schedules`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + user?.jwt
            },
            data: requestData
        };
        const request = apiRequestService(config);
        return request;
    } catch (error) {
        console.log(error);
    }
}

export const getSchedules = async (id: any, skip: any, limit: any, type: any) => {
    try {
        const currentDate = moment().format('YYYY-MM-DD HH:mm:ss');
        const user = await getUserData();

        let url = API_URL + `/v1/schedules?filters[end_date][$gte]=${currentDate}`;

        if (type === "For_Me") { url += `&filters[user][id][$eq]=${id}`; } else if (type === "For_Other") { url += `&filters[from_user][id][$eq]=${id}&filters[type][$eq]=for_other`; }
        url += `&populate[invitations]=*&populate[delivery_location]=*&sort[0]=createdAt:desc&populate[slots][populate][favMeal][populate][favItems]=*&populate[slots][populate][sponsoredBy]=*&populate[user]=*&populate[meal_categories]=*&pagination[start]=${skip || 0}&pagination[limit]=${limit || 100}`;

        let config = {
            method: 'get',
            url: url,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + user?.jwt
            },
        };

        const request = apiRequestService(config);
        return request;
    } catch (error) {
        console.log(error);
    }
};



// Get Schedules
export const getScheduleBySlug = async (slug: any) => {
    try {
        const user = await getUserData();

        let config = {
            method: 'get',
            url: API_URL + `/v1/schedules?filters[slug][$eq]=${slug}&populate[invitations]=*&populate[delivery_location]=*&populate[slots][populate][favMeal]=*&populate[meal_categories]=*&populate[user][fields][0]=name&populate[user][fields][1]=slug&populate[from_user][fields][0]=slug&populate[from_user][fields][1]=name`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + user?.jwt
            },
        };
        const request = apiRequestService(config);
        return request;
    } catch (error) {
        console.log(error);
    }
}

//Get Schedule Details By Id
export const getScheduleById = async (id: any) => {
    try {
        const user = await getUserData()

        let config = {
            method: 'get',
            url: API_URL + `/v1/schedules/${id}?populate[invitations]=*&populate[delivery_location]=*&populate[slots][populate][favMeal]=*&populate[meal_categories]=*&populate[user][fields][0]=name&populate[user][fields][1]=slug&populate[from_user][fields][0]=slug&populate[from_user][fields][1]=name`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + user?.jwt
            },
        };
        const request = apiRequestService(config);
        return request;
    } catch (error) {
        console.log(error);
    }
}

// Get Invitation By Slug
export const getInvitation = async (token: any) => {
    try {
        const user = await getUserData()

        let config = {
            method: 'get',
            url: API_URL + `/v1/schedule-invitations?filters[token][$eq]=${token}&populate[user]=*&populate[schedule]=*`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + user?.jwt
            },
        };
        const request = apiRequestService(config);
        return request;
    } catch (error) {
        console.log(error);
    }
}


// Add New Schedule Invitation
export const addNewInvitation = async (requestData: any) => {
    try {
        const user = await getUserData()

        let config = {
            method: 'POST',
            url: API_URL + `/v1/create/schedule-invitations`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + user?.jwt
            },
            data: requestData
        };
        const request = apiRequestService(config);
        return request;
    } catch (error) {
        console.log(error);
    }
}

//Get invitation By Id
export const getInvitationById = async (id: any) => {
    try {
        const user = await getUserData()

        let config = {
            method: 'get',
            url: API_URL + `/v1/schedule-invitations/${id}?populate[user]=*&populate[schedule]=*`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + user?.jwt
            },
        };
        const request = apiRequestService(config);
        return request;
    } catch (error) {
        console.log(error);
    }
}

// Remove Schedule Invitation
export const deleteInvitation = async (id: any) => {
    try {
        const user = await getUserData()

        let config = {
            method: 'delete',
            url: API_URL + `/v1/schedule-invitations/${id}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + user?.jwt
            },
        };
        const request = apiRequestService(config);
        return request;
    } catch (error) {
        console.log(error);
    }
}

// Update Schedule Data
export const updateSchedule = async (id: any, requestData: any) => {
    try {
        const user = await getUserData()

        let config = {
            method: 'put',
            url: API_URL + `/v1/schedules/${id}?populate[invitations]=*&populate[delivery_location]=*&populate[slots]=*&populate[user]=*&populate[meal_categories]=*`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + user?.jwt
            },
            data: requestData
        };
        const request = apiRequestService(config);
        return request;
    } catch (error) {
        console.log(error);
    }
}

//Get Schedule Slots By Schedule Id
export const getScheduleSlots = async (id: any, skip: any, limit: any, showEmpty: any) => {
    try {
        const user = await getUserData();
        //&populate[favMeal][populate][restaurant]=*&
        const currentDate = moment().format('YYYY-MM-DD');
        let config = {
            method: 'get',
            url: API_URL + `/v1/schedule-slots/?filters[schedule][id][$eq]=${id}&populate[delivery_location]=*&populate[favMeal][populate][0]=favItems.product&populate[favMeal][populate][1]=restaurant&populate[sponsoredBy]=*&populate[schedule]=*&populate[user]=*&pagination[start]=${skip || 0}&pagination[limit]=${limit || 10}&sort[0]=date&sort[1]=time&filters[date][$gte]=${currentDate}&${(showEmpty !== "") && (showEmpty ? ("filters[favMeal][id][$null]=" + showEmpty) : ("filters[favMeal][id][$null]=" + showEmpty))}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + user?.jwt
            },
        };
        const request = apiRequestService(config);
        return request;
    } catch (error) {
        console.log(error);
    }
}

export const checkCalendarAuthentication = async (id: any, schedule: any) => {
    try {
        const user = await getUserData();
        let config = {
            method: 'get',
            url: API_URL + `/v1/check-authentication/schedule/${id}?filters[schedule]=${schedule}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + user?.jwt
            },
        };
        const request = apiRequestService(config);
        return request;
        // const request = axios(config);
        // const dataPromise = request.then((response) => response);
        // return dataPromise;
    } catch (error) {
        console.log(error);
    }
}

// Update Slot Data
export const updateSlot = async (id: any, requestData: any) => {
    try {
        const user = await getUserData()
        //populate[favMeal][populate][favItems][populate][product]=*&populate[favMeal][populate][restaurant]=*
        let config = {
            method: 'put',
            url: API_URL + `/v1/schedule-slots/${id}?&populate[favMeal][populate][0]=favItems.product&populate[favMeal][populate][1]=restaurant&populate[sponsoredBy]=*&populate[schedule]=*&populate[delivery_location]=*&populate[user]=*`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + user?.jwt
            },
            data: requestData
        };
        const request = apiRequestService(config);
        return request;
    } catch (error) {
        console.log(error);
    }
}

// Remove Multiple Slots
export const deleteSlots = async (requestData: any) => {
    try {
        const user = await getUserData()

        let config = {
            method: 'POST',
            url: API_URL + `/v1/custom/delete-slots`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + user?.jwt
            },
            data: requestData
        };
        const request = apiRequestService(config);
        return request;
    } catch (error) {
        console.log(error);
    }
}

// Remove single slot
export const removeSlot = async (id: any) => {
    try {
        const user = await getUserData()

        let config = {
            method: 'delete',
            url: API_URL + `/v1/schedule-slots/${id}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + user?.jwt
            },
        };
        const request = apiRequestService(config);
        return request;
    } catch (error) {
        console.log(error);
    }
}

function filterMealCategory(ids: any) {

    let query = "";

    for (let i = 0; i < ids?.length; i++) {
        query += `${"&filters[meal_category][id][$in][" + i + "] =" + ids[i]}`
    }

    return query;
}

//Get User Fav Meal
export const getFavMeal = async (priceLimit: any, id: any, mealCategoriesId: any) => {
    // const user = await getUserData();
    try {

        let config = {
            method: 'get',
            url: API_URL + `/v1/fav-meals?populate[favItems][0]=*&populate[favItems][populate][product][populate][restaurant]=*&populate[user]=*&populate[restaurant]=*&filters[user][id][$eq]=${id}&filters[type][$eq]=Fav&sort[0]=id:desc${(priceLimit !== 0) ? ("&filters[total][$lte]=" + priceLimit) : ""}${(mealCategoriesId?.length > 0) ? filterMealCategory(mealCategoriesId) : ""}`,
            headers: {
                'Content-Type': 'application/json'
            },
        };
        const request = axios(config);
        return request;
    } catch (error) {
        console.log(error);
    }
}

// Decline Meal Schedule
export const declineSchedule = async (requestData: any) => {
    try {
        const user = await getUserData()

        let config = {
            method: 'put',
            url: API_URL + `/v1/decline/schedule-invitation`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + user?.jwt
            },
            data: requestData
        };
        const request = apiRequestService(config);
        return request;
    } catch (error) {
        console.log(error);
    }
}

// Decline Recipient Calendar
export const declineCalendar = async (id: any) => {
    try {
        const user = await getUserData()

        let config = {
            method: 'delete',
            url: API_URL + `/v1/decline-calendar/${id}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + user?.jwt
            },
        };
        const request = apiRequestService(config);
        return request;
    } catch (error) {
        console.log(error);
    }
}

// Remove Schedule
export const deleteSchedule = async (id: any) => {
    try {
        const user = await getUserData()

        let config = {
            method: 'delete',
            url: API_URL + `/v1/custom-delete/schedules/${id}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + user?.jwt
            },
        };
        const request = apiRequestService(config);
        return request;
    } catch (error) {
        console.log(error);
    }
}

// Add New Recipient
export const addNewRecipient = async (requestData: any) => {
    try {
        const user = await getUserData()

        let config = {
            method: 'POST',
            url: API_URL + `/v1/add-recipient/schedules`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + user?.jwt
            },
            data: requestData
        };
        const request = apiRequestService(config);
        return request;
    } catch (error) {
        console.log(error);
    }
}


