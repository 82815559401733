import { faAngleLeft } from "@fortawesome/pro-solid-svg-icons";
import { useIonViewDidEnter } from "@ionic/react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import styled from "styled-components";
import { SubText1, TextHead4 } from "../../components/styled/styled";
import AccordianCartegoryChild from "../../components/widgets/accordian/AccordianCartegoryChild";
import Accordion, {
  SubAccordion,
} from "../../components/widgets/accordian/AccordionStyled";
import AddressList from "../../components/widgets/address/AddressList";
import BothSideTextToggle from "../../components/widgets/buttons/BothSideTextToggle";
import RestaurantCardGrid from "../../components/widgets/cards/RestaurantCardGrid";
import RestaurantCardList from "../../components/widgets/cards/RestaurantCardList";
import Divider from "../../components/widgets/divider/Divider";
import TopHead from "../../components/widgets/head/TopHead";
import SearchRestaurantsByKeywords from "../../components/widgets/searchinput/SearchRestaurantsByKeywords";
import Section from "../../components/widgets/section/Section";
import Spinner from "../../components/widgets/spinner/Spinner";
import { getMealCategory, getUserFavoriteMeal } from "../../services/favourite";
import { restaurantsNearPlace } from "../../services/restaurant";
import { getUserInfo } from "../../services/user";
import {
  switchSentenceCase,
  trackPixelEvent,
  trackStandardPixelEvent,
} from "../../utils/utilities";
import PreferredMealGrid from "../orderFood/PreferredMealGrid";
import PreferredMealList from "../orderFood/PreferredMealList";
import { setOrderForSomeone } from "../orders/redux/upcomingOrderActionCreator";
import FavoriteMealGrid from "../profile/FavoriteMealGrid";
import FavoriteMealList from "../profile/FavoriteMealList";
import {
  setSelectedLocation,
  setUserDeliveryAddress,
} from "../profile/redux/locationActionCreator";
import { scheduleDetailsClear } from "../schedules/redux/createScheduleReduxActions";
import {
  clearRestaurantList,
  setNearByRestaurants,
  setOrderType,
  setRestaurantsListLoading,
  setSearchFilterRedux,
} from "../search/redux/searchActionCreator";
import {
  setFaveModalStatus,
  setRecentOrderList,
  setUserFaveMealList,
  setUserMealCategory,
} from "./redux/favouriteActionCreator";
// import ReactPullToRefresh from 'react-pull-to-refresh';

// ************************* Dynamic styling using styled components ************************************************
const Text = styled.div`
  padding: 10px 0px;
  border-top: 1px solid #8080801f;
  border-bottom: 1px solid #8080801f;
  cursor: pointer;
`;

export default function Faves(data: any) {
  // ******************************* Other Methods **************************************
  const dispatch = useDispatch();
  const history = useHistory();
  const userData = getUserInfo();

  // ******************************* States Management **********************************************
  const {
    mealCategory,
    userFaveMealsList,
    recentOrderList,
    faveMealModalStatus,
  } = useSelector((state: any) => state.getAllFavData);
  const { nearByRestaurants, isResultLoading, orderType } = useSelector(
    (state: any) => state.getUserRestaurantsLists
  );
  const { userDeliveryAddress, selectedLocationId } = useSelector(
    (state: any) => state.userAddresses
  );
  const { orderForSomeone } = useSelector(
    (state: any) => state?.upcomingOrderState
  );

  //*********************** Hooks ********************************************
  const restaurantData = nearByRestaurants?.slice(0, 10);
  const [switchState, setSwitchState] = useState(true);
  const [faveMealLoading, setFaveMealLoading] = useState(true);
  const [isRefreshed, setIsRefreshed] = useState(false);
  const [orderTypeToggle, setOrderTypeToggle] = useState(
    orderType === "pickup" ? true : false
  );

  const [recentOrderLoading, setRecentOrderLoading] = useState(true);
  const [nearbyRestaurantLoading, setNearbyRestaurantLoading] = useState(
    Object.keys(userDeliveryAddress).length === 0 ? true : false
  );
  const formattedAddress = userDeliveryAddress?.city
    ? (userDeliveryAddress?.streetNum
        ? userDeliveryAddress?.streetNum + ", "
        : "") +
      userDeliveryAddress?.streetName +
      ", " +
      userDeliveryAddress?.city +
      ", " +
      userDeliveryAddress?.state +
      " - " +
      userDeliveryAddress?.zip
    : "";

  // ********************** First time Rendering *************************************************
  useEffect(() => {
    setFaveMealLoading(true);
    setFaveModalStatus(false);
    setRecentOrderLoading(true);
    dispatch(scheduleDetailsClear() as any);
    setSearchFilterRedux({});
    (async () => {
      try {
        let query = {
          suggested: "All",
        };
        const response = await getMealCategory(userData?.id, query);
        setUserMealCategory(response?.data?.data);
      } catch (e) {
        console.log(e);
      }
    })();
    if (orderType === "") {
      setOrderType(orderTypeToggle === false ? "delivery" : "pickup");
    }
    if (
      (orderForSomeone?.addressId !== null &&
        orderForSomeone?.addressId !== undefined) ||
      (orderForSomeone?.recipientId !== userData?.id &&
        orderForSomeone?.addressId === null)
    ) {
      setUserDeliveryAddress({});
      setNearByRestaurants([]);
      setUserFaveMealList([]);
      setRecentOrderList([]);
      setOrderForSomeone({});
      setSelectedLocation("");
    }
  }, [isRefreshed, orderTypeToggle]);

  useIonViewDidEnter(() => {
    trackPixelEvent("Fave");
  }, []);

  // ************************* Functions ****************************************
  const isAddressTrue = userDeliveryAddress?.id !== undefined ? true : false;

  useEffect(() => {
    setFaveMealLoading(true);
    setRecentOrderLoading(true);

    (async () => {
      if (
        userDeliveryAddress?.city === undefined ||
        userDeliveryAddress?.city === ""
      ) {
        setFaveMealLoading(false);
        setRecentOrderLoading(false);
        return false;
      }
      const response = await getUserFavoriteMeal({
        id: userData?.id,
        type: "Fav",
        skip: 0,
        limit: 10,
        lat: userDeliveryAddress?.lat,
        lang: userDeliveryAddress?.lang,
        order_type: orderTypeToggle === true ? "pickup" : "delivery",
      });
      trackPixelEvent("Get user fave meal");
      setUserFaveMealList(response?.data);
      setFaveMealLoading(false);

      const order_response = await getUserFavoriteMeal({
        id: userData?.id,
        type: "Order",
        skip: 0,
        limit: 10,
        lat: userDeliveryAddress?.lat,
        lang: userDeliveryAddress?.lang,
        order_type: orderTypeToggle === true ? "pickup" : "delivery",
      });
      trackPixelEvent("Get user fave meal");
      if (order_response !== undefined) {
        setRecentOrderList(order_response?.data);
      } else {
        setRecentOrderList([]);
      }
      setRecentOrderLoading(false);
    })();
    searchFunction();
  }, [userDeliveryAddress, isRefreshed, orderTypeToggle]);

  // function handleRefresh(event: CustomEvent<RefresherEventDetail>) {
  //   setTimeout(() => {
  //     setIsRefreshed(prev => !prev);
  //     setNearByRestaurants([]);
  //     event.detail.complete();
  //   }, 2000);
  // }

  // const handleRefresh = (): Promise<void> => {
  //   return new Promise((resolve) => {
  //     setTimeout(() => {
  //       setIsRefreshed(prev => !prev);
  //       setNearByRestaurants([]);
  //       setFaveModalStatus(false);
  //       resolve();
  //     }, 2000);
  //   });
  // };

  const searchFunction = async () => {
    setNearbyRestaurantLoading(true);
    if (
      userDeliveryAddress?.city === "" ||
      userDeliveryAddress?.city === undefined ||
      nearByRestaurants?.length > 0
    ) {
      setNearbyRestaurantLoading(false);
      return false;
    }
    try {
      const placeObject = {
        lat: userDeliveryAddress.lat,
        lang: userDeliveryAddress?.lang,
        streetName: userDeliveryAddress?.streetName,
        streetNum: userDeliveryAddress?.streetNum,
        streetAddress: userDeliveryAddress?.streetAddress,
        city: userDeliveryAddress?.city,
        state: userDeliveryAddress?.state,
        country: userDeliveryAddress?.country,
        zip: userDeliveryAddress?.zip,
        orderType: orderTypeToggle === true ? "pickup" : "delivery",
      };

      const response = await restaurantsNearPlace(placeObject);
      if (response) {
        trackStandardPixelEvent("Search");
        const restaurantData = response?.data?.data?.stores;

        // -----------Filtering for Duplicate Data---------------
        const filteredData = Array?.from(
          new Set(restaurantData?.map((a: any) => a?.name))
        ).map((name: any) => {
          return restaurantData?.find((a: any) =>
            String(name)
              ?.toLowerCase()
              ?.includes(String(a?.name)?.toLowerCase())
          );
        });

        dispatch(setRestaurantsListLoading(false) as any);
        setNearByRestaurants(filteredData);
        setNearbyRestaurantLoading(false);
      }
    } catch (error) {
      dispatch(setRestaurantsListLoading(false) as any);
      setNearbyRestaurantLoading(false);
    }
  };

  function handleOnChangeToggle() {
    setSwitchState(switchState === false);
  }

  function handleOnChangeOrderType() {
    setFaveModalStatus(false);
    setNearByRestaurants([]);
    setOrderTypeToggle(orderTypeToggle === false);
    setOrderType(orderTypeToggle === false ? "pickup" : "delivery");
    history.push(
      `/faves?type=${orderTypeToggle === false ? "pickup" : "delivery"}`
    );
  }

  return (
    <div className="screen">
      <>
        <TopHead
          title="My Faves"
          leftTextIcon={faAngleLeft}
          leftIconStyle={{ fontSize: "24px" }}
          rightText={"\u00A0" + " "}
        />
        <Divider />

        <div className="grow overflow-auto">
          {/* <IonContent has-bouncing={false} forceOverscroll={false}> */}
          {/* <IonRefresher slot="fixed" onIonRefresh={handleRefresh}>
              <IonRefresherContent></IonRefresherContent>
            </IonRefresher> */}

          {/* <ReactPullToRefresh
            onRefresh={handleRefresh}
            className="pull-refresh"
            style={{ textAlign: 'center' }}
            resistance={5}
            distanceToRefresh={100}
            icon={<span className="genericon genericon-next"></span>}
          > */}
          <BothSideTextToggle
            leftText="Delivery"
            rightText="Pickup"
            switchState={orderTypeToggle}
            id="1"
            handleOnChangeToggle={handleOnChangeOrderType}
          />
          <Section className="!mt-[-10px]">
            <AddressList
              userAddress={formattedAddress}
              selectedId={
                (orderForSomeone?.addressId !== null &&
                  orderForSomeone?.addressId !== undefined) ||
                (orderForSomeone?.recipientId !== userData?.id &&
                  orderForSomeone?.addressId === null)
                  ? null
                  : selectedLocationId
                  ? selectedLocationId
                  : null
              }
              // selectedId={null}
              userId={userData?.id}
              isAddressAvailable={isAddressTrue}
              showList={true}
              type={orderTypeToggle === true ? "pickup" : "delivery"}
            />
            {isAddressTrue ? (
              <Accordion title="Search for New Faves">
                <SearchRestaurantsByKeywords
                  pageRoute="?for=faves"
                  goToSearch={true}
                  type={orderTypeToggle === true ? "pickup" : "delivery"}
                />
              </Accordion>
            ) : null}

            <BothSideTextToggle
              leftText="List"
              rightText="Grid"
              switchState={switchState}
              id="2"
              handleOnChangeToggle={handleOnChangeToggle}
            />

            {switchState === false ? (
              <>
                <Accordion title="My Fave Meals">
                  {faveMealLoading && (
                    <div className="flex items-center justify-center">
                      <Spinner />
                    </div>
                  )}
                  {!faveMealLoading && (
                    <>
                      {userFaveMealsList?.length > 0 ? (
                        <>
                          <FavoriteMealList
                            // header={null}
                            data={userFaveMealsList}
                            type={
                              orderTypeToggle === true ? "pickup" : "delivery"
                            }
                          />
                          <TextHead4
                            className="flex justify-center text-center text-green-600 mt-2"
                            onClick={() =>
                              history.push(
                                `/favesallitems/for-faves?type=${
                                  orderTypeToggle === true
                                    ? "pickup"
                                    : "delivery"
                                }`
                              )
                            }
                          >
                            <div className="cursor-pointer w-[80px]">
                              See all
                            </div>
                          </TextHead4>
                        </>
                      ) : (
                        <SubText1 className="text-center !text-gray-400 !text-base mb-10">
                          You haven’t created any Faves.
                          <span
                            className="cursor-pointer !text-[#22a45d]"
                            onClick={() =>
                              history.push(
                                `/search?for=faves&type=${
                                  orderTypeToggle === true
                                    ? "pickup"
                                    : "delivery"
                                }`
                              )
                            }
                          >
                            {" "}
                            Click here
                          </span>{" "}
                          to make your first.
                        </SubText1>
                      )}
                    </>
                  )}
                </Accordion>
              </>
            ) : (
              <>
                <AccordianCartegoryChild
                  text={{
                    categoryName: "My Fave Meals",
                    seeMore:
                      userFaveMealsList?.length > 0 && !faveMealLoading
                        ? "See all"
                        : "",
                  }}
                  handleOnClick={() =>
                    history.push(
                      `/favesallitems/for-faves?type=${
                        orderTypeToggle === true ? "pickup" : "delivery"
                      }`
                    )
                  }
                />

                {faveMealLoading && (
                  <div className="flex items-center justify-center">
                    <Spinner />
                  </div>
                )}

                {!faveMealLoading && (
                  <>
                    {userFaveMealsList?.length > 0 ? (
                      <FavoriteMealGrid
                        // header={null}
                        data={userFaveMealsList}
                        type={orderTypeToggle === true ? "pickup" : "delivery"}
                      />
                    ) : (
                      <SubText1 className="text-center !text-gray-400 !text-base mb-10">
                        You haven’t created any Faves.
                        <span
                          className="cursor-pointer !text-[#22a45d]"
                          onClick={() =>
                            history.push(
                              `/search?for=faves&type=${
                                orderTypeToggle === true ? "pickup" : "delivery"
                              }`
                            )
                          }
                        >
                          {" "}
                          Click here
                        </span>{" "}
                        to make your first.
                      </SubText1>
                    )}
                  </>
                )}
              </>
            )}

            {switchState === false ? (
              <>
                <Accordion title="Nearby Restaurants">
                  {nearbyRestaurantLoading ||
                    (isResultLoading && (
                      <div className="flex items-center justify-center">
                        <Spinner />
                      </div>
                    ))}
                  {!nearbyRestaurantLoading && !isResultLoading && (
                    <>
                      {nearByRestaurants?.length > 0 ? (
                        !faveMealModalStatus && (
                          <>
                            <RestaurantCardList
                              data={restaurantData}
                              page="for-fave"
                              type={
                                orderTypeToggle === true ? "pickup" : "delivery"
                              }
                            />
                            <TextHead4
                              className="flex justify-center text-center text-green-600 mt-2"
                              onClick={() => {
                                history.push(
                                  `/restaurantallitems/for-fave?type=${
                                    orderTypeToggle === true
                                      ? "pickup"
                                      : "delivery"
                                  }`
                                );
                                clearRestaurantList();
                              }}
                            >
                              <div className="cursor-pointer w-[80px]">
                                See all
                              </div>
                            </TextHead4>
                          </>
                        )
                      ) : (
                        <SubText1 className="text-center !text-gray-400 !text-base mb-10">
                          No restaurants are within the distance and search
                          criteria.
                        </SubText1>
                      )}
                    </>
                  )}
                </Accordion>
              </>
            ) : (
              <>
                <AccordianCartegoryChild
                  text={{
                    categoryName: "Nearby Restaurants",
                    seeMore:
                      !nearbyRestaurantLoading &&
                      !isResultLoading &&
                      nearByRestaurants?.length > 0
                        ? "See all"
                        : "",
                  }}
                  handleOnClick={() => {
                    history.push(
                      `/restaurantallitems/for-fave?type=${
                        orderTypeToggle === true ? "pickup" : "delivery"
                      }`
                    );
                    clearRestaurantList();
                  }}
                />
                {(nearbyRestaurantLoading || isResultLoading) && (
                  <div className="flex items-center justify-center">
                    <Spinner />
                  </div>
                )}

                {!nearbyRestaurantLoading && !isResultLoading && (
                  <>
                    {nearByRestaurants?.length > 0 ? (
                      !faveMealModalStatus && (
                        <RestaurantCardGrid
                          data={restaurantData}
                          page="for-fave"
                          type={
                            orderTypeToggle === true ? "pickup" : "delivery"
                          }
                        />
                      )
                    ) : (
                      <SubText1 className="text-center !text-gray-400 !text-base mb-10">
                        No restaurants are within the distance and search
                        criteria.
                      </SubText1>
                    )}
                  </>
                )}
              </>
            )}

            <Accordion title="Suggested Meals">
              <Divider />
              {switchState === false ? (
                <SubAccordion title="Recent Orders">
                  {recentOrderLoading && (
                    <div className="flex items-center justify-center">
                      <Spinner />
                    </div>
                  )}

                  {!recentOrderLoading && (
                    <>
                      {recentOrderList?.length > 0 ? (
                        !faveMealModalStatus && (
                          <>
                            <PreferredMealList
                              // header={null}
                              data={recentOrderList}
                              type={
                                orderTypeToggle === true ? "pickup" : "delivery"
                              }
                            />
                            <TextHead4
                              className="flex justify-center text-center text-green-600 mt-2"
                              onClick={() =>
                                history.push(
                                  `/ordersallitems/for-faves?type=${
                                    orderTypeToggle === true
                                      ? "pickup"
                                      : "delivery"
                                  }`
                                )
                              }
                            >
                              <div className="cursor-pointer w-[80px]">
                                See all
                              </div>
                            </TextHead4>
                          </>
                        )
                      ) : (
                        <SubText1 className="text-center !text-gray-400 !text-base mb-10">
                          No recent orders.
                        </SubText1>
                      )}
                    </>
                  )}
                </SubAccordion>
              ) : (
                <>
                  <AccordianCartegoryChild
                    text={{
                      categoryName: "Recent Orders",
                      seeMore:
                        !recentOrderLoading && recentOrderList?.length > 0
                          ? "See all"
                          : "",
                    }}
                    handleOnClick={() =>
                      history.push(
                        `/ordersallitems/for-faves?type=${
                          orderTypeToggle === true ? "pickup" : "delivery"
                        }`
                      )
                    }
                  />

                  {recentOrderLoading && (
                    <div className="flex items-center justify-center">
                      <Spinner />
                    </div>
                  )}
                  {!recentOrderLoading && (
                    <>
                      {recentOrderList?.length > 0 ? (
                        !faveMealModalStatus && (
                          <PreferredMealGrid
                            //  header={null}
                            data={recentOrderList}
                            type={
                              orderTypeToggle === true ? "pickup" : "delivery"
                            }
                          />
                        )
                      ) : (
                        // <NoDataFound
                        //   btnStyle={{
                        //     width: "70px",
                        //     fontSize: "12px",
                        //     marginTop: "10px",
                        //     placeSelf: "center",
                        //   }}
                        //   btnText="Add Meal"
                        //   goTo={() => history.push({ pathname: "/search?for=get-food-now" })}
                        // />
                        <SubText1 className="text-center !text-gray-400 !text-base mb-10">
                          No recent orders.
                        </SubText1>
                      )}
                    </>
                  )}
                </>
              )}
            </Accordion>

            <Accordion title="Fave Meal Categories">
              {mealCategory?.map((favMeal: any, index: any) => {
                return (
                  <React.Fragment key={index}>
                    <Text
                      onClick={() =>
                        history.push({
                          pathname: `/editmealcategory/${favMeal.id}`,
                        })
                      }
                    >
                      {switchSentenceCase(
                        "head text",
                        favMeal?.attributes?.name
                      )}{" "}
                      <i>
                        {favMeal?.attributes?.defaultMeal === true
                          ? `(Default for New Faves)`
                          : ""}
                      </i>
                    </Text>
                  </React.Fragment>
                );
              })}
              <Text
                className="italic"
                onClick={() =>
                  history.push({
                    pathname: "/faveaddnewcategory",
                  })
                }
              >
                Add New Category
              </Text>
            </Accordion>
          </Section>
          <Divider />
          {/* </ReactPullToRefresh> */}
          {/* </IonContent> */}
        </div>
      </>
    </div>
  );
}
