import Section from "../../components/widgets/section/Section";
import TopHead from "../../components/widgets/head/TopHead";
import Divider from "../../components/widgets/divider/Divider";
import {
  ButtonGreen,
  Flex,
  FlexBetween,
  InitialDiv,
  SubText1,
  TextHead1,
  TextHead2,
  TextHead4,
} from "../../components/styled/styled";
import { useHistory, useLocation, useParams } from "react-router";
import {
  faAngleLeft,
  faCartPlus,
  faChevronLeft,
  faCircleDollar,
  faHeart,
  faPotFood,
  faStore,
  faTrash,
  faTree,
} from "@fortawesome/pro-solid-svg-icons";
import DotSeprator from "../../components/widgets/dotseprator/DotSeprator";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import sampleMealImage from "../../components/assets/restaurent-Meal-3.jpg";
import { useDispatch, useSelector } from "react-redux";
import {
  isRestaurantOpen,
  properNameFormate,
  switchSentenceCase,
  textFormatter,
  isAuthenticated,
  trackPixelEvent
} from "../../utils/utilities";
import React, { useState, useEffect, useRef } from "react";
import { deleteSingleFavouriteData, setUserFaveMealList } from "./redux/favouriteActionCreator";
import { getUserFavoriteMeal } from "../../services/favourite";
import { getUserInfo } from "../../services/user";
import ModalMealClicked from "../../components/widgets/modals/ModalMealClicked";
import { preventAndStop } from "../../components/widgets/preventandstop/preventAndStop";
import ModalRemoved from "../../components/widgets/modals/ModalRemoved";
import ModalSpinner from "../../components/widgets/modalspinner/ModalSpinner";
import InitialModalCenter from "../../components/widgets/modals/InitialModalCenter";
import TabsForMultiple from "../../components/widgets/toggleable-tabs/TabsForMultiple";
import Spinner from "../../components/widgets/spinner/Spinner";
import ModalSuccess from "../../components/widgets/modals/ModalSuccess";
import { updateMealCategoryFavItem } from "../../services/favourite";
import { useIonViewDidEnter } from "@ionic/react";

const HeartModalIcon = styled.div`
  padding: 3px;
  background: #f03f3f29;
  border-radius: 15px;
  position: relative;
  top: 50px;
  left: 20px;
  backdrop-filter: blur(6.5px);
  color: #ff000082;
  width: 30px;
  height: 30px;
  text-align: center;
`;

const GridSection = styled.div`
  display: grid;
  grid-template-columns: auto auto auto auto;

  @media (max-width: 1100px) {
    grid-template-columns: auto auto auto;
    justify-content: center;
  }

  @media (max-width: 900px) {
    grid-template-columns: auto auto;
    justify-content: center;
  }
  @media (max-width: 600px) {
    grid-template-columns: auto;
    justify-content: center;
  }
`;

export const ButtonRemove = styled.button`
  width: 50px;
  height: 22px;
  color: #da1f1f;
  border: 1px solid #ff7070;
  background: #9797971f;
  border-radius: 25px;
  font-size: 14px;
  font-weight: 500;
  padding-bottom: 5px;
  align-self: center;
  margin: 0px 10px;
`;

export const ButtonAdd = styled.button`
  color: white;
  background: #22a45d;
  width: 50px;
  height: 22px;
  border: 1px solid #9797976b;
  border-radius: 25px;
  font-size: 14px;
  font-weight: 500;
  padding-bottom: 5px;
  align-self: center;
  margin: 0px 10px;
`;

export default function FavesAllItems() {
  // **************** Other Methods ***********************************
  const history = useHistory();
  const dispatch = useDispatch();
  const { pageType } = useParams<any>();
  const currentDateTime = new Date();
  const limit = 10;
  const userData = getUserInfo();
  const containerRef = useRef(null);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const type = searchParams.get('type');

  const showNav = pageType === "for-order" || pageType === "for-schedule" ? true : false;
  const { mealCategory, userFaveMealsList } = useSelector((state: any) => state.getAllFavData);
  const { userDeliveryAddress } = useSelector((state: any) => state.userAddresses);
  const { orderForSomeone } = useSelector((state: any) => state?.upcomingOrderState);

  // *************************** Hooks ***************************************
  const [showMealModal, setShowMealModal] = useState(false);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [mealItems, setMealItems] = useState<any>({});
  const [skip, setSkip] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [totalItems, setTotalItems] = useState(0);
  const [selectedTypes, setSelectedTypes] = useState<string[]>([]);
  // const [showIonAlertModal, setShowIonAlertModal] = useState(false);
  const [showModalSuccess, setShowModalSuccess] = useState(false);

  // *************************** First Time Rendering **********************************
  useEffect(() => {
    setIsLoading(true);
    if (totalItems === 0) {
      callData();
    }
    // if (totalItems > limit) {
    // const container = containerRef.current;
    // container.addEventListener('scroll', handleScroll);
    // return () => {
    //   container.removeEventListener('scroll', handleScroll);
    // };
  }, []);


  useIonViewDidEnter(() => {
    trackPixelEvent('Fav all items');
  }, []);


  const applyFilters = () => {
    setIsLoading(true);
    callData();
    setShowFilterModal(false);
  };

  // ******************************** Functions **********************************************
  // const handleScroll = () => {
  //   const container = containerRef.current;
  //   const { scrollTop, clientHeight, scrollHeight } = container;
  //   if (scrollTop + clientHeight >= scrollHeight - 10 && !loading) {
  //     const skippingValue = skip + limit
  //     setSkip(skippingValue)
  //     callData(skippingValue);
  //   }
  // };

  const callData = async (skippingValue = 0) => {
    if (isAuthenticated() === false) return false;

    if (userDeliveryAddress?.city === undefined || userDeliveryAddress?.city === "") {
      setIsLoading(false);
      return false;
    }
    try {
      let query = {
        id: (orderForSomeone.recipientId && pageType === "for-order") ? orderForSomeone.recipientId : userData?.id,
        type: "Fav",
        skip: skippingValue,
        limit: limit,
        lat: userDeliveryAddress?.lat,
        lang: userDeliveryAddress?.lang,
        order_type: type
      }
      if (selectedTypes?.length > 0) {
        query['category'] = selectedTypes
      }
      const response = await getUserFavoriteMeal(query);
      trackPixelEvent('Get user fave meal');
      setTotalItems(response?.total);
      let tempData = response?.data;

      if (skippingValue > 0) {
        setUserFaveMealList([...userFaveMealsList, ...tempData]);
        setIsLoading(false);
      } else {
        setUserFaveMealList(response?.data);
        setIsLoading(false);
      }
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  };

  const arrayOfObjects = mealCategory.map((nestedArray: any) => {
    return {
      name: nestedArray?.attributes?.name,
      value: nestedArray?.id,
    };
  });

  const showMore = () => {
    setIsLoading(true);
    const skippingValue = skip + limit;
    setSkip(skippingValue);
    callData(skippingValue);
  };

  const unfavoriteItem = async () => {
    if (userFaveMealsList?.user == null || mealItems?.id == null) {
      return;
    }
  };

  const openMealModal = (mealitems: any) => {
    setShowMealModal((prev) => !prev);
    setMealItems(mealitems);
  };

  const openModal = (mealitems: any) => {
    setShowModal((prev) => !prev);
    setMealItems(mealitems);
  };

  const deleteMeal = (ids: Number) => {
    const updatedData = {
      data: {
        type: null,
      }
    }
    updateMealCategoryFavItem(updatedData, ids)
      .then((res) => {
        trackPixelEvent('Update meal category fav item');
        dispatch(deleteSingleFavouriteData(ids, "Fav") as any)
      })
    setShowMealModal(false);
    setShowModal(false);
    // history.push({
    //   pathname: "/favesallitems/for-faves",
    // });
    setShowModalSuccess(true);
  };

  return (
    <div className="screen">
      <TopHead
        title="Faves"
        leftTextIcon={faAngleLeft}
        leftIconStyle={{ fontSize: "24px" }}
        rightText={"Filter"}
        gotoRightTextHandle={() => setShowFilterModal((prev) => !prev)}
      />
      <Divider />

      <div
        className="overflow-auto"
        ref={containerRef}
        style={{ overflowY: "scroll", maxHeight: "fit-content" }}
      >
        {isLoading && skip === 0 ? (
          <ModalSpinner />
        ) : (
          <Section>
            <TextHead1 style={{ marginBottom: "8px" }}>
              {totalItems} {totalItems > 0 ? "Results found" : "Result found"}
            </TextHead1>
            <GridSection>
              {userFaveMealsList?.map((faveMeal: any, index: any) => {
                let productImage: any;
                if (faveMeal?.attributes?.favItems?.data.length) {
                  if (faveMeal?.attributes?.favItems?.data[0].attributes?.product?.data?.attributes?.thumbnail_image) {
                    productImage = faveMeal?.attributes?.favItems?.data[0].attributes?.product?.data?.attributes?.thumbnail_image;
                  } else if (faveMeal?.attributes?.favItems?.data[0].attributes?.product?.data?.attributes?.image) {
                    productImage = faveMeal?.attributes?.favItems?.data[0].attributes?.product?.data?.attributes?.image;
                  } else {
                    productImage = faveMeal?.attributes?.favItems?.data[0].attributes?.product?.data?.attributes?.restaurant?.data?.attributes?.logo_photos;
                  }
                }

                // ************************* Checking Restaurant Open/Closed & Active/Inactive Condition ************************************
                const isClosedStrip = ((faveMeal?.attributes?.restaurant?.data !== null && userDeliveryAddress?.timezone !== null && userDeliveryAddress?.timezone !== undefined) && !isRestaurantOpen(type === "delivery" ? faveMeal?.attributes?.restaurant?.data?.attributes?.jsonData?.local_hours?.delivery : faveMeal?.attributes?.restaurant?.data?.attributes?.jsonData?.local_hours?.pickup, currentDateTime, userDeliveryAddress?.timezone, false)) ? true : false;
                const isInActive = (faveMeal?.attributes?.status === "INACTIVE") ? true : false;
                return (
                  <div className="slideshow" key={index}>
                    <div className="slideshowSlider">
                      <div className="slide">
                        <HeartModalIcon>
                          <FontAwesomeIcon icon={faHeart} />
                        </HeartModalIcon>
                        <img
                          className={isInActive ? "opacity-30 h-[215px] object-cover cursor-pointer" : "h-[215px] object-cover cursor-pointer"}
                          src={productImage ? productImage : sampleMealImage}
                          alt="fave-meals-logo"
                          height={215}
                          onClick={() => openMealModal(faveMeal)}
                        />
                      </div>
                    </div>
                    <div className="px-[10px]">
                      <FlexBetween>
                        <TextHead4>
                          {faveMeal?.attributes?.name
                            ? switchSentenceCase("head text", faveMeal?.attributes?.name)
                            : switchSentenceCase(
                              "head text",
                              properNameFormate(faveMeal?.attributes?.name)
                            )}
                        </TextHead4>

                        {showNav ? (
                          <ButtonAdd
                            // className={isClosedStrip ? "!cursor-not-allowed !border !border-solid !border-[#5cb383] !bg-[#5cb383]" : ""}
                            onClick={() => openMealModal(faveMeal)}
                          // disabled={isClosedStrip ? true : false}
                          >
                            <FontAwesomeIcon icon={faCartPlus} />
                          </ButtonAdd>
                        ) : (
                          <ButtonRemove
                            onClick={async (e: any) => {
                              preventAndStop(e);
                              openModal(faveMeal);
                            }}
                          >
                            <FontAwesomeIcon icon={faTrash} />
                          </ButtonRemove>
                        )}
                      </FlexBetween>

                      <InitialDiv className="my-2">
                        <SubText1 className="truncate">
                          {faveMeal?.attributes?.restaurant?.data?.attributes?.description}
                        </SubText1>
                      </InitialDiv>
                      <Flex className="items-center max-w-xs parent-elipse">
                        <SubText1>
                          <FontAwesomeIcon icon={faPotFood} className="text-gray-500 text-xs" />
                        </SubText1>
                        {faveMeal?.attributes?.favItems?.data
                          ?.slice(0, 3)
                          ?.map((faveMealItem: any, index: any) => {
                            return (
                              <React.Fragment key={index}>
                                <DotSeprator />
                                <SubText1 className="child-elipse">
                                  {switchSentenceCase(
                                    "head text",
                                    textFormatter(
                                      faveMealItem?.attributes?.product?.data?.attributes?.name
                                    )
                                  )}
                                </SubText1>
                              </React.Fragment>
                            );
                          })}
                      </Flex>
                      <FlexBetween className="items-center max-w-xs">
                        <FontAwesomeIcon
                          icon={faCircleDollar}
                          className="text-gray-400 text-sm mr-1"
                        />
                        <SubText1 className="mx-1">${faveMeal?.attributes?.total}</SubText1>
                        <DotSeprator />
                        <FontAwesomeIcon icon={faTree} className="text-gray-500 text-xs mx-1" />
                        <SubText1>
                          {faveMeal?.attributes?.restaurant?.data?.attributes?.jsonData?.address?.city}
                        </SubText1>
                        <DotSeprator />
                        <FontAwesomeIcon icon={faStore} className="text-gray-500 text-xs mx-1" />
                        <SubText1 className={(isClosedStrip || isInActive) ? "!text-red-600" : "!text-green-600"}>
                          {isInActive ? "[Inactive]" : isClosedStrip ? "[Closed]" : "[Open]"}
                        </SubText1>
                      </FlexBetween>
                    </div>
                  </div>
                );
              })}
            </GridSection>

            {totalItems > limit &&
              ((totalItems != userFaveMealsList?.length) ? (
                <div
                  className="text-green-600 text-center cursor-pointer mt-4 font-semibold"
                  onClick={() => {
                    showMore();
                  }}
                >
                  {!isLoading ? (
                    <span>See More</span>
                  ) : (
                    <span>
                      <Spinner />
                    </span>
                  )}
                </div>
              ) : (
                <div className="text-green-600 text-center mt-4 font-semibold">No More Faves</div>
              ))}

            {/* ********************************** Fav Items Removed Modal********************************************** */}
            {showModal && (
              <ModalRemoved
                firstText="Are you sure you want to"
                secondText="remove from faves?"
                btnOneText="OOPS! NEVER MIND..."
                btnTwoText="YES, REMOVE IT"
                showModal={showModal}
                setShowModal={setShowModal}
                id={mealItems?.id}
                deleteItem={deleteMeal}
                unfavoriteItem={unfavoriteItem}
              />
            )}

            {/* ********************************** Fav Items Details Modal********************************************** */}
            {showMealModal && (
              <ModalMealClicked
                showMealModal={showMealModal}
                setShowMealModal={setShowMealModal}
                mealProps={mealItems}
                deleteMeal={deleteMeal}
                favMealId={mealItems?.id}
              />
            )}

            {/* *********************Successfully delete Alert Modal****************************** */}
            <ModalSuccess
              contentDivStyle={{ height: "auto" }}
              firstText="Successfully Deleted"
              secondText="Fave meal is deleted"
              btnOneText="OK"
              btnOneGoto={() => {
                setShowModalSuccess(false)
              }}
              showModal={showModalSuccess}
              setShowModal={setShowModalSuccess}
            />

            {/* ********************************** Filter Modal********************************************** */}

            {showFilterModal && (
              <InitialModalCenter showModal={showFilterModal} setShowModal={setShowFilterModal}>
                <div className="h-12 mt-2 flex justify-between items-center">
                  <div className="flex justify-between items-center">
                    <FontAwesomeIcon
                      className="ml-4 cursor-pointer"
                      icon={faChevronLeft}
                      onClick={() => setShowFilterModal((prev: any) => !prev)}
                    />
                    <TextHead2 className="m-0 ml-6 text-black">Filters</TextHead2>
                  </div>

                  <TextHead4
                    className="text-gray-500 !text-base mr-4 cursor-pointer"
                    onClick={() => {
                      setSelectedTypes([]);
                    }}
                  >
                    CLEAR ALL
                  </TextHead4>
                </div>
                <Divider />
                <div className="">
                  <div className="overflow-y-auto rounded-[20px]">
                    <div className="grow overflow-auto">
                      <Divider />

                      <Section>
                        <FlexBetween>
                          <TextHead4>CATEGORIES</TextHead4>
                        </FlexBetween>
                        <TabsForMultiple
                          types={arrayOfObjects}
                          selected={selectedTypes}
                          setSelected={setSelectedTypes}
                        />
                      </Section>

                      <Divider />

                      <Section>
                        <ButtonGreen onClick={applyFilters}>
                          {switchSentenceCase("primary button", "APPLY FILTERS")}
                        </ButtonGreen>
                      </Section>
                    </div>
                  </div>
                </div>
              </InitialModalCenter>
            )}
          </Section>
        )}
      </div>
    </div>
  );
}
