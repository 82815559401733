import { ClosedStripDiv3, Flex, InitialDiv, SubText1 } from "../../styled/styled";
import defaultRestaurant from "../../assets/restaurent-Meal-3.jpg"
import { switchSentenceCase } from '../../../utils/utilities';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { useState } from 'react';
import { Rate } from 'antd';
import { setSearchedRestaurantData } from '../../../pages/orderFood/redux/orderFoodActionCreator';
import { getSearchedRestaurantsData } from '../../../pages/search/redux/searchActionCreator';
import 'swiper/css';
import { setSelectedRestaurantData } from "../../../services/restaurant";
import RestaurantCardImage from "./RestaurantCardImage";
import RestaurantCardDetails from "./RestaurantCardDetails";
import GetWindowWidth from "../../hooks/GetWindowWidth";

export default function RestaurantCardList(props: any) {

    const dispatch = useDispatch();
    const history = useHistory();
    const [swipe, setSwipe] = useState<any>();
    const { windowWidth } = GetWindowWidth();

    const onRestaurantClick = async (r: any) => {
        const restaurantSendData = {
            id: r._id,
            name: r.name,
            logoPhoto: r.logo_photos[0],
            description: r.description,
            rating: r.weighted_rating_value,
            ratingCount: r.aggregated_rating_count,
            quoteIds: r.quote_ids,
            is_open: r.is_open,
            dollar_signs: r.dollar_signs,
            local_hours: r.local_hours
        }

        if (props?.page === "for-order") {
            dispatch(setSearchedRestaurantData(restaurantSendData) as any);
            await setSelectedRestaurantData(restaurantSendData);
            history.push(`/restaurantDetail/${restaurantSendData?.id}?type=${props?.type}`);
        }
        else if (props?.page === "for-fave") {
            dispatch(getSearchedRestaurantsData(restaurantSendData) as any);
            await setSelectedRestaurantData(restaurantSendData);
            history.push(`/restaurant/${restaurantSendData?.id}?type=${props?.type}`);
        }
    };


    return (
        <>
            <InitialDiv>
                {props?.data?.map((r: any, index: any) => {
                    return (
                        <div
                            className="search-result"
                            key={index}
                            onClick={() => onRestaurantClick(r)}
                        >
                            {/* TODO placeholder img if needed */}
                            <div className="mr-4">
                                <RestaurantCardImage photo={r?.logo_photos} isClosed={r?.is_open} />
                                {
                                    r?.is_open
                                        ?
                                        ""
                                        :
                                        <ClosedStripDiv3 className="!text-red-600">Closed</ClosedStripDiv3>
                                }
                            </div>
                            <RestaurantCardDetails r={r} />
                        </div>
                    )
                }
                )}
            </InitialDiv>
        </>
    );
}
