import { faAngleLeft, faChevronLeft, faSearch } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useIonAlert } from "@ionic/react";
import { Select } from "antd";
import React, { useState } from "react";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import no_user_pic from "../../components/assets/profile/no-profile-photo.png";
import useDarkMode from "../../components/hooks/darkmode/DarkMode";
import { Astk, ButtonGreen, Flex, InitialDiv, InputLabel, SubText1, TextArea1, TextBox1, TextHead1, TextHead3, TextHead4 } from "../../components/styled/styled";
import AddressesCart from "../../components/widgets/address/AdressesCart";
import Divider from "../../components/widgets/divider/Divider";
import TopHead from "../../components/widgets/head/TopHead";
import InitialModalCenter from "../../components/widgets/modals/InitialModalCenter";
import ModalChooseOption from "../../components/widgets/modals/ModalChooseOption";
import Section from "../../components/widgets/section/Section";
import TabsForRecipientDetails from "../../components/widgets/toggleable-tabs/TabsForRecipientDetails";
import { findFriends, inviteFriend } from "../../services/friends";
import { setSomeOneElseOrderData } from "../../services/orderFood";
import { addNewRecipient } from "../../services/schedule";
import { getUserData, getUserInfo, setUserData, updateUser } from "../../services/user";
import { objectType } from "../../type";
import { countryCodeFilter, handleNetworkError, handleRequestError, handleResponse, maxCharacterLimit, removeMask, switchSentenceCase, trackPixelEvent, validateEmail, validatePhone } from "../../utils/utilities";
import { setRecentOrderList, setUserFaveMealList } from "../faves/redux/favouriteActionCreator";
import { setOrderForSomeone } from "../orders/redux/upcomingOrderActionCreator";
import { setUserDeliveryAddress } from "../profile/redux/locationActionCreator";
import { scheduleDetailsClear } from "../schedules/redux/createScheduleReduxActions";
import { clearRestaurantList, setNearByRestaurants } from "../search/redux/searchActionCreator";


export default function OrdersHome() {
  // ********************** Other Methods ************************************
  const history = useHistory();
  const dispatch = useDispatch();
  const user = getUserInfo();
  const isDarkMode = useDarkMode();
  const API_URL = process.env.REACT_APP_API_URL;
  const [presentAlert] = useIonAlert();
  const showAlert = (header: string, message: string, btnMessage: string) => {
    presentAlert({
      header: header,
      message: message,
      buttons: [btnMessage],
    });
  };

  // ********************** Hooks ************************************
  const [respondedData, setRespondedData] = useState<any>({});
  const [showRecipientModal, setShowRecipientModal] = useState(false);
  const [showOptionModal, setShowOptionModal] = useState(false);
  const [awaiting, setAwaiting] = useState(false);
  const [recipientEmail, setRecipientEmail] = useState("");

  const [values, setValues] = useState({
    recipientName: "",
    recipientContact: "",
    shortNotes: "",
    recipientId: "",
    addressId: "",
    type: "",
    orderType: "",
  });

  const { selectedSlots, scheduleDetails } = useSelector((state: any) => state?.createScheduleForMe);
  const { orderForSomeone } = useSelector((state: any) => state?.upcomingOrderState);
  const [userDatavalues, setUserDatavalues] = useState({
    username: "",
    useremail: "",
    userphonenumber: "",
  });

  const [searchedFriendText, setSearchedFriendText] = useState("");
  const [friendsData, setFriendsData] = useState([]);

  // ********************Error Handler****************************
  const [addressIdError, setAddressIdError] = useState(false);
  const [recipientContactError, setRecipientContactError] = useState(false);
  const [recipientEmailError, setRecipientEmailError] = useState(false);
  const [recipientEmailErrorMsg, setRecipientEmailErrorMsg] = useState("");
  const [userResponseError, setUserResponseError] = useState(false);
  const [recipientNameError, setRecipientNameError] = useState(false);
  const [userNameError, setUserNameError] = useState(false);
  const [userEmailError, setUserEmailError] = useState(false);
  const [userPhoneError, setUserPhoneError] = useState(false);
  const [disabledEmail, setDisabledEmail] = useState(false);

  const userDetails = [
    {
      name: "Details",
      value: "details",
      disabled: false,
      active: respondedData?.id ? true : false
    },
    {
      name: "Address",
      value: "address",
      disabled: respondedData?.id ? false : true,
      active: values?.addressId ? true : false
    }
  ];
  const [activeBar, setActiveBar] = useState(userDetails[0].value);

  const handleGetUserFriends = async (e: string) => { // Finding friend lists from api On search.... || calling api when length > 3     
    setSearchedFriendText(e);
    if (e?.length >= 3) {
      setAwaiting(true);
      setFriendsData([]);
      try {
        const { data } = await findFriends(e);
        trackPixelEvent('User find');
        setFriendsData(data?.data);
        setAwaiting(false);
      } catch (error) {
        setAwaiting(false);
        if (error.response) {
          handleResponse(error.response); // Server responded with a non-2xx status code
        } else if (error.request) {
          handleRequestError(error.request);  // The request was made but no response was received
        } else {
          handleNetworkError(error); // Network error or something else happened
        }
      }
    } else {
      setFriendsData([]);
    }
  };

  // ************************* Functions *************************************
  const handleAddressId = (id: any) => {
    setAddressIdError(false);
    setValues((prevValues) => {
      return {
        ...prevValues,
        addressId: id,
      };
    });
  };

  const handleGetData = async () => {
    const { recipientName, recipientContact } = values;
    if (
      removeMask(recipientContact) !== "" &&
      !recipientContactError &&
      recipientName !== "" &&
      !recipientNameError
    ) {
      const validPhoneNo = validatePhone(removeMask(recipientContact));
      if (!validPhoneNo || recipientContactError || recipientNameError) {
        return false;
      }
      setAwaiting(true);
      try {
        const payloadData = {
          recipientName: recipientName,
          recipient: removeMask(countryCodeFilter(recipientContact)),
          recipientEmail: recipientEmail
        };
        const response = await addNewRecipient(payloadData);
        if (response) {
          trackPixelEvent("Add new recipient");
          setRespondedData(response?.data?.data);
          if (response?.data?.data?.email !== null && response?.data?.data?.email !== "") {
            setRecipientEmail(response?.data?.data?.email)
          }
          setValues((prevValues) => {
            return {
              ...prevValues,
              recipientId: response?.data?.data?.id,
            };
          });

          // ************* Data Object for state********************
          let Data = {
            recipientName: "",
            recipientContact: "",
            recipientEmail: "",
            recipientId: response?.data?.data?.id,
            addressId: null,
            shortNotes: "",
            orderType: "",
            type: "",
          };
          setOrderForSomeone(Data);
          setAddressIdError(false);
          setAwaiting(false);
          return true;
        } else {
          setAddressIdError(true);
        }
      } catch (e) {
        setAwaiting(false);
        setRespondedData({});
        setRecipientEmail("");
        setValues((prevValues) => {
          return {
            ...prevValues,
            recipientId: "",
            addressId: ""
          };
        });
        return showAlert(e?.response?.data?.error?.name ? e?.response?.data?.error?.name : e?.response?.status, e?.response?.data?.error?.message ? e?.response?.data?.error?.message : e?.response?.statusText, "OK");
      }
    }
  };

  const handleChangePhoneNumber = (value: string) => {
    if (value === "" || value === undefined) {
      setRecipientContactError(true);
    } else {
      setRecipientContactError(false);
    }
    setValues((prevValues) => {
      return {
        ...prevValues,
        recipientContact: value,
      };
    });
  };

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setValues((prevValues) => {
      return {
        ...prevValues,
        [name]: value,
      };
    });

    if (name === "recipientName") {
      if (value.trim() === "" || value.length < 3) {
        setRecipientNameError(true);
      } else {
        setRecipientNameError(false);
      }
    }
  };

  const handleUserPhoneChange = (value: string) => {
    setUserResponseError(false);
    if (value === "" || value === undefined) {
      setUserPhoneError(true);
    } else {
      setUserPhoneError(false);
    }
    setUserDatavalues((prevValues) => {
      return {
        ...prevValues,
        userphonenumber: value,
      };
    });
  };

  const handleUserChange = (e: any) => {
    const { name, value } = e.target;
    setUserDatavalues((prevValues) => {
      return {
        ...prevValues,
        [name]: value,
      };
    });
    setUserResponseError(false);
    if (name === "username") {
      if (value.trim() === "" || value.length < 3) {
        setUserNameError(true);
      } else {
        setUserNameError(false);
      }
    }

    if (name === "useremail") {
      const validEmail = validateEmail(value);
      if (!validEmail) {
        setUserEmailError(true);
      } else {
        setUserEmailError(false);
      }
    }
  };

  const handleChangeNotes = (val: string) => {
    setValues((prevValues) => {
      return {
        ...prevValues,
        shortNotes: val.substring(0, 300),
      };
    });
    if (String(val).trim().length >= 300) {
      return showAlert("", "You exceed the maximum limit.", "OK");
    }
  };


  const handleKeyDown = (e: any) => {
    if (values?.shortNotes.length >= 300) {
      if (e.key === " " || e.keyCode === 32) {
        return showAlert("", "You exceed the maximum limit.", "OK");
      }
    }
  };


  const handleEmailChange = (e: any) => {
    setRecipientEmailErrorMsg("");
    setRecipientEmailError(false);
    setRecipientEmail(e?.target?.value);

    const validEmail = validateEmail(e?.target?.value);
    if (String(e?.target?.value).trim()?.length === 0 || !validEmail) {
      setRecipientEmailError(true);
    } else {
      setRecipientEmailError(false);
    }
  };

  const handleUpdateData = async () => {
    setAwaiting(true);
    setRecipientEmailErrorMsg("");
    if (recipientEmail !== "" && !recipientEmailError) {
      const validEmail = validateEmail(recipientEmail);
      if (!validEmail || recipientEmailError) {
        return false;
      }
      try {
        const data = {
          email: recipientEmail.toLowerCase(),
        };
        const res = await updateUser(data, respondedData?.id);
        if (res?.status === 200) {
          trackPixelEvent("Update user");
          setDisabledEmail(true);
        }
      } catch (error) {
        setRecipientEmailErrorMsg(error?.response?.data?.error?.message);
        setRecipientEmailError(true);
        setAwaiting(false);
      }
    }
  };

  const handleSubmitForOther = async () => {
    const {
      recipientName,
      recipientContact,
      shortNotes,
      addressId,
      recipientId,
      orderType
    } = values;
    const { username, useremail, userphonenumber } = userDatavalues;

    // *********************** validation Email and phone *************************************
    const validEmail = validateEmail(recipientEmail);
    const validPhoneNo = validatePhone(removeMask(recipientContact));
    const userValidEmail = validateEmail(useremail);
    const userValidPhoneNo = validatePhone(removeMask(userphonenumber));
    let recipientContactErr = false;

    if (activeBar === "details") {
      if (!validPhoneNo) {
        setRecipientContactError(true);
        recipientContactErr = true;
      }
      else {
        setRecipientContactError(false);
        recipientContactErr = false;
      }

      // *********************** validation recipient name*************************************
      if (recipientName === "") {
        setRecipientNameError(true);
        return false
      } else {
        setRecipientNameError(false);
      }

      // *********************** validation user name, email, phone*************************************
      if (user?.name === null && (username === "" || username?.length < 3)) {
        setUserNameError(true);
        return false
      } else {
        setUserNameError(false);
      }

      if (user?.email === null && (!userValidEmail || userResponseError)) {
        setUserEmailError(true);
        return false
      } else {
        setUserEmailError(false);
      }

      if (user?.phone_no === null && (!userValidPhoneNo || userResponseError)) {
        setUserPhoneError(true);
        return false
      } else {
        setUserPhoneError(false);
      }

      if (recipientContactErr || recipientNameError || recipientContactError || userNameError || userEmailError || userPhoneError) return false;

      if ((user?.email === null || user?.name === null || user?.phone_no === null)) {
        // *********************** Calling API to update user name, email, phone, if not************************************
        try {
          const data = {
            email: user?.email === null ? useremail.toLowerCase() : user?.email,
            name: user?.name === null ? username : user?.name,
            phone_no: user?.phone_no === null ? removeMask(countryCodeFilter(userphonenumber)) : user?.phone_no,
          };
          const localData = await getUserData();
          await updateUser(data, user?.id).then(async (response: any) => {
            let data = response?.data;
            if (response.status === 200) {
              trackPixelEvent("Update user");
              const newUpdatedUserInfo = {
                ...localData,
                user: {
                  ...localData.user,
                  name: data?.name,
                  email: data?.email,
                  phone_no: data?.phone_no,
                },
              };
              await setUserData(newUpdatedUserInfo);
            }
          });
        } catch (error) {
          setUserResponseError(true);
          setUserPhoneError(true);
          setUserEmailError(true);
          return showAlert("Error", error?.response?.data?.error?.message, "OK");
        }
      }
      let response = await handleGetData();
      if (response === true) {
        setActiveBar("address");
      }
    }
    else {
      if (respondedData?.email === null) {
        if (!validEmail || recipientEmailError) {
          setRecipientEmailError(true);
          return false;
        } else if (validEmail) {
          setRecipientEmailError(false);
        }
      }
      if (recipientEmailErrorMsg || recipientEmailError || addressIdError) return false;

      if (recipientId) {
        const payloadData = {
          data: {
            "from_id": user?.id,
            "to_id": recipientId,
            "status": "PENDING",
            "message": 'Hey! Add me as a friend so I can see your faves and buy you food on special occasions!'
          }
        }
        await inviteFriend(payloadData).then((response: any) => {
          if (response?.statusText === "OK" || response?.status === 200) {
            trackPixelEvent('Invite Friend');
          }
        });
      }
      // ************************validation Address ID**************************************
      if (addressId === "" || addressId === undefined) {
        setAddressIdError(true);
        return false;
      } else {
        setAddressIdError(false);
      }

      // **************Data Object for state********************
      let data = {
        recipientName: recipientName,
        recipientContact: removeMask(countryCodeFilter(recipientContact)),
        recipientEmail: recipientEmail,
        recipientId: recipientId,
        addressId: addressId,
        shortNotes: shortNotes,
        type: "for_other",
      };
      clearRestaurantList();
      setNearByRestaurants([]);
      setUserDeliveryAddress({});
      setOrderForSomeone(data);
      await setSomeOneElseOrderData(data);
      history.push(`/getfoodnow?type=${orderType}`);
    }
  };

  const handleSubmit = async (value: string) => {
    if (value === "") {
      return showAlert('', 'Please select any one option.', 'OK');
    }
    else if (values?.type === "for_other") {
      setValues((prevValues) => {
        return {
          ...prevValues,
          orderType: value,
        };
      });
      setShowOptionModal(false);
      setShowRecipientModal(true);
    }
    else {
      let data = {
        recipientName: "",
        recipientContact: "",
        recipientEmail: "",
        recipientId: user?.id,
        addressId: null,
        shortNotes: "",
        type: "for_me",
      };
      try {
        if (
          (orderForSomeone?.addressId !== null && orderForSomeone?.addressId !== undefined) || selectedSlots?.length > 0 || scheduleDetails?.attributes !== undefined || orderForSomeone?.recipientId !== user?.id) {
          dispatch(scheduleDetailsClear() as any);
          setUserDeliveryAddress({});
          setNearByRestaurants([]);
          setUserFaveMealList([]);
          setRecentOrderList([]);
          // setSelectedLocation("");
        }
        setOrderForSomeone(data);
        await setSomeOneElseOrderData(data);
        trackPixelEvent('Order now for me');
        history.push(`/getfoodnow?type=${value}`);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const onClickForOther = async () => {
    dispatch(scheduleDetailsClear() as any);
    // setUserDeliveryAddress({});
    setUserFaveMealList([]);
    setRecentOrderList([]);
    setValues((prevValues) => {
      return {
        ...prevValues,
        type: "for_other",
      };
    });
    setShowOptionModal(true);
  };

  const onClickForMe = async () => {
    setValues((prevValues) => {
      return {
        ...prevValues,
        orderType: "",
        type: ""
      };
    });
    setShowOptionModal(true);
  };

  let options = [];
  options = friendsData?.map((item: any, index: any) => (
    {
      id: item?.id,
      name: item?.name,
      phone_no: item?.phone_no,
      email: item?.email,
      value: (item?.name !== null ? item?.name + index : (item?.email !== null ? item?.email : item?.phone_no)),
      label: (
        <React.Fragment key={index}>
          <div className="flex">
            <img
              alt={item?.name + index}
              src={item?.profile_pic !== null ? API_URL + item?.profile_pic?.url : no_user_pic}
              className="mr-6 mt-1 rounded-[20px] border border-solid border-gray-500 w-8 h-8" />
            <div>
              <h3>{item?.name !== null ? item?.name : "-----"}</h3>
              <SubText1>{item?.city !== null ? item?.city : "-----"}</SubText1>
            </div>
          </div>
        </React.Fragment>
      ),
    }))

  const onSelect = (val: any, option: objectType) => {
    setRespondedData({});
    setRecipientContactError(false);
    setRecipientNameError(false);
    setValues((prevValues) => {
      return {
        ...prevValues,
        recipientName: option?.name,
        recipientContact: option?.phone_no,
        recipientId: option?.id
      };
    });
    if (option?.email) {
      setRecipientEmail(option?.email);
    }
    setFriendsData([]);
  };

  return (
    <>
      <div className="screen">
        <TopHead
          title="Order Meal"
          leftTextIcon={faAngleLeft}
          leftIconStyle={{ fontSize: "24px" }}
        />
        <Divider />

        <div className="grow overflow-auto">
          <Section>
            <TextHead1>Who is the Order for?</TextHead1>
          </Section>

          <Section>
            <ButtonGreen
              onClick={(e: any) => {
                e.preventDefault();
                onClickForMe()
              }}
            >
              {switchSentenceCase("primary button", "Food For Me")}
            </ButtonGreen>
          </Section>

          <Section>
            <ButtonGreen
              onClick={() => onClickForOther()}
            >
              {switchSentenceCase("primary button", "FOOD FOR SOMEONE ELSE")}
            </ButtonGreen>
          </Section>

          {/* ********************************** Show Recipient's Modal********************************************** */}
          {showRecipientModal && (
            <InitialModalCenter
              showModal={showRecipientModal}
              setShowModal={setShowRecipientModal}
            >
              <InitialDiv className="m-4">
                <Flex className="items-center">
                  <FontAwesomeIcon
                    className="cursor-pointer"
                    icon={faChevronLeft}
                    onClick={() => setShowRecipientModal((prev: any) => !prev)}
                  />
                  <InitialDiv className="w-full text-center">
                    <TextHead3 className="">Add Recipient Details</TextHead3>
                  </InitialDiv>
                </Flex>
              </InitialDiv>
              <Divider />
              <Section>

                <InitialDiv className="m-4">
                  <TabsForRecipientDetails
                    types={userDetails}
                    setActive={setActiveBar}
                    selected={activeBar}
                  />

                  {(activeBar === "details") &&
                    <>
                      {/* ********************* Search Friends Section ******************************* */}
                      <InitialDiv>
                        <TextHead3>
                          Search for Friend
                        </TextHead3>
                        <FontAwesomeIcon icon={faSearch} className="relative top-[32px] left-[10px] z-10" />
                        <Select
                          allowClear
                          showSearch
                          size="large"
                          bordered={false}
                          filterOption={false}
                          options={options}
                          loading={awaiting}
                          placeholder={"Search by name, phone, or email"}
                          onSearch={(e) => handleGetUserFriends(e)}
                          onSelect={(val, option) => onSelect(val, option)}
                          style={{ width: "100%", paddingLeft: "22px", border: "1px solid #7c7c7c69", borderRadius: "10px", marginBottom: "20px", background: `${isDarkMode ? "#3b3b3bb3" : "#FBFBFB"}` }}
                          notFoundContent={
                            !awaiting && searchedFriendText?.length > 2
                            &&
                            <SubText1 className="m-2 !mb-[2px]">No Result Found</SubText1>
                          }
                        />
                      </InitialDiv>
                      <SubText1 className="!font-bold text-center !text-green-600">OR</SubText1>

                      <TextHead3>
                        Add a Friend
                      </TextHead3>
                      <InitialDiv className="text-left">
                        <TextHead4 id="recipientname" className="mb-[-15px] mt-4">
                          Recipient Name<Astk> *</Astk>
                        </TextHead4>
                        <TextBox1
                          type="text"
                          placeholder="Enter Recipient Name"
                          name="recipientName"
                          style={{ border: recipientNameError ? "1px solid red" : "" }}
                          // value={respondedData?.name ? respondedData?.name : values?.recipientName}
                          value={values?.recipientName}
                          onChange={handleChange}
                        // readOnly={respondedData?.name ? true : false}
                        />
                      </InitialDiv>

                      <InitialDiv className="text-left">
                        <TextHead4 id="recipientcontact" className="mb-[-15px] mt-4">
                          Recipient Phone Number<Astk> *</Astk>
                        </TextHead4>
                        <div className="relative mb-4 flex mt-6">
                          <PhoneInput
                            className={isDarkMode ? "country-selector" : ""}
                            international
                            countryCallingCodeEditable={false}
                            defaultCountry="US"
                            limitMaxLength={true}
                            placeholder="Enter Recipient Phone Number"
                            name="recipientContact"
                            value={values?.recipientContact}
                            onChange={handleChangePhoneNumber}
                            style={{
                              borderRadius: "8px",
                              width: "100%",
                              border: recipientContactError ? "1px solid red" : "",
                            }}
                          />
                        </div>
                      </InitialDiv>

                      {user?.name === null && (
                        <InitialDiv className="text-left">
                          <TextHead4 id="username" className="mb-[-15px] mt-4">
                            Your Name<Astk> *</Astk>
                          </TextHead4>
                          <TextBox1
                            type="text"
                            placeholder="Enter Your Name"
                            name="username"
                            style={{
                              border: userNameError ? "1px solid red" : "",
                            }}
                            value={userDatavalues?.username}
                            onChange={handleUserChange}
                          />
                        </InitialDiv>
                      )}

                      {user?.email === null && (
                        <InitialDiv className="text-left">
                          <TextHead4 id="useremail" className="mb-[-15px] mt-4">
                            Your Email<Astk> *</Astk>
                          </TextHead4>
                          <TextBox1
                            type="text"
                            placeholder="Enter Your Email Address"
                            name="useremail"
                            style={{
                              border: userEmailError ? "1px solid red" : "",
                            }}
                            value={userDatavalues?.useremail}
                            onChange={handleUserChange}
                          />
                        </InitialDiv>
                      )}

                      {user?.phone_no === null && (
                        <InitialDiv className="text-left">
                          <TextHead4 id="userphonenumber" className="mb-[15px] mt-4">
                            Your Phone Number<Astk> *</Astk>
                          </TextHead4>
                          <PhoneInput
                            className={isDarkMode ? "country-selector" : ""}
                            international
                            countryCallingCodeEditable={false}
                            defaultCountry="US"
                            limitMaxLength={true}
                            placeholder="Enter Your Phone Number"
                            name="userphonenumber"
                            value={userDatavalues?.userphonenumber}
                            onChange={handleUserPhoneChange}
                            style={{
                              border: userPhoneError ? "1px solid red" : "",
                            }}
                          />
                        </InitialDiv>
                      )}

                      <InitialDiv className="text-left">
                        <TextHead4 id="shortnotes" className="mb-[-15px] mt-4">
                          Short Note
                        </TextHead4>
                        <SubText1 className="text-right mt-[20px]">
                          {values?.shortNotes?.length}/{maxCharacterLimit}
                        </SubText1>
                        <TextArea1
                          className="!mt-[0px]"
                          placeholder="Add a short note (optional)."
                          name="shortNotes"
                          value={values?.shortNotes}
                          onChange={(event: any) => handleChangeNotes(event.target.value)}
                          onKeyDown={(e) => handleKeyDown(e)}
                        // maxLength={maxCharacterLimit}
                        />
                      </InitialDiv>
                    </>}
                </InitialDiv>

                {(activeBar === "address") &&
                  <InitialDiv className="m-4 text-left">
                    {(respondedData?.email === null || respondedData?.email === "") && (
                      <>
                        <TextHead4
                          id="recipientcontact"
                          className="mb-[-15px] mt-4"
                        >
                          Recipient Email<Astk> *</Astk>
                        </TextHead4>
                        <TextBox1
                          placeholder="Enter Recipient Email"
                          name="recipientContact"
                          onBlur={handleUpdateData}
                          onChange={handleEmailChange}
                          style={{ border: recipientEmailError ? "1px solid red" : "" }}
                          value={recipientEmail}
                          readOnly={disabledEmail ? true : false}
                        />
                        <SubText1 className="!text-red-600 mt-[-10px] mb-[10px]">
                          {recipientEmailErrorMsg}
                        </SubText1>
                      </>
                    )}

                    {/*----------------------------------- Address Component Section  ------------------------*/}
                    <InitialDiv className="mb-8">
                      <TextHead4>
                        {values?.orderType === "pickup" ? "Select Address" : "Delivery Address"}<Astk> *</Astk>
                      </TextHead4>
                      <AddressesCart
                        title=""
                        addressId={handleAddressId}
                        showDeleteBtn={false}
                        showList={false}
                        pageName="odersHome"
                        pageDataId=""
                        changeAddressTxtBtn="CHANGE ADDRESS"
                        userId={values?.recipientId}
                        setDeliveryAddress={true}
                        changeAddress={true}
                        initialLength={1}
                      />
                      {addressIdError && (
                        <InputLabel className="!text-red-600 !text-center mt-2">
                          Address is required.
                        </InputLabel>
                      )}
                    </InitialDiv>
                  </InitialDiv>
                }
                <InitialDiv className="m-4">
                  <ButtonGreen onClick={handleSubmitForOther}>
                    {switchSentenceCase("head text", "NEXT")}
                  </ButtonGreen>
                </InitialDiv>
              </Section>
            </InitialModalCenter>
          )}
          <Divider />
        </div>
      </div >
      {
        setShowOptionModal &&
        <ModalChooseOption
          showModal={showOptionModal}
          setShowModal={setShowOptionModal}
          selectedValue={handleSubmit}
        />
      }
    </>
  );
}
