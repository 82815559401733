import { apiRequestService } from "./apiRequestService";
import { getUserData } from "./user";

const API_URL = process.env.REACT_APP_API_URL;

export async function setSomeOneElseOrderData(data: any) {
  localStorage.setItem("SomeoneElseOrderData", JSON.stringify(data));
}

export function getSomeOneElseOrderData() {
  return JSON.parse(localStorage.getItem("SomeoneElseOrderData"));
}

//Get Upcoming Orders
export const getUpcomingOrders = async (
  id: any,
  skip: any,
  limit: any,
  type: any,
  orderType: any
) => {
  try {
    const user = await getUserData();
    const base_url = `${API_URL}/v1/schedule-slots/?filters[sponsoredBy][id][$null]=false`;
    let filters = '';
    let sort = '';

    if (orderType === "SCHEDULED" || orderType === "RECENT") {
      filters += "&filters[$or][0][order_status][$eq]=SCHEDULED";
    }
    if (orderType === "CANCELED") {
      filters += "&filters[$or][1][order_status][$eq]=CANCELED";
    }
    if (orderType === "UPCOMING" || orderType === "RECENT") {
      filters += "&filters[$or][2][order_status][$eq]=ORDERED";
    }
    if (orderType === "COMPLETED") {
      filters += "&filters[$or][3][order_status][$eq]=COMPLETED";
    }

    if (type === "For_Me") {
      filters += `&filters[user][id][$eq]=${id}`;
    }
    if (type === "From_Me") {
      filters += `&filters[sponsoredBy][id][$eq]=${id}&filters[user][id][$ne]=${id}`;
    }

    filters += `&populate[favMeal][populate][favItems][populate][product]=*&populate[restaurant]=*&populate[sponsoredBy]=*&populate[schedule]=*&populate[user]=*&populate[delivery_location]=*&pagination[start]=${skip || 0}&pagination[limit]=${limit || 1000}`;

    if (orderType === "RECENT") {
      sort = "&sort[0]=id:desc";
    } else if (orderType === "UPCOMING") {
      sort = "&sort[0]=delivery_timestamp:asc";
    } else if (orderType === "CANCELED" || orderType === "COMPLETED") {
      sort = "&sort[0]=date:desc&sort[1]=time:desc";
    } else {
      sort = "&sort[0]=date:asc&sort[1]=time:asc";
    }

    const url = base_url + filters + sort;

    let config = {
      method: "get",
      url: url,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + user?.jwt,
      },
    };

    const request = apiRequestService(config);
    return request;
  } catch (error) {
    console.log(error);
  }
};


//Get count of Upcoming Orders
export const getUpcomingOrdersCount = async (id: any) => {
  //&filters[sponsoredBy][id][$eq]=${id}
  //&filters[date][$gte]=${currentDate}
  //&filters[$and][0][$or][2][order_status][$eq]=CANCELED&filters[$and][0][$or][3][order_status][$eq]=COMPLETED
  try {
    const user = await getUserData();

    let config = {
      method: "get",
      url:
        API_URL +
        `/v1/schedule-slots/?filters[sponsoredBy][id][$null]=false&filters[$and][0][$or][0][order_status][$eq]=SCHEDULED&filters[$and][0][$or][1][order_status][$eq]=ORDERED&filters[$and][1][$or][2][$or][0][user][id][$eq]=${id}&filters[$and][1][$or][2][$or][1][sponsoredBy][id][$eq]=${id}&sort[0]=date&sort[1]=time`,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + user?.jwt,
      },
    };
    const request = apiRequestService(config);
    return request;
  } catch (error) {
    console.log(error);
  }
};

export const previewOrder = async (requestData: any) => {
  try {
    const user = await getUserData();

    let config = {
      method: "post",
      url: API_URL + `/v1/preview-order`,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + user?.jwt,
      },
      data: requestData,
    };
    const request = apiRequestService(config);
    return request;
  } catch (error) {
    throw error;
  }
};

// Cancel Order
export const cancelOrder = async (requestData: any, id: any) => {
  try {
    const user = await getUserData();

    let config = {
      method: "put",
      url: API_URL + `/v1/schedule-slots/${id}?populate[favMeal]=*`,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + user?.jwt,
      },
      data: requestData,
    };
    const request = apiRequestService(config);
    return request;
  } catch (error) {
    console.log(error);
  }
};

// Track Order
export const trackOrder = async (orderId: any) => {
  try {
    const user = await getUserData();

    let config = {
      method: "GET",
      url: API_URL + `/v1/track-order/` + orderId,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + user?.jwt,
      },
    };

    const request = apiRequestService(config);
    return request;
  } catch (error) {
    throw error;
  }
};

export const finalizeOrder = async (requestData: any) => {
  try {
    const user = await getUserData();

    let config = {
      method: "post",
      url: API_URL + `/v1/finalize-order`,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + user?.jwt,
      },
      data: requestData,
    };
    const request = apiRequestService(config);
    return request;
  } catch (error) {
    throw error;
  }
};

// url: API_URL + `/v1/schedule-slots/?filters[sponsoredBy][id][$null]=false&${(type == "For_Me") ? ("filters[user][id][$eq]=" + id) : ""}&${(type == "From_Me") ? ("filters[sponsoredBy][id][$eq]=" + id + "&filters[user][id][$ne]=" + id) : ""}&populate[favMeal][populate][favItems][populate][product]=*&populate[sponsoredBy]=*&populate[schedule]=*&populate[user]=*&pagination[start]=${skip || 0}&pagination[limit]=${limit || 100}&sort[0]=date&sort[1]=time&filters[date][$gte]=${currentDate}`,

//&${(type == "For_Me") ? ("filters[user][id][$eq]=" + id) : ""}&${(type == "From_Me") ? ("filters[sponsoredBy][id][$eq]=" + id + "&filters[user][id][$ne]=" + id) : ""}
