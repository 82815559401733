import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState, useCallback, useRef, ReactNode } from "react";
import styled from "styled-components";
import useDarkMode from "../../hooks/darkmode/DarkMode";

type DirectionType = "top" | "right" | "bottom" | "left";

interface ChevronProps {
  direction: DirectionType;
  isDarkMode: boolean;
}

const Chevron = styled.div<ChevronProps>`
  border-style: solid;
  border-width: 0.125rem 0.125rem 0 0;
  border-color: ${props => (props?.isDarkMode ? 'white' : '#000000bf')};
  height: 0.6rem;
  width: 0.6rem;
  transition: all 0.25s ease-in-out;

  transform: ${(p) => p.direction === "top" && "rotate(-45deg)"};
  transform: ${(p) => p.direction === "right" && "rotate(45deg)"};
  transform: ${(p) => p.direction === "bottom" && "rotate(135deg)"};
  transform: ${(p) => p.direction === "left" && "rotate(-135deg)"};
`;

const ChevronSub = styled.div<ChevronProps>`
  border-style: solid;
  border-width: 0.125rem 0.125rem 0 0;
  border-color: ${props => (props?.isDarkMode ? 'white' : '#000000bf')};
  height: 0.6rem;
  width: 0.6rem;
  transition: all 0.25s ease-in-out;

  transform: ${(p) => p.direction === "top" && "rotate(-45deg)"};
  transform: ${(p) => p.direction === "right" && "rotate(45deg)"};
  transform: ${(p) => p.direction === "bottom" && "rotate(135deg)"};
  transform: ${(p) => p.direction === "left" && "rotate(-135deg)"};
`;

interface AccordionProps {
  title: string;
  children: ReactNode;
  opened?: boolean;
  requireText?: string;
  error?: boolean;
  leftTextIcon?: any;
  leftTextIconClassName?: string;
  leftSubTitle?: string;
  titleClassName?: string
}

const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 0;
  font-size: 20px;
  font-weight: 500;
  line-height: 1.25;
  cursor: pointer;
`;

const Title1 = styled.div<{ isDarkMode: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 0;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.25;
  color: ${props => (props?.isDarkMode ? 'white' : '#000000bf')};
  cursor: pointer;
`;

const ContentWrapper = styled.div<{ maxHeight: any }>`
  max-height: ${(p) => `${p.maxHeight}`};
  /* max-height: ${(p) => `${p.maxHeight}px`}; */
  transition: max-height 0.25s ease-in-out;
  overflow: hidden;
`;

const Content = styled.div`
  /* padding: 0 0 1rem; */
  /* color: rgba(0, 0, 0, 0.75); */
  line-height: 1.5;
  height: auto;
`;

export default function Accordion({
  title,
  requireText = "",
  leftTextIconClassName,
  titleClassName,
  leftTextIcon,
  leftSubTitle,
  children,
  opened = true,
  error = false,
}: AccordionProps): JSX.Element {


  //----------------------------- Hooks --------------------------------------
  const [isExpanded, setExpand] = useState<boolean>(opened);
  const isDarkMode = useDarkMode();

  const contentRef = useRef<HTMLDivElement>();
  const contentHeight = isExpanded
    ? /*contentRef.current?.scrollHeight*/ "100%"
    : 0;

  const handleExpandToggle = useCallback(() => {
    setExpand(!isExpanded);
  }, [isExpanded]);


  return (
    <>
      <Title
        onClick={handleExpandToggle}
        style={{ color: error ? "red" : null }}
      >
        <div className="flex items-center">
          {leftTextIcon == null ? null : (
            <FontAwesomeIcon
              icon={leftTextIcon}
              className={leftTextIconClassName}
            />
          )}
          <div className="grid">
            <span className={titleClassName}>
              {title} {requireText && <>[{requireText}]</>}
            </span>
            <span className="" style={{ marginTop: "-8px" }}>
              {leftSubTitle === null ? null : (
                <span className="text-sm text-gray-400">{leftSubTitle}</span>
              )}
            </span>
          </div>
        </div>
        <Chevron direction={isExpanded ? "bottom" : "right"} isDarkMode={isDarkMode} />
      </Title>
      <ContentWrapper maxHeight={contentHeight}>
        <Content ref={contentRef}>{children}</Content>
      </ContentWrapper>
    </>
  );
}

export const SubAccordion = ({
  title,
  children,
}: AccordionProps): JSX.Element => {

  // --------------------- hooks ---------------------
  const isDarkMode = useDarkMode();
  const [isExpanded, setExpand] = useState<boolean>(true);

  const contentRef = useRef<HTMLDivElement>();
  const contentHeight = isExpanded ? contentRef.current?.scrollHeight : 0;

  const handleExpandToggle = useCallback(() => {
    setExpand(!isExpanded);
  }, [isExpanded]);

  return (
    <>
      <Title1
        onClick={handleExpandToggle}
        style={{
          justifyContent: "start",
          columnGap: "15px",
          marginLeft: "5px",
        }}
        isDarkMode={isDarkMode}
      >
        <ChevronSub direction={isExpanded ? "bottom" : "right"} isDarkMode={isDarkMode} />
        {title}
      </Title1>
      <ContentWrapper maxHeight={contentHeight}>
        <Content ref={contentRef}>{children}</Content>
      </ContentWrapper>
    </>
  );
};

// ----------------Example----------------------
{
  /* <Accordion title="Title 1">
quas molestias excepturi sint occaecati cupiditate non provident,
</Accordion> */
}
