import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { scheduleDetailsClear } from "../schedules/redux/createScheduleReduxActions";
import useDarkMode from "../../components/hooks/darkmode/DarkMode";
import faves_icon from "../../components/assets/dashboard/faves-icon.svg";
import { useState } from "react";

export default function MyFavoritesButton() {
  const history = useHistory();
  const dispatch = useDispatch();
  const isDarkMode = useDarkMode();
  const { totalFavMeal } = useSelector((state: any) => state.getAllFavData);
  // const { totalExistedFriends } = useSelector((state: any) => state?.userAddresses);
  // const { totalForMe } = useSelector((state: any) => state.createScheduleForMe);


  const [getTotalFavMealClass, setGetTotalFavMealClass] = useState("");


  // useEffect(() => {
  //   getConditionsBasedClasses();

  //   // eslint-disable-next-line
  // }, [totalFavMeal, totalExistedFriends, totalForMe]);


  // const getConditionsBasedClasses = () => {
  //   if (totalFavMeal >= 1 && totalExistedFriends >= 1 && totalForMe >= 1) {
  //     setGetTotalFavMealClass("!opacity-100");
  //   } else if (totalFavMeal === 1) {
  //     setGetTotalFavMealClass("");
  //   } else if (totalFavMeal >= 1 && totalExistedFriends === 0 && totalForMe === 0) {
  //     setGetTotalFavMealClass("");
  //   } else if (totalFavMeal >= 1 && totalExistedFriends >= 1 && totalForMe === 0) {
  //     setGetTotalFavMealClass("");
  //   } else {
  //     setGetTotalFavMealClass("!opacity-100");
  //   }
  // }


  // let totalFavMeal = 1;

  return (
    <>
      <div className={`getFood_box food max-md:w-[50%] max-lg:w-[100%] lg:w-[50%] lg:min-h-32 ${getTotalFavMealClass}`}
        onClick={(e: any) => {
          e.preventDefault();
          dispatch(scheduleDetailsClear() as any)
          history.push('/faves');
        }}>
        <div className="w-full">

          <div className="Food_box_icon">
            <img src={faves_icon} alt="faves-img" className="w-[28px]" />
            <span className="Food_order-green">{totalFavMeal} {(totalFavMeal > 1 || totalFavMeal === 0) ? "" : ""}</span>
          </div>

          <div className="Food_box_text text-center max-md:text-left my-3">
            <span className="text">Save Your Faves</span>
            <p >Save exactly what you like, for easy ordering and sharing with friends.</p>
          </div>
        </div >
      </div>
    </>
  );
}


