import styled, { ThemeProvider, keyframes } from 'styled-components';
import useDarkMode from '../hooks/darkmode/DarkMode';


// ***************** Checking Condition for dark mode ************************************
const darkModeQuery = window.matchMedia("(prefers-color-scheme: dark)");
export const isDarkMode = (darkModeQuery.matches === true ? true : false);

const ThemedComponents = ({ children }) => {
    const isDarkMode = useDarkMode();

    // Define theme based on dark mode state
    const theme = {
        textH2Color: isDarkMode ? '#707070f0' : '#010f07a3',
        textTextHead4Color: isDarkMode ? 'white' : '',
        textTextBoxColor: isDarkMode ? '#c5c5c5' : '#919191',
        textBoxPlaceholderColor: isDarkMode ? '#9898983b' : '#c3c3c3',
        textInputBoxColor: isDarkMode ? '#c5c5c5' : '#726d6d',
    };
    return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};
export default ThemedComponents;



// ************************* Styled CSS ***********************************
export const TextHead1 = styled.h1`
    font-size: 22px;
    font-weight: 600;
`;

export const TextHead2 = styled.h1`
    font-size: 19px;
    line-height: 36px;
    color: ${props => props.theme.textH2Color};
  `;

export const TextHead3 = styled.h3`
    font-size: 20px;
    font-weight: 500;
`;

export const TextHead4 = styled.h4`
font-size: 17px;
font-weight: 500;
color: ${props => props.theme.textTextHead4Color};
`;

export const TextHead5 = styled.h3`
    font-size: 15px;
    font-weight: 400;
    margin: 5px 0px;
`;

export const SubText1 = styled.h3`
    color: #b5bbc9;
    font-size: 15px;
    font-weight: 400;
`;

export const SubText2 = styled.h3`
    color: #868686;
    font-size: 14px;
`;

export const TextBox1 = styled.input`
    padding: 5px 10px 10px 10px;
    background: none;
    color: ${props => props.theme.textTextHead4Color};
    border: 1px solid #e1e1e1;
    outline: none;
    font-size: 17px;
    margin: 25px 0px;
    ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: ${props => props.theme.textBoxPlaceholderColor};
        opacity: 1;
    }
  `;

export const TextBox2 = styled.input`
    padding: 5px 10px 10px 10px;
    background: none;
    border: 1px solid #e1e1e1;
    color: ${props => props.theme.textTextHead4Color};
    outline: none;
    text-align: center;
    font-size: 19px;
    font-weight: 600;
    ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: ${props => props.theme.textBoxPlaceholderColor};
        opacity: 1;
    }
`;

export const InputLabel = styled.h5`
    text-align: left;
    color: #868686;
`;

export const InputText = styled.input`
    padding: 8px 10px 8px 10px;
    background: none;
    color: ${props => props.theme.textInputBoxColor};
    border: 1px solid #e1e1e1;
    outline: none;
    height: 40px;
    ::-webkit-inner-spin-button{
        -webkit-appearance: none; 
        margin: 0; 
    }
    ::-webkit-outer-spin-button{
        -webkit-appearance: none; 
        margin: 0; 
    }    
 
    ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: ${props => props.theme.textBoxPlaceholderColor};;
        opacity: 1;
    }
  `;

export const InputWithIcon = styled.input`
background: none;
color: ${props => props.theme.textInputBoxColor};
border: 1px solid #e1e1e1;
outline: none;
height: 40px;
::-webkit-inner-spin-button{
        -webkit-appearance: none; 
        margin: 0; 
    }
    ::-webkit-outer-spin-button{
        -webkit-appearance: none; 
        margin: 0; 
    }  
::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: ${props => props.theme.textBoxPlaceholderColor};;
    opacity: 1;
}
`;

export const InputText2 = styled.input`
    padding: 8px 10px 8px 10px;
    background: #EBEBEB;
    color: ${props => props.theme.textInputBoxColor};
    border: 1px solid #e1e1e1;
    outline: none;
    width: 100%;
    border-radius: 10px;
    ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: ${props => props.theme.textBoxPlaceholderColor};;
        opacity: 1;
    }

    /* Remove arrow from number input */
    -webkit-appearance: none;
    -moz-appearance: textfield;
    appearance: none;
    margin: 0; /* Optional: Remove default margin */
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0; /* Optional: Remove default margin */
    }
  `;


export const TextArea1 = styled.textarea`
    padding: 10px 10px 10px 10px;
    background: none;
    color: #919191;
    border: 1px solid #e1e1e1;
    outline: none;
    font-size: 17px;
    margin: 25px 0px;
    border-radius: 10px;
    width: 100%;
    ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: ${props => props.theme.textBoxPlaceholderColor};;
        opacity: 1;
    }
`;


export const ButtonGreen = styled.button`
    background: #22A45D;
    color: white;
    border: 1px solid #22A45D;
    text-align: center;
    padding: 7px;
    border-radius: 0.5rem;
    width: 100%;
    font-size: 15px;
    font-weight: 500;
`;

export const ButtonLiteGreen = styled.button`
  background: #21734633;;
    color: #22A45D;
    border: 1px solid #21734633;
    text-align: center;
    padding: 7px;
    border-radius: 0.5rem;
    width: 100%;
    font-size: 15px;
    font-weight: 500;
`;

export const ButtonGray = styled.button`
    background: #b3b3b3;
    color: white;
    border: 1px solid #b3b3b3;
    text-align: center;
    padding: 7px;
    font-size: 15px;
    border-radius: 0.5rem;
    width: 100%;
    font-weight: 500;
`;


export const ButtonLimeGreen = styled.button`
    background: #BBD97C;
    color: #22A45D;
    border: 1px solid #BBD97C;
    text-align: center;
    padding: 7px;
    border-radius: 0.5rem;
    width: 100%;
    font-size: 15px;
    font-weight: 500;
`;


export const ButtonInitial = styled.button`
    text-align: center;
    padding: 7px;
    font-size: 15px;
    border-radius: 0.5rem;
    width: 100%;
    font-weight: 500;
`;

export const ButtonOne = styled.button`
    background: none;
    border: 1px solid #22A45D;
    border-radius: 15px;
    color: #22A45D;
    font-weight: 500;
    margin: 5px;
    width: 100%;
    padding: 10px;
`;


export const ButtonTwo = styled.button`
    background: none;
    border: 1px solid #22A45D;
    border-radius: 15px;
    color: #22A45D;
    font-weight: 500;
    margin: 5px;
    width: 100%;
    padding: 10px;
`;


export const ButtonThree = styled.button`
    background: #22A45D;
    color: white;
    border-radius: 15px;
    font-weight: 500;
    margin: 5px;
    width: 100%;
    padding: 10px;
    margin-top: 25px;

`;


export const ButtonFour = styled.button`
    background: #BBD97C;
    color: #22A45D;
    border-radius: 15px;
    font-weight: 500;
    margin: 5px;
    width: 100%;
    padding: 10px;

`;

export const ButtonFive = styled.button`
    background: #b3b3b3;
    color: white;
    border-radius: 15px;
    font-weight: 500;
    margin: 5px;
    width: 100%;
    padding: 10px;
`;

export const Astk = styled.span`
    color: red;
`;


export const ItemCount = styled.div`
    color: #22a45d;
    font-size: 15px;
    border: 1px solid #dddddd;
    width: 25px;
    height: 24px;
    border-radius: 5px;
    margin-right: 15px;
    text-align: center;
    margin-top: 3px;
`;


export const FlexBetween = styled.div`
display: flex;
justify-content: space-between;
`;


export const FlexAround = styled.div`
display: flex;
justify-content: space-around;
`;

export const Flex = styled.div`
display: flex;
`;

export const Block = styled.div`
display: block;
`;

export const Block2 = styled.div`
  display: block;
  text-align: center;
  width: 300px;
  @media (max-width: 980px) {
    text-align: start;
    width: 100%;
    &.last-stepper {
        margin-top: 1.25rem;
      }
  }
`;

export const InitialDiv = styled.div`
`;

export const TextPills = styled.div`
    color: #22ae62;
    width: fit-content;
    height: fit-content;
    border-radius: 7px;
    font-size: 14px;
    font-weight: 700;
`;


export const Pills = styled.div`
    background-color: #22ae62;
    color: white;
    width: fit-content;
    height: fit-content;
    padding: 5px;
    border-radius: 7px;
    font-size: 12px;
`;


export const OrderPills = styled.div`
    background-color: #dbf6e7;
    color: #22ae62;
    border: 1px solid #22ae62;
    width: fit-content;
    height: fit-content;
    padding: 5px;
    border-radius: 7px;
    font-size: 12px;
`;

export const AccordDiv = styled.div`
  padding: 10px 0px 20px 0px;
  font-size: 17px;
  font-weight: 600;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
`;


export const ClosedStripDiv = styled.div`
    position: relative;
    bottom: 12px;
    color: #e61107;
    font-weight: 800;
    background: #0000007d;
    text-align: center;
    width: 97%;
    border-radius: 0px 0px 10px 10px;
    height: 32px;
`;

export const ClosedStripDiv2 = styled.div`
    position: absolute;
    left: 0;
    bottom: 0;
    /* top: 80px; */
    /* right: 151px; */
    color: #e61107;
    font-weight: 800;
    background: #0000007d;
    text-align: center;
    width: 85%;
    height: 32px;
    border-radius: 0px 0px 10px 10px;
    border: "1px solid red";
`;

export const ClosedStripDiv3 = styled.div`
    position: relative;
    bottom: 2px;
    color: #e61107;
    font-weight: 800;
    background: #0000007d;
    text-align: center;
    width: 8rem;
    height: 32px;
    border-radius: 0px 0px 10px 10px;
`;

export const AddPlusItemBtn = styled.div`
    background: #22A45D;
    height: 25px;
    color: white;
    padding: 0px 4px;
    border-radius:5px;
`;

export const CustomizationTag = styled.div`
    border: solid #78d6a3 1px;
    border-radius: 6px;
    padding: 4px 4px;
    margin-right: 6px;
    margin-bottom: 3px;
    color: #22A45D;
    display: inline-block;
    background: rgb(243 255 218);
    font-size: 12px;
    `;

// ************Kayfremes for animation***********************

export const moveTopBottom = keyframes`
    0% {
    opacity: 0;
    transform: translateY(-300px);
    }

    100% {
    opacity: 1;
    /* transform: translate(0); */
    }
    `;




