import { useRef, useState, useEffect } from "react";
import styled from "styled-components";
import { ButtonGreen, FlexBetween, TextHead5, TextHead4, Flex, InitialDiv, TextHead1, moveTopBottom } from "../../styled/styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faXmark, faCircleInfo } from "@fortawesome/pro-solid-svg-icons";
import { faHeart } from "@fortawesome/pro-regular-svg-icons";
import Section from "../section/Section";
import { useHistory } from "react-router";
import { assignMealToSlots, MealSponsored } from "../../../pages/schedules/redux/createScheduleReduxActions";
import { useDispatch, useSelector } from "react-redux";
import { slotDateTimeFormatter, switchSentenceCase, subtotalFunction, mergeSlotDateTime, checkRestaurantAvailabilityForSelectedSlots, priceFormatterUsd, trackPixelEvent } from "../../../utils/utilities";
import { getUserInfo } from "../../../services/user";
import { getSingleFavItemsDetails } from "../../../pages/schedules/redux/createScheduleReduxActions";
import ModalSingleFavItemDetail from "./ModalSingleFavItemDetail";
import PaymentCart from "../paymentscomponent/PaymentCart";
import Accordion from "../accordian/AccordionStyled";
import { useIonAlert, useIonRouter } from "@ionic/react";
import { userMe } from "../../../services/user";
import ModalSpinner from "../modalspinner/ModalSpinner";
import ToolTip from "../alerts/tooltip";
import useDarkMode from "../../hooks/darkmode/DarkMode";

// flex-direction: column;
//   align-items: center;
const ModalDiv = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1000;
  overflow-y: auto;
`;

const ContentDiv = styled.div<{ isDarkMode: boolean }>`
  background-color: ${(props) => (props?.isDarkMode ? "#2e2e2e" : "#fff")};
  margin-bottom: 85px;
  margin-top: 80px;
  padding: 10px;
  z-index: 1000;
  width: 600px;
  max-width: 90%;
  border-radius: 20px;
  text-align: center;
  height: fit-content;
  animation: ${moveTopBottom} 0.4s ease-in-out;
`;

const HeartModalIcon = styled.div`
  padding: 3px;
  background: #f03f3f29;
  border-radius: 40px;
  text-align: center;
  height: -webkit-fit-content;
  font-size: 14px;
  color: #ff000082;
  width: 28px;
  margin-top: 5px;
  margin-left: 10px;
`;

const DoneModalIcon = styled.div`
  width: 60px;
  height: 60px;
  padding: 18px;
  z-index: 10;
  background: #22a45d;
  color: white;
  border-radius: 40px;
  margin: auto;
  position: relative;
  bottom: 65px;
  margin-bottom: -90px;
`;

const BackModalBtn = styled.div`
  position: relative;
  right: 49%;
  font-size: 17px;
  cursor: pointer;
`;

const OverflowDiv = styled.div`
  overflow-y: auto;
  max-height: 200px; /* Adjust the height as needed */

  /* Chrome, Safari, and newer Edge versions */
  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #bebcbc;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-track {
    background-color: #f1f1f1;
    border-radius: 5px;
  }

  /* Firefox */
  scrollbar-width: thin;
  scrollbar-color: #888888 #f1f1f1;

  &::-moz-scrollbar {
    width: 4px;
  }

  &::-moz-scrollbar-thumb {
    background-color: #bebcbc;
    border-radius: 5px;
  }

  &::-moz-scrollbar-track {
    background-color: #f1f1f1;
    border-radius: 5px;
  }
`;

export default function ModalAutofillConfirm(props) {
  const isDarkMode = useDarkMode();
  const history = useHistory();
  const router = useIonRouter();
  const modalRef = useRef();
  const dispatch = useDispatch();
  const user = getUserInfo();
  const [presentAlert] = useIonAlert();

  const showNav = router.routeInfo.pathname.includes("/calendar-slots") ? true : false;
  const { favMealList, scheduleDetails, selectedSlots, scheduleInvitationDetail } = useSelector((state: any) => state?.createScheduleForMe);
  const { scheduleSlug, invitationToken } = useSelector((state: any) => state?.scheduleInvitationState);
  const [loggedUserData, setLoggedUserData] = useState({ attributes: null, id: null });
  const [showModalSlotFill, setShowModalSlotFill] = useState(false);
  //(scheduleDetails !== undefined) ? scheduleDetails?.attributes?.confirm_before_order :
  const [confirmPrior, setConfirmPrior] = useState(selectedSlots[0]?.attributes?.notify_price_increase);
  const { orderType } = useSelector((state: any) => state.getUserRestaurantsLists);

  const [paymentCardId, setPaymentCardId] = useState("");
  const [orderProcess, setOrderProcess] = useState(false);
  const [saveToLaterProcess, setSaveToLaterProcess] = useState(false);
  const [mappedArray, setMappedArray] = useState([]);
  const [filledSlotIds, setFilledSlotIds] = useState([]);
  const [loading, setLoading] = useState(true);

  const slots = selectedSlots || [];
  const favMeals = favMealList || [];

  const filterFavMealsAvailability = () => {
    let tempArray: any = [];
    let filledSlot: any = [];

    slots?.forEach((slotData: any) => {
      let tempMealList = [];
      favMeals?.forEach((list: any) => {
        const data = checkRestaurantAvailabilityForSelectedSlots(list?.attributes?.restaurant?.data?.attributes?.jsonData?.local_hours?.delivery,
          mergeSlotDateTime(slotData?.attributes?.date, slotData?.attributes?.time),
          slotData?.attributes?.delivery_location?.data?.attributes?.timezone
        );
        if (data?.restaurant === "Open") {
          tempMealList.push(list);
        }
      });
      // tempMealList?.forEach((currentObj: any) => {
      //   const distance = findRestaurantDistance(slotData?.attributes?.delivery_location?.data?.attributes?.latitude, slotData?.attributes?.delivery_location?.data?.attributes?.longitude, currentObj?.attributes?.restaurant?.data?.attributes?.latitude, currentObj?.attributes?.restaurant?.data?.attributes?.longitude);
      //   if (distance <= MAX_DISTANCE_IN_MILES) {
      //     tempMealArray.push(currentObj);
      //   }
      // })
      if (tempMealList?.length > 0) {
        const newData = {
          ...slotData,
          attributes: {
            ...slotData.attributes,
            favMeal: {
              data: tempMealList[
                Math.floor(Math.random() * tempMealList.length)
              ],
            },
          },
        };
        tempArray.push(newData);
        filledSlot.push(slotData.id);
      } else {
        tempArray.push(slotData);
      }
    });
    setFilledSlotIds(filledSlot);
    setMappedArray(tempArray);
  };

  useEffect(() => {
    filterFavMealsAvailability();
    callUserData();
    setLoading(false);
  }, []);

  // Get Logged User Info
  const callUserData = async () => {
    try {
      const { data } = await userMe();
      trackPixelEvent("User me");
      setLoggedUserData({ attributes: data, id: data?.id });
    } catch (e) {
      console.log(e);
    }
  };

  // const mappedArray = slots.map((item: any) => { item.favMeal = favMeals[Math.floor(Math.random() * favMeals.length)]; return item; })
  const subTotalPrice = subtotalFunction(mappedArray) * 100;

  // const tax = orderType === "pickup" ? (subTotalPrice * PICKUP_TAX_PERCENT) / 100 : (subTotalPrice * DELIVERY_TAX_PERCENT) / 100;
  // const fees = orderType === "pickup" ? (subTotalPrice * PICKUP_FEES_PERCENT) / 100 : (subTotalPrice * DELIVERY_FEES_PERCENT) / 100;
  const tips = (orderType === "delivery") ? Number(user?.driver_auto_tip) : Number(user?.auto_tip_amt) || 0;
  const tip_subtotal = (tips * filledSlotIds?.length) * 100 || 0;
  const tax_fees_estimate = orderType === "pickup" ? subTotalPrice / 4 : subTotalPrice / 2;
  const totalPrice = subTotalPrice + tax_fees_estimate + tip_subtotal;

  const [skip, setSkip] = useState(3);

  const handlePaymentId = (paymentCardId: any) => {
    setPaymentCardId(paymentCardId);
    // setPaymentCardError(false);
  };

  function showMoreSlots() {
    setSkip((prevSkip) => prevSkip + 3);
  }

  const closeModal = (e: any) => {
    if (modalRef.current === e.target) {
      props?.setShowModal(false);
    }
    // setMappedArray([]);
  };

  const placeOrder = async () => {
    if (orderProcess || saveToLaterProcess || selectedSlots?.length === 0) {
      return false;
    }

    try {
      if (paymentCardId === "" || paymentCardId === undefined) {
        presentAlert({
          header: "",
          message: "No Payment Method Found. Please Add your Card.",
          buttons: ["OK"],
        });
        return false;
      }
      setOrderProcess(true);

      const updateData = {
        slotArray: mappedArray,
        sponsoredBy: scheduleInvitationDetail?.id ? scheduleInvitationDetail?.attributes?.user?.data : loggedUserData,
        price_notify: confirmPrior,
        paymentCardId: paymentCardId,
        order_type: orderType
      };
      let tempArray = [];
      mappedArray?.forEach((obj: any) => {
        if (obj?.attributes?.favMeal?.data !== null) {
          tempArray.push({
            slotId: obj?.id,
            favMealId: obj?.attributes?.favMeal?.data?.id,
            date: obj?.attributes?.date,
            time: obj?.attributes?.time,
            deliveryAddress: obj?.attributes?.delivery_location?.data?.id,
          });
        }
      });

      const payloadData = {
        data: {
          slots: tempArray,
          price_notify: confirmPrior,
          sponsoredBy: scheduleInvitationDetail?.id ? scheduleInvitationDetail?.attributes?.user?.data?.id : user?.id,
          invitationId: scheduleInvitationDetail?.id,
          paymentCardId: paymentCardId,
          order_type: orderType
        },
      };

      dispatch(MealSponsored(payloadData, updateData) as any).then(() => {
        props?.setShowModal((prev: any) => !prev);
        props?.setSelectedSlots([]);
        setOrderProcess(false);
        // props?.setSelectEmptySlots(false);
        // setConfirmPrior(scheduleDetails?.attributes?.confirm_before_order);
      });
    } catch (e) {
      console.log(e);
      setOrderProcess(false);
    }
  };

  const saveMealForSlot = async () => {
    if (orderProcess || saveToLaterProcess || selectedSlots?.length === 0) {
      return false;
    }
    setSaveToLaterProcess(true);
    try {
      const data = {
        slotArray: mappedArray,
        price_notify: confirmPrior,
        order_type: orderType
      };

      dispatch(assignMealToSlots(data) as any).then(() => {
        props?.setShowModal((prev: any) => !prev);
        props?.setSelectedSlots([]);
        setSaveToLaterProcess(false);
        // props?.setSelectEmptySlots(false);
        // setConfirmPrior(scheduleDetails?.attributes?.confirm_before_order);
      });
    } catch (e) {
      console.log(e);
      setSaveToLaterProcess(false);
    }
  };

  const openModalHandler = (id: any, date: any, time: any) => {
    dispatch(getSingleFavItemsDetails(id, date, time) as any);
    setShowModalSlotFill((prev) => !prev);
  };

  return (
    <>
      {loading === false ? (
        <>
          {props?.showModal ? (
            <ModalDiv onClick={closeModal}>
              <ContentDiv isDarkMode={isDarkMode}>
                <BackModalBtn>
                  <FontAwesomeIcon
                    icon={faXmark}
                    className="cursor-pointer"
                    onClick={() => props?.setShowModal((prev: any) => !prev)}
                  />
                </BackModalBtn>
                <DoneModalIcon>
                  <FontAwesomeIcon icon={faCheck} className="text-[25px]" />
                </DoneModalIcon>
                {favMealList?.length > 0 && (
                  <>
                    <InitialDiv className="mt-8">
                      <TextHead1
                        className={isDarkMode ? "text-white" : "text-black"}
                      >
                        {`Autofill [${selectedSlots?.length}] ${selectedSlots?.length > 1
                          ? "Slots with Faves"
                          : "Slot with Fave"
                          }`}
                      </TextHead1>
                      {/* <TextHead2 style={{ color: "black" }}>
                    {`Autofill [${selectedSlots?.length}] ${(selectedSlots?.length > 1) ? "Slots with Faves" : "Slot with Fav"}`}
                  </TextHead2> */}
                    </InitialDiv>
                    <Section>
                      <TextHead5 style={{ fontWeight: "500" }}>
                        Meals match Autofill Settings determined in the Meal
                        Calendar Detailed Settings.
                      </TextHead5>
                      <TextHead5
                        style={{ margin: "10px 0px", fontWeight: "500" }}
                      >
                        {`Payment will process on the day of ${(orderType === "delivery") ? "delivery" : "pickup"}, and will include Taxes and Fees, which can be 50% or more of food costs.`}
                      </TextHead5>
                    </Section>
                  </>
                )}
                {favMealList?.length > 0 ? (
                  <>
                    <InitialDiv className="mx-4 text-left">
                      <Accordion title="Slots" opened={true}>
                        <OverflowDiv>
                          {mappedArray
                            ?.slice(0, skip)
                            ?.map((item: any, index: any) => (
                              <InitialDiv key={index} className="my-2">
                                <TextHead5>
                                  {slotDateTimeFormatter(
                                    item?.attributes?.date,
                                    item?.attributes?.time
                                  )}
                                </TextHead5>
                                <FlexBetween
                                  style={{ alignItems: "center" }}
                                  onClick={() =>
                                    openModalHandler(
                                      item?.attributes?.favMeal?.data?.id,
                                      item?.attributes?.date,
                                      item?.attributes?.time
                                    )
                                  }
                                >
                                  <TextHead4 className="cursor-pointer">
                                    {switchSentenceCase(
                                      "head text",
                                      item?.attributes?.favMeal?.data ? item?.attributes?.favMeal?.data?.attributes.name : "No Fav Meal Available for this slot time!")}
                                  </TextHead4>
                                  <div className="flex items-center">
                                    {item?.attributes?.favMeal?.data && (
                                      <HeartModalIcon>
                                        <FontAwesomeIcon icon={faHeart} />
                                      </HeartModalIcon>
                                    )}

                                    <TextHead5 className="ml-[10px]">
                                      {" "}
                                      {item?.attributes?.favMeal?.data ? `$${item.attributes?.favMeal?.data?.attributes.total.toFixed(2)}` : ""}
                                    </TextHead5>
                                  </div>
                                </FlexBetween>
                              </InitialDiv>
                            ))}
                        </OverflowDiv>

                        {mappedArray.length > 3 &&
                          mappedArray.length >= skip && (
                            <div
                              onClick={showMoreSlots}
                              className="text-green-600 text-center cursor-pointer mt-2"
                            >
                              Show More
                            </div>
                          )}
                      </Accordion>
                    </InitialDiv>
                    <InitialDiv className="mx-4">
                      {/********************  Payment Section Section ******************************/}
                      <Accordion
                        title="Payment"
                        opened={
                          paymentCardId === "" || paymentCardId === undefined
                            ? true
                            : false
                        }
                      >
                        <PaymentCart
                          paymentId={handlePaymentId}
                          userId={user?.id}
                          changePayment={true}
                          selectedCard={null}
                        />
                      </Accordion>
                    </InitialDiv>
                    <Section>
                      {/* <FlexBetween>
                    <TextHead5>Subtotal</TextHead5>
                    <TextHead5>${subTotalPrice.toFixed(2)}</TextHead5>
                  </FlexBetween> */}
                      {/* <FlexBetween>
                    <TextHead5>Taxes</TextHead5>
                    <TextHead5>${tax.toFixed(2)}</TextHead5>
                  </FlexBetween>
                  <FlexBetween>
                    <TextHead5>Fees</TextHead5>
                    <TextHead5>${fees.toFixed(2)}</TextHead5>
                  </FlexBetween>
                  <FlexBetween>
                    <TextHead5>Tips</TextHead5>
                    <TextHead5>${tips.toFixed(2)}</TextHead5>
                  </FlexBetween> */}
                      <FlexBetween>
                        <Flex className="items-baseline">
                          <TextHead5 style={{ fontWeight: "500" }}>
                            Food Subtotal*
                          </TextHead5>
                          <ToolTip
                            className="mt-10"
                            instructions="Food costs are subject to restaurant price changes."
                          />
                        </Flex>
                        <TextHead5 style={{ fontWeight: "500" }}>
                          {priceFormatterUsd(subTotalPrice)}
                        </TextHead5>
                      </FlexBetween>

                      <FlexBetween>
                        <TextHead5 style={{ fontWeight: "500" }}>
                          Tip Subtotal
                        </TextHead5>
                        <TextHead5 style={{ fontWeight: "500" }}>
                          {priceFormatterUsd(tip_subtotal)}
                        </TextHead5>
                      </FlexBetween>

                      <FlexBetween>
                        <Flex className="items-baseline">
                          <TextHead5 style={{ fontWeight: "500" }}>
                            Taxes and Fees Estimate**
                          </TextHead5>
                          <ToolTip
                            className="!mt-[7px]"
                            instructions={`Taxes and Fees are estimates only and subject to change. Actual cost will be calculated on the day of ${(orderType === "delivery") ? "delivery" : "pickup"}.`}
                          />
                        </Flex>
                        <TextHead5 style={{ fontWeight: "500" }}>
                          {priceFormatterUsd(tax_fees_estimate)}
                        </TextHead5>
                      </FlexBetween>

                      <FlexBetween>
                        <TextHead5 style={{ fontWeight: "500" }}>
                          Estimated Total
                        </TextHead5>
                        <TextHead5 style={{ fontWeight: "500" }}>
                          {priceFormatterUsd(totalPrice)}
                        </TextHead5>
                      </FlexBetween>

                      <InitialDiv className="text-left text-[#7a7a7aba] flex items-baseline">
                        <FontAwesomeIcon
                          icon={faCircleInfo}
                          className="mt-[8px] mr-1 text-[13px]"
                        />
                        <TextHead5 className="font-medium">
                          {`Estimates are subject to change. Actual charges are calculated the day of ${(orderType === "delivery") ? "delivery" : "pickup"}.`}
                        </TextHead5>
                      </InitialDiv>

                      <InitialDiv className="text-left flex items-center mt-2">
                        {/* <Flex className="items-center mt-5"> */}
                        <input
                          type="checkbox"
                          style={{ height: "14px", width: "18px" }}
                          className="w-8 accent-green-600 cursor-pointer mr-[-2px]"
                          checked={confirmPrior}
                          onChange={() => setConfirmPrior(!confirmPrior)}
                        />
                        <TextHead5 className="!ml-1">
                          Confirm 48 hours prior if price increases
                        </TextHead5>
                        {/* </Flex> */}
                      </InitialDiv>
                      {/* <FlexBetween style={{ alignItems: "center" }}>
                    <div
                  className="flex"
                  onClick={() => setShowModalprice((prev) => !prev)}
                > 
                  <img alt="" src={cardIconImg} className="mr-2" />
                  <TextHead5>•••• 1234</TextHead5>
                </div>
                    <FontAwesomeIcon icon={faAngleRight} />
                  </FlexBetween> */}
                    </Section>
                    <InitialDiv className="mx-4">
                      {scheduleSlug !== "" && invitationToken !== "" ? (
                        <>
                          <ButtonGreen onClick={() => history.push("/login")}>
                            {switchSentenceCase(
                              "primary button",
                              "PLACE ORDER AFTER LOGIN"
                            )}
                          </ButtonGreen>
                          <ButtonGreen
                            style={{ marginBottom: "20px", marginTop: "5px" }}
                            onClick={() => placeOrder()}
                            className={filledSlotIds.length === 0 ? "cursor-not-allowed" : ""}
                            disabled={filledSlotIds.length === 0}
                          >
                            {switchSentenceCase(
                              "primary button",
                              "PLACE ORDER WITHOUT LOGIN"
                            )}
                          </ButtonGreen>
                        </>
                      ) : (
                        <>
                          <ButtonGreen
                            style={{ marginTop: "15px" }}
                            onClick={() => placeOrder()}
                            disabled={orderProcess || saveToLaterProcess || filledSlotIds.length === 0}
                            className={orderProcess || saveToLaterProcess || filledSlotIds.length === 0 ? "!cursor-not-allowed my-2" : "my-2"}
                          >
                            {switchSentenceCase(
                              "primary button",
                              "PLACE ORDER"
                            )}
                          </ButtonGreen>
                          {showNav &&
                            scheduleDetails?.attributes?.user?.data?.id ===
                            user?.id && (
                              <>
                                <ButtonGreen
                                  style={{ marginTop: "5px" }}
                                  disabled={orderProcess || saveToLaterProcess || filledSlotIds.length === 0}
                                  className={(orderProcess || saveToLaterProcess || filledSlotIds.length === 0) && "!cursor-not-allowed"}
                                  onClick={() => saveMealForSlot()}
                                  title="Use this if you want to authorize payment later, or the order will cancel."
                                >
                                  {switchSentenceCase(
                                    "primary button",
                                    "Save and Come Back Later"
                                  )}
                                </ButtonGreen>
                                <InitialDiv className="text-left text-[#7a7a7aba] flex items-baseline">
                                  <FontAwesomeIcon
                                    icon={faCircleInfo}
                                    className="mr-2 text-[12px]"
                                  />
                                  <TextHead5 className="font-normal">
                                    Save and Come Back Later: Use this if you
                                    want to authorize payment later, or the
                                    order will cancel.
                                  </TextHead5>
                                </InitialDiv>
                              </>
                            )}
                        </>
                      )}
                    </InitialDiv>
                  </>
                ) : (
                  <InitialDiv className="mt-8">
                    <TextHead1
                      style={{ color: isDarkMode ? "white" : "black" }}
                    >
                      No faves are available for autofill!
                    </TextHead1>
                    <ButtonGreen
                      style={{ marginTop: "20px" }}
                      onClick={() => props?.setShowModal((prev: any) => !prev)}
                    >
                      {switchSentenceCase("primary button", "OKAY")}
                    </ButtonGreen>
                  </InitialDiv>
                )}
              </ContentDiv>
              {/* ---------------------------------Modal Schedule Slot Filled------------------- */}
              {showModalSlotFill && (
                <ModalSingleFavItemDetail
                  showMealModal={showModalSlotFill}
                  setShowMealModal={setShowModalSlotFill}
                ></ModalSingleFavItemDetail>
              )}
            </ModalDiv>
          ) : null}
        </>
      ) : (
        <div className="text-center">
          <ModalSpinner />
        </div>
      )}
    </>
  );
}
