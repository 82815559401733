import { useState, useCallback } from "react";
import useDarkMode from "../../hooks/darkmode/DarkMode";


function DynamicToggleTabs({ children }) {

    const isDarkMode = useDarkMode();
    const [activeTab, setActiveTab] = useState("");
    const handleActiveTab = useCallback(label => setActiveTab(label), []);

    const tabs = children.map(child => (
        <button
            onClick={e => {
                e.preventDefault();
                handleActiveTab(child.props.label);
                child?.props?.setOrderType(child.props.label)
            }}
            className={
                child.props.label === child.props.selectedTab
                    ? ["dynamic-tabs__tab", "dynamic-tabs__tab-active"].join(" ")
                    : `dynamic-tabs__tab ${isDarkMode ? "!text-[#ffffff80]" : ""}`
            }
            key={child.props.label}
        >
            {child.props.tabName}
        </button>
    ));
    const tabContent = children.filter(child => child.props.label === child.props.selectedTab);
    return (
        <div>
            <div className="dynamic-tabs__box">{tabs}</div>
            <div>{tabContent}</div>
        </div>
    );
}

function DynamicTab(props) {
    return <>{props.children}</>;
}

export { DynamicToggleTabs, DynamicTab };
