import { faAngleLeft, faStar, faStore } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useIonViewDidEnter } from "@ionic/react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router";
import styled from "styled-components";
import { removeOrderMealItem, setSearchedRestaurantData } from "../../../pages/orderFood/redux/orderFoodActionCreator";
import { removeFavsMealItem } from "../../../pages/restaurant/redux/menuItemActionCreatior";
import { getRestaurantsList, getSearchedRestaurantsData, setRestaurantsListLoading } from "../../../pages/search/redux/searchActionCreator";
import { restaurantsNearPlace, setSelectedRestaurantData } from "../../../services/restaurant";
import { RouteParams } from "../../../type";
import { isAuthenticated, switchSentenceCase, trackPixelEvent, trackStandardPixelEvent } from "../../../utils/utilities";
import sampleMealImage from "../../assets/restaurent-Meal-3.jpg";
import { Flex, FlexBetween, SubText1, TextHead1, TextHead4 } from "../../styled/styled";
import Accordion from "../accordian/AccordionStyled";
import Divider from "../divider/Divider";
import DotSeprator from "../dotseprator/DotSeprator";
import TopHead from "../head/TopHead";
import ModalSearchFilter from "../modals/ModalSearchFilter";
import ModalSpinner from "../modalspinner/ModalSpinner";
import SearchRestaurantsByKeywords from "../searchinput/SearchRestaurantsByKeywords";
import Section from "../section/Section";
import Spinner from "../spinner/Spinner";

const GridSection = styled.div`
  display: grid;
  grid-template-columns: auto auto auto auto;

  @media (max-width: 1100px) {
    grid-template-columns: auto auto auto;
    justify-content: center;
  }

  @media (max-width: 900px) {
    grid-template-columns: auto auto;
    justify-content: center;
  }
  @media (max-width: 600px) {
    grid-template-columns: auto;
    justify-content: center;
  }
`;

export default function RestaurantAllList() {
  // **************** Other Methods ***********************************
  const history = useHistory();
  const dispatch = useDispatch();
  const { pageType } = useParams<RouteParams>();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const type = searchParams.get('type');

  // **************************** States Management *************************************
  const { restaurantsList, nextPage, isResultLoading, searchFilter } = useSelector((state: any) => state.getUserRestaurantsLists);
  const { userDeliveryAddress } = useSelector((state: any) => state.userAddresses);
  const { userSearchLocation } = useSelector((state: any) => state.getUserSearchLocation);
  const { scheduleDetails } = useSelector((state: any) => state?.createScheduleForMe);

  // **************************** hooks *************************************
  const [loading, setLoading] = useState(false);
  const [showFilterModal, setShowFilterModal] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isRefreshed, setIsRefreshed] = useState(false);

  // ********************************* First Time Redering *********************************************
  const placeObject = {
    lat: userDeliveryAddress?.lat ? userDeliveryAddress?.lat : userSearchLocation?.lat,
    lang: userDeliveryAddress?.lang ? userDeliveryAddress?.lang : userSearchLocation?.lang,
    nextPage: nextPage,
    limit: scheduleDetails?.attributes?.limit_per_meal,
    orderType: type
  };

  useEffect(() => {
    if (restaurantsList?.length === 0) {
      setLoading(true);
      showMore();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRefreshed]);

  useIonViewDidEnter(() => {
    trackPixelEvent('Restaurants all lists');
  }, []);

  // *********************************** Functions ******************************************
  // function handleRefresh(event: CustomEvent<RefresherEventDetail>) {
  //   setTimeout(() => {
  //     setIsRefreshed(prev => !prev);
  //     event.detail.complete();
  //   }, 2000);
  // };
  // const handleRefresh = (): Promise<void> => {
  //   return new Promise((resolve) => {
  //     setTimeout(() => {
  //       setIsRefreshed(prev => !prev);
  //       resolve();
  //     }, 2000);
  //   });
  // };

  // *********************************** Filtering for Duplicate Data ***************************************
  const filteredData = Array.from(new Set(restaurantsList?.map((a: any) => a.name))).map(
    (name: any) => {
      return restaurantsList?.find((a: any) =>
        String(name).toLowerCase().includes(String(a.name).toLowerCase())
      );
    }
  );

  const showMore = async () => {
    try {
      if (isAuthenticated() === false) return false;
      dispatch(setRestaurantsListLoading(true) as any);
      const response = await restaurantsNearPlace(placeObject);

      if (response) {
        trackStandardPixelEvent('Search');
        const restaurantData = response?.data?.data?.stores;
        const nextPage = response?.data?.data?.next_page;

        // -----------Filtering for Duplicate Data---------------
        const filteredData = Array?.from(new Set(restaurantData?.map((a: any) => a?.name))).map((name: any) => {
          return restaurantData?.find((a: any) =>
            String(name)?.toLowerCase()?.includes(String(a?.name)?.toLowerCase())
          );
        }
        );
        dispatch(setRestaurantsListLoading(false) as any);
        dispatch(getRestaurantsList(filteredData, nextPage) as any);
        setLoading(false);
      }
    } catch (error) {
      dispatch(setRestaurantsListLoading(false) as any);
      setLoading(false);
      console.log(error);
    }
  };

  function dollarSign(count: any) {
    let sign = "";
    if (count === null) {
      return (sign = "$");
    } else {
      for (let i = 0; i < count; i++) {
        sign += `$`;
      }
      return sign;
    }
  };

  const onRestaurantClick = async (r: any) => {
    const restaurantSendData = {
      id: r._id,
      name: r.name,
      logoPhoto: r.logo_photos[0],
      description: r.description,
      rating: r.weighted_rating_value,
      ratingCount: r.aggregated_rating_count,
      quoteIds: r.quote_ids,
      is_open: r.is_open,
      dollar_signs: r.dollar_signs,
      local_hours: r.local_hours,
    };

    if (pageType === "for-order" || pageType === "for-schedule") {
      dispatch(setSearchedRestaurantData(restaurantSendData) as any);
      await setSelectedRestaurantData(restaurantSendData);
      dispatch(removeOrderMealItem(restaurantSendData?.id) as any);
      history.push(`/restaurantDetail/${restaurantSendData?.id}?type=${type}`);
    } else if (pageType === "for-fave") {
      dispatch(getSearchedRestaurantsData(restaurantSendData) as any);
      await setSelectedRestaurantData(restaurantSendData);
      dispatch(removeFavsMealItem(restaurantSendData?.id) as any);
      history.push(`/restaurant/${restaurantSendData?.id}?type=${type}`);
    }
  };

  const openFilterModal = () => {
    setShowFilterModal(true);
  };

  return (
    <div className="screen">
      <>
        <TopHead
          title="Restaurants"
          leftTextIcon={faAngleLeft}
          leftIconStyle={{ fontSize: "24px" }}
          rightText={"Filter"}
          gotoRightTextHandle={openFilterModal}
        />
        <Divider />

        <div className="grow overflow-auto">

          {/* <IonContent scrollY={!showFilterModal?true:false}>
        <IonRefresher slot="fixed" onIonRefresh={handleRefresh}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher> */}

          {/* <ReactPullToRefresh
            onRefresh={handleRefresh}
            className="pull-refresh"
            style={{ textAlign: 'center' }}
            icon={<span className="genericon genericon-next"></span>}
          > */}

          {(isResultLoading || loading) && nextPage === null ? (
            <ModalSpinner />
          ) : (
            <Section>
              {/* "${searchFilter.query !== undefined ? "for" + " " + searchFilter.query : ""}" */}
              <TextHead1 style={{ marginBottom: "8px" }}>
                {filteredData?.length > 1
                  ? `${filteredData?.length} Results found ${(searchFilter.query !== undefined && searchFilter.query !== "") ? "for" + ` "${searchFilter.query}"` : ""
                  }`
                  : `${filteredData?.length} Result found`}
              </TextHead1>

              <Accordion title="Search">
                <SearchRestaurantsByKeywords pageRoute={`?for=get-food-now&type=${type}`} goToSearch={false} type={type} />
              </Accordion>

              <GridSection>
                {filteredData?.map((restaurantList: any, index: any) => {
                  return (
                    <div
                      className="slideshow !mt-8"
                      onClick={() => onRestaurantClick(restaurantList)}
                      key={index}
                    >
                      <div className="slideshowSlider">
                        <div className="slide">
                          <img
                            //!w-[345px] 
                            className="h-[215px] object-cover cursor-pointer"
                            src={
                              restaurantList?.logo_photos?.length
                                ? restaurantList.logo_photos?.[0]
                                : sampleMealImage
                            }
                            alt="fave-meals-logo"
                          />
                        </div>
                      </div>
                      <div className="px-[10px]">
                        <FlexBetween className="!mt-2 items-center">
                          {/* max-w-xs */}
                          <TextHead4>
                            {switchSentenceCase("head text", restaurantList?.name)}
                          </TextHead4>
                          <div className="grow text-right text-asprimary">
                            {restaurantList.miles?.toFixed(1)} mi
                          </div>
                        </FlexBetween>
                        <FlexBetween className="items-center">
                          {/* max-w-xs */}
                          <SubText1>{dollarSign(restaurantList?.dollar_signs)}</SubText1>
                          {restaurantList?.cuisines?.slice(0, 3)?.map((cuisiens: any, index: any) => {
                            return (
                              <React.Fragment key={index}>
                                <DotSeprator />
                                <SubText1>{cuisiens}</SubText1>
                              </React.Fragment>
                            );
                          })}
                        </FlexBetween>
                        <FlexBetween className="items-center">
                          {/* max-w-xs */}
                          <Flex>
                            <SubText1>
                              {Number(
                                restaurantList?.weighted_rating_value
                                  ? restaurantList?.weighted_rating_value
                                  : 0
                              ).toFixed(1)}
                            </SubText1>
                            {/* <Rate disabled allowHalf defaultValue={restaurantList.weighted_rating_value} style={{ color: "#c1a000", fontSize: "13px" }} /> */}
                            <FontAwesomeIcon
                              icon={faStar}
                              className="text-[#c1a000] text-xs mx-1 mt-[2px]"
                            />
                            <SubText1>({restaurantList?.aggregated_rating_count || 0}{restaurantList?.aggregated_rating_count ? "+" : ''})</SubText1>
                          </Flex>
                          {/* <FontAwesomeIcon icon={faRectangleHistoryCircleUser} className="text-gray-500 text-[14px]" />
                          <SubText1>{Number(restaurantList?.miles).toFixed(2)} mi</SubText1> */}
                          <Flex className="mt-1">
                            {/* <DotSeprator/> */}
                            <FontAwesomeIcon
                              icon={faStore}
                              className="text-gray-500 text-xs mx-1 mt-1"
                            />
                            <SubText1
                              className={
                                restaurantList?.is_open === true ? "!text-green-600" : "!text-red-600"
                              }
                            >
                              {restaurantList?.is_open === true ? "[Open]" : "[Closed]"}
                            </SubText1>
                          </Flex>
                        </FlexBetween>
                      </div>
                    </div>
                  );
                })}
              </GridSection>
              {nextPage !== null && (
                <div
                  className="text-green-600 text-center cursor-pointer mt-5 w-full"
                  onClick={showMore}
                >
                  {isResultLoading && nextPage ? (
                    <span className="text-center">
                      <Spinner />
                    </span>
                  ) : (
                    <span className="mt-2 font-semibold">See More</span>
                  )}
                </div>
              )}
            </Section>
          )}
          {showFilterModal && (
            <ModalSearchFilter showModal={showFilterModal} setShowModal={setShowFilterModal} type={type} />
          )}
          {/* </ReactPullToRefresh> */}
        </div>
        {/* </IonContent> */}
      </>
    </div >
  );
}
