import Divider from "../../components/widgets/divider/Divider";
import TopHead from "../../components/widgets/head/TopHead";
import { faAngleLeft, faTrashCan } from "@fortawesome/pro-solid-svg-icons";
import iconCenterCreditCard from "../../components/assets/IconCenter-Creditcard.png";
import Section from "../../components/widgets/section/Section";
import { ButtonInitial, SubText1, TextHead1, ButtonGreen } from "../../components/styled/styled";
import { switchSentenceCase, isAuthenticated, trackPixelEvent, trackStandardPixelEvent } from "../../utils/utilities";
import { InitialDiv } from "../../components/styled/styled";
import { FlexBetween } from "../../components/styled/styled";
import { Flex } from "../../components/styled/styled";
import { TextHead4 } from "../../components/styled/styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import {
  removeCardApi,
  setDefaultCardApi,
  userPaymentCardListApi,
} from "../../services/paymentCards";
import Spinner from "../../components/widgets/spinner/Spinner";
import ModalSuccessForCards from "../../components/widgets/modals/ModalSuccessForCards";
import ModalConfirmationForCards from "../../components/widgets/modals/ModalConfirmationForCards";
import { IonContent, IonRefresher, IonRefresherContent, IonSpinner, RefresherEventDetail, useIonViewDidEnter } from "@ionic/react";
import ModalAddPaymentCart from "../../components/widgets/modals/ModalAddPaymentCart";
import { getUserInfo } from "../../services/user";
import RadioButtonText from "../../components/widgets/buttons/RadioButtonText";




export default function PaymentMethod() {
  const [selectedId, setSelectedId] = useState("");

  const [cardList, setCardList] = useState([]);

  const [loading, setLoading] = useState(true);
  const [updatingPage, setUpdatingPage] = useState(false);
  const [removeProcessing, setRemoveProcessing] = useState(false);
  const [defaultProcessing, setDefaultProcessing] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showModalSuccess, setShowModalSuccess] = useState(false);
  const [method, setMethod] = useState(false);
  const [showNewPaymentModal, setShowNewPaymentModal] = useState(false);
  const [emailRequired, setEmailRequired] = useState(false);
  const [cardSelected, setCardSelected] = useState(false);
  const [isRefreshed, setIsRefreshed] = useState(false);

  const user = getUserInfo();


  useEffect(() => {
    callData();
  }, [isRefreshed]);


  useIonViewDidEnter(() => {
    trackStandardPixelEvent('AddPaymentInfo', {
      content_category: null,
      content_ids: null,
      contents: null,
      currency: null,
      value: null
    })
  }, []);


  function handleRefresh(event: CustomEvent<RefresherEventDetail>) {
    setTimeout(() => {
      setIsRefreshed(prev => !prev);
      event.detail.complete();
    }, 2000);
  }


  const callData = async () => {
    try {
      if (isAuthenticated() === false) return false;
      const response = await userPaymentCardListApi(user?.id);
      setCardList(response?.data?.data || []);
      trackPixelEvent('User Payment card lists');
      setLoading(false);
      setUpdatingPage(false);
      setEmailRequired(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
      setEmailRequired(e?.response?.data?.data?.message === "Please add email");
    }
  };

  const handleCardChange = (id: any) => {
    setCardSelected(true);
    setSelectedId(id);
  };

  const openSetDefaultModal = (id: any) => {
    if (removeProcessing || defaultProcessing) {
      return false;
    }
    setMethod(false);
    // setSelectedId(id);
    setCardSelected(false);
    setShowConfirmationModal(true);
  };

  const setDefaultCard = async () => {
    try {
      if (removeProcessing || defaultProcessing) {
        return false;
      }
      setDefaultProcessing(true);
      await setDefaultCardApi(selectedId).then((res) => {
        trackPixelEvent('Set Default Card');
        setDefaultProcessing(false);
        setShowModalSuccess(true);
        setUpdatingPage(true);
        callData();
      });
    } catch (e) {
      console.log(e);
      setDefaultProcessing(false);
    }
  };

  const openRemoveModal = (id: any) => {
    if (removeProcessing || defaultProcessing) {
      return false;
    }
    setMethod(true);
    setSelectedId(id);
    setShowConfirmationModal(true);
  };

  const removeCard = async () => {
    try {
      if (removeProcessing || defaultProcessing) {
        return false;
      }

      setRemoveProcessing(true);
      await removeCardApi(selectedId).then((res) => {
        trackPixelEvent('Removed card');
        setRemoveProcessing(false);
        setShowModalSuccess(true);
        setUpdatingPage(true);
        callData();
      });
    } catch (e) {
      console.log(e);
      setRemoveProcessing(false);
    }
  };

  const compareDate = (inputMonth: any, inputYear: any) => {
    const month = inputMonth;
    const year = inputYear;
    const currentDateTime = new Date();
    const currentYear = currentDateTime.getFullYear();
    const currentMonth = currentDateTime.getMonth() + 1;

    // Check if the year is in the past
    if (Number(year) < currentYear) {
      return "Card Expired";
    }

    // Check if the year is the current year and the month is in the past
    if (Number(year) === currentYear && Number(month) < currentMonth) {
      return "Card Expired";
    }
  };

  const handleOpenModal = () => {
    setShowNewPaymentModal(true);
  };

  const handleCloseModal = (value: any) => {
    setShowNewPaymentModal(value);
  };

  const handleRejectModal = () => {
    setShowConfirmationModal(false);
  };

  const handleUpdateData = () => {
    setUpdatingPage(true);
    callData();
  };

  return (
    <div className="screen">
      <TopHead
        title="Payment Methods"
        leftTextIcon={faAngleLeft}
        leftIconStyle={{ fontSize: "24px" }}
      />
      <Divider />
      <IonContent>
        <IonRefresher slot="fixed" onIonRefresh={handleRefresh}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>
        {cardList?.length > 0 && !loading && (
          <Section className="px-4 pt-4 pb-2" nopad={true}>
            <ButtonInitial
              onClick={handleOpenModal}
              style={{ color: "#22A45D", border: "1px solid #22A45D" }}
            >
              {switchSentenceCase("primary button", "ADD CREDIT CARDS")}
            </ButtonInitial>
          </Section>
        )}

        {(loading || updatingPage) && (
          <InitialDiv
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "20rem",
            }}
          >
            <Spinner />
          </InitialDiv>
        )}

        {cardList?.length === 0 && !loading && !updatingPage && (
          <div className="grow overflow-auto grid items-center">
            <Section>
              <img className="ml-auto mr-auto" src={iconCenterCreditCard} alt="credit-card-center" />
              <TextHead1 className="text-center mt-7">You haven’t added a card :)</TextHead1>
              <SubText1 className="text-center mt-5">
                It seems like you haven’t added a credit or debit card. You may easily add a card
                below.
              </SubText1>
              <Section>
                <ButtonInitial
                  onClick={handleOpenModal}
                  style={{ color: "#22A45D", border: "1px solid #22A45D" }}
                >
                  {switchSentenceCase("primary button", "ADD CREDIT CARDS")}
                </ButtonInitial>
              </Section>
            </Section>
          </div>
        )}

        <div className="grow overflow-auto">
          <Section>
            {cardList?.length > 0 &&
              !loading &&
              !updatingPage &&
              cardList?.map((item: any, index: any) => (
                <InitialDiv
                  style={{
                    boxShadow: "1px 3px 8px -1px #dcf0dccf",
                    padding: "15px",
                    borderRadius: "7px",
                    margin: "15px 0px",
                    border: item?.default_card
                      ? "2px solid rgb(34, 164, 93)"
                      : compareDate(item?.exp_month, item?.exp_year)
                        ? "1px solid #a42222"
                        : "1px solid rgb(34, 164, 93)",
                    backgroundColor: compareDate(item?.exp_month, item?.exp_year) ? "#fff2f2" : "",
                  }}
                  key={index}
                >
                  <FlexBetween>
                    <InitialDiv>
                      <Flex
                      //   style={{ cursor: "pointer" }}
                      //   onClick={() => {
                      //     !item?.default_card && openSetDefaultModal(item?.id);
                      //   }}
                      >
                        <TextHead4>XXXX XXXX XXXX {item?.last4}</TextHead4>
                      </Flex>
                      <SubText1 className="mt-2">
                        {item?.exp_month}/{String(item?.exp_year).slice(2, 4)}{" "}
                        <label className="text-red-600">
                          {compareDate(item?.exp_month, item?.exp_year)}
                        </label>
                      </SubText1>
                      <SubText1 className="mt-2" style={{ textTransform: "capitalize" }}>
                        {item?.brand}
                      </SubText1>
                    </InitialDiv>

                    <InitialDiv
                      style={{
                        display: "flex",
                        flexFlow: "column",
                        justifyContent: "space-between",
                      }}
                    >
                      {item?.default_card ? (
                        "(Default)"
                      ) : (
                        <>
                          {defaultProcessing && item?.id === selectedId ? (
                            <IonSpinner name="crescent" color="success" />
                          ) : (
                            <>
                              <RadioButtonText
                                border={{ height: "0px" }}
                                key={index}
                                index={index}
                                name="card"
                                value={item?.id}
                                checked={selectedId}
                                option={item}
                                onSelect={() => {
                                  handleCardChange(item?.id);
                                }}
                              //   border={{ borderBottom: "none" }}
                              />
                            </>
                          )}
                        </>
                      )}
                      {removeProcessing && item?.id === selectedId && (
                        <IonSpinner name="crescent" color="success" />
                      )}
                      {!item?.default_card && !removeProcessing && (
                        <FontAwesomeIcon
                          className="text-xl text-red-600 cursor-pointer"
                          icon={faTrashCan}
                          onClick={() => {
                            openRemoveModal(item?.id);
                          }}
                        />
                      )}
                    </InitialDiv>
                  </FlexBetween>
                </InitialDiv>
              ))}

            {cardList?.length > 1 && !loading && !updatingPage && (
              <ButtonGreen
                className={(!cardSelected) ? "!cursor-not-allowed my-2 opacity-25" : "mt-5 text-center"}
                id=""
                onClick={(id) => {
                  openSetDefaultModal(id);
                }}
                disabled={!cardSelected}
              >
                Set Default
              </ButtonGreen>
            )}

            <ModalSuccessForCards
              firstText={method ? "Card removed!" : "Card set!"}
              secondText={method ? "Your card removed successfully." : "Your default card is set."}
              btnOneText="OK"
              btnTwoText=""
              btnTwoStyle={{ border: "1px solid #e1e1e1", marginTop: "15px" }}
              btnOneGoto={() => {
                setShowModalSuccess(false);
              }}
              btnTwoGoto={() => { }}
              showModal={showModalSuccess}
              setShowModal={setShowModalSuccess}
              buttonOneLoader={false}
              buttonTwoLoader={false}
            />

            <ModalConfirmationForCards
              firstText={"Are you sure you want to"}
              secondText={method ? "remove this card?" : "set this card to default?"}
              btnOneText="NO"
              btnTwoText="YES"
              showModal={showConfirmationModal}
              setShowModal={setShowConfirmationModal}
              rejectAction={handleRejectModal}
              confirmAction={method ? removeCard : setDefaultCard}
              styleBtnSecondText={{ backgroundColor: !method ? "#22A45D" : "" }}
              iconBackgroundColor={method ? "#ca0b00" : ""}
            />
          </Section>
        </div>

        {showNewPaymentModal && (
          <ModalAddPaymentCart
            emailRequired={emailRequired}
            showPaymentModal={showNewPaymentModal}
            setShowPaymentModal={handleCloseModal}
            updateData={handleUpdateData}
          />
        )}
      </IonContent>
    </div>
  );
}
