/* eslint-disable @typescript-eslint/no-unused-vars */
import Divider from "../../components/widgets/divider/Divider";
import TopHead from "../../components/widgets/head/TopHead";
import { faAngleLeft } from "@fortawesome/pro-solid-svg-icons";
import Section from "../../components/widgets/section/Section";
import { ButtonGreen, FlexBetween, TextHead4 } from "../../components/styled/styled";
import { switchSentenceCase } from "../../utils/utilities";
import { useHistory } from "react-router";
import { useState } from "react";
import TabsForFilters from "../../components/widgets/toggleable-tabs/TabsForFilters";
import TabsForFilterPrice from "../../components/widgets/toggleable-tabs/TabsForFilterPrice";



export default function FiltersV2() {
    const history = useHistory();

    const [filterCategoryType, setFilterCategoryType] = useState("Burgers");
    const [filterDietaryType, setFilterDietaryType] = useState("Any");
    const [filterPriceType, setFilterPriceType] = useState("$");


    const filterCategoryTypes = [
        {
            name: "All",
            value: "ALL"
        },
        {
            name: "Brunch",
            value: "BRUNCH"
        },
        {
            name: "Burgers",
            value: "BURGERS"
        },
        {
            name: "Dinner",
            value: "DINNER"
        },
        {
            name: "Chinese",
            value: "CHINESE"
        },
        {
            name: "Pizza",
            value: "PIZZA"
        },
        {
            name: "Salads",
            value: "SALADS"
        },
        {
            name: "Soups",
            value: "SOUPS"
        },
        {
            name: "Breakfast",
            value: "BREAKFAST"
        }
    ]


    const filterDietaryTypes = [
        {
            name: "Any",
            value: "ANY"
        },
        {
            name: "Vegetarian",
            value: "VEGETARIAN"
        },
        {
            name: "Vegan",
            value: "VEGAN"
        },
        {
            name: "Gluten-Free",
            value: "GLUTEN-FREE"
        }
    ]

    const filterPriceTypes = [
        {
            name: "$",
            value: "$"
        },
        {
            name: "$$",
            value: "$$"
        },
        {
            name: "$$$",
            value: "$$$"
        },
        {
            name: "$$$$",
            value: "$$$$"
        },
        {
            name: "$$$$$",
            value: "$$$$$"
        }
    ]

    return (
        <div className="screen">

            <TopHead
                title="Filters"
                leftTextIcon={faAngleLeft}
                leftIconStyle={{ fontSize: "24px" }}
            />
            <Divider />
            <div className="grow overflow-auto">
                <Section>
                    <FlexBetween>
                        <TextHead4 >NOTIFICATIONS</TextHead4>
                        <TextHead4 className="text-gray-500 !text-base">CLEAR ALL</TextHead4>
                    </FlexBetween>
                    <TabsForFilters types={filterCategoryTypes} setFilterType={setFilterCategoryType} />
                </Section>

                <Section>
                    <FlexBetween>
                        <TextHead4 >DIETARY</TextHead4>
                        <TextHead4 className="text-gray-500 !text-base">CLEAR ALL</TextHead4>
                    </FlexBetween>
                    <TabsForFilters types={filterDietaryTypes} setFilterType={setFilterDietaryType} />
                </Section>

                <Section>
                    <FlexBetween>
                        <TextHead4 >PRICE RANGE</TextHead4>
                        <TextHead4 className="text-gray-500 !text-base">CLEAR ALL</TextHead4>
                    </FlexBetween>
                    <TabsForFilterPrice types={filterPriceTypes} setFilterType={setFilterPriceType} />
                </Section>

                <Section>
                    <ButtonGreen onClick={() => history.push({ pathname: "/timeslot" })}>
                        {switchSentenceCase("primary button", "APPLY FILTERS")}
                    </ButtonGreen>
                </Section>
            </div>
        </div >
    );
}