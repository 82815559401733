import { FacebookLogin } from '@capacitor-community/facebook-login';
import { useIonAlert, useIonRouter } from '@ionic/react';
import { useState } from 'react';
import { useHistory } from 'react-router';
import { FacebookLoginButton } from 'react-social-login-buttons';
import { FACEBOOK_PERMISSIONS } from '../../common/constant';
import Spinner from '../../components/widgets/spinner/Spinner';
import store from "../../redux/store";
import { getFacebookUserData, getFacebookUserLocations, setUserData, socialLogin } from '../../services/user';

export default function SignInWithMeta({ loginState }) {
    const router = useIonRouter();
    const history = useHistory();
    const [awaiting, setAwaiting] = useState(false);
    const [presentAlert] = useIonAlert();

    const showAlert = (header: string, message: string, btnMessage: string) => {
        presentAlert({
            header: header,
            message: message,
            buttons: [btnMessage],
        });
    };

    // useEffect(() => {
    //     const initializeFacebook = async () => {
    //         await FacebookLogin.initialize({
    //             appId: process.env.REACT_APP_FACEBOOK_APP_ID
    //         });
    //     };
    //     initializeFacebook();
    // }, []);

    const signInWithMeta = async () => {
        try {
            setAwaiting(true);
            const result = await FacebookLogin.login({ permissions: FACEBOOK_PERMISSIONS });
            // const token: FacebookLoginResponse = await FacebookLogin.getCurrentAccessToken();
            // console.log("token", token);
            if (result?.accessToken?.token) {
                handleLogin('facebook', result?.accessToken?.token);
            }
        } catch (error) {
            setAwaiting(false);
            store.dispatch({
                type: "SET_LOGIN_STATE",
                payload: { loginState: false }
            });
        }
    };


    const handleLogin = async (provider: string, accessToken: string) => {
        try {
            if (accessToken) {
                const userData = await getFacebookUserData(accessToken);
                if (!userData?.data?.email) {
                    setAwaiting(false);
                    store.dispatch({
                        type: "SET_LOGIN_STATE",
                        payload: { loginState: false }
                    });
                    return showAlert("Error", "You have not added your email in your facebook account please add your email in your account for signin with facebook otherwise continue with other method.", "OK");
                }
                const location_res = await getFacebookUserLocations(accessToken);
                const local_data = {
                    id: userData?.data?.id,
                    email: userData?.data?.email,
                    name: userData?.data?.name,
                    picture: userData?.data?.picture?.data?.is_silhouette ? "" : userData?.data?.picture?.data?.url,
                    addresses: location_res?.data?.location,
                    token: accessToken
                };
                await socialLogin(JSON.stringify(local_data), provider).then(
                    async (response: any) => {
                        let data = response?.data;

                        // Google Analytics event snippet
                        if (process.env.REACT_APP_GTAG_ENABLED_FOR_PRODUCTION === "true") {
                            if (window.gtag && data?.user?.new_user === true) {
                                window.gtag('event', 'conversion', { 'send_to': 'AW-16498935235/D9w9CMi297cZEMOLp7s9' });
                            } else if (window.gtag && data?.user?.new_user === false) {
                                window.gtag('event', 'conversion', { 'send_to': 'AW-16498935235/tNh_CPmurrkZEMOLp7s9', 'value': 1.0, 'currency': 'USD' });
                            }
                        }
                        // Google Analytics event snippet end

                        await setUserData(data);
                        setAwaiting(false);
                        store.dispatch({
                            type: "SET_LOGIN_STATE",
                            payload: { loginState: false }
                        });
                        if (router.routeInfo.pathname === '/login') {
                            history.push('/onboarding');
                        }
                        else {
                            store.dispatch({
                                type: "SHOW_ALERT",
                                payload: { show: false }
                            })
                            window.location.reload();
                        }
                    })
                    .catch((error) => {
                        // console.error('Error Social Login:', error);
                        setAwaiting(false);
                        store.dispatch({
                            type: "SET_LOGIN_STATE",
                            payload: { loginState: false }
                        });
                    });
            }
        }
        catch (error) {
            // console.log("Error login with facebook", error);
            setAwaiting(false);
            store.dispatch({
                type: "SET_LOGIN_STATE",
                payload: { loginState: false }
            });
        }
    }

    return (
        <>
            {awaiting ?
                <div className="text-center">
                    <Spinner />
                </div>
                :
                <FacebookLoginButton className='facebook-login-button' style={{ display: "flex", justifyContent: "center", borderColor: "none" }}
                    onClick={() => {
                        setAwaiting(true);
                        store.dispatch({
                            type: "SET_LOGIN_STATE",
                            payload: { loginState: true }
                        });
                        signInWithMeta();
                    }}
                    disabled={loginState ? true : false}>
                    <span>Sign in with Facebook</span>
                </FacebookLoginButton>
            }
        </>
    );
};
