import { useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import {
    ButtonGreen,
    TextHead1,
    InputText,
    InputLabel,
    Astk,
    TextArea1,
    moveTopBottom,
    SubText1
} from "../../styled/styled";
import styled from "styled-components";
import { maxCharacterLimit, switchSentenceCase } from "../../../utils/utilities";
import { useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/pro-solid-svg-icons";
import Section from "../section/Section";
import { updateSchedules } from "../../../pages/schedules/redux/createScheduleReduxActions";
import useDarkMode from "../../hooks/darkmode/DarkMode";

const ModalDiv = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1000;
  overflow-y: auto;
`;

const ContentDiv = styled.div<{ isDarkMode: boolean }>`
  position: absolute;
  background-color: ${props => (props?.isDarkMode ? '#2e2e2e' : '#fff')};
  margin-top: 80px;
  margin-bottom: 50px;
  padding: 10px;
  z-index: 1000;
  width: 600px;
  max-width: 90%;
  border-radius: 20px;
  text-align: center;
  animation: ${moveTopBottom} .4s ease-in-out;
`;

const BackModalBtn = styled.div`
  position: absolute;
  top: 12px;
  left: 12px;
  font-size: 17px;
`;


export default function ModalEditMealSchedule(props: any) {

    const history = useHistory();
    const modalRef = useRef();
    const dispatch = useDispatch();
    const [nameError, setNameError] = useState(false);
    const isDarkMode = useDarkMode();

    const [values, setValues] = useState({
        name: props?.value?.attributes?.name,
        description: props?.value?.attributes?.description
    })

    const handleChange = (e: any) => {
        setNameError(false)
        const { name, value } = e.target;
        setValues((prevValues) => {
            return {
                ...prevValues,
                [name]: value,
            };
        });
    }

    const handleUpdate = async () => {
        try {

            const { name, description } = values;
            if (name.trim() === "") {
                setNameError(true);
                return false;
            }
            else {
                setNameError(false);
            }

            if (name.trim() === "" || nameError) {
                return false;
            }
            const updatedData = {
                data: {
                    name: name,
                    description: description,
                }
            }

            dispatch(updateSchedules(props?.value?.id, updatedData) as any)
                .then(() => {
                    props?.setShowModal((prev: any) => !prev);
                })
        }
        catch (e) {
            console.log(e)
        }
    }


    const closeModal = (e: any) => {
        if (modalRef.current === e.target) {
            props?.setShowModal(false);
        }
    };

    return (
        <>
            {props?.showModal ? (
                <ModalDiv onClick={closeModal}>
                    <ContentDiv isDarkMode={isDarkMode}>
                        <BackModalBtn>
                            <FontAwesomeIcon
                                icon={faChevronLeft}
                                className="cursor-pointer"
                                onClick={() => props?.setShowModal((prev: any) => !prev)}
                            />

                        </BackModalBtn>

                        <TextHead1>Edit Meal Calendar</TextHead1>
                        {/* <SubText1 className="mt-1.5">
                            Meal Edit
                        </SubText1> */}

                        <Section>
                            <InputLabel className="mt-5">Meal Calendar Name <Astk> *</Astk></InputLabel>
                            <InputText
                                style={{
                                    marginBottom: "20px",
                                    border: nameError ? "1px solid red" : "",
                                }}
                                placeholder="e.g. Fusce Rd."
                                name="name"
                                value={values?.name}
                                onChange={(e) => handleChange(e)}
                            >
                            </InputText>

                            <InputLabel className="mt-5">Meal Calendar Description</InputLabel>
                            <SubText1 className="text-right mt-1">{values?.description?.length}/{maxCharacterLimit}</SubText1>
                            <TextArea1
                                className="!mt-[0px]"
                                placeholder="Enter description."
                                name="description"
                                value={values?.description}
                                onChange={handleChange}
                                maxLength={maxCharacterLimit}
                            />

                        </Section>

                        <Section>
                            <ButtonGreen
                                style={{ margin: "5px 0px" }}
                                onClick={() => handleUpdate()}
                            >
                                {switchSentenceCase("primary button", "UPDATE")}
                            </ButtonGreen>

                        </Section>

                    </ContentDiv>
                </ModalDiv>
            )
                :
                null}
        </>
    );
}
