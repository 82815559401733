// import Section from "../../components/widgets/section/Section";
import { ButtonInitial, SubText1, TextHead1 } from "../../components/styled/styled";
import { switchSentenceCase } from "../../utils/utilities";
import { useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/pro-solid-svg-icons";
import { useHistory } from "react-router";



export default function ScanCard() {

    const history = useHistory();

    let videoRef = useRef(null);
    let PhotoRef = useRef(null);

    // get access to user webcamera


    const getUserCamera = () => {
        navigator.mediaDevices.getUserMedia({
            video: true
        })
            .then((stream) => {
                //Attaching the stream to the video tag

                let video = videoRef.current

                video.srcObject = stream

                video.play()
            })
            .catch((error) => {
                console.error(error)
            })
    }


    // to taking picture of user
    const takePicture = () => {


        // width and height
        const width = 340;
        const height = width / (16 / 9);
        let photo = PhotoRef.current;
        let video = videoRef.current;


        // setting photo width and height
        photo.width = width;
        photo.height = height;

        let ctx = photo.getContext('2d');

        ctx.drawImage(video, 0, 0, photo.width, photo.height);
    }

    useEffect(() => {
        getUserCamera()
    }, [videoRef])


    return (
        <>
            <FontAwesomeIcon icon={faAngleLeft} onClick={() => history.push({ pathname: "/addpaymentcard" })} style={{
                position: "absolute",
                top: "20px",
                color: "white",
                zIndex: "10",
                left: "12px",
                fontSize: "20px"
            }}
            />
            <video ref={videoRef} style={{
                maxWidth: "none", height: "100%", position: "relative", right: "200px"
            }}>
            </video>
            <div className="card-bg">
                {/* <canvas ref={PhotoRef}></canvas> */}
                <div style={{
                    position: "relative",
                    top: "100px",
                }}
                >
                    <TextHead1 className="text-center mt-7" style={{ color: "white" }}>
                        Don’t have any card :)
                    </TextHead1>
                    <SubText1 className="text-center mt-5" style={{ color: "white" }}>
                        It’s seems like you don’t add any credit or debit card. You may easily add card.
                    </SubText1>
                </div>
            </div >
            <div style={{

                height: "175px",
                width: "305px",
                position: "absolute",
                top: "270px",
                left: "35px",
                border: "3px solid green",
                backdropFilter: "brightness(4.5)",
            }}
            >
            </div>
            <ButtonInitial onClick={takePicture} style={{ color: "#22A45D", border: "1px solid #22A45D" }}>
                {switchSentenceCase("primary button", "CAPTURE")}
            </ButtonInitial>
        </>

    );
}