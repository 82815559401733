import Section from "../../components/widgets/section/Section";
import TopHead from "../../components/widgets/head/TopHead";
import Divider from "../../components/widgets/divider/Divider";
import { ButtonGreen, SubText1, TextHead1, InputText2 } from "../../components/styled/styled";
import { useHistory } from "react-router";
import { switchSentenceCase } from "../../utils/utilities";
import {
  faAngleLeft
} from "@fortawesome/pro-solid-svg-icons";
import otherTipImg from "../../components/assets/OtherTip-BG.jpg";



export default function OtherTip(data: any) {
  const history = useHistory();

  const GotoHandle = () => {
    history.push({
      pathname: "/scheduledetailsrecipientsnew",
    });
  };




  return (
    <div className="screen">
      <TopHead
        title="Add A Tip"
        leftTextIcon={faAngleLeft}
        leftIconStyle={{ fontSize: "24px" }}
        goToHandle={GotoHandle}
      />
      <Divider />

      <div className="grow overflow-auto">
        <Section>
          <TextHead1 style={{ marginBottom: "8px" }}>
            Add a Tip
          </TextHead1>
          <InputText2 className="my-4" placeholder="$4.50" />
          <SubText1>100% of your tip goes to the meal driver.</SubText1>
        </Section>
        <Section>
          <img src={otherTipImg} alt="" className="ml-auto mr-auto" />
        </Section>
        <Section>
          <ButtonGreen
            onClick={() => history.push({ pathname: "/recipetveto" })}
            style={{ margin: "15px 0px" }}
          >
            {switchSentenceCase("primary button", "CONTINUE")}
          </ButtonGreen>
        </Section>
      </div>
    </div>
  );
}
