import { faAngleLeft, faBell } from "@fortawesome/pro-solid-svg-icons";
import { useIonViewDidEnter } from "@ionic/react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { ButtonGreen, InputLabel, InputText, SubText1, TextHead1, TextHead2, TextHead3 } from "../../components/styled/styled";
import IonModalAlert from "../../components/widgets/alerts/IonModalAlert";
import OneSideTextToggle from "../../components/widgets/buttons/OneSideTextToggle";
import ToggleWithSubText from "../../components/widgets/buttons/ToggleWithSubText";
import Divider from "../../components/widgets/divider/Divider";
import TopHead from "../../components/widgets/head/TopHead";
import ModalChooseOption from "../../components/widgets/modals/ModalChooseOption";
import ModalShowPaymentDetailsForAutofill from "../../components/widgets/modals/ModalShowPaymentDetailsForAutofill";
import ModalSpinner from "../../components/widgets/modalspinner/ModalSpinner";
import Section from "../../components/widgets/section/Section";
import TabsForSpendingLimit from "../../components/widgets/toggleable-tabs/TabsForSpendingLimit";
import { getMealCategory } from "../../services/favourite";
import { userPaymentCardListApi } from "../../services/paymentCards";
import { addNewSchedule, getCalendarData, getScheduleById, setCalendarData } from "../../services/schedule";
import { authorizePaymentForCalendar } from "../../services/schedule_slot";
import { getUserInfo } from "../../services/user";
import { maskCurrencyInput, switchSentenceCase, trackPixelEvent, trackStandardPixelEvent } from "../../utils/utilities";
import { calendarRecipientData, createScheduleForMeClear, createScheduleForMeStep1, createScheduleForMeStep2, setScheduleDetailsRedux, updateSchedules } from "../schedules/redux/createScheduleReduxActions";

const AUTO_FILL_FAVE = process.env.REACT_APP_AUTO_FILL_FAVE;

export default function NewMealPayments(data: any) {
  const history = useHistory();
  const dispatch = useDispatch();
  const user = getUserInfo();

  const { scheduleDetails } = useSelector((state: any) => state?.createScheduleForMe);
  const { orderType } = useSelector((state: any) => state.getUserRestaurantsLists);

  const scheduleValues = useSelector((state: any) => state?.createScheduleForMe);
  const spendingTypes = [
    {
      name: "None",
      value: "0",
    },

    {
      name: "$20",
      value: "20",
    },

    {
      name: "$30",
      value: "30",
    },

    {
      name: "$40",
      value: "40",
    },

    {
      name: "Other",
      value: "Other",
    },
  ];

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [switchSingleSponsor, setSwitchSingleSponsor] = useState((scheduleDetails !== undefined && scheduleDetails?.attributes) ? scheduleDetails?.attributes?.invitations?.data?.length > 0 ? false : true : scheduleValues?.invitations?.length > 0 ? false : true);
  const [includesFees, setIncludesFees] = useState(scheduleDetails?.attributes?.includes_fees_n_tips || false);
  const [autoFillSlots, setAutoFillSlots] = useState(scheduleDetails?.attributes?.autofill_slots_with_favs || false);
  const [confirmNotifyPriceIncrease, setConfirmNotifyPriceIncrease] = useState(scheduleDetails?.attributes?.notify_price_increase !== undefined ? scheduleDetails?.attributes?.notify_price_increase : true);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [pushNotification, setPushNotification] = useState(scheduleDetails?.attributes?.push_notify !== undefined ? scheduleDetails?.attributes?.push_notify : true);
  const [smsNotification, setSmsNotification] = useState(scheduleDetails?.attributes?.sms_notify !== undefined ? scheduleDetails?.attributes?.sms_notify : true);
  const [alertHour, setAlertHour] = useState(scheduleDetails?.attributes?.alert_hour !== undefined ? scheduleDetails?.attributes?.alert_hour : false);
  const [authorizePayment, setAuthorizePayment] = useState(scheduleDetails?.attributes?.authorize_payment !== undefined ? scheduleDetails?.attributes?.authorize_payment : false);
  const [active, setActive] = useState(spendingTypes[0]?.value);
  const [perMealValue, setPerMealValue] = useState("");
  const [otherAmount, setOtherAmount] = useState("");
  const [amountError, setAmountError] = useState(false);
  const [awaiting, setAwaiting] = useState(false);
  const [inputList, setInputList] = useState([{ id: "", name: "", selected: false, default: false }]);
  const [selectedCategoriesId, setSelectedCategoriesId] = useState([]);

  const [showIonAlertModal, setShowIonAlertModal] = useState(false);
  const [ionAlertHeader, setIonAlertHeader] = useState("");
  const [ionAlertMessage, setIonAlertMessage] = useState("");
  const [showPaymentDetail, setShowPaymentDetail] = useState(false);
  const [paymentData, setPaymentData] = useState({});
  const [cardData, setCardData] = useState([]);
  const [showOptionModal, setShowOptionModal] = useState(false);

  const GotoHandle = () => {
    history.push({
      pathname: "/mealforsomeoneelse",
    });
  };

  useEffect(() => {
    callData();
    // CategoryListData();
  }, []);

  window.onload = async () => {
    const data = await getCalendarData();
    dispatch(createScheduleForMeStep1(data?.step1) as any);
    dispatch(createScheduleForMeStep2(data?.step2) as any);
    if (data?.recipientData !== undefined) {
      calendarRecipientData(data?.recipientData);
    }
  };

  useIonViewDidEnter(() => {
    trackStandardPixelEvent("Schedule");
  }, []);

  const callData = async () => {
    const card_res = await userPaymentCardListApi(user?.id);
    const data = card_res?.data?.data;
    setCardData(data);

    const filteredStates = spendingTypes.filter((data) => data?.value === String(scheduleDetails?.attributes?.limit_per_meal));

    if (filteredStates?.length > 0) {
      setPerMealValue(filteredStates[0]?.value);
      setActive(filteredStates[0]?.value);
    } else if (
      scheduleDetails?.attributes?.limit_per_meal === null ||
      scheduleDetails?.attributes?.limit_per_meal === undefined
    ) {
      setPerMealValue(spendingTypes[0]?.value);
      setActive(spendingTypes[0]?.value);
    } else {
      setPerMealValue("Other");
      setActive(spendingTypes[4]?.value);
      setOtherAmount("$" + scheduleDetails?.attributes?.limit_per_meal);
    }
    let query = {
      suggested: scheduleDetails?.id ? (scheduleDetails?.attributes?.user?.data?.id !== user?.id ? true : "All") : (scheduleValues?.type === "for_other" ? true : "All")
    }
    const response = await getMealCategory(
      scheduleDetails?.id ? scheduleDetails?.attributes?.user?.data?.id : scheduleValues?.type === "for_other" ? scheduleValues?.recipientId : user?.id,
      query
    );
    // setFaveMealCategory(response?.data?.data);
    trackPixelEvent("Get favourite category");

    let tempArray = [];
    if (scheduleDetails?.id) {
      const categories: [] = scheduleDetails?.attributes?.meal_categories?.data;

      categories.forEach((list: any) => {
        tempArray.push(list?.id);
      });
      setSelectedCategoriesId(tempArray);
    }

    let tempArray1 = [];
    let selectedIds = [];
    const category: [] = response?.data?.data;
    category?.forEach((currentObj: any) => {
      // listId.push(currentObj?.id);
      if (currentObj?.attributes?.defaultMeal && !scheduleDetails?.id) {
        tempArray1.push({
          id: currentObj?.id,
          name: currentObj?.attributes?.name,
          selected: true,
          default: true,
        });
        selectedIds.push(currentObj?.id);
      } else {
        tempArray1.push({
          id: currentObj?.id,
          name: currentObj?.attributes?.name,
          selected: scheduleDetails?.id ? tempArray.includes(currentObj?.id) : true,
          default: false,
        });
        selectedIds.push(scheduleDetails?.id ? tempArray.includes(currentObj?.id) ? currentObj?.id : null : currentObj?.id);
      }
    });

    const filterSelectedIds = selectedIds.filter((element) => {
      return element !== null;
    });
    setInputList(tempArray1);
    setSelectedCategoriesId(filterSelectedIds);
  };


  function handleToggleLimitIncludes() {
    setIncludesFees(!includesFees);
  }

  function handleToggleAutoFill() {
    setAutoFillSlots(!autoFillSlots);
    if (!autoFillSlots) {
      setShowOptionModal(true);
    }
  }

  function handleToggleConfirmOrder() {
    setConfirmNotifyPriceIncrease(!confirmNotifyPriceIncrease);
  }

  function handleToggleSmsNotification() {
    setSmsNotification(!smsNotification);
  }

  function handleToggleAlertHour() {
    setAlertHour(!alertHour);
  }
  function handleAuthorizePayment() {
    setAuthorizePayment(!authorizePayment);
  }
  const handleChangeOtherAmt = (val: any) => {
    let maskedValue = maskCurrencyInput(val);
    setAmountError(false);
    setOtherAmount(maskedValue);
  };

  const handleFillSlots = async (value: string) => {
    setShowOptionModal(false);
  };

  const authorizePaymentForSlots = async () => {
    try {
      const user = await getUserInfo();

      if (perMealValue === "Other" && otherAmount === "") {
        setAmountError(true);
        return false;
      } else {
        setAmountError(false);
      }
      setAwaiting(true);

      // Re-Formatting Schedule Slot multiple time from state
      let time = [];
      scheduleValues?.slots?.time?.forEach((timeObj: any) => {
        time.push(timeObj?.startTime);
      });

      const reformattedSlotData = { ...scheduleValues?.slots, time };
      const payloadData = {
        data: {
          user: user?.id,
          slots: reformattedSlotData,
          type: scheduleValues?.type,
          recipientId: scheduleValues?.type === "for_other" ? scheduleValues?.recipientId : "",
          limit_per_meal: perMealValue === "Other" ? Number(otherAmount) : Number(perMealValue),
          authorize_payment: authorizePayment,
          includes_fees_n_tips: includesFees,
          autofill_slots_with_favs: autoFillSlots,
          notify_price_increase: confirmNotifyPriceIncrease,
          addressId: scheduleValues?.addressId,
          meal_categories: selectedCategoriesId,
          order_type: autoFillSlots ? orderType : ""
        },
      };
      const response = await authorizePaymentForCalendar(payloadData);

      if (response) {
        setPaymentData(response?.data?.data);
        const data = {
          user: user?.id,
          slots: reformattedSlotData,
          new_slots: response?.data?.data?.slots,
          type: scheduleValues?.type,
          name: scheduleValues?.name,
          senderName: scheduleValues?.senderName,
          description: scheduleValues?.description,
          recipientName: scheduleValues?.type === "for_other" ? scheduleValues?.recipientName : "",
          recipientId: scheduleValues?.type === "for_other" ? scheduleValues?.recipientId : "",
          invitations: scheduleValues?.invitations,
          limit_per_meal: perMealValue === "Other" ? Number(otherAmount) : Number(perMealValue),
          single_sponsor: switchSingleSponsor,
          authorize_payment: authorizePayment,
          includes_fees_n_tips: includesFees,
          autofill_slots_with_favs: autoFillSlots,
          notify_price_increase: confirmNotifyPriceIncrease,
          push_notify: pushNotification,
          sms_notify: smsNotification,
          alert_hour: alertHour,
          addressId: scheduleValues?.addressId,
          meal_categories: selectedCategoriesId,
          order_type: autoFillSlots ? orderType : ""
        };
        let calendarData = await getCalendarData();
        const newUpdatedCalendarData = {
          ...calendarData,
          step3: data,
        };
        await setCalendarData(newUpdatedCalendarData);
        setShowPaymentDetail(true);
        setAwaiting(false);
      }
    } catch (e) {
      setAwaiting(false);
      setShowIonAlertModal(true);
      setIonAlertHeader(e?.response?.data?.error?.name ? e?.response?.data?.error?.name : e?.response?.status);
      setIonAlertMessage(e?.response?.data?.error?.message ? e?.response?.data?.error?.message : e?.response?.statusText);
      console.log(e);
    }
  };

  const saveData = async () => {
    try {
      const user = await getUserInfo();
      if (perMealValue === "Other" && otherAmount === "") {
        setAmountError(true);
        return false;
      } else {
        setAmountError(false);
      }

      setAwaiting(true);

      // Re-Formatting Schedule Slot multiple time from state
      let time = [];
      scheduleValues?.slots?.time?.forEach((timeObj: any) => {
        time.push(timeObj?.startTime);
      });

      const reformattedSlotData = { ...scheduleValues?.slots, time };

      const payloadData = {
        data: {
          user: user?.id,
          slots: reformattedSlotData,
          new_slots: [],
          type: scheduleValues?.type,
          name: scheduleValues?.name,
          senderName: scheduleValues?.senderName,
          description: scheduleValues?.description,
          recipientName: scheduleValues?.type === "for_other" ? scheduleValues?.recipientName : "",
          recipientId: scheduleValues?.type === "for_other" ? scheduleValues?.recipientId : "",
          invitations: scheduleValues?.invitations,
          limit_per_meal: perMealValue === "Other" ? Number(otherAmount) : Number(perMealValue),
          single_sponsor: switchSingleSponsor,
          authorize_payment: authorizePayment,
          includes_fees_n_tips: includesFees,
          autofill_slots_with_favs: autoFillSlots,
          notify_price_increase: confirmNotifyPriceIncrease,
          push_notify: pushNotification,
          sms_notify: smsNotification,
          alert_hour: alertHour,
          addressId: scheduleValues?.addressId,
          meal_categories: selectedCategoriesId,
          order_type: autoFillSlots ? orderType : ""
        },
      };

      const response = await addNewSchedule(payloadData);
      if (response) {
        trackPixelEvent("Add new schedule");
        // Google Analytics event snippet
        if (process.env.REACT_APP_GTAG_ENABLED_FOR_PRODUCTION === "true") {
          window.gtag('event', 'conversion', { 'send_to': 'AW-16498935235/1l02CMGBhrkZEMOLp7s9', 'value': 1.0, 'currency': 'USD' });
        }
        dispatch(createScheduleForMeClear() as any);
        localStorage.removeItem("CalendarData");
        const scheduleResponse = await getScheduleById(response?.data?.data?.id);
        if (scheduleResponse) {
          trackPixelEvent("Get schedule by id");
          setScheduleDetailsRedux(scheduleResponse?.data?.data);
          setAwaiting(false);
          history.push({
            pathname: `/calendar-slots/${encodeURIComponent(
              response?.data?.data?.id
            )}`,
          });
        }
      }
    } catch (e) {
      setAwaiting(false);
      setShowIonAlertModal(true);
      setIonAlertHeader(e?.response?.data?.error?.name ? e?.response?.data?.error?.name : e?.response?.status);
      setIonAlertMessage(e?.response?.data?.error?.message ? e?.response?.data?.error?.message : e?.response?.statusText);
      console.log(e);
    }
  };

  const updateData = async () => {
    try {
      if (perMealValue === "Other" && otherAmount === "") {
        setAmountError(true);
        return false;
      } else {
        setAmountError(false);
      }
      setAwaiting(true);

      const updatedData = {
        data: {
          limit_per_meal: perMealValue === "Other" ? Number(otherAmount.slice(1)) : Number(perMealValue),
          single_sponsor: switchSingleSponsor,
          includes_fees_n_tips: includesFees,
          autofill_slots_with_favs: autoFillSlots,
          notify_price_increase: confirmNotifyPriceIncrease,
          push_notify: pushNotification,
          sms_notify: smsNotification,
          authorize_payment: authorizePayment,
          alert_hour: alertHour,
          meal_categories: selectedCategoriesId,
        },
      };
      dispatch(updateSchedules(scheduleDetails?.id, updatedData) as any).then(
        () => {
          setAwaiting(false);
          history.push({
            pathname: `/view-calendar/${scheduleDetails?.attributes?.slug}/${user?.slug === "" || user?.slug === null ? user?.id + "d" : user?.slug + "s"}`,
          });
        }
      );
    } catch (e) {
      setAwaiting(false);
      setShowIonAlertModal(true);
      setIonAlertHeader(e?.response?.status);
      setIonAlertMessage(e?.response?.statusText);
      console.log(e);
    }
  };

  const handleSwitch = async (id: any, value: any, index) => {
    const prevList: any = [...inputList];
    prevList[index]["selected"] = value;
    setInputList(prevList);
    if (selectedCategoriesId.indexOf(id) === -1) {
      const list = [...selectedCategoriesId];
      const newData = [id, ...list];
      setSelectedCategoriesId(newData);
    } else {
      const i = selectedCategoriesId.indexOf(id);
      const list = [...selectedCategoriesId];
      list.splice(i, 1);
      setSelectedCategoriesId(list);
    }
  };

  return (
    <>
      {awaiting ? (
        <ModalSpinner />
      ) : (
        <div className="screen">
          <TopHead
            title={
              scheduleDetails !== undefined
                ? switchSentenceCase(
                  "head text",
                  scheduleDetails?.attributes?.name
                )
                : "New Meal Calendar"
            }
            leftTextIcon={faAngleLeft}
            leftIconStyle={{ fontSize: "24px" }}
            goToHandle={GotoHandle}
          />
          <div className="grow overflow-auto">
            <Divider />
            <Section>
              <TextHead1>Payments</TextHead1>
              <InputLabel>
                By default each sponsor pays for the meal time slots they
                select.
              </InputLabel>
            </Section>

            {/* <Section>
                <TextHead2>BILLING</TextHead2>
                <ToggleWithSubText
                  iconLeft={faLock}
                  leftIconStyle={{ color: "#6b7280", fontSize: "1.25rem" }}
                  text="Single Sponsor"
                  subText="Allow recipient to fill slots and I will pay for it."
                  name="singleSponsor"
                  switchState={switchSingleSponsor}
                  handleOnChangeToggle={handleToggleSponsor}
                  disabled={disableSingleSponsored}
                />
                <Divider />
              </Section> */}

            <Section>
              <TextHead3 style={{ marginBottom: "10px" }}>
                Spending Limit per meal
              </TextHead3>

              <TabsForSpendingLimit
                types={spendingTypes}
                setPerMealValue={setPerMealValue}
                setActive={setActive}
                selected={active}
              />
              {perMealValue === "Other" && (
                <InputText
                  type="text"
                  inputMode="numeric"
                  style={{
                    border: amountError ? "1px solid red" : "",
                  }}
                  min={0}
                  value={otherAmount}
                  placeholder="Enter Amount"
                  onChange={(event: any) =>
                    handleChangeOtherAmt(event.target.value)
                  }
                />
              )}

              <OneSideTextToggle
                leftText="Limit Includes Fees, Tips and Taxes?"
                style={{ fontWeight: "500" }}
                switchState={includesFees}
                handleOnChangeToggle={handleToggleLimitIncludes}
              />
            </Section>

            <Section>
              <TextHead2>FAVE MEAL CATEGORIES</TextHead2>

              {inputList?.length > 0 ? (
                inputList.map((item: any, index: any) => {
                  return (
                    <React.Fragment key={index}>
                      <OneSideTextToggle
                        leftText={
                          item?.default === true
                            ? switchSentenceCase("head text", item?.name) +
                            ` (Default)`
                            : switchSentenceCase("head text", item?.name)
                        }
                        style={{ fontSize: "17px", fontWeight: "500" }}
                        name={item?.name}
                        id={item?.id}
                        switchState={item?.selected}
                        handleOnChangeToggle={() =>
                          handleSwitch(
                            item?.id,
                            !inputList[index]?.selected,
                            index
                          )
                        }

                      // handleOnChangeToggle={() => { handleSwitch((!(inputList[index]?.selected)), index) }}
                      />
                    </React.Fragment>
                  );
                })
              ) : (
                <SubText1 className="text-center !text-gray-400 !text-base mb-10">
                  Recipient has no Meal Faves. When they create faves, they will
                  be added to the calendar by default.
                </SubText1>
              )}
              <Divider />
            </Section>
            {AUTO_FILL_FAVE === "true" && (
              <Section>
                <TextHead2>AUTOFILL FAVES SETTINGS</TextHead2>
                <ToggleWithSubText
                  style={{ marginLeft: "0px" }}
                  text="Autofill Slots With Faves"
                  subText="Prepopulate the orders with faves and pay later. No decision fatigue :)"
                  name="autoFillSlots"
                  switchState={autoFillSlots}
                  disabled={scheduleDetails?.attributes?.autofill_slots_with_favs !== undefined}
                  handleOnChangeToggle={handleToggleAutoFill}
                />

                {autoFillSlots && (cardData?.length > 0) && (
                  <div className="">
                    {/* <TextHead2 className="!text-[15px] !mb-0">PAYMENT AUTHORIZATION</TextHead2> */}
                    <ToggleWithSubText
                      style={{ marginLeft: "0px" }}
                      text="Authorize Payment"
                      subText="Authorize payment with autofill if you have added your default card."
                      name="autoPayment"
                      switchState={authorizePayment}
                      disabled={scheduleDetails?.attributes?.authorize_payment !== undefined}
                      handleOnChangeToggle={handleAuthorizePayment}
                    />
                  </div>
                )}
                <Divider />
              </Section>
            )}
            <Section>
              <TextHead2>NOTIFICATIONS</TextHead2>
              {/* <ToggleWithSubText
                disabled={true}
                  iconLeft={faBell}
                  leftIconStyle={{ color: "#6b7280", fontSize: "1.25rem" }}
                  text="Push Notifications"
                  subText={'\u00A0'}
                  name="pushNotification"
                  switchState={pushNotification}
                  handleOnChangeToggle={handleTogglePushNotification}
                />
                <Divider /> */}

              <ToggleWithSubText
                iconLeft={faBell}
                leftIconStyle={{ color: "#6b7280", fontSize: "1.25rem" }}
                text="Possible Price Changes"
                name="priceChangeAlert"
                subText="If order price is expected to change greater than 20%, notify within 48 hours of delivery."
                switchState={confirmNotifyPriceIncrease}
                disabled={scheduleDetails?.attributes?.notify_price_increase !== undefined}
                handleOnChangeToggle={handleToggleConfirmOrder}
              />
              <Divider />
              <ToggleWithSubText
                iconLeft={faBell}
                leftIconStyle={{ color: "#6b7280", fontSize: "1.25rem" }}
                text="SMS Notifications"
                name="smsNotification"
                subText={"\u00A0"}
                switchState={smsNotification}
                handleOnChangeToggle={handleToggleSmsNotification}
              />
              <Divider />
              <ToggleWithSubText
                iconLeft={faBell}
                leftIconStyle={{ color: "#6b7280", fontSize: "1.25rem" }}
                text="48 Hour Warning"
                subText="Alert me 48 hours before there are empty slots expiring"
                name="alertHour"
                switchState={alertHour}
                handleOnChangeToggle={handleToggleAlertHour}
              />
              <Divider />
              <Section>
                <TextHead1>Cancellations</TextHead1>
                <InputLabel>
                  The Recipient can cancel or move a meal up to the morning of
                  the delivery date. Sponsors can also cancel up to the morning
                  of the delivery date for a full refund.
                </InputLabel>
              </Section>
            </Section>

            <Section>
              {scheduleDetails?.id ? (
                <ButtonGreen onClick={updateData}>
                  {switchSentenceCase("primary button", "UPDATE")}
                </ButtonGreen>
              ) : (
                <ButtonGreen
                  onClick={
                    authorizePayment ? authorizePaymentForSlots : saveData
                  }
                >
                  {switchSentenceCase("primary button", "SAVE & FILL SLOTs")}
                </ButtonGreen>
              )}
            </Section>
          </div>
        </div>
      )}

      <IonModalAlert
        isOpened={showIonAlertModal}
        setIsOpened={setShowIonAlertModal}
        header={ionAlertHeader}
        subHeader=""
        message={ionAlertMessage}
      />
      {showPaymentDetail && (
        <ModalShowPaymentDetailsForAutofill
          showModal={showPaymentDetail}
          setShowModal={setShowPaymentDetail}
          data={paymentData}
        />
      )}

      {setShowOptionModal &&
        <ModalChooseOption
          showModal={showOptionModal}
          setShowModal={setShowOptionModal}
          selectedValue={handleFillSlots}
        />
      }
    </>
  );
}
