import React from "react";
import styled from 'styled-components';
import AccordionCheckoutCustomization from "../../components/widgets/accordian/AccordionCheckoutCustomization";

const ListItem = styled.div`
    margin-left: .2rem;
`;

const ChildTitle = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
`;

const ListContainer = styled.div`
    // border: solid #78d6a3 1.5px;
    // border-right: none;
    // border-top-left-radius: 5px;
    // border-bottom-left-radius: 5px;
    padding: 0.2rem 0rem;
    padding-left: 0.4rem;
    padding-right: 0rem;

    // background: rgb(249 255 236);
`;


const CheckoutProductCustomization = ({ customizations }) => {


    const accordionShow = (list: any) => {
        let optionCount = 0;
        list?.forEach((element: any) => {
            if (element?.is_selected) {
                optionCount += 1;
            }
        });

        if (optionCount > 0) { return true; }
        else { return false; }
    }

    return (
        <>
            {
                customizations?.map((customizes: any, mainIndex: any) => {

                    return (
                        <React.Fragment key={mainIndex}>
                            {accordionShow(customizes?.options)
                                &&
                                <>

                                    <AccordionCheckoutCustomization
                                        title={"▪ " + customizes?.name}
                                        titleSize={"16px"}
                                    >

                                        <ListContainer>
                                            {customizes?.options?.map((optionObj: any, index: any) => {

                                                return (
                                                    <React.Fragment key={index}>
                                                        {(optionObj?.is_selected)
                                                            &&
                                                            <ListItem>

                                                                <ChildTitle>
                                                                    <div className="flex items-center">{(optionObj?.customizations?.length === 0) ? "•" : <span style={{ fontSize: "8px" }}>▼ &nbsp;</span>} {optionObj?.name}</div> <div>{optionObj?.formatted_price} [x{optionObj?.selected_quantity}]</div>
                                                                </ChildTitle>

                                                                {(optionObj?.customizations?.length > 0)
                                                                    &&
                                                                    <div style={{ marginLeft: '' }}>
                                                                        <CheckoutProductCustomization
                                                                            customizations={optionObj?.customizations}
                                                                        />
                                                                    </div>
                                                                }
                                                            </ListItem>
                                                        }
                                                    </React.Fragment >
                                                );
                                            })}
                                        </ListContainer>
                                    </AccordionCheckoutCustomization>
                                </>
                            }
                        </React.Fragment >
                    );
                })
            }
        </>
    )
}

export default CheckoutProductCustomization;