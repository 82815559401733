import { ButtonGreen, ButtonLimeGreen, FlexBetween, InitialDiv, InputLabel, SubText1, TextHead3, moveTopBottom } from "../../styled/styled";
import { useHistory, useLocation } from "react-router";
import { useEffect, useState } from "react";
import { getUserData, loginRegisterUser, setUserData, verifyUser } from "../../../services/user";
import { sleep, switchSentenceCase, trackPixelEvent } from "../../../utils/utilities";
import store from "../../../redux/store";
import SignInWithEmail from "../../../pages/signupin/SignInWithEmail";
import SignInWithPhone from "../../../pages/signupin/SignInWithPhone";
import styled from "styled-components";
import { useSelector } from "react-redux";
import unlockPassword from "../../assets/onboarding/unlock-password.svg"
import OtpInput from 'react18-input-otp';
import Spinner from "../spinner/Spinner";
import useDarkMode from "../../hooks/darkmode/DarkMode";
import Divider from "../divider/Divider";
import SignInWithGoogle from "../../../pages/signupin/SignInWithGoogle";
import SignInWithMeta from "../../../pages/signupin/SignInWithMeta";
import SigninWithApple from "../../../pages/signupin/SignInWithApple";
import { isPlatform } from '@ionic/react';

const ModalDiv = styled.div`
position: fixed;
display: flex;
justify-content: center;
top: 0;
left: 0;
width: 100%;
height: 100%;
background-color: rgba(0, 0, 0, 0.8);
z-index: 1000;
overflow-y: auto;
`;

const ContentDiv = styled.div <{ isDarkMode: boolean }>`
    background-color:${props => (props?.isDarkMode ? '#2e2e2e' : '#fff')};
    margin-bottom: 85px;
    margin-top: 80px;
    padding: 10px;
    z-index: 1000;
    width: 600px;
    max-width: 90%;
    border-radius: 20px;
    text-align: center;
    height: fit-content;
    animation: ${moveTopBottom} .4s ease-in-out;
`;

export default function ModalSignUp(props: any) {
    interface LocationStateProps {
        loginType?: boolean; // loginType is a boolean, change it to the correct type if different from verification page
    }

    const isDarkMode = useDarkMode();
    // const modalRef = useRef();
    const history = useHistory();
    const { loginState } = useSelector((state: any) => state.sessionAlertState);

    const location = useLocation<LocationStateProps>();
    const { state } = location;
    const [changeLoginType, setChangeLoginType] = useState<boolean>(state?.loginType || false);
    const [showVerifyModal, setShowVerifyModal] = useState(false);
    const [otp, setOtp] = useState("");
    const [otpError, setOtpError] = useState("");
    const [awaiting, setAwaiting] = useState(false);
    const [resendWait, setResendWait] = useState(false);
    const [localData, setLocalData] = useState<any>({});
    const [seconds, setSeconds] = useState(0);
    const time = 30;

    //***************** CHECK USER SESSION  *****************//
    async function checkUserLoggedIn() {
        const userData = await getUserData();
        setLocalData(userData);
        startCountdown();
    }

    useEffect(() => {
        checkUserLoggedIn();
    }, [showVerifyModal]);


    const { show } = useSelector((state: any) => state.sessionAlertState);

    //***************** HANDLE SUBMIT  *****************//
    const handleSubmit = async (e: any) => {

        //* --------------------------Validation-----------------------
        if (otp === "") {
            setOtpError("Please Enter OTP First !");
        } else if (otp?.length < 6 || otp?.length > 6) {
            setOtpError("OTP Should Contain 6 Digits !");
        } else if (otp?.length === 6) {
            setOtpError("")

            //* --------------------------Api Call--------------------------
            try {
                setAwaiting(true);
                await verifyUser(otp, localData?.provider)
                    .then(async (response: any) => {
                        trackPixelEvent('Login register user');
                        let data = response?.data;
                        if (data?.user?.confirmed === true) {
                            await setUserData(data);
                            setAwaiting(false);
                            store.dispatch({
                                type: "SHOW_ALERT",
                                payload: { show: false }
                            })
                            window.location.reload();
                            // }
                        }
                    })
            } catch (error) {
                console.log(error);
                setAwaiting(false);
                const err = error?.response?.data?.error?.message;
                if (String(err).toLowerCase().includes("invalid")) {
                    setOtpError("Code is invalid. Try again or →");
                }
            }
        }
    }

    //* Timer for user to wait between resending otp.
    const startCountdown = async () => {
        setResendWait(true);
        for (let i = time; i >= 0; i--) {
            await sleep(1000)
            setSeconds(i);

            if (i === 0) {
                setResendWait(false);
                break
            }
        }
    }

    //***************** HANDLE RESEND OTP  *****************//
    const resendOtp = async (e: any) => {
        try {
            if (awaiting || resendWait) { return false; }
            startCountdown();
            setOtp("");
            setOtpError("");

            //* Api Call--------------------------
            setAwaiting(true);
            await loginRegisterUser(localData?.contact, localData?.provider)
                .then(async (response: any) => {
                    trackPixelEvent('Login register user');
                    let data = response?.data;
                    if (data.status === "pending") {
                        setAwaiting(false);
                    }
                })

        } catch (error) {
            console.log(error);
            setAwaiting(false);
        }
    }
    // const handlePaste: React.ClipboardEventHandler = (event) => {
    //     const data = event.clipboardData.getData('text');
    //     setOtp(data)
    // };

    //***************** HANDLE KEY DOWN FUNCTION *****************//
    const handleKeyDown = (e: any) => {
        if (e.key === 'Enter' && showVerifyModal) {
            handleSubmit(e);
        }
    };

    return (
        <>
            {show && (
                <ModalDiv>
                    <ContentDiv isDarkMode={isDarkMode}>
                        <div className="grow overflow-auto my-2">
                            <InitialDiv className="mx-2">

                                <TextHead3>Login to Continue</TextHead3>
                            </InitialDiv>
                            {/* </IntialDiv> */}
                            {!showVerifyModal ?
                                <>
                                    <InitialDiv>
                                        {/* <Section> */}

                                        {/* <Section>
                                        <div className="bg-[#22a45d] w-[180px] ml-auto mr-auto rounded-[110px] p-[28px]">
                                            <img src={mealFaveLogo} alt="Send Meal" className="mx-auto logo-animate rounded-[22px]" width={120} height={120} />
                                        </div>
                                        <div className="text-below-image text-center">
                                            <SubText2 className="subtext text-center text-gray-400 my-1 !text-[18px]">Enjoy your favorite meals, stress-free.</SubText2>
                                        </div>
                                    </Section> */}
                                        {changeLoginType
                                            ?
                                            <SignInWithEmail inputTypeValue={state || ""} setShowVerifyModal={setShowVerifyModal} loginState={loginState}
                                            />
                                            :
                                            <SignInWithPhone inputTypeValue={state || ""} setShowVerifyModal={setShowVerifyModal} loginState={loginState}
                                            />
                                        }
                                        <SubText1 className="!font-bold text-center">OR</SubText1>
                                        <InitialDiv className="mx-4">
                                            <ButtonLimeGreen
                                                className="mt-[15px]"
                                                disabled={loginState}
                                                onClick={() => setChangeLoginType((prev) => !prev)}
                                            >
                                                {switchSentenceCase("primary button", changeLoginType ? "SIGN IN WITH PHONE" : "SIGN IN WITH EMAIL")}
                                            </ButtonLimeGreen>
                                        </InitialDiv>
                                        {
                                            process.env.REACT_APP_GOOGLE_ENABLED === "false" && process.env.REACT_APP_FACEBOOK_ENABLED === "false"
                                                ? ''
                                                : (
                                                    <InitialDiv className="ml-3 mr-3">
                                                        {
                                                            process.env.REACT_APP_GOOGLE_ENABLED === "false"
                                                                ? ''
                                                                : <SignInWithGoogle loginState={loginState} />
                                                        }
                                                        {
                                                            process.env.REACT_APP_FACEBOOK_ENABLED === "false"
                                                                ? ''
                                                                : <SignInWithMeta loginState={loginState} />
                                                        }
                                                        {
                                                            isPlatform('android') || process.env.REACT_APP_APPLE_ENABLED === "false"
                                                                ? ''
                                                                : <SigninWithApple loginState={loginState} />
                                                        }
                                                    </InitialDiv>
                                                )
                                        }
                                        {/* </Section> */}
                                    </InitialDiv>
                                    <InitialDiv className="my-4 mx-4">
                                        <Divider />
                                        <SubText1 className="text-center  mt-4">By continuing, you agree to MealFave's Terms & Conditions and Privacy Policy. Also, you may receive a one-time verification code and delivery notifications via text message to the phone number on your account. Message and data rates may apply.</SubText1>
                                        <SubText1 className="text-center"><span className="text-green-500 underline cursor-pointer" onClick={() => window.open(process.env.REACT_APP_URL + '#' + '/termsandconditions', "_blank")}>Terms & Conditions</span> and <span className="text-green-500 underline cursor-pointer" onClick={() => window.open(process.env.REACT_APP_URL + '#' + '/privacypolicy', "_blank")}>Privacy Policy</span>.</SubText1>
                                    </InitialDiv>
                                </>
                                :
                                <InitialDiv className="mx-2">
                                    {/* <Section>
                                        <TextHead3 style={{ fontWeight: "600" }}>Verification</TextHead3>
                                    </Section> */}
                                    <InitialDiv className="w-full flex justify-center">
                                        <img src={unlockPassword} alt="img" width={250} />
                                    </InitialDiv>

                                    <InitialDiv>
                                        <FlexBetween>
                                            <InputLabel className="mb-[10px]">{`Enter code sent to ${localData?.contact ? localData?.contact + "." : "[email or phone number]"}`}
                                            </InputLabel>
                                            {/* {(seconds > 0) ? "" : <FontAwesomeIcon icon={faPenLine} className="text-green-600 text-sm"
                                                onClick={async () => {
                                                    const isEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(localData?.contact);
                                                    history.push({
                                                        pathname: "/login",
                                                        state: { loginType: isEmail, inputValue: localData?.contact },
                                                    });
                                                }} />} */}
                                        </FlexBetween>
                                        <div>
                                            <form onKeyDown={handleKeyDown}>
                                                <OtpInput
                                                    value={otp}
                                                    shouldAutoFocus={true}
                                                    onChange={setOtp}
                                                    numInputs={6}
                                                    isInputNum={true}
                                                    separator={<span>-</span>}
                                                    containerStyle="justify-center"
                                                    inputStyle="bg-transparent !w-10 h-10 border m-[0.2rem]"

                                                />
                                            </form>
                                        </div>

                                        <FlexBetween>
                                            {otpError ? <p className="mt-2 text-rose-800">{otpError}</p> : <p></p>}
                                            <p className="mt-2 text-red-600 cursor-pointer">
                                                <span onClick={resendOtp} style={{ color: ((seconds > 0) ? "grey" : "") }}>Resend Code {(seconds > 0) ? `(in ${seconds}s)` : ""}</span>
                                            </p>
                                        </FlexBetween>


                                        {(awaiting === false)
                                            ?
                                            <ButtonGreen
                                                className="mt-[15px] mb-2"
                                                onClick={handleSubmit}
                                            >
                                                {switchSentenceCase("primary button", "CONTINUE")}
                                            </ButtonGreen>
                                            :
                                            <div className="text-center">
                                                <Spinner />
                                            </div>
                                        }
                                    </InitialDiv>
                                </InitialDiv>
                            }
                        </div>
                    </ContentDiv>
                </ModalDiv >
            )}


            {/* ********************* Modal Verify OTP ****************************** */}
            {/* {
                showVerifyModal &&
                <ModalVerifyOtp
                    showModal={showVerifyModal}
                    setShowModal={setShowVerifyModal}
                    setHideModal={setHideBackModal}
                />
            } */}
        </>
    );
}
