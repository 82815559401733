import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';
import { isPlatform, useIonAlert, useIonRouter } from '@ionic/react';
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import { GoogleLoginButton } from 'react-social-login-buttons';
import Spinner from '../../components/widgets/spinner/Spinner';
import store from "../../redux/store";
import { getGoogleUserData, getGoogleUserDetails, setUserData, socialLogin } from '../../services/user';
import { objectType } from '../../type';

export default function SignInWithGoogle({ loginState }) {

    const router = useIonRouter();
    const history = useHistory();
    const [awaiting, setAwaiting] = useState(false);
    const [presentAlert] = useIonAlert();

    const showAlert = (header: string, message: string, btnMessage: string) => {
        presentAlert({
            header: header,
            message: message,
            buttons: [btnMessage],
        });
    };

    useEffect(() => {
        // GoogleAuth.initialize({
        //     clientId: "940569690784-frj55aml7bf3qoikivlumm4lnni30kha.apps.googleusercontent.com",
        //     scopes: ['profile', 'email'],
        //     grantOfflineAccess: false,
        // });
        // GoogleAuth.initialize({ grantOfflineAccess: true });
        // const initializeGoogle = async () => {
        //     GoogleAuth.initialize({ grantOfflineAccess: true });
        // };
        // initializeGoogle();
        if (!isPlatform("capacitor")) {
            GoogleAuth.initialize();
        }
    }, []);

    const signInWithGoogle = async () => {
        try {
            const result = await GoogleAuth.signIn();
            if (result.authentication.accessToken) {
                handleLogin('google', result);
            }
        } catch (error) {
            if (error?.error === "popup_closed_by_user") {
                setAwaiting(false);
                store.dispatch({
                    type: "SET_LOGIN_STATE",
                    payload: { loginState: false }
                })
            }
            else {
                setAwaiting(false);
                store.dispatch({
                    type: "SET_LOGIN_STATE",
                    payload: { loginState: false }
                })
                return showAlert('Error', 'Login failed. Please try again.', 'OK');
            }
        }
    };

    const handleLogin = async (provider: string, data: objectType) => {
        if (data?.error === "access_denied") {
            setAwaiting(false);
            store.dispatch({
                type: "SET_LOGIN_STATE",
                payload: { loginState: false }
            })
            return showAlert('Error', 'Login failed. Please try again.', 'OK');
        }
        try {
            if (data.authentication.accessToken) {
                const userData = await getGoogleUserData(data?.authentication.accessToken);

                let local_data = userData?.data;
                local_data['provider'] = provider;
                local_data['token'] = data.authentication.accessToken;
                const userDetails = await getGoogleUserDetails(data?.authentication.accessToken);

                if (userDetails?.data?.addresses) {
                    local_data['addresses'] = userDetails.data.addresses;
                }
                if (userDetails?.data?.phoneNumbers) {
                    local_data['phoneNumbers'] = userDetails.data.phoneNumbers;
                }

                await socialLogin(JSON.stringify(local_data), provider).then(
                    async (response: any) => {
                        let data = response?.data;
                        // Google Analytics event snippet
                        if (process.env.REACT_APP_GTAG_ENABLED_FOR_PRODUCTION === "true") {
                            if (window.gtag && data?.user?.new_user === true) {
                                window.gtag('event', 'conversion', { 'send_to': 'AW-16498935235/D9w9CMi297cZEMOLp7s9' });
                            } else if (window.gtag && data?.user?.new_user === false) {
                                window.gtag('event', 'conversion', { 'send_to': 'AW-16498935235/tNh_CPmurrkZEMOLp7s9', 'value': 1.0, 'currency': 'USD' });
                            }
                        }
                        // Google Analytics event snippet end

                        await setUserData(data);
                        setAwaiting(false);
                        store.dispatch({
                            type: "SET_LOGIN_STATE",
                            payload: { loginState: false }
                        })
                        if (router.routeInfo.pathname === '/login') {
                            history.push('/onboarding');
                        }
                        else {
                            store.dispatch({
                                type: "SHOW_ALERT",
                                payload: { show: false }
                            })
                            window.location.reload();
                        }
                    })
                    .catch((error) => {
                        console.error('Error Social Login:', error);
                        setAwaiting(false);
                        store.dispatch({
                            type: "SET_LOGIN_STATE",
                            payload: { loginState: false }
                        })
                    });
            }
        }
        catch (error) {
            // console.log("Error login with google", error);
            setAwaiting(false);
            store.dispatch({
                type: "SET_LOGIN_STATE",
                payload: { loginState: false }
            });
        }
    }

    return (
        <>
            {awaiting ?
                <div className="text-center">
                    <Spinner />
                </div>
                :
                <>
                    <GoogleLoginButton className='google-login-button' style={{ display: "flex", justifyContent: "center" }}
                        disabled={loginState ? true : false}
                        onClick={() => {
                            setAwaiting(true);
                            store.dispatch({
                                type: "SET_LOGIN_STATE",
                                payload: { loginState: true }
                            })
                            signInWithGoogle();
                        }}
                    >
                        <span>Sign in with Google</span>
                    </GoogleLoginButton>
                </>
            }
        </>
    );
};    