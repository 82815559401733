import { useContext, useState } from "react";
import loadGoogle from "../../../services/google";
import { UserContext } from "../../../App";
import { useDispatch } from "react-redux";
import { setUserSearchLocation } from "./redux/addressActionCreator";
import { setRestaurantsListLoading } from "../../../pages/search/redux/searchActionCreator";
import { restaurantsNearPlace } from "../../../services/restaurant";
import { trackPixelEvent } from "../../../utils/utilities";

export default function useLocationComponents(ctx: any, user: any, place: any) {
  const dispatch = useDispatch()
  const { userValue, setUserValue } = useContext(UserContext);

  const [coordinate, setCoordinate] = useState({});
  const [awaitingCurrentLocation, setAwaitingCurrentLocation] = useState(false);

  const onAddressChosen = async (place: google.maps.places.PlaceResult) => { };

  var options = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0
  };

  const errors = (err: any) => {
    console.warn(`ERROR(${err.code}): ${err.message}`);
  }

  const onUseCurrentLocation = () => {
    navigator.geolocation.getCurrentPosition(onCurrentLocationGotten);
    dispatch(setRestaurantsListLoading(true) as any)
    setAwaitingCurrentLocation(true);
  };

  const onCurrentLocationGotten = () => {
    try {
      if (navigator.geolocation) {
        navigator.permissions
          .query({ name: "geolocation" })
          .then(function (result) {
            if (result.state === "granted") {
              //If granted then you can directly call your function here
              navigator.geolocation.getCurrentPosition(success);
            } else if (result.state === "prompt") {
              navigator.geolocation.getCurrentPosition(
                success,
                errors,
                options
              );

            } else if (result.state === "denied") {
              //If denied then you have to show instructions to enable location
            }
            result.onchange = function () {
            };
          });
        dispatch(setRestaurantsListLoading(false) as any)
        setAwaitingCurrentLocation(false);
      } else {
        alert("Sorry Not available!");
      }
    } catch (e) {
      console.log(e);
    }
  };

  const success = (pos: any) => {
    var crd = pos.coords;
    setCoordinate(crd)

    const location = {
      latitude: crd.latitude,
      longitude: crd.longitude,
    }
    onCurrentLocationGoogle(location)
  }

  const onCurrentLocationGoogle = (loc: any) => {
    loadGoogle().then(
      async (google) => {
        const geocoder = new google.maps.Geocoder();
        const response = await geocoder.geocode({
          location: {
            lat: loc.latitude,
            lng: loc.longitude,
          },
        });

        trackPixelEvent('Load google location');

        const bestGuess = response.results[0];

        // const addr = String(bestGuess.formatted_address).split(',').slice(1).toString()

        let streetAddress1: any, streetAddress2: any, streetNum: any, streetName: any, city: any, state: any, country: any, zip: any;
        for (let i = 0; i < bestGuess.address_components.length; i++) {
          for (let j = 0; j < bestGuess.address_components[i].types.length; j++) {
            switch (bestGuess.address_components[i].types[j]) {
              case "route":
                streetAddress1 = place?.address_components[i].long_name;
                break;
              case "sublocality_level_2":
                streetAddress2 = place?.address_components[i].long_name;
                break;
              case "premise":
                streetNum = bestGuess?.address_components[i].long_name;
                break;
              case "sublocality":
                streetName = bestGuess?.address_components[i].long_name;
                break;
              case "locality":
                city = bestGuess?.address_components[i].long_name;
                break;
              case "administrative_area_level_1":
                state = bestGuess?.address_components[i].long_name;
                break;
              case "country":
                country = bestGuess?.address_components[i].short_name;
                break;
              case "postal_code":
                zip = bestGuess?.address_components[i].long_name;
                break;
            }
          }
        }
        let lat = bestGuess?.geometry?.location.lat()
        let lang = bestGuess?.geometry?.location.lng()
        const placeObject = { streetAddress: `${(streetAddress1 == undefined && streetAddress2 == undefined) ? "" : (streetAddress1 !== undefined) ? + streetAddress1 : "" + `${(streetAddress2 !== undefined) ? ", " + streetAddress2 : ""}`}`, streetNum, streetName, city, state, country, zip, lat, lang }
        setUserSearchLocation(placeObject);
        setUserValue(placeObject)
        restaurantsNearPlace(placeObject);
      }
    );
  };

  return [
    onAddressChosen,
    onUseCurrentLocation,
    userValue,
    awaitingCurrentLocation,
  ] as const;
}
