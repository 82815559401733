import { faMinus, faPlus } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";

const LabelText = styled.div`
   font-size: 17px;
   margin-left: 5px;
   width: -webkit-fill-available;
`;

const Item = styled.div`
  display: flex;
  align-items: center;
  /* height: 48px; */
  position: relative;
  margin-bottom: 10px;
  margin-top: 5px;
`;

const styleDiv = {
  display: "flex",
  alignItems: "center",
  justifyContent: "start",
  width: "-webkit-fill-available",
}

const buttonStyle = {
  padding: "8px",
  background: "#22A45D",
  cursor: "pointer",
  color: "white",
}

const countNumberStyle = {
  padding: "2px 10px",
  borderTop: "2px solid #22A45D",
  borderBottom: "2px solid #22A45D",
}

export default function QuantitySelectorGlobal(props: any) {

  const increaseQuantity = (quantity: any) => {
    props?.increaseQuantity(quantity)
  }

  const decreaseQuantity = (quantity: any) => {
    props?.decreaseQuantity(quantity)
  }


  return (
    <>
      <Item style={props?.border}>
        <div style={styleDiv}>

          <div className="flex items-stretch justify-between">
            <FontAwesomeIcon
              icon={faMinus}
              style={{ ...buttonStyle, borderTopLeftRadius: "6px", borderBottomLeftRadius: "6px" }}
              onClick={() => decreaseQuantity(props?.selected_quantity - 1)}
            />

            <span style={countNumberStyle}>
              Quantity {props?.selected_quantity}
            </span>

            <FontAwesomeIcon
              icon={faPlus}
              style={{ ...buttonStyle, borderTopRightRadius: "6px", borderBottomRightRadius: "6px" }}
              onClick={() => increaseQuantity(props?.selected_quantity + 1)}
            />
          </div>

          {props?.label
            &&
            <label htmlFor={props?.customization_id + props?.index}>
              <LabelText style={props.labelStyle}>{props?.label}</LabelText>
            </label>
          }
        </div>
      </Item>
    </>
  );
}
