import { useState } from "react";
import Section from "../../components/widgets/section/Section";
import TopHead from "../../components/widgets/head/TopHead";
import Divider from "../../components/widgets/divider/Divider";
import { ButtonGray, ButtonGreen, Flex, InputLabel, ItemCount, SubText1, TextHead3, TextHead4, TextHead5 } from "../../components/styled/styled";
import styled from 'styled-components';
import { useHistory } from "react-router";
import { switchSentenceCase } from "../../utils/utilities";
import { faAngleLeft } from "@fortawesome/pro-solid-svg-icons";
import { faAngleRight } from "@fortawesome/pro-regular-svg-icons";
import ModalBottomToTop from "../../components/widgets/modals/ModalBottomToTop";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


const Container = styled.div`
   margin: 35px 0px 35px 5px;
`;

const BlockContainer = styled.div`
    display: block;
    width: -webkit-fill-available;
    text-align: left;
`;

const Block = styled.div`
`;


const MealFaveLogo = styled.div`
   background: #C4C4C4;
   width: 329px;
   height: 173px;
   display: inline-grid;
   align-items: center;
  `;

const TransDiv = styled.div`
padding: 10px 0px 20px 0px;
font-size: 17px;
font-weight: 600;
cursor: pointer;
display: flex;
justify-content: space-between;
`;

export default function Schedules(data: any) {
  const [showModalSave, setShowModalSave] = useState(false);
  const [showModalDecline, setShowModalDecline] = useState(false);

  const history = useHistory();

  const GotoHandle = () => {
    history.push({
      pathname: '/calendar-home',
    })
  }


  const openModalDecline = () => {
    setShowModalDecline(prev => !prev)
  }

  const openModalSave = () => {
    setShowModalSave(prev => !prev)
  }


  return (
    <div className="screen">
      <TopHead
        title="New Meal Calendar"
        leftTextIcon={faAngleLeft}
        leftIconStyle={{ fontSize: "24px" }}
        goToHandle={GotoHandle}
      />
      <Divider />

      <div className="grow overflow-auto">

        <Section>
          <TextHead3>
            <span className="text-green-600">Liza Weber</span> created a meal schedule for you, so your foodies can treat you to your favorite food!
          </TextHead3>
          <Section>
            <SubText1>
              Check out the details and change anything you wish!
            </SubText1>
          </Section>
          <Section>
            <SubText1>
              Once you’re satisfied, select “Save and Continue”, and you can view these settings later in your Meal Schedules.
            </SubText1>
          </Section>

          <Container >
            <Flex style={{ margin: "10px 0px" }}>
              <ItemCount>1</ItemCount>
              <BlockContainer>
                <Flex style={{ justifyContent: "space-between" }}>
                  <TextHead4>Schedule Name and Notes</TextHead4>
                  <TextHead5 className="text-green-600 !font-semibold underline cursor-pointer">EDIT</TextHead5>
                </Flex >
                <Block >
                  <SubText1 style={{ fontWeight: "500" }}>Work Fuel for Ryan Weber</SubText1>
                  <SubText1>“Let’s keep the creative juices flowing!”</SubText1>
                </Block>
              </BlockContainer>
            </Flex>
            <Divider />
            <Flex style={{ margin: "10px 0px" }}>
              <ItemCount>2</ItemCount>
              <BlockContainer>
                <Flex style={{ justifyContent: "space-between" }}>
                  <TextHead4>Delivery Location</TextHead4>
                  <TextHead5 className="text-green-600 !font-semibold underline cursor-pointer">EDIT</TextHead5>
                </Flex >
                <Block >
                  <SubText1>4953 McPherson Ave</SubText1>
                </Block>
              </BlockContainer>
            </Flex>
            <Divider />
            <Flex style={{ margin: "10px 0px" }}>
              <ItemCount>3</ItemCount>
              <BlockContainer>
                <Flex style={{ justifyContent: "space-between" }}>
                  <TextHead4>Schedule</TextHead4>
                  <TextHead5 onClick={() => history.push({ pathname: "/schedulewslots" })} className="text-green-600 !font-semibold underline cursor-pointer">EDIT</TextHead5>
                </Flex >
                <Block >
                  <SubText1>Dates: Mar 14 - July 1, 2022</SubText1>
                  <SubText1>Meals/Day: 2, 2:00P</SubText1>
                </Block>
              </BlockContainer>
            </Flex>
            <Divider />
            <Flex style={{ margin: "10px 0px" }}>
              <ItemCount>4</ItemCount>
              <BlockContainer>
                <Flex style={{ justifyContent: "space-between" }}>
                  <TextHead4>Invited Foodies (to Donate)</TextHead4>
                  <TextHead5 className="text-green-600 !font-semibold underline cursor-pointer">EDIT</TextHead5>
                </Flex >
                <Block >
                  <SubText1>Boss Man</SubText1>
                  <SubText1>Mom</SubText1>
                  <SubText1>Coworker #2</SubText1>
                </Block>
              </BlockContainer>
            </Flex>
            <Divider />
            <Flex style={{ margin: "10px 0px" }}>
              <ItemCount>5</ItemCount>
              <BlockContainer>
                <Flex style={{ justifyContent: "space-between" }}>
                  <TextHead4>Assign Meal Fave Categories</TextHead4>
                  <TextHead5 onClick={() => history.push({ pathname: "/assignmealcategories" })} className="text-green-600 !font-semibold underline cursor-pointer">EDIT</TextHead5>
                </Flex >
                <Block >
                  <SubText1>Suggest selection of your Fave Meals</SubText1>
                </Block>
              </BlockContainer>
            </Flex>
            <Divider />
            <Flex style={{ margin: "10px 0px" }}>
              <ItemCount>6</ItemCount>
              <BlockContainer>
                <Flex style={{ justifyContent: "space-between" }}>
                  <TextHead4>Say Thank You</TextHead4>
                  <TextHead5 onClick={openModalSave} className="text-green-600 !font-semibold underline cursor-pointer">EDIT</TextHead5>
                </Flex >
                <Block >
                  <SubText1>Thanks for thinking of me!</SubText1>
                </Block>
              </BlockContainer>
            </Flex>
          </Container>
        </Section>
        <Section>
          <Divider />
          <TransDiv onClick={() => history.push({ pathname: "/newmealpayments" })}>
            Detailed Settings
            <FontAwesomeIcon
              icon={faAngleRight}
              style={{ float: "right" }}
            />
          </TransDiv>
          <Divider />
          <ButtonGreen style={{ margin: "15px 0px" }}>{switchSentenceCase("primary button", "SAVE AND CONTINUE")}</ButtonGreen>
          <ButtonGray onClick={openModalDecline}>{switchSentenceCase("primary button", "DECLINE MEAL SCHEDULE (WITH NOTES)")}</ButtonGray>

          {/*---------- Modal Bottom to top -- (for save and continue)----------- */}
          <ModalBottomToTop title="Thank you!" containerStyle={{ height: "initial" }} showModal={showModalSave} setShowModal={setShowModalSave} >
            <Divider />
            <Section>
              <InputLabel>Hey Liza Weber, thank you for setting up a meal schedule for me in Meal Fave, that was so thoughtful!</InputLabel>
            </Section>
            <Section>
              <InputLabel>I look forward to the tasty food!</InputLabel>
            </Section>

            <Section>
              <ButtonGreen >{switchSentenceCase("primary button", "SEND THANK YOU")}</ButtonGreen>
            </Section>

            <MealFaveLogo >Meal Fave Logo</MealFaveLogo>
          </ModalBottomToTop>



          {/*---------- Modal Bottom to top --(For Declined)----------- */}
          <ModalBottomToTop title="Decline Meal Schedule" containerStyle={{ height: "initial" }} showModal={showModalDecline} setShowModal={setShowModalDecline} >
            <Divider />
            <Section>
              <InputLabel>Hey Liza Weber, thank you for setting up a meal schedule for me in Meal Fave, but I respectfully decline.</InputLabel>
            </Section>
            <Section>
              <InputLabel>It’s the thought that counts, though!</InputLabel>
            </Section>

            <Section>
              <ButtonGray >{switchSentenceCase("primary button", "DECLINE MEAL SCHEDULE")}</ButtonGray>
            </Section>

            <MealFaveLogo >Meal Fave Logo</MealFaveLogo>

          </ModalBottomToTop>

        </Section>
      </div >

    </div >
  );
}
